import React, { useEffect, useState } from "react";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { SelectCustom } from "../../../../../Components/FormComponent/SelectCustom";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FieldArray } from "formik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { TimeCustomFormik } from "../../../../../Components/FormComponent/TimeCustomFormik";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";

export const CreateRegularisationComp = ({ formValues, loading }) => {
  const [regularizationInitialValues, setRegularizationInitialValues] =
    useState({
      needInOut: false,
      numberOfRegularizeDays: 0,
      reasonForRegularize: "",
    });
  const [value, setValue] = useState([]);
  const [pendingValue, setPendingValue] = useState([]);
  const [options, setOptions] = useState([
    { label: "Forgot to check-in", value: "Forgot to check-in" },
    { label: "Forgot my ID card", value: "Forgot my ID card" },
    { label: "Forgot to check-out", value: "Forgot to check-out" },
  ]);
  const dispatch = useDispatch();
  const getRegularizationConfigData = async () => {
    try {
      const result = await getRequest(`AttendanceRegularizationConfig/Details`);
      if (result?.status === 200) {
        setRegularizationInitialValues(result?.data);
        setOptions(
          result?.data?.reasonForRegularize.split(",").map((item) => {
            return { label: item, value: item };
          })
        );
      } else {
        dispatch(
          showToast({
            text: "You are not aligible to create regularization!!",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Error in fetching Data",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Regularisation`;
    getRegularizationConfigData();
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const reqData = {
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 100,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  };
  const getListData = async () => {
    try {
      const result = await postRequest(
        "AttendanceRegularizations/List",
        reqData
      );
      let listData = [];
      let pendingData = [];
      result.data.data.map((item) => {
        if (item.statusLabel.toLowerCase().includes("approved")) {
          return listData.push(new Date(item.date));
        } else if (item.statusLabel.toLowerCase().includes("pending")) {
          return pendingData.push(new Date(item.date));
        }
        return null;
      });
      setValue(listData);
      setPendingValue(pendingData);
    } catch {
    } finally {
    }
  };

  const mergedVal = value.concat(pendingValue);

  useEffect(() => {
    getListData();
  }, []);
  if (loading) return <Loader />;

  return (
    <>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom
            labelName={"Regularisation Start Date"}
            mandatory={true}
          />
          <DatePickerFormiks
            name={"startDate"}
            maxDate={new Date()}
            excludeDates={mergedVal}
          />
        </div>
        <div>
          <LabelCustom labelName={"Regularisation End Date"} mandatory={true} />
          <DatePickerFormiks
            name={"endDate"}
            minDate={formValues?.startDate}
            maxDate={new Date()}
            excludeDates={mergedVal}
          />
        </div>
      </div>
      <div>
        {/* {formValues?.Items.length <= 0 ? <label className="form-error">Requests have been already created for some of these days.</label> : null } */}
        {formValues?.startDate &&
        formValues?.endDate &&
        formValues?.Items.length > 0 ? (
          <>
            <div className="repeatedSectionContainer">
              <LabelCustom labelName={""} />
              <FieldArray name="Items">
                {(fieldArrayProps) => {
                  const { form } = fieldArrayProps;
                  const { values } = form;
                  const { Items } = values;
                  return (
                    <StyledTableCont>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              padding="0"
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              Date
                            </StyledTableCell>
                            <StyledTableCell
                              padding="0"
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              Old Start Time
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              New Start Time{" "}
                              <label style={{ color: "red" }}>*</label>
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              Old End Time
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              New End Time{" "}
                              <label style={{ color: "red" }}>*</label>
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              Reason <label style={{ color: "red" }}>*</label>
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontSize: "12px",
                              }}
                              align="center"
                            >
                              Remark <label style={{ color: "red" }}>*</label>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {Items?.map((Items, index) => (
                          <TableBody key={index}>
                            {/* 1st column */}
                            <StyledTableCell
                              align="center"
                              style={{ paddingTop: "0px" }}
                            >
                              <InputCustomFormik
                                name={`Items[${index}].date`}
                                type="text"
                                disabled={true}
                              />
                            </StyledTableCell>
                            {/* 2nd column */}
                            <StyledTableCell>
                              <InputCustomFormik
                                type="text"
                                name={`Items[${index}].oldStartTime`}
                                disabled={true}
                              />
                            </StyledTableCell>
                            {/* 3rd column */}
                            <StyledTableCell>
                              <TimeCustomFormik
                                name={`Items[${index}].newStartTime`}
                                minTime={formValues?.newStartTime}
                                // disabled={true}
                                autoComplete="off"
                              />
                            </StyledTableCell>
                            {/* 4th column */}
                            <StyledTableCell>
                              <InputCustomFormik
                                name={`Items[${index}].oldEndTime`}
                                type="text"
                                disabled={true}
                              />
                            </StyledTableCell>
                            {/* 5th column */}
                            <StyledTableCell>
                              <TimeCustomFormik
                                name={`Items[${index}].newEndTime`}
                                minTime={formValues?.newStartTime}
                                // disabled={true}
                                autoComplete="off"
                              />
                            </StyledTableCell>
                            {/* 6th column */}
                            <StyledTableCell>
                              {/* <TextAreaFormik maxLength={500} name={`Items[${index}].Remarks`} /> */}
                              <SelectForm
                                name={`Items[${index}].Remarks`}
                                options={options}
                                placeholder={"Select... "}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <TextAreaFormik
                                maxLength={500}
                                name={`Items[${index}].remarksNew`}
                              />
                              {/* <SelectForm
                                name={`Items[${index}].remarksNew`}
                                options={options}
                                placeholder={"Select... "}
                              /> */}
                            </StyledTableCell>
                          </TableBody>
                        ))}
                      </Table>
                    </StyledTableCont>
                  );
                }}
              </FieldArray>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

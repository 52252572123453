
import React, { useEffect, useState } from "react";
import Addonboard from "../../../../Assets/Addonboard.svg";
import GirlCandidate from "../../../../Assets/GirlCandidate.svg";
import WelImg from "../../../../Assets/WelImg.svg";
import rotTraingle from "../../../../Assets/rotTraingle.svg";
import CandText from "../../../../Assets/CandText.svg";
import circleCan from "../../../../Assets/circleCan.svg";
import CanOuter from "../../../../Assets/CanOuter.svg";
import AddOrange from "../../../../Assets/AddOrange.svg";
import CirclePink from "../../../../Assets/CirclePink.svg";
import CircDouble from "../../../../Assets/CircDouble.svg";
import circlelilPur from "../../../../Assets/circlelilPur.svg";
import circleLittle from "../../../../Assets/circleLittle.svg";
import CircTraipur from "../../../../Assets/CircTraipur.svg";
import TraingSky from "../../../../Assets/TraingSky.svg";
import "../../../../../src/Pages/Landing/CandidateLanding.css"
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import UploadImageDetail from "../../../../Components/UploadDetail/UploadImageDetail";
const CandidateOfferLetter = () => {
    const [viewLetter, setViewLetter] = useState(false);
    const [submission, setSub] = useState(false)
    const [loading, setLoading] = useState(false);
    const [displayDetails, setDisDetails] = useState({});
    useEffect(() => {
        getDetails()
    }, [])
    const getDetails = async () => {
        let obj = getUrlVars();
        setLoading(true);
        let result = await getRequest("HrmsOfferLetter/candidateDetails?Id=" + obj.id + "&token=" + obj.token)
        setLoading(false);
        console.log("--------->", result)
        if (result.data)
            setDisDetails(result.data)
    }
    const acceptLetter = async () => {
        let obj = getUrlVars();
        setLoading(true)

        let result = await getRequest("HrmsOfferLetter/Accept?Id=" + obj.id + "&token=" + obj.token)
        setLoading(false)

        setSub(true)

    }
    const rejectLetter = async () => {
        let obj = getUrlVars();
        setLoading(true)
        let result = await getRequest("HrmsOfferLetter/Reject?Id=" + obj.id + "&token=" + obj.token)
        setLoading(false)
        setSub(true)

    }
    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
            function (m, key, value) {
                vars[key] = value;
            });
        return vars;
    }

    const CandidateOnboardingLanding = ({ candidateDetals }) => {
        useEffect(() => {
            document.title = `PeopleSol - Candidate Offer Letter Landing`;

            return () => {
                document.title = "PeopleSol";
            };

        }, []);


        return (
            <div className="outerCont">
                <div className="CirclePink">
                    <img src={CirclePink} alt="LoginPerson" />
                </div>
                <div className="canOuter">
                    <img src={CanOuter} alt="LoginPerson" />
                </div>
                <div className="outerGr">
                    <img src={Addonboard} alt="LoginPerson" />
                </div>
                <div className="circleCan">
                    <img src={circleCan} alt="LoginPerson" />
                </div>
                <div className="innerCont">
                    <div className="TraingSky">
                        <img src={TraingSky} style={{ width: "90%" }} alt="LoginPerson" />
                    </div>
                    <div className="CircDouble">
                        <img src={CircDouble} style={{ width: "90%" }} alt="LoginPerson" />
                    </div>
                    <div className="circlelilPur">
                        <img src={circlelilPur} style={{ width: "90%" }} alt="LoginPerson" />
                    </div>
                    <div className="circleLittle">
                        <img src={circleLittle} style={{ width: "90%" }} alt="LoginPerson" />
                    </div>
                    <div className="CircTraipur">
                        <img src={CircTraipur} style={{ width: "90%" }} alt="LoginPerson" />
                    </div>
                    {!submission && <div className="WelImg">
                        <img src={WelImg} alt="LoginPerson" />
                    </div>}
                    {!viewLetter && !submission && <div className="flexIn">
                        <div className="flexinText">
                            <div className="canName">
                                <div className="wel">Welcome</div>
                                <div className="canUser">

                                    <p>
                                        {loading ? (
                                            <Skeleton width={300} />
                                        ) : (
                                            <div>
                                                {displayDetails?.offerLetter?.name}
                                            </div>
                                        )}
                                    </p>
                                </div>
                            </div>
                            {/* <div className="innerCantext">
                    {loading ? (
                      <Skeleton count={10} />
                    ) : (
                      candidateDetals?.candidateOnboardingWelcomeContent
                    )}
                  </div> */}
                            <div className="orangeCan">
                                <img src={AddOrange} alt="LoginPerson" />
                            </div>
                            {/* <div className="cheersC">
                    {loading ? (
                      <Skeleton width={100} />
                    ) : (
                      candidateDetals?.candidateOnboardingWelcomeSignature?.split(
                        "|"
                      )?.[0]
                    )}
                    <div className=" teaes">
                      {loading ? (
                        <Skeleton width={100} />
                      ) : (
                        candidateDetals?.candidateOnboardingWelcomeSignature?.split(
                          "|"
                        )?.[1]
                      )}
                    </div>
                  </div> */}
                        </div>
                        <div className="girlImg">
                            <img
                                src={GirlCandidate}
                                style={{ width: "258.96px", height: "369.06px" }}
                                alt="LoginPerson"
                            />
                        </div>
                    </div>}
                    {loading && <Loader />}
                    {submission && <div style={{ color: "rgba(18, 150, 176, 1)", fontSize: 30, textAlign: "center", marginTop: "250px" }}>
                        {"Your request has been submitted successfully!"}
                        <br></br>
                        {"We will get back to you soon."}
                    </div>}
                    {viewLetter && !submission && <div className="btnContt" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-end", marginTop: 350 }}>
                        <div onClick={() => { setViewLetter(!viewLetter) }} className="button btnCand">
                            {!viewLetter ? "Next To View Offer Letter" : "Go Back"}
                        </div>
                        <div onClick={() => { acceptLetter() }} className="button btnCand" style={{ backgroundColor: "rgba(18, 150, 176, 1)", color: "white" }}>
                            Accept Offer Letter
                        </div>
                        <div onClick={() => { rejectLetter() }} className="button btnCand" style={{ backgroundColor: "Red", color: "white" }}>
                            Reject Offer Letter
                        </div>
                    </div>}
                    {!loading && !viewLetter && !submission && (
                        <div className="btnContt">

                            <button onClick={() => { setViewLetter(!viewLetter) }} className="btnCand">
                                {!viewLetter ? "Next To View Offer Letter" : "Go Back"}
                            </button>
                            <UploadImageDetail
                                filepath={`Company/aess_dev/Logo/${displayDetails?.companyLogo}`}
                            />
                        </div>
                    )}
                    {/* <div className="candText">
                        <img src={CandText} alt="LoginPerson" />
                    </div> */}
                </div>
                <div className="rotTraingle">
                    <img src={rotTraingle} alt="LoginPerson" />
                </div>
            </div>
        );
    };

    return (
        <div style={{ width: "100%", height: "100%", backgroundColor: "white" }}>

            <CandidateOnboardingLanding />
        </div>
    );
};

export { CandidateOfferLetter };

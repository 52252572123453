import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../../Components/FormComponent/PopupComponent/Remark";
import { Details } from "../../DetailsComp/Details";
import { Timeline } from "../../DetailsComp/TimeLine";
import { Notifications } from "../../DetailsComp/Notifications";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import { getBadgeCount } from "../../../../../Features/badgeUtils";
const DetailsPageProbation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  // //console.log("id", colorAction);
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (type, color) => {
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `EmploymentConfirmations/Details?ID=${selectedId}`
      );
      // //console.log('result.data.data',result.data.data);
      let listData = [];
      result?.data?.data.map((item) => {
        // //console.log('item',item)
        return listData.push({
          ...item,
        });
      });

      // result?.data?.data.map((item) =>{
      //     setData(item)
      // })
      setData(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const PostApRejHol = async (type, id, Remark, formData, values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Approve" || type === "Cancel" || type === "Release") {
        await postRequest(`EmploymentConfirmations/${type}/${id}`);
      } else if (type === "Mark as Closed") {
        await postRequest(
          `EmploymentConfirmations/Close?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Confirm Employment") {
        await postRequest(`EmploymentConfirmations/ConfirmProbation`, values);
      } else if (type === "Extend Probation") {
        await postRequest(`EmploymentConfirmations/ExtendProbation`, values);
      } else if (type === "Upload Hike & Confirmation Letter") {
        await postRequestForm(
          `EmploymentConfirmations/UploadHikeConfirmation`,
          formData
        );
      } else {
        await postRequest(`EmploymentConfirmations/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page Probation`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // //console.log("data", data);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Probation Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data?.length > 0 ? (
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="headingForm"> Probation Details</div>
                    <div className="detailInner">
                      {data?.map((item) => {
                        // // //console.log('item',item)
                        return (
                          <>
                            <Details
                              detailTitle={"Employee Name"}
                              Details={item?.item?.employee}
                            />
                            <Details
                              detailTitle={"Email ID"}
                              Details={item?.item?.email}
                            />
                            <Details
                              detailTitle={"Designation"}
                              Details={item?.item?.designation}
                            />
                            <Details
                              detailTitle={"Department"}
                              Details={item?.item?.department}
                            />

                            <Details
                              detailTitle={"Date Of Joining"}
                              Details={format(
                                new Date(item?.item?.doj),
                                "dd/MM/yyyy hh:mm a"
                              )}
                            />

                            <Details
                              detailTitle={"Extended Probation Date"}
                              Details={format(
                                new Date(item?.item?.extendedProbationDate),
                                "dd/MM/yyyy hh:mm a"
                              )}
                            />

                            <Details
                              detailTitle={"Probation End Date"}
                              Details={format(
                                new Date(item?.item?.probationEndDate),
                                "dd/MM/yyyy hh:mm a"
                              )}
                            />

                            <Details
                              detailTitle={"Probation Extension Reason"}
                              Details={item?.item?.probationExtensionReason}
                            />

                            <Details
                              detailTitle={"Confirmation Remarks"}
                              Details={item?.item?.probationConfirmationRemarks}
                            />

                            <Details
                              detailTitle={"Is Salary Hike Required?"}
                              Details={
                                <button
                                  className="button primary"
                                  style={{
                                    margin: "0",
                                    backgroundColor: !item?.statusColor
                                      ? "var(--secondary)"
                                      : item?.statusColor,
                                    color: "white",
                                    cursor: "default",
                                  }}
                                >
                                  {item?.item?.isSalaryHikeRequired === true
                                    ? "Yes"
                                    : "No"}
                                </button>
                              }
                            />
                            <Details
                              detailTitle={"Increment Type"}
                              Details={item?.item?.incrementType}
                            />

                            <Details
                              detailTitle={"Hike Percentage/Amount"}
                              Details={item?.item?.salaryPercentage}
                            />

                            <Details
                              detailTitle={"Status"}
                              Details={
                                <div
                                  style={{
                                    backgroundColor: getStatusColor(
                                      item?.status
                                    )?.split(",")?.[0],
                                    color: getStatusColor(item?.status)?.split(
                                      ","
                                    )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {item?.status}
                                </div>
                              }
                            />

                            <Details
                              detailTitle={"Last Updated On"}
                              Details={format(
                                new Date(item?.item?.lastUpdatedOn),
                                "dd/MM/yyyy hh:mm a"
                              )}
                            />
                          </>
                        );
                      })}
                    </div>

                    {/* {data?.summary?.actionlist ? (
                      <div
                        className="createFootDetails"
                        style={{ background: "white !important" }}
                      >
                        {data?.data?.actionlist.map((item) => (
                          <div>
                            <div
                              className="button secondaryButton"
                              onClick={() => {
                                item.type !== "Approve" &&
                                item.type !== "Release" &&
                                item.type !== "Cancel"
                                  ? togglePopup(item?.type, item?.color)
                                  : PostApRejHol(item?.type, item?.id);
                              }}
                              style={{
                                background: item?.color,
                                color: "white",
                              }}
                            >
                              {item?.type}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                    {data?.summary?.actionlist.map(
                      (item) =>
                        isOpen && (
                          <Popup
                            popupHeading={item?.type}
                            content={
                              <>
                                <Remark
                                  data={PostApRejHol}
                                  type={typeAction}
                                  id={item?.id}
                                  color={colorAction}
                                  handleClose={togglePopup}
                                />
                              </>
                            }
                            handleClose={togglePopup}
                          />
                        )
                    )} */}
                  </div>
                ) : (
                  <div className="noDatastyle">
                    <Loader />
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Records"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <Timeline
                        id={selectedId}
                        module={"Probation Requests"}
                        requestNumber={data?.data?.requestNumber}
                      />
                      {/* <Comments id={selectedId} module={data?.data?.module} requestNumber={
                          data?.data?.manpowerIndent?.requestNumber
                        } /> */}
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications id={selectedId} module={"Probation Requests"} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageProbation };

import React, { useEffect, useState } from 'react'
import { SelectCustom } from '../../Components/FormComponent/SelectCustom'
import { getRequest, postRequest } from '../../Services/axios';
import { TransactionTable } from '../../Components/Table/TransactionTable';
import { useDispatch } from 'react-redux';
import { isLoading, showToast } from '../../Features';
import { Loader } from '../../Components/FormComponent/Loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PublishWinnerTable from './PublishWinnerTable';
import { LabelCustom } from '../../Components/FormComponent/LabelCustom';
import { InputCustom } from '../../Components/FormComponent/InputCustom';
import publishwinnercrossimg from '../../Assets/publishwinnercrossimg.svg'
import PublishWinnerDepartmentTable from './PublishWinnerDepartmentTable';

const PublishWinnerSubTabDepartment = () => {
    const [searchParam, setSearchParam] = useState(null);
    // const [selectedLocation, setSelectedLocaton] = useState([]);
    // const [optionLocation, setOptionLocation] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [optionDepartment, setOptionDepartment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [originalData, setOriginalData] = useState(false);
    const [originalData1, setOriginalData1] = useState(false);
    const [originalData2, setOriginalData2] = useState(false);
    const [tbodyData, setTbodyData] = useState(false);
    const [renderButton, setRenderButton] = useState(true)
    // const [tbodyData1, setTbodyData1] = useState(false);
    // const [tbodyData2, setTbodyData2] = useState(false);
    const [tbodyDataItem, setTbodyDataItem] = useState(false);
    const [sortParam, setSortParam] = useState("Id");
    const [sortType, setSortType] = useState("descending");
    const [winnerCount, setWinnerCount] = useState([]);
    const [consolationCount, setConsolationCount] = useState([])
    const [position, setPosition] = useState({
        winnersArray: [],
        consolationArray: [],
    })
    const [mergrWinners, setMergeWinners] = useState(null)




    const Navigate = useNavigate()
    const location = useLocation();
    const dispatch = useDispatch();
    const id = useParams();
    let xy = Object.keys(id);
    const n = id[xy].lastIndexOf("/");
    const selectedId = id[xy].substring(n + 1);
    const [reqData, setReqData] = useState({
        RewardID: selectedId,
        DepartmentID: null,
        position: renderButton ? 1 : 2,
        createdOn: null,
        lastUpdatedOn: null,
        // AppraisalCycleId: Number(selectedId),
        dataTableRequest: {
            // iDisplayLength: recordsSelected.value,
            iDisplayStart: 0,
            sSearch: searchParam,
            // sorting: sortParam,
            // sSortDir_0: sortType,
            isExport: false,
        },
    });

    useEffect(() => {
        document.title = `PeopleSol -  publish winner`;
        return () => {
            document.title = "PeopleSol";
        };
    }, []);
    const getOrdinalSuffix = (number) => {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return (
                <>
                    {number}
                    <sup>th</sup>
                    <span> Position</span>
                </>
            ); // 11th, 12th, 13th for "th"
        }

        switch (lastDigit) {
            case 1:
                return (
                    <>
                        {number}
                        <sup>st</sup>
                        <span> Position</span>

                    </>
                ); // 1st, 21st, 31st
            case 2:
                return (
                    <>
                        {number}
                        <sup>nd</sup>
                        <span> Position</span>

                    </>
                ); // 2nd, 22nd, 32nd
            case 3:
                return (
                    <>
                        {number}
                        <sup>rd</sup>
                        <span> Position</span>

                    </>
                ); // 3rd, 23rd, 33rd
            default:
                return (
                    <>
                        {number}
                        <sup>th</sup>
                        <span> Position</span>

                    </>
                ); // remaining "th"
        }
    };

    // const clickHandlerFunction = (id, type, empname) => {
    //     if (type !== "Consolation") {
    //         // Update winnersArray
    //         setPosition((prev) => ({
    //             ...prev, // Keep other state properties
    //             winnersArray: prev?.winnersArray?.map((item) =>
    //                 item.id === id
    //                     ? { ...item, name: empname } // Update matched item
    //                     : item // Keep other items unchanged
    //             ),
    //         }));
    //     } else {
    //         // Update consolationArray
    //         setPosition((prev) => ({
    //             ...prev, // Keep other state properties
    //             consolationArray: prev?.consolationArray?.map((item) =>
    //                 item.id === id
    //                     ? { ...item, name: empname } // Update matched item
    //                     : item // Keep other items unchanged
    //             ),
    //         }));
    //     }
    // };
    const checkIsPresent = (prev, employeeId) => {

        const isInWinners = prev.winnersArray.some((item) => item.employeeId === employeeId);
        const isInConsolation = prev.consolationArray.some((item) => item.employeeId === employeeId);
        console.log("fsdkjfhlkjehjkbkf000000000=====", isInWinners, isInConsolation)
        return !isInWinners && !isInConsolation
    }

    const clickHandlerFunction = (id, type, empname, employeeId) => {

        console.log("here is define Type-----------", type)
        console.log("here is define empName-----------", empname)
        console.log("here is define employeeId-----------", employeeId)
        console.log("here is define id-----------", id)

        setPosition((prev) => {
            if (checkIsPresent(prev, employeeId)) {

                if (type !== "0") {


                    return ({
                        ...prev,
                        winnersArray: prev?.winnersArray?.map((item) =>
                            item.position === type
                                ? { ...item, name: empname, employeeId: employeeId }
                                : item
                        ),
                    })
                } else if (type === "0") {


                    const updatedConsolationArray = prev?.consolationArray.map((item, index) => {
                        if (item.name === "" && index === prev.consolationArray.findIndex(i => i.name === "")) {
                            return { ...item, name: empname, employeeId: employeeId }; // update first blank object
                        }
                        return item; // remaining object set as it is
                    });

                    return {
                        ...prev,
                        consolationArray: updatedConsolationArray,
                    };

                    // return ({
                    //     ...prev,
                    //     consolationArray: prev?.consolationArray?.map((item) =>
                    //         item.position === type
                    //             ? { ...item, name: empname, employeeId:employeeId }
                    //             : item
                    //     ),
                    // })
                }
            }
            else {


                dispatch(
                    showToast({
                        text: `This employee is already present in the list.`,
                        severity: "error",
                    })
                );
                return ({ ...prev })
            }
        });
        console.log("this is set position ppppppppppppppppppppppppp", position)
    };


    const handleRemoveName = (employeeId) => {
        setPosition((prev) => ({
            ...prev,
            consolationArray: prev?.consolationArray?.map((item, idx) =>
                item.employeeId === employeeId ? { ...item, id: "", employeeId: "", name: "" } : item
            ),
        }));
    };

    const handleRemoveWinnerName = (employeeId) => {
        setPosition((prev) => ({
            ...prev,
            winnersArray: prev?.winnersArray?.map((item, idx) =>
                item.employeeId === employeeId ? { ...item, id: "", employeeId: "", name: "" } : item
            ),
        }));
    };

    const getMoreActions = (actionList, empname, positionState, employeeId) => {
        let returnActionArray = [];
        // debugger
        console.log("this is position initial state", positionState)
        if (actionList.length > 0) {
            actionList.map((action) => {
                // if (
                //     action.type !== "Approve" &&
                //     action.type !== "Reject" &&
                //     action.type !== "Hold" &&
                //     action.type !== "Cancel"
                // ) {

                if (action.type === "0") {
                    if (!returnActionArray.some((item) => item.actionTitle === "Consolation")) {
                        returnActionArray.push({
                            actionTitle: "Consolation",
                            clickAction: () =>
                                clickHandlerFunction(action?.id, action?.type, empname, employeeId),
                        });
                    }
                } else {
                    // if type not 0
                    returnActionArray.push({
                        actionTitle: getOrdinalSuffix(action.type),
                        clickAction: () =>
                            clickHandlerFunction(action?.id, action?.type, empname, employeeId),
                    });
                }

                // returnActionArray.push({
                //     actionTitle: action.type !== "0" ? getOrdinalSuffix(action.type) : "Consolation",
                //     clickAction:
                //         () =>
                //             clickHandlerFunction(action?.id, action?.type, empname, employeeId),
                // });
                // }
            });
        }
        return returnActionArray.length > 0 ? returnActionArray : null;
    };

    const getListData = async () => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await postRequest(
                `RewardReview/DepartmentList`,
                reqData
            );
            if (result.data) {
                let tempData = [];
                // let tempData1 = [];
                // let tempData2 = [];
                setWinnerCount(() =>
                    result?.data?.data[0]?.actions?.filter((item) => item.type !== "0")
                        .map((item) => ({
                            position: item.type,
                            id: item.id,
                            name: "",
                            employeeId: "",
                        })),
                )
                setConsolationCount(() =>
                    result?.data?.data[0]?.actions?.filter((item) => item.type === "0")
                        .map((item) => ({
                            position: item.type,
                            id: item.id,
                            name: "",
                            employeeId: "",
                        }))
                )


                result.data.data.map((item) => {
                    return tempData.push({
                        ...item,
                        id: item.item.id,
                        employeeName: item.item.name,
                        department: item.item.department,
                        avgOfAchievedUnits: item.item.unit,
                        AvgPercentOfAchievedUnits: item.item.unitpercentage,
                        AvgOfAchievedValue: item.item.value,
                        AvgPercentOfAchievedValue: item.item.valuepercentage,
                        moreActions: getMoreActions(item?.actions, item.item.name, position, item.item.employeeId),


                    });

                });
                setOriginalData(tempData);



                const finalArray = tempData.map(
                    ({
                        id,
                        employeeName,
                        department,
                        avgOfAchievedUnits,
                        AvgPercentOfAchievedUnits,
                        AvgOfAchievedValue,
                        AvgPercentOfAchievedValue,

                    }) => ({
                        id,
                        employeeName,
                        department,
                        avgOfAchievedUnits,
                        AvgPercentOfAchievedUnits,
                        AvgOfAchievedValue,
                        AvgPercentOfAchievedValue,

                    })
                );
                setTbodyData(finalArray);



                // setCount(result.data.summary.allCount);
                // calculateTotalPages(result.data.summary.allCount);
            } else {
                setTbodyData(false);

            }
        } catch (error) {
            setTbodyData(false);

            // setCount(0);
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    };

    console.log("this is our winnersCount !!!!!!!!!!!!!!!!!!!!!!!", winnerCount)
    console.log("this is our consolationCount!!!!!!!!!!!!!!!!!!!", consolationCount)

    const clearPositionState = () => {
        setPosition({
            winnersArray: [],
            consolationArray: [],
        });
    };

    const createFunction = async (values) => {
        console.log("this is payload of reviewsubmit", values);
        try {
            setLoading(true);
            const result = await postRequest("RewardReview/WinnerPosition", values);
            if (result) {
                dispatch(
                    showToast({ text: "Successfully Created", severity: "success" })
                );
            }
            clearPositionState();
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.data?.response ?? "Some error occured",
                    severity: "error",
                })
            );
        } finally {
            setLoading(false);

            //   navigate(-1);
            //   getTargetDetail();
            getListData()
        }
    };

    const handleSubmit = async (values) => {

        // const SubmitRewardsData = {
        //     id: selectedId,
        const winnersArray = position?.winnersArray?.map((item, ind) => ({
            Position: Number(item.position),
            EmployeeId: item.employeeId,
            IsConsolation: false,
            IsDepartmentWinner: true,
            DepartmentId: selectedDepartment.length && selectedDepartment[0].value,
        }))
        const consolationArray = position?.consolationArray?.map((item, ind) => ({
            Position: Number(item.position),
            EmployeeId: item.employeeId,
            IsConsolation: true,
            IsDepartmentWinner: true,
            DepartmentId: selectedDepartment.length && selectedDepartment[0].value,
        }))

        const RewardsTotalPositionHolders = [...winnersArray, ...consolationArray]

        const SubmitRewardsData = {
            id: selectedId,
            RewardsPositionHolders: RewardsTotalPositionHolders.filter(item => item.EmployeeId),
        }

        createFunction(SubmitRewardsData);
    }

    // useEffect(() => {
    //     setMergeWinners([...position.winnersArray, ...position.consolationArray]);
    // }, [position]);

    // console.log("this is merge winners", mergrWinners)
    // const changeTableDataByPosition = () => {
    //     setRenderButton(!renderButton)
    //     setReqData((prev) => ({
    //         ...prev,
    //         position: renderButton ? 1 : 2,
    //     }))
    //     // getListData();
    // }


    useEffect(() => {
        // if ((winnerCount?.length === 0 || consolationCount?.length === 0))
        setPosition((prev) => {
            if (prev?.winnersArray?.length === winnerCount?.length && prev?.consolationArray?.length === consolationCount?.length)
                return { ...prev }
            else
                return {
                    winnersArray: Array.isArray(winnerCount) ? winnerCount
                        .map((item) => ({
                            ...item,
                            position: item.position,
                            id: item.id,
                            name: "",
                            employeeId: "",
                        })) : [],
                    consolationArray: Array.isArray(consolationCount) ? consolationCount
                        .map((item) => ({
                            position: item.position,
                            id: item.id,
                            name: "",
                            employeeId: "",
                        })) : [],
                }
        })

    }, [winnerCount, consolationCount])

    useEffect(() => {
        setReqData((prev) => ({
            ...prev,
            position: renderButton ? 1 : 2,
        }))
    }, [renderButton])


    console.log("this is department winners 555555555555555555 object", tbodyData)



    console.log("this is publish winner data",)
    console.log("this is publish winner data", originalData)


    // const sortUpdated = (heading) => {
    //     if (heading === sortParam) {
    //         if (sortType === "ascending") {
    //             setSortType("descending");
    //             setReqData({
    //                 ...reqData,
    //                 dataTableRequest: {
    //                     ...reqData.dataTableRequest,
    //                     sSortDir_0: "descending",
    //                 },
    //             });
    //         } else {
    //             setSortType("ascending");
    //             setReqData({
    //                 ...reqData,
    //                 dataTableRequest: {
    //                     ...reqData.dataTableRequest,
    //                     sSortDir_0: "ascending",
    //                 },
    //             });
    //         }
    //     } else {
    //         setSortType("ascending");
    //         setSortParam(heading);
    //         if (heading === "employeeName") heading = "nameWithPersonNumber";
    //         else if (heading === "reviewSubmitted") heading = "reviewDate";
    //         else if (heading === "location") heading = "locationName";
    //         // else if (heading === "travelMode") heading = "mode";
    //         setReqData({
    //             ...reqData,
    //             dataTableRequest: {
    //                 ...reqData.dataTableRequest,
    //                 sorting: heading,
    //                 sSortDir_0: "ascending",
    //             },
    //         });
    //     }
    // };
    // const applyFilters = () => {
    //     setReqData({
    //         ...reqData,
    //         EmployeeID: selectedEmployeeList[0]?.value,
    //         DepartmentID: selectedDepartmentList[0]?.value,
    //         LocationID: selectedLocationList[0]?.value,
    //         status: selectedStatusList[0]?.value,
    //         createdOn: createdOnStartDate
    //             ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${createdOnEndDate
    //                 ? format(createdOnEndDate, "dd/MM/yyyy")
    //                 : format(createdOnStartDate, "dd/MM/yyyy")
    //             }`
    //             : null,
    //         lastUpdatedOn: lastUpdatedOnStartDate
    //             ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${lastUpdatedOnEndDate
    //                 ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
    //                 : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
    //             }`
    //             : null,
    //     });
    //     setFilterSearchToogle((prev) => !prev);
    // };
    // const resetFilters = () => {
    //     setCreatedOnStartDate(false);
    //     setCreatedOnEndDate(false);
    //     setLastUpdatedOnStartDate(false);
    //     setLastUpdatedOnEndDate(false);
    //     setReqData({
    //         ...reqData,
    //         createdOn: null,
    //         lastUpdatedOn: null,
    //         EmployeeID: null,
    //         LocationID: null,
    //         status: null,
    //         DepartmentID: null,
    //     });
    //     setFilterSearchToogle((prev) => !prev);
    //     setSelectedLocationList([]);
    //     setSelectedStatusList([]);
    //     setSelectedDepartmentList([]);
    //     setSelectedEmployeeList(null);
    // };
    // const addToSelectedRows = (id) => {
    //     let arr = [...selectedRows];
    //     if (arr.includes(id)) {
    //         arr.splice(arr.indexOf(id), 1);
    //         setSelectedRows(arr);
    //         return;
    //     }
    //     arr.push(id);
    //     setSelectedRows(arr);
    // };
    // const selectAll = () => {
    //     if (selectedRows?.length > 0) {
    //         setSelectedRows([]);
    //     } else {
    //         let arr = [];
    //         tbodyData?.map((item) => {
    //             if (
    //                 originalData.find((x) => (x.id ?? x.Id) === (item.id ?? item.Id))
    //                     .actions?.length > 1
    //             )
    //                 arr.push(item.Id ?? item.id);
    //             return null;
    //         });
    //         setSelectedRows(arr);
    //     }
    // };


    const getDepartment = async () => {
        try {
            const result = await getRequest(`RewardReview/GetDepartments?RewardID=${selectedId}`);
            let listData = [];
            result.data.results.map((item) => {
                listData.push({ label: item.text, value: item.id });
                return null;
            });
            setOptionDepartment(listData);
            setSelectedDepartment([listData[0]])
        } catch (error) {
        } finally {
        }
    };

    console.log("selected Department", selectedDepartment)


    useEffect(() => {
        
        getDepartment();
       
   }, [])

    useEffect(() => {
        if (
            selectedId &&
            selectedDepartment.length &&
            location.pathname.toLowerCase().includes("quantitative/topperfomer")
        ) {
            setReqData((prev) => ({
                ...prev,
                DepartmentID: selectedDepartment[0]?.value,

            }));
        }
    }, [selectedId, selectedDepartment, location.pathname]);

    useEffect(() => {
        if (reqData.DepartmentID && reqData.position) {
            // if (location.pathname.toLowerCase().includes("quantitative/topperfomer")) {
            getListData();
        }
    }, [reqData]);

    const handleChange = () => {
        setRenderButton((prev) => !prev)
        // setPosition((prev)=>({
        //     ...prev,
        //     winnersArray:  prev.winnersArray.map((item) => ({...item})),
        //     consolationArray:  prev.consolationArray.map((item) => ({...item}))
        // }))
    }

    console.log("this is position Object00000000000000000000000000", position)
    // useEffect(() => {
    //     if (selectedDepartment.length > 0) {
    //         getLocation()
    //     }
    // }, [selectedDepartment])

    // useEffect(() => {
    //     if (
    //         location.pathname
    //             .toLowerCase()
    //             .includes("quantitative/topperfomer")
    //     )
    //         getListData();

    // }, [location.pathname, reqData]);
    // console.log("object tbodyDataItem", tbodyDataItem)

    return (
        <div className="bgCreate publishwinner">
            <div
                className="modulecontGoal"
                style={{
                    padding: "24px",
                    boxShadow: "none",
                    height: "calc(100% - 89px)",
                    overflow: "auto",
                }}
            >
                <div
                    className="fullTableContainer"
                    style={{
                        // boxShadow: "0px 3px 10px rgb(0 0 0 / 6%)",
                        height: "calc(100% - 88px)",
                    }}
                >

                    <div className="publishRewardSelectCustom tableHeaderContainer">
                        <div style={{ display: "flex" }}>

                            <SelectCustom

                                sty1={{
                                    background: "#EDF8F9",
                                    minWidth: "172px",
                                }}
                                // onDropdownOpen={() => {
                                //     getDepartment();
                                // }}
                                options={optionDepartment}
                                values={selectedDepartment}
                                placeholder="Department"
                                onChange={(value) => {
                                    setSelectedDepartment(value);
                                }}
                            />
                            {/* <SelectCustom

                                sty1={{
                                    background: "#EDF8F9 !important",
                                    minWidth: "172px",
                                }}
                                // onDropdownOpen={() => {
                                //     getLocation();
                                // }}
                                options={optionLocation}
                                values={selectedLocation}
                                placeholder="Department"
                                onChange={(value) => {
                                    setSelectedLocaton(value);
                                }}
                            /> */}

                        </div>
                        <div className="search-box" title="Search">
                            <input
                                className="search-text"
                                type="text"
                                placeholder="Search"
                                onChange={(e) => setSearchParam(e.target.value)}
                            />
                            <a className="search-btn" title="Search">
                                <svg
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                                        fill="#1296B0"
                                    />
                                </svg>
                            </a>
                        </div>


                    </div>

                    {selectedDepartment.length ?
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", overflow: "auto" }}>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
                                gap: "28px",
                                width: "100%",
                                padding: "0px 20px 20px 20px",
                                // backgroundColor: "#d4f2f1",
                                borderRadius: "10px",
                                marginTop: "20px",
                            }}>
                                <div style={{ gridColumn: "span 3 / span 3", boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 10px", borderRadius: "12px", backgroundColor: "#FAF9F8" }}>
                                    <div className="tableHeaderContainer" style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 10px", color: "#1296B0", marginBottom: "10px" }}>
                                        Winners
                                    </div>
                                    {loading ? (
                                        <div className="noDataFound">
                                            <Loader />
                                        </div>
                                    ) : position?.winnersArray?.map((item, index) => (
                                        <div key={index}>
                                            <LabelCustom labelName={getOrdinalSuffix(item.position)} mandatory={true} />
                                            <div style={{ position: "relative", cursor: "Pointer" }}>
                                                <InputCustom
                                                    name="text"
                                                    style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                                                    formikWidth="100%"
                                                    value={item.name}

                                                />
                                                <img
                                                    className="publishWinnerCrossimg"
                                                    src={publishwinnercrossimg}
                                                    alt="Cross img"
                                                    onClick={() => handleRemoveWinnerName(item.employeeId)} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ gridColumn: "span 6 / span 6", boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 10px", borderRadius: "12px", }}>

                                    <div className="tableHeaderContainer" style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 10px", color: "#1296B0", marginBottom: "10px" }}>
                                        {renderButton ? "First Position Holder from Each Department" : "Second Position Holder from Each Department"}
                                    </div>
                                    {loading ? (
                                        <div className="noDataFound">
                                            <Loader />
                                        </div>
                                    ) : (
                                        <>
                                            <PublishWinnerDepartmentTable

                                                // selectAll={selectAll}
                                                onlyTable={true}
                                                tableFilterName="HrdeskFilter"
                                                tbodyData={tbodyData}
                                                // headerAction={sortUpdated}
                                                // sortParam={sortParam}
                                                // sortType={sortType}
                                                // selectedRows={selectedRows}
                                                // addToSelectedRows={addToSelectedRows}
                                                originalData={originalData}
                                                containStatus={true}
                                                containTat={true}
                                            />

                                            {renderButton ?

                                                (<div className='publishwinnertabletoglebutton'
                                                    style={{ cursor: "Pointer" }}
                                                    onClick={() => handleChange()} >
                                                    <p>Second Position Holder from Each Department</p>
                                                </div>) :
                                                (<div className='publishwinnertabletoglebutton'
                                                    style={{ cursor: "Pointer" }}
                                                    onClick={() => handleChange()} >
                                                    <p>First Position Holder from Each Department</p>
                                                </div>)
                                            }
                                        </>
                                    )}
                                </div>
                                <div style={{ gridColumn: "span 3 / span 3", boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 10px", borderRadius: "12px", backgroundColor: "#FAF9F8" }}>
                                    <div className="tableHeaderContainer" style={{ boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 10px", color: "#1296B0", marginBottom: "10px" }}>
                                        consolation
                                    </div>
                                    {
                                        loading ? (
                                            <div className="noDataFound">
                                                <Loader />
                                            </div>
                                        ) :
                                            position?.consolationArray?.map((item, index) => (
                                                <div key={index}>
                                                    <LabelCustom labelName={`Consolation #${index + 1}`} mandatory={true} />
                                                    <div style={{ position: "relative", cursor: "Pointer" }}>
                                                        <InputCustom
                                                            name="text"
                                                            style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                                                            formikWidth="100%"
                                                            value={item.name}
                                                        />
                                                        <img
                                                            className="publishWinnerCrossimg"
                                                            src={publishwinnercrossimg}
                                                            alt="Cross img"
                                                            onClick={() => handleRemoveName(item.employeeId)} />
                                                    </div>
                                                </div>
                                            ))
                                        // (
                                        //     <div >
                                        //         <LabelCustom labelName={"1st Position"} mandatory={true} />
                                        //         <div style={{ position: "relative" }}>
                                        //             <InputCustom
                                        //                 name="text"
                                        //                 style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                                        //                 formikWidth="100%"
                                        //             // value={messageInput}
                                        //             // onChange={(e) => {
                                        //             //   setMessageInput(e.target.value);
                                        //             //   setCursorPosition(
                                        //             //     e.target.selectionStart +
                                        //             //       e.target.value.length -
                                        //             //       messageInput.length
                                        //             //   );
                                        //             // }}
                                        //             />
                                        //             <img className='publishWinnerCrossimg' src={publishwinnercrossimg} alt="Cross img" />
                                        //         </div>
                                        //     </div>
                                        // )
                                    }
                                </div>
                            </div>

                        </div>
                        :
                        <div className="noDataFound">
                            {/* <Loader /> */}
                        </div>
                    }
                </div>
                {position?.winnersArray?.length > 0 &&
                    (<div className="rewardspublishwinnerfooter">
                        <div>
                            <div
                                onClick={() => Navigate(-1)}
                                className={`button secondaryButton`}
                                style={{ border: "1px #1190A9 solid" }}
                            >
                                Cancel
                            </div>
                        </div>
                        <div>
                            <button
                                className="button primaryButton"
                                onClick={handleSubmit}

                            >
                                Announce Winners
                            </button>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default PublishWinnerSubTabDepartment

import { IconButton } from '@mui/material'
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { LabelCustom } from '../../../Components/FormComponent/LabelCustom';
import { InputCustomFormik } from '../../../Components/FormComponent/InputCustomFormik';
import { useEffect } from 'react';

const HomeLoanComp = ({ crossHandler }) => {

    useEffect(() => {
        document.title = `PeopleSol -  Home Loan`;
        return () => {
            document.title = 'PeopleSol';
        };
    }, []);

    return (
        <div className="extendedParticularDetails">
            <div className="extendedHeaderContainer" style={{ display: "flex", justifyContent: 'flex-end' }}>
                {/* <IconButton>
                    <label className='returnIconContainer cursor' onClick={crossHandler}>x</label>
                    </IconButton> */}
            </div>
            <div className="extendedFormContainer">
                <div className="extendedFormComp">
                    <LabelCustom labelName={"Interest paid on Home Loan"} />
                    <div className="extendedInputContainer" style={{ flexDirection: 'column' }}>
                        <InputCustomFormik type={"number"} style={{ border: '1px solid #E6E6E6' }} placeholder="Enter Interest" name={"HouseLoanInterest"} />
                    </div>
                </div>
                {/* <div className="extendedFormComp">
                        <LabelCustom labelName={"Name of the Lender"} mandatory={true} />
                        <div className="extendedInputContainer">
                            <InputCustomFormik style={{border:'1px solid #E6E6E6'}} placeholder="Enter Name" name={"TenantName"}  />
                        </div>
                    </div>

                    <div className="extendedFormComp">
                        <LabelCustom labelName={"Lender PAN"} mandatory={true} />
                        <div className="extendedInputContainer" style={{flexDirection: 'column'}}>
                            <InputCustomFormik style={{border:'1px solid #E6E6E6'}} placeholder="Enter PAN no." name={"TenantPan"}  />
                        </div>
                    </div> */}
            </div>
        </div>
    )
}

export { HomeLoanComp }

import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { getRequest } from "../../../Services/axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { useSelector } from "react-redux";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaCustom } from "../../../Components/FormComponent/TextAreaCustom";
import noemployees from "../../../Assets/no-employees.svg";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";

function CreateRewardEligibleAllEmpDeptWise({ employeeDeptWise, setEmployeeDeptWise, allSelectedEmpDeptWise, setAllSelectedEmpDeptWise, allSelectedLocations, allSelectedDepartmentList }) {
    // const [employeeDeptWise, setEmployeeDeptWise] = useState([]);
    // const [allSelectedEmpDeptWise, setAllSelectedEmpDeptWise] = useState([]);
    const [searchEmployeeList, setSearchEmployeeList] = useState(false);
    const [locationId, setLocationId] = useState([])
    const [deptId, setDeptId] = useState([])

    const [searchInput, setSearchInput] = useState("");

    const { LoggedInUser } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const location = useLocation();
    const selectedId = location?.state?.id;

    useEffect(() => {

        setLocationId(() => {
            const locationId = allSelectedLocations.map((item) => item.locationId);
            const arr = [...locationId]
            return arr;
        }
        )
        setDeptId(() => {
            const deptId = allSelectedDepartmentList.map((item) => item.deptId);
            const arr = [...deptId]
            return arr;
        }
        )

    }, [allSelectedLocations, allSelectedDepartmentList])

    //    const locationId = allSelectedLocations.map((item)=>item.locationId);
    //     console.log("sandeep Location id", locationId.join(","))
    //     const deptId =  allSelectedDepartmentList.map((item)=>item.deptId)
    //     console.log("sandeep department id", deptId.join(","))

    function selectAllAvailableEmployee() {
        const allChecked = employeeDeptWise.every(item => item.isChecked);

        const updatedEmployeeList = employeeDeptWise.map(item => ({
            ...item,
            isChecked: !allChecked
        }));

        setEmployeeDeptWise(updatedEmployeeList);
    }

    function selectAllSelectedEmployee(e) {
        const allChecked = allSelectedEmpDeptWise.every(item => item.isChecked);

        const updatedEmployeeList = allSelectedEmpDeptWise.map(item => ({
            ...item,
            isChecked: !allChecked
        }));

        setAllSelectedEmpDeptWise(updatedEmployeeList);
    }

    let listData = [];

    const getEmployeeData = async () => {
        try {

            const result = await getRequest(`Reward_ConfigController/GetEmployeesByDepartment?deptIDs=${deptId?.join(",")}&locationIDs=${locationId?.join(",")}`);
            // console.log(result);
            result?.data?.results?.map((item) => {
                return listData.push({ empId: item.id, empName: item.text, isChecked: false });
            });
            setEmployeeDeptWise(listData);

            console.log("sandeep rewards employee list", employeeDeptWise);

            // setAllEmployeeList(listData);
        } catch (error) {
            dispatch(
                showToast({
                    text: "some error occured in fetching data",
                    severity: "error",
                })
            );
        } finally {
        }
    };

    function getName(name) {
        if (name) {
            let index = name?.indexOf("(");
            let newName = index > -1 ? name.substring(0, index) : name;
            return newName;
        }
        return null;
    }

    function removeEmpList() {
        if (searchEmployeeList.length > 0) {

            const removedEmpFromSearchList = searchEmployeeList.filter(item => item.isChecked);
            const removedEmpFromEmployeeList = employeeDeptWise.filter(item => item.isChecked);

            const combinedEmployees = [...removedEmpFromEmployeeList, ...removedEmpFromSearchList];

            const uniqueEmployees = combinedEmployees.reduce((acc, current) => {
                const exists = acc.find(item => item.empId === current.empId);
                if (!exists) {
                    acc.push(current);
                }
                return acc;
            }, []);

            setAllSelectedEmpDeptWise((prev) => {
                return [...prev, ...uniqueEmployees];
            });
            const remainEmpList = searchEmployeeList.filter(item => !item.isChecked);
            setSearchEmployeeList(remainEmpList);
            const remainEmpListMainArray = employeeDeptWise.filter(item => !item.isChecked);
            setEmployeeDeptWise(remainEmpListMainArray);
            setSearchInput("");
            setSearchEmployeeList(false)
            console.log("remove search", searchInput)

        } else {
            const removedEmpList = employeeDeptWise.filter(item => item.isChecked);
            setAllSelectedEmpDeptWise((prev) => {
                const arr = [...prev, ...removedEmpList];
                return arr;
            });
            const remainEmpList_1 = employeeDeptWise.filter(item => !item.isChecked);
            setEmployeeDeptWise(remainEmpList_1);

        }
    }

    function restoreEmpList() {
        const restoreEmployeeList = allSelectedEmpDeptWise.filter(item => item.isChecked);
        setEmployeeDeptWise((prev) => {
            const arr = [...prev, ...restoreEmployeeList];
            return arr;
        });
        const remainEmpList = allSelectedEmpDeptWise.filter(item => !item.isChecked);
        setAllSelectedEmpDeptWise(remainEmpList);
    }


    const searchEmployeeFromList = (e) => {
        let input = e.target.value;
        setSearchInput(input)
        console.log("search input", searchInput)
        if (input === "") {
            setSearchEmployeeList(false)
        } else {
            let tempEmpList = employeeDeptWise.filter((item) =>
                item?.empName?.toLowerCase().includes(input.toLowerCase())
            );
            setSearchEmployeeList(tempEmpList);
            console.log(searchEmployeeList);
        }
    };

    useEffect(() => {
        if (locationId?.length > 0 && deptId?.length > 0)
            getEmployeeData();
    }, [locationId, deptId]);

    return (
        <div className="formcustom-employee">
            <div className="officeLocationEmployeeWrapper">
                <div className="officeLocationEmployeeBox">
                    <p className="rewards-filter-name">Employees</p>
                    <div className="officeLocationEmployeeList">
                        {/* <hr/> */}
                        <div className="officeLocationEmployeeListBox">
                            <div className="availableEmployeeList">
                                <div className="availableEmpBody">
                                    <div className="availableEmpHeader">
                                        <div className="selectAllCheckbox">
                                            <h6>Eligible Employees ({employeeDeptWise?.length})</h6>
                                            <input
                                                type="checkbox"
                                                className="selectInput large"
                                                onClick={selectAllAvailableEmployee}
                                                checked={employeeDeptWise.length > 0 && employeeDeptWise.every(emp => emp.isChecked)}
                                            />
                                        </div>
                                        {/* <h4>Available ({employeeDeptWise?.length})</h4> */}
                                    </div>
                                    <div className="empSearchContainer availableSearch">
                                        <input
                                            type="text"
                                            placeholder="Search By Name and ID"
                                            size={20}
                                            value={searchInput}
                                            onChange={searchEmployeeFromList}
                                            className="searchinputbox"
                                        />
                                    </div>
                                    <ul>
                                        {
                                            Array?.isArray(searchEmployeeList) ? searchEmployeeList?.map((d, i) => {
                                                return (
                                                    <li>
                                                        <span className="getEmployeeName">
                                                            {getName(d?.empName)}
                                                        </span>
                                                        <span>
                                                            <input
                                                                className="selectInput large"
                                                                type="checkbox"
                                                                checked={d?.isChecked}
                                                                onClick={() => {
                                                                    setSearchEmployeeList(prev => {
                                                                        const arr = [...prev];
                                                                        arr[i].isChecked = !arr[i]?.isChecked
                                                                        return arr;
                                                                    })

                                                                }}
                                                            // onChange={() => {
                                                            //   setEmployeeDeptWise((prev) =>

                                                            //     prev?.map((item) =>
                                                            //       item?.empId === d?.empId
                                                            //         ? { ...item, isChecked: !item.isChecked }
                                                            //         : item
                                                            //     )
                                                            //   );
                                                            // }}
                                                            />
                                                        </span>
                                                    </li>
                                                );
                                            }) :
                                                employeeDeptWise?.map((d, i) => {
                                                    return (
                                                        <li>
                                                            <span className="getEmployeeName">
                                                                {getName(d?.empName)}
                                                            </span>
                                                            <span>
                                                                <input
                                                                    className="selectInput large"
                                                                    type="checkbox"
                                                                    checked={d?.isChecked}
                                                                    onClick={() => {
                                                                        setEmployeeDeptWise(prev => {
                                                                            const arr = [...prev];
                                                                            arr[i].isChecked = !arr[i]?.isChecked
                                                                            return arr;
                                                                        })

                                                                    }}
                                                                // onChange={() => {
                                                                //   setEmployeeDeptWise((prev) =>

                                                                //     prev?.map((item) =>
                                                                //       item?.empId === d?.empId
                                                                //         ? { ...item, isChecked: !item.isChecked }
                                                                //         : item
                                                                //     )
                                                                //   );
                                                                // }}
                                                                />
                                                            </span>
                                                        </li>
                                                    );
                                                })}
                                    </ul>
                                    <div
                                        className="button-flex-align"
                                        style={{ 
                                            backgroundColor: employeeDeptWise.filter(item => item.isChecked).length <= 0 ? "#BFBFBF" : "#F71D1D", 
                                       }}
                                       disabled={employeeDeptWise.filter(item => item.isChecked).length <= 0}
                                        
                                        onClick={() => removeEmpList()}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M9 2l1 1h4l1-1h3v2H6V2h3zM4 7h16M10 11v6M14 11v6m-7 2a1 1 0 001 1h10a1 1 0 001-1V7H7v12z"
                                            />
                                        </svg>
                                        Remove{" "}
                                        {
                                            employeeDeptWise?.length > 0 &&
                                            `(${employeeDeptWise.filter(item => item.isChecked).length})`
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="officeLocationEmployeeListBox">
                            <div className="empSearchContainer selectedSearch">
                                {/* <input
                  type="text"
                  placeholder="Search Selected"
                  size={20}
                  onChange={searchSelectedEmployeeFromList}
                /> */}
                            </div>
                            <div className="selectedEmplList">
                                <div className="selectedEmpBody">
                                    <div className="selectedEmpHeader">
                                        <div className="selectAllCheckbox">
                                            <h4>Removed Employees ({allSelectedEmpDeptWise?.length})</h4>
                                            {allSelectedEmpDeptWise?.length > 0 ? (
                                                <input
                                                    type="checkbox"
                                                    className="selectInput large"
                                                    onChange={selectAllSelectedEmployee}
                                                    checked={allSelectedEmpDeptWise.length > 0 && allSelectedEmpDeptWise.every(emp => emp.isChecked)}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    {allSelectedEmpDeptWise?.length === 0 ? (
                                        <div className="no-employees">
                                            <img src={noemployees} alt="no-employees" />
                                            {/* <div>{selectedAllEmplList.length}</div> */}
                                        </div>
                                    ) : (
                                        <>
                                            <ul>
                                                {allSelectedEmpDeptWise?.length > 0 &&
                                                    allSelectedEmpDeptWise?.map((d, i) => {
                                                        return (
                                                            <li style={{ justifyContent: "space-between" }}>
                                                                <span className="getEmployeeName">{getName(d?.empName)}</span>
                                                                <span>
                                                                    <input
                                                                        className="selectInput large"
                                                                        type="checkbox"
                                                                        checked={d?.isChecked}
                                                                        onClick={() => {
                                                                            setAllSelectedEmpDeptWise((prev) => {
                                                                                const arr = [...prev];
                                                                                arr[i].isChecked = !arr[i].isChecked;
                                                                                return arr;
                                                                            })
                                                                        }}
                                                                    // onChange={() => {
                                                                    //   setAllSelectedEmpDeptWise((prev) =>

                                                                    //     prev?.map((item) =>
                                                                    //       item?.empId === d?.empId
                                                                    //         ? { ...item, isChecked: !item.isChecked }
                                                                    //         : item
                                                                    //     )
                                                                    //   );
                                                                    // }}
                                                                    />
                                                                </span>{" "}
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                            <div
                                                className="button-flex-align restorebutton"
                                                disabled={allSelectedEmpDeptWise.length <= 0}
                                                onClick={restoreEmpList}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x="0px"
                                                    y="0px"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 30 30"
                                                    fill="#ffffff"
                                                // stroke="currentColor"
                                                >
                                                    <path d="M 15 3 A 1.0001 1.0001 0 1 0 15 5 C 20.534534 5 25 9.4654664 25 15 C 25 20.534534 20.534534 25 15 25 C 9.4654664 25 5 20.534534 5 15 C 5 12.650241 5.8085376 10.496834 7.1601562 8.7929688 L 9 11 L 11 4 L 4 5 L 5.8671875 7.2402344 C 4.086665 9.3350655 3 12.041787 3 15 C 3 21.615466 8.3845336 27 15 27 C 21.615466 27 27 21.615466 27 15 C 27 8.3845336 21.615466 3 15 3 z"></path>
                                                </svg>
                                                Restore
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <LabelCustom
                                disabled={allSelectedEmpDeptWise <= 0}
                                labelName={"Reason for Removed Employees"}
                                mandatory="true"
                            />
                            <TextAreaFormik
                                maxLength={500}
                                disabled={allSelectedEmpDeptWise <= 0}
                                name={`deptWiseEmployeesSection.deptWiseReasonEmployees`}
                                type={"text"}
                                placeholder={"type Here"}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CreateRewardEligibleAllEmpDeptWise;

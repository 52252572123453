/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import "../../Payroll/Components/PreparePayrollComp/CheckAttendance.css";
import { IconButton, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDeferredValue } from "react";
import { format } from "date-fns";
import { isLoading, showToast } from "../../../Features";
import {
  getRequest,
  postRequest,
  postRequestXlsx,
} from "../../../Services/axios";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import { DateRangePicker } from "../../../Components/FormComponent/DateRangePicker";
import { Loader } from "../../../Components/FormComponent/Loader";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";

import { DateRangePick } from "../../../Components/FormComponent/DateRangePick";

import { DatePicker } from "../../../Components/FormComponent/DatePicker";
import { DatePickerCustom } from "../../../Components/FormComponent/DatePickerCustom";
import Nodata from "../../../Assets/Nodata.svg";
import SelectCustomEmployeeCreate from "../../DropdownComponents/SelectCustomEmployeeCreate";

const noOfRecords = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];

const MonthlyAttendanceReports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tbodyData, setTbodyData] = useState(false);
  const { state } = useLocation();
  const editId = state?.id;
  const month = state?.month;
  const returnPage = () => {
    // navigate("/payroll/preparepayroll/ongoing/prepare", {
    //   state: { id: editId },
    // });
  };
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [dateFromApi, setDateFromApi] = useState("");
  const [dateStartDate, setDateStartDate] = useState(false);
  const [dateEndDate, setDateEndDate] = useState(false);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [DepartmentId, setDepartmentId] = useState([]);
  const [Location, setLocation] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [monthName, setMonthName] = useState("");

  const [selectedStatusList, setSelectedStatusList] = useState([]);

  const [reqData, setReqData] = useState({
    noticePeriod: null,
    createdOn: null,
    lastUpdatedOn: null,
    date: format(new Date(), "MMM/yyyy"),
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      isExport: false,
    },
  });
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getLocations = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationNames");
      let listData = [];
      result.data.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setLocationList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getDepartment = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetDepartmentss");
      let listData = [];
      result.data.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setDepartmentList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getLocations();
    getDepartment();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Monthly Attendance Report `;

    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  // Extract employee attendance data from the provided data
  const employeeData = tbodyData
    ? tbodyData?.groupedData?.map((item) => item)
    : null;
  // Generate an array of all the days in the month
  const inputDate = dateFromApi; // October 15, 2023
  const totalDaysInMonth = getTotalDaysInMonthFromDate(inputDate);
  const daysInMonth = [...Array(totalDaysInMonth).keys()]?.map(
    (day) => day + 2
  );
  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days?.[date?.getDay()];
  };
  const getMonthFromDate = (dateString) => {
    const date = new Date(dateString);
    const month = format(date, "MMM");
    return month;
  };
  function getTotalDaysInMonthFromDate(inputDate) {
    const date = new Date(inputDate);

    // Check if the input date is valid
    if (isNaN(date)) {
      return null; // Handle invalid date input
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Adding 1 to get one-based month index

    // Calculate the last day of the month by setting the day to 0 of the next month
    const lastDayOfMonth = new Date(year, month, 0);

    // Get the day component (the last day of the month)
    const totalDaysInMonth = lastDayOfMonth.getDate();

    return totalDaysInMonth;
  }

  useEffect(() => {
    document.title = `PeopleSol - Check Attendance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };

  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredText]);
  const applyFilters = () => {
    setReqData({
      ...reqData,

      employeeId: selectedEmployeeList ? selectedEmployeeList[0]?.value : null,
      date: dateStartDate ? `${format(dateStartDate, "MMM/yyyy")}` : null,
      DepartmentId: DepartmentId ? DepartmentId[0]?.value : null,
      Location: Location ? Location[0]?.value : null,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${createdOnEndDate
          ? format(createdOnEndDate, "dd/MM/yyyy")
          : format(createdOnStartDate, "dd/MM/yyyy")
        }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${lastUpdatedOnEndDate
          ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
          : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
        }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setSelectedEmployeeList(null);
    setSelectedStatusList([]);
    setDateStartDate(false);
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setLocation([]);
    setDepartmentId([]);
    setReqData({
      ...reqData,
      date: format(new Date(), "MMM/yyyy"),
      noticePeriod: null,
      createdOn: null,
      lastUpdatedOn: null,
      employeeId: null,
      Location: null,
      DepartmentId: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  // const exportTable = async () => {
  //   try {
  //     dispatch(isLoading(true));
  //     setLoading(true);
  //     const downloadReqData = {
  //       ...reqData,
  //       dataTableRequest: {
  //         iDisplayLength: 1000,
  //         iDisplayStart: 0,
  //         isExport: true,
  //       },
  //     };
  //     const result = await postRequest(
  //       `EmployeeAttendances/MonthlyAttendnanceExport`,
  //       downloadReqData
  //     );
  //     var element = document.createElement("a");
  //     element.setAttribute(
  //       "href",
  //       `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8,${encodeURI(
  //         result.data
  //       )}`
  //     );
  //     element.setAttribute("download", "Monthly Attendance List");
  //     document.body.appendChild(element);
  //     element.click();
  //     document.body.removeChild(element);
  //   } catch (error) {
  //     dispatch(
  //       showToast({ text: "Error in downloading file", severity: "error" })
  //     );
  //   } finally {
  //     dispatch(isLoading(false));
  //     setLoading(false);
  //   }
  // };
  const exportTable = async () => {
    try {
      // Set the request payload as per your requirements

      const downloadReqData = {
        ...reqData,
        dataTableRequest: {
          iDisplayLength: 1000,
          iDisplayStart: 0,
          isExport: true,
        },
      };
      // Make a POST request to the backend API
      const result = await postRequestXlsx(
        `EmployeeAttendances/MonthlyAttendnanceExport`,
        downloadReqData
      );
      // const response = await axios.post(
      //   `${process.env.REACT_APP_.BASE_URL}/EmployeeAttendances/MonthlyAttendnanceExport`,
      //   downloadReqData,
      //   { responseType: "blob" } // Important to handle binary response
      // );

      // Create a Blob from the response data
      const blob = new Blob([result.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a download link and click it programmatically
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Monthly Attendance.xlsx"); // Name the file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error while downloading the file:", error);
      // Handle errors (e.g., show a notification)
    }
  };
  // const exportTable = async () => {
  //   try {
  //     dispatch(isLoading(true));
  //     setLoading(true);
  //     const downloadReqData = {
  //       ...reqData,
  //       dataTableRequest: {
  //         iDisplayLength: 1000,
  //         iDisplayStart: 0,
  //         isExport: true,
  //       },
  //     };
  //     const result = await postRequest(
  //       `EmployeeAttendances/MonthlyAttendnanceExport`,
  //       downloadReqData
  //     );

  //     // const blob = await result.data.blob();
  //     const blob = new Blob([result.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const downloadUrl = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = downloadUrl;
  //     link.setAttribute("download", "Monthly Attendance.xlsx"); // or the filename from the Content-Disposition header
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   } catch (error) {
  //     dispatch(
  //       showToast({ text: "Error in downloading file", severity: "error" })
  //     );
  //   } finally {
  //     dispatch(isLoading(false));
  //     setLoading(false);
  //   }
  // };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("monthlyattendancereport"))
      getListData();
  }, [location.pathname, reqData]);
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `EmployeeAttendances/MonthlyAttendanceReport`,
        reqData
      );
      // if (result) {
      //   setTbodyData(result.data.summary);
      //   // setCount(result.data.summary.allCount);
      //   // calculateTotalPages(result.data.summary.allCount);
      // }
      setTbodyData(result.data.summary);
      setDateFromApi(
        reqData?.date ??
        result.data.summary?.groupedData[0]?.dateWiseAttendance[0]?.date
      );
      // setDateFromApi("2023-09-05T00:00:00");
    } catch (error) {
      //dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getAttendanceClass = (type) => {
    if (!type) return null;
    else if (type === "P") return "greenDayCell";
    else if (type === "A") return "redDayCell";
    else if (type === "L") return "orangeDayCell";
    else if (type === "HL") return "secondaryDayCell";
    else if (type === "H") return "pastelDayCell";
    else if (type === "W") return "pinkDayCell";
    else return "redDayCell";
  };
  return (
    <div className="moduleMainContainer" style={{ boxShadow: "none" }}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
            }`}
          style={{ zIndex: "999" }}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton
              title="Close"
              onClick={() => setFilterSearchToogle((prev) => !prev)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p> Employee </p>
              <SelectCustomEmployeeCreate
                selectedEmployeeList={selectedEmployeeList}
                setSelectedEmployeeList={setSelectedEmployeeList}
                values={selectedEmployeeList}
              />
            </div>
            <div className="filterContainer">
              <p>Department</p>
              <SelectCustom
                placeholder={" Select Department"}
                name="DepartmentId"
                options={departmentList}
                values={DepartmentId}
                onChange={(value) => {
                  setDepartmentId(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Location</p>
              <SelectCustom
                placeholder={" Select Location"}
                name="Location"
                options={locationList}
                values={Location}
                onChange={(value) => {
                  setLocation(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Date</p>

              <DatePickerCustom
                selected={dateStartDate}
                onChange={(date) => setDateStartDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div className="tableHeaderContainer" style={{ padding: "0 12px" }}>
        <div className="reportsheading">
          <div className="fontstyleDailyAttendance">
            {dateFromApi
              ? format(new Date(dateFromApi), "MMMM yyyy")
              : format(new Date(), "MMMM yyyy")}
          </div>
        </div>
        <div className="search-box" title="Search">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn" title="Search">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        {/* <Tooltip arrow title="Filter" placement="top">
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                stroke="#1296B0"
                strokeWidth="1.5"
              />
            </svg>
          </IconButton>
        </Tooltip> */}
        <Tooltip arrow title="Filter" placement="top">
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            {!filterSearchToogle ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Download" placement="top">
          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
      </div>
      <div className="legendContainer">
        <div className="legendHolder">
          <div className="legendColorHolder primaryLegend" />
          <p>Total Days (TD)</p>
        </div>
        <div className="legendHolder">
          <div className="legendColorHolder greenLegend" />
          <p>Present Days (P)</p>
        </div>
        <div className="legendHolder">
          <div className="legendColorHolder redLegend" />
          <p>Absent (A)</p>
        </div>
        <div className="legendHolder">
          <div className="legendColorHolder orangeLegend" />
          <p>Leave (L)</p>
        </div>
        <div className="legendHolder">
          <div className="legendColorHolder secondaryLegend" />
          <p>Half Day (HL)</p>
        </div>
        <div className="legendHolder">
          <div className="legendColorHolder pastelLegend" />
          <p>Holiday (H)</p>
        </div>
        <div className="legendHolder">
          <div className="legendColorHolder pinkLegend" />
          <p>Week Off (W)</p>
        </div>
        {/* <div className="legendHolder">
          <div className="legendColorHolder tealLegend" />
          <p>Payable Days (PD)</p>
        </div> */}
      </div>
      {loading ? (
        <Loader />
      ) : tbodyData?.groupedData ? (
        <>
          <table className="attendance-table" style={{ height: "500px" }}>
            <colgroup>
              <col style={{ width: "35px", minWidth: "35px" }} />
              <col style={{ width: "135px", minWidth: "135px" }} />
              <col style={{ width: "45px", minWidth: "45px" }} />
              <col style={{ width: "45px", minWidth: "45px" }} />
              <col style={{ width: "45px", minWidth: "45px" }} />
              <col style={{ width: "45px", minWidth: "45px" }} />
              <col style={{ width: "45px", minWidth: "45px" }} />
              <col style={{ width: "45px", minWidth: "45px" }} />
              <col style={{ width: "45px", minWidth: "45px" }} />
              <col style={{ width: "45px", minWidth: "45px" }} />
              {daysInMonth.map((day) => {
                return <col style={{ flex: 1 }} />;
              })}
            </colgroup>
            <thead>
              <tr style={{ position: "sticky", top: "0px", zIndex: 11 }}>
                <th
                  className="primaryBackground"
                  style={{ left: 0, zIndex: 1, position: "sticky" }}
                >
                  Sl. No.
                </th>
                <th
                  className="primaryBackground"
                  style={{
                    textAlign: "start",
                    position: "sticky",
                    left: "35px",
                    zIndex: 1,
                  }}
                >
                  Employee
                </th>
                <th
                  className="primaryBackground primaryHead"
                  style={{
                    left: "170px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  TD
                </th>
                <th
                  className="primaryBackground greenHead"
                  style={{
                    left: "215px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  P
                </th>
                <th
                  className="primaryBackground redHead"
                  style={{
                    left: "260px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  A
                </th>
                <th
                  className="primaryBackground orangeHead"
                  style={{
                    left: "305px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  L
                </th>
                <th
                  className="primaryBackground secondaryHead"
                  style={{
                    left: "350px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  HL
                </th>
                <th
                  className="primaryBackground pastelHead"
                  style={{
                    left: "395px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  H
                </th>
                <th
                  className="primaryBackground pinkHead"
                  style={{
                    left: "440px",
                    zIndex: 1,
                    position: "sticky",
                  }}
                >
                  W
                </th>
                {/* <th
                  className="primaryBackground tealHead"
                  style={{
                    left: "485px",
                    zIndex: 1,
                    position: "sticky",
                    boxShadow: "2px 0px 0px 0px var(--primary)",
                  }}
                >
                  PD
                </th> */}
                {dateFromApi &&
                  daysInMonth.map((day) => {
                    // const date = new Date();
                    let date = new Date(dateFromApi);
                    date.setDate(day);
                    const formattedDate = date?.toISOString()?.split("T")[0];
                    const dayOfWeek = getDayOfWeek(formattedDate);
                    const monthFromApi = getMonthFromDate(dateFromApi);
                    return (
                      <th key={day} className="dayHeader">
                        <div className="date">
                          {day < 11 ? `0${day - 1}` : day - 1} {monthFromApi}
                        </div>
                        <div className="day">{dayOfWeek}</div>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {employeeData?.map((employee, index) => (
                <tr key={employee.id}>
                  <td style={{ left: 0, zIndex: 1, position: "sticky" }}>
                    {index + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      position: "sticky",
                      left: "35px",
                      zIndex: 1,
                    }}
                  >
                    {employee.employeeName}
                  </td>
                  <td
                    style={{
                      left: "170px",
                      zIndex: 1,
                      position: "sticky",
                    }}
                  >
                    <div className="dayCell primaryDayCellFixed">
                      {daysInMonth?.length}
                    </div>
                  </td>
                  <td
                    style={{
                      left: "215px",
                      zIndex: 1,
                      position: "sticky",
                    }}
                  >
                    <div className="dayCell greenDayCellFixed">
                      {employee?.statusCounts?.P
                        ? employee?.statusCounts?.P
                        : 0}
                    </div>
                  </td>
                  <td
                    style={{
                      left: "260px",
                      zIndex: 1,
                      position: "sticky",
                    }}
                  >
                    <div className="dayCell redDayCellFixed">
                      {employee?.statusCounts?.A
                        ? employee?.statusCounts?.A
                        : 0}
                    </div>
                  </td>
                  <td
                    style={{
                      left: "305px",
                      zIndex: 1,
                      position: "sticky",
                    }}
                  >
                    <div className="dayCell orangeDayCellFixed">
                      {employee?.statusCounts?.L
                        ? employee?.statusCounts?.L
                        : 0}
                    </div>
                  </td>
                  <td
                    style={{
                      left: "350px",
                      zIndex: 1,
                      position: "sticky",
                    }}
                  >
                    <div className="dayCell secondaryDayCellFixed">
                      {employee?.statusCounts?.HL
                        ? employee?.statusCounts?.HL
                        : 0}
                    </div>
                  </td>
                  <td
                    style={{
                      left: "395px",
                      zIndex: 1,
                      position: "sticky",
                    }}
                  >
                    <div className="dayCell pastelDayCellFixed">
                      {employee?.statusCounts?.H
                        ? employee?.statusCounts?.H
                        : 0}
                    </div>
                  </td>
                  <td
                    style={{
                      left: "440px",
                      zIndex: 1,
                      position: "sticky",
                    }}
                  >
                    <div className="dayCell pinkDayCellFixed">
                      {employee?.statusCounts?.W
                        ? employee?.statusCounts?.W
                        : 0}
                    </div>
                  </td>
                  {/* <td
                    style={{
                      left: "485px",
                      zIndex: 1,
                      position: "sticky",
                      boxShadow: "2px 0px 0px 0px var(--primary-hover)",
                    }}
                  >
                    <div className="dayCell tealDayCellFixed">
                      {employee.payableDays}
                    </div>
                  </td> */}
                  {daysInMonth.map((day) => {
                    let dayhere = day - 1;
                    let attendance = employee?.dateWiseAttendance?.find(
                      (item) =>
                        Number(format(new Date(item?.date), "d")) ===
                        Number(dayhere)
                    );

                    return (
                      <td key={day}>
                        <div
                          className={`dayCell ${getAttendanceClass(
                            attendance?.status
                          )}`}
                        >
                          {attendance ? attendance.status : ""}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="horizontalLine"></div>
          {/* <div className="tableFooterContainer">
            <label>Total Record Count: </label>
            <span>{count}</span>
            <SelectCustom
              searchable={false}
              options={noOfRecords}
              dropdownPosition="top"
              values={[recordsSelected]}
              onChange={(e) => paginationChanged(e)}
            />
            <label>Records Per Page</label>
            {currentPage > 1 ? (
              <IconButton
                onClick={() => pageChangedPrev()}
                color="primary"
                className="pageChangerIcon pageChangerIconActive"
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton disabled color="primary" className="pageChangerIcon">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
            {currentPage} - {totalPages}
            {totalPages > currentPage ? (
              <IconButton
                onClick={() => pageChangedNext()}
                color="primary"
                className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton
                disabled
                color="primary"
                className={`pageChangerIcon pageChangerIconNext`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
          </div> */}
        </>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Records"}</h4>
        </div>
      )}
    </div>
  );
};

export { MonthlyAttendanceReports };

/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import StarRatings from "react-star-ratings";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { useSelector } from "react-redux";
const DetailsAppraisalAllReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeDetails = () => {
    navigate(-1);
  };
  const location = useLocation();
  const selectedId = location?.state?.data;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleRow = (index) => {
    const expandedIndex = expandedRows.indexOf(index);
    const newExpandedRows = [...expandedRows];

    if (expandedIndex > -1) {
      newExpandedRows.splice(expandedIndex, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };
  const selfRatingObtain = (total) => {
    if (total >= 111 && total <= 120) {
      return 5;
    }
    if (total >= 101 && total <= 110) {
      return 4;
    }
    if (total >= 91 && total <= 100) {
      return 3;
    }
    if (total >= 81 && total <= 90) {
      return 2;
    }
    if (total < 80) {
      return 1;
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - All Review Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSAppraisalReview/ViewDetails/?ID=${selectedId?.performanceCycleID}&EmployeeId=${selectedId?.employeeId}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          // text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (location?.pathname?.toLowerCase()?.includes("allreview/details"))
      getDetails();
  }, [selectedId]);
  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal">
          {loading ? (
            <Loader />
          ) : data ? (
            <div className="selfParent">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
                  alignItems: "center",
                  padding: "4px 0px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={() => navigate(-1)}>
                    <svg
                      width="20"
                      height="8"
                      viewBox="0 0 20 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                        fill="#1296B0"
                      />
                    </svg>
                  </IconButton>
                  <h4> Performance Reviews</h4>
                </div>
                <div>
                  <button
                    className="btnSelf"
                    onClick={togglePopup}
                    type="button"
                  >
                    Appraisal Criteria{" "}
                  </button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div className="ratingSelf">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ marignTop: "12px" }}>Self Rating :</div>
                    <div>
                      {" "}
                      <StarRatings
                        rating={selfRatingObtain(
                          Array.isArray(
                            data?.pmsReviews
                              ?.pmsPerformanceReviewFeedbacksUpdatesNew
                          )
                            ? data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                                (sum, card) => sum + card.employeeAchievement,
                                0
                              )
                            : 0
                        )}
                        starRatedColor="#5BC4BF"
                        starSpacing="2px"
                        starEmptyColor="#FFFFFF"
                        numberOfStars={5}
                        starHoverColor="var(--primary)"
                        starDimension="28px"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>Self Achievement % :</div>
                    <div>
                      <p
                        style={{
                          color: "#5BC4BF",
                          fontSize: "30px",
                          fontWeight: "500",
                          marginTop: "5px",
                        }}
                      >
                        {Array.isArray(
                          data?.pmsReviews
                            ?.pmsPerformanceReviewFeedbacksUpdatesNew
                        )
                          ? data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                              (sum, card) => sum + card.employeeAchievement,
                              0
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="ratingSelf"
                  style={{ backgroundColor: "#FFEEF2" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ marignTop: "12px" }}>Appraiser Rating :</div>
                    <div>
                      {" "}
                      <StarRatings
                        rating={selfRatingObtain(
                          Array.isArray(
                            data?.pmsReviews
                              ?.pmsPerformanceReviewFeedbacksUpdatesNew
                          )
                            ? data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                                (sum, card) => sum + card.appraiserAchievement,
                                0
                              )
                            : 0
                        )}
                        starRatedColor="#DE64A1"
                        starSpacing="2px"
                        starEmptyColor="#FFFFFF"
                        numberOfStars={5}
                        starHoverColor="var(--primary)"
                        starDimension="28px"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>Appraiser Achievement % :</div>
                    <div>
                      <p
                        style={{
                          color: "#DE64A1",
                          fontSize: "30px",
                          fontWeight: "500",
                          marginTop: "5px",
                        }}
                      >
                        {Array.isArray(
                          data?.pmsReviews
                            ?.pmsPerformanceReviewFeedbacksUpdatesNew
                        )
                          ? data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                              (sum, card) => sum + card.appraiserAchievement,
                              0
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="ratingSelf"
                  style={{ backgroundColor: "#F0F0FF" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ marignTop: "12px" }}>Reviewer Rating :</div>
                    <div>
                      {" "}
                      <StarRatings
                        rating={selfRatingObtain(
                          Array.isArray(
                            data?.pmsReviews
                              ?.pmsPerformanceReviewFeedbacksUpdatesNew
                          )
                            ? data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                                (sum, card) => sum + card.reviewerAchievement,
                                0
                              )
                            : 0
                        )}
                        starRatedColor="#8A88ED"
                        starSpacing="2px"
                        starEmptyColor="#FFFFFF"
                        numberOfStars={5}
                        starHoverColor="var(--primary)"
                        starDimension="28px"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>Reviewer Achievement % :</div>
                    <div>
                      <p
                        style={{
                          color: "#8A88ED",
                          fontSize: "30px",
                          fontWeight: "500",
                          marginTop: "5px",
                        }}
                      >
                        {Array.isArray(
                          data?.pmsReviews
                            ?.pmsPerformanceReviewFeedbacksUpdatesNew
                        )
                          ? data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                              (sum, card) => sum + card.reviewerAchievement,
                              0
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {data?._pmsAppraisalCycles?.goalslist?.length > 0 ? (
                <div style={{ width: "100%", overflow: "auto" }}>
                  <table
                    className="tabself tabself-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#FBFBFB",
                        top: 0,
                        position: "sticky",
                        zIndex: 2,
                      }}
                    >
                      <tr>
                        <td>
                          <LabelCustom labelName={"S.no"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Name"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Description"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Start Date"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Expected End Date"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"KRA"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Weightage %"} />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {data?._pmsAppraisalCycles?.goalslist?.map(
                        (items, index) => {
                          const isRowExpanded = expandedRows.includes(index);

                          return (
                            <React.Fragment key={index}>
                              <tr
                                className={`trStyles`}
                                key={index}
                                style={{
                                  position: "relative",
                                }}
                              >
                                <td>
                                  <LabelCustom labelName={index + 1} />
                                </td>
                                <td>{items.name}</td>
                                <td>
                                  <LabelCustom labelName={items.description} />

                                  <IconButton
                                    onClick={() => toggleRow(index)}
                                    sx={{
                                      display: isRowExpanded && "none",
                                      position: "absolute",
                                      bottom: "-18px",
                                      left: "50%",
                                      zIndex: "1",
                                      boxShadow:
                                        "0px 1.89445px 10px rgba(0, 0, 0, 0.04);",
                                    }}
                                  >
                                    {!isRowExpanded && (
                                      <>
                                        <svg
                                          width="14"
                                          height="9"
                                          viewBox="0 0 14 9"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12.8765 0.898926L6.82826 6.94713L0.780053 0.898925"
                                            stroke="#1296B0"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      </>
                                    )}
                                  </IconButton>
                                </td>
                                <td>
                                  <LabelCustom
                                    labelName={
                                      items.startDate &&
                                      format(
                                        new Date(items.startDate),
                                        "dd/MM/yyyy"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <LabelCustom
                                    labelName={
                                      items.expectedEndDate &&
                                      format(
                                        new Date(items.expectedEndDate),
                                        "dd/MM/yyyy"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <LabelCustom labelName={items.kra} />
                                </td>
                                <td>
                                  <LabelCustom labelName={items.weightage} />
                                </td>
                              </tr>
                              {isRowExpanded && (
                                <tr>
                                  <td
                                    colSpan="7"
                                    style={{
                                      padding: "0",
                                    }}
                                  >
                                    <div className="formSelfReview">
                                      <div className="centertd">
                                        <div>Self</div>
                                        <div>Review</div>
                                      </div>
                                      <div style={{ flex: "1" }}>
                                        <label
                                          style={{
                                            paddingLeft: "12px",
                                          }}
                                        >
                                          {"Notes"}
                                        </label>
                                        <LabelCustom
                                          labelName={
                                            Array?.isArray(
                                              data?.pmsReviews
                                                ?.pmsPerformanceReviewFeedbacksUpdatesNew
                                            )
                                              ? data?.pmsReviews
                                                  ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                  index
                                                ]?.employeeRemarks
                                              : ""
                                          }
                                        />
                                      </div>
                                      <div style={{ flex: "1" }}>
                                        <label
                                          style={{
                                            paddingLeft: "12px",
                                          }}
                                        >
                                          {"Achievement %"}
                                        </label>
                                        <LabelCustom
                                          labelName={
                                            Array?.isArray(
                                              data?.pmsReviews
                                                ?.pmsPerformanceReviewFeedbacksUpdatesNew
                                            )
                                              ? data?.pmsReviews
                                                  ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                  index
                                                ]?.employeeAchievement
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div
                                      className="formSelfReview"
                                      style={{ backgroundColor: "#FFEEF2" }}
                                    >
                                      <div
                                        className="centertd"
                                        style={{ backgroundColor: "#DE64A1" }}
                                      >
                                        <div>Appraiser</div>
                                        <div>Review</div>
                                      </div>
                                      <div style={{ flex: "1" }}>
                                        <label
                                          style={{
                                            paddingLeft: "12px",
                                          }}
                                        >
                                          {"Notes"}
                                        </label>
                                        <LabelCustom
                                          labelName={
                                            Array?.isArray(
                                              data?.pmsReviews
                                                ?.pmsPerformanceReviewFeedbacksUpdatesNew
                                            )
                                              ? data?.pmsReviews
                                                  ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                  index
                                                ]?.appraiserRemarks
                                              : ""
                                          }
                                        />
                                      </div>

                                      <div style={{ flex: "1" }}>
                                        <label
                                          style={{
                                            paddingLeft: "12px",
                                          }}
                                        >
                                          {"Achievement %"}
                                        </label>
                                        <LabelCustom
                                          labelName={
                                            Array?.isArray(
                                              data?.pmsReviews
                                                ?.pmsPerformanceReviewFeedbacksUpdatesNew
                                            )
                                              ? data?.pmsReviews
                                                  ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                  index
                                                ]?.appraiserAchievement
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="formSelfReview"
                                      style={{
                                        backgroundColor: "#F0F0FF",
                                      }}
                                    >
                                      <div
                                        className="centertd"
                                        style={{
                                          backgroundColor: "#8A88ED",
                                        }}
                                      >
                                        <div>Reviewer</div>
                                        <div>Review</div>
                                      </div>
                                      <div style={{ flex: "1" }}>
                                        <label
                                          style={{
                                            paddingLeft: "12px",
                                          }}
                                        >
                                          {"Notes"}
                                        </label>
                                        <LabelCustom
                                          labelName={
                                            Array?.isArray(
                                              data?.pmsReviews
                                                ?.pmsPerformanceReviewFeedbacksUpdatesNew
                                            )
                                              ? data?.pmsReviews
                                                  ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                  index
                                                ]?.reviewerRemarks
                                              : ""
                                          }
                                        />
                                      </div>
                                      <div style={{ flex: "1" }}>
                                        <label
                                          style={{
                                            paddingLeft: "12px",
                                          }}
                                        >
                                          {"Achievement %"}
                                        </label>
                                        <LabelCustom
                                          labelName={
                                            Array?.isArray(
                                              data?.pmsReviews
                                                ?.pmsPerformanceReviewFeedbacksUpdatesNew
                                            )
                                              ? data?.pmsReviews
                                                  ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                  index
                                                ]?.reviewerAchievement
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>

                                    <IconButton
                                      onClick={() => toggleRow(index)}
                                      sx={{
                                        left: "50%",
                                        zIndex: "9999",
                                        boxShadow:
                                          "0px 1.89445px 10px rgba(0, 0, 0, 0.04);",
                                      }}
                                    >
                                      {isRowExpanded && (
                                        <>
                                          <svg
                                            width="15"
                                            height="9"
                                            viewBox="0 0 15 9"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13.3755 7.62805L7.32728 1.57985L1.27908 7.62805"
                                              stroke="#1296B0"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                            />
                                          </svg>
                                        </>
                                      )}
                                    </IconButton>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  <div className="reviewNotes">
                    <div className="notesSelf">
                      <div className="headingSelf">
                        <p>Self Notes</p>
                      </div>
                      <div className="detailSelf">
                        <div>
                          <LabelCustom labelName={data?.pmsReviews?.notes} />
                        </div>
                      </div>
                    </div>
                    {(data?.pmsReviews?.status === "Review Pending" ||
                      data?.pmsReviews?.status === "Review Completed") && (
                      <div
                        className="notesSelf"
                        style={{ backgroundColor: "#FFEEF2" }}
                      >
                        <div
                          className="headingSelf"
                          style={{ background: "#DE64A1" }}
                        >
                          <p>Appraiser Notes</p>
                        </div>
                        <div className="detailSelf">
                          <div>
                            <LabelCustom
                              labelName={data?.pmsReviews?.appraiserReviewNotes}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.pmsReviews?.status === "Review Completed" && (
                      <div
                        className="notesSelf"
                        style={{ backgroundColor: "rgb(240, 240, 255)" }}
                      >
                        <div
                          className="headingSelf"
                          style={{ background: "rgb(138, 136, 237)" }}
                        >
                          <p>Reviewer Notes</p>
                        </div>
                        <div className="detailSelf">
                          <div>
                            <LabelCustom
                              labelName={data?.pmsReviews?.reviewerReviewNotes}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Records"}</h4>
            </div>
          )}

          {isOpen && (
            <Popup
              popupHeading={"Rating Slab"}
              content={
                <>
                  <InstructionSelf handleClose={togglePopup} />
                </>
              }
              handleClose={togglePopup}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export { DetailsAppraisalAllReview };

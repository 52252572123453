import React, { useState, useEffect, useRef, useMemo } from "react";
import DropdownArrowChatMesssage from "../../../Assets/DropdownArrowChatMesssage.svg";
import starWhite from "../../../Assets/starWhite.svg";
import starYellow from "../../../Assets/starYellow.svg";
import DoubleTick from "../../../Assets/DoubleTick.svg";
import SingleTick from "../../../Assets/SingleTick.svg";
import DoubleTickGrey from "../../../Assets/DoubleTickGrey.svg";
import DropdownArrowChatMesssageWhite from "../../../Assets/DropdownArrowChatMesssageWhite.svg";
import AudioPlayIcon from "../../../Assets/AudioPlayIcon.svg";
import Forward from "../../../Assets/Forward.svg"
import ViewGroupIcon from "../../../Assets/GroupIconInvite.svg"
import ForwardBlue from "../../../Assets/ForwardBlue.svg"
import AddDoc from "../../../Assets/AddDocWhite.svg"
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import { scrollFunc } from "../ChatInterface";
import { useSelector } from "react-redux";
import "../../../Pages/Chat/Chat.css";
import dummyimage from "../../../Assets/SocialPremium.png"
import { confirmAlert } from "react-confirm-alert";
import { downloadBlobChat } from "./chatServices";
const PhotosAndVideos = ({
  setSelectedMessage,
  message,
  messageClass,
  setReplyData,
  setForwardData,
  deleteMessage,
  handleReplyPrivately,
  bookMarkMessage,
  activeChat,
  viewGroup,
  newSocket,
  handleStoryFromChat,
}) => {


  useEffect(() => {
    if (message.type != 6 && message.type != 8 && message.type != 12) {
      getMeta(message.type === 0 ? message.replyMsgObj.content[0].url : message.content[0].url, (err, img) => {
        let a = { width: img?.naturalWidth, height: img?.naturalHeight };

        if ((message.type === 0 && (a.width > 60 || a.height > 50)) || (message.type > 0 && (a.width > 350 || a.height > 250))) {
          while (true) {
            if ((message.type === 0 && a.width < 60 && a.height < 50) || (message.type > 0 && a.width < 350 && a.height < 250)) break;
            a.width /= 1.2;
            a.height /= 1.2;
          }
        }
        setCurrDim({ ...a })
      })
    }
    getMixerDimension();
  }, [])


  const openFile = (url) => {
    // Create an anchor element dynamically
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';  // Opens in new tab if needed
    anchor.download = url.split('/').pop(); // Extracts the file name from URL

    // Append anchor to body and trigger click to start download
    document.body.appendChild(anchor);
    anchor.click();

    // Remove the anchor after triggering download
    document.body.removeChild(anchor);
  };
  const downloadFile = async (url) => {
    downloadBlobChat(
      "",
      url?.slice(-3) === "jpg" ||
        url?.slice(-3) === "png" ||
        url?.slice(-3) === "svg" ||
        url?.slice(-3) === "ogv" ||
        url?.slice(-3) === "mp4"
        ? url?.slice(-3)
        : url?.slice(-4) === "jpeg" ||
          url?.slice(-4) === "webm"
          ? url?.slice(-4)
          : "",
      url,
      url.split("/").pop()
    );
  };


  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const isURL = (messageInput) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = messageInput.split(urlRegex);
    for (let i = 0; i < parts.length; i++) {
      if (urlRegex.test(parts[i])) return true;
    }
    if (messageInput.includes("peoplesol/chat/inviteLink")) return false;
  }
  const handleViewGroup = () => {
    let link = message.content[0].inviteLink;
    let conversationId = parseInt(link.split("/")[1]);
    viewGroup(conversationId);
  }
  const handleJoinGroup = () => {
    let link = message.content[0].inviteLink;
    let conversationId = parseInt(link.split("/")[1]);
    link = link.split("/")[2];
    newSocket.emit("validateInviteLink", ({ "conversationId": conversationId, "inviteLink": link }), (res) => {
      if (res?.data?.type === "Group") {
        viewGroup(conversationId, "validate");
      } else {
        confirmAlert({
          title: "Wrong link!",
          message: "This link is either expired or not correct",
          buttons: [
            {
              label: "Cancel",
              onClick: () => { },
            },
            {
              label: "Ok",
              onClick: () => { },
            },
          ],
          overlayClassName: "customConfirmAlertContainer",
        });
      }
    })
  }


  const audioRef = useRef(null);
  const [currDim, setCurrDim] = useState({ width: 0, height: 0 })
  const [popOpen, setPopupOpen] = useState(false);
  const [showViewMore, setViewMore] = useState(false);
  const [dimensionsArr, setDimArr] = useState([{ width: 0, height: 0 }, { width: 0, height: 0 }, { width: 0, height: 0 }, { width: 0, height: 0 }]);
  const userDetails = useSelector(state => state.chat.userDetails);
  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const getMixerDimension = () => {
    if (message.type !== 9) return;
    let dimArr = [];
    for (let i = 0; i < 4; i++) {
      if (isVideo(message.content[i].fileName)) {
        dimArr.push({})
      }
      else {
        getMeta(message.content[i].url, (err, img) => {
          let a = { width: img?.naturalWidth, height: img?.naturalHeight };

          if (a.width > 100 || a.height > 100) {
            while (true) {
              if (a.width < 100 && a.height < 100) break;
              a.width /= 1.2;
              a.height /= 1.2;
            }
          }
          a.url = message.content[i].url;
          dimArr.push(a);
        })
      }
    }
    setDimArr(dimArr);
  }



  const isVideo = (fileName) => {
    const videoExtensions = [".mp4", ".mkv", ".webm", ".mov", ".avi", ".flv"];
    const extension = fileName.split(".").pop().toLowerCase();
    return videoExtensions.includes(`.${extension}`);
  };
  const ContentMedia = useMemo(() => {
    return (
      <div
        onPointerEnter={() => { setViewMore(true) }}
        onPointerLeave={() => { setViewMore(false) }}
        style={
          message.type === 6 ?
            {
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "6px 6px 6px 6px",
              padding: "4px",
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            } :
            messageClass === "sent"
              ? {
                backgroundColor: "var(--primary)",
                borderRadius: "6px 0px 6px 6px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }
              : {
                backgroundColor: "white",
                borderRadius: "0px 6px 6px 6px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }
        }
      >
        {message.type !== 6 && <div onClick={() => { setPopupOpen(true) }} style={{ width: 20, height: 10, marginBottom: 5, marginTop: -10, marginRight: -10 }}>
          {showViewMore && <img src={messageClass === "sent" ? DropdownArrowChatMesssageWhite : DropdownArrowChatMesssage} />}
        </div>}
        {message.forwardStatus &&
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: message.type == 1 ? 10 : 5 }}>
            <img src={messageClass === "sent" ? Forward : ForwardBlue} alt="forward" />
            <p style={{ fontStyle: "italic", fontSize: 9, fontWeight: "400", marginLeft: 10, color: messageClass === "sent" ? "#D8F0F2" : "#1296B0" }}>Forwarded</p>
          </div>
        }

        {message.type === 12 &&
          <div onClick={() => { handleStoryFromChat(message.storyMsgObj._id, message.storyMsgObj.senderId) }} style={{ backgroundColor: "rgba(223, 236, 239, 1)", borderRadius: 6, padding: 5 }}>
            <div style={{ color: "rgba(18, 150, 176, 1)", fontSize: 10, marginBottom: 3 }}>
              {userDetails.personalNumber === message.storyMsgObj.personalNumber ? "You > Story" : (activeChat.title + " > Story")}
            </div>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", }}>
              <div style={{ width: "70%", marginRight: 10, color: "rgba(85, 85, 85, 1)", fontSize: 11 }}>
                {message.storyMsgObj.caption}
              </div>
              <div>
                {message.storyMsgObj.type == 3 ?
                  <img style={{ width: 60, height: 40 }} src={message.storyMsgObj.content.fileUrl ? message.storyMsgObj.content.fileUrl : message.storyMsgObj.content.url} />
                  :
                  <video
                    src={message.storyMsgObj.content.fileUrl ? message.storyMsgObj.content.fileUrl : message.storyMsgObj.content.url}
                    controls={false}
                    width="70"
                  />
                }
              </div>
            </div>
          </div>
        }
        {
          message.type === 14 || message.type == 8 ?
            <div>
              {!isURL(message.caption) && <div style={{ width: "100%", borderRadius: 6, backgroundColor: "rgba(217, 239, 243, 1)", color: "rgba(18, 150, 176, 1)", display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                {message.content[0].groupIcon && message.content[0].groupIcon != "" ? <img style={{ borderRadius: 20, width: 40, height: 40 }} src={message.content[0].groupIcon} />
                  :
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 45, width: 45, borderRadius: 25, backgroundColor: messageClass === "sent" ? "rgba(18, 150, 176, 1)" : "rgba(216, 240, 242, 1)", color: messageClass === "sent" ? "white" : "rgba(18, 150, 176, 1)" }}>
                    {message.content[0].groupTitle
                      ?.split(" ")
                      ?.slice(0, 2)
                      ?.map((item) => {
                        return item?.charAt(0)?.toUpperCase();
                      })
                      .join("")
                    }
                  </div>
                }
                <div style={{ marginLeft: 15 }}>
                  <div style={{ fontSize: 14, fontWeight: "500" }}>{message.content[0].groupTitle}</div>
                  <div style={{ fontSize: 10 }}>{"Group chat invite "}</div>
                </div>
              </div>}
              {!isURL(message.caption) && <div style={{ color: messageClass === "sent" ? "white" : "rgba(85, 85, 85, 1)", marginTop: 10 }}>
                Follow this link to join the group
              </div>}
              {isURL(message.caption) ? (
                <div style={{ color: messageClass == "sent" ? "white" : "rgba(18, 150, 176, 1)" }}>
                  {message.caption.split(urlRegex).map((part, index) => {
                    if (urlRegex.test(part)) return (
                      <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: "#F7941D" }}>
                        {part}
                      </a>
                    )
                    else return <span>{part}</span>
                  })}
                </div>
              ) : <div style={{ color: "#F7941D" }}>
                {message.caption}
              </div>}
              {isURL(message.caption) ? null : messageClass === "sent" ? <div onClick={() => { handleViewGroup() }} style={{ width: "100%", borderRadius: 6, backgroundColor: "rgba(217, 239, 243, 1)", color: "rgba(18, 150, 176, 1)", display: "flex", flexDirection: "row", padding: 10, alignItems: "center", justifyContent: "center", marginTop: 12 }}>
                <img src={ViewGroupIcon} />
                <div style={{ marginLeft: 10 }}>View Group</div>
              </div>
                :
                <div onClick={() => { handleJoinGroup() }} style={{ width: "100%", borderRadius: 6, color: "white", backgroundColor: "rgba(18, 150, 176, 1)", display: "flex", flexDirection: "row", padding: 10, alignItems: "center", justifyContent: "center", marginTop: 12 }}>
                  <img src={AddDoc} />
                  <div style={{ marginLeft: 10 }}>Join Group</div>
                </div>
              }
            </div>
            : null
        }
        {
          message.type === 6 ?
            <div style={{ fontSize: 12, color: "rgba(85, 85, 85, 1)" }}>
              {message.caption}
            </div> : null
        }
        {
          message.type === 10 ?
            <div style={{ color: "#F7941D", display: "flex", flexDirection: "column" }} onClick={(e) => { e.stopPropagation(); }}>
              <span>{message.content[0]?.name}</span>
              <span>{message.content[0]?.phoneNumbers[0]?.number}</span>
            </div>
            : null
        }

        {
          message.type === 3 ?
            <div onClick={(e) => { e.stopPropagation(); openFile(message.content[0].url) }} style={{ width: currDim.width, height: currDim.height, overflow: "hidden" }}>
              <img style={{ width: currDim.width, height: currDim.height }} src={message.content[0].url} />
            </div>
            : null
        }
        {
          message.type === 2 ?
            <video
              src={message.content[0].url}
              controls
              width="350"
            />
            : null
        }
        {
          message.type === 1 ?
            <div>
              <audio ref={audioRef} src={message.content[0].url} controls />
            </div> : null
        }

        {message.type === 9 ?
          <div style={{ position: "relative" }}>
            <div style={{ height: "100%", width: "100%", display: 'flex', flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
              <div style={{ width: 60, height: 60, borderRadius: 50, backgroundColor: messageClass === "sent" ? "var(--primary)" : "white", color: messageClass !== "sent" ? "var(--primary)" : "white", fontSize: 12, position: "absolute", cursor: "pointer", zIndex: 3 }}>
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  View
                </div>
              </div>
              {message.content.length - 4 > 0 ? <div style={{ width: 50, height: 50, borderRadius: 50, backgroundColor: messageClass === "sent" ? "var(--primary)" : "white", color: messageClass !== "sent" ? "var(--primary)" : "white", position: "absolute", cursor: "pointer", right: messageClass !== "sent" ? -35 : "inherit", left: messageClass === "sent" ? -35 : "inherit", zIndex: 1 }}>
                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", fontSize: 14, paddingLeft: messageClass === "sent" ? 14 : 28, }}>
                  {message.content.length - 4}+
                </div>
              </div> : null}
              {message.content.map((mix, index2) => {
                if (index2 < 4)
                  return (
                    <div key={index2} style={{ width: "47%", height: 100, margin: 4, borderRadius: 8, backgroundColor: "rgba(231, 245, 246, 1)", display: 'flex', justifyContent: "center", alignItems: "center", zIndex: 2 }}>
                      {isVideo(mix.fileName) ?
                        <video
                          src={mix.url}
                          width="100"
                        />
                        :
                        <img style={{ width: dimensionsArr.length > 0 ? dimensionsArr.filter(item => item.url === mix.url)[0]?.width : 0, height: dimensionsArr.length > 0 ? dimensionsArr.filter(item => item.url === mix.url)[0]?.height : 0 }} src={mix.url} />
                      }
                    </div>
                  )
                return null
              })}
            </div>
          </div>
          : null}
        {message.type != 14 && message.type != 8 && message.type !== 6 && <div style={{ width: "100%" }}>
          {message.caption != "" && <div style={{ width: "100%", marginBottom: 10, textAlign: messageClass == "sent" ? "end" : "start", color: messageClass == "sent" ? "white" : "rgba(18, 150, 176, 1)", padding: 8, }}>{message.caption}</div>}
        </div>}

        {
          message.type != 6 && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: messageClass === "sent" ? "flex-end" : "flex-start", marginTop: 10 }}>
            {message.isBookmarked && <img src={messageClass === "sent" ? starWhite : starYellow} />}
            {messageClass === "sent" && <img style={{ marginLeft: 10 }} src={message.msgStatus == 1 ? SingleTick : message.msgStatus == 3 ? DoubleTick : DoubleTickGrey} />}
          </div>
        }
      </div>
    )
  }
    , [message, messageClass, currDim, openFile]);

  const ReplyToMedia = () => {
    return (
      <div
        onPointerEnter={() => { setViewMore(true) }}
        onPointerLeave={() => { setViewMore(false) }}
        style={
          messageClass === "sent"
            ? {
              backgroundColor: "var(--primary)",
              borderRadius: "6px 0px 6px 6px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }
            : {
              backgroundColor: "white",
              borderRadius: "0px 6px 6px 6px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }
        }
      >
        {<div onClick={() => { setPopupOpen(true) }} style={{ width: 20, height: 10, marginBottom: 5, marginTop: -10, marginRight: -10 }}>
          {showViewMore && <img src={messageClass === "sent" ? DropdownArrowChatMesssageWhite : DropdownArrowChatMesssage} />}
        </div>}

        {message.forwardStatus &&
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: message.type == 1 ? 10 : 5 }}>
            <img src={messageClass === "sent" ? Forward : ForwardBlue} alt="forward" />
            <p style={{ fontStyle: "italic", fontSize: 9, fontWeight: "400", marginLeft: 10, color: messageClass === "sent" ? "#D8F0F2" : "#1296B0" }}>Forwarded</p>
          </div>
        }
        <div style={{ backgroundColor: "rgba(223, 236, 239, 1)", width: "100%", borderRadius: "6px 0px 6px 6px", padding: "10px", marginTop: 10 }}>
          <div style={{ color: "rgba(18, 150, 176, 1)", textAlign: "end", fontSize: 10, fontWeight: "900", }}>{message.replyMsgObj.senderId === userDetails._id ? "You" : activeChat.participateMembers.filter(item => item.contactId === message.replyMsgObj.senderId)[0]?.contactName}</div>
          <div style={{ marginTop: 10, flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
            <div style={{ color: "rgba(18, 150, 176, 1)" }}>
              {message.replyMsgObj.caption}
            </div>
            <div style={{ marginLeft: 15 }}>
              {message.replyMsgObj.type === 3 ?
                <div style={{ width: currDim.width, height: currDim.height, overflow: "hidden" }}>
                  <img style={{ width: currDim.width, height: currDim.height }} src={message.replyMsgObj.content[0].url} />
                </div>
                : null
              }
              {message.replyMsgObj.type === 2 ?
                <video
                  src={message.replyMsgObj.content[0].url}
                  // controls
                  width="60"
                />
                : null
              }
              {message.replyMsgObj.type === 1 ?
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "30%", marginLeft: 15 }}>
                  <img src={AudioPlayIcon} />
                </div> : null
              }
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {message.caption != "" && <div style={{ width: "100%", marginBottom: 10, textAlign: "start", color: messageClass == "sent" ? "white" : "rgba(18, 150, 176, 1)" }}>{message.caption}</div>}
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: messageClass === "sent" ? "flex-end" : "flex-start", marginTop: 10 }}>
          {message.isBookmarked && <img src={messageClass === "sent" ? starWhite : starYellow} />}
          {messageClass === "sent" && <img style={{ marginLeft: 10 }} src={message.msgStatus == 1 ? SingleTick : message.msgStatus == 3 ? DoubleTick : DoubleTickGrey} />}
        </div>
      </div>
    )
  }

  return (
    <>
      {message.type == 0 ?
        <ReplyToMedia />
        : ContentMedia}
      {<OutsideClickHandler
        key={message?._id}
        id={message?._id}
        onOutsideClick={(id) =>
          setPopupOpen(false)
        }
      >
        <div
          className="chatActionsPopup"
          style={
            !popOpen ?
              { display: "none" }
              :
              messageClass === "sent" ?
                {
                  position: "absolute",
                  zIndex: "6",
                  right: 80,
                  color: "black",

                }
                :
                {
                  position: "absolute",
                  zIndex: "6",
                  Left: -40,
                  color: "black",

                }
          }
        >
          {(message.type != 14 || isURL(message.caption)) && <div
            style={{ borderRadius: "6px 6px 0px 0px" }}
            onClick={(e) => {
              setReplyData({
                replyId: message._id,
                replyContent: message?.caption,
                data: message,
              });
              setPopupOpen(false);
              e.stopPropagation();
            }}
          >
            Reply
          </div>}
          {messageClass == "sent" && (message.type != 14 || isURL(message.caption)) && <div
            onClick={(e) => {
              setSelectedMessage(message);
              e.stopPropagation();
              setPopupOpen(false);
            }}
          >Info</div>}
          {(message.type != 14 || isURL(message.caption)) && <div
            onClick={(e) => {
              setForwardData({
                isActive: true,
                msgId: [message?._id],
                userId: [],
                conversationId: [],
              });
              setPopupOpen(false);
              e.stopPropagation();
            }}
          >
            Forward Message
          </div>}
          {activeChat.type === "Group" && messageClass != "sent" && <div
            onClick={(e) => {
              handleReplyPrivately(message);
              e.stopPropagation();
            }}
          >
            Reply Privately
          </div>}
          <div
            onClick={(e) => {
              downloadFile(message.content[0].url)
              e.stopPropagation()
            }}
          >
            Download
          </div>
          <div
            onClick={(e) => {
              bookMarkMessage(message._id, userDetails._id, message.isBookmarked ? "unstar" : "star");
              setPopupOpen(false);
              setForwardData({
                isActive: false,
                msgId: [],
                userId: [],
                conversationId: [],
              });
              e.stopPropagation()
            }}
          >
            {message.isBookmarked ? "Unstar" : "Star"}
          </div>
          <div
            style={{ borderRadius: "0px 0px 6px 6px" }}
            onClick={() => {
              deleteMessage(
                message?._id,
                userDetails?._id,
                messageClass
              );
              setPopupOpen(false);
            }}
          >
            Delete Message
          </div>
        </div>
      </OutsideClickHandler>}
    </>
  );
};

export default PhotosAndVideos;

import React, { useEffect } from 'react'
import { LabelCustom } from '../../Components/FormComponent/LabelCustom';
import { TableBody, TableHead, TableRow, Table } from "@mui/material";
import { InputCustomFormik } from '../../Components/FormComponent/InputCustomFormik';
import { FileUploadFormik } from '../../Components/FormComponent/FileUploadFormik';
import { TextAreaFormik } from '../../Components/FormComponent/TextAreaFormik';
import { DatePickerFormiks } from '../../Components/FormComponent/DatePickerFormiks';
import { FieldArray } from 'formik';
import { StyledTableCell } from '../../Components/FormComponent/Repeated';
import { CheckboxFormiks } from '../../Components/FormComponent/CheckboxFormiks';
import { showToast } from '../../Features';
import { Loader } from '../../Components/FormComponent/Loader';
import { useDispatch } from 'react-redux';
import SurveyAnswersDeleteIcon from "../../Assets/SurveyAnswersDeleteIcon.svg";
import SetTargetPlusIcon from "../../Assets/settargetplusicon.svg";
import settargetconsolationimg from "../../Assets/settargetconsolationimg.svg";


const RewardsCriteriaUpdateSetTarget = ({ getTarget, getConsolationTarget, loading,numbers, setInitialValues, type, formValues }) => {
  const dispatch = useDispatch();

  console.log("this is form value", formValues)

  // const numbers = Array.from({ length: winnersCount }, (_, index) => index + 1);

  const getOrdinalSuffix = (number) => {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return (
        <>
          {number}
          <sup>th</sup>
        </>
      ); // 11th, 12th, 13th for "th"
    }

    switch (lastDigit) {
      case 1:
        return (
          <>
            {number}
            <sup>st</sup>
          </>
        ); // 1st, 21st, 31st
      case 2:
        return (
          <>
            {number}
            <sup>nd</sup>
          </>
        ); // 2nd, 22nd, 32nd
      case 3:
        return (
          <>
            {number}
            <sup>rd</sup>
          </>
        ); // 3rd, 23rd, 33rd
      default:
        return (
          <>
            {number}
            <sup>th</sup>
          </>
        ); // remaining "th"
    }
  };

  useEffect(() => {
    document.title = `PeopleSol - Create Survey`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);


  if (loading) return <Loader />;
  else
    return (
      <>

        <div>
          {/* <label className="repeatedHeading">Expenses</label> */}
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              paddingTop: "12px",
            }}
            className="hideSurveyScrollBar"
          >

            <Table style={{ width: "100%" }}>
              <TableHead>
                <TableRow
                  style={{ border: "1px solid #ddd" }}
                >
                  <StyledTableCell
                    padding="0"
                    style={{
                      fontSize: "12px",
                    }}
                    align="center"
                  >
                    <LabelCustom labelName={"Position"} style={{
                      fontSize: "12px",
                    }} />
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <LabelCustom
                      labelName={"Objective"}
                      mandatory={true}
                      style={{
                        fontSize: "12px",
                      }}
                    />
                  </StyledTableCell>

                </TableRow>
              </TableHead>
              {numbers?.map((item, index) => (
                <TableBody key={index} >
                  <TableRow>
                    <StyledTableCell align="center">
                    {getOrdinalSuffix(index + 1)}
                      {/* {`${index + 1}`} */}
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "600px" }}>

                      <TextAreaFormik
                        mandatory={true}
                        name={`noOfWinners[${index}].objective`}
                        placeholder={"Enter Questions"}
                      />

                      <FieldArray name={`noOfWinners[${index}].winnersTarget`}>
                        {(fieldArrayProps) => {
                          console.log("fieldArray update rewardscriteria", fieldArrayProps)
                          const { remove, insert, form } = fieldArrayProps;
                          const { values } = form;
                          // const { winnersTarget } = values.noOfWinners[index];
                          // const  winnersTarget  = Array?.isArray(values?.noOfWinners) &&  Array?.isArray(values?.noOfWinners[index]?.winnersTarget) && values?.noOfWinners[index]?.winnersTarget ;
                          const winnersTarget = values?.noOfWinners?.[index]?.winnersTarget ?? getTarget;
                          return (
                            <>
                              {winnersTarget?.map((item, wTargetindex) => (
                                <TableRow key={index} >
                                  <div
                                    className="surveycreateform"
                                    style={{
                                      borderTop: "1px solid #ddd",
                                      display: "flex",
                                      alingnItem: "center",
                                    }}
                                  >
                                    <td
                                      style={{ padding: "0px" }}
                                    >
                                      <LabelCustom
                                        mandatory={true}
                                        labelName={"Item Description"}
                                        style={{ padding: "13px 13px 13px", fontSize: "11px" }}
                                      />
                                      <div style={{ display: "flex" }}>
                                        <TextAreaFormik
                                          mandatory={true}
                                          name={`noOfWinners[${index}].winnersTarget[${wTargetindex}].description`}
                                          placeholder={"Type Here"}
                                          style={{ margin: "0px 12px 12px" }}
                                        />
                                      </div>
                                    </td>
                                    <td
                                      style={{ padding: "0px" }}
                                    >
                                      <LabelCustom
                                        mandatory={true}
                                        labelName={"No. of unit"}
                                        style={{ padding: "13px 13px 13px", fontSize: "11px" }}
                                      />
                                      <div style={{ display: "flex" }}>
                                        <InputCustomFormik
                                          type="text"
                                          step="0.01"
                                          name={`noOfWinners[${index}].winnersTarget[${wTargetindex}].unit`}
                                          placeholder={"Enter"}
                                          style={{ margin: "0px 12px 12px" }}
                                        />

                                      </div>
                                    </td>
                                    <td
                                      style={{ padding: "0px", borderRight: "1px solid #DDD" }}
                                    >
                                      <LabelCustom
                                        mandatory={true}
                                        labelName={"Value"}
                                        style={{
                                          padding: "13px 13px 13px",
                                          fontSize: "11px",

                                        }}

                                      />
                                      <div style={{ display: "flex" }}>
                                        <InputCustomFormik
                                          type="text"
                                          step="0.01"
                                          name={`noOfWinners[${index}].winnersTarget[${wTargetindex}].value`}
                                          placeholder={"Enter"}
                                          style={{ margin: "0px 12px 12px" }}
                                        />

                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        minWidth: "75px",
                                        padding: "0px",
                                      }}
                                    >
                                      {wTargetindex === winnersTarget.length - 1 ? (
                                        <img
                                          onClick={() => {
                                            insert(wTargetindex + 1, {
                                              description: "",
                                              unit: "",
                                              value: ""
                                            },);

                                          }}
                                          style={{
                                            marginTop: "25px",
                                            marginLeft: "25px",
                                            cursor: "pointer",
                                          }}
                                          src={SetTargetPlusIcon}
                                          alt="SurveyAnswersDeleteIcon"
                                        />) : (
                                        <img
                                          onClick={() => {
                                            remove(wTargetindex);
                                          }}
                                          style={{
                                            marginTop: "25px",
                                            marginLeft: "25px",
                                            cursor: "pointer",
                                          }}
                                          src={SurveyAnswersDeleteIcon}
                                          alt="SurveyAnswersDeleteIcon"
                                        />)

                                      }
                                    </td>

                                  </div>

                                </TableRow>
                              ))}
                            </>
                          )
                        }}
                      </FieldArray>
                    </StyledTableCell>
 
                  </TableRow>
                </TableBody>
              ))}
            </Table>

            <Table style={{ marginTop: "20px" }}>
              <TableRow>
                <StyledTableCell align="center">
                  <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alingnItem: "center",
                    minWidth: "75px",
                  }}>
                    <img
                      // onClick={() => {
                      //     popAnswer(answer, answerIndex, index);
                      // }}
                      style={{
                        // marginInline: "25px",
                        cursor: "pointer",
                      }}
                      src={settargetconsolationimg}
                      alt="SurveyAnswersDeleteIcon"
                    />
                  </div>
                </StyledTableCell>
                <StyledTableCell sx={{ maxWidth: "600px" }}>

                  <TextAreaFormik
                    mandatory={true}
                    name={`consolation[0].objective`}
                    placeholder={"Type Here"}
                  />
                  <FieldArray name={`consolation[0].winnersTarget`}>
                    {(fieldArrayProps) => {
                      console.log("fieldArray update rewardscriteria", fieldArrayProps)
                      const { remove, insert, form } = fieldArrayProps;
                      const { values } = form;
                      const consolationTarget = values?.consolation?.[0]?.winnersTarget ?? getConsolationTarget;
                      return (
                        <>
                          {consolationTarget?.map((item, cTargetindex) => (
                            <TableRow  >
                              <div
                                className="surveycreateform"
                                style={{
                                  borderTop: "1px solid #ddd",
                                  display: "flex",
                                  alingnItem: "center",
                                }}
                              >

                                {/* {Questions?.Answers?.map(
                                    (answer, answerIndex) => ( */}
                                <td

                                  style={{ padding: "0px" }}
                                >
                                  <LabelCustom
                                    mandatory={true}
                                    labelName={"Item Description"}
                                    style={{ padding: "13px 13px 13px", fontSize: "11px" }}

                                  />
                                  <div style={{ display: "flex" }}>
                                    <TextAreaFormik
                                      mandatory={true}
                                      name={`consolation[0].winnersTarget[${cTargetindex}].description`}
                                      placeholder={"Type Here"}
                                      style={{ margin: "0px 12px 12px" }}

                                    />
                                    {/* <InputCustomFormik
                                        type="text"
                                        step="0.01"
                                        name={`SurveyQuestion AnswerText`}
                                        placeholder={"Enter"}
                                        style={{ margin: "0px 12px 12px" }}
                                    /> */}

                                  </div>
                                </td>
                                <td
                                  style={{ padding: "0px" }}
                                >
                                  <LabelCustom
                                    mandatory={true}
                                    labelName={"No. of unit"}
                                    style={{ padding: "13px 13px 13px", fontSize: "11px" }}

                                  />
                                  <div style={{ display: "flex" }}>
                                    <InputCustomFormik
                                      type="text"
                                      step="0.01"
                                      name={`consolation[0].winnersTarget[${cTargetindex}].unit`}
                                      placeholder={"Enter"}
                                      style={{ margin: "0px 12px 12px" }}
                                    />

                                  </div>
                                </td>
                                <td
                                  style={{ padding: "0px", borderRight: "1px solid #DDD" }}
                                >
                                  <LabelCustom
                                    mandatory={true}
                                    labelName={"Value"}
                                    style={{
                                      padding: "13px 13px 13px",
                                      fontSize: "11px",

                                    }}

                                  />
                                  <div style={{ display: "flex" }}>
                                    <InputCustomFormik
                                      type="text"
                                      step="0.01"
                                      name={`consolation[0].winnersTarget[${cTargetindex}].value`}
                                      placeholder={"Enter"}
                                      style={{ margin: "0px 12px 12px" }}
                                    />

                                  </div>
                                </td>
                                <td
                                  style={{
                                    minWidth: "75px",
                                    padding: "0px",
                                  }}
                                >

                                  {cTargetindex === consolationTarget.length - 1 ? (
                                    <img
                                      onClick={() => {
                                        insert(cTargetindex + 1, {
                                          description: "",
                                          unit: "",
                                          value: ""
                                        },);

                                      }}
                                      style={{
                                        marginTop: "25px",
                                        marginLeft: "25px",
                                        cursor: "pointer",
                                      }}
                                      src={SetTargetPlusIcon}
                                      alt="SurveyAnswersDeleteIcon"
                                    />) : (
                                    <img
                                      onClick={() => {
                                        remove(cTargetindex);
                                      }}
                                      style={{
                                        marginTop: "25px",
                                        marginLeft: "25px",
                                        cursor: "pointer",
                                      }}
                                      src={SurveyAnswersDeleteIcon}
                                      alt="SurveyAnswersDeleteIcon"
                                    />)

                                  }

                                </td>


                              </div>

                            </TableRow>
                          ))}
                        </>
                      )
                    }}
                  </FieldArray>
                </StyledTableCell>

              </TableRow>
            </Table>

          </div>
        </div>
      </>
    );
};

export default RewardsCriteriaUpdateSetTarget

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateTravelBookingComp } from "./FormComp/CreateTravelBookingComp";
import { useSelector } from "react-redux";
import { calculateAge } from "../../../Utilities/calculateAge";
import { format } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import CheckSubmit from "./CheckSubmit";

const CreateTravelBooking = (props) => {
  const { state } = useLocation();
  const [detailsform, setDetailsform] = useState(false);
  const navigate = useNavigate();
  const data = state?.id;
  console.log("object detailsform", detailsform);
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [finalValues, setFinalValues] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [budget, setBudget] = useState("");

  const toDateFunc = () => {
    if (detailsform && detailsform?.travellingdetails[1]) {
      if (detailsform?.travellingdetails[1]?.toDate === null) {
        return detailsform?.travellingdetails[1]?.fromDate
          ? new Date(detailsform?.travellingdetails[1]?.fromDate)
          : null;
      } else {
        return detailsform?.travellingdetails[1]?.toDate
          ? new Date(detailsform?.travellingdetails[1]?.toDate)
          : null;
      }
    } else {
      return null;
    }
  };
  const fromDateFunc = () => {
    return detailsform
      ? detailsform?.travellingdetails[0]?.fromDate
        ? new Date(detailsform?.travellingdetails[0]?.fromDate)
        : null
      : null;
  };
  const PickupLocation = () => {
    return detailsform ? detailsform?.travellingdetails[0]?.pickupLocation : "";
  };
  const DropLocation = () => {
    return detailsform ? detailsform?.travellingdetails[0]?.dropLocation : "";
  };
  const PickupLocationR = () => {
    return detailsform
      ? detailsform?.travellingdetails[1]?.pickupLocation &&
          detailsform?.travellingdetails[1]?.pickupLocation
      : "";
  };
  const DropLocationR = () => {
    return detailsform
      ? detailsform?.travellingdetails[1]?.dropLocation &&
          detailsform?.travellingdetails[1]?.dropLocation
      : "";
  };

  const millisecondsInAYear = 1000 * 60 * 60 * 24 * 365;

  const BookingNotefunc = () => {
    return detailsform ? detailsform?.travellingdetails[0]?.bookingnote : "";
  };
  const TravelingDetailsfunc = () => {
    return detailsform
      ? detailsform?.travellingdetails?.map((item) => ({
          id: item?.id ?? null,
          FromDate: item.fromDate
            ? item.fromDate === "0001-01-01T00:00:00"
              ? new Date(item.toDate)
              : new Date(item.fromDate)
            : null,
          PickupLocation: item?.pickupLocation,
          DropLocation: item?.dropLocation,
          Bookingnote: item?.bookingnote,
        }))
      : [
          {
            FromDate: null,
            PickupLocation: "",
            DropLocation: "",
            Bookingnote: "",
          },
        ];
  };
  const groupItemsDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.travelemployees
          ?.filter(
            (item, index) =>
              item?.travellingDetailId === detailsform?.travellingdetails[0]?.id
          )
          ?.map((items, indexs) => {
            return returnArray.push({
              id: items?.id ?? null,
              EmployeeId: items?.employeeId ?? null,
              Age: items?.age === 0 ? "" : items?.age,
              Gender: items?.gender ?? "",
              PhoneNumber: items?.phoneNumber ?? "",
              EmailId: items?.emailId ?? "",
            });
          })
      : (returnArray = [
          {
            travelId: 0,
            id: 0,
            EmployeeId: null,
            Age: null,
            Gender: "",
            PhoneNumber: "",
            EmailId: "",
          },
        ]);
    return returnArray;
  };

  const groupItemsMultiDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.travellingdetails?.map((item, index) => {
          return returnArray.push({
            id: item?.id ?? null,
            FromDate:
              item?.fromDate === "0001-01-01T00:00:00"
                ? item?.toDate
                  ? new Date(item?.toDate)
                  : null
                : item?.fromDate
                ? new Date(item?.fromDate)
                : null,

            PickupLocation: item?.pickupLocation ?? "",
            DropLocation: item?.dropLocation ?? "",
            Bookingnote: item?.bookingnote ?? "",
            EmployeeDetails: detailsform?.travelemployees
              ?.filter((items) => items?.travellingDetailId === item?.id)
              ?.map((it) => ({
                id: it?.id ?? null,
                EmployeeId: it?.employeeId ?? null,
                Age: it?.age === 0 ? "" : it?.age,
                Gender: it?.gender ?? "",
                PhoneNumber: it?.phoneNumber ?? "",
                EmailId: it?.emailId ?? "",
              })),
          });
        })
      : (returnArray = [
          {
            FromDate: null,
            PickupLocation: "",
            DropLocation: "",
            Bookingnote: "",
            EmployeeDetails: [
              {
                EmployeeId: null,
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ],
          },
        ]);
    return returnArray;
  };
  const vendorItemsDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.travelemployees
          ?.filter(
            (item, index) =>
              item?.travellingDetailId === detailsform?.travellingdetails[0]?.id
          )
          ?.map((items, indexs) => {
            return returnArray.push({
              id: items?.id ?? null,
              Name: items?.name ?? "",
              Age: items?.age ?? null,
              Gender: items?.gender ?? "",
              PhoneNumber: items?.phoneNumber ?? "",
              EmailId: items?.emailId ?? "",
            });
          })
      : (returnArray = [
          {
            travelId: 0,
            Name: "",
            Age: null,
            Gender: "",
            PhoneNumber: "",
            EmailId: "",
          },
        ]);
    return returnArray;
  };
  const vendorItemsMultiDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.travellingdetails?.map((item, index) => {
          return returnArray.push({
            id: item?.id ?? null,
            FromDate:
              item?.fromDate === "0001-01-01T00:00:00"
                ? item?.toDate
                  ? new Date(item?.toDate)
                  : null
                : item?.fromDate
                ? new Date(item?.fromDate)
                : null,
            PickupLocation: item?.pickupLocation ?? "",
            DropLocation: item?.dropLocation ?? "",
            Bookingnote: item?.bookingnote ?? "",
            EmployeeDetails: detailsform?.travelemployees
              ?.filter((items) => items?.travellingDetailId === item?.id)
              ?.map((it) => ({
                id: it?.id ?? null,
                Name: it?.name ?? "",
                Age: it?.age ?? null,
                Gender: it?.gender ?? "",
                PhoneNumber: it?.phoneNumber ?? "",
                EmailId: it?.emailId ?? "",
              })),
          });
        })
      : (returnArray = [
          {
            FromDate: null,
            PickupLocation: "",
            DropLocation: "",
            Bookingnote: "",

            EmployeeDetails: [
              {
                Name: "",
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ],
          },
        ]);
    return returnArray;
  };
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const [initialValues, setInitialValues] = useState({
    Mode: "Flight",
    Type: "Self",
    IsLinkedTravelHotel: false,
    CategoryID: null,
    TravelingType: "",
    FromDate: null,
    ToDate: null,
    PickupLocation: "",
    DropLocation: "",
    Age: null,
    Gender: "",
    Budget: "",
  });
  const tripInitialValuesSelf = {
    Mode: detailsform
      ? detailsform?._request?.mode === "Taxi"
        ? "Cab"
        : detailsform?._request?.mode
      : formValues?.Mode,
    Type: detailsform ? detailsform?._request?.type : "Self",
    IsLinkedTravelHotel: detailsform
      ? detailsform?._request?.isLinkedTravelHotel
      : false,
    CategoryID: detailsform
      ? detailsform?.travellingdetails[0]?.categoryID?.toString()
      : formValues?.CategoryID ?? "",
    // null,
    TravelingType: detailsform
      ? detailsform?.travellingdetails[0]?.travelingType
      : formValues?.TravelingType,
    FromDate: fromDateFunc(),
    ToDate: toDateFunc(),
    PickupLocation: PickupLocation(),
    DropLocation: DropLocation(),
    PickupLocationR: PickupLocationR(),
    DropLocationR: DropLocationR(),
    TravelHospitalityEmployeesId:
      formValues?.TravelingType === "Round Trip"
        ? detailsform
          ? [
              detailsform?.travelemployees[0]?.id,
              detailsform?.travelemployees[1]?.id,
            ]
          : [0, 0]
        : formValues?.TravelingType === "Multilocation Trip"
        ? detailsform
          ? detailsform?.travelemployees?.map((it) => it?.id)
          : []
        : formValues?.TravelingType === "One Way Trip" && detailsform
        ? detailsform?.travelemployees[0]?.id
        : 0,
    Age: detailsform
      ? detailsform?.travelemployees[0]?.age
      : Math.floor(
          (new Date() - new Date(LoggedInUser?.dob)) / millisecondsInAYear
        ),
    Gender: detailsform
      ? detailsform?.travelemployees[0]?.gender
      : LoggedInUser?.gender,
    Budget: detailsform ? detailsform?.travelemployees[0]?.travel?.budget : "",
    Bookingnote: BookingNotefunc(),
    TravelingDetails: TravelingDetailsfunc(),
  };

  const tripInitialValuesGroup = {
    Mode: detailsform
      ? detailsform?._request?.mode === "Taxi"
        ? "Cab"
        : detailsform?._request?.mode
      : formValues?.Mode,
    Type: detailsform ? detailsform?._request?.type : "Group",
    IsLinkedTravelHotel: detailsform
      ? detailsform?._request?.isLinkedTravelHotel
      : false,
    CategoryID: detailsform
      ? detailsform?.travellingdetails[0]?.categoryID?.toString()
      : formValues?.CategoryID ?? "",
    // null,
    TravelingType: detailsform
      ? detailsform?.travellingdetails[0]?.travelingType
      : formValues?.TravelingType,
    TravelHospitalityEmployeesId:
      formValues?.TravelingType === "Round Trip"
        ? detailsform
          ? detailsform?.travelemployees
              ?.filter(
                (it) =>
                  it?.travellingDetailId ===
                  detailsform?.travellingdetails[1]?.id
              )
              ?.map((it) => ({
                id: it?.id ?? null,
                EmployeeId: it?.employeeId ?? null,
                Age: it?.age === 0 ? "" : it?.age,
                Gender: it?.gender ?? "",
                PhoneNumber: it?.phoneNumber ?? "",
                EmailId: it?.emailId ?? "",
              }))
          : null
        : null,
    FromDate: fromDateFunc(),
    ToDate: toDateFunc(),
    PickupLocation: PickupLocation(),
    DropLocation: DropLocation(),
    PickupLocationR: PickupLocationR(),
    DropLocationR: DropLocationR(),
    Budget: detailsform ? detailsform?.travelemployees[0]?.travel?.budget : "",
    Bookingnote: BookingNotefunc(),
    groupItemsNotMulti: groupItemsDetailsfunc(),
    groupItems: groupItemsMultiDetailsfunc(),
  };
  const tripInitialValuesVendor = {
    Mode: detailsform
      ? detailsform?._request?.mode === "Taxi"
        ? "Cab"
        : detailsform?._request?.mode
      : formValues?.Mode,
    Type: detailsform ? detailsform?._request?.type : formValues?.Type,
    IsLinkedTravelHotel: detailsform
      ? detailsform?._request?.isLinkedTravelHotel
      : false,
    CategoryID: detailsform
      ? detailsform?.travellingdetails[0]?.categoryID?.toString()
      : formValues?.CategoryID ?? "",
    // null,
    TravelingType: detailsform
      ? detailsform?.travellingdetails[0]?.travelingType
      : formValues?.TravelingType,
    TravelHospitalityEmployeesId:
      formValues?.TravelingType === "Round Trip"
        ? detailsform
          ? detailsform?.travelemployees
              ?.filter(
                (it) =>
                  it?.travellingDetailId ===
                  detailsform?.travellingdetails[1]?.id
              )
              ?.map((it) => ({
                id: it?.id ?? null,
                Name: it?.name ?? "",
                Age: it?.age === 0 ? "" : it?.age,
                Gender: it?.gender ?? "",
                PhoneNumber: it?.phoneNumber ?? "",
                EmailId: it?.emailId ?? "",
              }))
          : null
        : null,
    FromDate: fromDateFunc(),
    ToDate: toDateFunc(),
    PickupLocation: PickupLocation(),
    DropLocation: DropLocation(),
    PickupLocationR: PickupLocationR(),
    DropLocationR: DropLocationR(),
    Budget: detailsform ? detailsform?.travelemployees[0]?.travel?.budget : "",
    Bookingnote: BookingNotefunc(),
    groupItemsNotMulti: vendorItemsDetailsfunc(),
    groupItems: vendorItemsMultiDetailsfunc(),
  };

  const tripInitialValuesCandidate = {
    Mode: detailsform
      ? detailsform?._request?.mode === "Taxi"
        ? "Cab"
        : detailsform?._request?.mode
      : formValues?.Mode,
    Type: detailsform ? detailsform?._request?.type : "Candidate",
    IsLinkedTravelHotel: detailsform
      ? detailsform?._request?.isLinkedTravelHotel
      : false,
    CategoryID: detailsform
      ? detailsform?.travellingdetails[0]?.categoryID?.toString()
      : formValues?.CategoryID ?? "",
    // null,
    TravelingType: detailsform
      ? detailsform?.travellingdetails[0]?.travelingType
      : formValues?.TravelingType,
    CandidateId: detailsform
      ? detailsform?.travelemployees[0]?.candidateId
      : null,
    TravelHospitalityEmployeesId:
      formValues?.TravelingType === "Round Trip"
        ? detailsform
          ? [
              detailsform?.travelemployees[0]?.id,
              detailsform?.travelemployees[1]?.id,
            ]
          : [0, 0]
        : formValues?.TravelingType === "Multilocation Trip"
        ? detailsform?.travelemployees?.length > 0
          ? detailsform?.travelemployees?.map((it) => it?.id)
          : []
        : formValues?.TravelingType === "One Way Trip" && detailsform
        ? detailsform?.travelemployees[0]?.id
        : 0,
    FromDate: fromDateFunc(),
    ToDate: toDateFunc(),
    PickupLocation: PickupLocation(),
    DropLocation: DropLocation(),
    PickupLocationR: PickupLocationR(),
    DropLocationR: DropLocationR(),
    Age: detailsform ? detailsform?.travelemployees[0]?.age : null,
    Gender: detailsform ? detailsform?.travelemployees[0]?.gender : "",
    Budget: detailsform ? detailsform?.travelemployees[0]?.travel?.budget : "",
    Bookingnote: BookingNotefunc(),
    TravelingDetails: TravelingDetailsfunc(),
  };

  useEffect(() => {
    if (formValues?.Type === "Self") {
      setInitialValues(tripInitialValuesSelf);
    } else if (formValues?.Type === "Group") {
      setInitialValues(tripInitialValuesGroup);
    } else if (formValues?.Type === "Vendor") {
      setInitialValues(tripInitialValuesVendor);
    } else if (formValues?.Type === "Guest") {
      setInitialValues(tripInitialValuesVendor);
    } else if (formValues?.Type === "Candidate") {
      setInitialValues(tripInitialValuesCandidate);
    }
  }, [
    formValues?.Type,
    // formValues?.Mode,
    formValues?.TravelingType,
    detailsform,
  ]);

  useEffect(() => {
    getEmployeeData();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("object initialValues", initialValues);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${data}`
      );
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  console.log("object formValues", formValues);
  useEffect(() => {
    if (data) getFormDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      !detailsform && // if not written it wont let edit autofill work as it should
      formValues?.TravelingType === "Round Trip" &&
      (formValues?.PickupLocation?.length > 0 ||
        formValues?.DropLocation?.length > 0)
    ) {
      setInitialValues(() => {
        let obj = { ...formValues };
        obj.PickupLocationR = formValues?.DropLocation;
        obj.DropLocationR = formValues?.PickupLocation;
        return obj;
      });
    }
  }, [formValues?.PickupLocation, formValues?.DropLocation]);

  const create = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(url, values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(url, values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const pushEmployee = (index) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails =
        formValues?.Type === "Vendor" || "Guest"
          ? [
              ...newGroupItems[index].EmployeeDetails,
              {
                Name: null,
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ]
          : [
              ...newGroupItems[index].EmployeeDetails,
              {
                EmployeeId: null,
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ];
      newGroupItems[index] = {
        ...newGroupItems[index],
        EmployeeDetails: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  const popAnswer = (index, indexW) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails = newGroupItems[index].EmployeeDetails?.filter(
        (item, ind) => ind !== indexW
      );

      newGroupItems[index] = {
        ...newGroupItems[index],
        EmployeeDetails: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };

  const getValidationSchema = () => {
    if (formValues) {
      if (formValues.Type === "Self") {
        if (formValues?.TravelingType === "One Way Trip")
          return flightSelfOneWaySchema;
        else if (formValues?.TravelingType === "Round Trip")
          return flightSelfRoundTripSchema;
        else if (formValues?.TravelingType === "Multilocation Trip")
          return flightSelfMultiTripSchema;
      } else if (formValues.Type === "Group") {
        if (formValues?.TravelingType === "One Way Trip")
          return flightGroupOneWaySchema;
        else if (formValues?.TravelingType === "Round Trip")
          return flightGroupRoundTripSchema;
        else if (formValues?.TravelingType === "Multilocation Trip")
          return flightGroupMultiTripSchema;
      } else if (formValues.Type === "Vendor" || formValues.Type === "Guest") {
        if (formValues?.TravelingType === "One Way Trip")
          return flightVendorOneWaySchema;
        else if (formValues?.TravelingType === "Round Trip")
          return flightVendorRoundTripSchema;
        else if (formValues?.TravelingType === "Multilocation Trip")
          return flightVendorMultiTripSchema;
      } else {
        if (formValues?.TravelingType === "One Way Trip")
          return flightCandidateOneWaySchema;
        else if (formValues?.TravelingType === "Round Trip")
          return flightCandidateRoundTripSchema;
        else if (formValues?.TravelingType === "Multilocation Trip")
          return flightCandidateMultiTripSchema;
      }
    }
    return Yup.object({
      TravelingType: Yup.string().required("Please Select Travel Type"),
    });
  };
  const flightSelfOneWaySchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    FromDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
  });
  const flightSelfRoundTripSchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    FromDate: Yup.string().required(" Please Select Date"),
    ToDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    PickupLocationR: Yup.string().required("Please Enter the Pickup Location."),
    DropLocationR: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.number().required("ksghdfigsdifh"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
  });
  const flightSelfMultiTripSchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    TravelingDetails: Yup.array().of(
      Yup.object().shape({
        FromDate: Yup.string().required("Please Select Date"),
        PickupLocation: Yup.string().required("Please Enter Pickup Location"),
        DropLocation: Yup.string().required("Please Enter Drop Location"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });
  const flightGroupOneWaySchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    FromDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        EmployeeId: Yup.string().required("Please Select Employee"),
        PhoneNumber: Yup.number()
          .typeError("Please enter a valid phone number")
          .required("Please Enter Phone Number"),
        // Age: Yup.string().required("Please Enter Age"),
        // Gender: Yup.string().required("Please Select Gender"),
        // EmailId: Yup.string().required("Please Enter Email"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });
  const flightGroupRoundTripSchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    FromDate: Yup.string().required(" Please Select Date"),
    ToDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    PickupLocationR: Yup.string().required("Please Enter the Pickup Location."),
    DropLocationR: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.number().required("ksghdfigsdifh"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        EmployeeId: Yup.string().required("Please Select Employee"),
        PhoneNumber: Yup.number()
          .typeError("Please enter a valid phone number")
          .required("Please Enter Phone Number"),
        // Age: Yup.string().required("Please Enter Age"),
        // Gender: Yup.string().required("Please Select Gender"),
        // EmailId: Yup.string().required("Please Enter Email"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });
  const flightGroupMultiTripSchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    groupItems: Yup.array().of(
      Yup.object().shape({
        FromDate: Yup.string().required("Please Select Date"),
        PickupLocation: Yup.string().required("Please Enter Pickup Location"),
        DropLocation: Yup.string().required("Please Enter Drop Location"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            EmployeeId: Yup.string().required("Please Select Employee"),
            PhoneNumber: Yup.number()
              .typeError("Please enter a valid phone number")
              .required("Please Enter Phone Number"),
            // Age: Yup.string().required("Please Enter Age"),
            // Gender: Yup.string().required("Please Select Gender"),
            // EmailId: Yup.string().required("Please Enter Email"),
          })
        ),
      })
    ),
  });
  const flightVendorOneWaySchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    FromDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().required("Please Enter Name"),
        PhoneNumber: Yup.number()
          .typeError("Please enter a valid phone number")
          .required("Please Enter Phone Number"),
        // Age: Yup.string().required("Please Enter Age"),
        // Gender: Yup.string().required("Please Select Gender"),
        // EmailId: Yup.string().required("Please Enter Email"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });
  const flightVendorRoundTripSchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    FromDate: Yup.string().required(" Please Select Date"),
    ToDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    PickupLocationR: Yup.string().required("Please Enter the Pickup Location."),
    DropLocationR: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.number().required("ksghdfigsdifh"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().required("Please Enter Name"),
        // Age: Yup.string().required("Please Enter Age"),
        // Gender: Yup.string().required("Please Select Gender"),
        PhoneNumber: Yup.string().required("Please Enter Phone Number"),
        // EmailId: Yup.string().required("Please Enter Email"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });
  const flightVendorMultiTripSchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    groupItems: Yup.array().of(
      Yup.object().shape({
        FromDate: Yup.string().required("Please Select Date"),
        PickupLocation: Yup.string().required("Please Enter Pickup Location"),
        DropLocation: Yup.string().required("Please Enter Drop Location"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            Name: Yup.string().required("Please Enter Name"),
            // Age: Yup.string().required("Please Enter Age"),
            // Gender: Yup.string().required("Please Select Gender"),
            PhoneNumber: Yup.string().required("Please Enter Phone Number"),
            // EmailId: Yup.string().required("Please Enter Email"),
          })
        ),
      })
    ),
  });
  const flightCandidateOneWaySchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    CandidateId: Yup.string().required(" Please Select Candidate"),
    FromDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
  });
  const flightCandidateRoundTripSchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    CandidateId: Yup.string().required(" Please Select Candidate"),
    FromDate: Yup.string().required(" Please Select Date"),
    ToDate: Yup.string().required(" Please Select Date"),
    PickupLocation: Yup.string().required("Please Enter the Pickup Location."),
    DropLocation: Yup.string().required(" Please Enter the Drop Location."),
    PickupLocationR: Yup.string().required("Please Enter the Pickup Location."),
    DropLocationR: Yup.string().required(" Please Enter the Drop Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.number().required("ksghdfigsdifh"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
  });
  const flightCandidateMultiTripSchema = Yup.object({
    TravelingType: Yup.string().required(" Please Select Travel Type"),
    CategoryID: Yup.string().required(" Please Select Category"),
    CandidateId: Yup.string().required(" Please Select Candidate"),
    Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    TravelingDetails: Yup.array().of(
      Yup.object().shape({
        FromDate: Yup.string().required("Please Select Date"),
        PickupLocation: Yup.string().required("Please Enter Pickup Location"),
        DropLocation: Yup.string().required("Please Enter Drop Location"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });

  const onSubmitCheck = (values) => {
    // setShowPopup(true);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div
              style={{
                width: "400px",
                backgroundColor: "white",
                borderRadius: " 6px",
              }}
            >
              <CheckSubmit
                isEdit={false}
                data={data ? data : false}
                values={values}
                onSubmit={onSubmit}
                onClose={onClose}
                navigate={navigate}
              />
            </div>
          </div>
        );
      },
    });
  };
  const onSubmitCheckEdit = (values) => {
    // setShowPopup(true);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div
              style={{
                width: "400px",
                backgroundColor: "white",
                borderRadius: " 6px",
              }}
            >
              <CheckSubmit
                isEdit={true}
                data={data ? data : false}
                values={values}
                onSubmit={onSubmit}
                onClose={onClose}
                navigate={navigate}
              />
            </div>
          </div>
        );
      },
    });
  };
  console.log(
    "object TravelHospitalityEmployeesId",
    formValues?.TravelHospitalityEmployeesId
  );
  const onSubmit = (values) => {
    const payload = {
      id: data ? detailsform?._request?.id : 0,
    };
    if (values) {
      if (values.Type === "Self") {
        if (values.TravelingType === "One Way Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;
          const TravelingDetailsObj = [
            {
              id: values?.id ?? 0,
              TravelingType: values.TravelingType,
              FromDate:
                format(new Date(values.FromDate), "yyyy-MM-dd") +
                "T00:00:00.000",
              PickupLocation: values?.PickupLocation,
              DropLocation: values?.DropLocation,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  id: values?.TravelHospitalityEmployeesId,
                  Age: Number(values?.Age),
                  Gender: values?.Gender,
                },
              ],
            },
          ];
          payload["TravelingDetails"] = TravelingDetailsObj;
        }
        if (values.TravelingType === "Round Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;
          const TravelingDetailsObj = [
            {
              id: detailsform ? detailsform?.travellingdetails[0]?.id : 0,
              TravelingType: values.TravelingType,
              FromDate:
                format(new Date(values.FromDate), "yyyy-MM-dd") +
                "T00:00:00.000",
              PickupLocation: values?.PickupLocation,
              DropLocation: values?.DropLocation,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  id: values?.TravelHospitalityEmployeesId[0],
                  Age: Number(values?.Age),
                  Gender: values?.Gender,
                },
              ],
            },
            {
              id: detailsform ? detailsform?.travellingdetails[1]?.id : 0,
              TravelingType: values.TravelingType,
              ToDate:
                format(new Date(values.ToDate), "yyyy-MM-dd") + "T00:00:00.000",
              PickupLocation: values?.PickupLocationR,
              DropLocation: values?.DropLocationR,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  id: values?.TravelHospitalityEmployeesId[1],
                  Age: Number(values?.Age),
                  Gender: values?.Gender,
                },
              ],
            },
          ];
          payload["TravelingDetails"] = TravelingDetailsObj;
        }
        if (values.TravelingType === "Multilocation Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;

          const TravelingDetailsObj = values?.TravelingDetails?.map(
            (item, ind) => ({
              id: item?.id ?? 0,
              FromDate:
                format(new Date(item.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
              PickupLocation: item?.PickupLocation,
              DropLocation: item?.DropLocation,
              Bookingnote: item?.Bookingnote,
              TravelingType: values.TravelingType,
              CategoryID: values.CategoryID,
              TravelHospitalityEmployees: [
                {
                  id: values?.TravelHospitalityEmployeesId[ind],
                  Age: Number(values?.Age),
                  Gender: values?.Gender,
                },
              ],
            })
          );

          payload["TravelingDetails"] = TravelingDetailsObj;
        }
      } else if (values.Type === "Group") {
        if (values.TravelingType === "One Way Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;
          const TravelingDetailsObj = [
            {
              id: values?.id ?? 0,
              TravelingType: values.TravelingType,
              FromDate:
                format(new Date(values.FromDate), "yyyy-MM-dd") +
                "T00:00:00.000",
              PickupLocation: values?.PickupLocation,
              DropLocation: values?.DropLocation,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: values?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,
                  Name: employeeList?.filter(
                    (item) => item?.value === items?.EmployeeId
                  )[0]?.label,
                  EmployeeId: items?.EmployeeId,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
          ];
          payload["TravelingDetails"] = TravelingDetailsObj;
        }
        if (values.TravelingType === "Round Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;
          const TravelingDetailsObj = [
            {
              id: detailsform ? detailsform?.travellingdetails[0]?.id : 0,
              TravelingType: values.TravelingType,
              FromDate:
                format(new Date(values.FromDate), "yyyy-MM-dd") +
                "T00:00:00.000",
              PickupLocation: values?.PickupLocation,
              DropLocation: values?.DropLocation,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: values?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,
                  Name: employeeList?.filter(
                    (item) => item?.value === items?.EmployeeId
                  )[0]?.label,
                  EmployeeId: items?.EmployeeId,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
            {
              id: detailsform ? detailsform?.travellingdetails[1]?.id : 0,
              TravelingType: values.TravelingType,
              ToDate:
                format(new Date(values.ToDate), "yyyy-MM-dd") + "T00:00:00.000",
              PickupLocation: values?.PickupLocationR,
              DropLocation: values?.DropLocationR,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: detailsform
                ? [
                    ...values?.TravelHospitalityEmployeesId?.map((items) => ({
                      id: items?.id ?? 0,
                      Name: employeeList?.filter(
                        (item) => item?.value === items?.EmployeeId
                      )[0]?.label,
                      EmployeeId: items?.EmployeeId,
                      PhoneNumber: items?.PhoneNumber?.toString(),
                      EmailId: items?.EmailId,
                      Age: Number(items?.Age),
                      Gender: items?.Gender,
                    })),
                    ...values?.groupItemsNotMulti
                      ?.filter((it) => !it?.id)
                      ?.map((items) => ({
                        id: items?.id ?? 0,
                        Name: employeeList?.filter(
                          (item) => item?.value === items?.EmployeeId
                        )[0]?.label,
                        EmployeeId: items?.EmployeeId,
                        PhoneNumber: items?.PhoneNumber?.toString(),
                        EmailId: items?.EmailId,
                        Age: Number(items?.Age),
                        Gender: items?.Gender,
                      })),
                  ]
                : values?.groupItemsNotMulti
                    ?.filter((it) => !it?.id)
                    ?.map((items) => ({
                      id: items?.id ?? 0,
                      Name: employeeList?.filter(
                        (item) => item?.value === items?.EmployeeId
                      )[0]?.label,
                      EmployeeId: items?.EmployeeId,
                      PhoneNumber: items?.PhoneNumber?.toString(),
                      EmailId: items?.EmailId,
                      Age: Number(items?.Age),
                      Gender: items?.Gender,
                    })),
            },
          ];
          payload["TravelingDetails"] = TravelingDetailsObj;
        }
        if (values.TravelingType === "Multilocation Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;

          const TravelingDetailsObj = values?.groupItems?.map((item) => ({
            id: item?.id ?? 0,
            TravelingType: values.TravelingType,
            CategoryID: values.CategoryID,
            FromDate:
              format(new Date(item.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: item?.PickupLocation,
            DropLocation: item?.DropLocation,
            Bookingnote: item?.Bookingnote,
            TravelHospitalityEmployees: item?.EmployeeDetails?.map((items) => ({
              id: items?.id ?? 0,
              Name: employeeList?.filter(
                (item) => item?.value === items?.EmployeeId
              )[0]?.label,
              EmployeeId: items?.EmployeeId,
              PhoneNumber: items?.PhoneNumber?.toString(),
              EmailId: items?.EmailId,
              Age: Number(items?.Age),
              Gender: items?.Gender,
            })),
          }));

          payload["TravelingDetails"] = TravelingDetailsObj;
        }
      } else if (values.Type === "Vendor" || values.Type === "Guest") {
        if (values.TravelingType === "One Way Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;
          const TravelingDetailsObj = [
            {
              id: values?.id ?? 0,
              TravelingType: values.TravelingType,
              FromDate:
                format(new Date(values.FromDate), "yyyy-MM-dd") +
                "T00:00:00.000",
              PickupLocation: values?.PickupLocation,
              DropLocation: values?.DropLocation,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: values?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,
                  Name: items?.Name,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
          ];
          payload["TravelingDetails"] = TravelingDetailsObj;
        }
        if (values.TravelingType === "Round Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;
          const TravelingDetailsObj = [
            {
              id: detailsform ? detailsform?.travellingdetails[0]?.id : 0,
              TravelingType: values.TravelingType,
              FromDate:
                format(new Date(values.FromDate), "yyyy-MM-dd") +
                "T00:00:00.000",
              PickupLocation: values?.PickupLocation,
              DropLocation: values?.DropLocation,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: values?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,
                  Name: items?.Name,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
            {
              id: detailsform ? detailsform?.travellingdetails[1]?.id : 0,
              TravelingType: values.TravelingType,
              ToDate:
                format(new Date(values.ToDate), "yyyy-MM-dd") + "T00:00:00.000",
              PickupLocation: values?.PickupLocationR,
              DropLocation: values?.DropLocationR,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: detailsform
                ? [
                    ...values?.TravelHospitalityEmployeesId?.map((items) => ({
                      id: items?.id ?? 0,
                      Name: items?.Name,
                      PhoneNumber: items?.PhoneNumber?.toString(),
                      EmailId: items?.EmailId,
                      Age: Number(items?.Age),
                      Gender: items?.Gender,
                    })),
                    ...values?.groupItemsNotMulti
                      ?.filter((it) => !it?.id)
                      ?.map((items) => ({
                        id: items?.id ?? 0,
                        Name: items?.Name,
                        PhoneNumber: items?.PhoneNumber?.toString(),
                        EmailId: items?.EmailId,
                        Age: Number(items?.Age),
                        Gender: items?.Gender,
                      })),
                  ]
                : values?.groupItemsNotMulti
                    ?.filter((it) => !it?.id)
                    ?.map((items) => ({
                      id: items?.id ?? 0,
                      Name: items?.Name,
                      PhoneNumber: items?.PhoneNumber?.toString(),
                      EmailId: items?.EmailId,
                      Age: Number(items?.Age),
                      Gender: items?.Gender,
                    })),
            },
          ];
          payload["TravelingDetails"] = TravelingDetailsObj;
        }
        if (values.TravelingType === "Multilocation Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;

          const TravelingDetailsObj = values?.groupItems?.map((item) => ({
            id: item?.id ?? 0,
            TravelingType: values.TravelingType,
            CategoryID: values.CategoryID,
            FromDate:
              format(new Date(item.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
            PickupLocation: item?.PickupLocation,
            DropLocation: item?.DropLocation,
            Bookingnote: item?.Bookingnote,
            TravelHospitalityEmployees: item?.EmployeeDetails?.map((items) => ({
              id: items?.id ?? 0,
              Name: items?.Name,
              PhoneNumber: items?.PhoneNumber?.toString(),
              EmailId: items?.EmailId,
              Age: Number(items?.Age),
              Gender: items?.Gender,
            })),
          }));

          payload["TravelingDetails"] = TravelingDetailsObj;
        }
      } else if (values.Type === "Candidate") {
        if (values.TravelingType === "One Way Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;
          const TravelingDetailsObj = [
            {
              id: values?.id ?? 0,
              TravelingType: values.TravelingType,
              FromDate:
                format(new Date(values.FromDate), "yyyy-MM-dd") +
                "T00:00:00.000",
              PickupLocation: values?.PickupLocation,
              DropLocation: values?.DropLocation,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  id: values?.TravelHospitalityEmployeesId,
                  Age: Number(values?.Age),
                  Gender: values?.Gender,
                  CandidateId: values.CandidateId,
                },
              ],
            },
          ];
          payload["TravelingDetails"] = TravelingDetailsObj;
        }
        if (values.TravelingType === "Round Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;
          const TravelingDetailsObj = [
            {
              id: detailsform ? detailsform?.travellingdetails[0]?.id : 0,
              TravelingType: values.TravelingType,
              CategoryID: values.CategoryID,
              FromDate:
                format(new Date(values.FromDate), "yyyy-MM-dd") +
                "T00:00:00.000",
              PickupLocation: values?.PickupLocation,
              DropLocation: values?.DropLocation,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  id: values?.TravelHospitalityEmployeesId[0],
                  Age: Number(values?.Age),
                  Gender: values?.Gender,
                  CandidateId: values.CandidateId,
                },
              ],
            },
            {
              id: detailsform ? detailsform?.travellingdetails[1]?.id : 0,
              TravelingType: values.TravelingType,
              ToDate:
                format(new Date(values.ToDate), "yyyy-MM-dd") + "T00:00:00.000",
              PickupLocation: values?.PickupLocationR,
              DropLocation: values?.DropLocationR,
              CategoryID: values.CategoryID,
              Bookingnote: values?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  id: values?.TravelHospitalityEmployeesId[1],
                  Age: values?.Age,
                  Gender: values?.Gender,
                  CandidateId: values.CandidateId,
                },
              ],
            },
          ];
          payload["TravelingDetails"] = TravelingDetailsObj;
        }
        if (values.TravelingType === "Multilocation Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = values.Mode === "Cab" ? "Taxi" : values.Mode;
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = false;

          const TravelingDetailsObj = values?.TravelingDetails?.map(
            (item, ind) => ({
              id: item?.id ?? 0,
              TravelingType: values.TravelingType,
              CategoryID: values.CategoryID,
              FromDate:
                format(new Date(item.FromDate), "yyyy-MM-dd") + "T00:00:00.000",
              PickupLocation: item?.PickupLocation,
              DropLocation: item?.DropLocation,
              Bookingnote: item?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  id: values?.TravelHospitalityEmployeesId[ind],
                  Age: Number(values?.Age),
                  Gender: values?.Gender,
                  CandidateId: values.CandidateId,
                },
              ],
            })
          );

          payload["TravelingDetails"] = TravelingDetailsObj;
        }
      }
    }
    if (data) {
      if (values?.Mode === "Train")
        Edit(payload, "TravelHospitalityRequests/Edit");
      else
        Edit(payload, `TravelHospitalityRequests/Edit${values?.Mode}Request`);
    } else {
      if (values?.Mode === "Train")
        create(payload, "TravelHospitalityRequests/Create");
      else
        create(
          payload,
          `TravelHospitalityRequests/Create${values?.Mode}Request`
        );
    }
  };
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Travel Booking" : "Create Travel Booking"}
      chooseFormValues={chooseFormValues}
      ButtonLabel={data ? "Edit Travel Booking" : "Create Travel Booking"}
      onSubmit={data ? onSubmit : onSubmitCheck}
      // onSubmit={(val) => {
      // data && detailsform?._request?.isLinkedTravelHotel === true
      //   ? onSubmitCheckEdit(val)
      // :
      //   onSubmitCheck(val);
      // }}
      initialvalue={initialValues}
      returnPage={returnPage}
      validationSchema={getValidationSchema}
      styles={{ maxWidth: "90%", width: "90%" }}
      propsDiv={
        <CreateTravelBookingComp
          LoggedInUser={LoggedInUser}
          setLoading={setLoading}
          employeeList={employeeList}
          loading={loading}
          setFormValues={setFormValues}
          setInitialValues={setInitialValues}
          formValues={formValues}
          editTrue={data ? true : false}
          pushEmployee={pushEmployee}
          popAnswer={popAnswer}
        />
      }
    />
  );
};

export { CreateTravelBooking };

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateReimbursementComp } from "./FormComp/CreateReimbursementComp";
import { format } from "date-fns";
import { Receipt } from "@mui/icons-material";

const CreateReimbursement = () => {
  const { state } = useLocation();
  const data = state?.id;
  const [claimCount, setClaimCount] = useState(0);
  useEffect(() => {
    document.title = `PeopleSol - Create Reimbursement`;

    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [formValues, setFormValues] = useState(null);
  const [editIDData, seteditIDData] = useState(false);
  const [detailsform, setDetailsform] = useState({});
  const [resultData, setResultData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  console.log("object data", data);
  // const getInitialValues = (item) => {
  //   let returnArray = [
  //     {
  //       Date: "",
  //       ExpenseTypes: "",
  //       Amount: "",
  //       IsReceiptAvailable: "Yes",
  //       Upload: "",
  //       Remarks: "",
  //     },
  //   ];
  //   if (data && item) {
  //     if (item.length > 0) {
  //       returnArray = [];
  //       item?.map((items) => {
  //         return returnArray.push({
  //           Id: items.id,
  //           Date: items.date ? new Date(items.date) : null,
  //           ExpenseTypeId: items.expenseTypeId,
  //           Amount: items.amount,
  //           IsReceiptAvailable: items.isReceiptAvailable,
  //           Upload: items.expenseTypeId
  //             ? items.isReceiptAvailable === "Yes"
  //               ? items.attachment
  //                 ? `TravelBookings/${items.attachment}`
  //                 : ""
  //               : items.justification ?? ""
  //             : items.isReceiptAvailable === "Yes"
  //             ? items.attachment
  //               ? `Expenses/${items.attachment}`
  //               : ""
  //             : items.justification ?? "",

  //           Remarks: items?.remarks,
  //         });
  //       });
  //     }
  //   }
  //   return returnArray;
  // };
  // const getInitialValuesRepeated = (item, date, budget, bookinglist) => {
  //   let returnArray = [
  //     {
  //       Date: "",
  //       ExpenseTypeId: "",
  //       Amount: "",
  //       IsReceiptAvailable: "Yes",
  //       Upload: "",
  //       Remarks: "",
  //     },
  //   ];
  //   if (item) {
  //     if (item.length > 0) {
  //       returnArray = [];
  //       item?.map((item) => {
  //         return returnArray.push({
  //           Date: date ? new Date(date) : null,
  //           ExpenseTypeId: "",
  //           Amount: budget ? budget : "",
  //           IsReceiptAvailable: bookinglist[0]?.attachment ? "Yes" : "No",
  //           Upload: bookinglist[0]?.attachment
  //             ? `TravelBookings/${bookinglist[0]?.attachment}`
  //             : "",
  //           Remarks: "",
  //         });
  //       });
  //     }
  //   }
  //   return returnArray;
  // };
  const groupItemsMultiDetailsfunc = () => {
    let returnArray = [];
    data
      ? (returnArray = resultData?.travellingdetails
          ? resultData?.travellingdetails
              ?.map((person, index) => {
                if (person?.isEdited === true) {
                  return Array?.isArray(resultData?.travelDetailNnew) &&
                    resultData?.travelDetailNnew?.length > 0
                    ? resultData?.travelDetailNnew
                        ?.filter(
                          (it) => person.id === it?.originalTravelDetailId
                        )
                        ?.map((itemz) => ({
                          FromDate: itemz?.fromDate
                            ? new Date(itemz?.fromDate)
                            : null,
                          PickupLocation: itemz?.pickupLocation,
                          DropLocation: itemz?.dropLocation,
                          Receipt: itemz?.finalTicket,
                          TravalId: itemz?.id,
                          isOriginalTravelDetailId: true,
                          Expenselist:
                            detailsform?.expenseDetails?.filter(
                              (itz) => itz?.travellingdetailsidNew === itemz?.id
                            )?.length > 0
                              ? detailsform?.expenseDetails
                                  ?.filter(
                                    (itz) =>
                                      itz?.travellingdetailsidNew === itemz?.id
                                  )
                                  ?.map((item, index) => {
                                    return {
                                      id: item?.id ?? null,
                                      ExpenseTypes: data
                                        ? item?.expenseTypes
                                        : "",
                                      Date: data ? new Date(item?.date) : "",
                                      fromlocation: data
                                        ? item?.fromlocation
                                        : "",
                                      tolocation: data ? item?.tolocation : "",
                                      categoryid: data
                                        ? item?.categoryid?.toString()
                                        : "",
                                      type: data ? Number(item?.type) : "",
                                      totalkm: data ? item?.totalkm : "",
                                      cost: data ? item?.cost : "",
                                      Amount: data ? item?.amount : "",
                                      IsReceiptAvailable: data
                                        ? item?.isReceiptAvailable
                                        : "",
                                      Upload: data ? item?.attachment : "",
                                      Justification: data
                                        ? item?.justification
                                        : "",
                                      Remarks: data ? item?.remarks : "",
                                      // mode: data ? item?.mode : "",
                                      totaltickets: data
                                        ? item?.totaltickets
                                        : "",
                                      totalitems: data ? item?.totalitems : "",
                                      totaldays: data ? item?.totaldays : "",
                                    };
                                  })
                              : [
                                  {
                                    id: 0,
                                    ExpenseTypes: "",
                                    Date: "",
                                    fromlocation: "",
                                    tolocation: "",
                                    categoryid: "",
                                    type: "",
                                    totalkm: "",
                                    cost: "",
                                    Amount: "",
                                    IsReceiptAvailable: "",
                                    Upload: "",
                                    Justification: "",
                                    Remarks: "",
                                    // mode: data ? item?.mode : "",
                                    totaltickets: "",
                                    totalitems: "",
                                    totaldays: "",
                                  },
                                ],
                        }))
                    : [];
                } else {
                  return {
                    FromDate: person?.fromDate
                      ? new Date(person?.fromDate)
                      : null,
                    PickupLocation: person?.pickupLocation,
                    DropLocation: person?.dropLocation,
                    Receipt: person?.finalTicket,
                    TravalId: person?.id,
                    isOriginalTravelDetailId: false,

                    Expenselist:
                      detailsform?.expenseDetails?.filter(
                        (itz) => itz?.travellingdetailsid === person?.id
                      )?.length > 0
                        ? detailsform?.expenseDetails
                            ?.filter(
                              (itz) => itz?.travellingdetailsid === person?.id
                            )
                            ?.map((item, index) => {
                              return {
                                id: item?.id ?? null,
                                ExpenseTypes: data ? item?.expenseTypes : "",
                                Date: data ? new Date(item?.date) : "",
                                fromlocation: data ? item?.fromlocation : "",
                                tolocation: data ? item?.tolocation : "",
                                categoryid: data
                                  ? item?.categoryid?.toString()
                                  : "",
                                type: data ? Number(item?.type) : "",
                                totalkm: data ? item?.totalkm : "",
                                cost: data ? item?.cost : "",
                                Amount: data ? item?.amount : "",
                                IsReceiptAvailable: data
                                  ? item?.isReceiptAvailable
                                  : "",
                                Upload: data ? item?.attachment : "",
                                Justification: data ? item?.justification : "",
                                Remarks: data ? item?.remarks : "",
                                // mode: data ? item?.mode : "",
                                totaltickets: data ? item?.totaltickets : "",
                                totalitems: data ? item?.totalitems : "",
                                totaldays: data ? item?.totaldays : "",
                              };
                            })
                        : [
                            {
                              id: 0,
                              ExpenseTypes: "",
                              Date: "",
                              fromlocation: "",
                              tolocation: "",
                              categoryid: "",
                              type: "",
                              totalkm: "",
                              cost: "",
                              Amount: "",
                              IsReceiptAvailable: "",
                              Upload: "",
                              Justification: "",
                              Remarks: "",
                              // mode: data ? item?.mode : "",
                              totaltickets: "",
                              totalitems: "",
                              totaldays: "",
                            },
                          ],
                  };
                }
              })
              ?.flat()
          : [])
      : (returnArray = []);
    return returnArray;
  };
  const groupItemsMultiHotelDetailsfunc = () => {
    let returnArray = [];
    data
      ? (returnArray =
          resultData?.hoteldetail?.length > 0
            ? resultData?.hoteldetail
                ?.map((person, index) => {
                  // if (person?.status?.toLowerCase()?.includes("edited")) {
                  if (person?.isEdited === true) {
                    return Array?.isArray(resultData?.hotelDetailsNew) &&
                      resultData?.hotelDetailsNew?.length > 0
                      ? resultData?.hotelDetailsNew
                          ?.filter(
                            (it) => person.id === it?.originalHotelBookinglId
                          )
                          ?.map((itemz) => ({
                            HotelcheckInTime: itemz?.checkInTime
                              ? new Date(itemz?.checkInTime)
                              : null,
                            HotelcheckOutTime: itemz?.checkOutTime
                              ? new Date(itemz?.checkOutTime)
                              : null,
                            Hotellocation: itemz?.location ?? "",
                            HotelpreferHotel: itemz?.preferHotel ?? "",
                            Receipt: itemz?.finalTicket,
                            TravalId: itemz?.id,
                            isOriginalTravelDetailId: true,

                            Expenselist:
                              detailsform?.expenseDetails?.filter(
                                (itz) => itz?.hotelDetailsIdNew === itemz?.id
                              )?.length > 0
                                ? detailsform?.expenseDetails
                                    ?.filter(
                                      (itz) =>
                                        itz?.hotelDetailsIdNew === itemz?.id
                                    )
                                    ?.map((item, index) => {
                                      return {
                                        id: item?.id ?? null,
                                        ExpenseTypes: item?.expenseTypes,
                                        Date: new Date(item?.date),
                                        fromlocation: item?.fromlocation,
                                        tolocation: item?.tolocation,
                                        categoryid: data
                                          ? item?.categoryid?.toString()
                                          : "",
                                        // type: Number(
                                        //   item?.travellingCategoriesDetails?.type
                                        // ),
                                        type: data ? Number(item?.type) : "",
                                        totalkm: item?.totalkm,
                                        cost: item?.cost,
                                        Amount: item?.amount,
                                        IsReceiptAvailable:
                                          item?.isReceiptAvailable,
                                        Upload: item?.attachment,
                                        Justification: item?.justification,
                                        Remarks: item?.remarks,
                                        // mode: item?.mode,
                                        totaltickets: item?.totaltickets,
                                        totalitems: item?.totalitems,
                                        totaldays: item?.totaldays,
                                      };
                                    })
                                : [
                                    {
                                      id: 0,
                                      ExpenseTypes: "",
                                      Date: "",
                                      fromlocation: "",
                                      tolocation: "",
                                      categoryid: "",
                                      // type: Number(
                                      //   item?.travellingCategoriesDetails?.type
                                      // ),
                                      type: "",
                                      totalkm: "",
                                      cost: "",
                                      Amount: "",
                                      IsReceiptAvailable: "",
                                      Upload: "",
                                      Justification: "",
                                      Remarks: "",
                                      // mode: item?.mode,
                                      totaltickets: "",
                                      totalitems: "",
                                      totaldays: "",
                                    },
                                  ],
                          }))
                      : [];
                  } else {
                    return {
                      HotelcheckInTime: person?.checkInTime
                        ? new Date(person?.checkInTime)
                        : null,
                      HotelcheckOutTime: person?.checkOutTime
                        ? new Date(person?.checkOutTime)
                        : null,
                      Hotellocation: person?.location ?? "",
                      HotelpreferHotel: person?.preferHotel ?? "",
                      Receipt: person?.finalTicket,
                      TravalId: person?.id,
                      isOriginalTravelDetailId: false,

                      Expenselist:
                        detailsform?.expenseDetails?.filter(
                          (itz) => itz?.hotelDetailsId === person?.id
                        )?.length > 0
                          ? detailsform?.expenseDetails
                              ?.filter(
                                (itz) => itz?.hotelDetailsId === person?.id
                              )
                              ?.map((item, index) => {
                                return {
                                  id: item?.id ?? null,
                                  ExpenseTypes: item?.expenseTypes,
                                  Date: new Date(item?.date),
                                  fromlocation: item?.fromlocation,
                                  tolocation: item?.tolocation,
                                  categoryid: data
                                    ? item?.categoryid?.toString()
                                    : "",
                                  // type: Number(
                                  //   item?.travellingCategoriesDetails?.type
                                  // ),
                                  type: data ? Number(item?.type) : "",
                                  totalkm: item?.totalkm,
                                  cost: item?.cost,
                                  Amount: item?.amount,
                                  IsReceiptAvailable: item?.isReceiptAvailable,
                                  Upload: item?.attachment,
                                  Justification: item?.justification,
                                  Remarks: item?.remarks,
                                  // mode: item?.mode,
                                  totaltickets: item?.totaltickets,
                                  totalitems: item?.totalitems,
                                  totaldays: item?.totaldays,
                                };
                              })
                          : [
                              {
                                id: 0,
                                ExpenseTypes: "",
                                Date: "",
                                fromlocation: "",
                                tolocation: "",
                                categoryid: "",
                                // type: Number(
                                //   item?.travellingCategoriesDetails?.type
                                // ),
                                type: "",
                                totalkm: "",
                                cost: "",
                                Amount: "",
                                IsReceiptAvailable: "",
                                Upload: "",
                                Justification: "",
                                Remarks: "",
                                // mode: item?.mode,
                                totaltickets: "",
                                totalitems: "",
                                totaldays: "",
                              },
                            ],
                    };
                  }
                })
                ?.flat()
            : [])
      : (returnArray = []);
    return returnArray;
  };
  const groupItemsDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.expenseDetails?.map((item, index) => {
          return returnArray.push({
            id: item?.id ?? null,

            ExpenseTypes: data ? item?.expenseTypes : "",
            Date: data ? new Date(item?.date) : "",
            fromlocation: data ? item?.fromlocation : "",
            tolocation: data ? item?.tolocation : "",
            categoryid: data ? item?.categoryid?.toString() : "",
            type: data ? Number(item?.type) : "",
            totalkm: data ? item?.totalkm : "",
            cost: data ? item?.cost : "",
            Amount: data ? item?.amount : "",
            IsReceiptAvailable: data ? item?.isReceiptAvailable : "",
            Upload: data ? item?.attachment : "",
            Justification: data ? item?.justification : "",
            Remarks: data ? item?.remarks : "",
            // mode: data ? item?.mode : "",
            totaltickets: data ? item?.totaltickets : "",
            totalitems: data ? item?.totalitems : "",
            totaldays: data ? item?.totaldays : "",
          });
        })
      : (returnArray = [
          {
            ExpenseTypes: "",
            Date: "",
            fromlocation: "",
            tolocation: "",
            categoryid: "",
            type: "",
            totalkm: "",
            cost: "",
            Amount: "",
            IsReceiptAvailable: "",
            Upload: "",
            Justification: "",
            Remarks: "",
            mode: "",
            totaltickets: "",
            totalitems: "",
            totaldays: "",
          },
        ]);
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({
    totalclaimed: "",
    TravalRequestId: null,
    Expenselist: groupItemsDetailsfunc(),
    groupItems: [],
    groupItemsHotel: [],
  });
  useEffect(() => {
    if (data) {
      console.log("object this worked");
      setInitialValues(() => {
        return {
          // totalclaimed: data ? detailsform?.totalclaimed : 34,
          TravalRequestId: data ? detailsform?.travalRequestId : null,
          Expenselist: groupItemsDetailsfunc(),
          groupItems: groupItemsMultiDetailsfunc(),
          groupItemsHotel: groupItemsMultiHotelDetailsfunc(),
        };
      });
    }
  }, [detailsform, resultData]);
  console.log("object formValues", formValues);
  console.log("object initialValues", initialValues);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const checkValidation = (values) => {
    if (!values?.TravalRequestId) {
      if (values?.Expenselist?.some((it) => it?.ExpenseTypes === "")) {
        dispatch(
          showToast({
            text: "Please select expense type",
            severity: "error",
          })
        );
        return false;
      } else {
        values?.Expenselist?.forEach((item, index) => {
          if (
            item?.ExpenseTypes === "Taxi" ||
            item?.ExpenseTypes === "Rickshaw" ||
            item?.ExpenseTypes === "Self Vehicle"
          ) {
            if (!item?.Date) {
              dispatch(
                showToast({
                  text: "Please enter date",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.fromlocation) {
              dispatch(
                showToast({
                  text: "Please enter from location",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.tolocation) {
              dispatch(
                showToast({
                  text: "Please enter to location",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.categoryid) {
              dispatch(
                showToast({
                  text: "Please select catergory",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.type) {
              dispatch(
                showToast({
                  text: "Please enter type",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.totalkm) {
              dispatch(
                showToast({
                  text: "Please enter total KM",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.cost) {
              dispatch(
                showToast({
                  text: "Please enter cost",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Amount) {
              dispatch(
                showToast({
                  text: "Please enter amount",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.IsReceiptAvailable) {
              dispatch(
                showToast({
                  text: "Please select Is Receipt Available",
                  severity: "error",
                })
              );
              return false;
            } else if (
              item?.IsReceiptAvailable === "Yes" &&
              item?.Upload === ""
            ) {
              dispatch(
                showToast({
                  text: "Please upload receipt",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Justification) {
              dispatch(
                showToast({
                  text: "Please enter reason",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Remarks) {
              dispatch(
                showToast({
                  text: "Please enter remarks",
                  severity: "error",
                })
              );
              return false;
            }
          } else if (item?.ExpenseTypes === "Food") {
            if (!item?.Date) {
              dispatch(
                showToast({
                  text: "Please enter date",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.categoryid) {
              dispatch(
                showToast({
                  text: "Please select catergory",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.totaldays) {
              dispatch(
                showToast({
                  text: "Please enter total days",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.cost) {
              dispatch(
                showToast({
                  text: "Please enter cost",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Amount) {
              dispatch(
                showToast({
                  text: "Please enter amount",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.IsReceiptAvailable) {
              dispatch(
                showToast({
                  text: "Please select is receipt available",
                  severity: "error",
                })
              );
              return false;
            } else if (
              item?.IsReceiptAvailable === "Yes" &&
              item?.Upload === ""
            ) {
              dispatch(
                showToast({
                  text: "Please upload receipt",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Justification) {
              dispatch(
                showToast({
                  text: "Please enter reason",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Remarks) {
              dispatch(
                showToast({
                  text: "Please enter remarks",
                  severity: "error",
                })
              );
              return false;
            }
          } else if (item?.ExpenseTypes === "Gadget") {
            if (!item?.Date) {
              dispatch(
                showToast({
                  text: "Please enter date",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.categoryid) {
              dispatch(
                showToast({
                  text: "Please select catergory",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.totalitems) {
              dispatch(
                showToast({
                  text: "Please enter total items",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.cost) {
              dispatch(
                showToast({
                  text: "Please enter cost",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Amount) {
              dispatch(
                showToast({
                  text: "Please enter amount",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.IsReceiptAvailable) {
              dispatch(
                showToast({
                  text: "Please select is receipt available",
                  severity: "error",
                })
              );
              return false;
            } else if (
              item?.IsReceiptAvailable === "Yes" &&
              item?.Upload === ""
            ) {
              dispatch(
                showToast({
                  text: "Please upload receipt",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Justification) {
              dispatch(
                showToast({
                  text: "Please enter reason",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Remarks) {
              dispatch(
                showToast({
                  text: "Please enter remarks",
                  severity: "error",
                })
              );
              return false;
            }
          } else if (
            item?.ExpenseTypes === "Metro" ||
            item?.ExpenseTypes === "Local Train" ||
            item?.ExpenseTypes === "Local Bus"
          ) {
            if (!item?.Date) {
              dispatch(
                showToast({
                  text: "Please enter date",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.fromlocation) {
              dispatch(
                showToast({
                  text: "Please enter from location",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.tolocation) {
              dispatch(
                showToast({
                  text: "Please enter to location",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.categoryid) {
              dispatch(
                showToast({
                  text: "Please select catergory",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.totaltickets) {
              dispatch(
                showToast({
                  text: "Please enter total tickets",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.cost) {
              dispatch(
                showToast({
                  text: "Please enter cost",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Amount) {
              dispatch(
                showToast({
                  text: "Please enter amount",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.IsReceiptAvailable) {
              dispatch(
                showToast({
                  text: "Please select is receipt available",
                  severity: "error",
                })
              );
              return false;
            } else if (
              item?.IsReceiptAvailable === "Yes" &&
              item?.Upload === ""
            ) {
              dispatch(
                showToast({
                  text: "Please upload receipt",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Justification) {
              dispatch(
                showToast({
                  text: "Please enter reason",
                  severity: "error",
                })
              );
              return false;
            } else if (!item?.Remarks) {
              dispatch(
                showToast({
                  text: "Please enter remarks",
                  severity: "error",
                })
              );
              return false;
            }
          }
        });
        return true;
      }
    } else if (values?.TravalRequestId) {
      if (
        !(
          (values?.groupItems?.length > 0 &&
            values?.groupItems?.some((itz) =>
              itz?.Expenselist?.some((it) => it?.ExpenseTypes !== "")
            )) ||
          (values?.groupItemsHotel?.length > 0 &&
            values?.groupItemsHotel?.some((itz) =>
              itz?.Expenselist?.some((it) => it?.ExpenseTypes !== "")
            ))
        )
      ) {
        dispatch(
          showToast({
            text: "Please select atleast one expense type",
            severity: "error",
          })
        );
        return false;
      } else {
        if (
          values?.groupItems?.length > 0 &&
          values?.groupItems?.some((itz) =>
            itz?.Expenselist?.some((it) => it?.ExpenseTypes !== "")
          )
        ) {
          values?.groupItems?.forEach((itemz, ind) => {
            itemz?.Expenselist?.forEach((item, index) => {
              if (
                item?.ExpenseTypes === "Taxi" ||
                item?.ExpenseTypes === "Rickshaw" ||
                item?.ExpenseTypes === "Self Vehicle"
              ) {
                if (!item?.Date) {
                  dispatch(
                    showToast({
                      text: "Please enter date",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.fromlocation) {
                  dispatch(
                    showToast({
                      text: "Please enter from location",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.tolocation) {
                  dispatch(
                    showToast({
                      text: "Please enter to location",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.categoryid) {
                  dispatch(
                    showToast({
                      text: "Please select catergory",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.type) {
                  dispatch(
                    showToast({
                      text: "Please enter type",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.totalkm) {
                  dispatch(
                    showToast({
                      text: "Please enter total KM",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.cost) {
                  dispatch(
                    showToast({
                      text: "Please enter cost",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Amount) {
                  dispatch(
                    showToast({
                      text: "Please enter amount",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.IsReceiptAvailable) {
                  dispatch(
                    showToast({
                      text: "Please select Is Receipt Available",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (
                  item?.IsReceiptAvailable === "Yes" &&
                  item?.Upload === ""
                ) {
                  dispatch(
                    showToast({
                      text: "Please upload receipt",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Justification) {
                  dispatch(
                    showToast({
                      text: "Please enter reason",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Remarks) {
                  dispatch(
                    showToast({
                      text: "Please enter remarks",
                      severity: "error",
                    })
                  );
                  return false;
                }
              } else if (item?.ExpenseTypes === "Food") {
                if (!item?.Date) {
                  dispatch(
                    showToast({
                      text: "Please enter date",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.categoryid) {
                  dispatch(
                    showToast({
                      text: "Please select catergory",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.totaldays) {
                  dispatch(
                    showToast({
                      text: "Please enter total days",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.cost) {
                  dispatch(
                    showToast({
                      text: "Please enter cost",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Amount) {
                  dispatch(
                    showToast({
                      text: "Please enter amount",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.IsReceiptAvailable) {
                  dispatch(
                    showToast({
                      text: "Please select is receipt available",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (
                  item?.IsReceiptAvailable === "Yes" &&
                  item?.Upload === ""
                ) {
                  dispatch(
                    showToast({
                      text: "Please upload receipt",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Justification) {
                  dispatch(
                    showToast({
                      text: "Please enter reason",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Remarks) {
                  dispatch(
                    showToast({
                      text: "Please enter remarks",
                      severity: "error",
                    })
                  );
                  return false;
                }
              } else if (item?.ExpenseTypes === "Gadget") {
                if (!item?.Date) {
                  dispatch(
                    showToast({
                      text: "Please enter date",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.categoryid) {
                  dispatch(
                    showToast({
                      text: "Please select catergory",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.totalitems) {
                  dispatch(
                    showToast({
                      text: "Please enter total items",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.cost) {
                  dispatch(
                    showToast({
                      text: "Please enter cost",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Amount) {
                  dispatch(
                    showToast({
                      text: "Please enter amount",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.IsReceiptAvailable) {
                  dispatch(
                    showToast({
                      text: "Please select is receipt available",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (
                  item?.IsReceiptAvailable === "Yes" &&
                  item?.Upload === ""
                ) {
                  dispatch(
                    showToast({
                      text: "Please upload receipt",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Justification) {
                  dispatch(
                    showToast({
                      text: "Please enter reason",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Remarks) {
                  dispatch(
                    showToast({
                      text: "Please enter remarks",
                      severity: "error",
                    })
                  );
                  return false;
                }
              } else if (
                item?.ExpenseTypes === "Metro" ||
                item?.ExpenseTypes === "Local Train" ||
                item?.ExpenseTypes === "Local Bus"
              ) {
                if (!item?.Date) {
                  dispatch(
                    showToast({
                      text: "Please enter date",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.fromlocation) {
                  dispatch(
                    showToast({
                      text: "Please enter from location",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.tolocation) {
                  dispatch(
                    showToast({
                      text: "Please enter to location",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.categoryid) {
                  dispatch(
                    showToast({
                      text: "Please select catergory",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.totaltickets) {
                  dispatch(
                    showToast({
                      text: "Please enter total tickets",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.cost) {
                  dispatch(
                    showToast({
                      text: "Please enter cost",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Amount) {
                  dispatch(
                    showToast({
                      text: "Please enter amount",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.IsReceiptAvailable) {
                  dispatch(
                    showToast({
                      text: "Please select is receipt available",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (
                  item?.IsReceiptAvailable === "Yes" &&
                  item?.Upload === ""
                ) {
                  dispatch(
                    showToast({
                      text: "Please upload receipt",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Justification) {
                  dispatch(
                    showToast({
                      text: "Please enter reason",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Remarks) {
                  dispatch(
                    showToast({
                      text: "Please enter remarks",
                      severity: "error",
                    })
                  );
                  return false;
                }
              }
            });
          });
        }
        if (
          values?.groupItemsHotel?.length > 0 &&
          values?.groupItemsHotel?.some((itz) =>
            itz?.Expenselist?.some((it) => it?.ExpenseTypes !== "")
          )
        ) {
          values?.groupItemsHotel?.forEach((itemz, ind) => {
            itemz?.Expenselist?.forEach((item, index) => {
              if (
                item?.ExpenseTypes === "Taxi" ||
                item?.ExpenseTypes === "Rickshaw" ||
                item?.ExpenseTypes === "Self Vehicle"
              ) {
                if (!item?.Date) {
                  dispatch(
                    showToast({
                      text: "Please enter date",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.fromlocation) {
                  dispatch(
                    showToast({
                      text: "Please enter from location",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.tolocation) {
                  dispatch(
                    showToast({
                      text: "Please enter to location",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.categoryid) {
                  dispatch(
                    showToast({
                      text: "Please select catergory",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.type) {
                  dispatch(
                    showToast({
                      text: "Please enter type",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.totalkm) {
                  dispatch(
                    showToast({
                      text: "Please enter total KM",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.cost) {
                  dispatch(
                    showToast({
                      text: "Please enter cost",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Amount) {
                  dispatch(
                    showToast({
                      text: "Please enter amount",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.IsReceiptAvailable) {
                  dispatch(
                    showToast({
                      text: "Please select Is Receipt Available",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (
                  item?.IsReceiptAvailable === "Yes" &&
                  item?.Upload === ""
                ) {
                  dispatch(
                    showToast({
                      text: "Please upload receipt",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Justification) {
                  dispatch(
                    showToast({
                      text: "Please enter reason",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Remarks) {
                  dispatch(
                    showToast({
                      text: "Please enter remarks",
                      severity: "error",
                    })
                  );
                  return false;
                }
              } else if (item?.ExpenseTypes === "Food") {
                if (!item?.Date) {
                  dispatch(
                    showToast({
                      text: "Please enter date",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.categoryid) {
                  dispatch(
                    showToast({
                      text: "Please select catergory",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.totaldays) {
                  dispatch(
                    showToast({
                      text: "Please enter total days",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.cost) {
                  dispatch(
                    showToast({
                      text: "Please enter cost",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Amount) {
                  dispatch(
                    showToast({
                      text: "Please enter amount",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.IsReceiptAvailable) {
                  dispatch(
                    showToast({
                      text: "Please select is receipt available",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (
                  item?.IsReceiptAvailable === "Yes" &&
                  item?.Upload === ""
                ) {
                  dispatch(
                    showToast({
                      text: "Please upload receipt",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Justification) {
                  dispatch(
                    showToast({
                      text: "Please enter reason",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Remarks) {
                  dispatch(
                    showToast({
                      text: "Please enter remarks",
                      severity: "error",
                    })
                  );
                  return false;
                }
              } else if (item?.ExpenseTypes === "Gadget") {
                if (!item?.Date) {
                  dispatch(
                    showToast({
                      text: "Please enter date",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.categoryid) {
                  dispatch(
                    showToast({
                      text: "Please select catergory",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.totalitems) {
                  dispatch(
                    showToast({
                      text: "Please enter total items",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.cost) {
                  dispatch(
                    showToast({
                      text: "Please enter cost",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Amount) {
                  dispatch(
                    showToast({
                      text: "Please enter amount",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.IsReceiptAvailable) {
                  dispatch(
                    showToast({
                      text: "Please select is receipt available",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (
                  item?.IsReceiptAvailable === "Yes" &&
                  item?.Upload === ""
                ) {
                  dispatch(
                    showToast({
                      text: "Please upload receipt",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Justification) {
                  dispatch(
                    showToast({
                      text: "Please enter reason",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Remarks) {
                  dispatch(
                    showToast({
                      text: "Please enter remarks",
                      severity: "error",
                    })
                  );
                  return false;
                }
              } else if (
                item?.ExpenseTypes === "Metro" ||
                item?.ExpenseTypes === "Local Train" ||
                item?.ExpenseTypes === "Local Bus"
              ) {
                if (!item?.Date) {
                  dispatch(
                    showToast({
                      text: "Please enter date",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.fromlocation) {
                  dispatch(
                    showToast({
                      text: "Please enter from location",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.tolocation) {
                  dispatch(
                    showToast({
                      text: "Please enter to location",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.categoryid) {
                  dispatch(
                    showToast({
                      text: "Please select catergory",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.totaltickets) {
                  dispatch(
                    showToast({
                      text: "Please enter total tickets",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.cost) {
                  dispatch(
                    showToast({
                      text: "Please enter cost",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Amount) {
                  dispatch(
                    showToast({
                      text: "Please enter amount",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.IsReceiptAvailable) {
                  dispatch(
                    showToast({
                      text: "Please select is receipt available",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (
                  item?.IsReceiptAvailable === "Yes" &&
                  item?.Upload === ""
                ) {
                  dispatch(
                    showToast({
                      text: "Please upload receipt",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Justification) {
                  dispatch(
                    showToast({
                      text: "Please enter reason",
                      severity: "error",
                    })
                  );
                  return false;
                } else if (!item?.Remarks) {
                  dispatch(
                    showToast({
                      text: "Please enter remarks",
                      severity: "error",
                    })
                  );
                  return false;
                }
              }
            });
          });
        }

        return true;
      }
    }
  };

  const getFormDetails = async (id) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Expenses/Details/${id}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    data ? seteditIDData(true) : seteditIDData(false);
    if (data) getFormDetails(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Expenses/Create", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Expenses/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const onSubmit = (values) => {
    console.log("object checkValidation", checkValidation(values));
    if (checkValidation(values)) {
      const formData = new FormData();
      formData.append("Id", data ? data : 0);
      formData.append("totalclaimed", claimCount);

      if (!values?.TravalRequestId) {
        values?.Expenselist?.forEach((item, index) => {
          formData.append(
            `Expenselist[${index}].ExpenseTypes`,
            item?.ExpenseTypes
          );
          if (
            item?.ExpenseTypes === "Taxi" ||
            item?.ExpenseTypes === "Rickshaw" ||
            item?.ExpenseTypes === "Self Vehicle"
          ) {
            formData.append(
              `Expenselist[${index}].Date`,
              format(new Date(item?.Date), "yyyy-MM-dd")
            );
            formData.append(
              `Expenselist[${index}].fromlocation`,
              item?.fromlocation
            );
            formData.append(
              `Expenselist[${index}].tolocation`,
              item?.tolocation
            );
            formData.append(
              `Expenselist[${index}].categoryid`,
              item?.categoryid
            );
            formData.append(`Expenselist[${index}].type`, employeeList?.value);
            formData.append(`Expenselist[${index}].totalkm`, item?.totalkm);
            formData.append(`Expenselist[${index}].cost`, item?.cost);
            formData.append(`Expenselist[${index}].Amount`, item?.Amount);
            formData.append(
              `Expenselist[${index}].IsReceiptAvailable`,
              item?.IsReceiptAvailable
            );
            if (typeof item?.Upload === "object" && item?.Upload) {
              formData.append(`Expenselist[${index}].Upload`, item?.Upload);
            } else {
              formData.append(`Expenselist[${index}].attachment`, item?.Upload);
            }
            formData.append(
              `Expenselist[${index}].Justification`,
              item?.Justification
            );
            formData.append(`Expenselist[${index}].Remarks`, item?.Remarks);
          }
          if (
            item?.ExpenseTypes === "Food" ||
            item?.ExpenseTypes === "Gadget"
          ) {
            formData.append(
              `Expenselist[${index}].Date`,
              format(new Date(item?.Date), "yyyy-MM-dd")
            );
            formData.append(
              `Expenselist[${index}].categoryid`,
              item?.categoryid
            );
            if (item?.ExpenseTypes === "Food") {
              formData.append(
                `Expenselist[${index}].totaldays`,
                item?.totaldays
              );
            } else {
              formData.append(
                `Expenselist[${index}].totalitems`,
                item?.totalitems
              );
            }
            formData.append(`Expenselist[${index}].cost`, item?.cost);
            formData.append(`Expenselist[${index}].Amount`, item?.Amount);
            formData.append(
              `Expenselist[${index}].IsReceiptAvailable`,
              item?.IsReceiptAvailable
            );
            if (typeof item?.Upload === "object" && item?.Upload) {
              formData.append(`Expenselist[${index}].Upload`, item?.Upload);
            } else {
              formData.append(`Expenselist[${index}].attachment`, item?.Upload);
            }
            formData.append(
              `Expenselist[${index}].Justification`,
              item?.Justification
            );
            formData.append(`Expenselist[${index}].Remarks`, item?.Remarks);
          }
          if (
            item?.ExpenseTypes === "Metro" ||
            item?.ExpenseTypes === "Local Train" ||
            item?.ExpenseTypes === "Local Bus"
          ) {
            formData.append(
              `Expenselist[${index}].Date`,
              format(new Date(item?.Date), "yyyy-MM-dd")
            );
            formData.append(
              `Expenselist[${index}].fromlocation`,
              item?.fromlocation
            );
            formData.append(
              `Expenselist[${index}].tolocation`,
              item?.tolocation
            );
            formData.append(
              `Expenselist[${index}].categoryid`,
              item?.categoryid
            );
            formData.append(
              `Expenselist[${index}].totaltickets`,
              item?.totaltickets
            );
            formData.append(`Expenselist[${index}].cost`, item?.cost);
            formData.append(`Expenselist[${index}].Amount`, item?.Amount);
            formData.append(
              `Expenselist[${index}].IsReceiptAvailable`,
              item?.IsReceiptAvailable
            );
            if (typeof item?.Upload === "object" && item?.Upload) {
              formData.append(`Expenselist[${index}].Upload`, item?.Upload);
            } else {
              formData.append(`Expenselist[${index}].attachment`, item?.Upload);
            }
            formData.append(
              `Expenselist[${index}].Justification`,
              item?.Justification
            );
            formData.append(`Expenselist[${index}].Remarks`, item?.Remarks);
          }
        });
      }
      if (values?.TravalRequestId) {
        let num = 0;
        formData.append("TravalRequestId", values?.TravalRequestId);

        values?.groupItems?.forEach((itemz, indexz) => {
          itemz?.Expenselist?.forEach((item, index) => {
            if (itemz?.isOriginalTravelDetailId) {
              formData.append(
                `Expenselist[${num}].travellingdetailsidNew`,
                itemz?.TravalId
              );
            } else {
              formData.append(
                `Expenselist[${num}].travellingdetailsid`,
                itemz?.TravalId
              );
            }
            formData.append(
              `Expenselist[${num}].ExpenseTypes`,
              item?.ExpenseTypes
            );
            if (
              item?.ExpenseTypes === "Taxi" ||
              item?.ExpenseTypes === "Rickshaw" ||
              item?.ExpenseTypes === "Self Vehicle"
            ) {
              formData.append(
                `Expenselist[${num}].Date`,
                format(new Date(item?.Date), "yyyy-MM-dd")
              );
              formData.append(
                `Expenselist[${num}].fromlocation`,
                item?.fromlocation
              );
              formData.append(
                `Expenselist[${num}].tolocation`,
                item?.tolocation
              );
              formData.append(
                `Expenselist[${num}].categoryid`,
                item?.categoryid
              );
              formData.append(`Expenselist[${num}].type`, employeeList?.value);
              formData.append(`Expenselist[${num}].totalkm`, item?.totalkm);
              formData.append(`Expenselist[${num}].cost`, item?.cost);
              formData.append(`Expenselist[${num}].Amount`, item?.Amount);
              formData.append(
                `Expenselist[${num}].IsReceiptAvailable`,
                item?.IsReceiptAvailable
              );
              if (typeof item?.Upload === "object" && item?.Upload) {
                formData.append(`Expenselist[${index}].Upload`, item?.Upload);
              } else {
                formData.append(
                  `Expenselist[${index}].attachment`,
                  item?.Upload
                );
              }
              formData.append(
                `Expenselist[${num}].Justification`,
                item?.Justification
              );
              formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
            }
            if (
              item?.ExpenseTypes === "Food" ||
              item?.ExpenseTypes === "Gadget"
            ) {
              formData.append(
                `Expenselist[${num}].Date`,
                format(new Date(item?.Date), "yyyy-MM-dd")
              );
              formData.append(
                `Expenselist[${num}].categoryid`,
                item?.categoryid
              );
              if (item?.ExpenseTypes === "Food") {
                formData.append(
                  `Expenselist[${num}].totaldays`,
                  item?.totaldays
                );
              } else {
                formData.append(
                  `Expenselist[${num}].totalitems`,
                  item?.totalitems
                );
              }
              formData.append(`Expenselist[${num}].cost`, item?.cost);
              formData.append(`Expenselist[${num}].Amount`, item?.Amount);
              formData.append(
                `Expenselist[${num}].IsReceiptAvailable`,
                item?.IsReceiptAvailable
              );
              if (typeof item?.Upload === "object" && item?.Upload) {
                formData.append(`Expenselist[${index}].Upload`, item?.Upload);
              } else {
                formData.append(
                  `Expenselist[${index}].attachment`,
                  item?.Upload
                );
              }
              formData.append(
                `Expenselist[${num}].Justification`,
                item?.Justification
              );
              formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
            }
            if (
              item?.ExpenseTypes === "Metro" ||
              item?.ExpenseTypes === "Local Train" ||
              item?.ExpenseTypes === "Local Bus"
            ) {
              formData.append(
                `Expenselist[${num}].Date`,
                format(new Date(item?.Date), "yyyy-MM-dd")
              );
              formData.append(
                `Expenselist[${num}].fromlocation`,
                item?.fromlocation
              );
              formData.append(
                `Expenselist[${num}].tolocation`,
                item?.tolocation
              );
              formData.append(
                `Expenselist[${num}].categoryid`,
                item?.categoryid
              );
              formData.append(
                `Expenselist[${num}].totaltickets`,
                item?.totaltickets
              );
              formData.append(`Expenselist[${num}].cost`, item?.cost);
              formData.append(`Expenselist[${num}].Amount`, item?.Amount);
              formData.append(
                `Expenselist[${num}].IsReceiptAvailable`,
                item?.IsReceiptAvailable
              );
              if (typeof item?.Upload === "object" && item?.Upload) {
                formData.append(`Expenselist[${index}].Upload`, item?.Upload);
              } else {
                formData.append(
                  `Expenselist[${index}].attachment`,
                  item?.Upload
                );
              }
              formData.append(
                `Expenselist[${num}].Justification`,
                item?.Justification
              );
              formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
            }
            num++;
          });
        });
        values?.groupItemsHotel?.forEach((itemz, indexz) => {
          itemz?.Expenselist?.forEach((item, index) => {
            if (itemz?.isOriginalTravelDetailId) {
              formData.append(
                `Expenselist[${num}].HotelDetailsIdNew`,
                itemz?.TravalId
              );
            } else {
              formData.append(
                `Expenselist[${num}].HotelDetailsId`,
                itemz?.TravalId
              );
            }

            formData.append(
              `Expenselist[${num}].ExpenseTypes`,
              item?.ExpenseTypes
            );
            if (
              item?.ExpenseTypes === "Taxi" ||
              item?.ExpenseTypes === "Rickshaw" ||
              item?.ExpenseTypes === "Self Vehicle"
            ) {
              formData.append(
                `Expenselist[${num}].Date`,
                format(new Date(item?.Date), "yyyy-MM-dd")
              );
              formData.append(
                `Expenselist[${num}].fromlocation`,
                item?.fromlocation
              );

              formData.append(
                `Expenselist[${num}].tolocation`,
                item?.tolocation
              );
              formData.append(
                `Expenselist[${num}].categoryid`,
                item?.categoryid
              );
              formData.append(`Expenselist[${num}].type`, employeeList?.value);
              formData.append(`Expenselist[${num}].totalkm`, item?.totalkm);
              formData.append(`Expenselist[${num}].cost`, item?.cost);
              formData.append(`Expenselist[${num}].Amount`, item?.Amount);
              formData.append(
                `Expenselist[${num}].IsReceiptAvailable`,
                item?.IsReceiptAvailable
              );
              if (typeof item?.Upload === "object" && item?.Upload) {
                formData.append(`Expenselist[${index}].Upload`, item?.Upload);
              } else {
                formData.append(
                  `Expenselist[${index}].attachment`,
                  item?.Upload
                );
              }
              formData.append(
                `Expenselist[${num}].Justification`,
                item?.Justification
              );
              formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
            }
            if (
              item?.ExpenseTypes === "Food" ||
              item?.ExpenseTypes === "Gadget"
            ) {
              formData.append(
                `Expenselist[${num}].Date`,
                format(new Date(item?.Date), "yyyy-MM-dd")
              );
              formData.append(
                `Expenselist[${num}].categoryid`,
                item?.categoryid
              );
              if (item?.ExpenseTypes === "Food") {
                formData.append(
                  `Expenselist[${num}].totaldays`,
                  item?.totaldays
                );
              } else {
                formData.append(
                  `Expenselist[${num}].totalitems`,
                  item?.totalitems
                );
              }
              formData.append(`Expenselist[${num}].cost`, item?.cost);
              formData.append(`Expenselist[${num}].Amount`, item?.Amount);
              formData.append(
                `Expenselist[${num}].IsReceiptAvailable`,
                item?.IsReceiptAvailable
              );
              if (typeof item?.Upload === "object" && item?.Upload) {
                formData.append(`Expenselist[${index}].Upload`, item?.Upload);
              } else {
                formData.append(
                  `Expenselist[${index}].attachment`,
                  item?.Upload
                );
              }
              formData.append(
                `Expenselist[${num}].Justification`,
                item?.Justification
              );
              formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
            }
            if (
              item?.ExpenseTypes === "Metro" ||
              item?.ExpenseTypes === "Local Train" ||
              item?.ExpenseTypes === "Local Bus"
            ) {
              formData.append(
                `Expenselist[${num}].Date`,
                format(new Date(item?.Date), "yyyy-MM-dd")
              );
              formData.append(
                `Expenselist[${num}].fromlocation`,
                item?.fromlocation
              );
              formData.append(
                `Expenselist[${num}].tolocation`,
                item?.tolocation
              );
              formData.append(
                `Expenselist[${num}].categoryid`,
                item?.categoryid
              );
              formData.append(
                `Expenselist[${num}].totaltickets`,
                item?.totaltickets
              );
              formData.append(`Expenselist[${num}].cost`, item?.cost);
              formData.append(`Expenselist[${num}].Amount`, item?.Amount);
              formData.append(
                `Expenselist[${num}].IsReceiptAvailable`,
                item?.IsReceiptAvailable
              );
              if (typeof item?.Upload === "object" && item?.Upload) {
                formData.append(`Expenselist[${index}].Upload`, item?.Upload);
              } else {
                formData.append(
                  `Expenselist[${index}].attachment`,
                  item?.Upload
                );
              }
              formData.append(
                `Expenselist[${num}].Justification`,
                item?.Justification
              );
              formData.append(`Expenselist[${num}].Remarks`, item?.Remarks);
            }
            num++;
          });
        });
      }

      data ? Edit(formData) : createPageData(formData);
    }
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      styles={{ minWidth: "80%" }}
      loading={loading}
      // styles={{ minWidth: "75%", maxWidth: "75%" }}
      FormHeading={data ? "Edit Reimbursement" : "Create Reimbursement"}
      ButtonLabel={data ? "Edit Reimbursement" : "Create Reimbursement"}
      onSubmit={onSubmit}
      // validationSchema={validationSchema}
      chooseFormValues={chooseFormValues}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={
        <CreateReimbursementComp
          setEmployeeList={setEmployeeList}
          employeeList={employeeList}
          setResultData={setResultData}
          editIDData={editIDData}
          seteditIDData={seteditIDData}
          seteditIDDatafunc={() => seteditIDData(false)}
          formValues={formValues}
          loading={loading}
          setInitialValues={setInitialValues}
          setClaimCount={setClaimCount}
          claimCount={claimCount}
          resultData={resultData}
          detailsform={detailsform}
          editID={data ? true : false}
          // getInitialValuesRepeated={getInitialValuesRepeated}
        />
      }
    />
  );
};

export { CreateReimbursement };

import React, { useState } from "react";
import "./CreatePersonalArea.css";
import { useNavigate } from "react-router-dom";
import { CreatePersonalAreaComp } from "./FormComp/CreatePersonalAreaComp";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const CreatePersonalArea = (props) => {
  const { state } = useLocation();

  const data = state?.id;
  const from = state?.from;

  const dispatch = useDispatch();
  const [detail, setDetail] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const getDetails = async () => {
    try {
      const result = await getRequest(`Personal/Details/${data}`);
      setDetail(result.data);

    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    document.title = `PeopleSol - Create Personal Areas `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Personal/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("Personal/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const initialvalue = {
    name: data ? detail.name : "",
    state: data ? detail.state : "",
    maxLimit: data ? detail.maxLimit : "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().max(49, " Name must be less than 50 characters").required("Please Enter Name"),
    state: Yup.string().required("Please Select State"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,
    };

    const create = {
      ...values,
      id: 0,
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <>
      <FormCustomFormik
        loading={loading}
        FormHeading={from == "personal" ? "Update Work Location" : data ? "Edit Personal Area" : "Create Personal Area"}
        ButtonLabel={from == "personal" ? "Update Work Location" : data ? "Update Personal Area" : "Create Personal Area"}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialvalue={initialvalue}
        returnPage={returnPage}
        propsDiv={<CreatePersonalAreaComp from={from} />}
      />
    </>
  );
};

export { CreatePersonalArea };

import React, { useState, useEffect } from "react";
import { getRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { Loader } from "../../../Components/FormComponent/Loader";

const LWFTab = (props) => {
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState(false);
  const [loading, setLoading] = useState(true);
  const getPageData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest("StatutoryCompliance/LWFList");
      setInitialData(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const changeStatus = async (id) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`StatutoryCompliance/LWFEnable/${id}`);
      dispatch(showToast({ text: "Status updated", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      getPageData();
    }
  };
  useEffect(() => {
    getPageData();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - LWF `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="fullTableContainer centerContent">
      <div className="companyCard">

        <h5 style={{ marginLeft: 30, marginTop: 20 }}>Labour Welfare Fund</h5>
        <p
          style={{
            marginLeft: 30,
            height: "21px",
            maxHeight: "21px",
          }}
        >
          Labour Welfare Fund act ensures social security and improved working
          conditions for employees whose monthly salary is less than or equal to
          Rs.15,000.
        </p>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <div
            className="moduleMainContentHolder"
            style={{ height: "calc(100% - 182px)" }}
          >
            <div className="homeCardsHolderContainer">
              <div className="homeDashboardCardHolder">
                {initialData?.length > 0 &&
                  initialData?.map((item) => {
                    return (
                      <div
                        className="dashboardCard internalJobCard"
                        key={item.id}
                      >
                        <div className="cardContainer">
                          <div className="headingContainer">
                            <h3>{item.workLocationName}</h3>
                          </div>
                          <div className="jobDetailContainerHolder">
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                Employees' Contribution
                              </label>
                              <label>{item.employeeContribution ?? "NA"}</label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                Employer's Contribution
                              </label>
                              <label>{item.employerContribution ?? "NA"}</label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                Deduction Cycle
                              </label>
                              <label>{item.deductionCycle ?? "NA"}</label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">Status</label>
                              <label>
                                {item.isActive ? "Enabled" : "Disabled"}
                              </label>
                            </div>
                          </div>
                          <div className="applyJobButtonContainer">
                            <div>
                              {item.isActive ? (
                                <button
                                  onClick={() => changeStatus(item.id)}
                                  className="button primaryButton"
                                  style={{
                                    backgroundColor: "var(--secondary)",
                                  }}
                                >
                                  Disable
                                </button>
                              ) : (
                                <button
                                  onClick={() => changeStatus(item.id)}
                                  className="button primaryButton"
                                >
                                  Enable
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export { LWFTab };

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { CreatePerformanceCycleComp } from "./FormComp/CreatePerformanceCycleComp";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import CheckSubmit from "../../Travel/Components/CheckSubmit";

const CreatePerformanceCycle = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const [formValues, setFormValues] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PerformanceCycleNew/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);

  console.log("object formValues", formValues);
  const createPageData = async (values, name) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "PerformanceCycleNew/Create",
        values
      );
      if (result?.data?.summary?.id) {
        returnPage();

        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "400px",
                    backgroundColor: "white",
                    borderRadius: " 6px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div
                      className="detailInner"
                      style={{ fontFamily: "Poppins", fontSize: "15px" }}
                    >
                      Do you want to create appraisal cycle for same performance
                      cycle?
                    </div>
                    <div
                      className="createFootCust"
                      style={{ backgroundColor: "white" }}
                    >
                      <div>
                        <div
                          onClick={() => {
                            onClose();
                          }}
                          className={`button secondaryButton `}
                        >
                          No
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            onClose();
                            navigate(
                              "/pmsmaster/appraisalcycle/appraisalcycle/create",
                              {
                                state: {
                                  performanceId: result?.data?.summary?.id,
                                  name: name,
                                },
                              }
                            );
                          }}
                          className="button primaryButton"
                          type="submit"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        });
      }
      // if (result) {
      //   dispatch(
      //     showToast({ text: "Successfully Created", severity: "success" })
      //   );
      // }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Performance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("PerformanceCycleNew/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const initialvalue = {
    name: "",
    type: "",
    goalSettingId: "",
    performanceSlabId: "",
    description: null,
    performanceCycleStartDate: null,
    performanceCycleEndData: null,
    processStartDate: "",
    processEndDate: "",
    selfReviewStartDate: "",
    selfReviewEndDate: "",
    appraiserReviewStartDate: "",
    appraiserReviewEndDate: "",
    reviewersReviewStartDate: "",
    reviewersReviewEndDate: "",
    upload: null,
    departmentIds: "",
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please enter name"),
    type: Yup.string().required("Please select type"),
    goalSettingId: Yup.string().required("Please select goal setting"),
    performanceSlabId: Yup.string().required("Please select performance slab"),
    description: Yup.string()
      .max(499, "Description must be less than 500 characters")
      .nullable(),
    upload: Yup.mixed()
      .test(
        "Fichier taille",
        "File Size should be less than 2 mb",
        (value) =>
          !value ||
          (value && value.size <= 2048 * 2048) ||
          typeof value !== "object"
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          typeof value !== "object" ||
          (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .notRequired("Please Attach File"),
    performanceCycleStartDate: Yup.date().required(
      "Please select performance cycle start date"
    ),
    performanceCycleEndData: Yup.date()
      .required("Please select performance cycle end date")
      .test(
        "is-greater",
        "End date must be greater than start date",
        function (value) {
          const startDate = formValues?.performanceCycleStartDate;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    processStartDate: Yup.date()
      .required("Please select process start date")
      .test(
        "is-greater",
        "Start date must be between performance start and end date",
        function (value) {
          const startAppDate = formValues?.performanceCycleStartDate;
          const endAppDate = formValues?.performanceCycleEndData;
          const startProcessDate = value;
          if (startProcessDate && startAppDate && endAppDate) {
            return (
              new Date(startProcessDate) >= new Date(startAppDate) &&
              new Date(startProcessDate) <= new Date(endAppDate)
            );
          }
          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    processEndDate: Yup.date()
      .required("Please select process end date")
      .test(
        "is-greater",
        "End date must be greater than start date and between performance start and end date",
        function (value) {
          const startAppDate = formValues?.performanceCycleStartDate;
          const endAppDate = formValues?.performanceCycleEndData;
          const startProcessDate = formValues?.processStartDate;
          const endProcessDate = value;
          if (endProcessDate && startAppDate && endAppDate) {
            return (
              new Date(endProcessDate) >= new Date(startProcessDate) &&
              new Date(endProcessDate) <= new Date(endAppDate) &&
              new Date(endProcessDate) >= new Date(startAppDate)
            );
          }
          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    selfReviewStartDate: Yup.date()
      .required("Please select self review start date")
      .test(
        "is-greater",
        "Start date must be between process start and end date",
        function (value) {
          const startProcessDate = formValues?.processStartDate;
          const endProcessDate = formValues?.processEndDate;
          const startDate = value;

          if (startDate && startProcessDate && endProcessDate) {
            return (
              new Date(startDate) >= new Date(startProcessDate) &&
              new Date(startDate) <= new Date(endProcessDate)
            );
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    selfReviewEndDate: Yup.date()
      .required("Please select self review end date")
      .test(
        "is-greater",
        "End date must be greater than start date and between process start and end date",
        function (value) {
          const startProcessDate = formValues?.processStartDate;
          const endProcessDate = formValues?.processEndDate;
          const startDate = formValues?.selfReviewStartDate;
          const endDate = value;

          if (startDate && endDate && startProcessDate && endProcessDate) {
            return (
              new Date(endDate) >= new Date(startDate) &&
              new Date(endDate) >= new Date(startProcessDate) &&
              new Date(endDate) <= new Date(endProcessDate)
            );
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    appraiserReviewStartDate: Yup.date()
      .required("Please select appraiser review start date")
      .test(
        "is-greater",
        "Start date must be between process start and end date",
        function (value) {
          const startProcessDate = formValues?.processStartDate;
          const endProcessDate = formValues?.processEndDate;
          const startDate = value;

          if (startDate && startProcessDate && endProcessDate) {
            return (
              new Date(startDate) >= new Date(startProcessDate) &&
              new Date(startDate) <= new Date(endProcessDate)
            );
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    appraiserReviewEndDate: Yup.date()
      .required("Please select appraiser review end date")
      .test(
        "is-greater",
        "End date must be greater than start date and between process start and end date",
        function (value) {
          const startProcessDate = formValues?.processStartDate;
          const endProcessDate = formValues?.processEndDate;
          const startDate = formValues?.appraiserReviewStartDate;
          const endDate = value;

          if (startDate && endDate && startProcessDate && endProcessDate) {
            return (
              new Date(endDate) >= new Date(startDate) &&
              new Date(endDate) >= new Date(startProcessDate) &&
              new Date(endDate) <= new Date(endProcessDate)
            );
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    reviewersReviewStartDate: Yup.date()
      .required("Please select reviewer review start date")
      .test(
        "is-greater",
        "Start date must be between process start and end date",
        function (value) {
          const startProcessDate = formValues?.processStartDate;
          const endProcessDate = formValues?.processEndDate;
          const startDate = value;

          if (startDate && startProcessDate && endProcessDate) {
            return (
              new Date(startDate) >= new Date(startProcessDate) &&
              new Date(startDate) <= new Date(endProcessDate)
            );
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    reviewersReviewEndDate: Yup.date()
      .required("Please select reviewer review end date")
      .test(
        "is-greater",
        "End date must be greater than start date and between process start and end date",
        function (value) {
          const startProcessDate = formValues?.processStartDate;
          const endProcessDate = formValues?.processEndDate;
          const startDate = formValues?.reviewersReviewStartDate;
          const endDate = value;

          if (startDate && endDate && startProcessDate && endProcessDate) {
            return (
              new Date(endDate) >= new Date(startDate) &&
              new Date(endDate) >= new Date(startProcessDate) &&
              new Date(endDate) <= new Date(endProcessDate)
            );
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    formData.append("name", values?.name);
    formData.append("type", values?.type);
    formData.append("goalSettingId", values?.goalSettingId);
    formData.append("PerformanceSlabId", values?.performanceSlabId);
    formData.append("description", values?.description);

    formData.append(
      "performanceCycleStartDate",
      `${format(values?.performanceCycleStartDate, "MM/dd/yyyy")}`
    );

    formData.append(
      "performanceCycleEndData",
      `${format(values?.performanceCycleEndData, "MM/dd/yyyy")}`
    );

    formData.append(
      "processStartDate",
      `${format(values?.processStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "processEndDate",
      `${format(values?.processEndDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "selfReviewStartDate",
      `${format(values?.selfReviewStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "selfReviewEndDate",
      `${format(values?.selfReviewEndDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "appraiserReviewStartDate",
      `${format(values?.appraiserReviewStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "appraiserReviewEndDate",
      `${format(values?.appraiserReviewEndDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "reviewersReviewStartDate",
      `${format(values?.reviewersReviewStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "reviewersReviewEndDate",
      `${format(values?.reviewersReviewEndDate, "MM/dd/yyyy")}`
    );
    // console.log("object formData", formData);
    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    if (values?.departmentIds?.length > 0)
      values?.departmentIds?.forEach((item, index) => {
        formData.append(`_departmentIds[${index}]`, item);
      });
    if (typeof values?.upload === "object")
      formData.append("upload", values?.upload);
    else
      formData.append(
        "guideLineDocument",
        detailsform?.guideLineDocument
          ? detailsform?.guideLineDocument.toString()
          : null
      );

    data ? Edit(formData) : createPageData(formData, values?.name);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={props.title}
      chooseFormValues={chooseFormValues}
      ButtonLabel={data ? "Update" : "Create"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?.name,
              type: detailsform?.type,
              goalSettingId: detailsform?.goalSettingId,
              performanceSlabId: detailsform?.performanceSlabId,
              description: detailsform?.description,
              performanceCycleStartDate: new Date(
                detailsform?.performanceCycleStartDate ?? null
              ),
              performanceCycleEndData: new Date(
                detailsform?.performanceCycleEndData ?? null
              ),
              processStartDate: new Date(detailsform?.processStartDate ?? null),
              processEndDate: new Date(detailsform?.processEndDate ?? null),
              selfReviewStartDate: new Date(
                detailsform?.selfReviewStartDate ?? null
              ),
              selfReviewEndDate: new Date(
                detailsform?.selfReviewEndDate ?? null
              ),
              appraiserReviewStartDate: new Date(
                detailsform?.appraiserReviewStartDate ?? null
              ),
              appraiserReviewEndDate: new Date(
                detailsform?.appraiserReviewEndDate ?? null
              ),
              reviewersReviewStartDate: new Date(
                detailsform?.reviewersReviewStartDate ?? null
              ),
              reviewersReviewEndDate: new Date(
                detailsform?.reviewersReviewEndDate ?? null
              ),
              upload: `PmsPerformanceCycle/${detailsform?.guideLineDocument}`,
              departmentIds: detailsform?.excludedDepartments
                ? detailsform?.excludedDepartments?.split(",")?.map(Number)
                : "",
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <CreatePerformanceCycleComp
          data={detailsform}
          editID={data ? true : false}
        />
      }
    />
  );
};

export { CreatePerformanceCycle };

/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { DetailsStep } from "../../../../Components/FormComponent/DetailsStep";

const DetailsViewGoals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);

  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Goals/ViewGoals?ID=${selectedId}`);
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details View Goals `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>{`${data?._pmsAppraisalCycles?.name ?? ""}`}</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div style={{ height: "100%", overflow: "auto" }}>
          {data != null ? (
            loading ? (
              <div className="noDataFound">
                <Loader />
              </div>
            ) : (
              <div className="detailsEReq" style={{ flexDirection: "column" }}>
                {data?._pmsAppraisalCycles?.goalslist?.length > 0 ? (
                  <>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Status"}
                        Details={
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                data?._pmsAppraisalCycles
                                  ?.pmsGoalsettingsEmployees[0]?.status
                              )?.split(",")?.[0],
                              color: getStatusColor(
                                data?._pmsAppraisalCycles
                                  ?.pmsGoalsettingsEmployees[0]?.status
                              )?.split(",")?.[1],
                              cursor: "default",
                              padding: "3px 6px",
                              width: "fit-content",
                              borderRadius: "6px",
                            }}
                          >
                            {
                              data?._pmsAppraisalCycles
                                ?.pmsGoalsettingsEmployees[0]?.status
                            }
                          </div>
                        }
                      />
                      <div style={{ width: "100%" }}>
                        <table
                          className="tabless tabless-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead
                            style={{
                              backgroundColor: "var(--primary-hover)",
                              borderTop: "1px solid lightgray",
                            }}
                          >
                            <tr>
                              <td>S.no</td>
                              <td>Objective</td>
                              <td>Goal Name</td>
                              <td>Start Date</td>
                              <td>Expected Date of Achievement</td>
                              <td>Goal Threshold</td>
                              <td>Weightage Percentage</td>

                              {/* <td>Duration</td> */}
                            </tr>
                          </thead>
                          <tbody>
                            {data?._pmsAppraisalCycles?.goalslist?.map(
                              (items, index) => {
                                return (
                                  <tr className="trStyle">
                                    <td>{index + 1}</td>
                                    <td>{items.name}</td>
                                    <td>{items.description}</td>
                                    <td>
                                      {" "}
                                      {items.startDate &&
                                        format(
                                          new Date(items.startDate),
                                          "dd/MM/yyyy "
                                        )}
                                    </td>
                                    <td>
                                      {" "}
                                      {items.expectedEndDate &&
                                        format(
                                          new Date(items.expectedEndDate),
                                          "dd/MM/yyyy "
                                        )}
                                    </td>
                                    <td>{items.kra}</td>
                                    <td>{items.weightage}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {data?._pmsAppraisalCycles?.goalslist?.length > 0 ? (
                  <>
                    <div className="detailInner">
                      <div style={{ width: "100%" }}>
                        <table
                          className="tabless tabless-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead
                            style={{
                              backgroundColor: "var(--primary-hover)",
                              borderTop: "1px solid lightgray",
                            }}
                          >
                            <tr>
                              <td>S.no</td>
                              <td>Employee Name</td>
                              <td>Employee ID</td>
                              <td>Remarks</td>
                              <td>Time</td>
                              {/* <td>Duration</td> */}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.history?.map((items, index) => {
                              return (
                                <tr className="trStyle">
                                  <td>{index + 1}</td>
                                  <td>
                                    {items?.employee?.nameWithPersonNumber}
                                  </td>
                                  <td>{items?.employeeId}</td>
                                  <td>{items?.remarks}</td>
                                  <td>
                                    {items.time &&
                                      format(
                                        new Date(items.time),
                                        "dd/MM/yyyy hh:mm a"
                                      )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Records"}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsViewGoals };

import React, { useEffect } from 'react'

const MyRewardsReviewWinnersCriteria = ({ details }) => {
    console.log("winnerscriteria details popup", details)
    useEffect(() => {
        document.title = `PeopleSol -  WinnersCriteria`;
        return () => {
            document.title = "PeopleSol";
        };
    }, []);

    const getOrdinalSuffix = (number) => {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;
    
        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
          return (
            <>
              <span> Objective For &nbsp;</span>
              {number}
              <sup>th</sup>
              <span> &nbsp;Position</span>
            </>
          ); // 11th, 12th, 13th for "th"
        }
    
        switch (lastDigit) {
          case 1:
            return (
              <>
                <span> Objective For &nbsp;</span>
                {number}
                <sup>st</sup>
                <span> &nbsp;Position</span>
              </>
            ); // 1st, 21st, 31st
          case 2:
            return (
              <>
                <span> Objective For &nbsp;</span>
                {number}
                <sup>nd</sup>
                <span> &nbsp;Position</span>
              </>
            ); // 2nd, 22nd, 32nd
          case 3:
            return (
              <>
                <span> Objective For &nbsp;</span>
                {number}
                <sup>rd</sup>
                <span> &nbsp;Position</span>
              </>
            ); // 3rd, 23rd, 33rd
          default:
            return (
              <>
                <span> Objective For &nbsp;</span>
                {number}
                <sup>th</sup>
                <span> &nbsp;Position</span>
              </>
            ); // remaining "th"
        }
      };
    // const getDetails = async () => {
    //   try {
    //     setIsLoading(true);
    //     const result = await getRequest(
    //       `PMSAppraisalReview/AppraisalRatingSlab?ID=${appraisalCycleId}&EmployeeId=${LoggedInUser?.id}`
    //     );
    //     setData(result?.data?.data);
    //   } catch (error) {
    //     dispatch(
    //       showToast({
    //         text: error?.response?.data ?? "Error in fetching Data",
    //         severity: "error",
    //       })
    //     );
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };
    // console.log("object");

    // useEffect(() => {
    //   if (appraisalCycleId) getDetails();
    // }, [appraisalCycleId, LoggedInUser]);

    return (
        <>
            {/* {
            Array?.isArray(details?.appraisalRatingSlab) &&
                details?.appraisalRatingSlab?.length > 0 &&  */}
            {/* ( */}
            {/* <div style={{ background: "white", padding: "12px" }}>
                <p style={{ fontSize: "14px", padding: "0px 0px 12px 1px" }}>
                    Rating
                </p> */}
            {/* <div className="ratingCar">
                {details?.appraisalRatingSlab
                  ?.sort((a, b) => b.rating - a.rating)
                  ?.map((item) => (
                    <div className="starholderpmself">
                      <div style={{}}>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="17px"
                          starSpacing="2px"
                          rating={item?.rating}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ fontSize: "15px", fontWeight: "500" }}
                      >
                        <b>{item?.name}</b> ({item?.weightFrom}-{item?.weightTo} %
                        Achievement)
                      </label>
                    </div>
                  ))}
              </div> */}
            <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
                maxWidth: "100%",
                gap: "20px",
                padding: "20px 56px 20px 24px",
                borderRadius: "10px"
            }}
            >
               { details?.targetsSet?.length > 0 && details?.targetsSet?.map((item, index)=>(
                <div key={index} style={{ gridColumn: "span 6 / span 6" }}>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
                        maxWidth: "600px",
                        gap: "20px",
                        padding: "20px",
                        backgroundColor: "#e9f3f4",
                        borderRadius: "10px"
                    }}
                    >
                        <div style={{ gridColumn: "span 12 / span 12" }}>
                            <h3 style={{
                                color: "#1296B0",
                                fontSize: "13px",
                                fontWeight: "500",
                                fontFamily: "Poppins, Arial, sans-serif",
                            }}>
                               {item?.isConsolation === false ?  getOrdinalSuffix(index + 1)  : "Consolation"} 
                            </h3>
                            <p style={{ 
                                fontSize: "13px",
                                fontWeight: "400",
                                fontFamily: "Poppins, Arial, sans-serif",
                                color: "#555" 
                                }}>
                                {item?.objectives}
                            </p>
                            <table style={{
                                width: "100%",
                                marginTop: "20px",
                                borderCollapse: "collapse",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                overflow: "hidden",

                            }}>
                                <thead>
                                    <tr>
                                        <th style={{
                                            padding: "10px",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#555",
                                            textAlign: "left",
                                           
                                        }}>Item Description</th>
                                        <th style={{
                                            padding: "10px",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#555",
                                            textAlign: "center",
                                           
                                        }}>No. of Units</th>
                                        <th style={{
                                            padding: "10px",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#555",
                                            textAlign: "center",
                                           
                                        }}>Values</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item?.rewardsTargets?.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{
                                            padding: "15px",
                                            fontSize: "11px",
                                            fontWeight: "400",
                                          
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#555",
                                            borderTop: "1px dashed #bfbfbf"
                                        }}>
                                            {item?.title}
                                        </td>
                                        <td style={{
                                            padding: "15px",
                                            fontSize: "11px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            textAlign: "center",
                                            color: "#555",
                                            borderTop: "1px dashed #bfbfbf"
                                        }}>
                                            {item?.unit}
                                        </td>
                                        <td style={{
                                            padding: "15px",
                                            fontSize: "11px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            textAlign: "center",
                                            color: "#555",
                                            borderTop: "1px dashed #bfbfbf"
                                        }}>
                                            {item?.value}
                                        </td>
                                    </tr>
                                  ))}
                                    {/* <tr>
                                        <td style={{
                                            padding: "15px",
                                            fontSize: "11px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            color: "#555"
                                        }}>
                                            Lorem ipsum dolor sit amet consectetur. Sit enim elit dolor parturient volutpat velit
                                        </td>
                                        <td style={{
                                            padding: "15px",
                                            fontSize: "11px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            textAlign: "center",
                                            color: "#555"
                                        }}>2000</td>
                                        <td style={{
                                            padding: "15px",
                                            fontSize: "11px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins, Arial, sans-serif",
                                            textAlign: "center",
                                            color: "#555"
                                        }}>1950</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> 
                ))}
                
              
            </div>



            {/* </div> */}
            {/* ) */}

            {/* } */}
        </>
    );
};

export default MyRewardsReviewWinnersCriteria

import React, { useEffect, useState } from "react";
import { CheckBox } from "../../../Components/FormComponent/RadioGroupInput/InputStyles";
import { QuestionRadioFormik } from "../../LD/Components/QuestionRadioFormik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import { showToast } from "../../../Features";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { useDispatch } from "react-redux";
import { AppraisalRadioFormik } from "./AppraisalRadioFormik";
import { getRequest } from "../../../Services/axios";
import StarRatings from "react-star-ratings";
import ImageRepeater from "./ImageRepeater";
const CriteriaRatingSlabComp = ({
  ind,
  setinitialValues,
  formValues,
  setRadioOptions,
  radioOptions,
  isRatingSlab,
  removeCriteria,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [sumWeightage, setSumWeightage] = useState(0);

  // const radioOptions = [
  //   { id: 1, answerText: "fdgdsg vDepartment" },
  //   { id: 2, answerText: "what Department" },
  //   { id: 3, answerText: "Department" },
  //   { id: 4, answerText: "Department" },
  //   { id: 5, answerText: "Department" },
  //   { id: 6, answerText: "Department" },
  //   { id: 7, answerText: "Department" },
  //   { id: 8, answerText: "Department" },
  //   { id: 9, answerText: "Department" },
  //   { id: 10, answerText: "Department" },
  //   { id: 11, answerText: "Department" },
  //   { id: 12, answerText: "Department" },
  // ];
  const dispatch = useDispatch();

  return (
    <div
      style={{
        backgroundColor: "#FFF",
        width: "100%",
        margin: "auto auto",
        paddingBottom: "10px",
        borderRadius: "8px",
      }}
    >
      <div
        className="criteriaCreateStyle"
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div>Criteria {ind + 1} </div>{" "}
        <div
          style={
            formValues?.criteria?.length > 1
              ? {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  width: "54px",
                  justifyContent: "space-between",
                }
              : {}
          }
        >
          {formValues?.criteria?.length > 1 && (
            <svg
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                removeCriteria(ind);
              }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 4.8C0 2.14903 2.14903 0 4.8 0H19.2C21.851 0 24 2.14903 24 4.8V19.2C24 21.851 21.851 24 19.2 24H4.8C2.14903 24 0 21.851 0 19.2V4.8Z"
                fill="#FFDDDC"
              />
              <path
                d="M16.8002 9.32282C15.0242 9.14682 13.2375 9.05615 11.4562 9.05615C10.4002 9.05615 9.3442 9.10949 8.2882 9.21615L7.2002 9.32282"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.1338 8.7842L10.2511 8.08553C10.3365 7.57886 10.4005 7.2002 11.3018 7.2002H12.6991C13.6005 7.2002 13.6698 7.6002 13.7498 8.09086L13.8671 8.7842"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.9285 9.62402L15.3079 16.3784C15.2492 17.2158 15.2012 17.8664 13.7132 17.8664H10.2892C8.8012 17.8664 8.7532 17.2158 8.69453 16.3784L8.1709 9.62402"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.0186 14.958V12.0489"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0791 14.958V12.0489"
                stroke="#F71D1D"
                stroke-width="0.96"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          {isOpen ? (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 7.01221L7 0.987749L13 7.01221"
                stroke="#1296B0"
                stroke-width="1.33429"
                stroke-linecap="round"
              />
            </svg>
          ) : (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 0.987793L7 7.01225L13 0.987793"
                stroke="#1296B0"
                stroke-width="1.33429"
                stroke-linecap="round"
              />
            </svg>
          )}
        </div>
      </div>

      {isOpen && (
        <div
          style={{
            padding: "15px",
            backgroundColor: "#FFFFFF",
            width: "100%",
          }}
        >
          <div
            className="headingCriteriaCreate"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              Select Department <span style={{ color: "red" }}>{"*"}</span>
            </div>
            <div>
              <div
                onClick={() => {
                  formValues?.criteria?.length === 1 &&
                  radioOptions?.length ===
                    formValues.criteria[ind].departmentSelected?.length
                    ? setinitialValues(() => {
                        const finalObj = { ...formValues };
                        finalObj.criteria[ind].departmentSelected = [];
                        return finalObj;
                      })
                    : setinitialValues(() => {
                        let obj;
                        const finalObj = { ...formValues };
                        obj = radioOptions?.map((item) => item?.id?.toString());
                        finalObj.criteria[ind].departmentSelected = obj;
                        return finalObj;
                      });
                }}
                className="button primaryButton"
                style={
                  formValues?.criteria?.length === 1
                    ? { margin: "0px" }
                    : {
                        margin: "0px",
                        backgroundColor: "#BFBFBF",
                        color: "#FFFFFF",
                        cursor: "default",
                      }
                }
                disabled={formValues?.criteria?.length !== 1}
              >
                Select All
              </div>
            </div>
          </div>
          <div>
            <AppraisalRadioFormik
              isRow={true}
              isMultiSelect={true}
              name={`criteria[${ind}].departmentSelected`}
              // options={radioOptions}
              options={radioOptions?.filter((it) => {
                const arr = [];
                formValues?.criteria?.forEach((item, index) => {
                  if (index !== ind)
                    arr?.push(...(item?.departmentSelected || []));
                });
                return !arr?.includes(it?.id?.toString());
              })}
            />
          </div>

          <div className="headingCriteriaCreate">Configure Rating Slab</div>
          <div className="repeatedSectionContainer">
            <FieldArray name={`criteria[${ind}].appraisalSlab`}>
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { appraisalSlab } =
                  Array.isArray(values?.criteria) && values?.criteria[ind]
                    ? values.criteria[ind]
                    : {};

                return (
                  <table
                    className="AppraisalWeightageTable"
                    style={{ width: "max-content" }}
                  >
                    <thead>
                      <tr>
                        <td
                          rowspan="2"
                          padding="0"
                          style={{
                            fontSize: "12px",
                            width: "62px",
                          }}
                          align="center"
                        >
                          S. No
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                          colspan="2"
                        >
                          <LabelCustom
                            labelName={" Rating "}
                            mandatory={true}
                          />
                        </td>
                        <td
                          rowspan="2"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Name"} mandatory={true} />
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                          colspan="2"
                        >
                          <LabelCustom labelName={"Weightage Range"} />
                        </td>

                        <td
                          rowspan="2"
                          style={{
                            fontSize: "12px",
                            width: "120px",
                          }}
                          align="center"
                        >
                          Add/Remove
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Value"} mandatory={true} />
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                            width: "144px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Star"} mandatory={true} />
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"From"} mandatory={true} />
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"To"} mandatory={true} />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {appraisalSlab?.map((weight, index) => (
                        <tr key={index}>
                          <td align="center">{index + 1}</td>

                          <td style={{ width: "130px" }}>
                            <InputCustomFormik
                              style={{ border: "1px solid #E9F3F4" }}
                              type="number"
                              step="0.5"
                              name={`criteria[${ind}].appraisalSlab[${index}].rating`}
                              placeholder={"Enter"}
                            />
                          </td>
                          <td style={{ width: "130px" }}>
                            {formValues?.criteria[ind]?.appraisalSlab[index]
                              ?.rating >= 0 &&
                              formValues?.criteria[ind]?.appraisalSlab[index]
                                ?.rating <= 5 &&
                              formValues?.criteria[ind]?.appraisalSlab[index]
                                ?.rating %
                                0.5 ===
                                0 && (
                                <ImageRepeater
                                  number={
                                    formValues?.criteria[ind]?.appraisalSlab[
                                      index
                                    ]?.rating
                                  }
                                  total={5}
                                  imageUrl={
                                    <svg
                                      width="18"
                                      height="17"
                                      viewBox="0 0 18 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                                        fill="#F7941D"
                                      />
                                    </svg>
                                  }
                                  imageUrlSecond={
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                                        fill="#E6E6E6"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M8.99994 0L6.97931 6.21885H0.44043L5.7305 10.0623L3.70987 16.2812L8.99994 12.4377V0ZM14.1987 16H13.903L14.29 16.2812L14.1987 16Z"
                                        fill="#F7941D"
                                      />
                                    </svg>
                                  }
                                  imgBlank={
                                    <svg
                                      width="18"
                                      height="17"
                                      viewBox="0 0 18 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                                        fill="#E6E6E6"
                                      />
                                    </svg>
                                  }
                                />
                              )}
                          </td>
                          <td style={{ width: "130px" }}>
                            <InputCustomFormik
                              style={{ border: "1px solid #E9F3F4" }}
                              name={`criteria[${ind}].appraisalSlab[${index}].name`}
                              placeholder={"Enter"}
                            />
                          </td>
                          <td style={{ width: "130px" }}>
                            <InputCustomFormik
                              style={{ border: "1px solid #E9F3F4" }}
                              type="number"
                              name={`criteria[${ind}].appraisalSlab[${index}].fromWeightageRange`}
                              placeholder={"Enter"}
                            />
                          </td>
                          <td style={{ width: "130px" }}>
                            <InputCustomFormik
                              style={{ border: "1px solid #E9F3F4" }}
                              type="number"
                              name={`criteria[${ind}].appraisalSlab[${index}].toWeightageRange`}
                              placeholder={"Enter"}
                            />
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                appraisalSlab?.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  fromWeightageRange: "",
                                  toWeightageRange: "",
                                  name: "",
                                  rating: "",
                                })
                              }
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              }}
            </FieldArray>
          </div>
        </div>
      )}
    </div>
  );
};

export default CriteriaRatingSlabComp;

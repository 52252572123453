import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Notifications } from "../../DetailsComp/Notifications";
import { Details } from "../../DetailsComp/Details";
import { Remark } from "../../../../../Components/FormComponent/PopupComponent/Remark";

import Nodata from "../../../../../Assets/Nodata.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { TimeLineData } from "../../TransferComponents/DetailsComp/Timeline/TimeLineData";
import UploadDetail from "../../../../../Components/UploadDetail/UploadDetail";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../../Features/badgeUtils";

const DetailsPageResignation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [typId, setTypeId] = useState(false);
  const togglePopup = (type, color, extraId) => {
    setTypeAction(type);
    setTypeId(extraId);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  useEffect(() => {
    document.title = `PeopleSol - Details Page Resignation`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { value2, approve } = extractValuesFromURL(currentUrl);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  // //console.log("fsmodule",)
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `Resignations/Details?ID=${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result?.data?.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.data?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              approve,
              result.data?.data?.actionlist[0]?.color,
              result.data?.data?.actionlist[0]?.id
            );
          } else
            PostApRejHol(
              approve,
              result.data?.data?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const PostApRejHol = async (type, id, Remark) => {
    try {
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "approve"
      ) {
        await postRequest(`Resignations/${type}?Id=${id}`);
      } else if (type === "End Deputation") {
        await postRequest(`Resignations/EndDeputation?Id=${id}`);
      } else {
        await postRequest(`Resignations/${type}?Id=${id}&Remarks=${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      setIsOpen(false);
      getDetails();
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Resignation Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data != null ? (
                  loading ? (
                    <div className="noDataFound">
                      <Loader />
                    </div>
                  ) : (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="headingForm">Request Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Request Number"}
                          Details={data?.request?.requestNumber}
                        />
                        <Details
                          detailTitle={"Status"}
                          Details={
                            <div
                              style={{
                                backgroundColor: getStatusColor(
                                  data?.status?.replace(/<[^>]+>/g, "")
                                )?.split(",")?.[0],
                                color: getStatusColor(
                                  data?.status?.replace(/<[^>]+>/g, "")
                                )?.split(",")?.[1],
                                cursor: "default",
                                padding: "6px",
                                borderRadius: "6px",
                              }}
                            >
                              {data?.status?.replace(/<[^>]+>/g, "")}
                            </div>
                          }
                        />
                        <Details
                          detailTitle={"Resigned On"}
                          Details={format(
                            new Date(data?.request?.createdOn),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                        <Details
                          detailTitle={"Resignation Reason"}
                          Details={data?.request?.reason ?? "-"}
                        />
                        <Details
                          detailTitle={"Approved Date of Relieving"}
                          Details={format(
                            new Date(data?.request?.approvedRelievingDate),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />

                        <Details
                          detailTitle={" Resignation Letter"}
                          Details={
                            data?.request?.attachement == null ? (
                              "-"
                            ) : (
                              <UploadDetail
                                filepath={`ResignationLetters/${data?.request?.attachement}`}
                              />
                            )
                          }
                        />
                        <Details
                          detailTitle={"Date of Relieving"}
                          Details={format(
                            new Date(data?.request?.dateOfRelieving),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                        <Details
                          detailTitle={"Seperation Type"}
                          Details={data?.request?.exitType}
                        />
                        <Details
                          detailTitle={"Last Updated On"}
                          Details={format(
                            new Date(data?.request?.lastUpdatedOn),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                      </div>
                      <div className="headingForm">Requester Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Requester Name"}
                          Details={`${data?.request?.employee?.name} ${data?.request?.employee?.lastName ?? ""
                            }`}
                        />
                        <Details
                          detailTitle={"Requester's Manager"}
                          Details={data?.request?.employee?.rO_Name}
                        />
                        <Details
                          detailTitle={"Requester Designation"}
                          Details={data?.request?.employee?.designation}
                        />
                        <Details
                          detailTitle={"Requester Department"}
                          Details={data?.request?.employee?.department}
                        />
                        <Details
                          detailTitle={"Requester Email"}
                          Details={data?.request?.employee?.email}
                        />
                        <Details
                          detailTitle={"Contact Number"}
                          Details={
                            data?.request?.employee?.personalPhoneNumber
                              ? data?.request?.employee?.personalPhoneNumber
                              : "-"
                          }
                        />

                        <Details
                          detailTitle={"Location"}
                          Details={data?.request?.employee?.locationName}
                        />
                      </div>
                      {isOpen && (
                        <Popup
                          popupHeading={
                            typeAction === "Resolved"
                              ? "Ticket Feedback"
                              : typeAction
                          }
                          content={
                            <Remark
                              data={PostApRejHol}
                              type={typeAction}
                              id={typId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          }
                          handleClose={togglePopup}
                        />
                      )}
                      {data?.actionlist?.length > 0 ? (
                        <div
                          className="createFootDetails"
                          style={{ background: "white !important" }}
                        >
                          {data?.actionlist?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                    item.type !== "Release" &&
                                    item.type !== "Cancel"
                                    ? togglePopup(
                                      item?.type,
                                      item?.color,
                                      item?.id
                                    )
                                    : PostApRejHol(
                                      item?.type,
                                      item?.id,
                                      item?.id
                                    );
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Records"}</h4>
                  </div>
                ))}

              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimeLineData
                        id={getIdFromDynamicUrl(currentUrl)}
                        url={"Resignations"}
                        module={data?.module}
                        requestNumber={data?.request?.requestNumber}
                      />
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications
                      id={getIdFromDynamicUrl(currentUrl)}
                      module={"Exit Request"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageResignation };

import { getRequest } from "../../../Services/axios";

export const downloadBlobChat = async (
  base64Data,
  fileType,
  fileUrl,
  fileName
) => {
  if (base64Data?.length > 0 && fileType === "pdf") {
    // Decode the Base64 string to binary data
    const binaryString = window.atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Determine the MIME type and filename extension based on fileType
    let mimeType = "application/octet-stream"; // default MIME type
    let extension = ""; // default extension
    switch (fileType.toLowerCase()) {
      case "pdf":
        mimeType = "application/pdf";
        extension = "pdf";
        break;
      case "jpg":
      case "jpeg":
        mimeType = "image/jpeg";
        extension = "jpg";
        break;
      case "png":
        mimeType = "image/png";
        extension = "png";
        break;
      case "docx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        extension = "docx";
        break;
      case "xlsx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        extension = "xlsx";
        break;
      case "mp4":
        mimeType = "video/mp4";
        extension = "mp4";
        break;
      case "zip":
        mimeType = "application/zip";
        extension = "zip";
        break;
      case "gzip":
        mimeType = "application/gzip";
        extension = "gzip";
        break;
      case "webm":
        mimeType = "video/webm";
        extension = "webm";
        break;
      case "ogv":
        mimeType = "video/ogv";
        extension = "ogv";
        break;
      // Add more cases for other file types as needed
      default:
        break;
    }

    // Create a new Blob object using the binary data
    const blob = new Blob([bytes], { type: mimeType });

    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(blob);

    // Create and trigger a download item
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", `${fileName}.${extension}`); // Use dynamic filename
    document.body.appendChild(element);
    element.click();

    // Cleanup
    document.body.removeChild(element);
    window.URL.revokeObjectURL(url);
  } else if (base64Data?.length === 0 && fileType) {
    const base64DataApi = await downloadBlobChatApiCall(fileUrl);
    const binaryString = window.atob(base64DataApi);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Determine the MIME type and filename extension based on fileType
    let mimeType = "application/octet-stream"; // default MIME type
    let extension = ""; // default extension
    switch (fileType.toLowerCase()) {
      case "pdf":
        mimeType = "application/pdf";
        extension = "pdf";
        break;
      case "jpg":
      case "jpeg":
        mimeType = "image/jpeg";
        extension = "jpg";
        break;
      case "png":
        mimeType = "image/png";
        extension = "png";
        break;
      case "docx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        extension = "docx";
        break;
      case "xlsx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        extension = "xlsx";
        break;
      case "mp4":
        mimeType = "video/mp4";
        extension = "mp4";
        break;
      case "webm":
        mimeType = "video/webm";
        extension = "webm";
        break;
      case "zip":
        mimeType = "application/zip";
        extension = "zip";
        break;
      case "gzip":
        mimeType = "application/gzip";
        extension = "gzip";
        break;
      case "ogv":
        mimeType = "video/ogv";
        extension = "ogv";
        break;
      // Add more cases for other file types as needed
      default:
        break;
    }

    // Create a new Blob object using the binary data
    const blob = new Blob([bytes], { type: mimeType });

    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(blob);

    // Create and trigger a download item
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", `${fileName}.${extension}`); // Use dynamic filename
    document.body.appendChild(element);
    element.click();

    // Cleanup
    document.body.removeChild(element);
    window.URL.revokeObjectURL(url);
  }
};
export const downloadBlobChatApiCall = async (url) => {
  try {
    const result = await getRequest(
      `LMSController/imageToBase64?imageUrl=${url}`
    );
    return result?.data;
  } catch (error) {
    // setError(error);
  }
};

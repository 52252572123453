import React from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { useEffect } from "react";
import { getRequest } from "../../../../../Services/axios";
import { useState } from "react";

function CandidateDetails({

    candidateList,
    subDepartmentList,
    departmentList,
    formValues,
    isRevise
}) {
    const [reqOptions, setReqOptions] = useState([]);


    useEffect(() => {
        document.title = `PeopleSol - Position Details`;
        return () => {
            document.title = "PeopleSol";
        };
    }, []);
    const RequisitionOptions = async () => {
        try {
            let listData = [];
            const result = await getRequest("HrmsOfferLetter/GetRequisition");
            if (result.data) {
                listData = result.data.data.map((item) => ({ label: item.requestNumber, value: item.id }))
            }
            setReqOptions(listData);
        } catch (error) {
        } finally {
        }
    };

    useEffect(() => {
        RequisitionOptions()
    }, []);
    return (
        <>
            <div className="formcustom twoColumn">
                <div>
                    <LabelCustom labelName={"Select Requisition No."} mandatory={true} />
                    <SelectForm
                        disabled={isRevise}
                        placeholder={"Select Requisition No."}
                        name="requisition_Number"
                        options={reqOptions}
                    />
                </div>
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Candidate Name"}
                        mandatory={true}
                    />
                    <SelectForm
                        options={candidateList}
                        disabled={isRevise}
                        placeholder={"Select Candidate Name"}
                        name="candidateId"
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Candidate Email"}
                        mandatory={true}
                    />
                    <InputCustomFormik
                        maxLength={150}
                        disabled={true}
                        placeholder={"Enter Candidate Email Address"}
                        name="email"
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom labelName={"Employment Type"} mandatory={true} />
                    <SelectForm
                        placeholder={"Select Employment Type"}
                        name="type"
                        disabled={isRevise}
                        options={[{ value: "Full Time", label: "Full Time" }, { label: "Contractual", value: "Contractual" }]}
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Proposed CTC"}
                        mandatory={true}
                    />
                    <InputCustomFormik
                        // maxLength={150}
                        disabled={true}
                        type={"number"}
                        placeholder={"No budget available"}
                        name="budget"
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Offer Annual CTC"}
                        mandatory={true}
                    />
                    <InputCustomFormik
                        // maxLength={150}
                        disabled={isRevise}
                        type={"number"}
                        placeholder={"Enter CTC Amount"}
                        name="annual_ctc_Offered"
                    />
                </div> : null}
                {isRevise && formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Revise Annual CTC"}
                        mandatory={true}
                    />
                    <InputCustomFormik
                        // maxLength={150}
                        type={"number"}
                        placeholder={"Enter CTC Amount"}
                        name="revisedannualCtcOffered"
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Offer Expiry Date"}
                        mandatory={true}
                    />
                    <DatePickerFormiks
                        disabled={isRevise}
                        placeholder={"Select Offer Expiry Date"}
                        name="expirayDate"
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Date Of Joining"}
                        mandatory={true}
                    />
                    <DatePickerFormiks
                        disabled={isRevise}
                        placeholder={"Select Date Of Joining"}
                        name="dateJoining"
                    />
                </div> : null}

                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Role"}
                        mandatory={true}
                    />
                    <InputCustomFormik
                        maxLength={150}
                        disabled={true}
                        placeholder={"Enter Role"}
                        name="role"
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom labelName={"Band"} mandatory={true} />
                    <InputCustomFormik
                        placeholder={"Select Band"}
                        name="band"
                        disabled={true}
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Designation"}
                        mandatory={true}
                    />
                    <InputCustomFormik
                        maxLength={150}
                        placeholder={"Enter Designation"}
                        name="designation"
                        disabled={true}
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom labelName={"Department"} mandatory={true} />
                    <SelectForm
                        placeholder={"Select Department"}
                        name="department"
                        disabled={isRevise}
                        options={departmentList}
                    />
                </div> : null}
                {formValues.requisition_Number > 0 && formValues.department > 0 && subDepartmentList.length > 0 ? <div>
                    <LabelCustom labelName={"Sub-Department"} mandatory={true} />
                    <SelectForm
                        options={subDepartmentList}
                        disabled={isRevise}
                        placeholder={"Select Sub-Department"}
                        name="subDepartment"
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Reporting Manager"}
                        mandatory={true}
                    />
                    <InputCustomFormik
                        maxLength={150}
                        placeholder={"Enter Reporting Manager"}
                        name="reportingManager"
                        disabled={true}
                    />
                </div> : null}
                {formValues.requisition_Number > 0 ? <div>
                    <LabelCustom
                        labelName={"Location"}
                        mandatory={true}
                    />
                    <InputCustomFormik
                        maxLength={150}
                        placeholder={"Enter Location"}
                        name="location"
                        disabled={true}
                    />
                </div> : null}

            </div>
        </>
    );
}

export { CandidateDetails };

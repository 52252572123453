import { FieldArray } from "formik";
import { Table } from "@mui/material";
import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import DropdownInfiniteScroll from "../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";
import { showToast } from "../../../Features";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
export const StyledTableCell = styled(TableCell)({
  padding: "5px",
  border: "1px solid #bdbdbd5c",
});
export const StyledTableCont = styled(TableCell)({
  overflow: "revert !important",
});
export const BuisnessUnitComp = ({ data, editID }) => {
  useEffect(() => {
    document.title = `PeopleSol - Buisness Unit`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const dispatch = useDispatch();
  return (
    <div style={{ paddingBottom: "65px" }}>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>

      <div className="repeatedSectionContainer">
        <FieldArray name="teamsMembers">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { teamsMembers } = values;

            return (
              <StyledTableCont className="">
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" S. No"} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" Employee"} mandatory={true} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={" Signature"}
                          mandatory={true}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={" Add/Remove"}
                          mandatory={true}
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {teamsMembers.map((teamsMember, index) => (
                    <TableBody key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <DropdownInfiniteScroll
                          name={`teamsMembers[${index}].employeeId`}
                          placeholder={"Select Employee"}
                          details={data}
                          editID={editID}
                          detailsForEdit={data?.teamsMembers?.map(
                            (it) => it?.employeeId
                          )}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <FileUploadFormik
                          name={`teamsMembers[${index}].signature`}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            teamsMembers.length > 1
                              ? remove(index)
                              : dispatch(
                                  showToast({
                                    text: "Atleast One Item is Required ",
                                    severity: "error",
                                  })
                                )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({
                              employeeId: "",
                              signature: "",
                            })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { GroupBy } from "../../../../Utilities";
import { useLocation, useNavigate } from "react-router-dom";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { RadioFormik } from "../../../../Components/FormComponent/RadioFormik";
import { Loader } from "../../../../Components/FormComponent/Loader";
import * as Yup from "yup";

const calculationTypeOptions = [
  { label: "Flat Amount", value: "Flat Amount" },
  { label: "Percentage of CTC", value: "Percentage of CTC" },
];
const calculationTypeOption = [
  { label: "Flat Amount", value: "Flat Amount" },
  { label: "Percentage of Basic", value: "Percentage of Basic" },
];
const payTypeOptions = [
  {
    label: "Fixed Pay (Fixed amount paid at the end of every month.)",
    value: "Fixed Pay",
  },
  {
    label: "Variable Pay (Variable amount paid during any payroll.)",
    value: "Variable Pay",
  },
];

function CreateEarning() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [earningTypeOptions, setEarningTypeOptions] = useState([]);
  const [earningConfigs, setEarningConfigs] = useState([]);
  const [earningTypeDetails, setEarningTypeDetails] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [isPayTypeVisible, setIsPayTypeVisible] = useState(false);
  const [isCalCulationTypeVisible, setIsCalCulationTypeVisible] =
    useState(false);
  const [configData, setConfigData] = useState(false);
  const [initialValues, setInitialValues] = useState({
    earningName: "",
    earningType: "",
    payType: "Fixed Pay",
    calculationType: "Flat Amount",
    considerForESI: "",
    calculationValue: "",
    nameInPayslip: "",
    isActive: true,
  });
  const [detailsform, setDetailsform] = useState(null);
  const { state } = useLocation();
  const editId = state?.id;
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `SalaryComponents/EarningDetails/${editId}`
      );
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Earning `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getInitialConfigValues = (groupedConfig) => {
    let returnObject = {};
    Object.entries(groupedConfig).map(([mainHeading, configItems]) => {
      returnObject[configItems[0]?.configId] = configItems[0]?.isActive;
      if (mainHeading === "Tax Deduction Preference") {
        returnObject["taxPreference"] = configItems[0]?.configId.toString();
      }
      if (mainHeading === "Consider for EPF Contribution") {
        returnObject["epfContribution"] = configItems[0]?.configId.toString();
      }
      return null;
    });
    return returnObject;
  };
  const getEditConfigValues = (groupedConfig) => {
    let returnObject = {};
    Object.entries(groupedConfig).map(([mainHeading, configItems]) => {
      returnObject[configItems[0]?.configId] = configItems[0]?.isActive;
      if (mainHeading === "Tax Deduction Preference") {
        let activeConfig = configItems.find((item) => item.isActive === true);
        returnObject["taxPreference"] = activeConfig
          ? activeConfig.configId?.toString()
          : configItems[0]?.configId?.toString();
      }
      if (mainHeading === "Consider for EPF Contribution") {
        let activeConfig = configItems.find((item) => item.isActive === true);
        returnObject["epfContribution"] = activeConfig
          ? activeConfig.configId?.toString()
          : configItems[0]?.configId?.toString();
      }
      return null;
    });
    return returnObject;
  };
  useEffect(() => {
    if (detailsform) {
      if (!earningTypeDetails) return;
      let earningType = earningTypeDetails?.find(
        (x) => x.earningType === detailsform?.earningType
      );
      let earningConfigs = [];
      setEarningConfigs(detailsform?.config);
      detailsform?.config?.map((item) => {
        return earningConfigs.push({
          ...item,
          configType: item?.config?.configType,
        });
      });
      setInitialValues((prev) => {
        return {
          ...prev,
          earningType: detailsform?.earningType,
          earningName: detailsform?.earningName,
          considerForESI: detailsform?.considerForESI === "Yes" ? true : false,
          payType: !earningType?.isPayType
            ? earningType?.isCalCulationType
              ? "Fixed Pay"
              : "Variable Pay"
            : detailsform?.payType,
          calculationType: detailsform?.calculationType,
          calculationValue: detailsform?.calculationValue,
          nameInPayslip: detailsform?.nameInPayslip,
          isActive: detailsform?.isActive,
          config: getEditConfigValues(GroupBy(earningConfigs, "configType")),
        };
      });
      if (earningType?.isPayType) setIsPayTypeVisible(true);
      if (earningType?.isCalCulationType) setIsCalCulationTypeVisible(true);
      setConfigData(GroupBy(earningConfigs, "configType"));
    }
  }, [detailsform, earningTypeDetails]);
  useEffect(() => {
    if (editId) {
      getFormDetails();
    }
  }, [editId]);

  useEffect(() => {
    if (formValues?.earningType) {
      if (!earningTypeDetails) return;
      let earningType = earningTypeDetails?.find(
        (x) => x.earningType === formValues?.earningType
      );
      if (earningType) {
        if (earningType?.isPayType) setIsPayTypeVisible(true);
        if (earningType?.isCalCulationType) setIsCalCulationTypeVisible(true);
        let earningConfigs = [];
        setEarningConfigs(earningType?.earningConfigs);
        earningType?.earningConfigs?.map((item) => {
          return earningConfigs.push({
            ...item,
            configType: item?.config?.configType,
          });
        });
        setInitialValues((prev) => {
          return {
            ...prev,
            earningName: formValues?.earningName,
            earningType: formValues?.earningType,
            payType: !earningType?.isPayType
              ? earningType?.isCalCulationType
                ? "Fixed Pay"
                : "Variable Pay"
              : formValues?.payType,
            calculationType: formValues?.calculationType,
            calculationValue: formValues?.calculationValue,
            nameInPayslip: formValues?.nameInPayslip,
            isActive: formValues?.isActive,
            config: getInitialConfigValues(
              GroupBy(earningConfigs, "configType")
            ),
          };
        });
        setConfigData(GroupBy(earningConfigs, "configType"));
      }
    }
  }, [formValues?.earningType]);
  const create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "SalaryComponents/CreateEarning",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("SalaryComponents/EditEarning", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  const getEarningType = async () => {
    try {
      setLoading(true);
      const result = await getRequest("SalaryComponents/DefaultEarning");
      if (result) {
        setEarningTypeDetails(result?.data?.data);
        let tempObject = [];
        result?.data?.data?.map((item) => {
          return tempObject.push({
            label: item?.earningType && item.earningType.trim(),
            value: item?.earningType && item.earningType.trim(),
          });
        });
        setEarningTypeOptions(tempObject);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };
  const submitEditCompany = (values) => {
    if (isCalCulationTypeVisible &&
      formValues?.payType === "Fixed Pay") {
      if (!values.calculationType) {
        dispatch(
          showToast({
            text: "Please Select Calculation type",
            severity: "error",
          })
        )
        return
      }
      if (!values.calculationValue && (values.CalculationValue != 0 || values.CalculationValue != "0")) {
        dispatch(
          showToast({
            text: "Please Enter Calculation value",
            severity: "error",
          })
        )
        return
      }
    }
    let payload = {};
    payload["Id"] = editId ?? 0;
    payload["EarningType"] = values?.earningType;
    payload["NameInPayslip"] = values?.nameInPayslip;
    payload["PayType"] = values?.payType;
    if (isCalCulationTypeVisible)
      payload["CalculationType"] = values?.calculationType;
    if (values?.calculationValue && values?.calculationValue !== "")
      payload["CalculationValue"] = values?.calculationValue;
    let considerForEPF = Object.entries(values?.config).some(([key, value]) => {
      return (
        (key.toString() === "8" || key.toString() === "9") && value === true
      );
    });
    payload["ConsiderForEPF"] = considerForEPF ? "Yes" : "No";
    payload["considerForESI"] = values?.considerForESI ? "Yes" : "No";
    payload["IsActive"] = values.isActive;


    payload["EarningName"] = values?.nameInPayslip;
    let configArray = [];
    for (const key in values?.config) {
      let earningConfigDetails = earningConfigs?.find(
        (x) => x?.configId?.toString() === key.toString()
      );
      if (values?.config[key] === true) {
        if (
          Number(key) !== 3 &&
          Number(key) !== 4 &&
          Number(key) !== 8 &&
          Number(key) !== 9
        ) {
          if (Number(key) !== 7) {
            configArray.push({
              ConfigId: Number(key),
              EarningName: values?.earningName,
              ConfigName: earningConfigDetails?.configName,
              IsEditable: earningConfigDetails?.isEditable,
              IsActive: true,
            });
          } else {
            if (values?.config["6"] === true) {
              configArray.push({
                ConfigId: Number(key),
                EarningName: values?.earningName,
                ConfigName: earningConfigDetails?.configName,
                IsEditable: earningConfigDetails?.isEditable,
                IsActive: true,
              });
            } else {
              configArray.push({
                ConfigId: Number(key),
                EarningName: values?.earningName,
                ConfigName: earningConfigDetails?.configName,
                IsEditable: earningConfigDetails?.isEditable,
                IsActive: false,
              });
            }
          }
        } else {
          if (Number(key) === 3 || Number(key) === 4) {
            if (values?.config?.taxPreference === "3") {
              configArray.push({
                EarningName: values?.earningName,
                ConfigId: 3,
                ConfigName: earningConfigs?.find((x) => x.configId === 3)
                  .configName,
                IsEditable: earningConfigs?.find((x) => x.configId === 3)
                  .isEditable,
                IsActive: true,
              });
              configArray.push({
                EarningName: values?.earningName,
                ConfigId: 4,
                ConfigName: earningConfigs?.find((x) => x.configId === 4)
                  .configName,
                IsEditable: earningConfigs?.find((x) => x.configId === 4)
                  .isEditable,
                IsActive: false,
              });
            } else {
              configArray.push({
                EarningName: values?.earningName,
                ConfigId: 3,
                ConfigName: earningConfigs?.find((x) => x.configId === 3)
                  .configName,
                IsEditable: earningConfigs?.find((x) => x.configId === 3)
                  .isEditable,
                IsActive: false,
              });
              configArray.push({
                EarningName: values?.earningName,
                ConfigId: 4,
                ConfigName: earningConfigs?.find((x) => x.configId === 4)
                  .configName,
                IsEditable: earningConfigs?.find((x) => x.configId === 4)
                  .isEditable,
                IsActive: true,
              });
            }
          } else if (Number(key) === 8 || Number(key) === 9) {
            if (values?.config?.epfContribution === "8") {
              configArray.push({
                EarningName: values?.earningName,
                ConfigId: 8,
                ConfigName: earningConfigs?.find((x) => x.configId === 8)
                  .configName,
                IsEditable: earningConfigs?.find((x) => x.configId === 8)
                  .isEditable,
                IsActive: true,
              });
              configArray.push({
                EarningName: values?.earningName,
                ConfigId: 9,
                ConfigName: earningConfigs?.find((x) => x.configId === 9)
                  .configName,
                IsEditable: earningConfigs?.find((x) => x.configId === 9)
                  .isEditable,
                IsActive: false,
              });
            } else {
              configArray.push({
                EarningName: values?.earningName,
                ConfigId: 8,
                ConfigName: earningConfigs?.find((x) => x.configId === 8)
                  .configName,
                IsEditable: earningConfigs?.find((x) => x.configId === 8)
                  .isEditable,
                IsActive: false,
              });
              configArray.push({
                EarningName: values?.earningName,
                ConfigId: 9,
                ConfigName: earningConfigs?.find((x) => x.configId === 9)
                  .configName,
                IsEditable: earningConfigs?.find((x) => x.configId === 9)
                  .isEditable,
                IsActive: true,
              });
            }
          }
        }
      } else if (values?.config[key] === false) {
        if (
          Number(key) !== 3 &&
          Number(key) !== 4 &&
          Number(key) !== 8 &&
          Number(key) !== 9
        ) {
          configArray.push({
            EarningName: values?.earningName,
            ConfigId: Number(key),
            ConfigName: earningConfigDetails?.configName,
            IsEditable: earningConfigDetails?.isEditable,
            IsActive: false,
          });
        } else {
          if (Number(key) === 3 || Number(key) === 4) {
            configArray.push({
              EarningName: values?.earningName,
              ConfigId: 3,
              ConfigName: earningConfigs?.find((x) => x.configId === 3)
                .configName,
              IsEditable: earningConfigs?.find((x) => x.configId === 3)
                .isEditable,
              IsActive: false,
            });
            configArray.push({
              EarningName: values?.earningName,
              ConfigId: 4,
              ConfigName: earningConfigs?.find((x) => x.configId === 4)
                .configName,
              IsEditable: earningConfigs?.find((x) => x.configId === 4)
                .isEditable,
              IsActive: false,
            });
          } else if (Number(key) === 8 || Number(key) === 9) {
            configArray.push({
              EarningName: values?.earningName,
              ConfigId: 8,
              ConfigName: earningConfigs?.find((x) => x.configId === 8)
                .configName,
              IsEditable: earningConfigs?.find((x) => x.configId === 8)
                .isEditable,
              IsActive: false,
            });
            configArray.push({
              EarningName: values?.earningName,
              ConfigId: 9,
              ConfigName: earningConfigs?.find((x) => x.configId === 9)
                .configName,
              IsEditable: earningConfigs?.find((x) => x.configId === 9)
                .isEditable,
              IsActive: false,
            });
          }
        }
      }
    }
    payload["Config"] = configArray;
    editId ? edit(payload) : create(payload);
  };
  useEffect(() => {
    getEarningType();
  }, []);
  const isInfoVisible = (earningType) => {
    if (earningType) {
      let earningType = earningTypeDetails?.find(
        (x) => x.earningType === formValues?.earningType
      );
      if (earningType) {
        if (earningType?.isPayType && earningType?.isCalCulationType)
          return false;
        if (!earningType?.isPayType && earningType?.isCalCulationType)
          return true;
        if (!earningType?.isPayType && !earningType?.isCalCulationType)
          return true;
      }
    }
    return false;
  };


  const validationSchema = Yup.object({
    earningType: Yup.string().required("Please Select Earning Type"),
    nameInPayslip: Yup.string()
      .max(100, "Must be less than 100 characters")
      .required("Please Enter Name"),
    // calculationType: isCalCulationTypeVisible && formValues?.payType === "Fixed Pay" ? Yup.string().required("Please Select Calculation Type") : Yup.string(),
    // calculationValue: isCalCulationTypeVisible && formValues?.payType === "Fixed Pay" ? Yup.mixed().required("Please Enter Calculation Value") : Yup.mixed(),
  });

  return (
    <div className="moduleMainContainer">
      <div
        className={"companyCard"}
        style={{ overflow: "auto", backgroundColor: "var(--form-background)" }}
      >
        <div className="createHeader" style={{ padding: 10, fontSize: 16, color: "rgba(18, 150, 176, 1)", fontWeight: "500" }}>
          New Earning

        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => submitEditCompany(values)}
        >
          {(formik) => {
            setFormValues(formik?.values);
            return (
              <Form
                style={{
                  overflow: "auto",
                  height: "calc(100% - 72px)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <LabelCustom
                        labelName={"Earning Type"}
                        mandatory={true}
                      />
                      <SelectForm
                        borderBlack={true}
                        name="earningType"
                        options={earningTypeOptions}
                        isDisabled={editId ? true : false}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      {isInfoVisible(formik?.values?.earningType) && (
                        <div
                          style={{
                            display: "flex",
                            marginTop: "43px",
                            height: "70px",
                            padding: "12px 12px 18px 0",
                            alignItems: "center",
                            gap: "6px",
                            fontSize: "12px",
                          }}
                        >
                          <svg
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="7.31091"
                              cy="6.4917"
                              r="6.44385"
                              fill="#BFBFBF"
                            />
                            <path
                              d="M7.88791 4.04598C8.48292 4.04598 8.96527 3.49528 8.96527 2.81596C8.96527 2.13664 8.48292 1.58594 7.88791 1.58594C7.2929 1.58594 6.81055 2.13664 6.81055 2.81596C6.81055 3.49528 7.2929 4.04598 7.88791 4.04598Z"
                              fill="white"
                            />
                            <path
                              d="M8.98951 9.03754C8.95975 9.01468 8.92629 8.99884 8.89109 8.99095C8.85589 8.98305 8.81967 8.98327 8.78454 8.99157C8.74942 8.99988 8.7161 9.01611 8.68655 9.03932C8.65699 9.06253 8.63179 9.09224 8.61243 9.12672C8.35206 9.56695 8.03309 9.95809 7.66705 10.286C7.57817 10.3629 7.24688 10.655 7.10682 10.5935C7.00986 10.5597 7.06642 10.3414 7.08528 10.2491L7.22802 9.76633C7.28728 9.56952 8.31885 6.07626 8.43198 5.67651C8.59897 5.09225 8.52625 4.51721 7.76401 4.65559C7.55662 4.68019 5.45307 4.99077 5.41537 4.99385C5.37999 4.99647 5.34542 5.00702 5.31363 5.0249C5.28183 5.04278 5.25343 5.06763 5.23004 5.09804C5.20666 5.12846 5.18875 5.16383 5.17733 5.20214C5.16592 5.24045 5.16123 5.28096 5.16353 5.32134C5.16583 5.36172 5.17507 5.40119 5.19073 5.43749C5.20639 5.4738 5.22816 5.50622 5.2548 5.53292C5.28144 5.55962 5.31242 5.58007 5.34597 5.5931C5.37953 5.60613 5.41501 5.61148 5.45038 5.60886C5.45038 5.60886 6.2584 5.48893 6.34728 5.4797C6.39281 5.47463 6.43865 5.4847 6.47927 5.50871C6.5199 5.53272 6.55357 5.56964 6.57622 5.61501C6.62503 5.78725 6.61834 5.97373 6.55737 6.14084C6.48734 6.44834 5.38035 10.0093 5.34534 10.2153C5.30778 10.3877 5.31821 10.5692 5.37517 10.7345C5.43213 10.8998 5.53279 11.0408 5.66316 11.1378C5.90786 11.324 6.19921 11.4136 6.49273 11.393C6.77811 11.389 7.06035 11.3244 7.32499 11.2024C7.99564 10.8949 8.69593 10.0769 9.06493 9.43422C9.0967 9.36892 9.10657 9.29265 9.09269 9.21966C9.07881 9.14666 9.04214 9.08192 8.98951 9.03754Z"
                              fill="white"
                            />
                          </svg>
                          {formValues.calculationType ===
                            "Percentage of Basic" &&
                            <p style={{ marginTop: '18px' }}> Variable amount paid at the end of every month if calculation type is selected as Percentage.</p>}
                          {formValues.calculationType === "Flat Amount" &&
                            "Fixed amount paid at the end of every month if calculation type is selected as Flat"}
                        </div>
                      )}
                    </div>
                  </div>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  {formValues?.earningType &&
                    !loading &&
                    formValues?.earningType !== "" ? (
                    <div
                      className="companyCardContainer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderTop: "1px solid var(--grey)",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "50%" }} className="formcustom">
                          {/* <div>
                            <LabelCustom
                              labelName={"Earning Name"}
                              mandatory={true}
                            />
                            <InputCustomFormik borderBlack={true} name="earningName" />
                          </div> */}
                          <div>
                            <LabelCustom
                              labelName={"Name in Payslip"}
                              mandatory={true}
                            />
                            <InputCustomFormik borderBlack={true} name="nameInPayslip" />
                          </div>
                          {isPayTypeVisible && (
                            <div>
                              <LabelCustom
                                labelName={"Pay Type"}
                                mandatory={true}
                              />
                              <SelectForm
                                borderBlack={true}
                                name="payType"
                                options={payTypeOptions}
                              />
                            </div>
                          )}
                          {isCalCulationTypeVisible &&
                            formValues?.payType === "Fixed Pay" && (
                              <>
                                <div>
                                  <LabelCustom
                                    labelName={"Calculation Type"}
                                    mandatory={true}
                                  />
                                  <SelectForm
                                    borderBlack={true}
                                    name="calculationType"
                                    options={
                                      formValues?.earningType === "Basic"
                                        ? calculationTypeOptions
                                        : calculationTypeOption
                                    }
                                  />
                                </div>
                                <div>
                                  <LabelCustom
                                    labelName={
                                      formValues?.calculationType ===
                                        "Flat Amount"
                                        ? "Enter Amount"
                                        : "Enter Percentage"
                                    }
                                    mandatory={true}
                                  />
                                  <InputCustomFormik borderBlack={true}
                                    name="calculationValue"
                                    type="number"
                                  />
                                </div>
                              </>
                            )}
                          <div>
                            <CheckboxFormiks
                              name="isActive"
                              labelName={"Mark this as Active"}
                            />
                          </div>
                          <div>
                            <CheckboxFormiks
                              name="considerForESI"
                              labelName={"Consider For ESIC"}
                            />
                          </div>
                        </div>
                        {/* <div
                          style={{
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.02)",
                            backgroundColor: "white",
                            borderRadius: "4px",
                            width: "50%",
                            padding: "12px",
                            margin: "24px 12px 12px 12px",
                            lineHeight: "22px",
                            height: "fit-content",
                          }}
                        >
                          <h5 style={{ color: "var(--primary)" }}>
                            Other Configurations
                          </h5>
                          <div>
                            {configData &&
                              Object.entries(configData).map(
                                ([mainHeading, configItems]) => {
                                  let epfOptions = [
                                    {
                                      label: "Always",
                                      value: "8",
                                    },
                                    {
                                      label:
                                        "Only when PF Wage is less than ₹ 15,000",
                                      value: "9",
                                    },
                                  ];
                                  let taxOptions = [
                                    {
                                      label:
                                        "Deduct tax in subsequent payrolls of the financial year",
                                      value: "3",
                                    },
                                    {
                                      label: "Deduct tax in the same payroll",
                                      value: "4",
                                    },
                                  ];
                                  if (
                                    mainHeading ===
                                      "Consider for EPF Contribution" ||
                                    mainHeading ===
                                      "Include this as a Flexible Benefit Plan component" ||
                                    mainHeading === "Tax Deduction Preference"
                                  )
                                    return (
                                      <div>
                                        <CheckboxFormiks
                                          name={`config.${configItems[0]?.configId}`}
                                          labelName={mainHeading}
                                          disabled={
                                            configItems[0]?.isEditable
                                              ? false
                                              : true
                                          }
                                        />
                                        {configItems[0]?.config
                                          ?.configDescriptions && (
                                          <p
                                            style={{
                                              fontSize: "11px",
                                              marginLeft: "45px",
                                              marginTop: "-24px",
                                            }}
                                          >
                                            {
                                              configItems[0]?.config
                                                ?.configDescriptions
                                            }
                                          </p>
                                        )}
                                        {formik?.values?.config[
                                          `${configItems[0]?.configId}`
                                        ] &&
                                          mainHeading ===
                                            "Include this as a Flexible Benefit Plan component" &&
                                          configItems?.map((item) => {
                                            if (
                                              item?.configName ===
                                              "Include this as a Flexible Benefit Plan component"
                                            )
                                              return null;
                                            return (
                                              <div
                                                style={{
                                                  marginLeft: "24px",
                                                  marginTop: "-12px",
                                                }}
                                              >
                                                <CheckboxFormiks
                                                  name={`config.${item?.configId}`}
                                                  labelName={item?.configName}
                                                  disabled={
                                                    item?.isEditable
                                                      ? false
                                                      : true
                                                  }
                                                />
                                                {item?.config
                                                  ?.configDescriptions && (
                                                  <p
                                                    style={{
                                                      fontSize: "11px",
                                                      marginLeft: "45px",
                                                      marginTop: "-24px",
                                                    }}
                                                  >
                                                    {
                                                      item?.config
                                                        ?.configDescriptions
                                                    }
                                                  </p>
                                                )}
                                              </div>
                                            );
                                          })}
                                        {formik?.values?.config[
                                          `${configItems[0]?.configId}`
                                        ] &&
                                          mainHeading ===
                                            "Consider for EPF Contribution" && (
                                            <div
                                              style={{
                                                marginLeft: "24px",
                                                marginTop: "-12px",
                                              }}
                                            >
                                              <RadioFormik
                                                options={epfOptions}
                                                name="config.epfContribution"
                                              />
                                              {configItems[1]?.config
                                                ?.configDescriptions && (
                                                <p
                                                  style={{
                                                    fontSize: "11px",
                                                    marginLeft: "45px",
                                                    marginTop: "-24px",
                                                  }}
                                                >
                                                  {
                                                    configItems[1]?.config
                                                      ?.configDescriptions
                                                  }
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        {formik?.values?.config[
                                          `${configItems[0]?.configId}`
                                        ] &&
                                          mainHeading ===
                                            "Tax Deduction Preference" && (
                                            <div
                                              style={{
                                                marginLeft: "24px",
                                                marginTop: "-6px",
                                              }}
                                            >
                                              <RadioFormik
                                                options={taxOptions}
                                                name="config.taxPreference"
                                              />
                                            </div>
                                          )}
                                      </div>
                                    );
                                  else
                                    return (
                                      <div key={mainHeading}>
                                        <CheckboxFormiks
                                          name={`config.${configItems[0]?.configId}`}
                                          labelName={mainHeading}
                                          disabled={
                                            configItems[0]?.isEditable
                                              ? false
                                              : true
                                          }
                                        />
                                        {configItems[0]?.config
                                          ?.configDescriptions && (
                                          <p
                                            style={{
                                              fontSize: "11px",
                                              marginLeft: "45px",
                                              marginTop: "-24px",
                                            }}
                                          >
                                            {
                                              configItems[0]?.config
                                                ?.configDescriptions
                                            }
                                          </p>
                                        )}
                                      </div>
                                    );
                                }
                              )}
                          </div>
                        </div> */}
                      </div>
                      <div
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#FFF4E5",
                          margin: "12px",
                          padding: "12px",
                        }}
                      >
                        Note: Once you associate this component with an
                        employee, you will only be able to edit the Name and
                        Amount/Percentage. The changes you make to
                        Amount/Percentage will apply only to new employees.
                      </div>
                    </div>
                  ) : null}
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    width: "100%",
                    bottom: "12px",
                    borderTop: "1px solid var(--secondary-text)",
                    // margin: "0 12px",
                    paddingRight: 20
                  }}
                >
                  <button
                    className="button secondaryButton"
                    type="button"
                    style={{ border: "1px solid rgba(18, 150, 176, 1)" }}
                    onClick={returnPage}
                  >
                    Cancel
                  </button>
                  <button className="button primaryButton" type="submit">
                    Update
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default CreateEarning;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ParticularDeclarationComp } from "../DeclarationComp/ParticularDeclarationComp";
import { ParticularDetailsComp } from "../DeclarationComp/ParticularDetailsComp";
import Nodata from "../../../Assets/Nodata.svg";
import CrossIcon from '../../../Assets/CrossIconRed.svg'
import InvestmentIcon from "../../../Assets/InvestmentIcon.svg";
import { TaxRegimeComp } from "../DeclarationComp/TaxRegimeComp";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { Loader } from "../../../Components/FormComponent/Loader";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { format, set } from "date-fns";
import { Tooltip } from "@mui/material";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import UploadDetail from "../../../Components/UploadDetail/UploadDetail";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import e from "cors";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { ParticularQuestionComp } from "../DeclarationComp/ParticularQuestionComp";
import { RemoveRepeatedSectionComp } from "../DeclarationComp/RemoveRepeatedSectionComp";
import { AddRepeatedSectionComp } from "../DeclarationComp/AddRepeatedSectionComp";

const TaxDeclaration = () => {
  const [declareActive, setDeclareActive] = useState(0);
  const [text, setText] = useState("");
  const [active, setActive] = useState(1);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const [id, setId] = useState(1);
  const [data, setData] = useState(false);
  const [tbodyData, setTbodyData] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [taxValue, setTaxValue] = useState(null);
  const [empValues, setEmpValues] = useState(null);
  const [editValues, setEditValues] = useState(null);
  const [editId, setEditId] = useState(null);
  const [detailsValues, setDetailsValues] = useState({});
  const [eaId, setEaId] = useState(null);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [val, setVal] = useState(null);
  const [oldTaxStruc, setOldTaxStruc] = useState(null);
  const [newTaxStruc, setNewTaxStruc] = useState(null);
  const reqData = {
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 20,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  };
  // const getEditDetails = async (id) => {
  //   try {
  //     setLoading(true);
  //     const result = await getRequest(`taxdeclaration/EditForm/?ID=${id}`);
  //     setDetailsValues(result?.data?.data?.taxDeclarartion);
  //     setEditValues(result?.data?.data?.taxDeclarartion);
  //   } catch {
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const CancelBack = () => {
    if (id === 2 || id === 3) {
      setId(1);
    } else {
      setDeclareActive(0);
      setId(1);
      getListData();
    }
  };
  const taxRegimeData = [
    {
      id: 1,
      heading: "Old Tax Regime",
      body: "Total Tax: ₹0.00",
    },
    {
      id: 2,
      heading: "New Tax Regime",
      body: "Total Tax: ₹0.00",
    },
  ];

  const clickHandler = (item) => {
    setActive(item);
  };
  useEffect(() => {
    if (location.pathname.includes("declaration")) getListData();
    document.title = `PeopleSol -  Tax Declaration`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getListData = async () => {
    try {
      setLoading(true);
      const result = await postRequest("TaxDeclaration/MyDeclarationList", reqData);

      if (result.data &&
        LoggedInUser?.permissions?.includes("Is Eligible For Income Tax Declaration?")
      ) {
        let tempData = [];
        let ogData = [];
        result?.data?.data?.map((item) => {
          return (
            <>
              {tempData.push({
                ...item.item,
                // actions: getActions(item?.actions),
                // actions: item.actions,
                status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              })}
              {ogData.push({
                ...item,
                // ...item.actions?.map(x => x),
              })}
            </>
          );
        });
        setOriginalData(ogData);
        const clippedArray = tempData?.map(
          ({ attachment, createdOn, lastUpdatedOn, ...rest }) => ({ ...rest })
        );
        const finalArray = clippedArray.map(({ id, ...rest }) => ({
          id,
          ...rest,
        }));
        setTbodyData(finalArray);
      } else {
        setData("You Don't have required permissions to view the content")
        setTbodyData(false);
      }
    } catch (error) {
      setTbodyData(false);
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();




  const [formFields, setFormFields] = useState([]);
  const [initialvalue, setInitialValues] = useState({});
  const [subDetails, setSubDetails] = useState({});
  const [editObj, setEditObj] = useState({});
  useEffect(() => {
    if (type == "Submit Tax Declaration") {
      let a = quesOptions;
      a[0].isActive = false;
      a[1].isActive = false;
      setQOp([...a])
      if (active == 1) {
        if (oldTaxStruc?.taxStructureComponent) {
          setInitialValues({ data: oldTaxStruc?.taxStructureComponent })
          setFormFields(oldTaxStruc?.taxStructureComponent);
        }
      } else {
        if (newTaxStruc?.taxStructureComponent) {
          setInitialValues({ data: newTaxStruc?.taxStructureComponent })
          setFormFields(newTaxStruc.taxStructureComponent);
        }
      }
    } else if (type == "Update Tax Declaration") {
      if (active == 1) {
        if (subDetails?.summary?.taxRegimeType == "Old Regime") {
          let list = [];
          let newData = [...editObj?.data];
          if (oldTaxStruc?.taxStructureComponent) {
            list = [...oldTaxStruc?.taxStructureComponent];
            for (let i = 0; i < newData.length; i++) {
              for (let j = 0; j < list.length; j++) {
                if (newData[i].componentName === list[j].componentName) {
                  if (newData[i]?.subComponents) {
                    let subList = [...list[j]?.subComponents];
                    for (let m = 0; m < newData[i]?.subComponents.length; m++) {
                      subList = subList.filter(subVal => subVal.subComponentName != newData[i].subComponents[m].subComponentName);
                    }
                    newData[i].subComponents = [...newData[i]?.subComponents, ...subList];
                  }
                }
              }
            }
            for (let i = 0; i < newData.length; i++) {
              list = list.filter(val => val.componentName != newData[i].componentName);
            }
          }
          setInitialValues({ ...editObj, data: [...newData, ...list] })
          setFormFields([...newData, ...list]);
        } else if (oldTaxStruc?.taxStructureComponent) {
          setInitialValues({ data: oldTaxStruc?.taxStructureComponent })
          setFormFields(oldTaxStruc?.taxStructureComponent);
        }
      } else {
        if (subDetails?.summary?.taxRegimeType == "New Regime") {
          let list = [];
          let newData = [...editObj?.data];
          if (newTaxStruc?.taxStructureComponent) {
            list = [...newTaxStruc?.taxStructureComponent];
            for (let i = 0; i < newData.length; i++) {
              for (let j = 0; j < list.length; j++) {
                if (newData[i].componentName === list[j].componentName) {
                  if (newData[i]?.subComponents) {
                    let subList = [...list[j].subComponents];
                    for (let m = 0; m < newData[i]?.subComponents.length; m++) {
                      subList = subList.filter(subVal => subVal.subComponentName != newData[i].subComponents[m].subComponentName);
                    }
                    newData[i].subComponents = [...newData[i]?.subComponents, ...subList];
                  }
                }
              }
            }
            for (let i = 0; i < newData.length; i++) {
              list = list.filter(val => val.componentName != newData[i].componentName);
            }
          }
          setInitialValues({ ...editObj, data: [...newData, ...list] })
          setFormFields([...newData, ...list]);
        } else if (newTaxStruc?.taxStructureComponent) {
          setInitialValues({ data: newTaxStruc?.taxStructureComponent })
          setFormFields(newTaxStruc.taxStructureComponent);
        }
      }
    }
  }, [active, newTaxStruc, oldTaxStruc, type, subDetails, editObj])

  const getTaxSubDetails = async (year, id) => {
    if (!year) return
    const result = await getRequest("TaxDeclaration/DeclarationFormDetails?financialYear=" + year)
    if (result.data) {
      let data = result.data.summary.components;
      let list = [];
      let other = {};
      let qop = [...quesOptions];
      for (let i = 0; i < data.length; i++) {
        let l2 = [];
        let obj = {};

        if (data[i].componentName == "Are you repaying home loan ?" && data[i].subComponents.length == 1) {
          other.HouseLoanInterest = parseInt(data[i].subComponents[0].declarationInvestmentValue.replace(/[^\x20-\x7E]/g, '').replace(/[^\d]/g, "").trim());
          qop[1].isActive = true;
        } else if (data[i].componentName == "Are you staying in a rented house?" && data[i].subComponents.length == 5) {
          other.selectedStateLimit = data[i].subComponents.filter((item) => item.subComponent == "State")[0].declarationInvestmentValue
          other.houseRentPaid = parseInt(data[i].subComponents.filter((item) => item.subComponent == "House Rent Paid Per Month")[0].declarationInvestmentValue.replace(/[^\x20-\x7E]/g, '').replace(/[^\d]/g, "").trim())
          other.LandlordPan = data[i].subComponents.filter((item) => item.subComponent == "Landlord PAN")[0].declarationInvestmentValue
          other.LandLordAddressOfProperty = data[i].subComponents.filter((item) => item.subComponent == "Rented House Address")[0].declarationInvestmentValue
          other.tenantName = data[i].subComponents.filter((item) => item.subComponent == "Landlord Name")[0].declarationInvestmentValue
          qop[0].isActive = true;
        } else {
          for (let j = 0; j < data[i].subComponents.length; j++) {
            l2.push({
              component: data[i].subComponents[j].component,
              componentId: data[i].subComponents[j].componentId,
              id: data[i].subComponents[j].id,
              declareAmount: parseInt(data[i].subComponents[j].declarationInvestmentValue),
              subComponentName: data[i].subComponents[j].subComponent,
            })
          }
          obj.subComponents = l2;
          obj.limitAmount = data[i].limitAmount;
          obj.componentName = data[i].componentName;
          obj.id = data[i].id;
          list.push(obj);
        }
      }
      if (!other.LandlordPan) qop[0].isActive = false;
      if (!other.HouseLoanInterest) qop[1].isActive = false
      setQOp([...qop])
      if (type != "Update Tax Declaration" && type != "Submit Tax Declaration") {
        setFormFields(list);
        setInitialValues({ ...other, data: list })
      }
      setSubDetails({ ...result.data })
      setEditObj({ ...other, data: list })
      if (result.data.summary.taxRegimeType == "Old Regime")
        setActive(1);
      else setActive(2);
    }
  }

  const createPageData = async (values) => {
    try {
      const result = await postRequest("TaxDeclaration/CreateDeclarationForm", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setDeclareActive(0);
      setId(1);
      getListData();
    }
  };

  const editPageData = async (values) => {
    try {
      const result = await postRequest(
        "TaxDeclaration/EditDeclarationForm",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setDeclareActive(0);
      setId(1);
      getListData();
    }
  };
  const getPageData = async (id) => {
    try {
      const result = await getRequest(
        `taxdeclaration/CreateForm/?EmployeeID=${LoggedInUser?.id}&SettingsID=${id}`
      );
      setEmpValues({ ...result?.data?.data, settingId: id });
    } catch {
    } finally {
    }
  };
  const getTaxDecDetails = async (id) => {
    const result = await getRequest("IncomeTaxDeclarationSettings/TaxDetails?Id=" + id);
    if (result.data) {

      let a = result.data.summary.newTaxStructure;
      for (let i = 0; i < a.taxStructureComponent.length; i++) {
        for (let j = 0; j < a.taxStructureComponent[i].subComponents.length; j++) {
          a.taxStructureComponent[i].subComponents[j].declareAmount = a.taxStructureComponent[i].subComponents[j].subLimitAmount;
        }
      }
      let b = result.data.summary.oldTaxStructure;
      for (let i = 0; i < b.taxStructureComponent.length; i++) {
        for (let j = 0; j < b.taxStructureComponent[i].subComponents.length; j++) {
          b.taxStructureComponent[i].subComponents[j].declareAmount = b.taxStructureComponent[i].subComponents[j].subLimitAmount;
        }
      }

      setNewTaxStruc({ ...a })
      setOldTaxStruc({ ...b })
    }
  }

  const [quesOptions, setQOp] = useState([
    {
      id: 1,
      text: "Are you staying in a rented house?",
      isActive: !false,
    },
    {
      id: 2,
      text: "Are you repaying home loan ?",
      isActive: !false
    },
    // {
    //   id: 3,
    //   text: "Is the employee receiving rental income?",
    // },
  ]);



  const getSum = (index) => {
    let sum = 0;
    if (updatedValues && updatedValues.data) {
      let data = updatedValues.data[index];
      if (data && data?.subComponents && data?.subComponents.length) {
        for (let i = 0; i < data.subComponents.length; i++) {
          if (data.subComponents[i].declareAmount) {
            sum += data.subComponents[i].declareAmount;
          }
        }
      }
    }
    return sum;

  }
  const getCompInd = (comp, sub) => {
    for (let i = 0; i < comp.subComponents.length; i++) {
      if (comp.subComponents[i].subComponentName == sub.subComponentName) return i;
    }
  }

  const onSubmit = async (values) => {
    let b = [];
    if (active == 1 && quesOptions[0].isActive) {
      if (!values.houseRentPaid && values.houseRentPaid != 0) {
        dispatch(
          showToast({
            text: "Provide House Rent Paid Per Month",
            severity: "error",
          }))
        return
      }
      if (!values.selectedStateLimit) {
        dispatch(
          showToast({
            text: "Select State",
            severity: "error",
          }))
        return
      }
      if (!values.LandlordPan) {
        dispatch(
          showToast({
            text: "Provide Landlord PAN",
            severity: "error",
          }))
        return
      }
      if (!values.LandLordAddressOfProperty) {
        dispatch(
          showToast({
            text: "Provide Rented House Address",
            severity: "error",
          }))
        return
      }
      if (!values.tenantName) {
        dispatch(
          showToast({
            text: "Provide Landlord Name",
            severity: "error",
          }))
        return
      }
      b.push({
        componentName: "Are you staying in a rented house?",
        subComponents: [
          {
            subComponent: "State",
            isDisplay: false,
            declarationInvestmentValue: values.selectedStateLimit,
          },
          {
            subComponent: "House Rent Paid Per Month",
            isDisplay: true,
            declarationInvestmentValue: JSON.stringify(values.houseRentPaid),
          },
          {
            subComponent: "Landlord PAN",
            isDisplay: false,
            declarationInvestmentValue: values.LandlordPan
          },
          {
            subComponent: "Rented House Address",
            isDisplay: false,
            declarationInvestmentValue: values.LandLordAddressOfProperty
          },
          {
            subComponent: "Landlord Name",
            isDisplay: false,
            declarationInvestmentValue: values.tenantName
          }
        ]
      }
      )
    }
    if (active == 1 && quesOptions[1].isActive) {
      if (values.HouseLoanInterest || values.HouseLoanInterest == 0) {
        b.push(
          {
            componentName: "Are you repaying home loan ?",
            subComponents: [
              {
                subComponent: "Interest paid on Home Loan",
                isDisplay: true,
                declarationInvestmentValue: JSON.stringify(values.HouseLoanInterest),
              },
            ]
          }
        )
      } else {
        dispatch(
          showToast({
            text: "Provide Interest paid on Home Loan Amount",
            severity: "error",
          }))
        return
      }
    }

    for (let i = 0; i < values.data.length; i++) {
      let d = []
      let sum = 0;
      for (let j = 0; j < values.data[i].subComponents.length; j++) {
        if (!values.data[i].subComponents[j].subComponentName || values.data[i].subComponents[j].subComponentName == "") {
          dispatch(
            showToast({
              text: "Provide investment name to all fields, check " + values.data[i].componentName,
              severity: "error",
            }))
          return
        }
        if (values.data[i].subComponents[j].declareAmount)
          sum += values.data[i].subComponents[j].declareAmount;
        else {
          dispatch(
            showToast({
              text: "Provide investment value to all fields greater than 0, check " + values.data[i].componentName,
              severity: "error",
            }))
          return
        }
        let subObj = { subComponent: values.data[i].subComponents[j].subComponentName, isDisplay: true, declarationInvestmentValue: JSON.stringify(values.data[i].subComponents[j].declareAmount) };
        if (type != "Submit Tax Declaration") {
          if (values.data[i].subComponents[j].id) subObj.id = values.data[i].subComponents[j].id;
          if (values.data[i].subComponents[j].componentId) subObj.componentId = values.data[i].subComponents[j].componentId
        }
        d.push(subObj)
      }

      if (sum > values.data[i].limitAmount) {
        dispatch(
          showToast({
            text: "Total amount of investments cannot be greater than Max limit, check " + values.data[i].componentName,
            severity: "error",
          }))
        return
      }
      let c = { componentName: values.data[i].componentName, subComponents: d, limitAmount: values.data[i].limitAmount };
      if (values.data[i].id && type != "Submit Tax Declaration") c.id = values.data[i].id
      b.push(c)
    }

    let sum = 0;
    for (let i = 0; i < b.length; i++) {
      for (let j = 0; j < b[i].subComponents.length; j++) {
        if (b[i].subComponents[j].isDisplay)
          sum += parseInt(b[i].subComponents[j].declarationInvestmentValue.replace(/[^\x20-\x7E]/g, '').replace(/[^\d]/g, "").trim());
      }
    }
    let dataToSend = {
      "financialYear": empValues.name,
      "totalDeclaredAmount": sum,
      "taxDeclarationSettingId": empValues?.settingId,
      "panNumber": empValues?.employee?.panNumber ? empValues?.employee?.panNumber : "0",
      "components": [...b],
      "taxRegimeType": active == 1 ? "Old Regime" : "New Regime",
    }
    type != "Submit Tax Declaration" ? editPageData({ ...dataToSend, id: editId }) : createPageData(dataToSend);
    setId(3);
    setText("Back To Tax Declaration List");
  };

  if (loading) return <Loader />
  else if (data) return <div className="noDatastyle">
    <img
      src={Nodata}
      alt="No data"
      style={{ height: "150px", width: "250px" }}
    />
    <h4>{data ? data : "No Records"}</h4>
  </div>
  else
    return (
      <div className="homeEngageMainContainer" style={{ width: "100%" }}>

        <div className="moduleMainContainer" style={{ width: "100%", overflow: "auto" }}>
          <div
            className="fullTableContainer"
            style={{ width: "100%", height: "100%" }}
          >
            {declareActive === 0 ? (
              <div
                className="boxViewContainer "
                style={{ width: "100%", height: "100%" }}
              >
                <div className="homeCardsHolderContainer">
                  <div className="homeDashboardCardHolder" key={1}>
                    {originalData &&
                      originalData?.map((item, index) => {
                        return (
                          <div className="dashboardCard internalJobCard">
                            <div className="cardContainer cursor">
                              <div className="headingContainer">
                                <h3
                                  style={{
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.item?.title}
                                </h3>
                                {/* <span className="internalJobDateTime" style={{color:'var(--secondary)'}}>
                          Pending
                        </span> */}
                              </div>
                              <div
                                className="jobDetailContainerHolder"
                              // style={{ padding: "7px 0px 23px 11px" }}
                              >
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">
                                    Start Date
                                  </label>
                                  <label>
                                    {" "}
                                    {item?.item?.startDate &&
                                      format(
                                        new Date(item?.item?.startDate),
                                        "dd/MM/yyyy "
                                      )}
                                  </label>
                                </div>
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">End Date</label>
                                  <label>
                                    {" "}
                                    {item?.item?.endDate &&
                                      format(
                                        new Date(item?.item?.endDate),
                                        "dd/MM/yyyy "
                                      )}
                                  </label>
                                </div>
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">Status</label>
                                  <label>
                                    {" "}
                                    <div
                                      style={{
                                        backgroundColor: getStatusColor(
                                          item?.item?.status
                                        )?.split(",")?.[0],
                                        color: getStatusColor(
                                          item?.item?.status
                                        )?.split(",")?.[1],
                                        cursor: "default",
                                        padding: "3px 6px",
                                        width: "fit-content",
                                        borderRadius: "6px",
                                      }}
                                    >
                                      {item?.item?.status}
                                    </div>
                                  </label>
                                </div>
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">Document</label>
                                  {item?.item?.attachment ? (
                                    <label>
                                      <UploadDetail
                                        filepath={`tax/${item?.item?.attachment}`}
                                      />
                                    </label>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </div>
                              <div
                                className="applyJobButtonContainer "
                                style={{ flexWrap: "wrap" }}
                              >
                                {item?.actions?.map((itemData, index) => {
                                  return (
                                    <>
                                      {itemData?.type ===
                                        "Submit Tax Declaration" && (
                                          <Tooltip
                                            arrow
                                            title={"Submit Tax Declaration"}
                                            placement="top"
                                          >
                                            <button
                                              // style={{ background: itemData?.color }}
                                              onClick={async () => {
                                                setText(item?.item?.title);
                                                setDeclareActive(1);
                                                await getPageData(item?.item?.id);
                                                getTaxDecDetails(item?.item?.id);
                                                setType("Submit Tax Declaration");
                                                setEditValues(null);
                                              }}
                                              className="button primaryButton"
                                            >
                                              {itemData?.type}
                                            </button>
                                          </Tooltip>
                                        )}
                                      {itemData?.type ===
                                        "Update Tax Declaration" && (
                                          <Tooltip
                                            arrow
                                            title={"Update Tax Declaration"}
                                            placement="top"
                                          >
                                            <button
                                              // style={{ background: itemData?.color }}
                                              onClick={async () => {
                                                // await getEditDetails(itemData?.id);
                                                setInitialValues({});
                                                setFormFields([])
                                                await getPageData(item?.item?.id);
                                                getTaxSubDetails(item.item.title, item?.item?.id)
                                                getTaxDecDetails(item?.item?.id);
                                                setDeclareActive(1);
                                                setEditId(itemData?.id);
                                                setEaId(itemData?.id);
                                                setType("Update Tax Declaration");
                                                setText(item?.item?.title);
                                              }}
                                              className="button primaryButton"
                                            >
                                              {itemData?.type}
                                            </button>
                                          </Tooltip>
                                        )}
                                      {itemData?.type === "Details" && (
                                        <Tooltip
                                          arrow
                                          title={"Details"}
                                          placement="top"
                                        >
                                          <button
                                            // style={{ background: itemData?.color }}
                                            onClick={async () => {
                                              // await getEditDetails(itemData.id);
                                              setInitialValues({});
                                              setFormFields([])
                                              await getPageData(item?.item?.id);
                                              setType("Tax Declaration Details");
                                              getTaxSubDetails(item.item.title, item?.item?.id)
                                              setDeclareActive(1);
                                              setId(3);
                                              setEaId(itemData.id);
                                              setText(item?.item?.title);
                                            }}
                                            className="button primaryButton"
                                          >
                                            {itemData?.type}
                                          </button>
                                        </Tooltip>
                                      )}
                                    </>
                                  );
                                })}
                                {(item?.actions.length === 0 && new Date(item?.item?.startDate) < new Date()) && (
                                  <Tooltip
                                    arrow
                                    title={"No Actions"}
                                    placement="top"
                                  >
                                    <div className="noActionsTextContainer">
                                      <label>Expired </label>
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {(!originalData || originalData.length == 0) ? <div className="noDatastyle" style={{ marginTop: 200 }}>
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{data ? data : "No Records"}</h4>
                    </div> : null}
                  </div>
                </div>
              </div>
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialvalue}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  setUpdatedValues(formik.values)
                  return (
                    <Form>
                      <div className="feedbackFormFooter">
                        {loading ? (
                          <Loader />
                        ) : (
                          <div className="submitDeclareContainer" style={{ width: "100%", }}>
                            <div
                              style={{ width: "100%", margin: 0, padding: 12, boxShadow: "0px 3px 15px rgb(0 0 0 / 6%)" }}
                              className="returnToDashboard cursor"
                              onClick={() => {
                                setDeclareActive(0);
                                setId(1);
                                getListData();
                              }}
                            >
                              <svg
                                width="20"
                                height="8"
                                viewBox="0 0 20 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.243615 3.74484C0.0483524 3.9401 0.0483524 4.25668 0.243615 4.45194L3.4256 7.63392C3.62086 7.82918 3.93744 7.82918 4.1327 7.63392C4.32796 7.43866 4.32796 7.12208 4.1327 6.92682L1.30427 4.09839L4.1327 1.26996C4.32796 1.0747 4.32796 0.758117 4.1327 0.562855C3.93744 0.367593 3.62086 0.367593 3.4256 0.562855L0.243615 3.74484ZM18.624 4.59839C18.9002 4.59839 19.124 4.37453 19.124 4.09839C19.124 3.82225 18.9002 3.59839 18.624 3.59839V4.59839ZM0.597168 4.59839H18.624V3.59839H0.597168V4.59839Z"
                                  fill="#1296B0"
                                />
                              </svg>

                              <label className="returnLabelHolder">{text}</label>
                            </div>
                            <div style={{ width: "80%" }}>
                              <div className="taxRegimeContainer">
                                {
                                  <>
                                    {type !== "Submit Tax Declaration" && type !== "Update Tax Declaration"
                                      ? <div
                                        className="computeTaxContainer"
                                        style={{ background: "#FFF4E5" }}
                                      >
                                        {/* <IconButton
                                    onClick={() => {
                                      togglePoppup();
                                    }}
                                  >
                                    <img
                                      src={OrangeInfoIcon}
                                      alt={OrangeInfoIcon}
                                    />
                                  </IconButton> */}
                                        <label
                                          style={{
                                            fontWeight: "500",
                                            paddingLeft: "7px",
                                          }}
                                        >
                                          {"Tax Regime : " + (active === 1 ? "Old" : "New")}

                                        </label>
                                      </div>
                                      :
                                      <>
                                        {taxRegimeData?.map((item, index) => {
                                          return (
                                            <>
                                              <TaxRegimeComp
                                                disabled={
                                                  id == 2 || id == 3 ? "off" : "on"
                                                }
                                                heading={item?.heading}
                                                body={item?.body}
                                                clickHandler={clickHandler}
                                                id={item.id}
                                                active={item.id === active}
                                              />
                                              {item.id === active && setTaxValue(item.id)}
                                            </>
                                          );
                                        })}
                                      </>
                                    }

                                  </>
                                }
                              </div>

                              <div>
                                {<div className="particularDetailsHolder" style={{ width: "100%" }}>
                                  {active == 1 && quesOptions?.map((item) => {
                                    return (
                                      <ParticularQuestionComp
                                        key={item.id}
                                        updatedValues={updatedValues}
                                        editValues={editValues}
                                        switchActive={item.isActive}
                                        setSwitchActive={(val) => {
                                          console.log("------------ switch")
                                          let a = quesOptions
                                          for (let i = 0; i < a.length; i++) {
                                            if (a[i] == item) {
                                              a[i].isActive = !a[i].isActive;
                                            }
                                          }
                                          setQOp([...a])
                                        }}
                                        // values={values}
                                        quesText={item.text}
                                        id={item.id}
                                      />
                                    );
                                  })}
                                </div>}
                                {/* <ParticularDeclarationComp
                                  values={val}
                                  errors={formik.errors}
                                  formFields={formFields}
                                  editValues={editValues}
                                /> */}
                                {initialvalue?.data?.map((item, index) => (

                                  <div key={index} className="investmentAllContainer">
                                    <div className="investmentHeading" style={{ justifyContent: "space-between" }}>
                                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <img
                                          style={{ height: "26px" }}
                                          src={InvestmentIcon}
                                          alt={InvestmentIcon}
                                        />
                                        <label style={{ paddingLeft: "10px", fontWeight: "500" }}>
                                          {item.componentName ?? ""}
                                        </label>
                                      </div>
                                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ paddingLeft: "10px", fontWeight: "500", marginRight: 20 }}>
                                          Max Limit : {item.limitAmount}
                                        </div>
                                        {id != 3 && <img onClick={() => {
                                          if (initialvalue?.data.length == 1) {
                                            dispatch(showToast({
                                              text: "Atleast One Item is Required ",
                                              severity: "error",
                                            }))
                                          } else {
                                            let a = initialvalue?.data.filter((rem) => rem != item);
                                            setFormFields(a)
                                            setInitialValues({ ...updatedValues, data: a })
                                          }
                                        }} src={CrossIcon} />}
                                      </div>
                                    </div>
                                    <label style={{ padding: "0 20px", marginTop: "15px" }}>
                                      {" "}
                                      {/* <b>{noteText ? "Note: " : ""}</b>
                                        {noteText ?? ""} */}
                                    </label>
                                    <div style={{ width: "calc(100% - 20px)" }}>
                                      {/* <FieldArray name={"Sameer"}>
                                          {(fieldArrayProps) => {
                                            const { insert, remove, form } = fieldArrayProps;
                                            const { values } = form;
                                            return ( */}
                                      {item?.subComponents?.length > 0 ? item.subComponents.map((sub, index2) => (
                                        <div key={index2}>
                                          {/* {item.body.map((OtherInvestmentLists, index) => { */}
                                          {/* //console.log("ds",errors?.[compName]) */}
                                          {/* return ( */}
                                          <div style={{ display: "flex", paddingTop: "10px" }}>
                                            <div
                                              className="extendedInputContainer"
                                              style={{ width: "100%" }}
                                            >
                                              <InputCustomFormik
                                                formikWidth={"100%"}
                                                disabled={id == 3 ? true : false}
                                                name={`data[${index}].subComponents[${getCompInd(item, sub)}].subComponentName`}
                                                style={{
                                                  border: "1px solid #E6E6E6 !important",
                                                  width: "90%",
                                                }}
                                                // disabled={true}
                                                placeholder={"Enter investment name"}
                                              />
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  width: "50%",
                                                }}
                                              >
                                                <InputCustomFormik
                                                  disabled={id == 3 ? true : false}
                                                  type={"number"}
                                                  style={{ border: "1px solid #E6E6E6" }}
                                                  placeholder="Investment Value"
                                                  name={`data[${index}].subComponents[${getCompInd(item, sub)}].declareAmount`}
                                                />
                                              </div>
                                            </div>

                                            {id != 3 && <div style={{ display: "flex", paddingLeft: "12px", }}
                                            >
                                              <div style={{ display: "flex", alignItems: "center" }}>
                                                <RemoveRepeatedSectionComp
                                                  clickHandler={() => {
                                                    let a = initialvalue.data;
                                                    for (let i = 0; i < a.length; i++) {
                                                      if (a[i] == item) {
                                                        if (a[i].subComponents.length == 1) {
                                                          dispatch(
                                                            showToast({
                                                              text: "Atleast One Item is Required ",
                                                              severity: "error",
                                                            })
                                                          )
                                                        } else {
                                                          let k = [];
                                                          for (let j = 0; j < a[i].subComponents.length; j++) {
                                                            if (a[i].subComponents[j] != sub) {
                                                              k.push(a[i].subComponents[j]);
                                                            } else {
                                                            }
                                                          }
                                                          a[i].subComponents = [...k];
                                                        }
                                                      }
                                                    }
                                                    setFormFields([...a])
                                                    setInitialValues({ ...updatedValues, data: [...a] })
                                                  }
                                                  }
                                                />
                                              </div>
                                              <div style={{ display: "flex", alignItems: "center" }}>
                                                <AddRepeatedSectionComp
                                                  clickHandler={() => {
                                                    let a = initialvalue.data;
                                                    for (let i = 0; i < a.length; i++) {
                                                      if (a[i] == item) {
                                                        a[i].subComponents.push({
                                                          componentId: a[i].id,
                                                          declareAmount: 0,
                                                          subComponentName: "",
                                                        })

                                                      }
                                                    }
                                                    setFormFields(a)
                                                    setInitialValues({ ...updatedValues, data: a })
                                                  }}
                                                />
                                              </div>
                                            </div>}
                                          </div>
                                          {/* ); */}
                                          {/* })} */}

                                        </div>
                                      )) : null}
                                      <div className="totalError">
                                        {getSum(index) > item.limitAmount && <div className="form-error" style={{ fontSize: 14 }}>{"Total amount cannot be greater than the limit amount"}</div>}
                                        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", paddingRight: 20 }}>
                                          {" "}
                                          <LabelCustom labelName={"Total Amount"} />
                                          <LabelCustom labelName={":"} />
                                          <LabelCustom labelName={getSum(index)} />
                                          {/* <LabelCustom
                                              labelName={
                                                <span
                                                  style={{
                                                    color: values[compName]
                                                      ?.reduce((sum, card) => sum + Number(card.Value), 0)
                                                      >= 150000
                                                      ? "red"
                                                      : "var(--primary)",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {values[compName]
                                                    ?.reduce((sum, card) => sum + Number(card.Value), 0)
                                                    .toFixed(2)}
                                                </span>
                                              }
                                            /> */}
                                        </div>
                                      </div>

                                      {/* );
                                          }}
                                        </FieldArray> */}
                                    </div>
                                  </div>
                                ))}
                              </div>

                              {/* {(id === 2 || id === 3) && formik.values && (
                                <ParticularDetailsComp
                                  formFields={formFields}
                                  formValues={formik.values}
                                  detailsValues={null}
                                  id={id}
                                />
                              )} */}
                            </div>

                            <div
                              className={`createFootCust ${id === 3 && "invisibleClass"
                                }`}
                              style={{ backgroundColor: "white", paddingRight: "20%" }}
                            >
                              <div>
                                <div
                                  // onClick={returnPage}
                                  className="button secondaryButton"
                                  style={{
                                    border: "1px solid rgba(18, 150, 176, 1)",

                                    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
                                  }}
                                  onClick={() => {
                                    setId(1);
                                    CancelBack();
                                  }}
                                >
                                  {id === 2 || id === 3 ? "Back" : "Cancel"}
                                </div>
                              </div>
                              <div>
                                {/* {id === 1 ? (
                                  <div
                                    className="button primaryButton"
                                    // type="button"
                                    style={{
                                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
                                    }}
                                    onClick={() => {
                                      Object.keys(formik?.errors).length === 0 &&
                                        setId(2);
                                      setVal(formik.values);
                                    }}
                                  >
                                    View Investment Declaration
                                  </div> */}
                                {/* ) : ( */}
                                <button
                                  className="button primaryButton"
                                  type="submit"
                                  style={{
                                    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
                                  }}
                                >
                                  {type === "Submit Tax Declaration"
                                    ? "Submit"
                                    : "Update"}{" "}
                                  Investment Declaration
                                </button>

                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </div>
      </div>
    );
};

export { TaxDeclaration };

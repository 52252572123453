import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";

export const CreatePayAreaComp = ({ data, detailsform }) => {
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  console.log("deta", detailsform);
  // const getEmployeeData = async () => {
  //   try {
  //     let result;
  //     if (data)
  //       result = await getRequest(
  //         `PayrollEmployee/ToBeMapPayrollEmployeeList?PayAreaId=${data}`
  //       );
  //     else
  //       result = await getRequest(`PayrollEmployee/ToBeMapPayrollEmployeeList`);
  //     let listData = [];
  //     result.data.results?.map((item) => {
  //       return listData.push({ label: item.text, value: item.id });
  //     });
  //     setEmployeeList(listData);
  //   } catch (error) {
  //     dispatch(
  //       showToast({
  //         text: "some error occured in fetching data",
  //         severity: "error",
  //       })
  //     );
  //   } finally {
  //   }
  // };
  useEffect(() => {
    document.title = `PeopleSol - Create Pay Area `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    // getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik borderBlack={true} name="Name" placeholder={"Enter name of pay area"} options={employeeList} />
      </div>
      <div>
        <LabelCustom labelName={"Employees"} mandatory={true} />
        <DropdownInfiniteScroll
          multi={true}
          postMethod={false}
          name="EmployeeIds"
          placeholder={"Select employees"}
          details={detailsform}
          attachQuery={detailsform[0]?.payArea?.id ? "PayAreaId=" + detailsform[0]?.payArea.id : null}
          editID={data ? true : false}
          // detailsForEdit={detailsform[0]?.payAreaMappingDetails.map((item) => item.payrollEmployeeId)}
          urlProp={"PayrollEmployee/ToBeMapPayrollEmployeeList"}
        />
        {/* <SelectFormikMulti borderBlack={true} name="EmployeeIds" options={employeeList} /> */}
      </div>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../Assets/Nodata.svg";
import editButton from "../../../../Assets/editButton.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import StarRatings from "react-star-ratings";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { useSelector } from "react-redux";
import { selfRatingObtain } from "../../../../Utilities";
import { truncateString } from "../../../../Utilities/truncate";
const DetailsPerformanceSelfReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [starData, setStarData] = useState(false);
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  console.log("object selectedId", selectedId);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const isEdit = location?.state?.type === "Update Review";
  const isEditID = location?.state?.ID;
  const performanceCycleId = location?.state?.performanceCycleId;
  const employeeId = location?.state?.employeeId;
  const dataAllReview = location?.state?.data;
  const isEditObj = { ...location?.state };
  const isEditObjemployeeId = location?.state?.employeeId;
  console.log("object location", location);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const toggleRow = (index) => {
    const expandedIndex = expandedRows.indexOf(index);
    const newExpandedRows = [...expandedRows];

    if (expandedIndex > -1) {
      newExpandedRows.splice(expandedIndex, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      let result;
      if (employeeId && performanceCycleId) {
        result = await getRequest(
          `PMSPerformanceReview/ViewDetails?ID=${performanceCycleId}&EmployeeId=${isEditObjemployeeId}`
        );
      } else {
        if (data) {
          result = await getRequest(
            `PMSPerformanceReview/ViewDetails?ID=${selectedId}&EmployeeId=${isEditObjemployeeId}`
          );
        } else {
          result = await getRequest(
            `PMSPerformanceReview/ViewDetails?ID=${selectedId}&EmployeeId=${isEditObjemployeeId}`
          );
        }
      }
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  const getStarDetails = async () => {
    try {
      let result;
      if (employeeId && performanceCycleId) {
        result = await getRequest(
          `PMSPerformanceReview/PerformanceRatingSlab?ID=${performanceCycleId}&EmployeeId=${employeeId}`
        );
      } else {
        result = await getRequest(
          `PMSPerformanceReview/PerformanceRatingSlab?ID=${data?.pmsReviews?.performanceCycleID}&EmployeeId=${data?.pmsReviews?.employeeId}`
        );
      }
      setStarData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    if (
      data?.pmsReviews?.performanceCycleID ||
      (employeeId && performanceCycleId)
    )
      getStarDetails();
  }, [data, LoggedInUser]);
  useEffect(() => {
    document.title = `PeopleSol -  Self Review Performance Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal">
          {loading ? (
            <Loader />
          ) : data ? (
            <div className="selfParent">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
                  alignItems: "center",
                  padding: "4px 0px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={() => navigate(-1)}>
                    <svg
                      width="20"
                      height="8"
                      viewBox="0 0 20 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                        fill="#1296B0"
                      />
                    </svg>
                  </IconButton>
                  <h4> Details Review</h4>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    className="btnSelf"
                    onClick={togglePopup}
                    type="button"
                  >
                    View Rating Slab
                  </button>
                  {isEdit && (
                    <div style={{ height: "31px" }}>
                      <img
                        onClick={() =>
                          navigate(
                            `/pms/performancereview/myreview/create/${isEditID}`,
                            { state: { ...isEditObj } }
                          )
                        }
                        src={editButton}
                        style={{ height: "100%", cursor: "pointer" }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
              {Array?.isArray(
                data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew
              ) &&
              data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew[0]
                ?.employeeAchievement ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "12px",
                    }}
                  >
                    {data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                      (sum, card) => sum + card.employeeAchievement,
                      0
                    ) !== 0 && (
                      <div className="ratingSelf">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div style={{ marignTop: "12px" }}>Self Rating :</div>
                          <div>
                            {" "}
                            <StarRatings
                              rating={selfRatingObtain(
                                data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                                  (sum, card) => sum + card.employeeAchievement,
                                  0
                                ),
                                starData
                              )}
                              starRatedColor="#5BC4BF"
                              starSpacing="2px"
                              starEmptyColor="#FFFFFF"
                              numberOfStars={5}
                              starHoverColor="var(--primary)"
                              starDimension="28px"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div>Self Achievement % :</div>
                          <div>
                            <p
                              style={{
                                color: "#5BC4BF",
                                fontSize: "30px",
                                fontWeight: "500",
                                marginTop: "5px",
                              }}
                            >
                              {data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                                (sum, card) => sum + card.employeeAchievement,
                                0
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                      (sum, card) => sum + card.performanceAchievement,
                      0
                    ) !== 0 && (
                      <div
                        className="ratingSelf"
                        style={{ backgroundColor: "#FFEEF2" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div style={{ marignTop: "12px" }}>
                            Appraiser Rating :
                          </div>
                          <div>
                            {" "}
                            <StarRatings
                              rating={selfRatingObtain(
                                data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                                  (sum, card) =>
                                    sum + card.performanceAchievement,
                                  0
                                ),
                                starData
                              )}
                              starRatedColor="#DE64A1"
                              starSpacing="2px"
                              starEmptyColor="#FFFFFF"
                              numberOfStars={5}
                              starHoverColor="var(--primary)"
                              starDimension="28px"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div>Appraiser Achievement % :</div>
                          <div>
                            <p
                              style={{
                                color: "#DE64A1",
                                fontSize: "30px",
                                fontWeight: "500",
                                marginTop: "5px",
                              }}
                            >
                              {data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                                (sum, card) =>
                                  sum + card.performanceAchievement,
                                0
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                      (sum, card) => sum + card?.reviewerAchievement,
                      0
                    ) !== 0 && (
                      <div
                        className="ratingSelf"
                        style={{ backgroundColor: "#F0F0FF" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div style={{ marignTop: "12px" }}>
                            Reviewer Rating :
                          </div>
                          <div>
                            {" "}
                            <StarRatings
                              rating={selfRatingObtain(
                                data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                                  (sum, card) =>
                                    sum + card?.reviewerAchievement,
                                  0
                                ),
                                starData
                              )}
                              starRatedColor="#8A88ED"
                              starSpacing="2px"
                              starEmptyColor="#FFFFFF"
                              numberOfStars={5}
                              starHoverColor="var(--primary)"
                              starDimension="28px"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div>Reviewer Achievement % :</div>
                          <div>
                            <p
                              style={{
                                color: "#8A88ED",
                                fontSize: "30px",
                                fontWeight: "500",
                                marginTop: "5px",
                              }}
                            >
                              {data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                                (sum, card) => sum + card?.reviewerAchievement,
                                0
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {data?._pmsAppraisalCycles?.goalslist?.length > 0 ? (
                      <div style={{ width: "100%", overflow: "auto" }}>
                        <table
                          className="tabself tabself-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead
                            style={{
                              backgroundColor: "#FBFBFB",
                              top: 0,
                              position: "sticky",
                              zIndex: 2,
                            }}
                          >
                            <tr>
                              <td>
                                <LabelCustom labelName={"S.no"} />
                              </td>
                              <td>
                                <LabelCustom labelName={"Goal Name"} />
                              </td>
                              <td>
                                <LabelCustom labelName={"Description"} />
                              </td>
                              <td>
                                <LabelCustom labelName={"Start Date"} />
                              </td>
                              <td>
                                <LabelCustom labelName={"Expected End Date"} />
                              </td>
                              <td>
                                <LabelCustom labelName={"KRA"} />
                              </td>
                              <td>
                                <LabelCustom labelName={"Weightage %"} />
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {data?._pmsAppraisalCycles?.goalslist?.map(
                              (items, index) => {
                                const isRowExpanded =
                                  expandedRows.includes(index);

                                return (
                                  <React.Fragment key={index}>
                                    <tr
                                      className={`trStyles`}
                                      key={index}
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <td>
                                        <LabelCustom labelName={index + 1} />
                                      </td>
                                      <td>{items.name}</td>
                                      <td>
                                        <LabelCustom
                                          labelName={items.description}
                                        />

                                        <IconButton
                                          onClick={() => toggleRow(index)}
                                          sx={{
                                            display: isRowExpanded && "none",
                                            position: "absolute",
                                            bottom: "-18px",
                                            left: "50%",
                                            zIndex: "1",
                                            boxShadow:
                                              "0px 1.89445px 10px rgba(0, 0, 0, 0.04);",
                                          }}
                                        >
                                          {!isRowExpanded && (
                                            <>
                                              <svg
                                                width="14"
                                                height="9"
                                                viewBox="0 0 14 9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M12.8765 0.898926L6.82826 6.94713L0.780053 0.898925"
                                                  stroke="#1296B0"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                />
                                              </svg>
                                            </>
                                          )}
                                        </IconButton>
                                      </td>
                                      <td>
                                        <LabelCustom
                                          labelName={
                                            items.startDate &&
                                            format(
                                              new Date(items.startDate),
                                              "dd/MM/yyyy"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <LabelCustom
                                          labelName={
                                            items.expectedEndDate &&
                                            format(
                                              new Date(items.expectedEndDate),
                                              "dd/MM/yyyy"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <LabelCustom labelName={items.kra} />
                                      </td>
                                      <td>
                                        <LabelCustom
                                          labelName={items.weightage}
                                        />
                                      </td>
                                    </tr>
                                    {isRowExpanded && (
                                      <tr>
                                        <td
                                          colSpan="7"
                                          style={{
                                            padding: "0",
                                          }}
                                        >
                                          {Array?.isArray(
                                            data?.pmsReviews
                                              ?.pmsPerformanceReviewFeedbacksUpdatesNew
                                          ) &&
                                            data?.pmsReviews
                                              ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                              index
                                            ]?.employeeAchievement && (
                                              <div className="formSelfReview">
                                                <div className="centertd">
                                                  <div>Self</div>
                                                  <div>Review</div>
                                                </div>
                                                <div
                                                  style={{
                                                    flex: "1",
                                                    marginTop: "23px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Notes"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?.pmsReviews
                                                        ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                        index
                                                      ]?.employeeRemarks
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    flex: "1",
                                                    marginTop: "23px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Attachment"}
                                                  </label>
                                                  <div
                                                    style={{
                                                      padding: "10px 13px",
                                                    }}
                                                  >
                                                    <a
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      href={`
                                              ${process.env.REACT_APP_.BASE_URL}/Core/Uploads/PmsPerformanceCycleReview/${data?._pmsAppraisalCycles?.goalslist[index]?.pmsPerformanceReviewFeedbacksUpdatesNew[0]?.employeeAttachment}
                                            `}
                                                    >
                                                      {truncateString(
                                                        data
                                                          ?._pmsAppraisalCycles
                                                          ?.goalslist[index]
                                                          ?.pmsPerformanceReviewFeedbacksUpdatesNew[0]
                                                          ?.employeeAttachment,
                                                        35
                                                      )}
                                                    </a>
                                                  </div>
                                                </div>
                                                <div
                                                  style={{
                                                    flex: "1",
                                                    marginTop: "23px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Achievement %"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?.pmsReviews
                                                        ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                        index
                                                      ]?.employeeAchievement ??
                                                      "NA"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          {Array?.isArray(
                                            data?.pmsReviews
                                              ?.pmsPerformanceReviewFeedbacksUpdatesNew
                                          ) &&
                                            data?.pmsReviews
                                              ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                              index
                                            ]?.performanceAchievement && (
                                              <div
                                                className="formSelfReview"
                                                style={{
                                                  backgroundColor: "#FFEEF2",
                                                }}
                                              >
                                                <div
                                                  className="centertd"
                                                  style={{
                                                    backgroundColor: "#DE64A1",
                                                  }}
                                                >
                                                  <div>Appraiser</div>
                                                  <div>Review</div>
                                                </div>
                                                <div
                                                  style={{
                                                    flex: "1",
                                                    marginTop: "23px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Notes"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?.pmsReviews
                                                        ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                        index
                                                      ]?.performanceRemarks
                                                    }
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    flex: "1",
                                                    marginTop: "23px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Achievement %"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?.pmsReviews
                                                        ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                        index
                                                      ]?.performanceAchievement
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    flex: "1",
                                                    marginTop: "23px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Send to L&D"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?.pmsReviews
                                                        ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                        index
                                                      ]?.performanceSendToLD
                                                        ? "Yes"
                                                        : "No"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          {Array?.isArray(
                                            data?.pmsReviews
                                              ?.pmsPerformanceReviewFeedbacksUpdatesNew
                                          ) &&
                                            data?.pmsReviews
                                              ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                              index
                                            ]?.reviewerAchievement && (
                                              <div
                                                className="formSelfReview"
                                                style={{
                                                  backgroundColor: "#F0F0FF",
                                                }}
                                              >
                                                <div
                                                  className="centertd"
                                                  style={{
                                                    backgroundColor: "#8A88ED",
                                                  }}
                                                >
                                                  <div>Reviewer</div>
                                                  <div>Review</div>
                                                </div>
                                                <div
                                                  style={{
                                                    flex: "1",
                                                    marginTop: "23px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Notes"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?.pmsReviews
                                                        ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                        index
                                                      ]?.reviewerRemarks
                                                    }
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    flex: "1",
                                                    marginTop: "23px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Achievement %"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?.pmsReviews
                                                        ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                        index
                                                      ]?.reviewerAchievement
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    flex: "1",
                                                    marginTop: "23px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Send to L&D"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?.pmsReviews
                                                        ?.pmsPerformanceReviewFeedbacksUpdatesNew[
                                                        index
                                                      ]?.reviewerSendToLD
                                                        ? "Yes"
                                                        : "No"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}

                                          <IconButton
                                            onClick={() => toggleRow(index)}
                                            sx={{
                                              left: "50%",
                                              zIndex: "9999",
                                              boxShadow:
                                                "0px 1.89445px 10px rgba(0, 0, 0, 0.04);",
                                            }}
                                          >
                                            {isRowExpanded && (
                                              <>
                                                <svg
                                                  width="15"
                                                  height="9"
                                                  viewBox="0 0 15 9"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M13.3755 7.62805L7.32728 1.57985L1.27908 7.62805"
                                                    stroke="#1296B0"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                  />
                                                </svg>
                                              </>
                                            )}
                                          </IconButton>
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            // width: "1087px",
                            gap: "17px",
                            flexWrap: "wrap",
                            marginTop: "20px",
                          }}
                        >
                          {data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                            (sum, card) => sum + card.employeeAchievement,
                            0
                          ) !== 0 && (
                            <div
                              className="notesSelf"
                              style={{ width: "348px" }}
                            >
                              <div className="headingSelf">
                                <p>Self Notes</p>
                                <p>Submitted Date</p>
                              </div>
                              <div className="detailSelf">
                                <div style={{ maxWidth: "174px" }}>
                                  <LabelCustom
                                    labelName={data?.pmsReviews?.notes}
                                  />
                                </div>
                                <div>
                                  <LabelCustom
                                    labelName={format(
                                      new Date(data?.pmsReviews?.lastUpdatedOn),
                                      "dd.MM.yy"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                            (sum, card) => sum + card.performanceAchievement,
                            0
                          ) !== 0 && (
                            <div
                              className="notesSelf"
                              style={{
                                backgroundColor: "#FFEEF2",
                                width: "348px",
                              }}
                            >
                              <div
                                className="headingSelf"
                                style={{ background: "#DE64A1" }}
                              >
                                <p>Appraiser Notes</p>
                                <p>Submitted Date</p>
                              </div>
                              <div className="detailSelf">
                                <div style={{ maxWidth: "174px" }}>
                                  <LabelCustom
                                    labelName={
                                      data?.pmsReviews?.performanceReviewNotes
                                    }
                                  />
                                </div>
                                <div>
                                  <LabelCustom
                                    labelName={format(
                                      new Date(data?.pmsReviews?.lastUpdatedOn),
                                      "dd.MM.yy"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {data?.pmsReviews?.pmsPerformanceReviewFeedbacksUpdatesNew?.reduce(
                            (sum, card) => sum + card?.reviewerAchievement,
                            0
                          ) !== 0 && (
                            <div
                              className="notesSelf"
                              style={{
                                backgroundColor: "#F0F0FF",
                                width: "348px",
                              }}
                            >
                              <div
                                className="headingSelf"
                                style={{ backgroundColor: "#8A88ED" }}
                              >
                                <p>Reviewer Notes</p>
                                <p>Submitted Date</p>
                              </div>
                              <div className="detailSelf">
                                <div style={{ maxWidth: "174px" }}>
                                  <LabelCustom
                                    labelName={
                                      data?.pmsReviews?.reviewerReviewNotes
                                    }
                                  />
                                </div>
                                <div>
                                  <LabelCustom
                                    labelName={format(
                                      new Date(data?.pmsReviews?.lastUpdatedOn),
                                      "dd.MM.yy"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>{" "}
                </>
              ) : (
                <div className="noDatastyle">
                  <img
                    src={Nodata}
                    alt="No data"
                    style={{ height: "150px", width: "250px" }}
                  />
                  <h4>{"No Records"}</h4>
                </div>
              )}
            </div>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Records"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={"Rating Slab"}
          content={
            <>
              <InstructionSelf data={starData} />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};
export { DetailsPerformanceSelfReview };

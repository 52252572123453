import React, { useEffect, useState } from 'react'
import { Details } from '../HRMS/Components/DetailsComp/Details';
import { format } from 'date-fns';
import { Notifications } from '../HRMS/Components/DetailsComp/Notifications';
import { Timeline } from '../HRMS/Components/DetailsComp/TimeLine';
import { Loader } from '../../Components/FormComponent/Loader';
import { IconButton, Tab, Tabs } from '@mui/material';
import { getStatusColor } from '../../Utilities/getStatusColor';
import { showToast } from '../../Features';
import { getRequest } from '../../Services/axios';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const RewardsCriteriaSetTargetDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const studentId = location?.state?.studentId;
  const rewardsId = location?.state?.rewardsId;

  console.log("redirect from master 444444444444444444444444", rewardsId,studentId)
  console.log("rewards location", location)
  const closeDetails = () => {
    // if (location.state?.returnUrl) navigate(location.state?.returnUrl);
    // else 
    navigate(-1);
    // let currentUrl = location?.pathname;
    // navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(false);
  const [eventtData, setEventtData] = useState(false);

  

  const getOrdinalSuffix = (number) => {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return (
        <>
          {number}
          <sup>th</sup>
          <span> &nbsp;Position</span>
        </>
      ); // 11th, 12th, 13th for "th"
    }

    switch (lastDigit) {
      case 1:
        return (
          <>
            {number}
            <sup>st</sup>
            <span> &nbsp;Position</span>
          </>
        ); // 1st, 21st, 31st
      case 2:
        return (
          <>
            {number}
            <sup>nd</sup>
            <span> &nbsp;Position</span>
          </>
        ); // 2nd, 22nd, 32nd
      case 3:
        return (
          <>
            {number}
            <sup>rd</sup>
            <span> &nbsp;Position</span>
          </>
        ); // 3rd, 23rd, 33rd
      default:
        return (
          <>
            {number}
            <sup>th</sup>
            <span> &nbsp;Position</span>
          </>
        ); // remaining "th"
    }
  };
  
  const getEmployeeData = async () => {
    
    try {
      setLoading(true);
      // const result = await getRequest(`Awards/Details/${selectedId}`);
      const result = await getRequest(`RewardsTransController/TargetSetDetails?RewardId=${rewardsId}&EmployeeId=${studentId}`);
      setEventtData(result?.data?.summary);
      console.log("sandy Quantative details:", result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  console.log("this is event detail data", eventtData)

  useEffect(() => {
    document.title = `PeopleSol -  Reward Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  useEffect(() => {
    console.log(studentId, "=-==========",rewardsId );
    if(studentId && rewardsId)
    getEmployeeData();
  }, [studentId,rewardsId]);

  useEffect(()=>{
     console.log("the componants running------ running state fields", location?.state);
  }, [])

  // return (
  //   <div className="moduleMasterDetailsPage">
  //     <div
  //       className="moduleMasterDetailsBackground"
  //       onClick={() => closeDetails()}
  //     ></div>
  //     <div className="moduleMasterDetailsPageContainer">
  //       <div className="headingContainer">
  //         <h3>Details</h3>
  //         <IconButton onClick={() => closeDetails()} title="close">
  //           <svg
  //             width="13"
  //             height="13"
  //             viewBox="0 0 13 13"
  //             fill="none"
  //             xmlns="http://www.w3.org/2000/svg"
  //           >
  //             <path
  //               d="M12.7579 0.56897L0.757935 12.569"
  //               stroke="#F71D1D"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //             />
  //             <path
  //               d="M0.757935 0.56897L12.7579 12.569"
  //               stroke="#F71D1D"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //             />
  //           </svg>
  //         </IconButton>
  //       </div>
  //       {loading ? (
  //         <Loader />
  //       ) : (
  //         <div className="moduleMasterDetailsPageBody">
  //           <DetailsStep detailTitle={"Name"} Details={eventtData?.title} />
  //           <DetailsStep
  //             detailTitle={"Award Cycle"}
  //             Details={eventtData?.cycle}
  //           />
  //           <DetailsStep
  //             detailTitle={"Award For"}
  //             Details={eventtData?.awardFor}
  //           />
  //           <DetailsStep
  //             detailTitle={"Location"}
  //             Details={eventtData?.locationname}
  //           />
  //           <DetailsStep
  //             detailTitle={"No. of Winners"}
  //             Details={eventtData?.noOfWinners}
  //           />
  //           <DetailsStep
  //             detailTitle={"Nominations can be Submitted By"}
  //             Details={eventtData?.nominationsCanBeSubmittedBy}
  //           />
  //           <DetailsStep
  //             detailTitle={"Eligibilty Criteria"}
  //             Details={eventtData?.eligibilityCriteria}
  //           />
  //           <DetailsStep
  //             detailTitle={"Is Active"}
  //             Details={eventtData?.isActive === true ? "Yes" : "No"}
  //           />{" "}

  //           <DetailsStep
  //             detailTitle={"Created On"}
  //             Details={eventtData.createdOn && format(
  //               new Date(eventtData.createdOn),
  //               "dd/MM/yyyy hh:mm a"
  //             )}
  //           />
  //           <DetailsStep
  //             detailTitle={"Last Updated On"}
  //             Details={eventtData.lastUpdatedOn && format(
  //               new Date(eventtData.lastUpdatedOn),
  //               "dd/MM/yyyy hh:mm a"
  //             )}
  //           />
  //           <div>
  //             <h4 style={{ margin: "10px 0px" }}>Questions</h4>
  //           </div>
  //           <div style={{ width: "100%" }}>
  //             <table className="tabless tabless-bordered">
  //               <thead
  //                 style={{
  //                   backgroundColor: "var(--primary-hover)",
  //                 }}
  //               >
  //                 <tr>
  //                   <td>Sl. No.</td>
  //                   <td>Question</td>
  //                   <td>Is Mandatory?</td>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {eventtData?.requestlist?.length > 0
  //                   ? eventtData?.requestlist?.map((item, index) => {
  //                       return (
  //                         <tr className="trStyle">
  //                           <td>{index + 1}</td>
  //                           <td>{item.question}</td>
  //                           <td>
  //                             {item.isMandatory === true ? "Yes" : "No"}
  //                           </td>
  //                         </tr>
  //                       );
  //                     })
  //                   : "No Data Found"}
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage" style={{ zIndex: "100" }}>
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer" style={{maxWidth: "50%"}}>
        <div className="headingContainer">
          <h3>Request Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody" >
            
            {eventtData && (
              <div style={{ overflow: "auto" }}>
                {tabIndex === 0 &&
                  (eventtData != null ? (
                    <>
                      {eventtData?.targetsSet?.map((item, index) =>
                      (
                        <div className="detailsEReq" key={index}>
                          <div

                            style={{ flexDirection: "column" }}
                          >




                            {/*-------- selected department detail here----------- */}

                            {/* <div >
                            <div className="headingForm" style={{ paddingBottom: "8px" }}> Selected Departments</div>
                            <div className="detailInner">
                              <Details
                                detailTitle={"Department"}
                                Details={eventtData?.summary?._RewardsConfig?.isAlldepartment === false ? eventtData?.summary?.departmentNames.map((item) => item) : "All Department"}
                              />
                              <Details
                                detailTitle={"Reason for Selected Departments"}
                                Details={eventtData?.summary?._RewardsConfig?.reason_selectDepartment}
                              />
                            </div>
                          </div> */}

                            {/*---------- removed employee detail here ------------*/}

                            <div>
                              <div className="headingForm" style={{ paddingBottom: "8px" }}>
                              {item?.isConsolation === false ?  getOrdinalSuffix(index + 1)  : "Consolation"}
                             {/* {` ${getOrdinalSuffix(index + 1)} Position`} */}
                              </div>
                              <div className="detailInner" style={{alignItems: "start", flexDirection: "column-reverse"}}>

                                <div className="detaiTablePrice" style={{minHeight: "fit-content", width: "100%"}}>
                                  <div style={{ paddingRight: "12px" }}>
                                    <div
                                      style={{
                                        overflowY: "auto",
                                      }}
                                    >
                                      <table
                                        className="TravelTableMultiIn"
                                        style={{
                                          overflow: "auto",
                                          // width: "100%",
                                          // height: "197px",
                                        }}
                                      >
                                        <thead
                                          // style={{
                                          //   backgroundColor:
                                          //     "var(--primary-hover)",
                                          // }}
                                        >
                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid #555",
                                                paddingLeft: "10px",
                                                textAlign: "center",
                                                width: "fit-content",
                                              }}
                                            >
                                              S.no{" "}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid #555",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                                width: "65%",
                                              }}
                                            >
                                              Description
                                            </td>

                                            <td
                                              style={{
                                                border: "1px solid #555",
                                                textAlign: "center",
                                                paddingLeft: "10px",
                                                width: "15%",
                                              }}
                                            >
                                             No. Of Unit
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid #555",
                                                textAlign: "center",
                                                paddingLeft: "10px",
                                                width: "15%",
                                              }}
                                            >
                                              Value
                                            </td>

                                          </tr>
                                        </thead>
                                        <tbody>
                                          {item?.rewardsTargets?.map(
                                            (subItem, index) => (
                                              <tr key={index}>
                                                <td
                                                  style={{
                                                    border: "1px solid #555",
                                                    paddingLeft: "10px",
                                                    color: "#555555",
                                                    textAlign: "center",
                                                    width: "65px",
                                                  }}
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid #555",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                    color: "#555555",
                                                  }}
                                                >
                                                  {subItem?.title}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid #555",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                    color: "#555555",
                                                  }}
                                                >
                                                  {subItem?.unit}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid #555",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                    color: "#555555",
                                                  }}
                                                >
                                                  {subItem?.value}
                                                </td>
                                              </tr>

                                            ))}

                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <Details
                                  detailTitle={"Objective"}
                                  Details={item?.objectives}
                                  style={{width: "100%"}}
                                />
                              </div>
                            </div>

                            {/*---------- all Selected dates here ------------*/}

                            

                            {eventtData?.summary?.actionList ? (
                              <div
                                className="createFootDetails"
                                style={{ background: "white !important" }}
                              >
                                {eventtData?.summary?.actionList?.map((item) => (
                                  <div>
                                    <div
                                      className="button secondaryButton"
                                      // onClick={() => {
                                      //   item.type !== "Approve" &&
                                      //   item.type !== "Release" &&
                                      //   item.type !== "Cancel"
                                      //     ? togglePopup(
                                      //         item?.id,
                                      //         item?.type,
                                      //         item?.color
                                      //       )
                                      //     : PostApRejHol(item?.type, item?.id);
                                      // }}
                                      style={{
                                        background: item?.color,
                                        color: "white",
                                      }}
                                    >
                                      {item?.type}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {isOpen && (
                            <Popup
                              popupHeading={typeAction}
                              content={
                                <>
                                  <Remark
                                    eventtData={PostApRejHol}
                                    type={typeAction}
                                    id={selId}
                                    color={colorAction}
                                    handleClose={togglePopup}
                                  />
                                </>
                              }
                              handleClose={togglePopup}
                            />
                          )} */}
                          </div>

                        </div>)
                      )
                      }

                    </>
                  ) : (
                    <div className="noDatastyle">
                      <Loader />
                      <img
                        //   src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Data Found"}</h4>
                    </div>
                  ))}

                {/* {tabIndex === 1 && (
                    <>
                      <div className="tabularMasterDetailsPageTabBodyContainer">
                        <div style={{ display: "flex", width: "100%" }}>
                          <Timeline
                            // id={selectedId}
                            module={"Rewards"}
                          // requestNumber={
                          //   data?.pmsAppraisalCriteria?.requestNumber
                          // }
                          />
                        </div>
                      </div>
                    </>
                  )} */}

                {/* {tabIndex === 2 && (
                    <>
                      <div
                        className="tabularMasterDetailsPageTabBodyContainer"
                        style={{ flexGrow: "initial" }}
                      >
                        <Notifications
                        //   id={selectedId}
                          module={"Rewards"}
                        />
                      </div>
                    </>
                  )} */}

              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RewardsCriteriaSetTargetDetail

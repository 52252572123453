import React, { useEffect, useState } from 'react'
import Nodata from "../../Assets/Nodata.svg";
import StarRatings from 'react-star-ratings';
import { getStatusColor } from '../../Utilities/getStatusColor';
import { IconButton, Tooltip } from '@mui/material';
import OutsideClickHandler from '../../Components/OutsideClickHandler/OutsideClickHandler';
import { CheckBoxCustom } from '../../Components/FormComponent/CheckBoxCustom';
import { format } from 'date-fns';
import { showToast } from '../../Features';
import { useDispatch } from 'react-redux';
import "../PMSMaster/Components/Rewards.css"

const PublishWinnerTable = ({
    tbodyData,
    headerAction,
    data,
    sortType,
    sortParam,
    tableFilterName,
    selectedRows,
    addToSelectedRows,
    selectAll,
    onlyTable,
    originalData,
    containStatus,
    containTat,
    containStarRating,
    starRatingHeading,
  }) => {
    // debugger;
    tbodyData = tbodyData ? (tbodyData.length > 0 ? tbodyData : false) : false;
    const dispatch = useDispatch();
    const initialFilter =
      localStorage.getItem(tableFilterName) === null ||
      localStorage.getItem(tableFilterName).length === 0
        ? []
        : localStorage
            .getItem(tableFilterName)
            .split(",")
            .map((item) => (item = parseInt(item)));
    const [sliceIndex, setSliceIndex] = useState(initialFilter);
    const [filterToogle, setFilterToogle] = useState(false);
    const [statusIndex, setStatusIndex] = useState(false);
    const [tatIndex, setTatIndex] = useState(false);
    const [starRatingIndex, setStarRatingIndex] = useState(false);
    const getCheckIsPresent = () => {
      return originalData?.some((x) => x.checkActive);
    };
    const [moreActionPopup, setMoreActionPopup] = useState({
      isActive: false,
      Id: 0,
    });
    const theadData = tbodyData ? Object.keys(tbodyData[0]).slice(1) : null;
    useEffect(() => {
      if (containStatus)
        if (theadData) setStatusIndex(theadData.findIndex((x) => x === "status"));
  
      if (containTat)
        if (theadData) setTatIndex(theadData.findIndex((x) => x === "TAT"));
  
      if (containStarRating)
        if (theadData)
          setStarRatingIndex(theadData.findIndex((x) => x === starRatingHeading));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containTat, containStatus, theadData]);
    const filteredHeading = tbodyData
      ? Object.keys(tbodyData[0])
          .slice(1)
          .filter((d, i) => sliceIndex.indexOf(i) === -1)
      : null;
    const camelToCapital = (key) => {
      var result = key.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    };
    const headerFilter = (e, index) => {
      if (e.target.checked) {
        setSliceIndex((prev) =>
          prev.filter(function (eachElem) {
            return eachElem !== index;
          })
        );
        localStorage.setItem(
          tableFilterName,
          sliceIndex.filter(function (eachElem) {
            return eachElem !== index;
          })
        );
      } else {
        if (sliceIndex.length !== theadData.length - 1) {
          setSliceIndex((prev) => [...prev, index]);
          localStorage.setItem(tableFilterName, [...sliceIndex, index]);
        } else {
          e.target.checked = true;
          dispatch(
            showToast({
              text: "You cannot unselect all the column headers.",
              severity: "error",
            })
          );
        }
      }
    };
    const initialFilterCheck = (index) => {
      if (initialFilter.includes(index)) {
        return false;
      } else {
        return true;
      }
    };
    const getTableDataValue = (data) => {
      let parsedDate = Date.parse(data);
      if (data === true) {
        return "Yes";
      } else if (data === false) {
        return "No";
      } else if (!Number.isNaN(parsedDate)) {
        try {
          if (data.includes("T") && data.includes(":")) {
            let date = new Date(data);
            return `${format(date, "dd/MM/yyyy hh:mm a")}`;
          } else {
            if (data?.length > 50) return `${data?.slice(0, 50)}...`;
            else return data;
          }
        } catch {
          if (data?.length > 50) return `${data?.slice(0, 50)}...`;
          else return data;
        }
      } else if (data?.length > 50) return `${data?.slice(0, 50)}...`;
      else return data;
    };
    if (tbodyData) {
      return (
        <div className={`tableContainer  ${onlyTable && "onlyTable"}  publish_winner_container`}>
          <OutsideClickHandler
            onOutsideClick={() => {
              setFilterToogle((prev) => {
                if (prev) return !prev;
                else return prev;
              });
            }}
          >
            <div
              className={`${
                filterToogle
                  ? "headingFilterContainerActive headingFilterContainer"
                  : "headingFilterContainer"
              }`}
            >
              <div className="headingFilterHeader">
                <h5>Column Headers</h5>
                <IconButton onClick={() => setFilterToogle((prev) => !prev)}>
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.7579 0.56897L0.757935 12.569"
                      stroke="#F71D1D"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M0.757935 0.56897L12.7579 12.569"
                      stroke="#F71D1D"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IconButton>
              </div>
              <div className="headingFilterBodyContainer">
                {theadData.map((heading, index) => {
                  return (
                    <CheckBoxCustom
                      key={index}
                      id={camelToCapital(heading)}
                      defaultChecked={initialFilterCheck(index)}
                      label={camelToCapital(heading)}
                      onChange={(e) => {
                        headerFilter(e, index);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </OutsideClickHandler>
          <table className="table">
            <colgroup>
              <col />
              {getCheckIsPresent() && <col />}
              <col />
              <col style={{ width: "100%" }} />
              {theadData.slice(2).map((h, index) => {
                return <col key={index} />;
              })}
            </colgroup>
            <thead className="thead">
              <tr>
                <td title="Options" className="optionsCell">
                  <IconButton onClick={() => setFilterToogle((prev) => !prev)}>
                    {!filterToogle ? (
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="10.9794"
                          cy="1.73941"
                          r="1.2"
                          transform="rotate(90 10.9794 1.73941)"
                          fill="#BFBFBF"
                        />
                        <circle
                          cx="6.17946"
                          cy="1.73941"
                          r="1.2"
                          transform="rotate(90 6.17946 1.73941)"
                          fill="#BFBFBF"
                        />
                        <circle
                          cx="1.37944"
                          cy="1.73941"
                          r="1.2"
                          transform="rotate(90 1.37944 1.73941)"
                          fill="#BFBFBF"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.7579 0.56897L0.757935 12.569"
                          stroke="#F71D1D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M0.757935 0.56897L12.7579 12.569"
                          stroke="#F71D1D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </IconButton>
                </td>
                {getCheckIsPresent() && (
                  <td title="Select" className="checkCell">
                    <IconButton onClick={() => selectAll()}>
                      {selectedRows?.length ===
                      originalData?.filter((x) => x?.checkActive !== false)
                        ?.length ? (
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.75"
                            y="0.75"
                            width="16.8417"
                            height="16.5"
                            rx="4.25"
                            stroke="#1296B0"
                            stroke-width="1.5"
                          />
                          <path
                            d="M4 9L7 12L14 6"
                            stroke="#1296B0"
                            stroke-width="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.75"
                            y="1.48941"
                            width="16.8417"
                            height="16.5"
                            rx="4.25"
                            stroke="#BFBFBF"
                            strokeWidth="1.5"
                          />
                        </svg>
                      )}
                    </IconButton>
                  </td>
                )}
                <td
                  className={`${
                    getCheckIsPresent()
                      ? "actionsCell"
                      : "actionsCellCheckInactive"
                  }`}
                  style={{fontSize: "10px"}}
                >
                  Actions
                </td>
                {filteredHeading.map((heading, index) => {
                  return (
                    <td key={index} style={{fontSize: "10px"}}>
                      <div className="tableHeaderCell">
                        {camelToCapital(heading)}
                        {!onlyTable && (
                          <>
                            {containTat &&
                            sliceIndex.indexOf(tatIndex) === -1 &&
                            tatIndex === index ? null : (
                              <IconButton onClick={() => headerAction(heading)}>
                                <svg
                                  className={`${
                                    sortParam === heading
                                      ? "headingSort headingSortActive"
                                      : "headingSort"
                                  }`}
                                  width="6"
                                  height="11"
                                  viewBox="0 0 6 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    className={`${
                                      sortType === "ascending" && "sortTypeActive"
                                    }`}
                                    d="M2.99759 0.734329L5.28394 4.39843L2.99759 4.39843L0.711235 4.39843L2.99759 0.734329Z"
                                    fill="#BFBFBF"
                                  />
                                  <path
                                    className={`${
                                      sortType === "descending" &&
                                      "sortTypeActive"
                                    }`}
                                    d="M2.99759 10.7343L5.28394 7.07023L2.99759 7.07023L0.711235 7.07023L2.99759 10.7343Z"
                                    fill="#BFBFBF"
                                  />
                                </svg>
                              </IconButton>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tbodyData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={`${
                      selectedRows?.some((r) => r === (item?.Id ?? item?.id))
                        ? "selectedRow"
                        : null
                    }`}
                  >
                    <td className="optionsRow">{index + 1}</td>
                    {getCheckIsPresent() && (
                      <td title="Select" className="checkRow" style={{fontSize: "10px"}}>
                        {originalData.find(
                          (x) => (x.id ?? x.Id) === (item.id ?? item.Id)
                        )?.checkActive !== false && (
                          <IconButton
                            onClick={() => {
                              addToSelectedRows(item?.Id ?? item?.id);
                            }}
                          >
                            {selectedRows?.some(
                              (r) => r === (item?.Id ?? item?.id)
                            ) ? (
                              <svg
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.75"
                                  y="0.75"
                                  width="16.8417"
                                  height="16.5"
                                  rx="4.25"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                />
                                <path
                                  d="M4 9L7 12L14 6"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                  strokeLinecap="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.75"
                                  y="1.48941"
                                  width="16.8417"
                                  height="16.5"
                                  rx="4.25"
                                  stroke="#BFBFBF"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            )}
                          </IconButton>
                        )}
                      </td>
                    )}
                    <td
                      className={`${
                        getCheckIsPresent()
                          ? "actionsRow"
                          : "actionsRowCheckInactive"
                      } ${
                        moreActionPopup.isActive &&
                        (item.Id ?? item.id) === moreActionPopup.Id &&
                        "moreActionActive"
                      }`}
                      style={{fontSize: "10px !important"}}
                    >
                      <div className="actionCellContainer">
                        {originalData
                          .find((x) => (x.id ?? x.Id) === (item.id ?? item.Id))
                          ?.actions?.map((itemData, index) => {
                            return (
                              <Tooltip
                                arrow
                                title={itemData.action}
                                placement="top"
                              >
                                <IconButton
                                  key={index}
                                  color="primary"
                                  onClick={
                                    itemData.clickAction
                                      ? () =>
                                          itemData.clickAction(item.Id ?? item.id)
                                      : () => {}
                                  }
                                >
                                  {itemData.img}
                                </IconButton>
                              </Tooltip>
                            );
                          })}
                        {originalData.find(
                          (x) => (x.id ?? x.Id) === (item.id ?? item.Id)
                        )?.moreActions && (
                          <OutsideClickHandler
                            key={item.id ?? item.Id}
                            id={item.id ?? item.Id}
                            onOutsideClick={(id) =>
                              setMoreActionPopup((prev) => {
                                if (prev.isActive) {
                                  if (prev.Id === id) {
                                    return { isActive: false, Id: 0 };
                                  } else {
                                    return prev;
                                  }
                                } else {
                                  return prev;
                                }
                              })
                            }
                          >
                            <div
                              style={{
                                display: "inline-block",
                                position: "relative",
                              }}
                            >
                              <Tooltip
                                arrow
                                title={"More Actions"}
                                placement="top"
                              >
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    setMoreActionPopup((prev) => {
                                      if (prev.isActive) {
                                        if (prev.Id === (item.Id ?? item.id)) {
                                          return { isActive: false, Id: 0 };
                                        } else {
                                          return {
                                            isActive: true,
                                            Id: item.Id ?? item.id,
                                          };
                                        }
                                      } else {
                                        return {
                                          isActive: true,
                                          Id: item.Id ?? item.id,
                                        };
                                      }
                                    })
                                  }
                                >
                                  <svg
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="0.264709"
                                      y="0.744141"
                                      width="20"
                                      height="20"
                                      rx="4"
                                      fill="#E4E4FD"
                                    />
                                    <circle
                                      cx="15.0649"
                                      cy="10.7439"
                                      r="1.2"
                                      transform="rotate(90 15.0649 10.7439)"
                                      fill="#8A88ED"
                                    />
                                    <circle
                                      cx="10.2649"
                                      cy="10.7439"
                                      r="1.2"
                                      transform="rotate(90 10.2649 10.7439)"
                                      fill="#8A88ED"
                                    />
                                    <circle
                                      cx="5.4649"
                                      cy="10.7439"
                                      r="1.2"
                                      transform="rotate(90 5.4649 10.7439)"
                                      fill="#8A88ED"
                                    />
                                  </svg>
                                </IconButton>
                              </Tooltip>
                              {moreActionPopup.isActive &&
                                moreActionPopup.Id === (item.Id ?? item.id) && (
                                  <div className="moreActionContainer">
                                    <div className="headingFilterHeader">
                                      <h5>Actions</h5>
                                      <IconButton
                                        onClick={() =>
                                          setMoreActionPopup({
                                            isActive: false,
                                            Id: 0,
                                          })
                                        }
                                      >
                                        <svg
                                          width="13"
                                          height="13"
                                          viewBox="0 0 13 13"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12.7579 0.56897L0.757935 12.569"
                                            stroke="#F71D1D"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M0.757935 0.56897L12.7579 12.569"
                                            stroke="#F71D1D"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </IconButton>
                                    </div>
                                    {originalData
                                      .find(
                                        (x) =>
                                          (x.id ?? x.Id) === (item.id ?? item.Id)
                                      )
                                      ?.moreActions?.map((itemData, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="moreActionTile cursor"
                                            onClick={
                                              itemData.clickAction
                                                ? () =>{
                                                    itemData.clickAction(
                                                      item.Id ?? item.id
                                                    );  setMoreActionPopup({
                                                      isActive: false,
                                                      Id: 0,
                                                    })}
                                                : () => {}
                                            }
                                          >
                                            <label
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {itemData.actionTitle}
                                            </label>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                            </div>
                          </OutsideClickHandler>
                        )}
                      </div>
                    </td>
                    {containStatus || containTat || containStarRating ? (
                      <>
                        {Object.values(item)
                          .map((cellItem, prevIndex) => {
                            return {
                              data: cellItem,
                              index: prevIndex - 1,
                              Id: item.Id ?? item.id,
                            };
                          })
                          .slice(1)
                          .filter((eachElem, index) => {
                            return sliceIndex.indexOf(index) === -1;
                          })
                          .map((data, mappingIndex) => {
                            if (containStatus && data.index === statusIndex) {
                              let statusColor = "black";
                              let statusColorFont = "white";
                              let statusColorString = getStatusColor(
                                originalData.find(
                                  (x) => (x.id ?? x.Id) === data.Id
                                )?.status
                              );
                              statusColor = statusColorString?.split(",")[0];
                              statusColorFont = statusColorString?.split(",")[1];
                              return (
                                <td key={mappingIndex}>
                                  {data.data ? (
                                    <div
                                      className="statusCell"
                                      style={{
                                        backgroundColor: statusColor,
                                        color: statusColorFont,
                                      }}
                                    >
                                      {data.data}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              );
                            } else if (containTat && data.index === tatIndex) {
                              let tatColor = originalData
                                .find((x) => (x.id ?? x.Id) === data.Id)
                                ?.tatColor?.toString();
                              return (
                                <td key={mappingIndex} style={{fontSize: "10px"}}>
                                  {data?.data ? (
                                    <div
                                      className="statusCell"
                                      style={{
                                        border: `1px solid ${tatColor}`,
                                        color: tatColor,
                                        fontWeight: 500,
                                        position: "relative",
                                      }}
                                    >
                                      <svg
                                        style={{
                                          position: "absolute",
                                          right: "-5px",
                                          top: "-5px",
                                        }}
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle
                                          cx="6"
                                          cy="6"
                                          r="6"
                                          fill={tatColor}
                                        />
                                        <path
                                          d="M6.53721 4.00631C7.09124 4.00631 7.54036 3.55718 7.54036 3.00315C7.54036 2.44913 7.09124 2 6.53721 2C5.98318 2 5.53406 2.44913 5.53406 3.00315C5.53406 3.55718 5.98318 4.00631 6.53721 4.00631Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M7.56298 8.07704C7.53526 8.0584 7.50411 8.04548 7.47133 8.03905C7.43856 8.03261 7.40483 8.03278 7.37213 8.03956C7.33942 8.04633 7.3084 8.05957 7.28088 8.0785C7.25336 8.09742 7.2299 8.12166 7.21187 8.14977C6.96943 8.50881 6.67244 8.82781 6.33161 9.09525C6.24885 9.15794 5.94038 9.39619 5.80997 9.34603C5.71968 9.31845 5.77235 9.14039 5.7899 9.06515L5.92282 8.67141C5.97799 8.51091 6.93851 5.66195 7.04385 5.33593C7.19933 4.85943 7.13162 4.39046 6.42189 4.50331C6.22878 4.52337 4.27013 4.77667 4.23502 4.77918C4.20208 4.78132 4.16989 4.78993 4.14028 4.80451C4.11068 4.81909 4.08423 4.83936 4.06246 4.86416C4.04068 4.88896 4.02401 4.91781 4.01338 4.94905C4.00276 4.9803 3.99839 5.01333 4.00053 5.04627C4.00267 5.0792 4.01128 5.11139 4.02586 5.141C4.04044 5.17061 4.06071 5.19705 4.08551 5.21883C4.11031 5.2406 4.13916 5.25728 4.17041 5.2679C4.20165 5.27853 4.23468 5.2829 4.26762 5.28075C4.26762 5.28075 5.01998 5.18295 5.10274 5.17542C5.14513 5.17129 5.18782 5.1795 5.22564 5.19908C5.26347 5.21866 5.29482 5.24877 5.31591 5.28577C5.36136 5.42624 5.35513 5.57833 5.29836 5.71462C5.23315 5.96541 4.20241 8.86954 4.16981 9.03756C4.13484 9.17817 4.14455 9.32618 4.19759 9.46102C4.25062 9.59586 4.34435 9.71082 4.46574 9.78993C4.69359 9.94179 4.96487 10.0149 5.23817 9.99808C5.5039 9.99484 5.76669 9.94211 6.01311 9.84259C6.63757 9.59181 7.28962 8.92471 7.6332 8.40056C7.66278 8.34731 7.67197 8.2851 7.65905 8.22557C7.64613 8.16604 7.61198 8.11324 7.56298 8.07704Z"
                                          fill="white"
                                        />
                                      </svg>
                                      {data?.data
                                        ?.split(":")
                                        .slice(0, 2)
                                        .join(":")}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              );
                            } else if (
                              containStarRating &&
                              data.index === starRatingIndex
                            ) {
                              let tempRating = parseInt(data.data);
                              if (isNaN(tempRating)) {
                                return <td></td>;
                              } else
                                return (
                                  <td key={mappingIndex}>
                                    {data.data && (
                                      <StarRatings
                                        starEmptyColor={"#BFBFBF"}
                                        starRatedColor={"var(--primary)"}
                                        rating={data.data}
                                        starDimension="35px"
                                        starSpacing="5px"
                                      />
                                    )}
                                  </td>
                                );
                            } else {
                              return (
                                <td key={mappingIndex}>
                                  {getTableDataValue(data.data)}
                                </td>
                              );
                            }
                          })}
                      </>
                    ) : (
                      <>
                        {Object.values(item)
                          .slice(1)
                          .filter(function (eachElem, index) {
                            return sliceIndex.indexOf(index) === -1;
                          })
                          .map((data, index) => {
                            return <td key={index} style={{fontSize: "10px"}}>{getTableDataValue(data)}</td>;
                          })}
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{data ? data : "No Data Found"}</h4>
        </div>
      );
    }
  };

export default PublishWinnerTable

import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { Details } from "../DetailsComp/Details";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import Nodata from "../../../../Assets/Nodata.svg";
import { confirmAlert } from "react-confirm-alert";
import { UpdateProjectStatus } from "./FormComp/UpdateProjectStatus";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";

const DetailsPageProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const [isOpen, setIsOpen] = useState(false);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Projects/Details?ID=${selectedId}`);
      setEventtData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page Project`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getFormDetails();
    return () => { };
  }, []);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3> Project Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div
          className="tabularMasterDetailsPageBody"
          style={{ height: "100%", overflow: "auto" }}
        >
          {eventtData != null ? (
            loading ? (
              <div className="noDataFound">
                <Loader />
              </div>
            ) : (
              <div className="detailsEReq" style={{ flexDirection: "column" }}>
                <>
                  <div className="headingForm">Project Details</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Project Name"}
                      Details={eventtData?._project?.name ?? ""}
                    />
                    <Details
                      detailTitle={"Project Code"}
                      Details={eventtData?._project?.code ?? ""}
                    />
                    <Details
                      detailTitle={"Project Type"}
                      Details={eventtData?._project?.projectType ?? ""}
                    />
                    <Details
                      detailTitle={"Customer Name"}
                      Details={
                        eventtData?.customer
                          ? `${eventtData?.customer?.firstName}${" "}${eventtData?.customer?.lastName ?? ""
                          }`
                          : "-"
                      }
                    />
                    <Details
                      detailTitle={"Department"}
                      Details={eventtData?.projectdepartments?.map((item) => (
                        <div>{item?.name ?? ""}</div>
                      ))}
                    />
                    <Details
                      detailTitle={"Project Delivery Locations"}
                      Details={eventtData?.projectlocations?.map((item) => (
                        <div>{item?.name ?? ""}</div>
                      ))}
                    />
                    <Details
                      detailTitle={"Project Description"}
                      Details={eventtData?._project?.description ?? ""}
                    />
                    <Details
                      detailTitle={"Project Document"}
                      Details={
                        eventtData?._project?.attachments ? (
                          <UploadDetail
                            filepath={`Projects/${eventtData?._project?.attachments}`}
                          />
                        ) : (
                          "-"
                        )
                      }
                    />
                    <Details
                      detailTitle={"Tag Name"}
                      Details={eventtData?._project?.tagName ?? ""}
                    />
                    <Details
                      detailTitle={"Is Billable"}
                      Details={eventtData?._project?.isBillable ? "Yes" : "No"}
                    />
                    <Details
                      detailTitle={"Created On"}
                      Details={
                        eventtData?._project?.createdOn &&
                        format(
                          new Date(eventtData?._project?.createdOn),
                          "dd/MM/yyyy hh:mm a"
                        )
                      }
                    />
                    <Details
                      detailTitle={"Last Updated On"}
                      Details={
                        eventtData?._project?.createdOn &&
                        format(
                          new Date(eventtData?._project?.lastUpdatedOn),
                          "dd/MM/yyyy hh:mm a"
                        )
                      }
                    />
                  </div>
                  <div className="headingForm">Project Timeline</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Starts On"}
                      Details={
                        eventtData?._project?.createdOn &&
                        format(
                          new Date(eventtData?._project?.startsOn),
                          "dd/MM/yyyy hh:mm a"
                        )
                      }
                    />

                    <Details
                      detailTitle={"Ends On"}
                      Details={
                        eventtData?._project?.createdOn &&
                        format(
                          new Date(eventtData?._project?.endsOn),
                          "dd/MM/yyyy hh:mm a"
                        )
                      }
                    />

                    <Details
                      detailTitle={"Estimated Hours"}
                      Details={eventtData?._project?.estimatedHours ?? ""}
                    />
                  </div>
                  <div className="headingForm">Project Cost</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Cost Estimation"}
                      Details={eventtData?._project?.costEstimation ?? ""}
                    />
                    <Details
                      detailTitle={"Billing Schedule"}
                      Details={eventtData?._project?.billingSchedule ?? ""}
                    />
                    <Details
                      detailTitle={"Billing Type"}
                      Details={eventtData?.billingtype?.name ?? ""}
                    />
                    <Details
                      detailTitle={"Billing Rate"}
                      Details={eventtData?._project?.billingRate ?? ""}
                    />
                  </div>
                </>

                {eventtData?._project?.projectTeams?.length > 0 ? (
                  <>
                    <div className="headingForm">Project Team Members</div>
                    <div className="detailInner">
                      <div style={{ width: "100%" }}>
                        <table
                          className="tabless tabless-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead
                            style={{
                              backgroundColor: "var(--primary-hover)",
                            }}
                          >
                            <tr>
                              <td>S.no</td>
                              <td>Project Employee</td>
                              <td>Project Role</td>
                              <td>Project Description</td>
                            </tr>
                          </thead>
                          <tbody>
                            {eventtData?._project?.projectTeams?.map(
                              (items, index) => {
                                return (
                                  <tr className="trStyle">
                                    <td>{index + 1}</td>
                                    <td>
                                      {items.employee?.nameWithPersonNumber ??
                                        ""}
                                    </td>
                                    <td>{items.role?.name ?? ""}</td>
                                    <td>{items?.description ?? ""}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="createFootDetails">
                  {" "}
                  <div
                    className="primaryButton updateStatusHolder cursor, button"
                    onClick={() => togglePopup()}
                    style={{ marginTop: 20 }}
                  >
                    <label>Update Status</label>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Records"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={"Update Status"}
          content={
            <>
              <UpdateProjectStatus
                type={"Update Status"}
                id={selectedId}
                prestatus={eventtData?._project}
                data={getFormDetails}
                handleClose={togglePopup}
              />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};
export { DetailsPageProject };

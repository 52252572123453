import React from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";

import { Form, Formik } from "formik";
import { OnboardingSettingForm } from "./Form/OnboardingSettingForm";
import { GenralForm } from "./Form/GenralForm";
import { useEffect } from "react";

const GenralSetting = (props) => {
  const dispatch = useDispatch();
  const [option, setOption] = React.useState({});

  const getOption = async () => {
    try {
      const result = await postRequest("Settings/GetCompanySettings");
      setOption(result.data);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    getOption();
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("Settings/CompanySettings", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Genrel Settings`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const initialvalue = {
    GeneralRelievingLetterFormat: option?.generalRelievingLetterFormat,
    ObCertificateContent: option?.obCertificateContent,
    CafeteriaEmailId: option?.cafeteriaEmailId,
    ObInductionContent: option?.obInductionContent,
    ObInductionMinTime: option?.obInductionMinTime,
    ObPoliciesMinTime: option?.obPoliciesMinTime,
    ObAssetFormMinTime: option?.obAssetFormMinTime,
    ObFeedbackMinTime: option?.obFeedbackMinTime,
    SupportMail: option?.supportMail,
    Upload: option?.attachment,
  };
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append(
      "GeneralRelievingLetterFormat",
      values?.GeneralRelievingLetterFormat
    );
    formData.append("ObCertificateContent", values?.ObCertificateContent);
    formData.append("CafeteriaEmailId", values?.CafeteriaEmailId);
    formData.append("ObInductionContent", values?.ObInductionContent);
    formData.append("ObInductionMinTime", values?.ObInductionMinTime);
    formData.append("ObPoliciesMinTime", values?.ObPoliciesMinTime);
    formData.append("ObAssetFormMinTime", values?.ObAssetFormMinTime);
    formData.append("ObFeedbackMinTime", values?.ObFeedbackMinTime);
    formData.append("SupportMail", values?.SupportMail);
    formData.append("Upload", values?.Upload);
    Edit(formData);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("settings/general");
  };
  return (
    <div
      style={{
        background: "#FAF9F8",
        marginTop: "20px",
        height: "calc(100% - 69px)",
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialvalue}
        onSubmit={onSubmit}
      >
        {(formik, values) => {
          return (
            <Form
              style={{
                // padding: " 15px 15px 0 15px ",
                position: "relative",
                height: "100%",
                flexGrow: 1,
              }}
            >
              <div
                style={{
                  padding: " 15px 15px 0 15px ",
                  height: "calc(100% - 79px)",
                  overflowY: "auto",
                }}
              >
                <GenralForm />
              </div>
              <div className="createFootCust" style={{ paddingRight: "14px" }}>
                <div>
                  <div onClick={returnPage} className="cancelbtn">
                    Cancel
                  </div>
                </div>
                <div>
                  <button className="button primaryButton" type="submit">
                    Update
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { GenralSetting };

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import {
    deleteWithPayloadRequest,
    getRequest,
    postRequest,
} from "../../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import MoreActivityContainer from "../../../../Components/Table/MoreActivityContainer";
import ApproveIcon from "../../../../Assets/ApproveIcon.svg";
import RejectIcon from "../../../../Assets/RejectIcon.svg";
import HoldIcon from "../../../../Assets/HoldIcon.svg";
import CancelIcon from "../../../../Assets/CancelIcon.svg";
import { TransactionTable } from "../../../../Components/Table/TransactionTable";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";

import { useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { DatePickerCustom } from "../../../../Components/FormComponent/DatePickerCustom";
import { ApprovePopup } from "../../../../Components/FormComponent/PopupComponent/ApprovePopup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import DeletePopup from "../../../../Components/DeletePopup/DeletePopup";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../Features/badgeUtils";
const status = [
    { label: "All", value: "All" },
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
    { label: "Pending", value: "Pending" },
    { label: "Rejected", value: "Rejected" },
    { label: "Hold", value: "Hold" },
    { label: "Cancelled", value: "Cancelled" },
];
const ReasonForVacancyOptions = [
    { label: "Resignation", value: "Resignation" },
    { label: "Termination", value: "Termination" },
    { label: "New Position", value: "New Position" },
    { label: "Other", value: "Other" },
];
const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
];
const OfferLetter = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tbodyData, setTbodyData] = useState(false);
    const [originalData, setOriginalData] = useState(false);
    const [count, setCount] = useState(false);
    const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortParam, setSortParam] = useState("Id");
    const [sortType, setSortType] = useState("descending");
    const [searchParam, setSearchParam] = useState(null);
    const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
    const [dateOfJoning, setDateOfJoining] = useState(false);
    const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
    const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
    const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedStatusList, setSelectedStatusList] = useState([]);
    const [selectedReasonForVacancyList, setSelectedReasonForVacancyList] =
        useState([]);
    const [typeAll, setTypeAll] = useState("");
    const [typeAction, setTypeAction] = useState("");
    const [colorAction, setColorAction] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isApprovePopup, setIsApprovePopup] = useState(false);
    const [selId, setSelId] = useState(false);
    const togglePopup = (type, color, id) => {
        setTypeAction(type);
        setColorAction(color);
        setSelId(id);
        setIsOpen((prev) => !prev);
    };
    const togglePopupapprove = (type) => {
        setTypeAll(type);
        setIsApprovePopup((prev) => !prev);
    };

    const { LoggedInUser } = useSelector((state) => state.auth);
    const clickHandlerFunction = (id, type, color) => {
        if (type !== "Approve" && type !== "Release" && type !== "Cancel") {
            togglePopup(type, color, id);
        } else {
            PostApRejHol(type, id);
        }
    };
    const PostApRejHol = async (type, id, Remark) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            if (type === "Approve" || type === "Cancel" || type === "Release") {
                await postRequest(`ManpowerIndents/${type}?Id=${id}`);
            } else if (type === "Mark as Closed") {
                await postRequest(`ManpowerIndents/Close?Id=${id}&Remarks=${Remark}`);
            } else {
                await postRequest(`ManpowerIndents/${type}?Id=${id}&Remarks=${Remark}`);
            }
            dispatch(showToast({ text: "Success", severity: "success" }));
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "Error in fetching Data",
                    severity: "error",
                })
            );
        } finally {
            await getBadgeCount(dispatch);
            dispatch(isLoading(false));
            setLoading(false);
            setIsOpen(false);
            getListData();
        }
    };
    useEffect(() => {
        document.title = `PeopleSol - Onboarding Requisition`;
        return () => {
            document.title = "PeopleSol";
        };
    }, []);
    const defaultList = "OnboardingsReqDL";
    const [ActivityList, setActivityList] = useState([
        {
            id: 1,
            actionTitle: "My Requests",
            badge: 0,
            favourite: (localStorage.getItem(defaultList) ?? "1") === "1",
            defaultList: defaultList,
        },
    ]);

    function handleActiveListChange(newValue) {
        setActiveList(newValue.toString());
        setSelectedRows([]);
        setRecordsSelected(noOfRecords[0]);
        setSearchParam(null);
        setSortParam("Id");
        setSortType("descending");
        setReqData({
            createdOn: null,
            lastUpdatedOn: null,
            dataTableRequest: {
                iDisplayLength: noOfRecords[0].value,
                iDisplayStart: 0,
                sSearch: null,
                sorting: "Id",
                sSortDir_0: "descending",
                isExport: false,
            },
        });
    }
    const [reqData, setReqData] = useState({
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
            iDisplayLength: recordsSelected.value,
            iDisplayStart: 0,
            sSearch: searchParam,
            sorting: sortParam,
            sSortDir_0: sortType,
            isExport: false,
        },
    });
    const [filterSearchToogle, setFilterSearchToogle] = useState(false);
    const calculateTotalPages = (count) => {
        if (count > 0) {
            let numberOfPages = parseInt(count / recordsSelected.value);
            if (count % recordsSelected.value !== 0) {
                setTotalPages(numberOfPages + 1);
            } else {
                setTotalPages(numberOfPages);
            }
        } else {
            setTotalPages(1);
        }
    };


    const getListData = async () => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await postRequest("HrmsOfferLetter/List", reqData);
            if (result.data) {
                let tempData = [];
                result?.data?.data?.map((item) =>
                    tempData?.push({
                        ...item,
                        statusColor: getStatusColor(item.status),
                    })
                );
                let a = tempData.map(item => ({ ...item.item, actions: item.actions, monthlyCtc: (item.item.annual_ctc_Offered / 12).toFixed(0), annualCtc: item.item.annual_ctc_Offered }))
                for (let i = 0; i < a.length; i++) {
                    delete a[i].monthly_ctc;
                    delete a[i].annual_ctc_Offered;
                    delete a[i].employeeId;
                }
                let b = a.map(item => ({ ...item, actions: getStaticActions(item), moreActions: getMoreActions(item.actions) }));
                for (let i = 0; i < a.length; i++) {
                    delete a[i].actions
                }
                setOriginalData(b);
                setTbodyData(a);
                setSelectedRows([]);
            } else {
                setTbodyData(false);
            }
            setCount(result.data.summary.allCount);
            calculateTotalPages(result.data.summary.allCount);
        } catch (error) {
            setTbodyData(false);
            setCount(0);
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    };

    const cancelRequest = async (id) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await getRequest("HrmsOfferLetter/Cancel?Id=" + id);
            setLoading()
            dispatch(showToast({ text: "success", severity: "success" }));
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "some error occured",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
            getListData();
        }
    }

    const getActions = async (data) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            if (data.type === "Release" || data.type === "Re-Release") {
                const result = await postRequest("HrmsOfferLetter/Release?ID=" + data.id, { id: data.id });
                setLoading()
            }
            if (data.type === "Revise") {
                navigate("/hrms/onboarding/offerletter/create", {
                    state: { id: data.id, type: "revise" },
                });
            }
            dispatch(showToast({ text: "success", severity: "success" }));
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "some error occured",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
            getListData();
        }
    }

    const getMoreActions = (actionList) => {
        let returnActionArray = [];
        if (actionList.length > 0) {
            actionList.map((action) => {
                if (
                    action.type !== "Approve" &&
                    action.type !== "Reject" &&
                    action.type !== "Hold"
                ) {
                    returnActionArray.push({
                        actionTitle: action.type,
                        clickAction: () => { getActions(action) }
                    });
                }
                return null;
            });
        }
        return returnActionArray.length > 0 ? returnActionArray : null;
    };

    const getStaticActions = (data) => {

        let returnActionArray = [
            {
                img: (
                    <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            opacity="0.15"
                            d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
                            fill="#0DB53C"
                        />
                        <line
                            x1="14.7295"
                            y1="7.43701"
                            x2="7.72949"
                            y2="7.43701"
                            stroke="#0DB53C"
                            stroke-linecap="round"
                        />
                        <line
                            x1="5.72949"
                            y1="7.43701"
                            x2="5.61462"
                            y2="7.43701"
                            stroke="#0DB53C"
                            stroke-linecap="round"
                        />
                        <line
                            x1="14.7295"
                            y1="11.3437"
                            x2="7.72949"
                            y2="11.3437"
                            stroke="#0DB53C"
                            stroke-linecap="round"
                        />
                        <line
                            x1="5.72949"
                            y1="11.3437"
                            x2="5.61462"
                            y2="11.3438"
                            stroke="#0DB53C"
                            stroke-linecap="round"
                        />
                        <line
                            x1="14.7295"
                            y1="15.2505"
                            x2="7.72949"
                            y2="15.2505"
                            stroke="#0DB53C"
                            stroke-linecap="round"
                        />
                        <line
                            x1="5.72949"
                            y1="15.2505"
                            x2="5.61462"
                            y2="15.2505"
                            stroke="#0DB53C"
                            stroke-linecap="round"
                        />
                    </svg>
                ),
                action: "Request Details",
                clickAction: (id) => getFormDetails(id),
            },

        ];

        if (!["Accepted", "Cancelled", "Released", "Rejected", "Candidate-Rejected"].includes(data.status)) {
            returnActionArray.push(
                {
                    img: (
                        <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.15"
                                d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
                                fill="#FB7158"
                            />
                            <path
                                d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
                                stroke="#FB7158"
                                strokeWidth="0.698594"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    ),
                    action: "Edit Request",
                    clickAction: (id) => handleEdit(id),
                },
            );
            returnActionArray.push(
                {
                    img: <img src={CancelIcon} alt="Cancel" />,
                    action: "Cancel Request",
                    clickAction: (id) => {
                        cancelRequest(id);
                    },
                }
            )
        }
        return returnActionArray;
    };


    const handleEdit = (id) => {
        navigate("/hrms/onboarding/offerletter/create", {
            state: { id },
        });
    };

    const location = useLocation();
    useEffect(() => {
        getListData();
        document.title = `PeopleSol - Requisition`;
        return () => {
            document.title = "PeopleSol";
        };
    }, [location.pathname, reqData]);

    const paginationChanged = (e) => {
        setCurrentPage(1);
        setRecordsSelected(e[0]);
        if (e[0].value > recordsSelected.value) {
            if (count > recordsSelected.value) {
                setReqData({
                    ...reqData,
                    dataTableRequest: {
                        ...reqData.dataTableRequest,
                        iDisplayLength: e[0].value,
                        iDisplayStart: 0,
                    },
                });
            }
        } else {
            if (count > e[0].value) {
                setReqData({
                    ...reqData,
                    dataTableRequest: {
                        ...reqData.dataTableRequest,
                        iDisplayLength: e[0].value,
                        iDisplayStart: 0,
                    },
                });
            }
        }
    };
    const pageChangedNext = () => {
        setCurrentPage((prev) => prev + 1);
        setReqData({
            ...reqData,
            dataTableRequest: {
                ...reqData.dataTableRequest,
                iDisplayStart: currentPage * recordsSelected.value,
            },
        });
    };
    const pageChangedPrev = () => {
        setCurrentPage((prev) => prev - 1);
        setReqData({
            ...reqData,
            dataTableRequest: {
                ...reqData.dataTableRequest,
                iDisplayStart: (currentPage - 2) * recordsSelected.value,
            },
        });
    };
    const deferredText = useDeferredValue(searchParam);
    useMemo(() => {
        setCurrentPage(1);
        setReqData({
            ...reqData,
            dataTableRequest: {
                ...reqData.dataTableRequest,
                iDisplayStart: 0,
                sSearch: deferredText,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deferredText]);
    const getFormDetails = (id) => {
        navigate(`/hrms/onboarding/requisition/details/${id}`);
    };


    const sortUpdated = (heading) => {
        console.log("data sort param", sortParam);
        if (heading === sortParam) {
            if (sortType === "ascending") {
                setSortType("descending");
                setReqData({
                    ...reqData,
                    dataTableRequest: {
                        ...reqData.dataTableRequest,
                        sSortDir_0: "descending",
                    },
                });
            } else {
                setSortType("ascending");
                setReqData({
                    ...reqData,
                    dataTableRequest: {
                        ...reqData.dataTableRequest,
                        sSortDir_0: "ascending",
                    },
                });
            }
        } else {
            setSortType("ascending");
            setSortParam(heading);
            if (heading === "vacantDesignation") heading = "vacanctDesigination";
            else if (heading === "vacantLocation") heading = "vacanctLocation";
            else if (heading === "expectedDOJ") heading = "expectedDoj";
            setReqData({
                ...reqData,
                dataTableRequest: {
                    ...reqData.dataTableRequest,
                    sorting: heading,
                    sSortDir_0: "ascending",
                },
            });
        }
    };
    const applyFilters = () => {
        setReqData({
            ...reqData,
            status:
                selectedStatusList.length > 0 ? selectedStatusList[0]?.value : null,
            reasonForVacancy:
                selectedReasonForVacancyList.length > 0
                    ? selectedReasonForVacancyList[0]?.value
                    : null,
            dojDate: dateOfJoning ? `${format(dateOfJoning, "MM/dd/yyyy")}` : null,
            createdOn: createdOnStartDate
                ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${createdOnEndDate
                    ? format(createdOnEndDate, "dd/MM/yyyy")
                    : format(createdOnStartDate, "dd/MM/yyyy")
                }`
                : null,
            lastUpdatedOn: lastUpdatedOnStartDate
                ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${lastUpdatedOnEndDate
                    ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
                    : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
                }`
                : null,
        });
        setFilterSearchToogle((prev) => !prev);
    };
    const resetFilters = () => {
        setCreatedOnStartDate(false);
        setDateOfJoining(false);
        setCreatedOnEndDate(false);
        setLastUpdatedOnStartDate(false);
        setSelectedStatusList([]);
        setSelectedReasonForVacancyList([]);
        setLastUpdatedOnEndDate(false);
        setReqData({
            ...reqData,
            createdOn: null,
            lastUpdatedOn: null,
            dojDate: null,
            status: null,
            reasonForVacancy: null,
        });
        setFilterSearchToogle((prev) => !prev);
    };
    const addToSelectedRows = (id) => {
        let arr = [...selectedRows];
        if (arr.includes(id)) {
            arr.splice(arr.indexOf(id), 1);
            setSelectedRows(arr);
            return;
        }
        arr.push(id);
        setSelectedRows(arr);
    };
    const selectAll = () => {
        if (selectedRows?.length > 0) {
            setSelectedRows([]);
        } else {
            let arr = [];
            tbodyData?.map((item) => {
                if (
                    originalData.find((x) => (x.id ?? x.Id) === (item.id ?? item.Id))
                        .checkActive
                )
                    arr.push(item.Id ?? item.id);
                return null;
            });
            setSelectedRows(arr);
        }
    };
    const handleDelete = async (selectedRows) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await deleteWithPayloadRequest(
                `ManpowerIndents/CancelAll`,
                selectedRows
            );
            if (result) dispatch(showToast({ text: "success", severity: "success" }));
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "some error occured",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
            setSelectedRows([]);
            getListData();
        }
    };
    const deleteSelected = () => {
        if (selectedRows.length === 1) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <DeletePopup
                            onClose={onClose}
                            cancel={true}
                            message={`Are you sure you want to cancel this request?`}
                            onClick={() => handleDelete(selectedRows)}
                        />
                    );
                },
            });
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <DeletePopup
                            onClose={onClose}
                            cancel={true}
                            message={`Are you sure you want to cancel these ${selectedRows.length} requests?`}
                            onClick={() => handleDelete(selectedRows)}
                        />
                    );
                },
            });
        }
    };
    const handleApprove = async (selectedRows, typeAll) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            let tempIds = [];
            selectedRows.map((item) => {
                return tempIds.push(
                    originalData.find((x) => x.Id ?? x.id === item)?.nextRequestId
                );
                return null;
            });
            await deleteWithPayloadRequest(`ManpowerIndents/${typeAll}`, tempIds);
            dispatch(showToast({ text: "success", severity: "success" }));
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "some error occured",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
            setSelectedRows([]);
            if (isApprovePopup) togglePopupapprove();
        }
    };
    const rejectAll = async (typeAll, selectedRows, remarks) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            let tempIds = [];
            selectedRows.map((item) => {
                return tempIds.push(
                    originalData.find((x) => x.Id ?? x.id === item)?.nextRequestId
                );
                return null;
            });
            await deleteWithPayloadRequest(
                `ManpowerIndents/${typeAll}?Remarks=${remarks}`,
                tempIds
            );
            dispatch(showToast({ text: "success", severity: "success" }));
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "some error occured",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
            setSelectedRows([]);
            if (isApprovePopup) togglePopupapprove();
        }
    };
    const exportTable = async () => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const downloadReqData = {
                dataTableRequest: {
                    isExport: true,
                },
            };
            let listURL = "";
            if (activeList === "1") listURL = "ManpowerIndents/List";
            else listURL = "ManpowerIndents/AllList";
            const result = await postRequest(listURL, downloadReqData);
            var element = document.createElement("a");
            element.setAttribute(
                "href",
                `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
            );
            element.setAttribute("download", `${props.title}`);
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        } catch (error) {
            dispatch(
                showToast({ text: "Error in downloading file", severity: "error" })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    };
    const childRef = useRef(null);
    function handleActivityClick() {
        childRef.current.updateState();
    }
    function handleActivityClose() {
        childRef.current.closeState();
    }
    const [activeList, setActiveList] = useState(
        localStorage.getItem(defaultList) ?? "1"
    );
    return (
        <div className="fullTableContainer">
            <OutsideClickHandler
                onOutsideClick={() => {
                    setFilterSearchToogle((prev) => {
                        if (prev) return false;
                        else return prev;
                    });
                }}
            >
                <div
                    className={`${filterSearchToogle
                        ? "filterSearchContainerActive filterSearchContainer"
                        : "filterSearchContainer"
                        }`}
                >
                    <div className="headingContainer">
                        <h3>Filter</h3>
                        <IconButton
                            onClick={() => setFilterSearchToogle((prev) => !prev)}
                            title="close"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18.7579 1.56897L0.757935 19.569"
                                    stroke="#F71D1D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M0.757935 1.56897L18.7579 19.569"
                                    stroke="#F71D1D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </IconButton>
                    </div>
                    <h5>Fields</h5>
                    <div className="filterListContainer">
                        <div className="filterContainer">
                            <p> Status </p>
                            <SelectCustom
                                options={status}
                                values={selectedStatusList}
                                onChange={(value) => {
                                    setSelectedStatusList(value);
                                }}
                                placeholder="Select Status"
                            />
                        </div>
                        <div className="filterContainer">
                            <p> Reason For Vacancy </p>
                            <SelectCustom
                                options={ReasonForVacancyOptions}
                                values={selectedReasonForVacancyList}
                                onChange={(value) => {
                                    setSelectedReasonForVacancyList(value);
                                }}
                                placeholder="Select Reason For Vacancy"
                            />
                        </div>
                        <div className="filterContainer">
                            <p>Date of Joining</p>
                            <DatePickerCustom
                                selected={dateOfJoning}
                                onChange={(date) => setDateOfJoining(date)}
                                placeholder="Select Date of Joining"
                            />
                        </div>
                        <div className="filterContainer">
                            <p>Created On</p>
                            <DateRangePicker
                                selectedEnd={createdOnEndDate}
                                selectedStart={createdOnStartDate}
                                onChangeStart={(date) => setCreatedOnStartDate(date)}
                                onChangeEnd={(date) => setCreatedOnEndDate(date)}
                            />
                        </div>
                        <div className="filterContainer">
                            <p>Last Updated On</p>
                            <DateRangePicker
                                selectedEnd={lastUpdatedOnEndDate}
                                selectedStart={lastUpdatedOnStartDate}
                                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
                            />
                        </div>
                    </div>
                    <div className="filterSearchFooterContainer">
                        <button className="button secondaryButton" onClick={resetFilters}>
                            Reset
                        </button>
                        <button className="button primaryButton" onClick={applyFilters}>
                            Apply
                        </button>
                    </div>
                </div>
            </OutsideClickHandler>
            <div className="tableHeaderContainer">
                {selectedRows.length > 0 ? (
                    <>
                        {activeList === "1" &&
                            (LoggedInUser?.permissions?.includes("Can Create Requisition?") ||
                                LoggedInUser?.isHRHead ||
                                LoggedInUser?.isHRDirector ||
                                LoggedInUser?.teamnames?.includes("Talent Acquisition")) && (
                                <button className="deletebtn" onClick={deleteSelected}>
                                    <span className="deletebtnflex">
                                        <p>Cancel</p>
                                        <span>{`(${selectedRows.length})`}</span>
                                    </span>
                                </button>
                            )}
                        {(activeList === "2" || activeList === "3") && (
                            <>
                                <button
                                    className="button primaryButton"
                                    onClick={() => togglePopupapprove("ApproveAll")}
                                >
                                    <span className="deletebtnflex">
                                        <p>Approve</p>
                                        <span>{`(${selectedRows.length})`}</span>
                                    </span>
                                </button>
                                <button
                                    className="deletebtn"
                                    onClick={() => togglePopupapprove("RejectAll")}
                                >
                                    <span className="deletebtnflex">
                                        <p>Reject</p>
                                        <span>{`(${selectedRows.length})`}</span>
                                    </span>
                                </button>
                            </>
                        )}
                    </>
                ) : (
                    (LoggedInUser?.isReportingManager ||
                        LoggedInUser?.permissions?.includes("Can Create Requisition?") ||
                        LoggedInUser?.isHRHead ||
                        LoggedInUser?.isHRDirector ||
                        LoggedInUser?.teamnames?.includes("Talent Acquisition")) && (
                        <button
                            onClick={() => {
                                navigate("/hrms/onboarding/offerletter/create");
                            }}
                            className="button primaryButton"
                            type="submit"
                        >
                            + Create {props.title}
                        </button>
                    )
                )}

                <OutsideClickHandler onOutsideClick={handleActivityClose}>
                    <div className="moreActivityLabelContainer cursor not-selectable">
                        <MoreActivityContainer
                            ref={childRef}
                            ActivityList={ActivityList}
                            onChange={handleActiveListChange}
                            activeList={activeList}
                        />
                        <div
                            className="moreActivityLabelHolder"
                            onClick={handleActivityClick}
                        >
                            <label style={{ color: "var(--primary)", fontWeight: 500 }}>
                                {
                                    ActivityList.find((x) => x.id.toString() === activeList)
                                        ?.actionTitle
                                }
                            </label>
                        </div>
                    </div>
                </OutsideClickHandler>

                <div className="search-box">
                    <input
                        className="search-text"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setSearchParam(e.target.value)}
                    />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="search-btn">
                        <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                                fill="#1296B0"
                            />
                        </svg>
                    </a>
                </div>
                <Tooltip
                    arrow
                    title={`${filterSearchToogle ? "Cancel" : "Filter"}`}
                    placement="top"
                >
                    <IconButton
                        color="primary"
                        onClick={() => {
                            setFilterSearchToogle((prev) => !prev);
                        }}
                    >
                        {!filterSearchToogle ? (
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                                    stroke="#1296B0"
                                    strokeWidth="1.5"
                                />
                            </svg>
                        ) : (
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18.7579 1.56897L0.757935 19.569"
                                    stroke="#F71D1D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M0.757935 1.56897L18.7579 19.569"
                                    stroke="#F71D1D"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title="Download" placement="top">
                    <IconButton color="primary" onClick={exportTable}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 21 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                                stroke="#1296B0"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </IconButton>
                </Tooltip>
            </div>
            <>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        {activeList === "1" && (
                            <TransactionTable

                                selectAll={selectAll}
                                tableFilterName="onboardingRequisitionFilter"
                                tbodyData={tbodyData}
                                headerAction={sortUpdated}
                                sortParam={sortParam}
                                sortType={sortType}
                                selectedRows={selectedRows}
                                addToSelectedRows={addToSelectedRows}
                                originalData={originalData}
                                containStatus={true}
                            />
                        )}
                    </>
                )}
                <div className="horizontalLine"></div>
                <div className="tableFooterContainer">
                    <label>Total Record Count: </label>
                    <span>{count}</span>
                    <SelectCustom
                        searchable={false}
                        options={noOfRecords}
                        dropdownPosition="top"
                        values={[recordsSelected]}
                        onChange={(e) => paginationChanged(e)}
                    />
                    <label>Records Per Page</label>
                    {currentPage > 1 ? (
                        <IconButton
                            onClick={() => pageChangedPrev()}
                            color="primary"
                            className="pageChangerIcon pageChangerIconActive"
                        >
                            <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                                    stroke="#BFBFBF"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </IconButton>
                    ) : (
                        <IconButton disabled color="primary" className="pageChangerIcon">
                            <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                                    stroke="#BFBFBF"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </IconButton>
                    )}
                    {currentPage} - {totalPages}
                    {totalPages > currentPage ? (
                        <IconButton
                            onClick={() => pageChangedNext()}
                            color="primary"
                            className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                        >
                            <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                                    stroke="#BFBFBF"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </IconButton>
                    ) : (
                        <IconButton
                            disabled
                            color="primary"
                            className={`pageChangerIcon pageChangerIconNext`}
                        >
                            <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                                    stroke="#BFBFBF"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </IconButton>
                    )}
                </div>
            </>

            {isOpen && (
                <Popup
                    popupHeading={typeAction}
                    content={
                        <>
                            <Remark
                                data={PostApRejHol}
                                type={typeAction}
                                id={selId}
                                color={colorAction}
                                handleClose={togglePopup}
                            />
                        </>
                    }
                    handleClose={togglePopup}
                />
            )}
            {isApprovePopup && (
                <ApprovePopup
                    popupHeading={typeAll}
                    selectedRows={selectedRows}
                    type={typeAll}
                    content={
                        <div style={{ display: "flex" }}>
                            {typeAll === "ApproveAll" ? (
                                <>
                                    <button
                                        className="button secondaryButton"
                                        onClick={togglePopupapprove}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="button primaryButton"
                                        onClick={() => {
                                            handleApprove(selectedRows, typeAll);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                </>
                            ) : (
                                <div style={{ width: "100%" }}>
                                    <Remark
                                        data={rejectAll}
                                        type={typeAll}
                                        id={selectedRows}
                                        color={"red"}
                                        handleClose={togglePopupapprove}
                                    />
                                </div>
                            )}
                        </div>
                    }
                    handleClose={togglePopupapprove}
                />
            )}
        </div>
    );
};
export { OfferLetter };

import React from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";
// import { useDispatch } from "react-redux";
// import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
// import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
// import { YesNoFormik } from "../../../../../Components/FormComponent/YesNoFormik";
// import { showToast } from "../../../../../Features";
// import { getRequest } from "../../../../../Services/axios";
// import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import CrossIcon from "../../../../../Assets/CrossChat.svg"

// import DocIconWhite from "../../../../../Assets/DocIconWhite.svg"
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
function Footer({ }) {
    return (
        <div style={{ padding: 20, paddingBottom: 10, paddingTop: 10, borderTop: "1px solid #e6e6e6", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "14%", }}>
            <p style={{ fontSize: 6, color: "grey", fontStyle: "italic" }}>
                {"Corp. Office-"}
                <br />
                {"Plot No. 13-14, Sector-5 , I.M.T Manesar,"}
                <br />
                {"Dist. Gurgaun, Pin - 122050 (Hr.)"}
                <br />
                {"Ph: 0124-4396900"}
                <br />
                {"email: info@askbrake.com"}
                <br />
                {"Website:"}
                <a href="www.askbrake.com">www.askbrake.com</a>
            </p>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", color: "grey" }}>
                <div style={{ marginLeft: 10, marginRight: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ fontSize: 8, fontWeight: "bold" }}>{"IA TF"}</p>
                    <p style={{ fontSize: 6 }}>{"16949:2016"}</p>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ fontSize: 8, fontWeight: "bold" }}>{"ISO"}</p>
                    <p style={{ fontSize: 6 }}>{"14001:2015"}</p>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ fontSize: 8, fontWeight: "bold" }}>{"ISO"}</p>
                    <p style={{ fontSize: 6 }}>{"9001:2015"}</p>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ fontSize: 8, fontWeight: "bold" }}>{"ISO"}</p>
                    <p style={{ fontSize: 6 }}>{"45001:2018"}</p>
                </div>
            </div>

            <p style={{ fontSize: 6, color: "grey", fontStyle: "italic" }}>
                {"Regd. Office"}
                <br />
                {"Flat No. 104, 929/1, Naiwala, "}
                <br />
                {"Faiz Road, Karol Bagh, "}
                <br />
                {"New Delhi, 110 005"}
                <br />
                {"Tel: 011-28758433, 28759605"}
                <br />
                {"Fax: 011-28752694, 45032488"}
                <br />
                {"CIN: U34300DL1988PTC030342"}
            </p>
        </div>
    )



}

export { Footer };

import React from "react";
const DetailsStep = ({ detailTitle, Details, clas, style, ...props }) => {
  return (
    <div className={clas ?? "detailDatas"} style={{ ...style }} {...props}>
      <label style={{ flex: 2 }}>{detailTitle ?? ""}</label>
      <label style={{ marginRight: 24 }}>:</label>
      <label style={{ flex: 3, }} className="detailsValue">{Details ?? ""}</label>
    </div>
  );
};
export { DetailsStep };

import React, { useEffect } from "react";
import InvestmentIcon from "../../../Assets/InvestmentIcon.svg";
import { AddRepeatedSectionComp } from "./AddRepeatedSectionComp";
import { RemoveRepeatedSectionComp } from "./RemoveRepeatedSectionComp";
import { FieldArray } from "formik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";

const InvestmentComp = ({
  heading,
  noteText,
  maxLimit,
  body,
  sortId,
  errors,
  erorr,
  editValues,
  compName,
  item,
}) => {
  const dispatch = useDispatch();
  const getExistingIds = (data, index) => {
    let idArray = [];
    data?.map((item, indexN) => {
      if (indexN !== index) {
        idArray.push(item.Title);
      }
      return null;
    });
    return idArray;
  };
  useEffect(() => {
    document.title = `PeopleSol -  Investment`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="investmentAllContainer">
      <div className="investmentHeading" style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <img
            style={{ height: "26px" }}
            src={InvestmentIcon}
            alt={InvestmentIcon}
          />
          <label style={{ paddingLeft: "10px", fontWeight: "500" }}>
            {heading ?? ""}
          </label>
        </div>
        <div style={{ paddingLeft: "10px", fontWeight: "500" }}>
          Max Limit : {item.maxLimit}
        </div>
      </div>
      <label style={{ padding: "0 20px", marginTop: "15px" }}>
        {" "}
        <b>{noteText ? "Note: " : ""}</b>
        {noteText ?? ""}
      </label>
      {console.log("------------------->", item)}
      <div style={{ width: "calc(100% - 20px)" }}>
        <FieldArray name={"Investments"}>
          {(fieldArrayProps) => {
            const { insert, remove, form } = fieldArrayProps;
            const { values } = form;
            return (
              <div>
                {/* {item.body.map((OtherInvestmentLists, index) => { */}
                {/* //console.log("ds",errors?.[compName]) */}
                {/* return ( */}
                <div style={{ display: "flex", paddingTop: "10px" }}>
                  <div
                    className="extendedInputContainer"
                    style={{ width: "100%" }}
                  >
                    <SelectForm
                      formikWidth={"50%"}
                      name={" dsa djsa dkj"}
                      style={{
                        border: "1px solid #E6E6E6 !important",
                        width: "50%",
                      }}
                      placeholder={"Select Investment"}
                      options={item.body}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <InputCustomFormik
                        style={{ border: "1px solid #E6E6E6" }}
                        placeholder="Investment Value"
                        name={"s dsa dsa"}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "12px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RemoveRepeatedSectionComp
                        clickHandler={() =>
                          2 > 1
                            ? remove(2)
                            : dispatch(
                              showToast({
                                text: "Atleast One Item is Required ",
                                severity: "error",
                              })
                            )
                        }
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AddRepeatedSectionComp
                        clickHandler={() =>
                          insert(2, { Title: "", Value: "" })
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* ); */}
                {/* })} */}
                <div className="totalError">
                  <div className="form-error">{typeof errors?.[compName] == "string" ? errors?.[compName] : ''}</div>
                  <div style={{ display: "flex" }}>
                    {" "}
                    <LabelCustom labelName={"Total Amount"} />
                    <LabelCustom labelName={":"} />
                    <LabelCustom
                      labelName={
                        <span
                          style={{
                            color: values[compName]
                              ?.reduce((sum, card) => sum + Number(card.Value), 0)
                              >= 150000
                              ? "red"
                              : "var(--primary)",
                            fontWeight: "500",
                          }}
                        >
                          {values[compName]
                            ?.reduce((sum, card) => sum + Number(card.Value), 0)
                            .toFixed(2)}
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

export { InvestmentComp };

import { IconButton } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";
import { getRequest } from "../../../Services/axios";

const HouseRentComp = ({ switchActive, values, updatedValues }) => {
  // Options for the metro selection
  const metroOptions = [
    { label: "Metro", value: "Metro" },
    { label: "Non-Metro", value: "Non-Metro" },
  ];
  const [stateOptions, setStateOpt] = useState([]);
  const getStates = async () => {
    const result = await getRequest("TaxDeclaration/GetStateLimit");
    if (result.data) {
      let list = result.data.results;
      let newList = [];
      for (let i = 0; i < list.length; i++) {
        newList.push({ label: list[i].text.split("-")[0], value: list[i].text })
      }
      setStateOpt(newList);
    }
  }

  useEffect(() => {
    getStates()
    document.title = `PeopleSol -  House Rent`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  // Options for the month selection
  return (
    <>
      {switchActive && (
        <div className="extendedParticularDetails">
          {/* Extended form container */}
          <div
            className="extendedHeaderContainer"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* Extended form heading */}
            {/* <label className="extendedHeading">House rent Details</label> */}
            {/* Button or icon for closing the form */}
          </div>
          <div className="extendedFormContainer">
            {/* Form fields */}


            {/* House rent paid per month field */}
            <div className="extendedFormComp">
              {/* House rent paid label */}
              <LabelCustom labelName={"State"} />
              {/* House rent paid input */}
              <div
                className="extendedInputContainer"
                style={{ flexDirection: "column" }}
              >
                <SelectForm
                  options={stateOptions}
                  style={{ border: "1px solid #E6E6E6" }}
                  placeholder="Select State"
                  name={"selectedStateLimit"}
                />
              </div>
            </div>

            <div className="extendedFormComp">
              {/* House rent paid label */}
              <LabelCustom labelName={"House Rent Paid Per Month"} />
              {/* House rent paid input */}
              <div
                className="extendedInputContainer"
                style={{ flexDirection: "column" }}
              >
                <InputCustomFormik
                  style={{ border: "1px solid #E6E6E6" }}
                  placeholder="Enter house Rent Paid"
                  name={"houseRentPaid"}
                  type="number"
                  min={0}
                  max={99999999}
                />
                <div>{""}</div>
                {/* Display total rent if houseRentPaid value exists */}
                {updatedValues?.houseRentPaid ? (
                  <label
                    style={{
                      fontSize: "11px",
                      fontWeight: "400",
                      paddingLeft: "15px",
                    }}
                  >
                    {`Total Rent: ₹${updatedValues?.houseRentPaid * 12}`}
                  </label>
                ) : null}
                {updatedValues.selectedStateLimit && parseFloat(updatedValues.selectedStateLimit.split("-")[1]) != NaN && (updatedValues?.houseRentPaid * 12) > parseFloat(updatedValues.selectedStateLimit.split("-")[1]) ? (
                  <label
                    style={{
                      fontSize: "11px",
                      fontWeight: "400",
                      paddingLeft: "15px",
                      color: "red"
                    }}
                  >
                    {`Total rent cannot be greater than limit amount`}
                  </label>
                ) : null}
              </div>
            </div>

            {/* Landlord address field */}

            {/* Landlord details fields */}
            <div className="extendedFormComp">
              {/* Landlord details label */}
              <LabelCustom labelName={"Landlord Name"} />
              {/* Landlord details inputs */}

              <InputCustomFormik
                style={{ border: "1px solid #E6E6E6" }}
                placeholder="Enter Landlord Name"
                name={"tenantName"}
              />
            </div>
          </div>

          <div className="extendedInputContainer">
            {/* Address label */}
            <div className="extendedFormComp">
              <LabelCustom labelName={"Rented House Address"} />
              {/* Address input */}
              <InputCustomFormik
                name="LandLordAddressOfProperty"
                formikStyle={"100%"}
                style={{
                  border: "1px solid #E6E6E6",
                }}
                placeholder="Enter Address"
              />
            </div>
            <div className="extendedFormComp">
              <LabelCustom labelName={"Landlord PAN"} />

              <InputCustomFormik
                style={{ border: "1px solid #E6E6E6" }}
                placeholder="Landlord PAN"
                name={"LandlordPan"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { HouseRentComp };

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import HomeDashboardBackground from "../../../Assets/HomeDashboardBackground.svg";
import ProfileAvatar from "../../../Assets/ProfileAvatar.svg";
// import "./HomePayroll.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useSelector } from "react-redux";
import { getInitialLetter } from "../../../Utilities/getInitialLetter";
// import { BoxView } from "../../../Components";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const CompanyTaxDashboard = () => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bannerList, setBannerList] = useState([]);
  const labels = [
    "Sep 2022",
    "Oct 2022",
    "Nov 2022",
    "Dec 2022",
    "Jan 2023",
    "Feb 2023",
    "Mar 2023",
    "Apr 2023",
    "May 2023",
    "Jun 2023",
    "Jul 2023",
    "Aug 2023",
  ];
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  const createBackGradient = () => {
    const ctx = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(138, 136, 237, 0.4)");
    gradient.addColorStop(1, "rgba(193, 54, 153, 0)");
    return gradient;
  };
  const createLineGradient = () => {
    const ctx = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(138, 136, 237, 1)");
    gradient.addColorStop(1, "rgba(193, 54, 153, 1)");
    return gradient;
  };
  useEffect(() => {
    document.title = `PeopleSol -  Tax Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const [chartData, setChartData] = useState({
    labels,
    datasets: [
      {
        label: "Pay Summary",
        data: labels.map(() => randomIntFromInterval(30000, 50000)),
        borderColor: createLineGradient(),
        backgroundColor: createBackGradient(),
        tension: 0.5,
        fill: true,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
  });

  const chart = () => {
    fetch("your-api-url")
      .then((res) => res.json())
      .then((data) => {
        const chartData = {
          labels: data.labels,
          datasets: [
            {
              label: "Data",
              data: [1, 2, 3, 4, 5],
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
          ],
        };
        setChartData(chartData);
      });
  };

  useEffect(() => {
    // chart();
  }, []);
  const getProfileData = async () => {
    try {
      const result = await getRequest("Employees/GetBannerProfile");
      // // //console.log('result', result);

      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("tax")) getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="fullTableContainer" style={{ overflow: "auto" }}>
      <div className={`homeDashboardContainer`} style={{ margin: "12px" }}>
        <div className="profileImgHolder" style={{ marginLeft: "-108px" }}>
          <img
            src={HomeDashboardBackground}
            alt="Profile"
            className="profileImgHolderbackground"
          />
          <div className="avatarContainer">
            {LoggedInUser?.image ? (
              <img
                src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.image}`}
                alt={"Profile"}
                className="imageInsideProgressBar"
              />
            ) : (
              <label
                className="childDisplayText"
                style={{ height: "100%", fontSize: "50px" }}
              >
                {LoggedInUser?.name
                  ? getInitialLetter(LoggedInUser?.name)
                  : LoggedInUser?.name?.slice(0, 2).toUpperCase()}
              </label>
            )}
          </div>
        </div>

        <div className="homeGreetingContainer">
          <h3>{LoggedInUser?.name?.split("(")[0]}</h3>
          <h4>{LoggedInUser?.personNumber}</h4>
          <div className="horizontalLinePrimary"></div>
          <h4>{LoggedInUser?.designation}</h4>
          {/* <div className="starContainer">
            <img src={RewardIconOrange} alt="RewardIconOrange" />
            <label>Star Performer of the Month</label>
          </div> */}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            justifyContent: "center",
            gap: "6px",
            padding: "12px",
            flexGrow: 1,
          }}
        >
          <div className="dashboardServicesType taxDashboardBox">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "#82C1AD",
                marginBottom: "3px",
              }}
            ></div>
            <p className="taxHeadingRight" style={{ color: "#555555" }}>
              No. of Employees
            </p>
            <p
              className="dashboardServicesName companyDash"
              style={{ color: "#555555", fontSize: "30px" }}
            >
              10,000
            </p>
          </div>
          <div className="dashboardServicesType taxDashboardBox">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "#B69FB3",
                marginBottom: "3px",
              }}
            ></div>
            <p className="taxHeadingRight" style={{ color: "#555555" }}>
              Monthly Declarations
            </p>
            <p
              className="dashboardServicesName companyDash"
              style={{ color: "#555555", fontSize: "30px" }}
            >
              10,000
            </p>
          </div>
          <div className="dashboardServicesType taxDashboardBox">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "#8A88ED",
                marginBottom: "3px",
              }}
            ></div>
            <p className="taxHeadingRight" style={{ color: "#555555" }}>
              Declaration Pending
            </p>
            <p
              className="dashboardServicesName companyDash"
              style={{ color: "#555555", fontSize: "30px" }}
            >
              20%
            </p>
          </div>
          <div className="dashboardServicesType taxDashboardBox">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "#0DB53C",
                marginBottom: "3px",
              }}
            ></div>
            <p className="taxHeadingRight" style={{ color: "#555555" }}>
              Submissions Pending
            </p>
            <p
              className="dashboardServicesName companyDash"
              style={{ color: "#555555", fontSize: "30px" }}
            >
              30%
            </p>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            justifyContent: "center",
            gap: "6px",
            padding: "12px",
            flexGrow: 1,
          }}
        >
          <div
            className="dashboardServicesType taxDashboardBox"
            style={{
              border: "2px solid #FFEEFA",
            }}
          >
            <p className="taxHeadingRight" style={{ color: "#DE64A1" }}>
              Last Month deduction
            </p>
            <p
              className="dashboardServicesName companyDash"
              style={{ color: "#555555", fontSize: "30px" }}
            >
              10,000
            </p>
          </div>
          <div
            className="dashboardServicesType taxDashboardBox"
            style={{
              border: "2px solid #EAF5FF",
            }}
          >
            <p className="taxHeadingRight" style={{ color: "#77A2C7" }}>
              Expected this Month Deduction
            </p>
            <p
              className="dashboardServicesName companyDash"
              style={{ color: "#555555", fontSize: "30px" }}
            >
              10,000
            </p>
          </div>
          <div
            className="dashboardServicesType taxDashboardBox"
            style={{
              border: "2px solid #FFF4E6",
            }}
          >
            <p className="taxHeadingRight" style={{ color: "#F7941D" }}>
              This Year Deduction till now
            </p>
            <p
              className="dashboardServicesName companyDash"
              style={{ color: "#555555", fontSize: "30px" }}
            >
              4,00,000
            </p>
          </div>
          <div
            className="dashboardServicesType taxDashboardBox"
            style={{
              border: "2px solid #EAF5FF",
            }}
          >
            <p className="taxHeadingRight" style={{ color: "#A0A0CB" }}>
              Expected This Year Deduction
            </p>
            <p
              className="dashboardServicesName companyDash"
              style={{ color: "#555555", fontSize: "30px" }}
            >
              4,00,000
            </p>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            width: "calc(65% - 24px)",
            boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
            borderRadius: "6px",
            margin: "12px",
          }}
        >
          <div className="headingContainer">
            <h5>Tax Summary</h5>
          </div>
          <div style={{ width: "100%", padding: "12px" }}>
            <Line
              data={chartData}
              width={"100%"}
              height={"480px"}
              options={{
                plugins: {
                  legend: false,
                  datalabels: {
                    backgroundColor: "white",
                    borderRadius: 4,
                    color: "#8A88ED",
                    font: {
                      weight: "bold",
                    },
                    formatter: Math.round,
                    padding: 6,
                    fill: true,
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 25000,
                    suggestedMax: 55000,
                  },
                },
                elements: {
                  point: {
                    radius: 4,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>
        <div
          style={{
            width: "calc(35% - 24px)",
            margin: "12px",
            height: "calc(100% - 24px)",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
              borderRadius: "6px",
              // marginTop: "24px",
            }}
          >
            <div className="headingContainer">
              <h5>Tax Deduction Summary</h5>
            </div>
            <div
              style={{
                padding: "12px",
                display: "flex",
                flexWrap: "wrap",
                gap: "12px",
                overflow: "auto",

                // height: "calc(100% - 50px)",
              }}
            >
              <div style={{ height: "fit-content" }} className="payrollSummaryContainer tdsSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>TDS</p>
                  <h5 style={{ color: "var(--primary-text)" }}>200000</h5>
                </div>
              </div>
              <div style={{ height: "fit-content" }} className="payrollSummaryContainer esicSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>ESIC</p>
                  <h5 style={{ color: "var(--primary-text)" }}>15000</h5>
                </div>
              </div>
              <div style={{ height: "fit-content" }} className="payrollSummaryContainer insuranceSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>
                    Insurance
                  </p>
                  <h5 style={{ color: "var(--primary-text)" }}>100000</h5>
                </div>
              </div>
              <div style={{ height: "fit-content" }} className="payrollSummaryContainer epfSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>EPF</p>
                  <h5 style={{ color: "var(--primary-text)" }}>3000</h5>
                </div>
              </div>
              <div style={{ height: "fit-content" }} className="payrollSummaryContainer gratuitySummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>
                    Gratuity
                  </p>
                  <h5 style={{ color: "var(--primary-text)" }}>1500</h5>
                </div>
              </div>
              <div style={{ height: "fit-content" }} className="payrollSummaryContainer loanSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>Loan</p>
                  <h5 style={{ color: "var(--primary-text)" }}>500000</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <h5 style={{ margin: "12px 0 0 24px" }}>Monthly Pay Out</h5>
      <BoxView
        tbodyData={tbodyData}
        actionData={actionData}
        headingTile="month"
        subTiles={["monthAmount", "deduction", "payDays", "leaveTaken"]}
        date="LastUpdatedOn"
        payroll={true}
      /> */}
    </div>
  );
};
export { CompanyTaxDashboard };

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useDeferredValue } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import Nodata from "../../Assets/Nodata.svg";
import "react-confirm-alert/src/react-confirm-alert.css";
import { IconButton, Tooltip } from "@mui/material";
import { isLoading, showToast } from "../../Features";
import { postRequest } from "../../Services/axios";
import { Loader } from "../../Components/FormComponent/Loader";
import { getStatusColor } from "../../Utilities/getStatusColor";
import { SelectCustom } from "../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../Components/FormComponent/DateRangePicker";
import OutsideClickHandler from "../../Components/OutsideClickHandler/OutsideClickHandler";
import { useSelector } from "react-redux";
import { DatePickerCustom } from "../../Components/FormComponent/DatePickerCustom";
export const MyAppraisalReviews = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState([]);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [dateOfJoning, setDateOfJoining] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedMode, setSelectedMode] = useState([]);
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const [selectedRequestType, setSelectedRequestType] = useState([]);
  const [processStartDate, setProcessStartDate] = useState(false);
  const [processEndDate, setProcessEndDate] = useState(false);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });
  useEffect(() => {
    document.title = `PeopleSol -  My Review `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const { LoggedInUser } = useSelector((state) => state.auth);

  const getAllListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `PMSAppraisalReview/MyAppraisalList`,
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            return tempData.push({
              ...item.item,
              actions: item?.actions,
            });
          });
          const clippedArray = tempData.map(
            ({
              createdBy,
              department,
              designation,
              email,
              employeeId,
              toLocation,
              toLocationName,
              ...rest
            }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              appraisalCriteriaName,
              name,
              ratingSlabName,
              processStartDate,
              processEndDate,
              selfReviewStartDate,
              selfReviewEndDate,
              appraisalCycleStartDate,
              appraisalCycleEndDate,
              actions,
              createdOn,
              lastUpdatedOn,
              ...rest
            }) => ({
              id,
              appraisalCriteriaName,
              name,
              ratingSlabName,
              processStartDate,
              processEndDate,
              selfReviewStartDate,
              selfReviewEndDate,
              appraisalCycleStartDate,
              appraisalCycleEndDate,
              actions,
              createdOn,
              lastUpdatedOn,
              ...rest,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const SubmmitAccept = async (id) => {
    try {
      setLoading(true);
      const result = await postRequest(
        `PMSPerformanceReview/SubmitAcceptance/?Id=${id}`
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getAllListData();
    }
  };
  console.log("object tbodyData", tbodyData);
  const location = useLocation();
  const applyFilters = () => {
    setReqData({
      ...reqData,
      status: selectedStatusList[0]?.value,
      type: selectedRequestType[0]?.value,
      mode: selectedMode[0]?.value,
      date: dateOfJoning
        ? `${format(dateOfJoning, "dd/MM/yyyy")} ${format(
          dateOfJoning,
          "dd/MM/yyyy"
        )}`
        : null,
      processStartDate: processStartDate
        ? `${format(processStartDate, "dd/MM/yyyy")}`
        : null,
      processEndDate: processEndDate
        ? `${format(processEndDate, "dd/MM/yyyy")}`
        : null,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${createdOnEndDate
          ? format(createdOnEndDate, "dd/MM/yyyy")
          : format(createdOnStartDate, "dd/MM/yyyy")
        }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${lastUpdatedOnEndDate
          ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
          : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
        }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setProcessEndDate(false);
    setProcessStartDate(false);
    setCreatedOnStartDate(false);
    setDateOfJoining(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setSelectedStatusList([]);
    setSelectedMode([]);
    setSelectedRequestType([]);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      processStartDate: null,
      processEndDate: null,
      createdOn: null,
      lastUpdatedOn: null,
      date: null,
      status: null,
      type: null,
      mode: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      var result = await postRequest(
        "PMSAppraisalReview/MyAppraisalList",
        downloadReqData
      );

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `My Appraisal Review`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      // // console.log(error);
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (
      location.pathname
        .toLowerCase()
        .includes("appraisalreview/myappraisalreview")
    )
      getAllListData();
  }, [location.pathname, reqData]);

  return (
    <div
      className="fullTableContainer"
      style={{ width: `${props.width}`, height: `${props.height}` }}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${filterSearchToogle
            ? "filterSearchContainerActive filterSearchContainer"
            : "filterSearchContainer"
            }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton onClick={() => setFilterSearchToogle((prev) => !prev)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            {/* <div className="filterContainer">
              <p> Status </p>
              <SelectCustom
                options={status}
                values={selectedStatusList}
                onChange={(value) => {
                  setSelectedStatusList(value);
                }}
              />
            </div> */}
            {/* <div className="filterContainer">
              <p>Travel Date</p>
              <DatePickerCustom
                selected={dateOfJoning}
                onChange={(date) => setDateOfJoining(date)}
              />
            </div> */}
            <div className="filterContainer">
              <p>Process Start Date</p>
              <DatePickerCustom
                selected={processStartDate}
                onChange={(date) => setProcessStartDate(date)}
                // maxDate={new Date()}
              />
            </div>
            <div className="filterContainer">
              <p>Process End Date</p>
              <DatePickerCustom
                selected={processEndDate}
                onChange={(date) => setProcessEndDate(date)}
                // maxDate={new Date()}
              />
            </div>
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div className="tableHeaderContainer">
        {/* <button
          onClick={() => {
            navigate("/pmsmaster/appraisalcycle/appraisalcycle/create");
          }}
          className="button primaryButton"
          type="submit"
        >
          + Create {props.title}
        </button> */}
        <div className="search-box">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip arrow title="Filter" placement="top">
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            {!filterSearchToogle ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Download" placement="top">
          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="boxViewContainer ">
            <div className="homeCardsHolderContainer">
              <div className="homeDashboardCardHolder" key={1}>
                {tbodyData?.length ? (
                  tbodyData?.map((item, index) => {
                    return (
                      <div className="dashboardCard internalJobCard">
                        <div
                          className="cardContainer cursor"
                          style={{ minHeight: "187px" }}
                        >
                          <div className="headingContainer">
                            <h3
                              style={{
                                fontSize: "16px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.name}
                            </h3>
                            <span
                              className="internalJobDateTime"
                              style={{ color: "var(--secondary)" }}
                            >
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    item.status
                                  )?.split(",")?.[0],
                                  color: getStatusColor(item.status)?.split(
                                    ","
                                  )?.[1],
                                  cursor: "default",
                                  padding: "3px 6px",
                                  width: "fit-content",
                                  borderRadius: "6px",
                                }}
                              >
                                {item.status}
                              </div>
                            </span>
                          </div>
                          <div
                            className="jobDetailContainerHolder"
                            style={{ padding: "7px 0px 23px 11px" }}
                          >
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                Process Start Date
                              </label>
                              <label>
                                {item?.processStartDate &&
                                  format(
                                    new Date(item?.processStartDate),
                                    "dd/MM/yyyy "
                                  )}
                              </label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                Process End Date
                              </label>
                              <label>
                                {item?.processEndDate &&
                                  format(
                                    new Date(item?.processEndDate),
                                    "dd/MM/yyyy "
                                  )}
                              </label>
                            </div>
                          </div>
                          <div
                            className="applyJobButtonContainer "
                            style={{ flexWrap: "wrap", amrginLeft: "12px" }}
                          >
                            {item?.actions?.map((itemData, index) => {
                              return (
                                (itemData?.type === "Self Review" ||
                                  itemData?.type === "View Details" ||
                                  itemData?.type === "Update Review") && (
                                  // itemData?.type === "Update Review" ||
                                  // itemData?.type === "Submit Acceptance" ||
                                  // itemData?.type === "View Rating"
                                  <button
                                    onClick={() =>
                                      itemData?.type === "View Details"
                                        ? navigate(
                                          `/pms/appraisalreview/myappraisalreview/details/${itemData?.id}`,
                                          {
                                            state: {
                                              item: item,
                                              name: item?.pmsName,
                                              employeeId: LoggedInUser?.id,
                                            },
                                          }
                                        )
                                        : itemData?.type === "Submit Acceptance"
                                          ? SubmmitAccept(itemData?.id)
                                          : itemData?.type === "View Rating"
                                            ? navigate(
                                              `/pms/appraisalreview/myappraisalreview/summary/${itemData?.id}`,
                                              {
                                                state: { item },
                                              }
                                            )
                                            : itemData?.type === "Update Review"
                                              ? navigate(
                                                `/pms/appraisalreview/myappraisalreview/details/${itemData?.id}`,
                                                // {
                                                //   state: {
                                                //     ID: itemData?.id,
                                                //     item,
                                                //     type: "Update Review",
                                                //   },
                                                // }
                                                {
                                                  state: {
                                                    ID: itemData?.id,
                                                    item: item,
                                                    name: item?.pmsName,
                                                    employeeId: LoggedInUser?.id,
                                                    type: "Update Review",
                                                  },
                                                }
                                              )
                                              : navigate(
                                                `/pms/appraisalreview/myappraisalreview/create/${itemData?.id}`,
                                                {
                                                  state: {
                                                    ID: itemData?.id,
                                                    item,
                                                  },
                                                }
                                              )
                                    }
                                    className="button primaryButton"
                                  >
                                    {itemData?.type === "Self Review"
                                      ? "Submit my Appraisal Review"
                                      : itemData?.type === "Update Review"
                                        ? "View & Edit my Appraisal Review"
                                        : itemData?.type}
                                  </button>
                                )
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Records"}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="tableFooterContainer">
            <label>Total Record Count: </label>
            <span>{count}</span>
            <SelectCustom
              searchable={false}
              options={noOfRecords}
              dropdownPosition="top"
              values={[recordsSelected]}
              onChange={(e) => paginationChanged(e)}
            />
            <label>Records Per Page</label>
            {currentPage > 1 ? (
              <IconButton
                onClick={() => pageChangedPrev()}
                color="primary"
                className="pageChangerIcon pageChangerIconActive"
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton disabled color="primary" className="pageChangerIcon">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
            {currentPage} - {totalPages}
            {totalPages > currentPage ? (
              <IconButton
                onClick={() => pageChangedNext()}
                color="primary"
                className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton
                disabled
                color="primary"
                className={`pageChangerIcon pageChangerIconNext`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

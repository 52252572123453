import React, { useEffect, useState } from "react";
import { FieldArray } from "formik";
import { TableBody, TableRow, Table, TableCell } from "@mui/material";
import { useDispatch } from "react-redux";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { showToast } from "../../../../Features";
import SurveyAnswersDeleteIcon from "../../../../Assets/SurveyAnswersDeleteIcon.svg";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
const CreateTaxStructureComp = ({ loading, setInitialValues, formValues }) => {
  const dispatch = useDispatch();
  const [financeYearOpt, setFinanceOpt] = useState([]);
  const getFinanceYear = () => {
    let startYear = new Date().getFullYear() - 10;
    let financialYears = [];
    while (true) {
      if (financialYears.length == 50) break;
      let a = JSON.stringify(startYear) + "-" + JSON.stringify(startYear + 1);
      financialYears.push({ label: a, value: a })
      startYear++;
    }
    setFinanceOpt(financialYears);
  }

  useEffect(() => { getFinanceYear() }, [])
  const pushSubComponent = (index, subDisplayName) => {
    setInitialValues((prev) => {
      return {
        ...formValues, // Copy previous state
        TaxStructureComponent: formValues.TaxStructureComponent.map((item, i) => {
          if (i === index) {
            // If this is the item we're updating
            return {
              ...item,
              SubComponents: [
                ...subDisplayName,
                { SubComponentName: "", SubLimitAmount: "" },
              ],
            };
          }
          return item; // Return unchanged items
        }),
      };
    });
  };

  const popSubComponent = (index, subIndex) => {
    setInitialValues((prev) => {
      return {
        ...formValues,
        TaxStructureComponent: formValues.TaxStructureComponent.map((item, i) => {
          if (i === index) {
            // If this is the item we're updating
            return {
              ...item,
              SubComponents: item.SubComponents.filter(
                (_, ind) => ind !== subIndex
              ),
            };
          }
          return item; // Return unchanged items
        }),
      };
    });
  };

  if (loading) return <Loader />;
  else
    return (
      <>
        <div className="formcustom threeColumn createSurveyComp">
          <div>{/* Render your other form fields here */}</div>
        </div>
        <div>
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              paddingTop: "12px",
            }}
            className="hideSurveyScrollBar"
          >

            <div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div style={{ width: "49%" }}>
                  <LabelCustom
                    mandatory={true}
                    labelName={`Financial Year`}
                  />
                  <SelectForm
                    options={financeYearOpt}
                    name={`FinancialYear`}
                    placeholder={"Enter Financial Year"}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <LabelCustom
                    mandatory={true}
                    labelName={`Tax Year`}
                  />
                  <InputCustomFormik
                    name={`TaxYear`}
                    placeholder={"Enter Tax Year"}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <LabelCustom
                    mandatory={true}
                    labelName={`Type Of Regime`}
                  />
                  <SelectForm
                    name="TypeOfRegime" placeholder={"Select Regime"} options={[{ label: "Old Regime", value: "Old Regime" }, { label: "New Regime", value: "New Regime" }]} />
                </div>
                <div style={{ width: "49%" }}>
                  <LabelCustom
                    labelName={`Description`}
                  />
                  <TextAreaFormik
                    maxLength={500}
                    placeholder={"Enter Description"}
                    name="Description"
                  />
                </div>
              </div>
              <div>
                <div style={{ width: "100%" }}>

                  <FieldArray name="TaxStructureComponent">
                    {(fieldArrayProps) => {
                      const { remove, push, form } = fieldArrayProps;
                      const { values } = form;
                      const { TaxStructureComponent } = values;
                      return (
                        <>
                          {TaxStructureComponent.map((item, index) => (
                            <React.Fragment key={index}>
                              <div
                                style={{
                                  background: "white",
                                  marginBottom: 20,
                                  borderRadius: 8,
                                  boxShadow: "0px 2px 10px 0px #0000000F",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    background: "var(--primary-hover)",
                                    // paddingBottom: "16px",
                                    borderRadius: 8,

                                    marginBottom: -20,
                                    width: "100%"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%"
                                      // justifyContent: "space-between",
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={{
                                        padding: "0 0 16px 0",
                                        borderBottom: "none",
                                        width: "45%",
                                        paddingLeft: 30
                                      }}
                                    >
                                      <LabelCustom labelName={"Investment Type"}
                                        mandatory={true}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        border: "none",
                                        padding: "0 0 16px 0",
                                        borderBottom: "none",
                                        width: "26.5%"

                                      }}
                                    >
                                      {" "}
                                      <LabelCustom labelName={"Display Name"}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        border: "none",
                                        padding: "0 0 16px 0",
                                        borderBottom: "none",
                                        width: "13.5%"
                                      }}
                                    >
                                      {" "}
                                      <LabelCustom labelName={"Permisible Limit"}
                                        mandatory={true}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        padding: "0 0 16px 0",
                                        borderBottom: "none",
                                        width: "15%"
                                      }}
                                    >
                                      <LabelCustom labelName={"Add/Remove"} />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    borderTop: "none !important",
                                    padding: "0 0 16px 0",
                                    borderBottom: "none",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      background: "var(--primary-hover)",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "45%",
                                      }}
                                    >
                                      <LabelCustom
                                        labelName={`${index + 1}.`}
                                      />
                                      <InputCustomFormik
                                        formikWidth={"100%"}
                                        name={`TaxStructureComponent[${index}].ComponentName`}
                                        placeholder={"Enter component name"}
                                      />
                                    </div>
                                    <div style={{ width: "26.5%" }}>
                                      <InputCustomFormik
                                        name={`TaxStructureComponent[${index}].DisplayName`}
                                        placeholder={"Enter display name"}
                                      />
                                    </div>
                                    <div style={{ width: "13.5%" }}>

                                      <InputCustomFormik
                                        name={`TaxStructureComponent[${index}].LimitAmount`}
                                        placeholder={"Enter limit amount"}
                                        type={"number"}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        // textAlign: "center",
                                        border: "none!",
                                        width: "15%",
                                        paddingLeft: 10
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                          TaxStructureComponent.length > 1
                                            ? remove(index)
                                            : dispatch(
                                              showToast({
                                                text: "Atleast One Item is Required ",
                                                severity: "error",
                                              })
                                            )
                                        }
                                      >
                                        -
                                      </button>
                                      <button
                                        type="button"
                                        className="add-btn"
                                        onClick={() =>
                                          push({
                                            ComponentName: "",
                                            LimitAmount: "",
                                          })
                                        }
                                      >
                                        +
                                      </button>
                                    </div>

                                  </div>
                                  <div
                                    style={{
                                      padding: "0 0 16px 0",
                                      borderBottom: "none",
                                    }}
                                  >
                                    {item.SubComponents &&
                                      item.SubComponents.map(
                                        (subItem, subIndex) => (
                                          <div
                                            key={subIndex}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              // paddingLeft: "32px",
                                            }}
                                          >
                                            <div style={{ width: "44%", display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: "32px", }}>
                                              <LabelCustom
                                                labelName={`${String.fromCharCode(
                                                  97 + subIndex
                                                )}.`}
                                              />
                                              <InputCustomFormik
                                                style={{
                                                  border: ".75px solid #E6E6E6",
                                                }}
                                                formikWidth={"100%"}

                                                name={`TaxStructureComponent[${index}].SubComponents[${subIndex}].SubComponentName`}
                                                placeholder={
                                                  "Enter subcomponent name"
                                                }
                                              />
                                            </div>
                                            <div style={{ width: "27.5%", paddingLeft: "10px", }}>
                                              <InputCustomFormik
                                                style={{
                                                  border: ".75px solid #E6E6E6",
                                                }}
                                                name={`TaxStructureComponent[${index}].SubComponents[${subIndex}].SubDisplayName`}
                                                placeholder={
                                                  "Enter subcomponent display name"
                                                }
                                              />
                                            </div>
                                            <div style={{ width: "13.5%", }}>
                                              <InputCustomFormik
                                                style={{
                                                  border: ".75px solid #E6E6E6",
                                                }}
                                                name={`TaxStructureComponent[${index}].SubComponents[${subIndex}].SubLimitAmount`}
                                                placeholder={
                                                  "Enter sublimit amount"
                                                }
                                                type={"number"}
                                              />
                                            </div>
                                            <div style={{ paddingLeft: 12 }}>
                                              {" "}
                                              <div
                                                type="button"
                                                onClick={() => {
                                                  if (item.SubComponents.length == 1) {
                                                    dispatch(
                                                      showToast({
                                                        text: "Atleast One Item is Required ",
                                                        severity: "error",
                                                      })
                                                    )
                                                  } else {
                                                    popSubComponent(
                                                      index,
                                                      subIndex
                                                    )
                                                  }
                                                }
                                                }
                                              >
                                                <img
                                                  style={{ width: 30, height: 30 }}
                                                  src={SurveyAnswersDeleteIcon}
                                                  alt="SurveyAnswersDeleteIcon"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}

                                  </div>
                                  <div
                                    className="surveyAddBtnContainer"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: 'flex-end',
                                      width: "100%",
                                      paddingRight: "11%"
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="add-survey-btn"
                                      onClick={() =>
                                        pushSubComponent(
                                          index,
                                          item.SubComponents || []
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                    <span>Add sub-component</span>
                                  </div>
                                </div>


                              </div>
                            </React.Fragment>
                          ))}
                        </>
                      );
                    }}
                  </FieldArray>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export { CreateTaxStructureComp };

import { FieldArray } from "formik";

import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TeamsFormIt = ({ data, editID }) => {
  const [locationValuesArray, setLocationValuesArray] = React.useState({});
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(editID ? 0 : 1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };

  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);
  React.useEffect(() => {
    // if (editID) {
    //   setPage(0);
    // } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (editID) {
      // setPage(0);

      const idArray =
        data?.itsmHelpdeskTeammembers &&
        data?.itsmHelpdeskTeammembers?.map((item, index) => item.employeeId);

      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data]);

  React.useEffect(() => {
    setListData([]);
    getLocation();
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);
  const [location, setLocation] = React.useState([]);
  const getLocation = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setLocation(listData);
    } catch (error) {
    } finally {
    }
  };

  const getLocationValues = (index) => {
    return locationValuesArray[index];
  };
  React.useEffect(() => {
    if (location.length >= 0) {
      data?.itsmHelpdeskTeammembers?.map((item, index) => {
        let loactionIdArray = [];
        item.itsmHelpdeskTeammemberlocations.map((loction) => {
          let tempVal = location?.filter(
            (options) => options?.value === loction.locationId
          );
          return loactionIdArray.push(tempVal[0]);
        });
        setLocationValuesArray((prev) => {
          return { ...prev, [index]: loactionIdArray };
        });
        return null;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Teams `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={50}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>

      <div className="repeatedSectionContainer">
        <FieldArray name="itsmHelpdeskTeammembers">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { itsmHelpdeskTeammembers } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Sl. No."} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Employee"} mandatory={true} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Location"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Level"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {itsmHelpdeskTeammembers.map(
                    (itsmHelpdeskTeammember, index) => (
                      <TableBody key={index}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell>
                          <SelectForm
                            name={`itsmHelpdeskTeammembers[${index}].employeeId`}
                            onMenuScrollToBottom={handleScrollToBottom}
                            options={searchParamemployee ? option : listData}
                            onInputChange={(e) => setSearchParamemployee(e)}
                          />
                        </StyledTableCell>

                        <StyledTableCell>
                          <SelectFormikMulti
                            name={`itsmHelpdeskTeammembers[${index}].locationIds`}
                            values={getLocationValues(index)}
                            options={location}
                            placeholder={" Select "}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            type="number"
                            step="0.01"
                            min={1}
                            max={999999}
                            name={`itsmHelpdeskTeammembers[${index}].level`}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              itsmHelpdeskTeammembers.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                employeeId: "",
                                level: "",
                                locationIds: [],
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    )
                  )}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
export { TeamsFormIt };

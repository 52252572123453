import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { BoxView, Table } from "../../../Components/index";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import {
  postRequest,
  deleteWithPayloadRequest,
  getRequest,
} from "../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { format } from "date-fns";
import { DateRangePicker } from "../../../Components/FormComponent/DateRangePicker";
import { YesNoCustom } from "../../../Components/FormComponent/YesNoCustom";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import { Loader } from "../../../Components/FormComponent/Loader";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { RadioCustom } from "../../../Components/FormComponent/RadioCustom";
import { Rewardsubtab } from "./Rewardsubtab";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import { TransactionTablePMS } from "../../../Components/Table/TransactionTablePMS";
import ApproveIcon from "../../../Assets/ApproveIcon.svg";
import HoldIcon from "../../../Assets/HoldIcon.svg";
import RejectIcon from "../../../Assets/RejectIcon.svg";
import CancelIcon from "../../../Assets/CancelIcon.svg";



const noOfRecords = [ 
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];

const RewardsTab = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [boxView, setBoxView] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bandList, setBandList] = useState([]);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedBandList, setSelectedBandList] = useState([]);
  const [activ, setActiv] = useState(null);
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [boxData, setBoxData] = useState([])
  const [reqData, setReqData] = useState({
    categories: null,
    types: null,
    isActive: activ,
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const [openpopup, setpopupsstate] = useState(false);
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState(false);

  const togglePoppup = (prev) => {
    setpopupsstate((prev) => !prev);
    console.log('toggle_button', prev);
  }
  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setIsOpen((prev) => !prev);
  };

  const handleDelete = async (selectedRows) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await deleteWithPayloadRequest(
        `Awards/DeleteMultiple`,
        selectedRows
      );
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      getListData();
    }
  };

  useEffect(() => {
    document.title = `PeopleSol -  Rewards`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const clickHandlerFunction = (id, type, color, itemId, deptId, locationId) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Ready for Release" &&
      type !== "HRReject" &&
      type !== "Publish" &&
      type !== "Confirm" &&
      type !== "Send To HOD" &&
      type !== "View Status"
    ) {
      togglePopup(type, color, id);
    } else if (type === "View Status") {
      navigate("/pmsmaster/rewards/quantitative/viewstatus", {
        state: { id: id, deptId: deptId, locationId: locationId },
      });
    } else if (type === "Confirm") {
      navigate("/pmsmaster/goalsetting/appraisalcriteria/confirm", {
        state: { id },
      });
    } else {
      PostApRejHol(type, id, "", itemId);
    }
  };

  const PostApRejHol = async (type, id, Remark, itemId) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "HRReject"
      ) {
        await getRequest(`pmsAppraisalCriteria/${type}/${id}`);
      } else if (type === "Publish") {
        await getRequest(`RewardsTransController/Publish?ID=${itemId}`);
      } else if (type === "Ready for Release") {
        await getRequest(`TravelHospitalityRequests/HRApprove/${id}`);
      } else if (type === "Send To HOD") {
        await getRequest(`RewardsTransController/SendToHOD?ID=${id}`);
      } else {
        await getRequest(`TravelHospitalityRequests/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getListData();
    }
  };

  const deleteSelected = () => {
    if (selectedRows.length === 1) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <DeletePopup
              onClose={onClose}
              message={"Are you sure you want to delete this Reward?"}
              onClick={() => handleDelete(selectedRows)}
            />
          );
        },
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <DeletePopup
              onClose={onClose}
              message={`Are you sure you want to delete these ${selectedRows.length} Rewards?`}
              onClick={() => handleDelete(selectedRows)}
            />
          );
        },
      });
    }
  };

  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };

  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Reward_ConfigController/List", reqData);
      if (result?.data?.summary?.allCount > 0) {
        let tempData = [];
        result.data.data.map((item) =>
          tempData.push({
            ...item.item,
            actions: getMyListActions(item, item?.item?.id),
            moreActions: getMoreActions(item?.actions),
            // NumberOfWinners: item?.NoOfWinners,
            isLinkIncentive: item?.item?.link_incentive,
            isPmsLinked: item?.item?.link_pms,
            rewardsEndDate: format(new Date(item?.item?.reward_Enddate), "dd/MM/yyyy"),
            rewardsStartDate: format(new Date(item?.item?.reward_Startdate), "dd/MM/yyyy"),
            awardAnnouncementDate: format(new Date(item?.item?.award_announcement_date), "dd/MM/yyyy"),
          })
        );
        console.log("tempData", tempData);
        setOriginalData(tempData);
        const finalArray = tempData?.map(
          ({
            id,
            title,
            awardAnnouncementDate,
            description,
            isLinkIncentive,
            isPmsLinked,
            rewardsEndDate,
            rewardsStartDate,
            status,
            createdOn,
            lastUpdatedOn,
          }) => ({
            id,
            title,
            awardAnnouncementDate,
            description,
            isLinkIncentive,
            isPmsLinked,
            rewardsEndDate,
            rewardsStartDate,
            status,
            createdOn,
            lastUpdatedOn,
          })
        );
        const finalArrayA = tempData?.map(
          ({
            id,
            title,
            awardAnnouncementDate,
            description,
            isLinkIncentive,
            isPmsLinked,
            rewardsEndDate,
            rewardsStartDate,
            status,
            createdOn,
            lastUpdatedOn,
            moreActions,
          }) => ({
            id,
            title,
            awardAnnouncementDate,
            description,
            isLinkIncentive,
            isPmsLinked,
            rewardsEndDate,
            rewardsStartDate,
            status,
            createdOn,
            lastUpdatedOn,
            moreActions,
          })
        );

        setTbodyData(finalArray);
        setBoxData(finalArrayA)
      } else {
        setTbodyData(false);
      }
      setCount(result.data.count);
      calculateTotalPages(result.data.count);
      setSelectedRows([]);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  console.log("tbodyData", tbodyData)
  console.log("boxdata", boxData)
  const getBandListFilter = async () => {
    try {
      const result = await getRequest("Awards/GetAwardCycles");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setBandList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("rewards")) getListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };

  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);

  const getFormDetails = (id) => {
    navigate(`/pmsmaster/rewards/quantitative/Details/${id}`, { state: { id: id } });
  };

  const handleEdit = (id) => {
    navigate("/pmsmaster/rewards/quantitative/create", { state: { id: id, type: "quantitative" } });
  };

  const getMyListActions = (data, itemId) => {
    // console.log("object 2222222222222222222222222222", data);
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(id),
      },
    ];
    // if (data?.actions?.length > 0) {
    //   data?.actions?.map((action) => {
    //     if (action.type === "Publish") {
    //       returnActionArray.push({
    //         img: (
    //           <svg
    //             width="21"
    //             height="21"
    //             viewBox="0 0 21 21"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <rect
    //               x="0.75"
    //               y="0.23941"
    //               width="20"
    //               height="20"
    //               rx="4"
    //               fill="#D8F0F2"
    //             ></rect>
    //             <path
    //               d="M6.25 10.2394L9.25 13.2394L16.25 7.23941"
    //               stroke="#1296B0"
    //               stroke-linecap="round"
    //             ></path>
    //           </svg>
    //         ),
    //         action: "Publish Request",
    //         clickAction: () =>
    //           clickHandlerFunction(
    //             action?.id,
    //             "Publish",
    //             "rgb(255, 128, 0)",
    //             itemId
    //           ),
    //       });
    //     } else if (action.type === "Approve") {
    //       returnActionArray.push({
    //         img: <img src={ApproveIcon} alt="Approve" />,
    //         action: "Approve Request",
    //         clickAction: () =>
    //           clickHandlerFunction(action?.id, "Approve", "rgb(255, 128, 0)"),
    //       });
    //     } else if (action.type === "Reject") {
    //       returnActionArray.push({
    //         img: <img src={RejectIcon} alt="Reject" />,
    //         action: "Reject Request",
    //         clickAction: () =>
    //           clickHandlerFunction(action?.id, "Reject", "red"),
    //       });
    //     } else if (action.type === "Hold") {
    //       returnActionArray.push({
    //         img: <img src={HoldIcon} alt="Hold" />,
    //         action: "Hold Request",
    //         clickAction: () =>
    //           clickHandlerFunction(action?.id, "Hold", "rgb(255, 128, 0)"),
    //       });
    //     }
    //     return null;
    //   });
    // }
    if (
      data?.item?.status === "Target not Set"
    ) {
      returnActionArray.push({
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
              fill="#FB7158"
            />
            <path
              d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
              stroke="#FB7158"
              strokeWidth="0.698594"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ),
        action: "Edit Request",
        clickAction: (id) => handleEdit(id),
      });
      // returnActionArray.push({
      //   img: <img src={CancelIcon} alt="Cancel" />,
      //   action: "Cancel Request",
      //   clickAction: (id) => {
      //     cancelRequest(id);
      //   },
      // });
    }
    return returnActionArray;
  };

  const actionData = [
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
            fill="#0DB53C"
          />
          <line
            x1="14.7295"
            y1="7.43701"
            x2="7.72949"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="7.43701"
            x2="5.61462"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="11.3437"
            x2="7.72949"
            y2="11.3437"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="11.3437"
            x2="5.61462"
            y2="11.3438"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="15.2505"
            x2="7.72949"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="15.2505"
            x2="5.61462"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Course Details",
      clickAction: (id) => getFormDetails(id),
    },
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
            fill="#FB7158"
          />
          <path
            d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
            stroke="#FB7158"
            strokeWidth="0.698594"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      action: "Edit Course",
      clickAction: (id) => handleEdit(id),
    },
  ];

  const getMoreActions = (actionList) => {
    console.log("sandeep more action", actionList);
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (
          action.type !== "Approve" &&
          action.type !== "Reject" &&
          action.type !== "Hold" &&
          action.type !== "Cancel"
        ) {
          returnActionArray.push({
            actionTitle: action.type,
            clickAction: () =>
              clickHandlerFunction(action?.id, action?.type, action?.color),
          });
        }
      });
    }
    return returnActionArray.length > 0 ? returnActionArray : null;
  };

  const cancelRequest = (id) => {
    clickHandlerFunction(id, "Cancel", "red");
  };

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData?.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData?.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      if (heading === "isLinkIncentive") heading = "link_incentive";
      else if (heading === "isPmsLinked") heading = "link_pms";
      else if (heading === "rewardsEndDate") heading = "reward_Enddate";
      else if (heading === "rewardsStartDate") heading = "reward_Startdate";
      else if (heading === "awardAnnouncementDate") heading = "award_announcement_date";

      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData?.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    let tempSelectedBandListIds = [];
    selectedBandList.map((item) => {
      return tempSelectedBandListIds.push(item.label);
    });
    setReqData({
      ...reqData,
      cycle:
        tempSelectedBandListIds.length > 0 ? tempSelectedBandListIds : null,
      isActive: activ,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${createdOnEndDate
          ? format(createdOnEndDate, "dd/MM/yyyy")
          : format(createdOnStartDate, "dd/MM/yyyy")
        }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${lastUpdatedOnEndDate
          ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
          : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
        }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setSelectedBandList([]);
    setActiv(null);
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      cycle: null,
      isActive: null,
      createdOn: null,
      lastUpdatedOn: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (count > recordsSelected?.value) {
      if (selectedRows?.length === recordsSelected?.value) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
          return arr.push(item.Id);
        });
        setSelectedRows(arr);
      }
    } else {
      if (selectedRows?.length === count) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
          return arr.push(item.Id);
        });
        setSelectedRows(arr);
      }
    }
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest("Awards/List", downloadReqData);
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  return (
    <div className="fullTableContainer">
      {/* Filter Right Data */}

      <div
        className={`${filterSearchToogle
          ? "filterSearchContainerActive filterSearchContainer"
          : "filterSearchContainer"
          }`}
      >
        <div className="headingContainer">
          <h3>Filter</h3>
        </div>
        <h5>Fields</h5>
        <div className="filterListContainer">
          <div className="filterContainer">
            <p>Award Cycle</p>
            <SelectCustom
              onDropdownOpen={() => {
                getBandListFilter();
              }}
              options={bandList}
              multi
              values={selectedBandList}
              onChange={(value) => {
                setSelectedBandList(value);
              }}
            />
          </div>
          <div className="filterContainer">
            <p>Is Active?</p>
            <YesNoCustom
              checkedyes={activ === true}
              checkedNo={activ === false}
              onChangeyes={(e) =>
                setActiv(e.target.value === "Yes" ? true : false)
              }
              onChangeNo={(e) =>
                setActiv(e.target.value === "Yes" ? true : false)
              }
            />
          </div>
          <div className="filterContainer">
            <p>Created On</p>
            <DateRangePicker
              selectedEnd={createdOnEndDate}
              selectedStart={createdOnStartDate}
              onChangeStart={(date) => setCreatedOnStartDate(date)}
              onChangeEnd={(date) => setCreatedOnEndDate(date)}
            />
          </div>
          <div className="filterContainer">
            <p>Last Updated On</p>
            <DateRangePicker
              selectedEnd={lastUpdatedOnEndDate}
              selectedStart={lastUpdatedOnStartDate}
              onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
              onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
            />
          </div>
        </div>
        <div className="filterSearchFooterContainer">
          <button className="button secondaryButton" onClick={resetFilters}>
            Reset
          </button>
          <button className="button primaryButton" onClick={applyFilters}>
            Apply
          </button>
        </div>
      </div>

      <div className="tableHeaderContainer">
        {selectedRows.length > 0 ? (
          <button className="deletebtn" onClick={() => deleteSelected()}>
            <span className="deletebtnflex">
              <p>Delete</p>
              <span>{`(${selectedRows.length})`}</span>
            </span>
          </button>
        ) : (
          <button
            onClick={togglePoppup}

            className="button primaryButton"
          >
            + Create {props.title}
          </button>
        )}
        <div className="search-box" >
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn" >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>

        <Tooltip arrow title="Filter" placement="top">

          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            {!filterSearchToogle ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Download" placement="top">

          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

          </IconButton>
        </Tooltip>

        <Tooltip arrow title={boxView ? "List View" : "Tile View"} placement="top">
          <svg
            style={{ scale: "1.3", marginLeft: "6px" }}
            width="37"
            className="cursor"
            height="21"
            viewBox="0 0 37 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setBoxView((prev) => !prev)}
          >
            {!boxView ? (
              <>
                <rect
                  x="0.700684"
                  y="0.71875"
                  width="36"
                  height="20"
                  rx="3"
                  fill="#D8F0F2"
                />
                <rect
                  x="2.04883"
                  y="2.29004"
                  width="33.3027"
                  height="16.8572"
                  rx="2"
                  fill="white"
                />
                <path
                  d="M35.3516 4.29004C35.3516 3.18547 34.4561 2.29004 33.3516 2.29004H18.475V19.1473H33.3516C34.4561 19.1473 35.3516 18.2518 35.3516 17.1473V4.29004Z"
                  fill="#1296B0"
                />
                <rect
                  x="5.58203"
                  y="5.96558"
                  width="3.75384"
                  height="3.75384"
                  rx="0.2"
                  stroke="#1296B0"
                  stroke-width="0.75"
                />
                <rect
                  x="11.6382"
                  y="5.96558"
                  width="3.75384"
                  height="3.75384"
                  rx="0.2"
                  stroke="#1296B0"
                  stroke-width="0.75"
                />
                <rect
                  x="5.58203"
                  y="11.718"
                  width="3.75384"
                  height="3.75384"
                  rx="0.2"
                  stroke="#1296B0"
                  stroke-width="0.75"
                />
                <rect
                  x="11.6382"
                  y="11.718"
                  width="3.75384"
                  height="3.75384"
                  rx="0.2"
                  stroke="#1296B0"
                  stroke-width="0.75"
                />
                <line
                  x1="22.3384"
                  y1="6.34375"
                  x2="32.2264"
                  y2="6.34375"
                  stroke="white"
                  stroke-width="0.75"
                  stroke-linecap="round"
                />
                <line
                  x1="22.3384"
                  y1="10.7202"
                  x2="32.2264"
                  y2="10.7202"
                  stroke="white"
                  stroke-width="0.75"
                  stroke-linecap="round"
                />
                <line
                  x1="22.3384"
                  y1="15.0969"
                  x2="32.2264"
                  y2="15.0969"
                  stroke="white"
                  stroke-width="0.75"
                  stroke-linecap="round"
                />
              </>
            ) : (
              <>
                <rect
                  x="0.604492"
                  y="0.71875"
                  width="36"
                  height="20"
                  rx="3"
                  fill="#D8F0F2"
                />
                <rect
                  x="1.95264"
                  y="2.29004"
                  width="33.3027"
                  height="16.8572"
                  rx="2"
                  fill="white"
                />
                <path
                  d="M1.95264 4.29004C1.95264 3.18547 2.84807 2.29004 3.95264 2.29004H18.8292V19.1473H3.95264C2.84807 19.1473 1.95264 18.2518 1.95264 17.1473V4.29004Z"
                  fill="#1296B0"
                />
                <rect
                  x="5.48584"
                  y="5.96558"
                  width="3.75384"
                  height="3.75384"
                  rx="0.2"
                  stroke="white"
                  stroke-width="0.75"
                />
                <rect
                  x="11.542"
                  y="5.96558"
                  width="3.75384"
                  height="3.75384"
                  rx="0.2"
                  stroke="white"
                  stroke-width="0.75"
                />
                <rect
                  x="5.48584"
                  y="11.718"
                  width="3.75384"
                  height="3.75384"
                  rx="0.2"
                  stroke="white"
                  stroke-width="0.75"
                />
                <rect
                  x="11.542"
                  y="11.718"
                  width="3.75384"
                  height="3.75384"
                  rx="0.2"
                  stroke="white"
                  stroke-width="0.75"
                />
                <line
                  x1="22.2422"
                  y1="6.34375"
                  x2="32.1302"
                  y2="6.34375"
                  stroke="#1296B0"
                  stroke-width="0.75"
                  stroke-linecap="round"
                />
                <line
                  x1="22.2422"
                  y1="10.7202"
                  x2="32.1302"
                  y2="10.7202"
                  stroke="#1296B0"
                  stroke-width="0.75"
                  stroke-linecap="round"
                />
                <line
                  x1="22.2422"
                  y1="15.0969"
                  x2="32.1302"
                  y2="15.0969"
                  stroke="#1296B0"
                  stroke-width="0.75"
                  stroke-linecap="round"
                />
              </>
            )}
          </svg>
        </Tooltip>

      </div>
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            {boxView ? (
              <div
                className="fullTableContainer"
                style={{ height: `calc(100% - 102px)` }}
              >
                <div className="boxViewContainer " style={{ height: "100%" }}>
                  <div className="homeCardsHolderContainer">
                    <div className="homeDashboardCardHolder" key={1}>
                      {boxData?.length ? (
                        boxData?.map((item, index) => {
                          return (
                            <div className="dashboardCard internalJobCard">
                              <div
                                className="cardContainer cursor"
                                style={{ minHeight: "187px" }}
                              >
                                <div className="headingContainer">
                                  <h3
                                    style={{
                                      fontSize: "16px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item?.title}
                                  </h3>
                                  <div
                                    style={{
                                      backgroundColor: getStatusColor(
                                        item.status
                                      )?.split(",")?.[0],
                                      color: getStatusColor(
                                        item.status
                                      )?.split(",")?.[1],
                                      cursor: "default",
                                      padding: "3px 6px",
                                      width: "fit-content",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {item.status}
                                  </div>
                                </div>
                                <div
                                  className="jobDetailContainerHolder"
                                  style={{ padding: "14px 0px 18px 11px" }}
                                >
                                  <div
                                    className="jobDetailContainer"
                                    style={{ width: "50%" }}
                                  >
                                    <div className="verticalLine"></div>
                                    <label className="jobDesignation">
                                      Start Date
                                    </label>
                                    <span
                                      className="internalJobDateTime"
                                      style={{ color: "var(--primary-text)" }}
                                    >
                                      <div
                                        style={{
                                          fontsize: "15px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {item.rewardsStartDate}
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    className="jobDetailContainer"
                                    style={{ width: "50%" }}
                                  >
                                    <div className="verticalLine"></div>
                                    <label className="jobDesignation">
                                      End Date
                                    </label>
                                    <span
                                      className="internalJobDateTime"
                                      style={{ color: "var(--primary-text)" }}
                                    >
                                      <div
                                        style={{
                                          fontsize: "15px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {item.rewardsEndDate}
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    className="jobDetailContainer"
                                    style={{ width: "50%" }}
                                  >
                                    <div className="verticalLine"></div>
                                    <label className="jobDesignation">
                                      Announcement Date
                                    </label>
                                    <span
                                      className="internalJobDateTime"
                                      style={{ color: "var(--primary-text)" }}
                                    >
                                      <div
                                        style={{
                                          fontsize: "15px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {item.awardAnnouncementDate}
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    className="jobDetailContainer"
                                    style={{ width: "50%" }}
                                  >
                                    <div className="verticalLine"></div>
                                    <label className="jobDesignation">
                                      Link with PMS
                                    </label>
                                    <span
                                      className="internalJobDateTime"
                                      style={{ color: "var(--primary-text)" }}
                                    >
                                      <div
                                        style={{
                                          fontsize: "15px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {item.isPmsLinked ? <p>Yes</p> : <p>No</p>}
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="applyJobButtonContainer "
                                  style={{
                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                    margin: "8px 12px",
                                  }}
                                >
                                  <div
                                    onClick={() => getFormDetails(item?.id)}
                                    style={{
                                      padding: "5px 0px",
                                      width: "35%",
                                      backgroundColor: "#F7941D",
                                      color: "white",
                                      fontSize: "13px",
                                      textAlign: "center",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    Details{" "}
                                  </div>

                                  {
                                  
                                    item?.status === "Target not Set" && 
                                  (

                                      <>
                                        {" "}
                                        <div
                                          style={{
                                            padding: "5px 0px",
                                            width: "25%",
                                            backgroundColor: "#FB7158",
                                            color: "white",
                                            fontSize: "13px",
                                            textAlign: "center",
                                            borderRadius: "4px",
                                          }}
                                          onClick={() => handleEdit(item?.id)}
                                        >
                                          Edit{" "}
                                        </div>
                                        <div
                                          style={{
                                            padding: "5px 0px",
                                            width: "35%",
                                            backgroundColor: "#1296B0",
                                            color: "white",
                                            fontSize: "13px",
                                            textAlign: "center",
                                            borderRadius: "4px",
                                          }}

                                          onClick={() =>
                                            clickHandlerFunction(
                                              item?.id,
                                              "Send To HOD"
                                            )
                                          }
                                        >
                                          Send to HOD{" "}
                                        </div>
                                      </>
                                    )}
                                  {item?.moreActions?.map((itemData, index) => {
                                    return (
                                      <>
                                        {itemData?.actionTitle === "View Status" && (
                                          <div
                                            style={{
                                              padding: "5px 32px",
                                              backgroundColor: "#1296B0",
                                              color: "white",
                                              fontSize: "13px",
                                              textAlign: "center",
                                              borderRadius: "4px",
                                            }}
                                            onClick={() =>
                                              clickHandlerFunction(
                                                item?.id,
                                                "View Status",
                                                "",
                                                "",
                                                item?.employee?.departmentId,
                                                item?.employee?.locationId,

                                              )
                                            }
                                          >
                                            View Status{" "}
                                          </div>
                                        )}
                                        {itemData?.actionTitle === "Publish" && (
                                          <div
                                            style={{
                                              padding: "6px 12px",
                                              backgroundColor: "#1296B0",
                                              color: "white",
                                              fontSize: "13px",
                                              textAlign: "center",
                                              borderRadius: "4px",
                                            }}
                                          onClick={() =>
                                            PostApRejHol(
                                              "Publish",
                                              itemData?.id,
                                              "",
                                              item?.id
                                            )
                                          }
                                          >
                                            Publish{" "}
                                          </div>
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="noDatastyle">
                          {/* <img
                            src={Nodata}
                            alt="No data"
                            style={{ height: "150px", width: "250px" }}
                          /> */}
                          <h4>{"No Records"}</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* <Table
                  selectAll={() => selectAll()}
                  tableFilterName="rewardsTableFilter"
                  addToSelectedRows={(Id) => addToSelectedRows(Id)}
                  selectedRows={selectedRows}
                  tbodyData={tbodyData}
                  actionData={actionData}
                  headerAction={sortUpdated}
                  sortParam={sortParam}
                  sortType={sortType}
                /> */}
                {console.log("=========================in create section original data", originalData)}

                <TransactionTablePMS
                  selectAll={selectAll}
                  tableFilterName="rewardsTableFilter"
                  tbodyData={tbodyData}
                  headerAction={sortUpdated}
                  sortParam={sortParam}
                  sortType={sortType}
                  selectedRows={selectedRows}
                  addToSelectedRows={addToSelectedRows}
                  originalData={originalData}
                  containStatus={true}
                  containTat={true}
                />
              </>
            )}
            <div className="horizontalLine"></div>
            <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords} dropdownPosition="top"
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
          </>
        )}
      </>
      {openpopup && (
        <Popup
          contentClassName="popsC"
          handleClose={togglePoppup}
          popupHeading={"Create Rewards"}
          content={<Rewardsubtab handleClose={togglePoppup} />}
        />
      )}
    </div>
  );
};
export { RewardsTab };

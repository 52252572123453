import React, { useCallback, useState } from "react";
import "./Connect.css";
import "./Community.css";
import SocialCommunityViewPageHeaderIcon from "../../../../../Assets/SocialCommunityViewPageHeaderIcon.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import SocialEditIcon from "../../../../../Assets/SocialEditIcon.svg";
import SocialCommunityNewProfileImage from "../../../../../Assets/SocialCommunityNewProfileImage.svg";
import SocialUserProfileBackBtnIcon from "../../../../../Assets/SocialUserProfileBackBtnIcon.svg";
import SocialCreateCommunityImageIcon from "../../../../../Assets/SocialCreateCommunityImageIcon.svg";
import SocialCommunityNewProfileCameraIcon from "../../../../../Assets/SocialCommunityNewProfileCameraIcon.svg";
import SocialNoPostFoundIcon from "../../../../../Assets/SocialNoPostFoundIcon.svg";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../../Services/axios";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import ArrowBackButton from "../../../../../Assets/ArrowBackButton.svg";
import { Form, Formik } from "formik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import SharePost from "./SharePost";
import EditSharePost from "./EditSharePost";
import EditMyPost from "./EditMyPost";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import {
  communityProfileImages,
  profileImages,
} from "../../../../../Utilities/profileAndUploadImages";
import SocialCommunityDeactivateGroupIcon from "../../../../../Assets/SocialCommunityDeactivateGroupIcon.svg";
import SocialCommunityLeavrGroupIcon from "../../../../../Assets/SocialCommunityLeavrGroupIcon.svg";
import SearchMagnifierIcon from "../../../../../Assets/SearchMagnifierIcon.svg";
import SocialPostTeamsIcon from "../../../../../Assets/SocialPostTeamsIcon.svg";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialPostOrganizationIcon from "../../../../../Assets/SocialPostOrganizationIcon.svg";
import CreateCommunityPost from "./CreateCommunityPost";
import * as Yup from "yup";
import dateFormat from "dateformat";
import {
  setCommunityData,
  setCommunityPostData,
} from "../../../../../Features/SocialSlice";
import {
  getFormattedCount,
  getPersonNumber,
  getUserInitials,
} from "../../../../../Utilities/socialAllFunctions";
import LikeDislike from "./LikeDislike";
import ShareDetails from "./ShareDetails";
import ShowViewsList from "./ShowViewsList";
import CommunityPostDetails from "./CommunityPostDetails";
import CommunitySharedEmployeePost from "./CommunitySharedEmployeePost";
import CommunityUnSharedPost from "./CommunityUnSharedPost";
import CommunitySharedPost from "./CommunitySharedPost";
import CommunityNestedSharedPost from "./CommunityNestedSharedPost";
import SocialLikeAndDislikeComponent from "./SocialLikeAndDislikeComponent";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import InfiniteScroll from "react-infinite-scroll-component";
import SocialNotificationItem from "./SocialNotificationItem";

const CommunityViewPage = ({ unreadPostCount }) => {
  const { communityid } = useParams();
  const [communityViewPageData, setCommunityViewPageData] = useState([]);
  const [communityNameData, setCommunityNameData] = useState(null);
  const [communityAboutData, setCommunityAboutData] = useState(null);
  const [editCommunityIsActive, setEditCommunityIsActive] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [isSocialPostDeletePopupActive, setIsSocialPostDeletePopupActive] =
    useState(false);
  const [deactivateCommunityActive, setDeactivateCommunityActive] =
    useState(false);

  const [editDeleteSocialPostId, setEditDeleteSocialPostId] = useState(0);
  const [editDeleteSocialPostData, setEditDeleteSocialPostData] = useState();
  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [shareDetailsActive, setShareDetailsActive] = useState(false);
  const [viewsPostId, setViewsPostId] = useState(0);
  const [viewsListActive, setViewsListActive] = useState(false);
  const [editEmployeeSelectActive, setEditEmployeeSelectActive] =
    useState(false);
  const { communityPostData } = useSelector((state) => state.social);
  const [page, setPage] = useState(1);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [presentEmployeeData, setPresentEmployeeData] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState([]);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [newCommunityProfileImg, setNewCommunityProfileImg] = useState(null);
  const [communityTypeActive, setCommunityTypeActive] = useState(false);
  const [communityType, setCommunityType] = useState("");
  const [isSharePosEditActive, setIsSharePosEditActive] = useState(false);
  const [isMyPosEditActive, setIsMyPosEditActive] = useState(false);
  const dispatch = useDispatch();
  const [leaveCommunityActive, setLeaveCommunityActive] = useState(false);
  const [leaveCommunityActiveByAdmin, setLeaveCommunityActiveByAdmin] =
    useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [postLoading, setPostLoading] = useState(false);
  const [commData, setCommData] = useState();
  const [searchCommData, setSearchCommData] = useState();
  const [selectedRecipient, setSelectedRecipient] = useState(0);
  const [employeeSelectionActive, setEmployeeSelectionActive] = useState(false);
  const [communityAdminAndMemberList, setCommunityAdminAndMemberList] =
    useState([]);
  const [postDataCount, setPostDataCount] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const [canManageSocial, setCanManageSocial] = useState(
    LoggedInUser?.permissions?.includes("Can Manage Social?")
  );
  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && communityPostData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 2);
  }, [loading, communityPostData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".homeEngageMainCenterContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
      dispatch(setCommunityPostData([]));
      setPostDataCount(-1);
      setPaginationData({
        total: 0,
        per_page: 5,
      });
      setTotalOffset(undefined);
      setOffset(0);
    };
  }, [communityid]);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 2)) &&
      !loading &&
      (postDataCount === -1 || communityPostData?.length < postDataCount)
    ) {
      getData();
    } else if (offset > totaloffset + 2) {
      setOffset(totaloffset + 2);
    }
  }, [offset, totaloffset, loading]);

  useEffect(() => {
    setOffset(0);
  }, []);
  function searchEditEmployee(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(JSON.stringify(selectEmployee));
      tempChatters = tempChatters.filter((d, i) =>
        d?.name?.toLowerCase()?.includes(e.currentTarget.value?.toLowerCase())
      );
      setAllEmployeeList(tempChatters);
    } else {
      setAllEmployeeList(selectEmployee);
    }
  }
  function searchChatters(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(
        JSON.stringify(searchCommData?.groupMembers)
      );
      tempChatters = tempChatters.filter((d, i) =>
        d?.name?.toLowerCase()?.includes(e.currentTarget.value?.toLowerCase())
      );
      setCommData({ ...searchCommData, groupMembers: tempChatters });
    } else {
      setCommData(searchCommData);
    }
  }
  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        `Social/GetEmployeesImageDropDown?i=${page}`
      );
      setAllEmployeeList(result?.data.textfilters);
      setSelectEmployee(result?.data.textfilters);
      setPresentEmployeeData(result?.data.textfilters);
      setPage((prev) => prev + 1);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const deactivateCommunityById = async () => {
    try {
      const result = await getRequest(
        `Community/DeleteCommunity?Id=${communityid}`
      );
      // getcommunityViewPageData();
      navigate("/home/social/community");
      getCommunityData();
      setDeactivateCommunityActive(false);
      dispatch(
        showToast({
          text: "Community deactivated successfully!",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in creating community",
          severity: "error",
        })
      );
      return;
    } finally {
    }
  };
  const leaveCommunityById = async () => {
    try {
      const result = await postRequest(
        `Community/LeftCommunity?CommunityId=${communityid}`
      );
      getcommunityViewPageData();
      setLeaveCommunityActive(false);
      dispatch(
        showToast({
          text: "Community left successfully!",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in creating community",
          severity: "error",
        })
      );
      return;
    } finally {
    }
  };
  const leaveCommunityByAdmin = async () => {
    setLoading(true);
    try {
      const result = await postRequest(
        `Community/LeftCommunity?CommunityId=${communityid}&CreatedBy=${selectedRecipient}`
      );
      getcommunityViewPageData();
      setLeaveCommunityActive(false);
      dispatch(
        showToast({
          text: "Community left successfully!",
          severity: "success",
        })
      );
      setEmployeeSelectionActive(false);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in creating community",
          severity: "error",
        })
      );
      return;
    } finally {
      setLoading(false);
    }
  };
  const editCommunity = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("Id", communityid);
      formData.append("CommunityName", communityNameData);
      formData.append("CommunityType", communityType);
      formData.append("About", communityAboutData);
      if (selectEmployee?.length > 0) {
        formData.append("Employees");
      }
      if (newCommunityProfileImg) {
        formData.append("Upload", newCommunityProfileImg);
      }
      const result = await postRequestForm("Community/EditCommunity", formData);
      await getcommunityViewPageData();
      setEditCommunityIsActive(false);
      setEditCommunityIsActive(false);
      setCommunityTypeActive(false);
      setCommunityType("");
      dispatch(
        showToast({
          text: "Community Created successfully!",
          severity: "success",
        })
      );
      getCommunityData();
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in creating community",
          severity: "error",
        })
      );
      return;
    } finally {
      setLoading(false);
    }
  };
  const handleFileChange = (event) => {
    if (event.target.files[0] === undefined) {
      setNewCommunityProfileImg(newCommunityProfileImg);
    } else {
      const file = event.target.files[0];
      setNewCommunityProfileImg(file);
    }
  };
  const validationSchema = Yup.object({
    communityName: Yup.string().required("Please Enter Community Name"),
    communityDescription: Yup.string().required(
      "Please enter description about your community"
    ),
  });
  const onSubmit = (values) => {
    setCommunityNameData(values.communityName);
    setCommunityAboutData(values.communityDescription);
    setCommunityTypeActive(true);
    setEditCommunityIsActive(false);
    // editCommunity(formData);
  };

  const deleteSocialPostById = async () => {
    try {
      const result = await getRequest(
        `SocialPost/Delete/?Id=${editDeleteSocialPostId}`
      );
      if (result?.status === 200 && result?.statusText === "OK") {
        getData();
        setIsSocialPostDeletePopupActive(false);
        dispatch(
          showToast({
            text: "Post Deleted Successfully",
            severity: "success",
          })
        );
        setEditDeleteSocialPostId(0);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in deleting post",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getData = async (freshLoad = false, reload = false) => {
    setPostLoading(true);
    try {
      const result = await postRequest(`SocialPost/AllPostList`, {
        createdOn: null,
        communityId: Number(communityid),
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: reload
            ? communityPostData?.length
            : postDataCount === -1 || offset + 2 <= postDataCount || freshLoad
              ? 2
              : postDataCount - offset,
          iDisplayStart: freshLoad || reload ? 0 : offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      let listData = [];
      result?.data?.data?.map((item) => {
        listData.push(item?.item);
      });

      dispatch(setCommunityPostData(listData));
      setPostDataCount(result?.data?.summary?.allCount);
      setTotalOffset(offset);
      if (freshLoad) {
        document.querySelector(".homeEngageMainCenterContainer").scrollTop = 0;
        dispatch(setCommunityPostData(listData));
        setOffset(0);
        setTotalOffset(0);
      } else {
        dispatch(setCommunityPostData([...communityPostData, ...listData]));
      }
      setCurrentIndex((currentIndex) => currentIndex + 2);
      setPaginationData({
        ...paginationData,
        total: result?.data?.summary.allCount,
        per_page: paginationData.per_page,
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setPostLoading(false);
    }
  };
  const getCommunityData = async () => {
    try {
      const result = await postRequest("Community/AllList", {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 100,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      dispatch(setCommunityData(result?.data?.data));
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getCommunityMemberDataForNewAdmin = async () => {
    setLoading(true);
    try {
      const result = await postRequest(`Community/AllList`, {
        createdOn: null,
        communityId: Number(communityid),
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 10,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      let listData = [];
      result?.data?.data?.map((item) => {
        listData.push(item?.item);
      });
      if (listData?.length > 0) {
        setCommData(listData[0]);
        setSearchCommData(listData[0]);
      }
      let updatedList = [];
      for (let i = 0; i < listData[0]?.groupMembers?.length; i++) {
        let isMemberAdmin = false;
        for (let j = 0; j < listData[0]?.groupAdmin?.length; j++) {
          if (
            listData[0]?.groupMembers[i]?.personId ===
            listData[0]?.groupAdmin[j]?.personId
          ) {
            isMemberAdmin = true;
            break;
          }
        }
        updatedList.push({ ...listData[0]?.groupMembers[i], isMemberAdmin });
      }
      updatedList.sort((a, b) => (b?.isMemberAdmin ? 1 : -1));
      setCommunityAdminAndMemberList(updatedList);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getcommunityViewPageData = async () => {
    setLoading(true);
    try {
      const result = await postRequest(`Community/AllList`, {
        createdOn: null,
        communityId: communityid,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 10,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      if (result?.data?.data?.length <= 0) {
        dispatch(
          showToast({
            text: "No Records!",
            severity: "error",
          })
        );
        navigate("/home/social/community");
        // return;
      }
      let listData = [];
      result?.data?.data?.map((item) => {
        listData.push(item?.item);
      });
      setCommunityViewPageData(listData);
      setCommunityType(listData[0]?.communityType);
      // setNewCommunityProfileImg(listData[0]?.communityImage);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  useEffect(() => {
    if (LoggedInUser?.id) {
      getcommunityViewPageData();
    }
  }, [communityid]);

  return (
    <>
      <div className="homeEngageMainCenterContainer">
        {!loading && (
          <>
            <div className="communityViewPageContainer">
              <div className="communityViewPageHeaderContainer">
                <img
                  src={SocialCommunityViewPageHeaderIcon}
                  alt=""
                  className="viewPageHeaderImg"
                />
                <div
                  className="communityViewPageBackeBtn"
                  onClick={() => {
                    navigate("/home/social/social");
                  }}
                >
                  <IconButton>
                    <img
                      src={SocialUserProfileBackBtnIcon}
                      alt=""
                      style={{ margin: "6px 1px", cursor: "pointer" }}
                    />
                  </IconButton>
                  <span>Back to Social</span>
                </div>
              </div>
              <div className="communityProfileContainer">
                <div>
                  {communityViewPageData[0]?.groupAdminId?.includes(
                    LoggedInUser?.id
                  ) && (
                      <img
                        src={SocialEditIcon}
                        alt=""
                        onClick={() => {
                          getcommunityViewPageData();
                          setEditCommunityIsActive(true);
                        }}
                        style={{
                          position: "absolute",
                          top: "170px",
                          left: "145px",
                          zIndex: "999",
                          cursor: "pointer",
                        }}
                      />
                    )}

                  <div
                    className={`${communityViewPageData[0]?.communityImage
                      ? ""
                      : "communityViewPageCustomProfilePic"
                      }`}
                  >
                    {!communityViewPageData[0]?.communityImage &&
                      communityViewPageData[0]?.communityName ? (
                      getUserInitials(communityViewPageData[0]?.communityName)
                    ) : (
                      <img
                        className="communityProfileImgContainer"
                        src={
                          process.env.REACT_APP_.BASE_URL +
                          communityProfileImages +
                          communityViewPageData[0]?.communityImage
                        }
                        alt="Profile"
                        onError={(e) => {
                          e.target.src = SocialCommunityGroupIcon;
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="communityExitOrLeaveContainer">
                  <div className="communityExitOrLeaveWrapper">
                    <h4>{communityViewPageData[0]?.communityName}</h4>
                    <div className="myCommunityListLeaveExitGroupContainer">
                      {(communityViewPageData[0]?.createdby ===
                        LoggedInUser?.id ||
                        canManageSocial) && (
                          <div className="myCommunityListLeaveExitGroupWrapper">
                            <div
                              className="deactivateGroupWrapper"
                              onClick={() => {
                                setDeactivateCommunityActive(true);
                              }}
                            >
                              <span>Deactive</span>
                              <img
                                src={SocialCommunityDeactivateGroupIcon}
                                alt=""
                              />
                            </div>
                            {communityViewPageData[0]?.createdby ===
                              LoggedInUser?.id && (
                                <div
                                  className="leaveGroupWrapper"
                                  onClick={() => {
                                    setLeaveCommunityActiveByAdmin(true);
                                  }}
                                >
                                  <span>Leave</span>
                                  <img src={SocialCommunityLeavrGroupIcon} alt="" />
                                </div>
                              )}
                          </div>
                        )}
                      {communityViewPageData[0]?.createdby !==
                        LoggedInUser?.id && (
                          <div className="myCommunityListLeaveExitGroupWrapper">
                            <div
                              className="leaveGroupWrapper"
                              onClick={() => {
                                setLeaveCommunityActive(true);
                              }}
                            >
                              <span>Leave</span>
                              <img src={SocialCommunityLeavrGroupIcon} alt="" />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="communityAboutContainer">
                <div className="communityAboutWrapper">
                  <h5>About</h5>
                  <p>{communityViewPageData[0]?.about}</p>
                </div>
                <div className="communityAboutText">
                  <p>Created On</p>
                  <span>
                    {dateFormat(
                      communityViewPageData[0]?.createdOn,
                      "hh:MM TT | dd mmm yyyy"
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="postUpdateContainerParent"
              style={{ padding: "0 3% 0 4%" }}
            >
              <div className="postUpdateContainer">
                <div
                  onClick={() => {
                    handleUserProfileClick(LoggedInUser?.id);
                  }}
                  className={`${LoggedInUser?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePic"
                    }`}
                >
                  {!LoggedInUser?.image ? (
                    getUserInitials(
                      getDisplayName(LoggedInUser?.nameWithPersonNumber)
                    )
                  ) : (
                    <img
                      className="socialProfilePic"
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        profileImages +
                        LoggedInUser?.image
                      }
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = ProfileAvatar;
                      }}
                    />
                  )}
                </div>

                <input
                  placeholder="What's on your mind?"
                  onClick={() => {
                    // setCreatePostActive(true);
                    navigate("create");
                  }}
                ></input>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "50px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={SocialCreateCommunityImageIcon}
                  alt=""
                  onClick={() => {
                    // setCreatePostActive(true);
                    navigate("create");
                  }}
                />
              </div>
            </div>
          </>
        )}
        {loading && <Loader />}
        {!postLoading && communityPostData?.length > 0 && (
          <div className="homeEngagePostContainer">
            {communityPostData.map((d, i) => {
              return (
                <div
                  className="socialAllPostContainer"
                  key={"social-item-" + i}
                >
                  <div className="engagePostContentHolder">
                    {d?.sharedEmployee !== null && (
                      <CommunitySharedEmployeePost
                        d={d}
                        setViewsListActive={setViewsListActive}
                        setViewsPostId={setViewsPostId}
                        LoggedInUser={LoggedInUser}
                        setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                        setSharePostData={setSharePostData}
                        setIsSocialPostDeletePopupActive={
                          setIsSocialPostDeletePopupActive
                        }
                        setEditDeleteSocialPostData={
                          setEditDeleteSocialPostData
                        }
                        setIsSharePosEditActive={setIsSharePosEditActive}
                        setIsMyPosEditActive={setIsMyPosEditActive}
                      />
                    )}
                    {d?.sharedPost === null && (
                      <CommunityUnSharedPost
                        d={d}
                        setViewsListActive={setViewsListActive}
                        setViewsPostId={setViewsPostId}
                        LoggedInUser={LoggedInUser}
                        setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                        setIsSocialPostDeletePopupActive={
                          setIsSocialPostDeletePopupActive
                        }
                        setEditDeleteSocialPostData={
                          setEditDeleteSocialPostData
                        }
                        setIsSharePosEditActive={setIsSharePosEditActive}
                        setIsMyPosEditActive={setIsMyPosEditActive}
                      />
                    )}
                    {d?.sharedPost && !d?.isNasted && (
                      <CommunitySharedPost
                        d={d}
                        setViewsListActive={setViewsListActive}
                        setViewsPostId={setViewsPostId}
                        LoggedInUser={LoggedInUser}
                        setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                        setIsSocialPostDeletePopupActive={
                          setIsSocialPostDeletePopupActive
                        }
                        setEditDeleteSocialPostData={
                          setEditDeleteSocialPostData
                        }
                        setIsSharePosEditActive={setIsSharePosEditActive}
                        setIsMyPosEditActive={setIsMyPosEditActive}
                      />
                    )}
                    {d?.sharedPost && d?.isNasted && (
                      <CommunityNestedSharedPost
                        d={d}
                        setViewsListActive={setViewsListActive}
                        setViewsPostId={setViewsPostId}
                      />
                    )}
                    <SocialLikeAndDislikeComponent
                      d={d}
                      setSharePostData={setSharePostData}
                      setSharePostId={setSharePostId}
                      setLikeId={setLikeId}
                      setTotalLikesWatching={setTotalLikesWatching}
                      setDislikeId={setDislikeId}
                      setTotalDisLikesWatching={setTotalDisLikesWatching}
                      setShareDetailsActive={setShareDetailsActive}
                      postData={communityPostData}
                      setPostData={setCommunityPostData}
                      getData={getData}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!postLoading && !loading && communityPostData?.length <= 0 && (
          <div className="noPostFoundContainer">
            <img src={SocialNoPostFoundIcon} alt="" />
            <div className="noPostFoundWrapper">
              <h5>No Post Found</h5>
              <span>No one has posted something in social.</span>
            </div>
          </div>
        )}
        {postLoading && <Loader />}
      </div>
      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}
      <Routes>
        <Route
          path="create"
          element={
            <CreateCommunityPost getData={getData} commentId={communityid} />
          }
        />
      </Routes>
      {deactivateCommunityActive && (
        <Popup
          firstClassName="communityLeaveDeactivePopupBox1"
          secondClassName="communityLeaveDeactivePopupBox2"
          popupHeading={`Leave this community`}
          content={
            <>
              <div className="communityLeaveDeactiveBody">
                <p>Are you sure want to deactive your community?</p>
              </div>
              <div className="socialPostDeleteBtn">
                <button onClick={deactivateCommunityById}>Deactive</button>
                <button
                  onClick={() => {
                    setDeactivateCommunityActive(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          }
          handleClose={() => {
            setDeactivateCommunityActive(false);
          }}
        />
      )}
      {leaveCommunityActive && (
        <Popup
          firstClassName="communityLeaveDeactivePopupBox1"
          secondClassName="communityLeaveDeactivePopupBox2"
          popupHeading={`Leave this community`}
          content={
            <>
              <div className="communityLeaveDeactiveBody">
                <p>
                  You will no longer receive updates from this community. Would
                  you still like to leave this community?
                </p>
              </div>
              <div className="socialPostDeleteBtn">
                <button onClick={leaveCommunityById}>Leave</button>
                <button
                  onClick={() => {
                    setLeaveCommunityActive(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          }
          handleClose={() => {
            setLeaveCommunityActive(false);
          }}
        />
      )}
      {leaveCommunityActiveByAdmin && (
        <Popup
          firstClassName="communityLeaveDeactivePopupBox1"
          secondClassName="communityLeaveDeactivePopupBox2"
          popupHeading={`Leave this community`}
          content={
            <>
              <div className="communityLeaveDeactiveBody">
                <p>
                  Your are the creator of the community, If you want to leave
                  please select creator for this Community
                </p>
              </div>
              <div className="socialPostDeleteBtn">
                <button
                  onClick={() => {
                    getCommunityMemberDataForNewAdmin();
                    setEmployeeSelectionActive(true);
                    setLeaveCommunityActiveByAdmin(false);
                  }}
                >
                  Next
                </button>
                <button
                  onClick={() => {
                    setLeaveCommunityActiveByAdmin(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          }
          handleClose={() => {
            setLeaveCommunityActiveByAdmin(false);
          }}
        />
      )}
      {editCommunityIsActive && (
        <Popup
          firstClassName="SelectCommunityMembers1"
          secondClassName="SelectCommunityMembers2"
          bodyClassName="SelectCommunityMembersBody "
          popupHeading={
            <div>
              <img
                src={ArrowBackButton}
                alt=""
                style={{ marginRight: "15px", cursor: "pointer" }}
                onClick={() => {
                  setEditCommunityIsActive(false);
                }}
              />
              <span>Edit Community</span>
            </div>
          }
          content={
            <>
              {!loading && (
                <div className="createCommunityMainContainer">
                  <div className="createCommunityDefaultProfileImgContainer">
                    <img src={SocialCommunityNewProfileImage} alt="" />
                    <div
                      className="createCommunityImageInputContainer"
                      onClick={() => {
                        document
                          .querySelector("#newGroupProfileImgFile")
                          .click();
                      }}
                    >
                      <img src={SocialCommunityNewProfileCameraIcon} alt="" />
                      <input
                        type="file"
                        id="newGroupProfileImgFile"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        accept=".jpg,.png,.gif,.jpeg,.jfif"
                      />
                    </div>
                    <div
                      className="editCommunityProfileImgContainer"
                      id="newGroupProfileImg"
                    >
                      {(newCommunityProfileImg !== null ||
                        communityViewPageData[0]?.communityImage) && (
                          <img
                            src={
                              newCommunityProfileImg
                                ? URL.createObjectURL(newCommunityProfileImg)
                                : process.env.REACT_APP_.BASE_URL +
                                communityProfileImages +
                                communityViewPageData[0]?.communityImage
                            }
                            alt=""
                          />
                        )}
                    </div>
                  </div>

                  <div className="createCommunityTextContainer">
                    <Formik
                      initialValues={{
                        communityName: communityViewPageData[0]?.communityName,
                        communityDescription: communityViewPageData[0]?.about,
                      }}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      <Form>
                        <LabelCustom
                          labelName={"Community Name"}
                          mandatory={true}
                        />
                        <InputCustomFormik
                          placeholder={"Enter Your Community Name"}
                          type="text"
                          name="communityName"
                        />
                        <LabelCustom
                          labelName={"Description"}
                          mandatory={true}
                        />
                        <TextAreaFormik
                          maxLength={500}
                          placeholder="What is the purpose of your community?"
                          style={{ height: "150px" }}
                          name="communityDescription"
                        />
                        <div
                          className="createFootCust"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <button
                            className="button primaryButton"
                            type="submit"
                          >
                            Next
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              )}
              {loading && <Loader />}
            </>
          }
          handleClose={() => {
            setEditCommunityIsActive(false);
            setNewCommunityProfileImg(null);
          }}
        />
      )}
      {communityTypeActive && (
        <Popup
          firstClassName="selectPostTypePopupBox1 selectCommunityTypePopupBox1"
          secondClassName="selectPostTypePopupBox2 selectCommunityTypePopupBox2"
          popupHeading="Select Community Type"
          content={
            <div className="selectPostTypeContainer">
              <div className="selectPostTypeWrapper">
                <div className="selectPostOrganizationContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img src={SocialPostOrganizationIcon} alt="" />
                    </article>
                    <span>Public</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={communityType === "public" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setCommunityType("public");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="selectPostTeamContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img
                        src={SocialPostTeamsIcon}
                        alt=""
                        style={{ height: "20px", paddingTop: "4px" }}
                      />
                    </article>
                    <span>Private</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={communityType === "private" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setCommunityType("private");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              {!loading && (
                <div
                  className="socialPostDeleteBtn"
                  style={{ marginTop: "20px" }}
                >
                  {communityType === "public" && (
                    <button
                      onClick={() => {
                        editCommunity();
                      }}
                    >
                      Save
                    </button>
                  )}
                  {communityType === "private" && (
                    <button
                      onClick={() => {
                        getEmployeeData();
                        setEditEmployeeSelectActive(true);
                        setCommunityTypeActive(false);
                      }}
                    >
                      Next
                    </button>
                  )}

                  <button
                    onClick={() => {
                      setCommunityTypeActive(false);
                      setEditCommunityIsActive(true);
                      setCommunityType("");
                    }}
                    style={{ border: "1px solid #1296B0", color: "#1296B0" }}
                  >
                    Back
                  </button>
                </div>
              )}
              {loading && (
                <div
                  style={{
                    fontWeight: "500",
                    color: "#bfbfbf",
                    paddingBottom: "20px",
                    paddingRight: "10px",
                  }}
                >
                  Saving...
                </div>
              )}
            </div>
          }
          handleClose={() => {
            setCommunityTypeActive(false);
            setCommunityType("");
          }}
        />
      )}
      {editEmployeeSelectActive && (
        <Popup
          firstClassName="SelectCommunityMembers1"
          secondClassName="SelectCommunityMembers2"
          contentClassName="SelectCommunityMemberContent"
          bodyClassName="SelectCommunityMembersBody"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setCommunityTypeActive(true);
                  setEditEmployeeSelectActive(false);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select Community Members</h5>
            </div>
          }
          content={
            <div className="newCommunityContainer">
              <div className="communityEmployeeSearchContainer">
                {selectedParticipant?.length > 0 && (
                  <div className="communitySelectedEmployeeContainer">
                    {selectedParticipant.map((item, index) => {
                      return (
                        <div className="communitySelectChipContainer">
                          <div
                            className={`${item?.image
                              ? "socialProfilePicContainer"
                              : "socialCustomProfilePic"
                              }`}
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "0",
                              fontSize: "14px",
                            }}
                          >
                            {!item?.image ? (
                              getUserInitials(getDisplayName(item?.name))
                            ) : (
                              <img
                                className="commentProfilePic"
                                style={{ width: "28px", height: "28px" }}
                                onClick={() => {
                                  // handleUserProfileClick(d?.id);
                                }}
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <label className="chipLabel">
                            {getDisplayName(item.name)}
                          </label>
                          <IconButton
                            onClick={() => {
                              let tempList = JSON.parse(
                                JSON.stringify(selectedParticipant)
                              );
                              tempList = tempList.filter((selectedValue) => {
                                return (
                                  Number(selectedValue.id) !== Number(item.id)
                                );
                              });
                              setSelectedParticipant(tempList);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="9"
                              viewBox="0 0 10 9"
                              fill="none"
                            >
                              <line
                                x1="8.11133"
                                y1="1.68654"
                                x2="1.44905"
                                y2="8.34882"
                                stroke="#555555"
                                stroke-linecap="round"
                              />
                              <line
                                x1="0.5"
                                y1="-0.5"
                                x2="9.92189"
                                y2="-0.5"
                                transform="matrix(0.707107 0.707107 0.707107 -0.707107 1.67139 0.979431)"
                                stroke="#555555"
                                stroke-linecap="round"
                              />
                            </svg>
                          </IconButton>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="chatListSearchContainer">
                  <input
                    type="text"
                    className={`chatListSearch ${selectedParticipant?.length > 0
                      ? "communityListSearch"
                      : null
                      }`}
                    placeholder="Search"
                    onChange={searchEditEmployee}
                  />
                  <img
                    src={SearchMagnifierIcon}
                    alt=""
                    style={{ marginRight: "15px", cursor: "pointer" }}
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", borderBottom: "1px solid #EEE" }}
              ></div>
              {allEmployeeList?.length <= 0 && <Loader />}
              {/* {allEmployeeList?.length > 0 && (
                <div className="communityMemberListBox newScrollbar">
                  {allEmployeeList.map((item, index) => (
                    <div
                      onClick={() => {
                        let searchSelectedParticipant =
                          selectedParticipant?.find((d) => d?.id === item?.id);
                        if (!searchSelectedParticipant)
                          setSelectedParticipant([
                            ...selectedParticipant,
                            item,
                          ]);

                        let tempList = JSON.parse(
                          JSON.stringify(allEmployeeList)
                        );
                        tempList = tempList.filter((selectedValue) => {
                          return Number(selectedValue.id) !== Number(item.id);
                        });
                        setAllEmployeeList(tempList);
                      }}
                      className="communityAllEmployeeListContainer"
                    >
                      <div className="newChatActiveImage">
                        <div
                          className={`${
                            item?.image
                              ? "socialProfilePicContainer"
                              : "socialCustomProfilePic"
                          }`}
                          style={{ width: "50px", height: "50px", margin: "0" }}
                        >
                          {!item?.image ? (
                            getUserInitials(getDisplayName(item?.name))
                          ) : (
                            <img
                              className="commentProfilePic"
                              onClick={() => {
                                // handleUserProfileClick(d?.id);
                              }}
                              src={
                                process.env.REACT_APP_.BASE_URL +
                                profileImages +
                                item?.image
                              }
                              alt="Profile"
                              onError={(e) => {
                                e.target.src = ProfileAvatar;
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="newChatPostMainTextContainer">
                        <label className="chatListName">
                          {getDisplayName(item?.name)}
                        </label>
                        <label className="chatListRole">{item?.jobRoal}</label>
                        <label className="chatListRole">{item?.location}</label>
                      </div>
                      <div
                        className="socialViewsContainer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setViewsListActive(true);
                          setViewsPostId(item?.iitem);
                        }}
                      >
                        <img src={SocialPostViewsEyeIcon} alt="" />
                        <span>
                          {item?.views < 10
                            ? "0" + item?.views
                            : getFormattedCount(item?.views)}{" "}
                          Views
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )} */}
              <InfiniteScroll
                dataLength={allEmployeeList?.length}
                next={getEmployeeData}
                hasMore={presentEmployeeData?.length < 20 ? false : true}
                loader={<h4 style={{ padding: "30px" }}> Loading...</h4>}
                height={600}
                width={200}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {allEmployeeList?.map((item) => {
                  return (
                    <SocialNotificationItem
                      item={item}
                      selectedRecipient={selectedRecipient}
                      setSelectedRecipient={setSelectedRecipient}
                    />
                  );
                })}
              </InfiniteScroll>
              {selectedParticipant.length > 0 && (
                <div
                  className="groupCreationNxtBtn"
                  onClick={() => {
                    editCommunity();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                  >
                    <circle cx="18.6455" cy="18.8914" r="18" fill="#1296B0" />
                    <path
                      d="M27.8232 19.1169L21.3721 12.5392M27.8232 19.1169L21.3721 24.9637M27.8232 19.1169H9.18649"
                      stroke="white"
                      stroke-width="1.10154"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>
          }
          handleClose={() => {
            setEditEmployeeSelectActive(false);
            setEditCommunityIsActive(false);
            setCommunityTypeActive(false);
            setSelectEmployee([]);
          }}
        />
      )}
      {employeeSelectionActive && (
        <Popup
          firstClassName="newPostForEmployeePopup1"
          secondClassName="newPostForEmployeePopup2"
          contentClassName="newPostForEmployeePopupContent"
          bodyClassName="hideScrollBarContainer"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setEmployeeSelectionActive(false);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select New Creator</h5>
            </div>
          }
          content={
            <div className="selectEmployeeContainer">
              <div className="selectEmployeeWrapper">
                <div className="commonSearchListContainer">
                  <input
                    type="text"
                    className="commonSearchList"
                    placeholder="Search"
                    onChange={searchChatters}
                  />

                  <img src={SearchMagnifierIcon} alt="" />
                </div>
                <div className="totalEmployeeSelectedContainer">
                  {communityAdminAndMemberList?.map((d, i) => {
                    return (
                      <div className="selectedEmployeeList">
                        <div className="employeeList">
                          <div className="totalLikePopupImg">
                            <img
                              src={
                                d?.profilePicture
                                  ? process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.profilePicture
                                  : ProfileAvatar
                              }
                              onError={(e) => {
                                e.target.src = ProfileAvatar;
                              }}
                              alt=""
                            />
                          </div>
                          <div className="totalLikePopupText">
                            <h5>
                              {getDisplayName(d?.name)}{" "}
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {getPersonNumber(d?.name)}
                              </span>
                            </h5>
                            <p style={{ fontSize: "14px", fontWeight: "500" }}>
                              {d?.jobRoal}
                            </p>
                          </div>
                        </div>
                        <div
                          className="socialCommonChkbox"
                          onClick={() => {
                            setSelectedRecipient(d?.personId);
                          }}
                        >
                          <input
                            type="checkbox"
                            class="myinput large"
                            name="chjsah"
                            checked={selectedRecipient === d?.personId}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="welcomeOptionBottomContainer">
                <div
                  className={`welcomeOptionBottomWrapper ${selectedRecipient !== null ? "active" : ""
                    }`}
                >
                  {!loading && selectedRecipient !== 0 && (
                    <span
                      className="active"
                      onClick={() => {
                        leaveCommunityByAdmin();
                      }}
                    >
                      Leave
                    </span>
                  )}
                  {loading && <span>Leaving...</span>}
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setEmployeeSelectionActive(false);
          }}
        />
      )}
      <Routes>
        <Route path="details/:postid" element={<CommunityPostDetails />} />
      </Routes>

      <Routes>
        <Route
          path="/post/share/:postid"
          element={
            <SharePost
              sharePostData={sharePostData}
              sharePostId={sharePostId}
              getData={getData}
              unreadPostCount={unreadPostCount}
            />
          }
        />
      </Routes>
      {isSocialPostDeletePopupActive && (
        <Popup
          firstClassName="deletePostPopupBox1"
          secondClassName="deletePostPopupBox2"
          popupHeading={`Delete Post`}
          content={
            <>
              <div className="socialPostDeleteBody">
                <p>Are you sure want to Permanently remove this Post?</p>
              </div>
              <div className="socialPostDeleteBtn">
                <button onClick={deleteSocialPostById}>Delete</button>
                <button
                  onClick={() => {
                    setIsSocialPostDeletePopupActive(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          }
          handleClose={() => {
            setIsSocialPostDeletePopupActive(false);
          }}
        />
      )}
      {isSharePosEditActive && (
        <EditSharePost
          editDeleteSocialPostData={editDeleteSocialPostData}
          editDeleteSocialPostId={editDeleteSocialPostId}
          getData={getData}
          setIsSharePosEditActive={setIsSharePosEditActive}
          isCommunityorConnect="community"
        />
      )}
      {isMyPosEditActive && (
        <EditMyPost
          editDeleteSocialPostData={editDeleteSocialPostData}
          editDeleteSocialPostId={editDeleteSocialPostId}
          setEditDeleteSocialPostData={setEditDeleteSocialPostData}
          getData={getData}
          setIsMyPosEditActive={setIsMyPosEditActive}
          isCommunityorConnect="community"
        />
      )}

      {shareDetailsActive && (
        <ShareDetails
          setShareDetailsActive={setShareDetailsActive}
          sharePostId={sharePostId}
          setSharePostId={setSharePostId}
        />
      )}
      {viewsListActive && (
        <ShowViewsList
          viewsPostId={viewsPostId}
          module={"community"}
          setViewsListActive={setViewsListActive}
          setViewsPostId={setViewsPostId}
        />
      )}
    </>
  );
};
export default CommunityViewPage;

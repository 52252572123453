import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AllRewards from './AllRewards';
import MyRewards from './MyRewards';
import TeamRewards from './TeamRewards';

const RewardsPositionTab = () => {
    const [tabIndex, setTabIndex] = useState("allrewards");
    const handleTabChange = (event, newTabIndex) => {
      navigate(`rewards/${newTabIndex}`);
    };
    const location = useLocation();
    const navigate = useNavigate();
    const [featureMenu, setFeatureMenu] = useState(null);
    const { featureMenuList } = useSelector((state) => state.menu);
    useEffect(() => {
      if (featureMenuList) {
        featureMenuList.map((menu) => {
          if (
            menu[0]?.subMenuName
              ?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase() === "rewards" &&
            menu[0]?.menuName
              ?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase() === "pms"
          )
            setFeatureMenu(menu);
          return null;
        });
      }
    }, [featureMenuList]);
    useEffect(() => {
      document.title = `PeopleSol -  Reward `;
      return () => {
        document.title = "PeopleSol";
      };
    }, []);
    useEffect(() => {
      const currentpath = location.pathname.toLowerCase();
      if (currentpath.includes("pms/rewards")) {
        let splitArray = currentpath.split("/");
        let submoduleIndex = splitArray.findIndex((x) => x === "rewards");
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            setTabIndex(
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
            navigate(
              `rewards/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) => parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `rewards/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) => parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, featureMenu]);
    return (
      <div className="moduleMainContainer">
        <div className="tabContainer">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "#1296B0" } }}
          >
            {featureMenu &&
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )
                ?.map((item, index) => {
                  return (
                    <Tab
                      key={index}
                      value={item?.featureName
                        ?.replaceAll(" ", "")
                        ?.replaceAll("&", "")
                        ?.toLowerCase()}
                      label={
                        <span
                          className={`tabHeading ${
                            tabIndex ===
                              item?.featureName
                                ?.replaceAll(" ", "")
                                ?.replaceAll("&", "")
                                ?.toLowerCase() && "tabActive"
                          }`}
                        >
                          {item?.featureDisplayName}
                        </span>
                      }
                    />
                  );
                })}
          </Tabs>
          <div className="tabContainerLine"></div>
        </div>
      {console.log("this is features menu", featureMenu)}

        {tabIndex === "allrewards" && (
          <>
            <AllRewards title={"All rewards"} />
          </>
        )}
        {tabIndex === "myrewards" && (
          <>
            <MyRewards title={"Myrewards"} />
          </>
        )}
        {tabIndex === "teamrewards" && (
          <>
            <TeamRewards title={"Teamrewards"} />
          </>
        )}
      </div>
    );
  };

export default RewardsPositionTab

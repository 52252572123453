import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserInitials } from '../../../../../Utilities/socialAllFunctions';
import getDisplayName from '../../../../../Utilities/displayNameFormat';
import { profileImages } from '../../../../../Utilities/profileAndUploadImages';
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import SearchMagnifierIcon from "../../../../../Assets/SearchMagnifierIcon.svg";
import SocialSearchGreyIcon from "../../../../../Assets/SocialSearchGreyIcon.svg";
import SocialSearchResultLeftIcon from "../../../../../Assets/SocialSearchResultLeftIcon.svg";
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';

function SocialHeaderComponent() {
  const [connectPostSearchActive, setConnectPostSearchActive] = useState(false);
  const [connectPostSearchTxt, setConnectPostSearchTxt] = useState("");
  const [connectPostSearchData, setConnectPostSearchData] = useState([]);
  const { employeeListReduxData } = useSelector((state) => state.social);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  const searchPostByName = async (text) => {
    let data = employeeListReduxData?.filter((d, i) => {
      if (d?.name.toLowerCase().includes(text.toLowerCase())) {
        return d;
      }
    });
    setConnectPostSearchData(data);
  };
  const moreActivityHandler = useCallback((e) => {
    let moreActivity = document.querySelector(`.connectSearchPostContainer`);
    if (moreActivity && !moreActivity?.contains(e.target)) {
      setConnectPostSearchActive(false);
      if (connectPostSearchTxt === "") {
        setTimeout(() => {
          setConnectPostSearchTxt("");
          setConnectPostSearchData([]);
        }, 3000);
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mouseup", moreActivityHandler);
    return () => {
      document.removeEventListener("mouseup", moreActivityHandler);
    };
  }, [moreActivityHandler]);

  return (
    <div className="socialsearch-holdercontainer" style={{
      left: "50%",
      transform: "translateX(-50%)", width: "84%",
    }}>
      <div className="postUpdateContainerParent" style={{
        width: "100%",
      }}>
        <div className="postUpdateContainer">
          <div
            onClick={(e) => {
              handleUserProfileClick(LoggedInUser?.id);
            }}
            className={`${LoggedInUser?.image
              ? "socialProfilePicContainer"
              : "socialInputCustomProfilePic"
              }`}
          >
            {!LoggedInUser?.image && LoggedInUser?.nameWithPersonNumber ? (
              getUserInitials(
                getDisplayName(LoggedInUser?.nameWithPersonNumber)
              )
            ) : (
              <img
                className="socialInputProfilePic"
                src={
                  process.env.REACT_APP_.BASE_URL +
                  profileImages +
                  LoggedInUser?.image
                }
                alt="Profile"
                onError={(e) => {
                  e.target.src = ProfileAvatar;
                }}
              />
            )}
          </div>
          <input
            placeholder="What's on your mind?"
            onClick={() => {
              navigate("post/create");
            }}
          ></input>
          <div>
            <span></span>
            <img
              className="mg-search"
              src={SocialSearchGreyIcon}
              alt=""
              onClick={() => {
                setConnectPostSearchActive(true);
              }}
            />
          </div>
        </div>
        <div
          className={`connectSearchPostContainer ${connectPostSearchActive ? "active" : ""
            }`}
        >
          <div className="connectSearchPostWrapper">
            <input
              type="text"
              onChange={(e) => {
                setConnectPostSearchTxt(e.target.value);
                searchPostByName(e.target.value);
              }}
              value={connectPostSearchTxt}
              placeholder="Search"
            />
            {connectPostSearchTxt === "" && (
              <img src={SearchMagnifierIcon} alt="" />
            )}
            {connectPostSearchTxt !== "" && (
              <img
                src={CloseIcon}
                alt=""
                onClick={() => {
                  setConnectPostSearchTxt("");
                  setConnectPostSearchData([]);
                  setConnectPostSearchActive(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {connectPostSearchData?.length > 0 && (
        <div className="searchResultContainer" >
          <div className="searchResultWrapper newScrollbar">
            {connectPostSearchData?.map((res, i) => {
              return (
                <div
                  className="searchResultListContainer"
                  onClick={(e) => {
                    // e.stopPropagation();
                    handleUserProfileClick(res?.id);
                  }}
                >
                  <div className="searchResultRight">
                    <div
                      // onClick={(e) => {
                      //   e.stopPropagation();
                      //   handleUserProfileClick(res?.id);
                      // }}
                      className={`${res?.image
                        ? "socialProfilePicContainer"
                        : "socialHeaderCustomProfilePic"
                        }`}
                    >
                      {!res?.image ? (
                        getUserInitials(getDisplayName(res?.name))
                      ) : (
                        <img
                          className="socialProfilePic"
                          src={
                            process.env.REACT_APP_.BASE_URL +
                            profileImages +
                            res?.image
                          }
                          alt="Profile"
                          onError={(e) => {
                            e.target.src = ProfileAvatar;
                          }}
                        />
                      )}
                    </div>

                    <h6>{getDisplayName(res?.name)}</h6>
                    <p>
                      <article>.</article>
                      <span>{res?.jobRoal}</span>
                    </p>
                    <p>
                      <article>.</article>
                      <span>{res?.location}</span>
                    </p>
                  </div>
                  <div className="searchResultLeft">
                    <img src={SocialSearchResultLeftIcon} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {connectPostSearchData?.length <= 0 &&
        connectPostSearchTxt !== "" && (
          <div className="searchResultNotFoundContainer">
            <div className="searchResultNotFoundWrapper">
              No Records!
            </div>
          </div>
        )}
    </div>
  );
}

export default SocialHeaderComponent;

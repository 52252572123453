import React, { useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";

const opt = [{ label: "select", value: null }];

export const CabSelfComp = ({
  data,
  formValues,
  travelCategoryOption,
  pushEmployee,
  setFormValues,
  LoggedInUser,
  setInitialValues,
  editTrue,
  detailsform,
}) => {
  const dispatch = useDispatch();
  const millisecondsInAYear = 1000 * 60 * 60 * 24 * 365;

  const travellingOptions = [
    { label: "One Way Trip", value: "One Way Trip" },
    { label: "Round Trip", value: "Round Trip" },
    { label: "Multilocation Trip", value: "Multilocation Trip" },
  ];
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];

  const [budget, setBudget] = useState("");
  const getBudget = async (catId) => {
    if (formValues?.Type === "Self" && formValues?.Mode === "Cab") {
      const result = await getRequest(
        `TravelHospitalityRequests/getBudgetByCategory?mode=Cab&categoryId=${catId}`
      );
      setBudget(result?.data?.budget);
      if (formValues?.TravelingType === "Multilocation Trip")
        setInitialValues((prev) => {
          const arr = { ...formValues };
          arr.CategoryID = formValues?.CategoryID;

          arr.Budget =
            result?.data?.budget * formValues?.TravelingDetails?.length;
          return arr;
        });
      else if (formValues?.TravelingType === "Round Trip")
        setInitialValues((prev) => {
          const arr = { ...formValues };
          arr.CategoryID = formValues?.CategoryID;
          arr.Budget = result?.data?.budget * 2;
          return arr;
        });
      else
        setInitialValues((prev) => {
          const arr = { ...formValues };
          arr.CategoryID = formValues?.CategoryID;

          arr.Budget = result?.data?.budget;
          return arr;
        });
    } else {
      setInitialValues((prev) => {
        const arr = { ...formValues };
        arr.CategoryID = "";
        arr.Budget = "";
        return arr;
      });
    }
  };
  useEffect(() => {
    if (formValues?.CategoryID && formValues?.TravelingType) {
      getBudget(formValues?.CategoryID);
    }
  }, [formValues?.CategoryID, formValues?.TravelingType]);
  useEffect(() => {
    if (
      formValues?.Type === "Self" &&
      formValues?.Mode === "Cab" &&
      typeof formValues?.Budget === "number" &&
      formValues?.TravelingType === "Multilocation Trip"
    ) {
      setInitialValues(() => {
        const arr = { ...formValues };
        arr.Budget = budget * formValues?.TravelingDetails?.length;
        return arr;
      });
    }
  }, [formValues?.TravelingDetails]);
  useEffect(() => {
    if (
      formValues?.Type === "Self" &&
      formValues?.Mode === "Cab" &&
      !editTrue
    ) {
      setInitialValues((prev) => {
        const arr = { ...formValues };
        arr.Age = Math.floor(
          (new Date() - new Date(LoggedInUser?.dob)) / millisecondsInAYear
        );
        arr.Gender = LoggedInUser?.gender;
        return arr;
      });
    }
  }, [formValues?.Type, LoggedInUser]);

  useEffect(() => {
    document.title = `PeopleSol - Self Cab`;

    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div
        style={
          formValues?.TravelingType
            ? { borderBottom: "1px solid #E6E6E6", padding: "10px 0px" }
            : {}
        }
        className="formcustom threeColumn"
      >
        <div>
          <LabelCustom labelName={"Travel Type"} mandatory={true} />
          <SelectForm name="TravelingType" options={travellingOptions} />
        </div>
        {formValues?.TravelingType && (
          <div>
            <LabelCustom labelName={"Travel Category"} mandatory={true} />
            <SelectForm
              disabled={formValues?.TravelingType ? false : true}
              name="CategoryID"
              options={travelCategoryOption}
            />
          </div>
        )}
      </div>
      {formValues?.TravelingType && (
        <div className="formcustom threeColumn">
          {formValues?.TravelingType !== "Multilocation Trip" && (
            <>
              {" "}
              <div>
                <LabelCustom labelName={"Travelling Date"} mandatory={true} />
                <DatePickerFormiks name={"FromDate"} minDate={new Date()} />
              </div>
              <div>
                <LabelCustom labelName={"From Location"} mandatory={true} />
                <InputCustomFormik
                  maxLength={50}
                  name="PickupLocation"
                  type={"text"}
                  placeholder={" Enter From Location"}
                />
              </div>
              <div>
                <LabelCustom labelName={"To Location"} mandatory={true} />
                <InputCustomFormik
                  maxLength={50}
                  name="DropLocation"
                  type={"text"}
                  placeholder={" Enter To Location"}
                />
              </div>
              {formValues?.TravelingType === "Round Trip" && (
                <>
                  <div>
                    <LabelCustom labelName={"Return Date"} mandatory={true} />
                    <DatePickerFormiks
                      name={"ToDate"}
                      minDate={new Date(formValues?.FromDate)}
                    />
                  </div>
                  <div>
                    <LabelCustom labelName={"From Location"} mandatory={true} />
                    <InputCustomFormik
                      maxLength={50}
                      name="PickupLocationR"
                      type={"text"}
                      placeholder={"Enter From Location"}
                    />
                  </div>

                  <div>
                    <LabelCustom labelName={"To Location"} mandatory={true} />
                    <InputCustomFormik
                      maxLength={50}
                      name="DropLocationR"
                      type={"text"}
                      placeholder={"Enter To Location"}
                    />
                  </div>
                </>
              )}
            </>
          )}

          <div>
            <LabelCustom labelName={"Age"} />
            <InputCustomFormik
              type="number"
              maxLength={2}
              name="Age"
              placeholder={"Enter Age"}
            />
          </div>
          <div>
            <LabelCustom labelName={"Gender"} />
            <SelectForm
              values={
                data
                  ? genderOptions.filter(
                      (options) => options.label === data?.gender
                    )
                  : opt
              }
              name="Gender"
              options={genderOptions}
            />
          </div>
          <div>
            <LabelCustom labelName={"Budget (In Thousands)"} mandatory={true} />
            <InputCustomFormik
              disabled={true}
              // value={
              //   formValues?.TravelingType === "Multilocation Trip"
              //     ? budget * formValues?.TravelingDetails?.length
              //     : budget
              // }
              type="number"
              name="Budget"
              placeholder={" Enter Budget"}
            />
          </div>
          {formValues?.TravelingType !== "Multilocation Trip" && (
            <div>
              <LabelCustom labelName={"Booking Note"} />
              <TextAreaFormik
                name="Bookingnote"
                placeholder={" Enter Booking Note"}
                maxLength={500}
              />
            </div>
          )}
        </div>
      )}
      {formValues?.TravelingType === "Multilocation Trip" && (
        <div
          style={{
            marginTop: "20px",
            borderTop: "1px solid #E6E6E6",
            padding: "20px 0px",
          }}
          className="repeatedSectionContainer"
        >
          <label className="repeatedHeading">
            Please specify your journey details
          </label>
          <FieldArray name="TravelingDetails">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values, errors } = form;
              const { TravelingDetails } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Travelling Date"}
                            mandatory={true}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"From Location"}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"To Location"}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Booking Note"} />{" "}
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={" Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {TravelingDetails?.map((Requestlist, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        {/* 2nd column */}
                        <StyledTableCell>
                          <DatePickerFormiks
                            name={`TravelingDetails[${index}].FromDate`}
                            minDate={new Date()}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={50}
                            name={`TravelingDetails[${index}].PickupLocation`}
                            type="text"
                            placeholder={" Enter From Location"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={50}
                            name={`TravelingDetails[${index}].DropLocation`}
                            type={"text"}
                            placeholder={" Enter To Location"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextAreaFormik
                            name={`TravelingDetails[${index}].Bookingnote`}
                            placeholder={" Enter Note"}
                            maxLength={500}
                          />
                        </StyledTableCell>

                        {/* 8th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              TravelingDetails.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                FromDate: "",
                                PickupLocation: "",
                                DropLocation: "",
                                Remarks: "",
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      )}
    </>
  );
};

import React, { useState, useEffect } from "react";
import PMSgoalsPic from "../../Assets/PMSgoalsPic.svg";
import PMSperformancereviewPic from "../../Assets/PMSperformancereviewPic.svg";
import PMSappraisalPic from "../../Assets/PMSappraisalPic.svg";
import RewardCriteriaPic from "../../Assets/rewardsCriteria.svg";
import RewardReviewPic from "../../Assets/rewardreviewicon.svg";
import PublishWinnerPic from "../../Assets/publishwinner.svg";
import RewardsPositionPic from "../../Assets/rewards.svg";
import { useLocation, useNavigate } from "react-router-dom";
import HRMSIcon from "../../Assets/HRMSIcon.svg";
import "./PMS.css";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { GoalsTab } from "./GoalsTab";
import { PerformanceTab } from "./PerformanceTab";
import { AppraisalTab } from "./AppraisalTab";
import { SetGoals } from "./Components/Goals/SetGoals";
import { ViewGoals } from "./Components/Goals/ViewGoals";
import { SpecificDetails } from "./Components/Goals/SpecificDetails";
import { GoalGettingSummary } from "./Components/Goals/GoalGettingSummary";
import { ReviewDashboard } from "./Components/Performance Review/ReviewDashboard";
import { DetailsViewGoals } from "./Components/Goals/DetailsViewGoals";
import { DetailsAllGoals } from "./Components/Goals/DetailsAllGoals";
import { SubmitSelfReview } from "./Components/Performance Review/SubmitSelfReview";
import { DetailsPerformanceSelfReview } from "./Components/Performance Review/DetailsPerformanceSelfReview";
import { DetailsAppraiserReview } from "./Components/Performance Review/DetailsAppraiserReview";
import { SubmitAppraiserReview } from "./Components/Performance Review/SubmitAppraiserReview";
import { DetailsReviewerReview } from "./Components/Performance Review/DetailsReviewerReview";
import { SubmitReviewerReview } from "./Components/Performance Review/SubmitReviewerReview";
import { DetailsAllReview } from "./Components/Performance Review/DetailsAllReview";
import { DetailsMySubmittedReview } from "./Components/Performance Review/DetailsMySubmittedReview";
import { AppraisalReviewTab } from "./AppraisalReviewTab";
import { ReviewAppraisalDashboard } from "./Components/Performance Review/ReviewAppraisalDashboard";
import { DetailsAppraisalAllReview } from "./Components/Performance Review/DetailsAppraisalAllReview";
import { SubmitAppraisalSelfReview } from "./Components/Performance Review/SubmitAppraisalSelfReview";
import { DetailsAppraisalSelfReview } from "./Components/Performance Review/DetailsAppraisalSelfReview";
import { DetailsAppraiserAppraiserReview } from "./Components/Performance Review/DetailsAppraiserAppraiserReview";
import { SubmitAppraiserAppraiserReview } from "./Components/Performance Review/SubmitAppraiserAppraiserReview";
import { DetailsAppraiserReviewerReview } from "./Components/Performance Review/DetailsAppraiserReviewerReview";
import { SubmitAppraiserReviewerReview } from "./Components/Performance Review/SubmitAppraiserReviewerReview";
import { SubmitFinalReviewerReview } from "./Components/Performance Review/SubmitFinalReviewerReview";
import { SubmitFinalAppraiserReviewerReview } from "./Components/Performance Review/SubmitFinalAppraiserReviewerReview";
import { PMSsubmitAppraisalTab } from "./PMSsubmitAppraisalTab";
import { PMSSendCorrectionAppraisalTab } from "./PMSSendCorrectionAppraisalTab";
import { PMSSendCorrectionAppraisalDetailsTab } from "./PMSSendCorrectionAppraisalDetailsTab";
import { PMSSendCorrectionAppraisalEditTab } from "./PMSSendCorrectionAppraisalEditTab";
import { PmsMyAppraisalLetter } from "./PMSAppraisalLetter/PmsMyAppraisalLetter";
import PmsMyAppraisalLetterReactpdf from "./PMSAppraisalLetter/PmsMyAppraisalLetterReactpdf";
import { RewardCreteriatab } from "./RewardCriteriatab";
import RewardsCriteriaSetTargetList from "./RewardsCriteriaSetTargetList";
import RewardsCriteriaSetTargetDetail from "./RewardsCriteriaSetTargetDetail";
import RewardReviewTab from "./RewardReviewTab";
import SubmitMyRewardsSelfReview from "./Components/Performance Review/SubmitMyRewardsSelfReview";
import DetailTeamRewardsAppraiserReview from "./DetailTeamRewardsAppraiserReview";
import SubmitTeamRewardsAppraiserReview from "./SubmitTeamRewardsAppraiserReview";
import SubmitTeamRewardsReviewerReview from "./SubmitTeamRewardsReviewerReview";
import DetailTeamRewardsReview from "./DetailTeamRewardsReview";
import DetailTeamRewardsFinalReview from "./DetailTeamRewardsFinalReview";
import SubmitTeamRewardsFinalReview from "./SubmitTeamRewardsFinalReview";
import MyRewardsReviewDetail from "./MyRewardsReviewDetail";
import ReviewRewardsDashboard from "./ReviewRewardsDashboard";
import PublishWinnerTab from "./PublishWinnerTab";
import PublishWinnerSubtabInd from "./PublishWinnerSubtabInd";
import RewardsPositionTab from "./RewardsPositionTab";
import MyRewardsWinners from "./MyRewardsWinners";
import TeamRewardsWinnwers from "./TeamRewardsWinnwers";
import AllRewardsWinners from "./AllRewardsWinners";


const PMS = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState(null);
  const [activeSubmodule, setActiveSubmodule] = useState("goals");
  const [activeFeature, setActiveFeature] = useState("");
  const [detailsActive, setDetailsActive] = useState(false);
  const [summaryActive, setSummaryActive] = useState(false);
  const [reviewerDetailActive, setReviewerDetailActive] = useState(false);
  const [reviewerReviewDetailActive, setReviewerReviewDetailActive] =
    useState(false);
  const [finalReviewDetailActive, setfinalReviewDetailActive] = useState(false);
  const [finalReviewerReviewDetailActive, setfinalReviewerReviewDetailActive] =
    useState(false);
  const [createActive, setCreateActive] = useState(false);
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "goals") return PMSgoalsPic;
    else if (subMenuName === "performancereview")
      return PMSperformancereviewPic;
    else if (subMenuName === "appraisalreview") return PMSappraisalPic;
    else if (subMenuName === "appraisal") return PMSappraisalPic;
    else if (subMenuName === "rewardscriteria") return RewardCriteriaPic;
    else if (subMenuName === "rewardsreview") return RewardReviewPic;
    else if (subMenuName === "publishwinner") return PublishWinnerPic;
    else if (subMenuName === "rewards") return RewardsPositionPic;
  };

  useEffect(() => {
    if (subMenuList) {
      subMenuList.map((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "pms"
        )
          setSubmoduleMenu(menu);
        return null;
      });
    }
  }, [subMenuList]);

  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    setSummaryActive(false);
    setReviewerDetailActive(false);
    setReviewerReviewDetailActive(false);
    setfinalReviewDetailActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("pms")) {
      let splitArray = currentpath.split("/");
      console.log("pms split array", currentpath)
      let submoduleIndex = splitArray.findIndex((x) => x === "pms");
      if (splitArray?.length > submoduleIndex + 1) {
        if (
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.some(
              (x) =>
                x[0].subMenuName
                  ?.replaceAll(" ", "")
                  ?.replaceAll("&", "")
                  ?.toLowerCase() === splitArray[submoduleIndex + 1]
            )
        ) {
          setActiveSubmodule(splitArray[submoduleIndex + 1]);
        } else {
          navigate(
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )[0][0]
              .subMenuName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
        }
      } else {
        navigate(
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.sort(
              (a, b) =>
                parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
            )[0][0]
            .subMenuName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else if (splitArray[submoduleIndex + 2] === "summary") {
          setSummaryActive(true);
        } else if (splitArray[submoduleIndex + 2] === "reviewerreview") {
          setReviewerReviewDetailActive(true);
          setDetailsActive(false);
        } else if (splitArray[submoduleIndex + 2] === "finaldetail") {
          setReviewerReviewDetailActive(true);
          setDetailsActive(false);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        } else if (splitArray[submoduleIndex + 3] === "summary") {
          setSummaryActive(true);
        } else if (splitArray[submoduleIndex + 3] === "reviewerdetail") {
          setReviewerDetailActive(true);
          setReviewerReviewDetailActive(false);
          setDetailsActive(false);
        } else if (splitArray[submoduleIndex + 3] === "finaldetail") {
          setfinalReviewDetailActive(true);
          setfinalReviewerReviewDetailActive(false);
          setDetailsActive(false);
        } else if (splitArray[submoduleIndex + 3] === "reviewerreview") {
          setReviewerReviewDetailActive(true);
          setReviewerDetailActive(false);
          setDetailsActive(false);
        } else if (splitArray[submoduleIndex + 3] === "finalreviewerreview") {
          setfinalReviewerReviewDetailActive(true);
          setfinalReviewDetailActive(false);
          setDetailsActive(false);
        } else if (splitArray[submoduleIndex + 3] === "employeeDetails") {
          setDetailsActive(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, submoduleMenu]);

  useEffect(() => {
    document.title = `PeopleSol -  PMS`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="moduleContainer">
      {activeSubmodule === "goals" && (
        <>
          {activeFeature === "allgoals" && (
            <>
              {createActive && <GoalGettingSummary title={"Goal"} />}
              {detailsActive && <DetailsAllGoals />}
            </>
          )}
          {activeFeature === "mygoals" && (
            <>
              {createActive && <SetGoals title={"Goal"} />}
              {detailsActive && <DetailsViewGoals />}
            </>
          )}
          {activeFeature === "teamgoals" && (
            <>
              {detailsActive && <ViewGoals />}
              {createActive && <SpecificDetails />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "performancereview" && (
        <>
          {activeFeature === "allreview" && (
            <>
              {createActive && <ReviewDashboard title={"Review"} />}
              {detailsActive && <DetailsAllReview />}
              {/* {detailsActive && <DetailsReviewerReview />} */}
            </>
          )}
          {activeFeature === "myreview" && (
            <>
              {createActive && <SubmitSelfReview title={"Review"} />}
              {detailsActive && <DetailsPerformanceSelfReview />}
              {/* {summaryActive && <DetailsMySubmittedReview />} */}
            </>
          )}
          {activeFeature === "teamreview" && (
            <>
              {createActive && <DetailsAppraiserReview title={"Goal"} />}
              {detailsActive && <SubmitAppraiserReview />}
              {reviewerDetailActive && <DetailsReviewerReview />}
              {reviewerReviewDetailActive && <SubmitReviewerReview />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "appraisalreview" && (
        <>
          {activeFeature === "allappraisalreview" && (
            <>
              {createActive && <ReviewAppraisalDashboard title={"Review"} />}
              {detailsActive && <DetailsAppraisalAllReview />}
              {/* {detailsActive && <DetailsReviewerReview />} */}
            </>
          )}
          {activeFeature === "myappraisalreview" && (
            <>
              {createActive && <SubmitAppraisalSelfReview title={"Review"} />}
              {detailsActive && <DetailsAppraisalSelfReview />}
              {summaryActive && <DetailsMySubmittedReview />}
            </>
          )}
          {activeFeature === "teamappraisalreview" && (
            <>
              {createActive && (
                <DetailsAppraiserAppraiserReview title={"Goal"} />
              )}
              {detailsActive && <SubmitAppraiserAppraiserReview />}
              {reviewerDetailActive && <DetailsAppraiserReviewerReview />}
              {reviewerReviewDetailActive && <SubmitAppraiserReviewerReview />}
              {finalReviewDetailActive && <SubmitFinalReviewerReview />}
              {finalReviewerReviewDetailActive && (
                <SubmitFinalAppraiserReviewerReview />
              )}
            </>
          )}
        </>
      )}
      {activeSubmodule === "appraisal" && (
        <>
          {activeFeature === "allappraisal" && (
            <>
              {" "}
              {activeFeature === "allappraisal" &&
                location.pathname
                  .toLowerCase()
                  .includes("sendcorrectionappraisal/details") && (
                  <PMSSendCorrectionAppraisalDetailsTab />
                )}
              {activeFeature === "allappraisal" &&
                location.pathname
                  .toLowerCase()
                  .includes("sendcorrectionappraisal/edit") && (
                  <PMSSendCorrectionAppraisalEditTab />
                )}
              {activeFeature === "allappraisal" &&
                location.pathname
                  .toLowerCase()
                  .includes("sendcorrectionappraisal/info") && (
                  <PMSSendCorrectionAppraisalTab />
                )}
              {createActive && <ReviewAppraisalDashboard title={"Review"} />}
              {detailsActive && <DetailsAppraisalAllReview />}
              {/* {detailsActive && <DetailsReviewerReview />} */}
            </>
          )}
          {activeFeature === "myappraisal" && (
            <>
              {location.pathname.toLowerCase().includes("letter") && (
                // <PmsMyAppraisalLetter />
                <PmsMyAppraisalLetterReactpdf />
              )}
            </>
          )}
          {activeFeature === "teamappraisal" && (
            <>
              {location.pathname.toLowerCase().includes("submitappraisal") && (
                <PMSsubmitAppraisalTab />
              )}
            </>
          )}
        </>
      )}
     {activeSubmodule === "rewardscriteria" && (
        <>
          {location.pathname.toLowerCase() === "/pms/rewardscriteria/quantitative/settarget" && (
            <>
              <RewardsCriteriaSetTargetList />
            </>
          )}
          {/* {location.pathname.toLowerCase().includes("/pms/rewardscriteria/quantitative/settarget/details") && (
            <>
              <RewardsCriteriaSetTargetDetail />
            </>
          )} */}
           {detailsActive && <RewardsCriteriaSetTargetDetail />}
          {/* {location.pathname.toLowerCase() === "/pms/rewardscriteria/quantitative/settarget/details" && (
            <>
              <RewardsCriteriaSetTargetDetail />
            </>
          )} */}
          {/* {activeFeature === "Qualitative" &&
            location.pathname.toLowerCase().includes("/pmsmaster/rewards/quantitative/settarget") && (
              <>
                <RewardsCriteriaSetTargetList />
              </>
          )}  */}
        </>
      )}
      {activeSubmodule === "rewardsreview" && (
        <>
          {activeFeature === "allrewardsreview" && (
            <>
              {location.pathname.toLowerCase().includes("/pms/rewardsreview/allrewardsreview/rewardsreview") && (
                <ReviewRewardsDashboard />
                // <PmsMyAppraisalLetterReactpdf />
              )}
 
              {detailsActive && <DetailsAppraisalAllReview />}
              {/* {detailsActive && <DetailsReviewerReview />} */}
            </>
          )}
          {activeFeature === "myrewardsreview" && (
            <>
              {location.pathname.toLowerCase().includes("/pms/rewardsreview/myrewardsreview/selfreview") && (
                <SubmitMyRewardsSelfReview />
                // <PmsMyAppraisalLetterReactpdf />
              )}
              {location.pathname.toLowerCase().includes("/pms/rewardsreview/myrewardsreview/detail") && (
                <MyRewardsReviewDetail />
                // <PmsMyAppraisalLetterReactpdf />
              )}
            </>
          )}
          {activeFeature === "teamrewardsreview" && (
            <>
              {location.pathname.toLowerCase().includes("/pms/rewardsreview/teamrewardsreview/appraiserdetail") && (
                <DetailTeamRewardsAppraiserReview />
                // <PmsMyAppraisalLetterReactpdf />
              )}
              {location.pathname.toLowerCase().includes("/pms/rewardsreview/teamrewardsreview/submitappraiserreview") && (
                <SubmitTeamRewardsAppraiserReview />
                // <PmsMyAppraisalLetterReactpdf />
              )}
              {location.pathname.toLowerCase().includes("/pms/rewardsreview/teamrewardsreview/reviewerdetail") && (
                <DetailTeamRewardsReview />
                // <PmsMyAppraisalLetterReactpdf />
              )}
              {location.pathname.toLowerCase().includes("/pms/rewardsreview/teamrewardsreview/submitreviewerreview") && (
                <SubmitTeamRewardsReviewerReview />
                // <PmsMyAppraisalLetterReactpdf />
              )}
              {location.pathname.toLowerCase().includes("/pms/rewardsreview/teamrewardsreview/finaldetail") && (
                <DetailTeamRewardsFinalReview />
                // <PmsMyAppraisalLetterReactpdf />
              )}
              {location.pathname.toLowerCase().includes("/pms/rewardsreview/teamrewardsreview/submitfinalreview") && (
                <SubmitTeamRewardsFinalReview />
                // <PmsMyAppraisalLetterReactpdf />
              )}
            </>
          )}
        </>
      )}
      {activeSubmodule === "publishwinner" && (
        <>
          {activeFeature === "quantitative" && (
            <>
              {location.pathname.toLowerCase().includes("/pms/publishwinner/quantitative/topperfomer") && (
                <PublishWinnerSubtabInd />
                // <PmsMyAppraisalLetterReactpdf />
              )}
 
              {/* {detailsActive && <DetailsAppraisalAllReview />}  */}
              {/* {detailsActive && <DetailsReviewerReview />} */}
            </>
          )}
          {activeFeature === "qualitative" && (
            <>
 
            </>
          )}
 
        </>
      )}
      {activeSubmodule === "rewards" && (
        <>
          {activeFeature === "allrewards" && (
            <>
              {location.pathname.toLowerCase().includes("/pms/rewards/allrewards/viewresult") && (
                <AllRewardsWinners />
                // <PmsMyAppraisalLetterReactpdf />
              )}
 
            </>
          )}
          {activeFeature === "myrewards" && (
            <>
              {location.pathname.toLowerCase().includes("/pms/rewards/myrewards/viewresult") && (
                <MyRewardsWinners />
                // <PmsMyAppraisalLetterReactpdf />
              )}
 
            </>
          )}
          {activeFeature === "teamrewards" && (
            <>
              {location.pathname.toLowerCase().includes("/pms/rewards/teamrewards/viewresult") && (
                <TeamRewardsWinnwers />
                // <PmsMyAppraisalLetterReactpdf />
              )}
 
            </>
          )}
        </>
      )}

      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {submoduleMenu &&
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )
              ?.map((subMenu, index) => {
                return (
                  <div
                    key={index}
                    className={`menuOptions ${activeSubmodule ===
                      subMenu[0]?.subMenuName
                        ?.replaceAll(" ", "")
                        ?.replaceAll("&", "")
                        ?.toLowerCase() && "menuOptionsActive"
                      }`}
                    onClick={() => {
                      setActiveSubmodule(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      );
                      navigate(
                        `/pms/${subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}`
                      );
                    }}
                  >
                    <div className="verticalLine"></div>
                    <img
                      src={handleMenuIcon(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      )}
                      alt={subMenu[0]?.subMenuName}
                    />
                    <label>{subMenu[0]?.subMenuDisplayName}</label>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {activeSubmodule === "goals" ? <GoalsTab /> : null}
        {activeSubmodule === "performancereview" ? <PerformanceTab /> : null}
        {activeSubmodule === "appraisalreview" ? <AppraisalReviewTab /> : null}
        {activeSubmodule === "appraisal" ? <AppraisalTab /> : null}
        {activeSubmodule === "rewardscriteria" ? <RewardCreteriatab /> : null}
        {activeSubmodule === "rewardsreview" ? <RewardReviewTab /> : null}
        {activeSubmodule === "publishwinner" ? <PublishWinnerTab /> : null}
        {activeSubmodule === "rewards" ? <RewardsPositionTab /> : null}
      </div>
    </div>
  );
};

export { PMS };

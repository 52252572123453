import React, { useEffect, useState } from 'react'
import { isLoading, showToast } from '../../Features';
import { getRequest, postRequest } from '../../Services/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import "../PMSMaster/Components/Rewards.css";
import rewardscardimg from '../../../src/Assets/rewardscardImage.png'

const AllRewards = (props) => {
  const [activeTab, setActiveTab] = useState("Quantitative");
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState(false)


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
  });

  useEffect(() => {
    document.title = `PeopleSol -  Rewards`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);





  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest("RewardReview/AllRewardsList", reqData);

      if (result?.data.length > 0) {
        let tempData = [];
        result?.data?.map((item) =>
          tempData.push({
            ...item,
            // actiontype: item.actions[0]?.type, 

          })
        );
        setOriginalData(tempData);
      }

    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );

    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };



  useEffect(() => {
    if (location.pathname.toLowerCase().includes("rewards/allrewards"))
      getListData();
  }, [location.pathname, reqData]);





  return (
    <div className="fullTableContainer noTab" style={{overflow: "auto"}}>
      {/* Filter Right Data */}


      <div className="tableHeaderContainer" style={{ justifyContent: "left", marginTop: "0px", padding: "40px 38px", position: "sticky", top: "0px ", height: "0px", 
    minHeight: "0px" }}>

        <div className="tab-container" >

          <button
            className={`rewardstab ${activeTab === "Quantitative" ? "tabactive" : ""}`}
            onClick={() => setActiveTab("Quantitative")}
          >
            Quantitative
          </button>
          <button
            className={`rewardstab ${activeTab === "Qualitative" ? "tabactive" : ""}`}
            onClick={() => setActiveTab("Qualitative")}
          >
            Qualitative
          </button>

        </div>

      </div>
      <>

        <div
          className=""
          style={{ display: "flex", flexWrap: "wrap", padding: "12px" }}
        >

          {activeTab === "Quantitative" &&
            (<div
              className=""
              style={{ display: "flex", flexWrap: "wrap", padding: "12px" }}
            >
              {originalData.length > 0 && originalData.map((item, ind) => (

                <div className="rewardcard-container">
                  <div className="rewardcard-content">
                    <h3 className="rewardcard-title">{item.title}</h3>
                    <p className="rewardcard-description">
                      {item.rewardDescription}
                    </p>
                    <button
                      className="rewardcard-button"
                      onClick={() =>
                        navigate(`/pms/rewards/allrewards/viewresult/:${item.rewardId}`, {
                          state: { rewardId: item.rewardId, title: item.title },
                        })
                      }
                    >
                      View Result <span className="arrow">↗</span>
                    </button>
                  </div>
                  <div className="rewardcard-image">
                    <img
                      src={rewardscardimg}
                      alt="Trophy illustration"
                      onClick={() =>
                        navigate(`/pms/rewards/allrewards/viewresult/:${item.rewardId}`, { 
                          state: { rewardId: item.rewardId, title: item.title } })
                      }
                    />
                  </div>
                </div>
              ))

              }
              {/* <div className="rewardcard-container">
                        <div className="rewardcard-content">
                            <h3 className="rewardcard-title">Employee of the Year: 2023–24</h3>
                            <p className="rewardcard-description">
                                Lorem ipsum dolor sit amet consectetur. Tincidunt nibh nunc pretium
                                sollicitudin lobortis.
                            </p>
                            <a href="#" className="rewardcard-button">
                                View Awards <span className="arrow">↗</span>
                            </a>
                        </div>
                        <div className="rewardcard-image">
                            <img
                                src={rewardscardimg} // Replace with the actual image URL
                                alt="Trophy illustration"
                            />
                        </div>
                    </div>
                    <div className="rewardcard-container">
                        <div className="rewardcard-content">
                            <h3 className="rewardcard-title">Employee of the Year: 2023–24</h3>
                            <p className="rewardcard-description">
                                Lorem ipsum dolor sit amet consectetur. Tincidunt nibh nunc pretium
                                sollicitudin lobortis.
                            </p>
                            <a href="#" className="rewardcard-button">
                                View Awards <span className="arrow">↗</span>
                            </a>
                        </div>
                        <div className="rewardcard-image">
                            <img
                                src={rewardscardimg} // Replace with the actual image URL
                                alt="Trophy illustration"
                            />
                        </div>
                    </div>
                    <div className="rewardcard-container">
                        <div className="rewardcard-content">
                            <h3 className="rewardcard-title">Employee of the Year: 2023–24</h3>
                            <p className="rewardcard-description">
                                Lorem ipsum dolor sit amet consectetur. Tincidunt nibh nunc pretium
                                sollicitudin lobortis.
                            </p>
                            <a href="#" className="rewardcard-button">
                                View Awards <span className="arrow">↗</span>
                            </a>
                        </div>
                        <div className="rewardcard-image">
                            <img
                                src={rewardscardimg} // Replace with the actual image URL
                                alt="Trophy illustration"
                            />
                        </div>
                    </div> */}

            </div>)
          }



        </div>

      </>

    </div>
  );
};

export default AllRewards

import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { SelectFormikMulti } from "../../../Components/FormComponent/SelectFormikMulti";
import { SelectFormikMultiString } from "../../../Components/FormComponent/SelectFormikMultiString";
import { getRequest } from "../../../Services/axios";

const opt = [{ label: "select", value: null }];
const type = [
  { label: "IT Infra", value: "IT Infra" },
  { label: "SAP", value: "SAP" },
  { label: "IT Application ", value: "IT Application " },
  { label: "My Assets ", value: "My Assets " },
];
const ChangeTypeForm = ({ data }) => {
  const [option, setOption] = React.useState([]);
  const [optionArray, setOptionArray] = React.useState([]);
  const [materialArray, setMaterialArray] = React.useState([]);
  const [optionSer, setOptionSer] = React.useState([]);
  const getOptionReq = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionSer = async () => {
    try {
      const result = await getRequest("ServiceCategories/GetServiceCategories");
      let listData = [];
      result?.data?.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    if (optionSer.length >= 0) {
      data?.serviceCategoryIDs?.split(",").map((item) => {
        let tempVal = optionSer?.filter((options) => options?.value == item);
        if (tempVal.length > 0) {
          setOptionArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
    data?.employeeIDs?.split(",").map((item) => {
      let tempVal = option?.filter((options) => options.value == item);
      if (tempVal.length > 0) {
        setMaterialArray((prev) => {
          var contains = prev.some((elem) => {
            return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
          });

          if (!contains) {
            return [...prev, tempVal[0]];
          } else {
            return prev;
          }
        });
      }
    });

    // }
  }, [optionSer, option]);
  useEffect(() => {
    getOptionReq();
    getOptionSer();
    document.title = `PeopleSol - Create Asset`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={50}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Service Categories"} />
        <SelectFormikMultiString
          values={optionArray}
          name={"serviceCategoryIDs"}
          options={optionSer}
          placeholder={" Select Service Categories "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Members"} />
        <SelectFormikMultiString
          values={materialArray}
          name={"employeeIDs"}
          options={option}
          placeholder={" Select Members "}
        />
      </div>
    </div>
  );
};
export { ChangeTypeForm };

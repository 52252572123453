/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import Nodata from "../../../../Assets/Nodata.svg";
import { format } from "date-fns";
import UploadImageDetail from "../../../../Components/UploadDetail/UploadImageDetail";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import { Timeline } from "antd";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { DetailData } from "../../../HRMSMaster/Components";

const DetailsPagePayroll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const closeDetails = () => {
    navigate(-1);
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  console.log("id", id);
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState(false);
  console.log("employeeData", employeeData);
  const getProfileData = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Employees/Details/${selectedId}`);
      setEmployeeData(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const [subTabIndex, setSubTabIndex] = useState(0);
  const handleSubTabChange = (event, newTabIndex) => {
    setSubTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Employee`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Employees Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          {/* <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div> */}
          {loading ? (
            <div className="noDataFound">
              <Loader />
            </div>
          ) : employeeData ? (
            <div className="tabularMasterDetailsPageBody">
              {tabIndex === 0 && (
                <div className="tabContainer">
                  <Tabs
                    value={subTabIndex}
                    onChange={handleSubTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                      style: { background: "var(--secondary)" },
                    }}
                  >
                    <Tab
                      label={
                        <span
                          className={`tabHeading ${subTabIndex === 0 && "tabActive tabActiveSecondary"
                            }`}
                        >
                          Employee
                        </span>
                      }
                    />
                    <Tab
                      label={
                        <span
                          className={`tabHeading ${subTabIndex === 1 && "tabActive tabActiveSecondary"
                            }`}
                        >
                          Personal
                        </span>
                      }
                    />
                    <Tab
                      label={
                        <span
                          className={`tabHeading ${subTabIndex === 2 && "tabActive tabActiveSecondary"
                            }`}
                        >
                          Education
                        </span>
                      }
                    />
                    <Tab
                      label={
                        <span
                          className={`tabHeading ${subTabIndex === 3 && "tabActive tabActiveSecondary"
                            }`}
                        >
                          Experience
                        </span>
                      }
                    />
                    <Tab
                      label={
                        <span
                          className={`tabHeading ${subTabIndex === 4 && "tabActive tabActiveSecondary"
                            }`}
                        >
                          Address
                        </span>
                      }
                    />

                    <Tab
                      label={
                        <span
                          className={`tabHeading ${subTabIndex === 5 && "tabActive tabActiveSecondary"
                            }`}
                        >
                          Identification
                        </span>
                      }
                    />
                    <Tab
                      label={
                        <span
                          className={`tabHeading ${subTabIndex === 6 && "tabActive tabActiveSecondary"
                            }`}
                        >
                          Payment
                        </span>
                      }
                    />
                  </Tabs>
                  <div className="tabContainerLine"></div>
                </div>
              )}
              {loading ? (
                <Loader />
              ) : (
                <>
                  {tabIndex === 0 && (
                    <>
                      {employeeData && (
                        <>
                          {subTabIndex === 0 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: "0" }}
                              >
                                Employee Profile
                              </div>
                              <DetailData
                                detailTitle="Employee ID"
                                detailData={
                                  employeeData?._employees?.personNumber
                                }
                              />
                              <DetailData
                                detailTitle="Title"
                                detailData={
                                  employeeData?._employees?.initialName
                                }
                              />
                              <DetailData
                                detailTitle="First Name"
                                detailData={employeeData?._employees?.name}
                              />
                              {/* <DetailData
                        detailTitle="Middle Name"
                        detailData={employeeData?._employees?.middleName}
                      /> */}
                              <DetailData
                                detailTitle="Last Name"
                                detailData={
                                  employeeData?._employees?.lastName ?? ""
                                }
                              />
                              {/* <DetailData
                        detailTitle="Date of Birth"
                        detailData={employeeData?._employees?.dob}
                      /> */}
                              <DetailData
                                detailTitle="Official Email Id"
                                detailData={employeeData?._employees?.email}
                              />
                              <DetailData
                                detailTitle="Official Mobile Number"
                                detailData={
                                  employeeData?._employees?.officePhoneNumber
                                }
                              />
                              {/* <DetailData
                        detailTitle="Blood Group"
                        detailData={employeeData?._employees?.bloodGroup}
                      />
                      <DetailData
                        detailTitle="Extension Number"
                        detailData={employeeData?._employees?.extensionNumber}
                      /> */}

                              {/* <DetailData
                        detailTitle="Role"
                        detailData={employeeData?._employees?.role}
                      /> */}
                              {/* <DetailData
                        detailTitle="Gender "
                        detailData={employeeData?._employees?.gender}
                      /> */}
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: "0" }}
                              >
                                Organisation Details
                              </div>
                              <DetailData
                                detailTitle="Employee Status"
                                detailData={
                                  employeeData?._employees?.employmentStatus
                                }
                              />
                              <DetailData
                                detailTitle="Employee Type"
                                detailData={
                                  employeeData?._employees?.employeeTypeCode
                                }
                              />
                              <DetailData
                                detailTitle="Date of Joining"
                                detailData={
                                  employeeData?._employees?.doj
                                    ? format(
                                      new Date(employeeData?._employees?.doj),
                                      "dd/MM/yyyy"
                                    )
                                    : null
                                }
                              />
                              <DetailData
                                detailTitle="Probation End Date"
                                detailData={
                                  employeeData?._employees?.probationEndDate
                                    ? format(
                                      new Date(
                                        employeeData?._employees?.probationEndDate
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : null
                                }
                              />
                              <DetailData
                                detailTitle="Reporting Manager"
                                detailData={employeeData?.reportingOfficer}
                              />
                              <DetailData
                                detailTitle="HOD"
                                detailData={employeeData?._employees?.fhoD_Name}
                              />
                              <DetailData
                                detailTitle="Department"
                                detailData={
                                  employeeData?._employees?.department
                                }
                              />

                              <DetailData
                                detailTitle="Sub Department"
                                detailData={
                                  employeeData?._employees?.subDepartment
                                }
                              />
                              <DetailData
                                detailTitle="PSA Location"
                                detailData={employeeData?.location?.name}
                              />
                              <DetailData
                                detailTitle="Area Location"
                                detailData={employeeData?._employees?.area}
                              />

                              <DetailData
                                detailTitle="Designation"
                                detailData={
                                  employeeData?._employees?.designation
                                }
                              />
                              {/* TO BE ADDED */}
                              <DetailData
                                detailTitle="Role"
                                detailData={
                                  employeeData?._employees?.role ??
                                  employeeData?.systemroles
                                }
                              />

                              <DetailData
                                detailTitle="Band"
                                detailData={
                                  employeeData?._employees?.band?.name
                                }
                              />

                              <DetailData
                                detailTitle="Grade"
                                detailData={employeeData?._employees?.grade}
                              />
                              <DetailData
                                detailTitle="Shift"
                                detailData={
                                  employeeData?._employees?.shift?.title
                                }
                              />

                              {/* <DetailData
                        detailTitle="Photo"
                        detailData={employeeData?._employees?.photo}
                      /> */}
                              {/* <DetailData
                        detailTitle="Personal Email"
                        detailData={employeeData?._employees?.personalEmail}
                      /> */}
                            </div>
                          )}
                          {subTabIndex === 4 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: 0 }}
                              >
                                Permanent Address
                              </div>
                              {/* <DetailData
                        detailTitle="C/o Name "
                        detailData={employeeData?._employees?.permanentConame}
                      /> */}
                              <DetailData
                                detailTitle="Address"
                                detailData={
                                  employeeData?._employees?.permanentAddress
                                }
                              />
                              <DetailData
                                detailTitle="Address Line 2"
                                detailData={
                                  employeeData?._employees
                                    ?.permanentAddressLine2
                                }
                              />
                              <DetailData
                                detailTitle="City"
                                detailData={
                                  employeeData?._employees?.permanentAddressCity
                                }
                              />

                              {/* <DetailData
                        detailTitle="District "
                        detailData={
                          employeeData?._employees?.permanentAddressDistrict
                        }
                      /> */}
                              {/* <DetailData
                        detailTitle="House Number"
                        detailData={
                          employeeData?._employees?.permanentHouseNumber
                        }
                      />
                      <DetailData
                        detailTitle="Identification of an apartment"
                        detailData={
                          employeeData?._employees
                            ?.permanentIdentificationOfAnApartment
                        }
                      /> */}
                              <DetailData
                                detailTitle="State"
                                detailData={
                                  employeeData?._employees
                                    ?.permanentAddressState ??
                                  employeeData?._employees?.currentAddressState
                                }
                              />
                              <DetailData
                                detailTitle="Pincode/Zip Code "
                                detailData={
                                  employeeData?._employees
                                    ?.permanentAddressPincode
                                }
                              />
                              <DetailData
                                detailTitle="Country"
                                detailData={
                                  employeeData?._employees
                                    ?.permanentAddressCountry
                                }
                              />
                              {/* <DetailData
                        detailTitle="Phone Number"
                        detailData={
                          employeeData?._employees?.permanentTelephoneNumber
                        }
                      />

                      <DetailData
                        detailTitle="C/o Name "
                        detailData={employeeData?._employees?.currentConame}
                      /> */}
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: 0 }}
                              >
                                Current Address
                              </div>
                              <DetailData
                                detailTitle="Address"
                                detailData={
                                  employeeData?._employees?.currentAddress
                                }
                              />
                              <DetailData
                                detailTitle="Address Line 2"
                                detailData={
                                  employeeData?._employees?.currentAddressLine2
                                }
                              />
                              <DetailData
                                detailTitle="City"
                                detailData={
                                  employeeData?._employees?.currentAddressCity
                                }
                              />
                              {/* <DetailData
                        detailTitle="District "
                        detailData={
                          employeeData?._employees?.currentAddressDistrict
                        }
                      />
                      <DetailData
                        detailTitle="House Number"
                        detailData={
                          employeeData?._employees?.currentHouseNumber
                        }
                      />
                      <DetailData
                        detailTitle="Identification of an apartment"
                        detailData={
                          employeeData?._employees
                            ?.currentIdentificationOfAnApartment
                        }
                      /> */}
                              <DetailData
                                detailTitle="State"
                                detailData={
                                  employeeData?._employees?.currentAddressState
                                }
                              />
                              <DetailData
                                detailTitle="Pincode/Zip Code "
                                detailData={
                                  employeeData?._employees
                                    ?.currentAddressPincode
                                }
                              />
                              <DetailData
                                detailTitle="Country"
                                detailData={
                                  employeeData?._employees
                                    ?.currentAddressCountry
                                }
                              />
                            </div>
                          )}

                          {subTabIndex === 5 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <DetailData
                                detailTitle="Aadhaar Number"
                                detailData={
                                  employeeData?._employees?.aaadharNumber
                                }
                              />
                              <DetailData
                                detailTitle="PAN Number"
                                detailData={employeeData?._employees?.panNumber}
                              />
                              <DetailData
                                detailTitle="UAN Number"
                                detailData={employeeData?._employees?.uannumber}
                              />
                              {/* <DetailData
                        detailTitle="Provident Fund Number"
                        detailData={employeeData?._employees?.pfNumber}
                      /> */}
                              <DetailData
                                detailTitle="Passport Number"
                                detailData={
                                  employeeData?._employees?.passportNumber
                                }
                              />
                              <DetailData
                                detailTitle="Passport Issue Date"
                                detailData={
                                  employeeData?._employees?.passportIsuueDate
                                    ? format(
                                      new Date(
                                        employeeData?._employees?.passportIsuueDate
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : null
                                }
                              />
                              <DetailData
                                detailTitle="Passport Expiry Date"
                                detailData={
                                  employeeData?._employees?.passportExpiryDate
                                    ? format(
                                      new Date(
                                        employeeData?._employees?.passportExpiryDate
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                    : null
                                }
                              />
                            </div>
                          )}

                          {subTabIndex === 6 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <DetailData
                                detailTitle="Cost Centre"
                                detailData={
                                  employeeData?._employees?.costCentre
                                }
                              />
                              <DetailData
                                detailTitle="Is Payroll Employee"
                                detailData={
                                  employeeData?._employees?.isPayroll
                                    ? "Yes"
                                    : "No"
                                }
                              />
                              <DetailData
                                detailTitle="Payment Mode"
                                detailData={
                                  employeeData?._employees?.paymentMode
                                }
                              />
                              <DetailData
                                detailTitle="Account Holder Name"
                                detailData={
                                  employeeData?._employees?.accountHolderName
                                }
                              />
                              <DetailData
                                detailTitle="Bank Account Number"
                                detailData={
                                  employeeData?._employees?.bankAccountNumber
                                }
                              />
                              <DetailData
                                detailTitle="IFSC Code"
                                detailData={employeeData?._employees?.ifsccode}
                              />
                              <DetailData
                                detailTitle="Bank Name"
                                detailData={
                                  employeeData?._employees?.branchName
                                }
                              />

                              <DetailData
                                detailTitle="Type Of Account"
                                detailData={
                                  employeeData?._employees?.typeofAccount
                                }
                              />
                            </div>
                          )}

                          {subTabIndex === 1 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: "0" }}
                              >
                                Personal Details
                              </div>
                              <DetailData
                                detailTitle="Personal Email Id"
                                detailData={
                                  employeeData?._employees?.personalEmail
                                }
                              />
                              <DetailData
                                detailTitle="Personal Mobile Number"
                                detailData={
                                  employeeData?._employees?.personalPhoneNumber
                                }
                              />
                              <DetailData
                                detailTitle="Date Of Birth"
                                detailData={
                                  employeeData?._employees?.dob
                                    ? format(
                                      new Date(employeeData?._employees?.dob),
                                      "dd/MM/yyyy"
                                    )
                                    : null
                                }
                              />
                              <DetailData
                                detailTitle="Gender"
                                detailData={employeeData?._employees?.gender}
                              />
                              <DetailData
                                detailTitle="Religion"
                                detailData={employeeData?._employees?.religion}
                              />
                              <DetailData
                                detailTitle="Nationality"
                                detailData={
                                  employeeData?._employees?.nationality
                                }
                              />
                              <DetailData
                                detailTitle="Language "
                                detailData={employeeData?._employees?.language}
                              />
                              <DetailData
                                detailTitle="Marital Status"
                                detailData={
                                  employeeData?._employees?.martialStatus
                                }
                              />
                              {employeeData?._employees?.martialStatus ===
                                "Married" && (
                                  <DetailData
                                    detailTitle="Date of Marriage"
                                    detailData={
                                      employeeData?._employees?.dom
                                        ? format(
                                          new Date(
                                            employeeData?._employees?.dom
                                          ),
                                          "dd/MM/yyyy"
                                        )
                                        : null
                                    }
                                  />
                                )}

                              <DetailData
                                detailTitle="Overall Experience"
                                detailData={
                                  employeeData?._employees?.currentExperience
                                    ? `${employeeData?._employees?.currentExperience} Years`
                                    : `${employeeData?._employees?.previousExperience} Years`
                                }
                              />
                              <DetailData
                                detailTitle="Current Salary"
                                detailData={
                                  employeeData?._employees?.cuurentSalary
                                    ? `${employeeData?._employees?.cuurentSalary} Lakhs`
                                    : null
                                }
                              />
                              <DetailData
                                detailTitle="Group Health Insurance"
                                detailData={
                                  employeeData?._employees?.groupHealthInsurance
                                }
                              />
                              <DetailData
                                detailTitle="Offer Letter"
                                detailData={
                                  employeeData?._employees?.offerLetter ? (
                                    <UploadDetail
                                      filepath={`OfferLetter/${employeeData?._employees?.offerLetter}`}
                                    />
                                  ) : (
                                    "NA"
                                  )
                                }
                              />
                              <DetailData
                                detailTitle="Profile Photo"
                                detailData={
                                  employeeData?._employees?.image ? (
                                    <UploadImageDetail
                                      filepath={`ProfileImageUploads/${employeeData?._employees?.image}`}
                                    />
                                  ) : (
                                    "NA"
                                  )
                                }
                              />
                              {/* <DetailData
                        detailTitle="Source of Hire"
                        detailData={employeeData?._employees?.sourceOfHigher}
                      /> */}
                              {employeeData?._employees?.martialStatus ===
                                "Married" && (
                                  <>
                                    <div
                                      className="headingForm"
                                      style={{ width: "100%", margin: 0 }}
                                    >
                                      Spouse Details
                                    </div>
                                    <DetailData
                                      detailTitle="Spouse Name"
                                      detailData={
                                        employeeData?._employees?.spouseName
                                      }
                                    />
                                    <DetailData
                                      detailTitle="Spouse Mobile Number"
                                      detailData={
                                        employeeData?._employees
                                          ?.spouseMobileNumber
                                      }
                                    />
                                    <DetailData
                                      detailTitle="No. of Children"
                                      detailData={
                                        employeeData?._employees?.numberofChildren
                                      }
                                    />
                                  </>
                                )}
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: 0 }}
                              >
                                Emergency Contact Details
                              </div>
                              <DetailData
                                detailTitle="Person Name"
                                detailData={
                                  employeeData?._employees?.emergencyContactName
                                }
                              />
                              {/* <DetailData
                        detailTitle="Mobile Number"
                        detailData={
                          employeeData?._employees?.emergencyContactNumber
                        }
                      /> */}
                              <DetailData
                                detailTitle="Relationship"
                                detailData={
                                  employeeData?._employees?.relationShip
                                }
                              />
                              {/* <DetailData
                        detailTitle="Address"
                        detailData={employeeData?._employees?.shift}
                      /> */}
                              <DetailData
                                detailTitle="Emergency Mobile Number"
                                detailData={
                                  employeeData?._employees
                                    ?.emergencyContactNumber
                                }
                              />
                              {/* <DetailData
                        detailTitle="Birth Country"
                        detailData={employeeData?._employees?.birthCountry}
                      /> */}
                              {/* <DetailData
                        detailTitle="State"
                        detailData={employeeData?._employees?.state}
                      />
                      <DetailData
                        detailTitle="Highest Qualification"
                        detailData={
                          employeeData?._employees?.highestQualification
                        }
                      /> */}

                              {/* <DetailData
                        detailTitle="Additional Information"
                        detailData={
                          employeeData?._employees?.additionalInformation
                        }
                      />
                      <DetailData
                        detailTitle="Skillset"
                        detailData={employeeData?._employees?.skillSet}
                      /> */}
                              {/* <DetailData
                        detailTitle="Occupation"
                        detailData={employeeData?._employees?.occupation}
                      />
                      <DetailData
                        detailTitle="Company Name"
                        detailData={employeeData?._employees?.companyName}
                      />
                      <DetailData
                        detailTitle="Spouse Working?"
                        detailData={employeeData?._employees?.spouseWorking}
                      /> */}
                              <div style={{ width: "100%" }}>
                                <div
                                  className="headingForm"
                                  style={{ margin: 0 }}
                                >
                                  Dependent Details
                                </div>
                                <div
                                  style={{ width: "100%", padding: "12px" }}
                                  className="repeatedSectionContainer"
                                >
                                  <table className="tabless tabless-bordered">
                                    <thead
                                      style={{
                                        backgroundColor: "var(--primary-hover)",
                                      }}
                                    >
                                      <tr>
                                        <td>S. No.</td>
                                        <td>Name</td>
                                        <td>Date of Birth</td>
                                        <td>Relationship</td>
                                        <td>Gender</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {employeeData?._employees?.dependentList
                                        ?.length > 0
                                        ? employeeData?._employees?.dependentList?.map(
                                          (item, index) => {
                                            return (
                                              <tr className="trStyle">
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>
                                                  {item.dob
                                                    ? format(
                                                      new Date(item.dob),
                                                      "dd/MM/yyyy"
                                                    )
                                                    : ""}
                                                </td>
                                                <td>{item.relationship}</td>
                                                <td>{item.gender}</td>
                                              </tr>
                                            );
                                          }
                                        )
                                        : "No Records"}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}

                          {subTabIndex === 2 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div
                                style={{ width: "100%", padding: "12px" }}
                                className="repeatedSectionContainer"
                              >
                                <table className="tabless tabless-bordered">
                                  <thead
                                    style={{
                                      backgroundColor: "var(--primary-hover)",
                                    }}
                                  >
                                    <tr>
                                      <td>S. No.</td>
                                      <td>Education Level</td>
                                      <td>Specialization</td>
                                      <td>College/School</td>
                                      <td>Year of Passing</td>
                                      <td>Grade/%</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {employeeData?._employees?.educationlist
                                      ?.length > 0
                                      ? employeeData?._employees?.educationlist?.map(
                                        (item, index) => {
                                          return (
                                            <tr className="trStyle">
                                              <td>{index + 1}</td>
                                              <td>{item.educationLevel}</td>
                                              <td>{item.degree}</td>
                                              <td>{item.college}</td>
                                              <td>
                                                {item.yearofPassingToYear}
                                              </td>
                                              <td>{item.grade}</td>
                                            </tr>
                                          );
                                        }
                                      )
                                      : "No Records"}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                          {subTabIndex === 3 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div
                                style={{ width: "100%", padding: "12px" }}
                                className="repeatedSectionContainer"
                              >
                                <table className="tabless tabless-bordered">
                                  <thead
                                    style={{
                                      backgroundColor: "var(--primary-hover)",
                                    }}
                                  >
                                    <tr>
                                      <td>S. No.</td>
                                      <td>Job Role</td>
                                      <td>Job Position</td>
                                      <td>Company</td>
                                      <td>Duration (in Years)</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {employeeData?._employees?.experiencelist
                                      ?.length > 0
                                      ? employeeData?._employees?.experiencelist?.map(
                                        (item, index) => {
                                          return (
                                            <tr className="trStyle">
                                              <td>{index + 1}</td>
                                              <td>{item.occupation}</td>
                                              <td>{item.summary}</td>
                                              <td>{item.company}</td>
                                              <td>{item.duration}</td>
                                            </tr>
                                          );
                                        }
                                      )
                                      : "No Records"}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {tabIndex === 1 && (
                    <>
                      <div className="tabularMasterDetailsPageTabBodyContainer">
                        <div style={{ display: "flex", width: "100%" }}>
                          <Timeline
                            id={selectedId}
                            module={"Employees Onboarding"}
                            requestNumber={
                              employeeData?.candidateOnboardings
                                ? employeeData?.candidateOnboardings[0]
                                  ?.referenceNo
                                : null
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {tabIndex === 2 && (
                    <>
                      <div
                        className="tabularMasterDetailsPageTabBodyContainer"
                        style={{ flexGrow: "initial" }}
                      >
                        <Notifications
                          id={selectedId}
                          module={"Employees Onboarding"}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Records"}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPagePayroll };

import React, { useEffect, useState } from "react";
import { HouseRentComp } from "./HouseRentComp";
import { HomeLoanComp } from "./HomeLoanComp";
import { RentalIncomeComp } from "./RentalIncomeComp";

const ParticularQuestionComp = ({ quesText, values, editValues, switchActive, setSwitchActive, updatedValues }) => {
  // const [switchActive, setSwitchActive] = useState(
  //   editValues ? true : false
  // );

  const handleSwitchClick = () => {
    setSwitchActive(!switchActive);
  };
  useEffect(() => {
    let switchActiveManual = false;

    if (
      (quesText.includes("staying") && editValues?.landlordPan) ||
      (quesText.includes("repaying") && editValues?.houseLoanPrincipal) ||
      (quesText.includes("receiving") && editValues?.rentalIncomeData)
    ) {
      switchActiveManual = true;
    }

    // setSwitchActive(switchActiveManual);
  }, [editValues, quesText]);
  useEffect(() => {
    document.title = `PeopleSol -  Particular Questions`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="particularAllHolder">
      <div
        className="particularQuestionHolder"
        style={{ background: `${switchActive ? "#EFF9FA" : "white"}` }}
      >
        <div className="particularQuestion" style={{ paddingLeft: "26px" }}>
          <label style={{ fontWeight: "500" }}>{quesText}</label>
        </div>
        <div
          className="particularAnswer cursor"
          onClick={handleSwitchClick}
        >
          {switchActive && quesText?.includes("staying") && updatedValues?.selectedStateLimit && updatedValues?.selectedStateLimit.split("-")[1] != ""
            ? <div style={{ marginRight: 20, fontWeight: "500" }}>
              {
                "Max Limit : "
                +
                updatedValues.selectedStateLimit.split("-")[1]}
            </div> : null
          }
          {switchActive ? (
            <>
              <label style={{ marginRight: "5px" }}>Yes</label>
              <svg
                width="32"
                height="23"
                viewBox="0 0 32 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.961426"
                  y="5.14746"
                  width="25.0104"
                  height="8.33681"
                  rx="3"
                  fill="#D8F0F2"
                />
                <g filter="url(#filter0_d_501_5500)">
                  <circle
                    cx="20.4139"
                    cy="9.31545"
                    r="6.94734"
                    fill="#1296B0"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_501_5500"
                    x="9.46655"
                    y="0.368103"
                    width="21.8948"
                    height="21.8947"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_501_5500"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_501_5500"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </>
          ) : (
            <>
              <label style={{ marginRight: "5px" }}>No</label>
              <svg
                width="32"
                height="23"
                viewBox="0 0 32 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="25.0104"
                  height="8.33681"
                  rx="3"
                  transform="matrix(-1 0 0 1 31.3613 5.30078)"
                  fill="#BFBFBF"
                />
                <g filter="url(#filter0_d_501_5361)">
                  <circle
                    cx="6.94734"
                    cy="6.94734"
                    r="6.94734"
                    transform="matrix(-1 0 0 1 18.8562 2.52142)"
                    fill="#555555"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_501_5361"
                    x="0.961426"
                    y="0.521423"
                    width="21.8948"
                    height="21.8947"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_501_5361"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_501_5361"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </>
          )}
        </div>
      </div>
      {switchActive && quesText?.includes("staying") && (
        <HouseRentComp
          updatedValues={updatedValues}
          // crossHandler={() => setSwitchActive((prev) => !prev)}
          switchActive={switchActive}
          values={values}
        />
      )}
      {switchActive && quesText?.includes("repaying") && (
        <HomeLoanComp
          // crossHandler={() => setSwitchActive((prev) => !prev)}
          switchActive={switchActive}
          values={values}
        />
      )}
      {/* {switchActive && quesText?.includes("receiving") && (
        <RentalIncomeComp
          switchActive={switchActive}
          values={values}
        />
      )} */}
    </div>
  );
};

export { ParticularQuestionComp };

import React, { useEffect, useState } from "react";
import DropdownArrowChatMesssage from "../../../Assets/DropdownArrowChatMesssage.svg";
import pdfDocIcon from "../../../Assets/pdfDocIcon.svg";
import pdfDocIconRecieve from "../../../Assets/pdfDocIconRecieve.svg";
import starWhite from "../../../Assets/starWhite.svg";
import starYellow from "../../../Assets/starYellow.svg";
import DoubleTick from "../../../Assets/DoubleTick.svg";
import DoubleTickGrey from "../../../Assets/DoubleTickGrey.svg";
import SingleTick from "../../../Assets/SingleTick.svg";
import Forward from "../../../Assets/Forward.svg";
import ForwardBlue from "../../../Assets/ForwardBlue.svg";
import ZipSend from "../../../Assets/ZipSend.svg";
import ZipRecieve from "../../../Assets/ZipReceive.svg"
import DropdownArrowChatMesssageWhite from "../../../Assets/DropdownArrowChatMesssageWhite.svg";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import PDFComponent from "./PDFComponent";
import { downloadBlobChat } from "./chatServices";
import { scrollFunc } from "../ChatInterface";
import { useSelector } from "react-redux";

const DocumentsChat = ({
  message,
  messageClass,
  setPopupOpen,
  popupOpen,
  setReplyData,
  setForwardData,
  bookMarkMessage,
  setSelectedMessage,
  deleteMessage,
  handleReplyPrivately,
  LoggedInUser,
  messageType,
  activeChat
}) => {
  const [numPages, setNumPages] = useState();
  const [base64BLobImg, setBase64BLobImg] = useState("");
  const userDetails = useSelector(state => state.chat.userDetails);
  const myContactId = userDetails._id;

  const downloadFile = (item) => {

    if (item.url?.slice(-3) === "pdf") {
      downloadBlobChat(
        base64BLobImg,
        "pdf",
        item.url,
        item.url.split("/").pop()
      );
    } else if (item.url?.slice(-3) === "doc") {
      downloadBlobChat(
        base64BLobImg,
        "doc",
        item.url,
        item.url.split("/").pop()
      );
    }
    else if (item.url?.slice(-4) === "docx") {
      downloadBlobChat(
        base64BLobImg,
        "docx",
        item.url,
        item.url.split("/").pop()
      );
    } else if (item.url?.slice(-4) === "xlsx") {
      downloadBlobChat(
        base64BLobImg,
        "xlsx",
        item.url,
        item.url.split("/").pop()
      );
    } else if (item.url?.slice(-3) === "png") {
      downloadBlobChat(
        base64BLobImg,
        "png",
        item.url,
        item.url.split("/").pop()
      );
    } else if (item.url?.slice(-3) === "jpg") {
      downloadBlobChat(
        base64BLobImg,
        "jpg",
        item.url,
        item.url.split("/").pop()
      );
    } else if (item.url?.slice(-4) === "jpeg") {
      downloadBlobChat(
        base64BLobImg,
        "jpeg",
        item.url,
        item.url.split("/").pop()
      );
    }
    else if (item.url?.slice(-3) === "zip") {
      downloadBlobChat(
        base64BLobImg,
        "zip",
        item.url,
        item.url.split("/").pop()
      );
    }
    else if (item.url?.slice(-4) === "gzip") {
      downloadBlobChat(
        base64BLobImg,
        "gzip",
        item.url,
        item.url.split("/").pop()
      );
    }
  }

  const openFile = (url) => {
    // Create an anchor element dynamically
    if (message.type == 11) return;
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';  // Opens in new tab if needed
    anchor.download = url.split('/').pop(); // Extracts the file name from URL

    // Append anchor to body and trigger click to start download
    document.body.appendChild(anchor);
    anchor.click();

    // Remove the anchor after triggering download
    document.body.removeChild(anchor);
  };
  return (
    <>
      {message?.replyMsgObj ? (
        <div
          style={
            messageClass === "sent"
              ? {
                backgroundColor: "var(--primary)",
                borderRadius: "6px 0px 6px 6px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }
              : {
                backgroundColor: "var(--primary)",
                borderRadius: "0px 6px 6px 6px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }
          }
        >
          <div
            className="cursor"
            onClick={(e) => {
              // scrollFunc(message?.replyingToMsgId);
              e.stopPropagation();
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            {message?.replyMsgObj?.content?.map((item, index) => {
              return (
                <div
                  className="message-content cursor unselectable"
                  style={
                    index === 0
                      ? messageClass === "sent"
                        ? {
                          display: "flex",
                          padding: "8px",
                          borderRadius: "6px 0px 6px 6px",
                          position: "relative",
                          maxWidth: "100%",

                          margin: "0px 0px 7px 0px",
                          backgroundColor: "#0E788D",
                        }
                        : {
                          display: "flex",
                          padding: "8px",

                          borderRadius: "0px 6px 6px 6px",
                          position: "relative",
                          maxWidth: "100%",
                          margin: "0px 0px 7px 0px",
                        }
                      : messageClass === "sent"
                        ? {
                          display: "flex",
                          padding: "8px",
                          borderRadius: "6px 6px 6px 6px",
                          position: "relative",
                          maxWidth: "100%",

                          margin: "0px 0px 7px 0px",
                          backgroundColor: "#0E788D",
                        }
                        : {
                          display: "flex",
                          padding: "8px",

                          borderRadius: "6px 6px 6px 6px",
                          position: "relative",
                          maxWidth: "100%",
                          margin: "0px 0px 7px 0px",
                        }
                  }
                >
                  {" "}
                  <div
                    style={
                      index === 0
                        ? messageClass === "sent"
                          ? {
                            backgroundColor: "#6ABCCC",
                            height: "6px",
                            position: "absolute",
                            width: "100%",
                            left: "0px",
                            top: "0px",
                            borderRadius: "6px 0px 0px 0px",
                          }
                          : {
                            backgroundColor: "#6ABCCC",
                            height: "6px",
                            position: "absolute",
                            width: "100%",
                            left: "0px",
                            top: "0px",
                            borderRadius: "0px 6px 0px 0px",
                          }
                        : messageClass === "sent"
                          ? {
                            backgroundColor: "#6ABCCC",
                            height: "6px",
                            position: "absolute",
                            width: "100%",
                            left: "0px",
                            top: "0px",
                            borderRadius: "6px 6px 0px 0px",
                          }
                          : {
                            backgroundColor: "#6ABCCC",
                            height: "6px",
                            position: "absolute",
                            width: "100%",
                            left: "0px",
                            top: "0px",
                            borderRadius: "6px 6px 0px 0px",
                          }
                    }
                  ></div>
                  <div
                    style={{
                      width: "98%",
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "-14px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "7px",
                        margin: "6px 0px 0px 0px",
                        justifyContent: "space-between",
                        width: "286px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            style={{ height: "30px" }}
                            src={
                              messageClass === "sent"
                                ? message.replyMsgObj.type === 11 ? ZipSend : pdfDocIcon
                                : message.replyMsgObj.type === 11 ? ZipRecieve : pdfDocIconRecieve
                            }
                            alt=""
                          />
                        </div>
                        <div>
                          <div>{item.url.split("/").pop()}</div>
                          {item.url?.slice(-3) === "pdf" ? (
                            <div>
                              {numPages} Page .{" "}
                              {item.url?.slice(-3)?.toUpperCase()}
                            </div>
                          ) : item.url?.slice(-3) === "jpg" ||
                            item.url?.slice(-3) === "png" ||
                            item.url?.slice(-3) === "svg" ? (
                            <div>{item.url?.slice(-3)}</div>
                          ) : item.url?.slice(-4) === "jpeg" ||
                            item.url?.slice(-4) === "xlsx" ||
                            item.url?.slice(-4) === "docx" ? (
                            <div>{item.url?.slice(-4)?.toUpperCase()}</div>
                          ) : (
                            <div> File</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {index === 0 && (
                    <>
                      <div
                        onClick={(e) => {
                          setPopupOpen(true);
                          e.stopPropagation();
                        }}
                        style={{
                          width: "15px",
                          height: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          className="DropdownArrowChatDesign"
                          style={
                            popupOpen
                              ? { display: "block", margin: "7px 0 0 0" }
                              : { margin: "7px 0 0 0" }
                          }
                          src={
                            messageClass === "sent"
                              ? DropdownArrowChatMesssageWhite
                              : DropdownArrowChatMesssage
                          }
                          alt=""
                        />
                      </div>

                      <OutsideClickHandler
                        key={message?._id}
                        id={message?._id}
                        onOutsideClick={(id) =>
                          setPopupOpen(false)
                        }
                      >
                        <div
                          className="chatActionsPopup"
                          style={
                            popupOpen
                              ? {
                                display: "block",
                                position: "absolute",
                                zIndex: "6",
                                right: "-50px",
                                top: "26px",
                                color: "black",
                              }
                              : { display: "none" }
                          }
                        >
                          <div
                            style={{ borderRadius: "6px 6px 0px 0px" }}
                            onClick={(e) => {
                              setReplyData({
                                replyId: message._id,
                                replyContent: message?.caption,
                                data: message,
                              });
                              setPopupOpen(false);
                              e.stopPropagation();
                            }}
                          >
                            Reply
                          </div>
                          {messageClass == "sent" && <div
                            onClick={(e) => {
                              setSelectedMessage(message);
                              e.stopPropagation();
                              setPopupOpen(false);
                            }}
                          >Info</div>}
                          <div
                            onClick={(e) => {
                              setForwardData({
                                isActive: true,
                                msgId: [message?._id],
                                userId: [],
                                conversationId: [],
                              });
                              setPopupOpen(false);
                              e.stopPropagation();
                            }}
                          >
                            Forward Message
                          </div>
                          {activeChat.type === "Group" && messageClass != "sent" && <div
                            onClick={(e) => {
                              handleReplyPrivately(message);
                              e.stopPropagation();
                            }}
                          >
                            Reply Privately
                          </div>}

                          <div
                            onClick={() => {
                              bookMarkMessage(message._id, myContactId, message.isBookmarked ? "unstar" : "star");
                              setPopupOpen(false);
                              setForwardData({
                                isActive: false,
                                msgId: [],
                                userId: [],
                                conversationId: [],
                              });
                            }}
                          >
                            {message.isBookmarked ? "Unstar" : "Star"}
                          </div>
                          <div
                            style={{ borderRadius: "0px 0px 6px 6px" }}
                            onClick={() => {
                              deleteMessage(
                                message?._id,
                                userDetails._id,
                                messageClass
                              );
                              setPopupOpen(false);
                            }}
                          >
                            Delete Message
                          </div>
                        </div>
                      </OutsideClickHandler>
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <div
            style={
              message?.content?.length > 65
                ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }
                : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }
            }
          >
            <div
              style={
                message?.content?.length > 65
                  ? {
                    maxWidth: "427px",
                    width: "100%",
                    color: "white",
                    overflowWrap: "break-word",
                  }
                  : {
                    maxWidth: "427px",
                    width: "calc(100% - 50px)",
                    color: "white",
                  }
              }
            >
              {message?.caption}
            </div>

            <div
              style={
                message?.content?.length > 65
                  ? {
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }
                  : {
                    display: "flex",
                    justifyContent: "flex-end",
                  }
              }
            >
              <div
                style={{
                  display: "flex",
                  textAlign: "end",
                  width: "50px",
                  alignItems: "flex-end",
                  justifyContent: "flex-end", // backgroundColor: "blueviolet",
                }}
              >
                {message.isBookmarked === true && (
                  <img
                    src={messageClass === "sent" ? starWhite : starYellow}
                    style={{ margin: "0px 5px 0px 5px" }}
                    alt="bookmark"
                  />
                )}
                {message.msgStatus === 3 && messageClass === "sent" ? <img src={DoubleTick} alt="read" /> : messageClass === "sent" ? <img src={SingleTick} alt="unread" />
                  : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div onClick={(e) => {
          openFile(message.content[0].url)
          e.stopPropagation()
        }} style={{ display: "flex", flexDirection: "column" }}>
          {message?.content?.map((item, index) => {
            return (
              <div
                className="message-content cursor unselectable"
                style={
                  index === 0
                    ? messageClass === "sent"
                      ? {
                        display: "flex",
                        padding: "8px",
                        borderRadius: "6px 0px 6px 6px",
                        position: "relative",
                        maxWidth: "100%",
                        margin: "0px 0px 7px 0px",
                      }
                      : {
                        display: "flex",
                        padding: "8px",
                        borderRadius: "0px 6px 6px 6px",
                        position: "relative",
                        maxWidth: "100%",
                        margin: "0px 0px 7px 0px",
                      }
                    : messageClass === "sent"
                      ? {
                        display: "flex",
                        padding: "8px",
                        borderRadius: "6px 6px 6px 6px",
                        position: "relative",
                        maxWidth: "100%",
                        margin: "0px 0px 7px 0px",
                      }
                      : {
                        display: "flex",
                        padding: "8px",
                        borderRadius: "6px 6px 6px 6px",
                        position: "relative",
                        maxWidth: "100%",
                        margin: "0px 0px 7px 0px",
                      }
                }
              >
                {" "}
                <div
                  style={
                    index === 0
                      ? messageClass === "sent"
                        ? {
                          backgroundColor: "#6ABCCC",
                          height: "6px",
                          position: "absolute",
                          width: "100%",
                          left: "0px",
                          top: "0px",
                          borderRadius: "6px 0px 0px 0px",
                        }
                        : {
                          backgroundColor: "#6ABCCC",
                          height: "6px",
                          position: "absolute",
                          width: "100%",
                          left: "0px",
                          top: "0px",
                          borderRadius: "0px 6px 0px 0px",
                        }
                      : messageClass === "sent"
                        ? {
                          backgroundColor: "#6ABCCC",
                          height: "6px",
                          position: "absolute",
                          width: "100%",
                          left: "0px",
                          top: "0px",
                          borderRadius: "6px 6px 0px 0px",
                        }
                        : {
                          backgroundColor: "#6ABCCC",
                          height: "6px",
                          position: "absolute",
                          width: "100%",
                          left: "0px",
                          top: "0px",
                          borderRadius: "6px 6px 0px 0px",
                        }
                  }
                ></div>
                <div
                  style={{
                    width: "98%",
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "-14px",
                  }}
                >
                  {message.forwardStatus &&
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: 5 }}>
                      <img src={messageClass === "sent" ? Forward : ForwardBlue} alt="forward" />
                      <p style={{ fontStyle: "italic", fontSize: 9, fontWeight: "400", marginLeft: 10 }}>Forwarded</p>
                    </div>
                  }
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "7px",
                      margin: "6px 0px 0px 0px",
                      justifyContent: "space-between",
                      width: "286px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          style={{ height: "30px" }}
                          src={
                            messageClass === "sent"
                              ? message.type === 11 ? ZipSend : pdfDocIcon
                              : message.type === 11 ? ZipRecieve : pdfDocIconRecieve
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <div>{item.url.split("/").pop()}</div>
                        {item.url?.slice(-3) === "pdf" ? (
                          <div>
                            {numPages} Page . {item.url?.slice(-3)?.toUpperCase()}
                          </div>
                        ) : item.url?.slice(-3) === "jpg" ||
                          item.url?.slice(-3) === "png" ||
                          item.url?.slice(-3) === "svg" ? (
                          <div>{item.url?.slice(-3)}</div>
                        ) : item.url?.slice(-4) === "jpeg" ||
                          item.url?.slice(-4) === "xlsx" ||
                          item.url?.slice(-4) === "docx" ? (
                          <div>{item.url?.slice(-4)?.toUpperCase()}</div>
                        ) : (
                          <div> File</div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          textAlign: "end",
                          width: "50px",
                          alignItems: "flex-end",
                          justifyContent: "flex-end", // backgroundColor: "blueviolet",
                        }}
                      >
                        {message.isBookmarked === true && (
                          <img
                            src={
                              messageClass === "sent" ? starWhite : starYellow
                            }
                            style={{ margin: "0px 5px 0px 5px" }}
                            alt="bookmark"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {item.url?.slice(-3) === "pdf" && (
                    <>
                      <div
                        className="mediaCoverChat"
                        style={{
                          justifyContent: "start",
                          alignItems: "start",
                          width: " 286px",
                          height: "152px",
                          margin: "10px 0px 0 0",
                        }}
                      >
                        <div>
                          <PDFComponent
                            imageUrlProp={item.url}
                            setNumPages={setNumPages}
                            setBase64BLobImg={setBase64BLobImg}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: 10 }}>
                    <div style={{ marginRight: 10 }}>{message.caption}</div>
                    <div>
                      {messageClass === "sent" ?
                        <img src={message.msgStatus == 1 ? SingleTick : message.msgStatus == 3 ? DoubleTick : DoubleTickGrey} alt="read" />
                        : null}
                    </div>
                  </div>
                </div>
                {index === 0 && (
                  <>
                    <div
                      onClick={(e) => {
                        setPopupOpen(true);
                        e.stopPropagation();
                      }}
                      style={{
                        width: "15px",
                        height: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        className="DropdownArrowChatDesign"
                        style={
                          popupOpen
                            ? { display: "block", margin: "7px 0 0 0" }
                            : { margin: "7px 0 0 0" }
                        }
                        src={
                          messageClass === "sent"
                            ? DropdownArrowChatMesssageWhite
                            : DropdownArrowChatMesssage
                        }
                        alt=""
                      />
                    </div>
                    <OutsideClickHandler
                      key={message?._id}
                      id={message?._id}
                      onOutsideClick={(id) =>
                        setPopupOpen(false)
                      }
                    >
                      <div
                        className="chatActionsPopup"
                        style={
                          popupOpen
                            ? {
                              display: "block",
                              position: "absolute",
                              zIndex: "6",
                              right: "-50px",
                              top: "26px",
                              color: "black",
                            }
                            : { display: "none" }
                        }
                      >
                        <div
                          style={{ borderRadius: "6px 6px 0px 0px" }}
                          onClick={(e) => {
                            setReplyData({
                              replyId: message._id,
                              replyContent: message?.caption,
                              data: message,
                            });
                            setPopupOpen(false);
                            e.stopPropagation();
                          }}
                        >
                          Reply
                        </div>
                        {messageClass == "sent" && <div
                          onClick={(e) => {
                            setSelectedMessage(message);
                            e.stopPropagation();
                            setPopupOpen(false);
                          }}
                        >Info</div>}
                        <div
                          onClick={(e) => {
                            setForwardData({
                              isActive: true,
                              msgId: [message?._id],
                              userId: [],
                              conversationId: [],
                            });
                            setPopupOpen(false);
                            e.stopPropagation();
                          }}
                        >
                          Forward Message
                        </div>
                        {activeChat.type === "Group" && messageClass != "sent" && <div
                          onClick={(e) => {
                            handleReplyPrivately(message);
                            e.stopPropagation();
                          }}
                        >
                          Reply Privately
                        </div>}
                        <div
                          style={{ borderRadius: "6px 6px 0px 0px" }}
                          onClick={(e) => {
                            downloadFile(item)
                            setPopupOpen(false);
                            e.stopPropagation();
                          }}

                        >
                          Download
                        </div>
                        <div
                          onClick={() => {
                            bookMarkMessage(message._id, myContactId, message.isBookmarked ? "unstar" : "star");
                            setPopupOpen(false);
                            setForwardData({
                              isActive: false,
                              msgId: [],
                              userId: [],
                              conversationId: [],
                            });
                          }}
                        >
                          {message.isBookmarked ? "Unstar" : "Star"}
                        </div>
                        <div
                          style={{ borderRadius: "0px 0px 6px 6px" }}
                          onClick={() => {
                            deleteMessage(
                              message?._id,
                              userDetails._id,
                              messageClass
                            );
                            setPopupOpen(false);
                          }}
                        >
                          Delete Message
                        </div>
                      </div>
                    </OutsideClickHandler>
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DocumentsChat;

import React, { useState, useEffect } from "react";
import { getRequest } from "../../../Services/axios";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { showToast, isLoading } from "../../../Features";
import { useDispatch } from "react-redux";
import { FieldArray } from "formik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { SelectFormikMulti } from "../../../Components/FormComponent/SelectFormikMulti";

const opt = [{ label: "select", value: null }];

export const CreateClaimTypeComp = ({
  data,
  formValues,
  handleModeChange,
  categoryArray,
  setCategoryArray,
}) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Travel Eligibility`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  console.log("object formValues", formValues);
  console.log("object categoryArray", categoryArray);

  const dispatch = useDispatch();
  const [roleList, setRoleList] = useState([]);

  const modeArray = [
    { label: "Flight", value: "Flight" },
    { label: "Train", value: "Train" },
    { label: "Bus", value: "Bus" },
    { label: "Taxi", value: "Taxi" },
    { label: "Hotel", value: "Hotel" },
    { label: "Rickshaw", value: "Rickshaw" },
    { label: "Self Vehicle", value: "Self Vehicle" },
    { label: "Food", value: "Food" },
    { label: "Gift", value: "Gift" },
    { label: "Gadget", value: "Gadget" },
    { label: "Metro", value: "Metro" },
    { label: "Local Train", value: "Local Train" },
    { label: "Local Bus", value: "Local Bus" },
  ];

  return (
    <div>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName={"Name/Type of Expenses"} mandatory={true} />
          <InputCustomFormik
            name="perdayEligibilty"
            // type={"number"}
            placeholder={"Enter"}
          />
        </div>
      </div>
      <div style={{ marginTop: "20px" }} className="repeatedSectionContainer">
        <label className="repeatedHeading">Expense details</label>
        <FieldArray name="traveladvanceeligibilitiesdetails">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values, errors } = form;
            const { traveladvanceeligibilitiesdetails } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"S. No."} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Mode"} mandatory={true} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={"Categories"}
                          mandatory={true}
                        />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {traveladvanceeligibilitiesdetails?.map(
                    (Requestlist, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        {/* 2nd column */}

                        <StyledTableCell>
                          <SelectForm
                            name={`traveladvanceeligibilitiesdetails[${index}].mode`}
                            options={modeArray}
                            onChangeProp={(e) => {
                              form.setFieldValue(
                                `traveladvanceeligibilitiesdetails[${index}].mode`,
                                e.value
                              );
                              handleModeChange(index, e.value);
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <SelectFormikMulti
                            name={`traveladvanceeligibilitiesdetails[${index}].category`}
                            options={categoryArray[index] ?? []}
                            // disabled={true}
                            isDisabled={
                              !categoryArray[index] ||
                              categoryArray[index]?.length === 0
                            }
                          />
                          {/* <SelectForm
                            name={`traveladvanceeligibilitiesdetails[${index}].category`}
                            // disabled={
                            //   !categoryArray[index] ||
                            //   categoryArray[index]?.length === 0
                            // }
                            options={categoryArray[index] ?? []}
                          /> */}
                        </StyledTableCell>

                        {/* 8th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              traveladvanceeligibilitiesdetails.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                mode: "",
                                category: "",
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    )
                  )}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
const DetailsPayrollTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const [isOpen, setIsOpen] = useState(false);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`SalaryTemplate/Details/${selectedId}`);
      setEventtData(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getFormDetails();
    return () => { };
  }, []);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Template Details</h3>
          <div >
            <IconButton onClick={() => closeDetails()} title="close">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7579 0.56897L0.757935 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 0.56897L12.7579 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
        </div>
        <div className="tabularMasterDetailsPageBody" style={{ height: "100%", overflow: "auto" }}>
          <>
            {eventtData ? (
              loading ? (
                <Loader />
              ) : (
                <div
                  className="detailsEReq"
                  style={{ flexDirection: "column" }}
                >
                  <div className="headingForm">Payroll Template</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Template Name"}
                      Details={eventtData?.templateName ?? "--"}
                    />
                    <Details
                      detailTitle={"Description"}
                      Details={eventtData?.description ?? "--"}
                    />
                    <Details
                      detailTitle={"Annual CTC"}
                      Details={`Rs ${eventtData?.annualCTC}` ?? "--"}
                    />
                    <Details
                      detailTitle={"Monthly CTC"}
                      Details={`Rs ${(eventtData?.annualCTC / 12).toFixed(0)}` ?? "--"}
                    />
                  </div>

                  {eventtData.templateValues.length > 0 ? (
                    <>
                      <div className="headingForm">
                        Template Details
                      </div>

                      <div>
                        <table
                          className="tabless tabless-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead
                            style={{
                              backgroundColor: "var(--primary-hover)",
                            }}
                          >
                            <tr>
                              <td>S.no</td>
                              <td>Salary Component</td>
                              <td>Calculation Value</td>
                              <td>Calculation Type</td>
                              <td>Monthly Amount</td>
                              <td>Annual Amount</td>
                            </tr>
                          </thead>
                          <tbody>
                            {eventtData.templateValues.map(
                              (items, index) => {
                                return (
                                  <tr className="trStyle">
                                    <td>{index + 1}</td>
                                    <td>{items.displayName}</td>
                                    <td>{items.calculationValue}</td>
                                    <td>{items.calculationType}</td>
                                    <td>{items.monthlyAmount}</td>
                                    <td>{items.annualAmount}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>

                    </>
                  ) : (
                    ""
                  )}
                </div>
              )
            ) : (
              <div className="noDatastyle">
                <img
                  src={Nodata}
                  alt="No data"
                  style={{ height: "150px", width: "250px" }}
                />
                <h4>{"No Records"}</h4>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
export { DetailsPayrollTemplate };

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import Nodata from "../../../Assets/Nodata.svg";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Loader } from "../../../Components/FormComponent/Loader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: " 500",
    backgroundColor: "var(--table-head-back)",
    position: "sticky",
    fontSize: "14px",
    top: "6px",
    zIndex: "5",
    color: "inherit",
    fontFamily: "inherit",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    backgroundColor: " var(--table-back) !important",
    color: "var(--primary-text) !important",
    borderSpacing: " 0 6px",
    borderBottom: " 5px solid white",
    fontFamily: "inherit",
  },
}));
export const StyledTableCont = styled(TableContainer)({
  padding: "0px 15px !important",
  marginTop: "15px",
  fontFamily: "inherit",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: " var(--table-back) !important",
    color: "var(--secondary-text) !important",
    borderSpacing: " 0 6px",
    borderBottom: " 5px solid white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const RequisitionConfig = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  const [loading, setLoading] = useState(false);
  const [tbodyData, setTbodyData] = useState([]);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [bandList, setBandList] = useState(null);
  const [reqData, setReqData] = useState({
    bandIds: null,
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest("ConfigValues/GetMpi");
      setTbodyData(result.data);
    } catch (error) {
      //dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Requisition Config`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("requisitionconfig"))
      getListData();
  }, [location.pathname, reqData]);
  const deferredText = useDeferredValue(searchParam, {
    timeoutMs: 5000,
  });
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);

  const IconDetail = () => (
    <i>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.15"
          d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8437V4.84375Z"
          fill="#0DB53C"
        />
        <line
          x1="14.7295"
          y1="7.43701"
          x2="7.72949"
          y2="7.43701"
          stroke="#0DB53C"
          stroke-linecap="round"
        />
        <line
          x1="5.72949"
          y1="7.43701"
          x2="5.61462"
          y2="7.43701"
          stroke="#0DB53C"
          stroke-linecap="round"
        />
        <line
          x1="14.7295"
          y1="11.3437"
          x2="7.72949"
          y2="11.3437"
          stroke="#0DB53C"
          stroke-linecap="round"
        />
        <line
          x1="5.72949"
          y1="11.3437"
          x2="5.61462"
          y2="11.3438"
          stroke="#0DB53C"
          stroke-linecap="round"
        />
        <line
          x1="14.7295"
          y1="15.2505"
          x2="7.72949"
          y2="15.2505"
          stroke="#0DB53C"
          stroke-linecap="round"
        />
        <line
          x1="5.72949"
          y1="15.2505"
          x2="5.61462"
          y2="15.2505"
          stroke="#0DB53C"
          stroke-linecap="round"
        />
      </svg>
    </i>
  );

  const IconEdit = () => (
    <i>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.15"
          d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
          fill="#FB7158"
        ></path>
        <path
          d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
          stroke="#FB7158"
          stroke-width="0.698594"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </i>
  );

  const handleEdit = (id) => {
    navigate("onboarding/requisitionconfig/create", {
      state: { id },
    });
  };
  const handleDetail = (id) => {
    const selectedRecord = tbodyData?.find((item) => item?.id === id);
    navigate("onboarding/requisitionconfig/details", {
      state: { selectedRecord },
    });
  };
  return (
    <div className="fullTableContainer">
      <>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <>
            <StyledTableCont>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.No</StyledTableCell>
                    <StyledTableCell>Key</StyledTableCell>
                    <StyledTableCell>Value</StyledTableCell>
                    <StyledTableCell>Edit</StyledTableCell>
                    <StyledTableCell>Details</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tbodyData ? (
                    tbodyData?.map((item, index) => {
                      return (
                        <StyledTableRow key={item?.id}>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{item?.key}</StyledTableCell>
                          <StyledTableCell>{item?.value}</StyledTableCell>
                          <StyledTableCell style={{ backgroundColor: "green" }}>
                            <div onClick={() => handleEdit(item?.id)} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <IconEdit />
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div onClick={() => handleDetail(item?.id)} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <IconDetail />
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <div className="noDatastyle">
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Records"}</h4>
                    </div>
                  )}
                </TableBody>
              </Table>
            </StyledTableCont>
          </>
        )}
      </>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import StarRatings from "react-star-ratings";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import UpdateA from "../../../../Assets/UpdateA.svg";
import UpdateAs from "../../../../Assets/UpdateAs.svg";
import { Notifications } from "../DetailsComp/Notifications";
import { Details } from "../DetailsComp/Details";
import { TimelineHrDesk } from "./Timeline/TimelineHrDesk";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { DetailsKeyComp } from "../../../../Components/FormComponent/PopupComponent/DetailsKeyComp";
import { AddAssignee } from "./Form/AddAssignee";
import { UpdateStatus } from "./Form/UpdateStatus";
import { FeedBack } from "./Form/Feedback";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../Features/badgeUtils";

const DetailsPageHrDesk = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page HR Desk`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const { LoggedInUser } = useSelector((state) => state.auth);
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [isOpen, setIsOpen] = useState(false);
  const [typId, setTypeId] = useState(false);
  const currentUrl = window.location.href;

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);
  const togglePopup = (type, color, extraId) => {
    setTypeAction(type);
    setTypeId(extraId);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [empId, setEmpId] = useState(false);
  const PostFeedback = async (value) => {
    try {
      setLoading(true);
      await postRequest("HrHelpdesks/TicketFeedback", value);
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      getDetailsHrHelpDesk();
      setLoading(false);
      setIsOpen(false);
    }
  };
  const getDetailsHrHelpDesk = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `HrHelpdesks/Details?ID=${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result?.data?.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.data?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              approve,
              result.data?.data?.actionlist[0]?.color,
              result.data?.data?.actionlist[0]?.id
            );
          } else
            PostApRejHol(
              approve,
              result.data?.data?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const PostReopen = async (id) => {
    try {
      setLoading(true);
      await postRequest("HrHelpdesks/Reopen", {
        TicketID: id,
        Status: "ReOpen",
      });
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
      setIsOpen(false);
      getDetailsHrHelpDesk();
      setLoading(false);
    }
  };

  const getEmployee = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Employees/GetBannerProfile`);
      setEmpId(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const GetRatingText = (ratingValue) => {
    if (ratingValue >= 4) {
      return "Above Expectations";
    } else if (ratingValue >= 3 && ratingValue < 4) {
      return "Satisfactory";
    } else if (ratingValue >= 2 && ratingValue < 3) {
      return "Meets Expectation";
    } else {
      return "Needs Improvement";
    }
  };
  const PostAddAssignee = async (value) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      await postRequest("HrHelpdesks/Update", value);
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data,
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetailsHrHelpDesk();
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      setLoading(true);
      if (type === "Approve" || type === "Cancel" || type === "Release") {
        await postRequest(`HrHelpdesks/${type}?Id=${id}`);
      } else if (type === "Mark as Closed") {
        await postRequest(`HrHelpdesks/Close?Id=${id}&Remarks=${Remark}`);
      } else {
        await postRequest(`HrHelpdesks/${type}?Id=${id}&Remarks=${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      setIsOpen(false);
      getDetailsHrHelpDesk();
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetailsHrHelpDesk();
    getEmployee();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>HR Desk Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data != null ? (
                  loading ? (
                    <div className="noDataFound">
                      <Loader />
                    </div>
                  ) : (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="headingForm">Request Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Request Number"}
                          Details={data?.hrHelpdeskTickets?.ticketNumber}
                        />
                        <Details
                          detailTitle={"Status"}
                          Details={
                            <div
                              style={{
                                backgroundColor: getStatusColor(
                                  data?.status?.replace(/<[^>]+>/g, "")
                                )?.split(",")?.[0],
                                color: getStatusColor(
                                  data?.status?.replace(/<[^>]+>/g, "")
                                )?.split(",")?.[1],
                                cursor: "default",
                                padding: "6px",
                                borderRadius: "6px",
                              }}
                            >
                              {data?.status?.replace(/<[^>]+>/g, "")}
                            </div>
                          }
                        />
                        <Details
                          detailTitle={"Service Type"}
                          Details={data?.ticketTypes?.text}
                        />
                        <Details
                          detailTitle={"Request Category"}
                          Details={
                            data?.hrHelpdeskTickets?.requestCategory?.name
                          }
                        />
                        <Details
                          detailTitle={"Request Type"}
                          Details={
                            data?.hrHelpdeskTickets?.requestType?.name
                              ? data?.hrHelpdeskTickets?.requestType?.name
                              : "-"
                          }
                        />
                        <Details
                          detailTitle={"Request Assigned To"}
                          Details={data?.assignedEmployee?.text ?? "-"}
                        />
                        <Details
                          detailTitle={"Request Description"}
                          Details={data?.hrHelpdeskTickets?.description}
                        />
                        <Details
                          detailTitle={"Request Priority"}
                          Details={data?.hrHelpdeskTickets?.priority}
                        />
                        {data?.hrHelpdeskTickets?.attachment === null ? (
                          <Details
                            detailTitle={"Supported Document"}
                            Details={"-"}
                          />
                        ) : (
                          <Details
                            detailTitle={"Supported Document"}
                            Details={
                              <UploadDetail
                                filepath={`HRhelpdeskTickets/${data?.hrHelpdeskTickets?.attachment}`}
                              />
                            }
                          />
                        )}
                        <Details
                          detailTitle={"Created On"}
                          Details={format(
                            new Date(data?.hrHelpdeskTickets?.createdOn),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                        <Details
                          detailTitle={"Last Updated On"}
                          Details={format(
                            new Date(data?.hrHelpdeskTickets?.lastUpdatedOn),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                        {/* <Details
                          detailTitle={"Team Name"}
                          Details={
                            data?.teamName
                          }
                        /> */}
                      </div>
                      <div className="headingForm">Requester Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Requester Name"}
                          Details={`${data?.hrHelpdeskTickets?.employee?.name
                            } ${data?.hrHelpdeskTickets?.employee?.lastName ?? ""
                            }`}
                        />
                        <Details
                          detailTitle={"Requester's Manager"}
                          Details={data?.reportingOfficer?.text}
                        />
                        <Details
                          detailTitle={"Requester Designation"}
                          Details={
                            data?.hrHelpdeskTickets?.employee?.designation
                          }
                        />
                        <Details
                          detailTitle={"Requester Department"}
                          Details={
                            data?.hrHelpdeskTickets?.employee?.department
                          }
                        />
                        <Details
                          detailTitle={"Requester Email"}
                          Details={data?.hrHelpdeskTickets?.employee?.email}
                        />
                        <Details
                          detailTitle={"Contact Number"}
                          Details={
                            data?.hrHelpdeskTickets?.employee
                              ?.personalPhoneNumber
                              ? data?.hrHelpdeskTickets?.employee
                                ?.personalPhoneNumber
                              : "-"
                          }
                        />

                        <Details
                          detailTitle={"Location"}
                          Details={data?.location?.text}
                        />
                      </div>
                      {data?.itsmTicketFeedback != null && (
                        <>
                          <div className="headingForm">
                            Request Closure Feedback
                          </div>
                          <div className="detailInner">
                            <DetailsKeyComp
                              detailTitle={"Was your query resolved on time?"}
                              Details={GetRatingText(
                                data?.itsmTicketFeedback?.queryResolvedOnTime
                              )}
                            />
                            <DetailsKeyComp
                              detailTitle={
                                "Are you satisfied with the HR Team response?"
                              }
                              Details={GetRatingText(
                                data?.itsmTicketFeedback?.satisfiedWithResponse
                              )}
                            />
                            <DetailsKeyComp
                              detailTitle={
                                "Are you satisfied with the service?"
                              }
                              Details={GetRatingText(
                                data?.itsmTicketFeedback?.satisfiedWithResult
                              )}
                            />
                            <DetailsKeyComp
                              detailTitle={
                                "How was your interaction experience with HR Team?"
                              }
                              Details={GetRatingText(
                                data?.itsmTicketFeedback
                                  ?.interactionExperienceWithEngineer
                              )}
                            />
                            <DetailsKeyComp
                              detailTitle={
                                "How was your experience using the HR Desk?"
                              }
                              Details={GetRatingText(
                                data?.itsmTicketFeedback?.experienceUsingTool
                              )}
                            />
                            <DetailsKeyComp
                              detailTitle={"Closure Remarks"}
                              Details={data?.itsmTicketFeedback?.remarks}
                            />
                            <Details
                              detailTitle={"HR Support Rating"}
                              Details={
                                <StarRatings
                                  starEmptyColor={"#BFBFBF"}
                                  starRatedColor={"var(--primary)"}
                                  rating={data?.itsmTicketFeedback?.rating}
                                  starDimension="30px"
                                  starSpacing="5px"
                                />
                              }
                            />

                            <Details
                              detailTitle={"HR Desk Rating"}
                              Details={
                                <StarRatings
                                  starEmptyColor={"#BFBFBF"}
                                  starRatedColor={"var(--primary)"}
                                  rating={
                                    data?.itsmTicketFeedback.experienceUsingTool
                                  }
                                  starDimension="30px"
                                  starSpacing="5px"
                                />
                              }
                            />
                          </div>
                        </>
                      )}
                      {data?.cStatus !== "Closed" &&
                        data?.cStatus !== "Resolved" &&
                        data?.cStatus !== "Rejected" &&
                        data?.cStatus !== "Cancelled" &&
                        data?.cStatus !== "New Implementation" &&
                        data?.approvalStatus === true &&
                        data?.assignedEmployee?.id === empId?.Id && (
                          <div
                            className="createFootDetails"
                            style={{ background: "white !important" }}
                          >
                            <div
                              className="button secondaryButton"
                              onClick={() =>
                                togglePopup(
                                  "Update Status",
                                  data?.hrHelpdeskTickets?.status
                                )
                              }
                              style={{
                                background: "#5BC4BF",
                                color: "white",
                              }}
                            >
                              <div className="flexyD">
                                <span>Update Status</span>
                                <img src={UpdateA} alt="Update Status" />
                              </div>
                            </div>
                            {LoggedInUser.permissions.includes(
                              "Can Reassign HR Desk Requests?"
                            ) && (
                                <div
                                  className="button secondaryButton"
                                  onClick={() =>
                                    togglePopup(
                                      "Update Assignee",
                                      data?.assignedEmployee?.id,
                                      data?.hrHelpdeskTickets?.typeId
                                    )
                                  }
                                  style={{
                                    background: "#5BC4BF",
                                    color: "white",
                                  }}
                                >
                                  <div className="flexyD">
                                    <span> Update Assignee</span>{" "}
                                    <img src={UpdateAs} alt="Update Assignee" />
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      {data?.cStatus === "Resolved" &&
                        data?.employee?.id === empId?.Id && (
                          <div
                            className="createFootDetails"
                            style={{ background: "white !important" }}
                          >
                            <div
                              className="button secondaryButton"
                              onClick={() => PostReopen(selectedId)}
                              style={{
                                background: "rgb(71 143 109 / 97%)",
                                color: "white",
                              }}
                            >
                              Reopen
                            </div>
                            <div
                              className="button secondaryButton"
                              onClick={() => togglePopup("Resolved")}
                              style={{
                                background: "#746AB0",
                                color: "white",
                              }}
                            >
                              Close
                            </div>
                          </div>
                        )}
                      {isOpen && (
                        <Popup
                          popupHeading={
                            typeAction === "Resolved"
                              ? "Ticket Feedback"
                              : typeAction
                          }
                          content={
                            typeAction === "Resolved" ? (
                              <FeedBack
                                data={PostFeedback}
                                type={typeAction}
                                handleClose={togglePopup}
                                id={selectedId}
                              />
                            ) : typeAction === "Update Assignee" ? (
                              <AddAssignee
                                type={typeAction}
                                id={selectedId}
                                color={colorAction}
                                typeId={typId}
                                handleClose={togglePopup}
                                data={PostAddAssignee}
                              />
                            ) : typeAction === "Update Status" ? (
                              <UpdateStatus
                                handleClose={togglePopup}
                                assIgniID={data?.assignedEmployee?.id}
                                priority={data?.hrHelpdeskTickets?.priority}
                                data={PostAddAssignee}
                                prestatus={colorAction}
                                id={selectedId}
                              />
                            ) : (
                              <Remark
                                data={PostApRejHol}
                                type={typeAction}
                                id={typId}
                                color={colorAction}
                                handleClose={togglePopup}
                              />
                            )
                          }
                          handleClose={togglePopup}
                        />
                      )}
                      {data?.actionlist?.length > 0 ? (
                        <div
                          className="createFootDetails"
                          style={{ background: "white !important" }}
                        >
                          {data?.actionlist?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                    item.type !== "Release" &&
                                    item.type !== "Cancel"
                                    ? togglePopup(
                                      item?.type,
                                      item?.color,
                                      item?.id
                                    )
                                    : PostApRejHol(
                                      item?.type,
                                      item?.id,
                                      item?.id
                                    );
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Records"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimelineHrDesk id={selectedId} url={"HrHelpdesks"} />
                      {/* <Comments id={selectedId} module={data?.module} requestNumber={
                          data?.manpowerIndent?.requestNumber
                        } /> */}
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications id={selectedId} module={"HR Helpdesk"} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageHrDesk };

/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../Assets/Nodata.svg";
import editButton from "../../../../Assets/editButton.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import StarRatings from "react-star-ratings";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { useSelector } from "react-redux";
import { selfRatingObtain } from "../../../../Utilities";
import { Form, Formik } from "formik";
import { formatNumber } from "../../../../Utilities/formatNumber";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
const DetailsAppraisalSelfReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const isEdit = location?.state?.type === "Update Review";
  const isEditID = location?.state?.ID;
  const isEditObj = { ...location?.state };
  const employeeId = location?.state?.employeeId;
  const showBottomInfo = location?.state?.showBottomInfo;

  console.log("object location", location);
  // const { LoggedInUser } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState();
  const [details, setDetails] = useState(false);

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSAppraisalReview/AppraisalRatingSlab?ID=${selectedId}&EmployeeId=${employeeId}`
      );
      const resultDetails = await getRequest(
        `PMSAppraisalReview/FinalReviewEmployee?ID=${selectedId}&EmployeeId=${employeeId}`
      );
      setData(result?.data?.data);
      setDetails(resultDetails?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol -  Self Review Performance Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (details && data) {
      setInitialValues({
        total: "",
        appraisalCriteriaClassification: getGoalslist(),
      });
    }
  }, [details, data]);
  const getGoalslist = () => {
    let returnArray = [];
    data
      ? data?.appraisalCriteriaClassification?.map((item, index) => {
        returnArray.push({
          criteria: item?.criteria,
          companyWeightage: item?.weightage,
          systemInputs: 0,
          selfReview: "",
          selfComment: "",
        });
        return null;
      })
      : returnArray.push({
        criteria: "",
        companyWeightage: "",
        systemInputs: "",
        selfReview: "",
        selfComment: "",
      });
    return returnArray;
  };
  const selfValuePercentObtain = (total, starData) => {
    return {
      val: Number(
        starData?.appraisalCriteriaSlab
          ?.sort((a, b) => a.weightFrom - b.weightFrom)
          ?.find(
            (item) =>
              Number(total) >= item?.weightFrom &&
              Number(total) <= item?.weightTo
          )?.appraisalValue
      ),
      perct: Number(
        starData?.appraisalCriteriaSlab
          ?.sort((a, b) => a.weightFrom - b.weightFrom)
          ?.find(
            (item) =>
              Number(total) >= item?.weightFrom &&
              Number(total) <= item?.weightTo
          )?.appraisalPercentage
      ),
    };
  };
  const getRevisedSalary = (ctc) => {
    const { val, perct } = selfValuePercentObtain(
      details?.pmsReviews?.appraisalReviewFeedbackNews[0]
        ?.finalPerformanceReview
        ? Number(
            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
              ?.finalLNDReview ?? 0
          ) +
            Number(
              details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.finalPerformanceReview ?? 0
            ) +
            Number(
              details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.finalRewordsReview ?? 0
            ) +
            Number(
              details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.finalTaskReview ?? 0
            )
        : Number(
            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
              ?.reviewerLNDReview ?? 0
          ) +
            Number(
              details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerPerformanceReview ?? 0
            ) +
            Number(
              details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerRewordsReview ?? 0
            ) +
            Number(
              details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                ?.reviewerTaskReview ?? 0
            ),
      data
    );
    const valFinalSalary = Number(ctc) + Number(val);
    const perctFinalSalary = Number(ctc) + Number(ctc) * (Number(perct) / 100);

    if (val) {
      return valFinalSalary;
    } else {
      return perctFinalSalary;
    }
  };
  const [initialValues, setInitialValues] = useState({
    total: "",
    appraisalCriteriaClassification: getGoalslist(),
  });
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  console.log("object initialValues", initialValues);
  console.log("object formValues", formValues);

  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal">
          {loading ? (
            <Loader />
          ) : data ? (
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={() => { }}
            >
              {(formik) => {
                chooseFormValues && chooseFormValues(formik.values);
                return (
                  <Form style={{ height: "100%" }}>
                    <div className="selfParent">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
                          alignItems: "center",
                          padding: "4px 0px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton onClick={() => navigate(-1)}>
                            <svg
                              width="20"
                              height="8"
                              viewBox="0 0 20 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                                fill="#1296B0"
                              />
                            </svg>
                          </IconButton>
                          <h4> Details Review</h4>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button
                            className="btnSelf"
                            onClick={togglePopup}
                            type="button"
                          >
                            Appraisal Criteria{" "}
                          </button>
                          {isEdit && (
                            <div style={{ height: "31px" }}>
                              <img
                                onClick={() =>
                                  navigate(
                                    `/pms/appraisalreview/myappraisalreview/create/${isEditID}`,
                                    { state: { ...isEditObj } }
                                  )
                                }
                                src={editButton}
                                style={{ height: "100%", cursor: "pointer" }}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={{ overflowY: "auto" }}>
                        <div style={{ display: "flex", gap: "20px" }}>
                          {(details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                            ?.employeeLNDReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.employeePerformanceReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.employeeRewordsReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.employeeTaskReview) && (
                              <div
                                className="ratingSelf"
                                style={{
                                  display: "block",
                                  padding: "16px",
                                  width: "278px",
                                  height: "149px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div style={{ marignTop: "12px" }}>
                                    Self Rating :
                                  </div>
                                  <div>
                                    {" "}
                                    <StarRatings
                                      rating={selfRatingObtain(
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeLNDReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeePerformanceReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeRewordsReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeTaskReview ?? 0
                                        ),
                                        data
                                      )}
                                      starRatedColor="#5BC4BF"
                                      starSpacing="2px"
                                      starEmptyColor="#FFFFFF"
                                      numberOfStars={5}
                                      starHoverColor="var(--primary)"
                                      starDimension="28px"
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div>Self Achievement % :</div>
                                  <div>
                                    <p
                                      style={{
                                        color: "#5BC4BF",
                                        fontSize: "30px",
                                        fontWeight: "500",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {Number(
                                        details?.pmsReviews
                                          ?.appraisalReviewFeedbackNews[0]
                                          ?.employeeLNDReview ?? 0
                                      ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeePerformanceReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeRewordsReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.employeeTaskReview ?? 0
                                        )}
                                      %
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          {(details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                            ?.appraiserLNDReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.appraiserPerformanceReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.appraiserRewordsReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.appraiserTaskReview) && (
                              <div
                                className="ratingSelf"
                                style={{
                                  display: "block",
                                  padding: "16px",
                                  width: "278px",
                                  height: "149px",
                                  backgroundColor: "#FFEEF2",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div style={{ marignTop: "12px" }}>
                                    Appraiser Review Rating :
                                  </div>
                                  <div>
                                    {" "}
                                    <StarRatings
                                      rating={selfRatingObtain(
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.appraiserLNDReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.appraiserPerformanceReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.appraiserRewordsReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.appraiserTaskReview ?? 0
                                        ),
                                        data
                                      )}
                                      starRatedColor="#DE64A1"
                                      starSpacing="2px"
                                      starEmptyColor="#FFFFFF"
                                      numberOfStars={5}
                                      starHoverColor="var(--primary)"
                                      starDimension="28px"
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div>Appraiser Review Achievement % :</div>
                                  <div>
                                    <p
                                      style={{
                                        color: "#DE64A1",
                                        fontSize: "30px",
                                        fontWeight: "500",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {Number(
                                        details?.pmsReviews
                                          ?.appraisalReviewFeedbackNews[0]
                                          ?.appraiserLNDReview ?? 0
                                      ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.appraiserPerformanceReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.appraiserRewordsReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.appraiserTaskReview ?? 0
                                        )}
                                      %
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          {(details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                            ?.reviewerLNDReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.reviewerPerformanceReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.reviewerRewordsReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.reviewerTaskReview) && (
                              <div
                                className="ratingSelf"
                                style={{
                                  display: "block",
                                  padding: "16px",
                                  width: "278px",
                                  height: "149px",
                                  backgroundColor: "#FFF2F0",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div style={{ marignTop: "12px" }}>
                                    Reviewer Review Rating :
                                  </div>
                                  <div>
                                    {" "}
                                    <StarRatings
                                      rating={selfRatingObtain(
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.reviewerLNDReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.reviewerPerformanceReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.reviewerRewordsReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.reviewerTaskReview ?? 0
                                        ),
                                        data
                                      )}
                                      starRatedColor="#FB7158"
                                      starSpacing="2px"
                                      starEmptyColor="#FFFFFF"
                                      numberOfStars={5}
                                      starHoverColor="var(--primary)"
                                      starDimension="28px"
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div>Reviewer Review Achievement % :</div>
                                  <div>
                                    <p
                                      style={{
                                        color: "#FB7158",
                                        fontSize: "30px",
                                        fontWeight: "500",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {Number(
                                        details?.pmsReviews
                                          ?.appraisalReviewFeedbackNews[0]
                                          ?.reviewerLNDReview ?? 0
                                      ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.reviewerPerformanceReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.reviewerRewordsReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.reviewerTaskReview ?? 0
                                        )}
                                      %
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          {(details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                            ?.finalLNDReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.finalPerformanceReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.finalRewordsReview ||
                            details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.finalTaskReview) && (
                              <div
                                className="ratingSelf"
                                style={{
                                  display: "block",
                                  padding: "16px",
                                  width: "278px",
                                  height: "149px",
                                  backgroundColor: "#F0F0FF",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div style={{ marignTop: "12px" }}>
                                    Final Review Rating :
                                  </div>
                                  <div>
                                    {" "}
                                    <StarRatings
                                      rating={selfRatingObtain(
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.finalLNDReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.finalPerformanceReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.finalRewordsReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.finalTaskReview ?? 0
                                        ),
                                        data
                                      )}
                                      starRatedColor="#8A88ED"
                                      starSpacing="2px"
                                      starEmptyColor="#FFFFFF"
                                      numberOfStars={5}
                                      starHoverColor="var(--primary)"
                                      starDimension="28px"
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div>Final Review Achievement % :</div>
                                  <div>
                                    <p
                                      style={{
                                        color: "#8A88ED",
                                        fontSize: "30px",
                                        fontWeight: "500",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {Number(
                                        details?.pmsReviews
                                          ?.appraisalReviewFeedbackNews[0]
                                          ?.finalLNDReview ?? 0
                                      ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.finalPerformanceReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.finalRewordsReview ?? 0
                                        ) +
                                        Number(
                                          details?.pmsReviews
                                            ?.appraisalReviewFeedbackNews[0]
                                            ?.finalTaskReview ?? 0
                                        )}
                                      %
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                        <div>
                          {details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                            ?.employeePerformanceReview ? (
                            <div
                              style={{
                                width: "100%",
                                // overflow: "auto",
                                margin: "10px 0px 20px 0px",
                              }}
                            >
                              {/* <div className="borderDot"></div> */}
                              <div
                                style={{
                                  marginTop: "12px",
                                  background: "#d4f2f1",
                                  borderRadius: "6px",
                                  display: "flex",
                                }}
                              >
                                <div className="centertdtwo">
                                  <div>Self</div>
                                  <div>Review</div>
                                </div>
                                <div
                                  style={{
                                    width: "calc(100% - 120px)",
                                    padding: "1px 10px",
                                  }}
                                >
                                  <table
                                    className="appriaisalReviewTableDetail"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr
                                        style={{
                                          borderBottom: "1px #5BC4BF solid",
                                        }}
                                      >
                                        <td style={{ width: "140px" }}>
                                          Criteria
                                        </td>
                                        <td
                                          style={{
                                            width: "119px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Company <br />
                                          Weightage
                                        </td>
                                        <td
                                          style={{
                                            width: "119px",
                                            textAlign: "center",
                                          }}
                                        >
                                          System <br />
                                          Inputs
                                        </td>
                                        <td
                                          style={{
                                            width: "119px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Self <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "308px",
                                            // paddingLeft: "23px",
                                          }}
                                        >
                                          Self Comment
                                        </td>
                                        <td></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.appraisalCriteriaClassification?.map(
                                        (item, index) => (
                                          <tr>
                                            <td>{item?.criteria}</td>
                                            <td style={{ textAlign: "center" }}>
                                              {" "}
                                              {item?.weightage}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? formatNumber(
                                                  data?.systemInput
                                                    ?.Performance
                                                )
                                                : item?.criteria === "Tasks"
                                                  ? formatNumber(
                                                    data?.systemInput?.Tasks
                                                  )
                                                  : item?.criteria === "Rewards"
                                                    ? formatNumber(
                                                      data?.systemInput?.Rewards
                                                    )
                                                    : item?.criteria === "L&D"
                                                      ? formatNumber(
                                                        data?.systemInput?.[`L&D`]
                                                      )
                                                      : ""}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.employeeTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.employeeRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.employeeLNDReview
                                                      : ""}
                                            </td>
                                            <td>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceComment
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.employeeTaskComment
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.employeeRewordsComment
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.employeeLNDComment
                                                      : ""}
                                            </td>
                                            <td></td>
                                          </tr>
                                        )
                                      )}
                                      <tr
                                        style={{
                                          borderTop:
                                            "1px solid rgb(91, 196, 191)",
                                        }}
                                      >
                                        <td style={{ color: "#5BC4BF" }}>
                                          Total
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            color: "#5BC4BF",
                                          }}
                                        >
                                          {data?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(currentValue?.weightage)
                                              );
                                            },
                                            0
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            color: "#5BC4BF",
                                          }}
                                        >
                                          {formatNumber(
                                            data?.systemInput?.Performance +
                                            data?.systemInput?.Tasks +
                                            data?.systemInput?.Rewards +
                                            data?.systemInput?.[`L&D`]
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            color: "#5BC4BF",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeePerformanceReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeTaskReview ?? 0
                                            )}
                                        </td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                            ?.appraiserPerformanceReview ? (
                            <div
                              style={{
                                width: "100%",
                                // overflow: "auto",
                                margin: "10px 0px 20px 0px",
                              }}
                            >
                              {/* <div className="borderDot"></div> */}
                              <div
                                style={{
                                  marginTop: "12px",
                                  background: "#FFEEF2",
                                  borderRadius: "6px",
                                  display: "flex",
                                }}
                              >
                                <div className="centertdthree">
                                  <div>Appraiser</div>
                                  <div>Review</div>
                                </div>
                                <div
                                  style={{
                                    width: "calc(100% - 120px)",
                                    padding: "1px 10px",
                                  }}
                                >
                                  <table
                                    className="appriaisalReviewTableDetail"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr
                                        style={{
                                          borderBottom: "1px #E06DA6 solid",
                                        }}
                                      >
                                        <td style={{ width: "140px" }}>
                                          Criteria
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Company <br />
                                          Weightage
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          System <br />
                                          Inputs
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Self <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Appraiser <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "start",
                                          }}
                                        >
                                          Appraiser <br />
                                          Comment
                                        </td>

                                        <td></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.appraisalCriteriaClassification?.map(
                                        (item, index) => (
                                          <tr>
                                            <td>{item?.criteria}</td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.weightage}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? formatNumber(
                                                  data?.systemInput
                                                    ?.Performance
                                                )
                                                : item?.criteria === "Tasks"
                                                  ? formatNumber(
                                                    data?.systemInput?.Tasks
                                                  )
                                                  : item?.criteria === "Rewards"
                                                    ? formatNumber(
                                                      data?.systemInput?.Rewards
                                                    )
                                                    : item?.criteria === "L&D"
                                                      ? formatNumber(
                                                        data?.systemInput?.[`L&D`]
                                                      )
                                                      : ""}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.employeeTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.employeeRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.employeeLNDReview
                                                      : ""}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserPerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.appraiserTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.appraiserRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.appraiserLNDReview
                                                      : ""}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "start",
                                                width: "308px",
                                                paddingLeft: "23px",
                                              }}
                                            >
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserPerformanceComment
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.appraiserTaskComment
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.appraiserRewordsComment
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.appraiserLNDComment
                                                      : ""}
                                            </td>
                                            <td></td>
                                          </tr>
                                        )
                                      )}
                                      <tr
                                        style={{
                                          borderTop: "1px solid #DE64A1",
                                        }}
                                      >
                                        <td>Total</td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            color: "#E06DA6",
                                          }}
                                        >
                                          {data?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(currentValue?.weightage)
                                              );
                                            },
                                            0
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            color: "#E06DA6",
                                          }}
                                        >
                                          {formatNumber(
                                            data?.systemInput?.Performance +
                                            data?.systemInput?.Tasks +
                                            data?.systemInput?.Rewards +
                                            data?.systemInput?.[`L&D`]
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            color: "#E06DA6",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeePerformanceReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeTaskReview ?? 0
                                            )}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            color: "#E06DA6",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.appraiserLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserPerformanceReview ??
                                              0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserTaskReview ?? 0
                                            )}
                                        </td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                            ?.reviewerPerformanceReview ? (
                            <div
                              style={{
                                width: "100%",
                                // overflow: "auto",
                                margin: "10px 0px 20px 0px",
                              }}
                            >
                              {/* <div className="borderDot"></div> */}
                              <div
                                style={{
                                  marginTop: "12px",
                                  background: "#FFF2F0",
                                  borderRadius: "6px",
                                  display: "flex",
                                }}
                              >
                                <div className="centertdfour">
                                  <div>Reviewer</div>
                                  <div>Review</div>
                                </div>
                                <div
                                  style={{
                                    width: "calc(100% - 120px)",
                                    padding: "1px 10px",
                                  }}
                                >
                                  <table
                                    className="appriaisalReviewTableDetail"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr
                                        style={{
                                          borderBottom: "1px #FB7158 solid",
                                        }}
                                      >
                                        <td style={{ width: "140px" }}>
                                          Criteria
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Company <br />
                                          Weightage
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          System <br />
                                          Inputs
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Self <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Appraiser <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Reviewer <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "308px",
                                            paddingLeft: "23px",
                                          }}
                                        >
                                          Reviewer Comment
                                        </td>
                                        <td></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.appraisalCriteriaClassification?.map(
                                        (item, index) => (
                                          <tr>
                                            <td>{item?.criteria}</td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.weightage}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? formatNumber(
                                                  data?.systemInput
                                                    ?.Performance
                                                )
                                                : item?.criteria === "Tasks"
                                                  ? formatNumber(
                                                    data?.systemInput?.Tasks
                                                  )
                                                  : item?.criteria === "Rewards"
                                                    ? formatNumber(
                                                      data?.systemInput?.Rewards
                                                    )
                                                    : item?.criteria === "L&D"
                                                      ? formatNumber(
                                                        data?.systemInput?.[`L&D`]
                                                      )
                                                      : ""}{" "}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.employeeTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.employeeRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.employeeLNDReview
                                                      : ""}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserPerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.appraiserTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.appraiserRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.appraiserLNDReview
                                                      : ""}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.reviewerPerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.reviewerTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.reviewerRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.reviewerLNDReview
                                                      : ""}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "start",
                                                paddingLeft: "26px",
                                              }}
                                            >
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.reviewerPerformanceComment
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.reviewerTaskComment
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.reviewerTaskComment
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.reviewerLNDComment
                                                      : ""}
                                            </td>
                                            <td></td>
                                          </tr>
                                        )
                                      )}
                                      <tr
                                        style={{
                                          borderTop: "1px solid #FB7158",
                                        }}
                                      >
                                        <td style={{ color: "#FB7158" }}>
                                          Total
                                        </td>
                                        <td
                                          style={{
                                            color: "#FB7158",
                                            textAlign: "center",
                                          }}
                                        >
                                          {formValues?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(
                                                  currentValue?.companyWeightage
                                                )
                                              );
                                            },
                                            0
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            color: "#FB7158",
                                            textAlign: "center",
                                          }}
                                        >
                                          {formatNumber(
                                            data?.systemInput?.Performance +
                                            data?.systemInput?.Tasks +
                                            data?.systemInput?.Rewards +
                                            data?.systemInput?.[`L&D`]
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            color: "#FB7158",
                                            textAlign: "center",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeePerformanceReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeTaskReview ?? 0
                                            )}
                                        </td>
                                        <td
                                          style={{
                                            color: "#FB7158",
                                            textAlign: "center",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.appraiserLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserPerformanceReview ??
                                              0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserTaskReview ?? 0
                                            )}
                                        </td>
                                        <td
                                          style={{
                                            color: "#FB7158",
                                            textAlign: "center",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.reviewerLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.reviewerPerformanceReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.reviewerRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.reviewerTaskReview ?? 0
                                            )}
                                        </td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                            ?.finalPerformanceReview ? (
                            <div
                              style={{
                                width: "100%",
                                // overflow: "auto",
                                margin: "10px 0px 20px 0px",
                              }}
                            >
                              {/* <div className="borderDot"></div> */}
                              <div
                                style={{
                                  marginTop: "12px",
                                  background: "#F0F0FF",
                                  borderRadius: "6px",
                                  display: "flex",
                                }}
                              >
                                <div className="centertdfive">
                                  <div>Final</div>
                                  <div>Review</div>
                                </div>
                                <div
                                  style={{
                                    width: "calc(100% - 120px)",
                                    padding: "1px 10px",
                                  }}
                                >
                                  <table
                                    className="appriaisalReviewTableDetail"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr
                                        style={{
                                          borderBottom: "1px #8A88ED solid",
                                        }}
                                      >
                                        <td style={{ width: "140px" }}>
                                          Criteria
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Company <br />
                                          Weightage
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          System <br />
                                          Inputs
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Self <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Appraiser <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Reviewer <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "40px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Final <br />
                                          Review
                                        </td>
                                        <td
                                          style={{
                                            width: "308px",
                                            paddingLeft: "23px",
                                          }}
                                        >
                                          Final Comment
                                        </td>
                                        <td></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.appraisalCriteriaClassification?.map(
                                        (item, index) => (
                                          <tr>
                                            <td>{item?.criteria}</td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.weightage}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? formatNumber(
                                                  data?.systemInput
                                                    ?.Performance
                                                )
                                                : item?.criteria === "Tasks"
                                                  ? formatNumber(
                                                    data?.systemInput?.Tasks
                                                  )
                                                  : item?.criteria === "Rewards"
                                                    ? formatNumber(
                                                      data?.systemInput?.Rewards
                                                    )
                                                    : item?.criteria === "L&D"
                                                      ? formatNumber(
                                                        data?.systemInput?.[`L&D`]
                                                      )
                                                      : ""}{" "}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.employeePerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.employeeTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.employeeRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.employeeLNDReview
                                                      : ""}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.appraiserPerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.appraiserTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.appraiserRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.appraiserLNDReview
                                                      : ""}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.reviewerPerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.reviewerTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.reviewerRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.reviewerLNDReview
                                                      : ""}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.finalPerformanceReview
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.finalTaskReview
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.finalRewordsReview
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.finalLNDReview
                                                      : ""}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "start",
                                                paddingLeft: "26px",
                                              }}
                                            >
                                              {item?.criteria === "Performance"
                                                ? details?.pmsReviews
                                                  ?.appraisalReviewFeedbackNews[0]
                                                  ?.finalPerformanceComment
                                                : item?.criteria === "Tasks"
                                                  ? details?.pmsReviews
                                                    ?.appraisalReviewFeedbackNews[0]
                                                    ?.finalTaskComment
                                                  : item?.criteria === "Rewards"
                                                    ? details?.pmsReviews
                                                      ?.appraisalReviewFeedbackNews[0]
                                                      ?.finalTaskComment
                                                    : item?.criteria === "L&D"
                                                      ? details?.pmsReviews
                                                        ?.appraisalReviewFeedbackNews[0]
                                                        ?.finalLNDComment
                                                      : ""}
                                            </td>
                                            <td></td>
                                          </tr>
                                        )
                                      )}
                                      <tr
                                        style={{
                                          borderTop: "1px solid #8A88ED",
                                        }}
                                      >
                                        <td style={{ color: "#8A88ED" }}>
                                          Total
                                        </td>
                                        <td
                                          style={{
                                            color: "#8A88ED",
                                            textAlign: "center",
                                          }}
                                        >
                                          {formValues?.appraisalCriteriaClassification?.reduce(
                                            (accumulator, currentValue) => {
                                              return (
                                                accumulator +
                                                Number(
                                                  currentValue?.companyWeightage
                                                )
                                              );
                                            },
                                            0
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            color: "#8A88ED",
                                            textAlign: "center",
                                          }}
                                        >
                                          {formatNumber(
                                            data?.systemInput?.Performance +
                                            data?.systemInput?.Tasks +
                                            data?.systemInput?.Rewards +
                                            data?.systemInput?.[`L&D`]
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            color: "#8A88ED",
                                            textAlign: "center",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.employeeLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeePerformanceReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.employeeTaskReview ?? 0
                                            )}
                                        </td>
                                        <td
                                          style={{
                                            color: "#8A88ED",
                                            textAlign: "center",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.appraiserLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserPerformanceReview ??
                                              0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.appraiserTaskReview ?? 0
                                            )}
                                        </td>
                                        <td
                                          style={{
                                            color: "#8A88ED",
                                            textAlign: "center",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.reviewerLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.reviewerPerformanceReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.reviewerRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.reviewerTaskReview ?? 0
                                            )}
                                        </td>
                                        <td
                                          style={{
                                            color: "#8A88ED",
                                            textAlign: "center",
                                          }}
                                        >
                                          {Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.finalLNDReview ?? 0
                                          ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.finalPerformanceReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.finalRewordsReview ?? 0
                                            ) +
                                            Number(
                                              details?.pmsReviews
                                                ?.appraisalReviewFeedbackNews[0]
                                                ?.finalTaskReview ?? 0
                                            )}
                                        </td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {showBottomInfo &&
                            (details?.pmsReviews?.appraisalReviewFeedbackNews[0]
                              ?.reviewerPerformanceReview ||
                              details?.pmsReviews
                                ?.appraisalReviewFeedbackNews[0]
                                ?.finalPerformanceReview) && (
                              <div
                                style={{
                                  display: "block",
                                  padding: "0px",
                                  width: "100%",
                                  backgroundColor: "none",
                                }}
                              >
                                <div
                                  className="detailInner"
                                  style={{ padding: "20px", fontSize: "14px" }}
                                >
                                  <Details
                                    detailTitle={"Final Weightage"}
                                    Details={
                                      details?.pmsReviews
                                        ?.appraisalReviewFeedbackNews[0]
                                        ?.finalPerformanceReview
                                        ? Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.finalLNDReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.finalPerformanceReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.finalRewordsReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.finalTaskReview ?? 0
                                          )
                                        : Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.reviewerLNDReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.reviewerPerformanceReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.reviewerRewordsReview ?? 0
                                          ) +
                                          Number(
                                            details?.pmsReviews
                                              ?.appraisalReviewFeedbackNews[0]
                                              ?.reviewerTaskReview ?? 0
                                          )
                                    }
                                  />
                                  <Details
                                    detailTitle={"Current Designation"}
                                    Details={
                                      details?.pmsReviews?.employee?.designation
                                    }
                                  />
                                  <Details
                                    detailTitle={"Current Salary"}
                                    Details={details?.pmsReviews?.lastCTC}
                                  />
                                  <Details
                                    detailTitle={"New Designation"}
                                    Details={
                                      details?.pmsReviews?.newDesignation
                                    }
                                  />

                                  <Details
                                    detailTitle={
                                      "Revised Salary as per Final weightage"
                                    }
                                    Details={getRevisedSalary(
                                      details?.pmsReviews?.lastCTC
                                    )}
                                  />
                                  <Details
                                    detailTitle={"Current Role"}
                                    Details={
                                      details?.pmsReviews?.employee?.roleName
                                    }
                                  />
                                  <Details
                                    detailTitle={"Proposed Final Salary"}
                                    Details={
                                      details?.pmsReviews?.purposedFinalSalary
                                    }
                                  />

                                  <Details
                                    detailTitle={"New Role"}
                                    Details={details?.pmsReviews?.newRole}
                                  />
                                  <div className="detailData"></div>
                                  <Details
                                    detailTitle={"Current Band"}
                                    Details={
                                      details?.pmsReviews?.employee?.bandName
                                    }
                                  />
                                  <div className="detailData"></div>

                                  <Details
                                    detailTitle={"New Band"}
                                    Details={details?.pmsReviews?.newBand}
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Records"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={"Rating Slab"}
          content={
            <>
              <InstructionSelf data={data} />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};
export { DetailsAppraisalSelfReview };

import React, { useEffect } from "react";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
export const CreateGoalSettingComp = ({ formValues }) => {
  //console.log("formValues",formValues?.cycleStartDate)
  useEffect(() => {
    document.title = `PeopleSol -  Create Goal Setting`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter goal setting name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Cycle Start Date"} mandatory={true} />
        <DatePickerFormiks
          minDate={new Date()}
          maxDate={formValues?.cycleEndDate}
          name="cycleStartDate"
        />
      </div>
      <div>
        <LabelCustom labelName={"Cycle End Date"} mandatory={true} />
        <DatePickerFormiks
          minDate={formValues?.cycleStartDate}
          name="cycleEndDate"
        />
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react'
import CreateSurveyComp from '../Home/Components/HomeEngage/Components/SurveyComp/Components/CreateSurveyComp'
import { FormCustomFormik } from '../../Components/FormComponent/FormCustomFormik'
import * as Yup from "yup";
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Loader } from '../../Components/FormComponent/Loader';
import RewardsCriteriaUpdateSetTarget from './RewardsCriteriaUpdateSetTarget';
import { useDispatch } from 'react-redux';
import { isLoading, showToast } from '../../Features';
import { getRequest, postRequest } from '../../Services/axios';
import { useSelector } from 'react-redux';
// import { position } from 'html2canvas/dist/types/css/property-descriptors/position';


const RewardsCriteriaSetTargetPopupForm = ({ actionType,
    rewardsId, studentId, numbers = [], isTarget, selectedRows, clickedButton, setClickedButton }) => {
    const { state } = useLocation();
    // const numbers = Array.from({ length: winnersCount }, (_, index) => index + 1);
    const [loading, setLoading] = useState(false);
    const [daata, setData] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState([]);
    const [details, setDetails] = useState([])

    const navigate = useNavigate();
    const dispatch = useDispatch();

  const { LoggedInUser } = useSelector((state) => state.auth);
  const loggedInemployeeId = LoggedInUser.id ;


  
  
    
    useEffect(() => {
        document.title = `PeopleSol - Update Target`;
        return () => {
            document.title = "PeopleSol";
        };
    }, []);

    const togglePopup = (formData) => {
        setData(formData);
        setIsOpen((prev) => !prev);
    };

    const getEmployeeData = async (studentId) => {

        try {
            setLoading(true);
            // const result = await getRequest(`Awards/Details/${selectedId}`);
            const result = await getRequest(`RewardsTransController/TargetSetDetails?RewardId=${rewardsId}&EmployeeId=${studentId}`);
            setDetails(result?.data?.summary);
            console.log("sandy Quantative details:", result.data.summary);
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "Error in fetching Data",
                    severity: "error",
                })
            );
        } finally {
            setLoading(false);
        }
    };
   
    useEffect(()=>{
        if(actionType === "Set Target"){
    
            getEmployeeData(loggedInemployeeId)
            console.log("loggedin user are inside===============", loggedInemployeeId, typeof(actionType));
        }
      },[])

    useEffect(() => {
        // debugger
        if (isTarget || actionType === "Set Target")
            setInitialValues((prev) => ({
                noOfWinners: details?.targetsSet?.filter(it => it?.isConsolation === false)?.map((item, ind) => ({
                    objective: isTarget || actionType === "Set Target" ? item?.objectives : "",
                    positionId: isTarget || actionType === "Set Target" ? item?.id : "",
                    winnersTarget: isTarget || actionType === "Set Target" ? item?.rewardsTargets?.map((item, index) => ({
                        description: isTarget || actionType === "Set Target" ? item?.title : "",
                        unit: isTarget || actionType === "Set Target" ? item?.unit : "",
                        value: isTarget || actionType === "Set Target" ? item?.value : "",
                        tergetId: isTarget || actionType === "Set Target" ? item.id : ""
                    })).sort((a, b) => a.id - b.id) : getTarget(),
                    // [
                    //     {
                    //         description: "",
                    //         unit: "",
                    //         value: ""
                    //     },
                    // ],
                })),
                consolation: details?.targetsSet?.filter(it => it?.isConsolation === true)?.map((item, ind) => ({
                    objective: isTarget || actionType === "Set Target" || item?.isConsolation ? item?.objectives : "",
                    positionId: isTarget || actionType === "Set Target" ? item?.id : "",
                    winnersTarget: isTarget || actionType === "Set Target" ? item?.rewardsTargets?.map((item, index) => ({
                        description: isTarget || actionType === "Set Target" ? item?.title : "",
                        unit: isTarget || actionType === "Set Target" ? item?.unit : "",
                        value: isTarget || actionType === "Set Target" ? item?.value : "",
                        tergetId: isTarget || actionType === "Set Target" ? item.id : "",
                    })).sort((a, b) => a.id - b.id) : getTarget(),
                }))

                // noOfWinners:   [
                //     {
                //         objective: "",
                //         winnersTarget: getTarget(),
                //     },
                // ],
                // consolation: [
                //     {
                //         objective: "",
                //         winnersTarget: getConsolationTarget(),
                //     },
                // ],

            }))
    }, [details])

    const getItems = () => {
        let returnArray = [];
        returnArray = [
            {
                QuestionText: "",
                Answers: [
                    { AnswerId: 1, AnswerText: "" },
                    {
                        AnswerId: 2,
                        AnswerText: "",
                    },
                    {
                        AnswerId: 3,
                        AnswerText: "",
                    },
                    {
                        AnswerId: 4,
                        AnswerText: "",
                    },
                ],
                AllowMultipleAnswer: false,
            },
        ];

        return returnArray;
    };

    const getTarget = () => {
        let returnArray = [];

        returnArray = [
            {
                description: "",
                unit: "",
                value: ""
            },
        ];

        return returnArray;
    };

    const getConsolationTarget = () => {
        let returnArray = [];
        returnArray = [
            {
                description: "",
                unit: "",
                value: ""
            },
        ];

        return returnArray;
    };
    const [initialValues, setInitialValues] = useState({
        noOfWinners: numbers?.map((item, ind) => ({
            objective: "",
            winnersTarget: getTarget(),
        })),
        // noOfWinners:   [
        //     {
        //         objective: "",
        //         winnersTarget: getTarget(),
        //     },
        // ],
        consolation: [
            {
                objective: "",
                winnersTarget: getConsolationTarget(),
            },
        ],

    });

    console.log("this is edit initial value", initialValues);
    const validationSchema = Yup.object({
        noOfWinners: Yup.array().of(
            Yup.object().shape({
                objective: Yup.string().required("Objective Is required"),
                winnersTarget: Yup.array().of(
                    Yup.object().shape({
                        description: Yup.string().required("Description Is required"),
                        unit: Yup.number().required("Unit Is required"),
                        value: Yup.number().required("Value Is required"),
                    })
                )
            })
        ),
        consolation: Yup.array().of(
            Yup.object().shape({
                objective: Yup.string().required("Objective Is required"),
                winnersTarget: Yup.array().of(
                    Yup.object().shape({
                        description: Yup.string().required("Description Is required"),
                        unit: Yup.number().required("Unit Is required"),
                        value: Yup.number().required("Value Is required"),
                    })
                )
            })
        ),
        // StartOn: Yup.string().required("StartOn Is required"),
        // EndOn: Yup.string().required("EndOn Is required"),
        // Description: Yup.string()
        //     .max(499, "Description cannot be more than 500 characters")
        //     .required("Description Is required"),
        // SurveyQuestion: Yup.array().of(
        //     Yup.object().shape({
        //         QuestionText: Yup.string().required("QuestionText is required"),
        //         Answers: Yup.array().of(
        //             Yup.object().shape({
        //                 AnswerText: Yup.string().required("AnswerText is required"),
        //             })
        //         ),
        //     })
        // ),
    });


    const createPageData = async (values) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await postRequest("RewardsTransController/SetTargetsForObjectives", values);
            console.log("rewards create data", result)
            if (result) {
                dispatch(
                    showToast({ text: "Successfully Updated", severity: "success" })
                );
                returnPage();
            }
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "Error in fetching Data",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    };

    const editTarget = async (values) => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await postRequest("RewardsTransController/EditTargetsForObjectives", values);
            if (result) {
                dispatch(
                    showToast({ text: "Successfully Updated", severity: "success" })
                );
                returnPage();
            }
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "Error in fetching Data",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    };



    console.log("this is detail for edit", details);
    // const onSubmit = (values) => {
    //     togglePopup(values);
    // };
    const onSubmit = (values) => {

        // console.log("this is update target value", values);
        // {selectedRows.length > 0 ? "" : "";

        // } 
        if (clickedButton !== "save") {
            const setTargetUpdateData = {
                id: rewardsId,
            };
            const RewardsObjectivesWinner = values?.noOfWinners?.map((item, index) => ({
                Objectives: item.objective,
                id: item.positionId,
                position: index + 1,
                Rewards_Id: rewardsId,
                Reward_EmployeeID: studentId,
                IsConsolation: false,
                RewardsTargets: item?.winnersTarget?.map((innerItem, Index) => ({
                    Rewards_Id: rewardsId,
                    Title: innerItem.description,
                    Unit: innerItem.unit,
                    Value: innerItem.value,
                    id: innerItem.tergetId
                }))
            }))
            const RewardsObjectivesConsolation = values?.consolation?.map((item, index) => ({
                Objectives: item.objective,
                id: item.positionId,
                position: 0,
                Rewards_Id: rewardsId,
                Reward_EmployeeID: studentId,
                IsConsolation: true,
                RewardsTargets: item?.winnersTarget?.map((innerItem, Index) => ({
                    Rewards_Id: rewardsId,
                    Title: innerItem.description,
                    Unit: innerItem.unit,
                    Value: innerItem.value,
                    id: innerItem.tergetId
                }))
            }))
            const RewardsObjectives = [...RewardsObjectivesWinner, ...RewardsObjectivesConsolation];
            setTargetUpdateData["RewardsObjectives"] = RewardsObjectives;
            console.log("=====================================================this is submit values", setTargetUpdateData);

            isTarget ? editTarget(setTargetUpdateData) : createPageData(setTargetUpdateData);
        }
        else {
            const setTargetUpdateData = {
                id: rewardsId,
            };
            const RewardsObjectives = selectedRows?.map(it => {
                const RewardsObjectivesWinner = values?.noOfWinners?.map((item, index) => ({
                    Objectives: item.objective,
                    position: index + 1,
                    Rewards_Id: rewardsId,
                    Reward_EmployeeID: it,
                    IsConsolation: false,
                    RewardsTargets: item?.winnersTarget?.map((innerItem, Index) => ({
                        Rewards_Id: rewardsId,
                        Title: innerItem.description,
                        Unit: innerItem.unit,
                        Value: innerItem.value,
                    }))
                }));
                const RewardsObjectivesConsolation = values?.consolation?.map((item, index) => ({
                    Objectives: item.objective,
                    position: index + 1,
                    Rewards_Id: rewardsId,
                    Reward_EmployeeID: it,
                    IsConsolation: true,
                    RewardsTargets: item?.winnersTarget?.map((innerItem, Index) => ({
                        Rewards_Id: rewardsId,
                        Title: innerItem.description,
                        Unit: innerItem.unit,
                        Value: innerItem.value,
                    }))
                }));
                return [...RewardsObjectivesWinner, ...RewardsObjectivesConsolation]
            })
            setTargetUpdateData["RewardsObjectives"] = RewardsObjectives?.flat();
            console.log("object setTargetUpdateData", setTargetUpdateData)
            createPageData(setTargetUpdateData);
        }


    };

    const returnPage = () => {
        if (state?.returnUrl) navigate(state?.returnUrl);
        else navigate(-1);
    };
    const chooseFormValues = (values) => {
        setFormValues(values);
    };

    useEffect(() => {
        // debugger
        if (isTarget) {
            getEmployeeData(studentId);
        }
    }, []);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik) => {
                        chooseFormValues && chooseFormValues(formik.values);
                        return (
                            <Form
                                style={{
                                    padding: " 15px 15px 0 15px ",
                                    position: "relative",
                                    height: "100%",
                                    flexGrow: 1,
                                }}
                            >
                                {/* {propsDiv} */}
                                {/* <CreateSurveyComp
                                    loading={loading}
                                    setInitialValues={setInitialValues}
                                    formValues={formValues}
                                    type={state?.type}
                                /> */}
                                <RewardsCriteriaUpdateSetTarget
                                    loading={loading}
                                    setInitialValues={setInitialValues}
                                    getTarget={getTarget()}
                                    getConsolationTarget={getConsolationTarget()}
                                    formValues={formValues}
                                    type={state?.type}
                                    numbers={numbers}

                                />

                                <div className="createFootCust">
                                    <div>
                                        <div
                                            onClick={returnPage}
                                            className={`button secondaryButton`}
                                        >
                                            Cancel
                                        </div>
                                    </div>
                                    <div>
                                        <button className="button primaryButton" type="submit">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            )}


        </div>
    )
}

export default RewardsCriteriaSetTargetPopupForm

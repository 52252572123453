import React, { useRef, useState, useEffect } from "react";
import "./CreateArea.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateAreaComp } from "./FormComp/CreateAreaComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";

const CreateArea = (props) => {
  const { state } = useLocation();

  const [detailsform, setDetailsform] = useState({});

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Area/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Area `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    if (data) getFormDetails();
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Area/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      returnPage();
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("Area/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
      returnPage();
    }
  };

  const initialvalue = {
    name: "",
    sapid: "",
    hrbp: null,
    taEmployeeId: null,
    subAreaId: null,
    locationHOD: null,
    accounts: null,
    plantTrainingExecutive: null,
    itHOD: null,
    goalSheet: null,
    paymentTeam: null,
    plantGateEmployee: null,
    planttimeOfficer: null,
    salaryStructureGroup: null,
    salaryStructureHR: null,
    settlement: null,
    accountforLoan: null,
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please Enter Name "),
    sapid: Yup.string()
      .min(1)
      .max(50, "SAP Id must be lesss then 50 characters")
      .required("Please Enter SAP ID"),
    taEmployeeId: Yup.string().required("Please Select TA"),
    hrbp: Yup.string().required("Please Select HRBP"),
    locationHOD: Yup.string().required("Please Select Location HOD"),
    accounts: Yup.string().required("Please Select Accounts"),
    plantTrainingExecutive: Yup.string().required(
      "Please Select Plant Training Executive"
    ),
    itHOD: Yup.string().required("Please Select IT HOD"),
    goalSheet: Yup.string().required("Please Select Goal Sheet"),
    paymentTeam: Yup.string().required("Please Select Payment Team"),
    plantGateEmployee: Yup.string().required(
      "Please Select Plant Gate Employee"
    ),
    planttimeOfficer: Yup.string().required("Please Select Plant Time Officer"),
    salaryStructureGroup: Yup.string().required(
      "Please Select Salary Structure Group"
    ),
    salaryStructureHR: Yup.string().required(
      "Please Select Salary Structure HR"
    ),
    settlement: Yup.string().required("Please Select Settlement"),
    accountforLoan: Yup.string().required("Please Select Account For Loan"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: data,
    };

    const create = {
      ...values,
      id: 0,
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      FormHeading={data ? "Edit Area" : "Create Area"}
      ButtonLabel={data ? "Update Area" : "Create Area"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?.area?.name,
              sapid: detailsform?.area?.sapid,
              hrbp: detailsform?.area?.hrbp,
              taEmployeeId: detailsform?.area?.taEmployeeId,
              subAreaId: detailsform?.area?.subAreaId,
              locationHOD: detailsform?.area?.locationHOD,
              accounts: detailsform?.area?.accounts,
              plantTrainingExecutive: detailsform?.area?.plantTrainingExecutive,
              itHOD: detailsform?.area?.ithod,
              goalSheet: detailsform?.area?.goalSheet,
              paymentTeam: detailsform?.area?.paymentTeam,
              plantGateEmployee: detailsform?.area?.plantGateEmployee,
              planttimeOfficer: detailsform?.area?.planttimeOfficer,
              salaryStructureGroup: detailsform?.area?.salaryStructureGroup,
              salaryStructureHR: detailsform?.area?.salaryStructureHR,
              settlement: detailsform?.area?.settlement,
              accountforLoan: detailsform?.area?.accountforLoan,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <CreateAreaComp data={detailsform} editID={data ? true : false} />
      }
    />
  );
};

export { CreateArea };

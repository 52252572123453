import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import EditPencilIcon from "../../../Assets/EditPencilIcon.svg";
import EPFCover from "../../../Assets/EPFCover.png";
import { IconButton, Tooltip } from "@mui/material";
import "./EPFTab.css";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { Form, Formik } from "formik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";

import { CheckboxFormiks } from "../../../Components/FormComponent/CheckboxFormiks";
import { RadioFormik } from "../../../Components/FormComponent/RadioFormik";
import { Loader } from "../../../Components/FormComponent/Loader";
const GratuityTab = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [enabled, setEnabled] = useState(false);
    const [editActive, setEditActive] = useState(false);
    const [ePFData, setEPFData] = useState({});


    const [isGratuityEnabled, setGratuityEnabled] = useState(false);
    const [eligibilityYear, setEligibilityYear] = useState(null);

    useEffect(() => {
        getGratuityDetails();
    }, [])
    const updateGratuity = async () => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await postRequest("PayrollGratuity/Update", { Id: 1, EligibilityYear: eligibilityYear, IsEnable: true });
            dispatch(
                showToast({
                    text: result?.data.message,
                    severity: "success",
                })
            );
        } catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "Error in fetching Data",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    };

    const getGratuityDetails = async () => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await getRequest(
                `PayrollGratuity/Details`
            );
            if (result?.data?.data) {
                setGratuityEnabled(result.data.data.isEnable);
                setEligibilityYear(result.data.data.eligibilityYear)
            }

        }
        catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "Error in fetching Data",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    }

    const enableGratuity = async () => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await getRequest(
                `PayrollGratuity/Enable`
            );
            if (result.data) {
                setGratuityEnabled(true);
            }

        }
        catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "Error in fetching Data",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    }

    const disableGratuity = async () => {
        try {
            dispatch(isLoading(true));
            setLoading(true);
            const result = await getRequest(
                `PayrollGratuity/Disable`
            );
            if (result.data) {
                setGratuityEnabled(false);
            }
        }
        catch (error) {
            dispatch(
                showToast({
                    text: error?.response?.data ?? "Error in fetching Data",
                    severity: "error",
                })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    }

    const GratuityBoard = () => {
        return (
            <div style={{ padding: 20, backgroundColor: isGratuityEnabled ? "#EFF9FA" : "#FAF9F8", margin: 24, display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: 12 }}>
                <div style={{ width: "80%" }}>
                    <p style={{ fontSize: 14, fontWeight: 500, color: isGratuityEnabled ? "#1296B0" : "#555555" }}>{"Gratuity"}</p>
                    <p style={{ fontSize: 12, fontWeight: 400, marginTop: 10 }}>{"However, only those employees who have been by the company for five years or more are given the gratuity amount. It is governed by the Payment of Gratuity Act, 1972."}</p>
                </div>
                <div style={{ margin: 24 }}>
                    <div onClick={() => { !isGratuityEnabled ? enableGratuity() : disableGratuity() }} style={{ width: 30, height: 10, backgroundColor: isGratuityEnabled ? "#D8F0F2" : "#BFBFBF", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: isGratuityEnabled ? "flex-end" : "flex-start" }}>
                        <div style={{ width: 15, height: 15, backgroundColor: isGratuityEnabled ? "#1296B0" : "#555555", borderRadius: 8 }}></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="fullTableContainer" style={{ overflow: 'auto' }}>
            <GratuityBoard />
            {isGratuityEnabled ? <div style={{ margin: 24 }}>
                <p>{"Eligibility Year"}</p>
                <input type="number" defaultValue={eligibilityYear ? eligibilityYear : null} onChange={(e) => { setEligibilityYear(e.target.value) }} placeholder="Enter" style={{ borderStyle: "solid", borderWidth: .5, borderColor: "#BFBFBF", borderRadius: 4, marginTop: 15, height: 40, padding: 10, outline: "none" }}>

                </input>
                <div
                    style={{
                        display: "flex",
                        alignSelf: "flex-end",
                        justifyContent: "flex-end",
                        width: "95%",
                        position: "absolute",
                        bottom: "12px",
                        borderTop: "1px solid var(--secondary-text)",
                        margin: "0 12px",
                    }}
                >
                    <button
                        className="button secondaryButton"
                        type="button"
                        onClick={() => { disableGratuity() }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => { updateGratuity() }}
                        className="button primaryButton"
                        type="submit"
                    >
                        Save & Update
                    </button>
                </div>
            </div> : null}
        </div>
    );
};
export { GratuityTab };

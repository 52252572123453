import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import EditPencilIcon from "../../../Assets/EditPencilIcon.svg";
import { IconButton } from "@mui/material";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { Form, Formik } from "formik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { CheckboxFormiks } from "../../../Components/FormComponent/CheckboxFormiks";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { Loader } from "../../../Components/FormComponent/Loader";
const salaryOptions = [
  { label: "Employee", value: "Employee" },

];
const IncomeTaxTab = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [ePFData, setEPFData] = useState({});
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest("CompanyTax/Details");
      if (result?.data) {
        setEPFData(result?.data?.data);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("CompanyTax/Update", values);
      getEmployeeData();
      setEditActive(false);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Pay Config`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (location.pathname.includes("companytax")) getEmployeeData();
  }, []);
  const submitEditCompany = (values) => {
    createPageData(values);
  };
  // console.log("ePFData.taxDeductorName", ePFData.taxDeductorType);
  return (
    <div className="fullTableContainer centerContent" style={{ height: 'calc(100% - 102px)' }}>
      {editActive ? (
        loading ? (
          <Loader />
        ) : (
          <div
            style={{ height: '100%' }}
            className={`companyCard ${editActive && "editActiveBackground"}`}
          >
            <div className="companyCardContainer" style={{ height: '100%' }}>
              <div className="headingContainer">
                <h3>Taxes</h3>
                <IconButton
                  onClick={() => setEditActive((prev) => !prev)}
                  className={`${editActive && "pencilBackground"}`}
                >
                  <img
                    src={EditPencilIcon}
                    alt="Edit Pencil"
                    style={{ height: 30, width: 30 }}
                    className="editPencilIcon"
                  />
                </IconButton>
              </div>
              <Formik

                enableReinitialize={true}
                initialValues={{
                  PAN: ePFData.pan ?? "-",
                  TAN: ePFData.tan ?? "-",
                  TDSorAO: ePFData.tdSorAO ?? "-",
                  TaxPaymentFrequency: ePFData.taxPaymentFrequency ?? "-",
                  TaxDeductorType: ePFData.taxDeductorType ?? "-",
                  TaxDeductorName: ePFData.taxDeductorName ?? "-",

                  TaxAmountDeductionPeriod:
                    ePFData.taxAmountDeductionPeriod ?? "-",
                }}
                onSubmit={submitEditCompany}
              >
                {({ values }) => {
                  // console.log("values", values);
                  return (
                    <Form style={{ height: 'calc(100% - 120px)', overflow: "auto" }}>
                      <div className="formcustom twoColumn">
                        <div>
                          <LabelCustom labelName={"PAN"} />
                          <InputCustomFormik borderBlack={true} name="PAN" />
                        </div>
                        <div>
                          <LabelCustom labelName={"TAN"} />
                          <InputCustomFormik borderBlack={true} name="TAN" />
                        </div>
                        <div>
                          <LabelCustom labelName={"TDS circle / AO code "} />
                          <InputCustomFormik borderBlack={true}
                            name="TDSorAO"
                            placeholder="FDG/FD/445/45"
                          />
                        </div>
                        <div>
                          <LabelCustom labelName={"Tax Payment Frequency "} />
                          <InputCustomFormik borderBlack={true}
                            disabled={true}
                            name="TaxPaymentFrequency"
                            placeholder="Monthly/Yearly"
                          />
                        </div>
                      </div>
                      <div className="headingForm">Tax Deductor Details</div>
                      <div className="formcustom twoColumn">
                        <div>
                          <LabelCustom labelName={"Deductor's Type"} />
                          <SelectForm
                            borderBlack={true}
                            name="TaxDeductorType"
                            placeholder={"Select any option"}
                            options={salaryOptions}
                            opt
                          />
                        </div>
                        <div>
                          <LabelCustom labelName={"Deductor's Name"} />
                          <InputCustomFormik borderBlack={true} name="TaxDeductorName" />
                        </div>
                        <div>
                          <LabelCustom labelName={"Deduction Period"} />
                          <InputCustomFormik borderBlack={true} name="TaxAmountDeductionPeriod" disabled={true} />
                        </div>
                      </div>
                      {editActive && (
                        <div
                          className="createFootCust"
                          style={{ bottom: "-6px", position: "absolute" }}
                        >
                          <button
                            className="button secondaryButton "
                            type="button"
                            onClick={() => setEditActive((prev) => !prev)}
                          >
                            Cancel
                          </button>

                          <button
                            className="button primaryButton "
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )
      ) : loading ? (
        <Loader />
      ) : (
        <div className="companyCard">
          <div className="companyCardContainer" style={{ height: '100%' }}>
            <div className="headingForm" style={{ marginLeft: 24, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <h5 >Tax Config</h5>
              <IconButton
                onClick={() => setEditActive(true)}
                className={`${editActive && "pencilBackground"}`}
              >
                <img
                  src={EditPencilIcon}
                  alt="Edit Pencil"
                  style={{ width: 35, height: 35 }}
                  className="editPencilIcon"
                />
              </IconButton>
            </div>
            <div className="dataTableRowContainer">
              <div className="dataTableRow">
                <label className="dataTableRowCell">PAN</label>
                <label className="dataTableRowCell">:</label>
                <label className="dataTableRowCell">{ePFData.pan}</label>
              </div>
              <div className="dataTableRow">
                <label className="dataTableRowCell">TAN</label>
                <label className="dataTableRowCell">:</label>
                <label className="dataTableRowCell">{ePFData.tan}</label>
              </div>
              <div className="dataTableRow">
                <label className="dataTableRowCell">TDS circle / AO code</label>
                <label className="dataTableRowCell">:</label>
                <label className="dataTableRowCell">{ePFData.tdSorAO}</label>
              </div>
              <div className="dataTableRow">
                <label className="dataTableRowCell">
                  Tax Payment Frequency{" "}
                </label>
                <label className="dataTableRowCell">:</label>
                <label className="dataTableRowCell">{`${ePFData.taxPaymentFrequency}`}</label>
              </div>
            </div>
            <div className="headingForm" style={{ marginLeft: 24 }}>
              <h5>Tax Deductor Details</h5></div>
            <div className="dataTableRowContainer">
              <div className="dataTableRow">
                <label className="dataTableRowCell">Deductor's Type</label>
                <label className="dataTableRowCell">:</label>
                <label className="dataTableRowCell">{`${ePFData.taxDeductorType}`}</label>
              </div>
              <div className="dataTableRow">
                <label className="dataTableRowCell">Deductor's Name</label>
                <label className="dataTableRowCell">:</label>
                <label className="dataTableRowCell">{`${ePFData.taxDeductorName}`}</label>
              </div>

              <div className="dataTableRow">
                <label className="dataTableRowCell">Deduction Period</label>
                <label className="dataTableRowCell">:</label>
                <label className="dataTableRowCell">{`${ePFData.taxAmountDeductionPeriod}`}</label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export { IncomeTaxTab };

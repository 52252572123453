/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { getBadgeCount } from "../../../../Features/badgeUtils";
import { useSelector } from "react-redux";
import { CheckBoxCustom } from "../../../../Components/FormComponent/CheckBoxCustom";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { DatePickerCustom } from "../../../../Components/FormComponent/DatePickerCustom";
import { InputCustom } from "../../../../Components/FormComponent/InputCustom";
import { TextAreaCustom } from "../../../../Components/FormComponent/TextAreaCustom";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import TravelBookingAddJourney from "./TravelBookingAddJourney";
import HotelBookingAddJourney from "./HotelBookingAddJourney";
import { UploadTicketOptions } from "./UploadTicketOptions";
import { SubmitBooking } from "./SubmitBooking";
import { UploadTicket } from "./UploadTicket";
import CancelUploadTickets from "./CancelUploadTickets";
const TravelBookingDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [typeAction, setTypeAction] = useState("");
  const [summary, setSummary] = useState(false);
  const [colorAction, setColorAction] = useState("");
  const [isCancel, setIsCancel] = useState(false);
  const [isHotelCancel, setIsHotelCancel] = useState(false);
  const [isReSchedule, setIsReSchedule] = useState(false);
  const [isEditReSchedule, setIsEditReSchedule] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [isActionOpen, setisActionOpen] = useState(false);
  const [selActionId, setSelActionId] = useState(false);
  const [typeActionAction, setTypeActionAction] = useState(false);
  const [resultState, setResultState] = useState(false);
  const [selectedTravelEmployees, setSelectedTravelEmployees] = useState("");
  const toggleActionPopup = (type, id) => {
    setTypeActionAction(type);
    setSelActionId(id);
    setisActionOpen((prev) => !prev);
  };
  const closeDetails = () => {
    if (state?.prevPath) {
      navigate(state.prevPath);
    }
    // else if (document.referrer) {
    //   // Use the referrer (previous URL) if available
    //   navigate(document.referrer);
    // }
    else {
      navigate(-1);
    }
  };
  console.log("object showAction", showAction);

  useEffect(() => {
    document.title = `PeopleSol - Travel Booking`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  console.log("object selectedTravelEmployees", selectedTravelEmployees);
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [selId, setSelId] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [hotelCategoryOptions, sethotelCategoryOptions] = useState(null);

  const getFormDetails = async () => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/Getcategory?mode=Hotel`
      );

      let findCategory = result.data?.results?.map((d, i) => {
        return { label: d?.text, value: d?.id };
      });
      sethotelCategoryOptions(findCategory);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getFormDetails();
  }, []);
  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setSelId(id);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result?.data?.data);
      setResultState(result);
      setSummary(result?.data?.summary);
      valuesSet(result);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result?.data?.data?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              approve,
              result.data?.data?.actionlist[0]?.color,
              result.data?.data?.actionlist[0]?.id
            );
          } else PostApRejHol(approve, result.data?.data?.actionlist[0]?.id);
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setShowAction(false);
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const valuesSet = (result) => {
    const resultantData = {
      travelJourney: result?.data?.data?.travellingdetails
        ?.map((person, index) => {
          // if (person?.status?.toLowerCase()?.includes("edited")) {
          if (person?.isEdited === true) {
            return Array?.isArray(result?.data?.data?.travelDetailNnew) &&
              result?.data?.data?.travelDetailNnew?.length > 0
              ? result?.data?.data?.travelDetailNnew
                  ?.filter((it) => person.id === it?.originalTravelDetailId)
                  ?.map((itemz) => ({
                    detailIsCancel: false,
                    detailIsReschedule: false,
                    detailIsNew: true,
                    detailForm: {
                      originalId: person.id,
                      travelId: itemz?.id,
                      fromLocation: itemz?.pickupLocation,
                      toLocation: itemz?.dropLocation,
                      travellingDate:
                        itemz?.fromDate === "0001-01-01T00:00:00"
                          ? new Date(itemz?.toDate)
                          : new Date(itemz?.fromDate),
                      isToDate:
                        itemz?.fromDate === "0001-01-01T00:00:00"
                          ? true
                          : false,
                      bookingNote: itemz?.bookingnote,
                    },
                    detailActions:
                      result?.data?.data?.new_Travel_Journey_actions
                        ?.filter((it) => it?.id === itemz?.id)
                        ?.map((item) => item),
                    detailInd: index,
                    detailElement: itemz,
                    detailArr:
                      Array?.isArray(result?.data?.data?.travelEmployeeNew) &&
                      result?.data?.data?.travelEmployeeNew?.length > 0
                        ? result?.data?.data?.travelEmployeeNew
                            ?.filter(
                              (items) =>
                                items?.newTravellingDetailId === itemz?.id
                            )
                            ?.map((item, indexW) => ({
                              element: item,
                              ind: indexW,
                              isSelected: false,
                            }))
                        : [],
                  }))
              : [];
          } else {
            return {
              detailIsCancel: false,
              detailIsReschedule: false,
              detailIsNew: false,

              detailForm: {
                originalId: person.id,
                travelId: 0,
                fromLocation: person?.pickupLocation,
                toLocation: person?.dropLocation,
                travellingDate:
                  person?.fromDate === "0001-01-01T00:00:00"
                    ? new Date(person?.toDate)
                    : new Date(person?.fromDate),
                isToDate:
                  person?.fromDate === "0001-01-01T00:00:00" ? true : false,
                // travellingDate: person?.fromDate
                //   ? new Date(person?.fromDate)
                //   : null,
                bookingNote: person?.bookingnote,
              },
              detailActions: result?.data?.data?.travel_Journey_actions
                ?.filter((it) => it?.id === person?.id)
                ?.map((item) => item),
              detailInd: index,
              detailElement: person,
              detailArr: result?.data?.data?.travelemployees
                ?.filter((items) => items?.travellingDetailId === person?.id)
                ?.map((item, indexW) => ({
                  element: item,
                  ind: indexW,
                  isSelected: false,
                })),
            };
          }
        })
        ?.flat(),
      hotelBooked: result?.data?.data?.hoteldetail
        ?.map((person, index) => {
          // if (person?.status?.toLowerCase()?.includes("edited")) {
          if (person?.isEdited === true) {
            return Array?.isArray(result?.data?.data?.hotelDetailsNew) &&
              result?.data?.data?.hotelDetailsNew?.length > 0
              ? result?.data?.data?.hotelDetailsNew
                  ?.filter((it) => person.id === it?.originalHotelBookinglId)
                  ?.map((itemz) => ({
                    detailIsCancel: false,
                    detailIsReschedule: false,
                    detailIsNew: true,

                    detailForm: {
                      originalId: person.id,
                      hotelId: itemz?.id,
                      hotelCategory: itemz?.hotelCategory?.toString(),
                      location: itemz?.location,
                      checkInDate: itemz?.checkInTime
                        ? new Date(itemz?.checkInTime)
                        : null,
                      checkOutDate: itemz?.checkOutTime
                        ? new Date(itemz?.checkOutTime)
                        : null,
                      preferredHotel: itemz?.preferHotel,
                      bookingNote: itemz?.bookingNote,
                    },
                    detailInd: index,
                    detailElement: itemz,
                    detailActions: result?.data?.data?.new_Hotel_Journey_actions
                      ?.filter((it) => it?.id === itemz?.id)
                      ?.map((item) => item),
                    detailArr:
                      Array?.isArray(result?.data?.data?.hotelEmployeeNew) &&
                      result?.data?.data?.hotelEmployeeNew?.length > 0
                        ? result?.data?.data?.hotelEmployeeNew
                            ?.filter(
                              (items) => items?.hotelDetailNewId === itemz?.id
                            )
                            ?.map((item, indexW) => ({
                              element: item,
                              ind: indexW,
                              isSelected: false,
                            }))
                        : [],
                  }))
              : [];
          } else {
            return {
              detailIsCancel: false,
              detailIsReschedule: false,
              detailIsNew: false,

              detailForm: {
                originalId: person.id,
                hotelId: 0,
                hotelCategory: [
                  {
                    label: result?.data?.data?.category_name_hotel,
                    value: person?.hotelCategory?.toString(),
                  },
                ],
                location: person?.location,
                checkInDate: person?.checkInTime
                  ? new Date(person?.checkInTime)
                  : null,
                checkOutDate: person?.checkOutTime
                  ? new Date(person?.checkOutTime)
                  : null,
                preferredHotel: person?.preferHotel,
                bookingNote: person?.bookingNote,
              },
              detailInd: index,
              detailActions: result?.data?.data?.hotel_Journey_actions
                ?.filter((it) => it?.id === person?.id)
                ?.map((item) => item),
              detailElement: person,
              detailArr: result?.data?.data?.hotelemployee
                ?.filter((items) => items?.hotelDetailId === person?.id)
                ?.map((item, indexW) => ({
                  element: item,
                  ind: indexW,
                  isSelected: false,
                })),
            };
          }
        })
        ?.flat(),
    };
    if (
      result?.data?.data?.travelDetailNnew?.some(
        (it) => it?.originalTravelDetailId === null
      )
    ) {
      resultantData?.travelJourney?.push(
        result?.data?.data?.travelDetailNnew
          ?.filter((it) => it?.originalTravelDetailId === null)
          ?.sort((a, b) => a.id - b.id)
          ?.map((itemz) => ({
            detailIsCancel: false,
            detailIsReschedule: false,
            detailIsNew: true,

            detailForm: {
              originalId: 0,
              travelId: itemz?.id,
              fromLocation: itemz?.pickupLocation,
              toLocation: itemz?.dropLocation,
              // travellingDate: itemz?.fromDate
              //   ? new Date(itemz?.fromDate)
              //   : null,
              travellingDate:
                itemz?.fromDate === "0001-01-01T00:00:00"
                  ? new Date(itemz?.toDate)
                  : new Date(itemz?.fromDate),
              isToDate:
                itemz?.fromDate === "0001-01-01T00:00:00" ? true : false,
              bookingNote: itemz?.bookingnote,
            },
            // detailInd: index,
            detailElement: itemz,
            detailActions: result?.data?.data?.new_Travel_Journey_actions
              ?.filter((it) => it?.id === itemz?.id)
              ?.map((item) => item),

            detailArr:
              Array?.isArray(result?.data?.data?.travelEmployeeNew) &&
              result?.data?.data?.travelEmployeeNew?.length > 0
                ? result?.data?.data?.travelEmployeeNew
                    ?.filter(
                      (items) => items?.newTravellingDetailId === itemz?.id
                    )
                    ?.map((item, indexW) => ({
                      element: item,
                      ind: indexW,
                      isSelected: false,
                    }))
                : [],
          }))
        //
      );
      resultantData.travelJourney = resultantData?.travelJourney?.flat();
    }
    if (
      result?.data?.data?.hotelDetailsNew?.some(
        (it) => it?.originalHotelBookinglId === null
      )
    ) {
      resultantData?.hotelBooked?.push(
        result?.data?.data?.hotelDetailsNew
          ?.filter((it) => it?.originalHotelBookinglId === null)
          ?.sort((a, b) => a.id - b.id)
          ?.map((itemz) => ({
            detailIsCancel: false,
            detailIsReschedule: false,
            detailIsNew: true,

            detailForm: {
              hotelId: itemz?.id,
              originalId: 0,
              hotelCategory: itemz?.hotelCategory?.toString(),
              location: itemz?.location,
              checkInDate: itemz?.checkInTime
                ? new Date(itemz?.checkInTime)
                : null,
              checkOutDate: itemz?.checkOutTime
                ? new Date(itemz?.checkOutTime)
                : null,
              preferredHotel: itemz?.preferHotel,
              bookingNote: itemz?.bookingNote,
            },
            // detailInd: index,
            detailElement: itemz,
            detailActions: result?.data?.data?.new_Hotel_Journey_actions
              ?.filter((it) => it?.id === itemz?.id)
              ?.map((item) => item),
            detailArr:
              Array?.isArray(result?.data?.data?.hotelEmployeeNew) &&
              result?.data?.data?.hotelEmployeeNew?.length > 0
                ? result?.data?.data?.hotelEmployeeNew
                    ?.filter((items) => items?.hotelDetailNewId === itemz?.id)
                    ?.map((item, indexW) => ({
                      element: item,
                      ind: indexW,
                      isSelected: false,
                    }))
                : [],
          }))
        //
      );
      resultantData.hotelBooked = resultantData?.hotelBooked?.flat();
    }
    setSelectedTravelEmployees(resultantData);
  };
  const AppRejCall = async (type, mode, id) => {
    try {
      let result;
      if (mode === "travel") {
        if (type === "Approve Journey") {
          result = await getRequest(
            `TravelHospitalityRequests/JourneyApprove?Id=${id}`
          );
        } else if (type === "Reject Journey") {
          result = await getRequest(
            `TravelHospitalityRequests/JourneyReject?Id=${id}`
          );
        }
      } else if (mode === "hotel") {
        if (type === "Approve Journey") {
          result = await getRequest(
            `TravelHospitalityRequests/BookingApprove?Id=${id}`
          );
        } else if (type === "Reject Journey") {
          result = await getRequest(
            `TravelHospitalityRequests/BookingReject?Id=${id}`
          );
        }
      }
    } catch {
    } finally {
      getDetails();
    }
  };

  const cancelJourneys = async (val) => {
    try {
      console.log("object val", val);
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `TravelHospitalityRequests/CancelEmployees`,
        val
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      getDetails();
    }
  };
  const editJourneys = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "TravelHospitalityRequests/EditJourney",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.message ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getDetails();
    }
  };
  const editResceduleJourneys = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "TravelHospitalityRequests/EditRescheduleJourney",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.message ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getDetails();
    }
  };
  const clickHandlerFunction = (id, type, color) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Ready for Release" &&
      type !== "HRReject"
    ) {
      togglePopup(type, color, id);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "HRReject"
      ) {
        await getRequest(`TravelHospitalityRequests/${type}/${id}`);
      } else if (type === "Ready for Release") {
        await getRequest(`TravelHospitalityRequests/HRApprove/${id}`);
      } else {
        await getRequest(`TravelHospitalityRequests/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          {/* {pathname.toLowerCase().includes("travelbooking") && (
            <h3>Travel Booking Details</h3>
          )}
          {pathname.toLowerCase().includes("hotelbooking") && (
            <h3>Hotel Booking Details</h3>
          )} */}
          <h3>Travel & Hotel Booking Details</h3>

          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          <>
            {tabIndex === 0 && data != null && (
              <>
                {loading ? (
                  <Loader />
                ) : (
                  <div
                    className="detailsEReq"
                    style={{
                      flexDirection: "column",
                      padding: "13px",
                      gap: "0px",
                    }}
                  >
                    <div className="headingForm">Request Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Reference Number"}
                        Details={data?._request?.requestNumber}
                      />
                      <Details
                        detailTitle={"Status"}
                        Details={
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                data?.status
                              )?.split(",")?.[0],
                              color: getStatusColor(data?.status)?.split(
                                ","
                              )?.[1],
                              cursor: "default",
                              padding: "6px",
                              borderRadius: "6px",
                            }}
                          >
                            {data?.status}
                          </div>
                        }
                      />
                      <Details
                        detailTitle={"Travel Mode"}
                        Details={data?._request?.mode}
                      />
                      <Details
                        detailTitle={"Request Type"}
                        Details={data?._request?.type}
                      />
                      <Details
                        detailTitle={"Travel Type"}
                        Details={
                          data?.travellingdetails[0]?.travelingType ?? "-"
                        }
                      />
                      <Details
                        detailTitle={"Hotel Type"}
                        Details={data?.hoteldetail[0]?.travellingType}
                      />
                      <Details
                        detailTitle={
                          pathname.toLowerCase().includes("travelbooking")
                            ? "Is Linked To Hotel?"
                            : "Is Linked To Travel?"
                        }
                        Details={
                          data?._request?.isLinkedTravelHotel !== null
                            ? data?._request?.isLinkedTravelHotel
                              ? "Yes"
                              : "No"
                            : "No"
                        }
                      />
                      <Details
                        detailTitle={"Eligible Budget for Travel"}
                        Details={
                          data?._request?.budget !== null
                            ? data?._request?.budget ?? "-"
                            : "-"
                        }
                      />
                      <Details
                        detailTitle={"Eligible Budget for Hotel"}
                        Details={
                          data?._request?.hotelBudget !== null
                            ? data?._request?.hotelBudget ?? "-"
                            : "-"
                        }
                      />
                      <Details
                        detailTitle={"Total Eligible Trip Budget"}
                        Details={
                          (Number(data?._request?.budget) ?? 0) +
                          (Number(data?._request?.hotelBudget) ?? 0)
                        }
                      />
                      <Details
                        detailTitle={"Actual Final Cost"}
                        Details={
                          data?.total_final_budget !== null
                            ? data?.total_final_budget ?? "-"
                            : "-"
                        }
                      />
                      <Details
                        detailTitle={"Budget vs Actual Cost"}
                        Details={
                          data?.total_final_budget -
                            ((Number(data?._request?.budget) ?? 0) +
                              (Number(data?._request?.hotelBudget) ?? 0)) <
                          0
                            ? data?.total_final_budget -
                              ((Number(data?._request?.budget) ?? 0) +
                                (Number(data?._request?.hotelBudget) ?? 0)) +
                              "(underbudget)"
                            : data?.total_final_budget -
                                ((Number(data?._request?.budget) ?? 0) +
                                  (Number(data?._request?.hotelBudget) ?? 0)) >
                              0
                            ? data?.total_final_budget -
                              ((Number(data?._request?.budget) ?? 0) +
                                (Number(data?._request?.hotelBudget) ?? 0)) +
                              "(exceeded)"
                            : data?.total_final_budget -
                              ((Number(data?._request?.budget) ?? 0) +
                                (Number(data?._request?.hotelBudget) ?? 0))
                        }
                      />
                      <Details
                        detailTitle={"Created On"}
                        Details={
                          data?._request?.createdOn
                            ? format(
                                new Date(data?._request?.createdOn),
                                "dd/MM/yyyy hh:mm a"
                              )
                            : "-"
                        }
                      />

                      <Details
                        detailTitle={"Last Updated On"}
                        Details={
                          data?._request?.lastUpdatedOn
                            ? format(
                                new Date(data?._request?.lastUpdatedOn),
                                "dd/MM/yyyy hh:mm a"
                              )
                            : "-"
                        }
                      />
                    </div>
                    {selectedTravelEmployees?.travelJourney?.length > 0 && (
                      <>
                        <div className="headingForm" style={{ width: "100%" }}>
                          Travel Journey Details
                        </div>

                        <div
                          style={{
                            width: "100%",

                            padding: "0px 0px 0px 0px",
                          }}
                        >
                          <div className="detailInner">
                            {selectedTravelEmployees?.travelJourney?.map(
                              (person, index) => (
                                <div style={{ width: "100%" }}>
                                  {/* <div
                                // className="headingForm"
                                style={{
                                  width: "100%",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                  padding: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#1296b0",
                                  height: "24px",
                                  margin: "10px 0px 9px 0px",
                                }}
                              >
                                Journey Details
                              </div> */}
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="headingForm"
                                      style={{
                                        width: "100%",
                                        borderBottom: "none",
                                      }}
                                    >
                                      Travel Journey #{index + 1}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: "#EFF9FA",
                                      borderRadius: "12px",
                                    }}
                                  >
                                    <div
                                      style={{ padding: "10px 10px 30px 10px" }}
                                    >
                                      <div
                                        style={{
                                          // borderBottom: "1px solid #E6E6E6",
                                          margin: "10px 0px",
                                        }}
                                      >
                                        {person?.detailElement
                                          ?.originalTravelDetailId ? (
                                          <>
                                            <div
                                              className="headingForm"
                                              style={{ width: "97%" }}
                                            >
                                              Old Journey Details{" "}
                                            </div>
                                            <div className="detailInner">
                                              <Details
                                                detailTitle={"From Location"}
                                                Details={
                                                  data?.travellingdetails?.filter(
                                                    (it) =>
                                                      it?.id ===
                                                      person?.detailElement
                                                        ?.originalTravelDetailId
                                                  )[0]?.pickupLocation
                                                }
                                              />
                                              <Details
                                                detailTitle={"Travelling Date"}
                                                Details={
                                                  data?.travellingdetails?.filter(
                                                    (it) =>
                                                      it?.id ===
                                                      person?.detailElement
                                                        ?.originalTravelDetailId
                                                  )[0]?.fromDate ===
                                                  "0001-01-01T00:00:00"
                                                    ? format(
                                                        new Date(
                                                          data?.travellingdetails?.filter(
                                                            (it) =>
                                                              it?.id ===
                                                              person
                                                                ?.detailElement
                                                                ?.originalTravelDetailId
                                                          )[0]?.toDate
                                                        ),
                                                        "dd/MM/yyyy"
                                                      )
                                                    : format(
                                                        new Date(
                                                          data?.travellingdetails?.filter(
                                                            (it) =>
                                                              it?.id ===
                                                              person
                                                                ?.detailElement
                                                                ?.originalTravelDetailId
                                                          )[0]?.fromDate
                                                        ),
                                                        "dd/MM/yyyy"
                                                      )
                                                }
                                              />
                                              <Details
                                                detailTitle={"To Location"}
                                                Details={
                                                  data?.travellingdetails?.filter(
                                                    (it) =>
                                                      it?.id ===
                                                      person?.detailElement
                                                        ?.originalTravelDetailId
                                                  )[0]?.dropLocation
                                                }
                                              />
                                              <Details
                                                detailTitle={"Booking Note"}
                                                Details={
                                                  data?.travellingdetails?.filter(
                                                    (it) =>
                                                      it?.id ===
                                                      person?.detailElement
                                                        ?.originalTravelDetailId
                                                  )[0]?.bookingnote
                                                }
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                      <div>
                                        {person?.detailElement
                                          ?.originalTravelDetailId && (
                                          <div
                                            className="headingForm"
                                            style={{ width: "97%" }}
                                          >
                                            Rescheduled Journey Details{" "}
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          // borderBottom: "1px solid #E6E6E6",
                                          margin: "10px 0px",
                                        }}
                                        className="detailInner"
                                      >
                                        {!selectedTravelEmployees
                                          ?.travelJourney[index]
                                          ?.detailIsReschedule ? (
                                          <>
                                            {" "}
                                            <Details
                                              detailTitle={"From Location"}
                                              Details={
                                                person?.detailElement
                                                  ?.pickupLocation
                                              }
                                            />
                                            <Details
                                              detailTitle={"Travelling Date"}
                                              Details={
                                                person?.detailElement
                                                  ?.fromDate ===
                                                "0001-01-01T00:00:00"
                                                  ? format(
                                                      new Date(
                                                        person?.detailElement?.toDate
                                                      ),
                                                      "dd/MM/yyyy"
                                                    )
                                                  : format(
                                                      new Date(
                                                        person?.detailElement?.fromDate
                                                      ),
                                                      "dd/MM/yyyy"
                                                    )
                                              }
                                            />
                                            <Details
                                              detailTitle={"To Location"}
                                              Details={
                                                person?.detailElement
                                                  ?.dropLocation
                                              }
                                            />
                                            <Details
                                              detailTitle={"Status"}
                                              Details={
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      getStatusColor(
                                                        person?.detailElement
                                                          ?.status === null
                                                          ? data?._request
                                                              ?.status
                                                          : person
                                                              ?.detailElement
                                                              ?.status
                                                      )?.split(",")?.[0],
                                                    color: getStatusColor(
                                                      person?.detailElement
                                                        ?.status === null
                                                        ? data?._request?.status
                                                        : person?.detailElement
                                                            ?.status
                                                    )?.split(",")?.[1],
                                                    cursor: "default",
                                                    padding: "6px",
                                                    borderRadius: "6px",
                                                  }}
                                                >
                                                  {person?.detailElement
                                                    ?.status === null
                                                    ? data?._request?.status
                                                    : person?.detailElement
                                                        ?.status}
                                                </div>
                                              }
                                            />
                                            <Details
                                              detailTitle={"Booking Note"}
                                              Details={
                                                person?.detailElement
                                                  ?.bookingnote
                                              }
                                            />
                                            {person?.detailElement
                                              ?.originalTravelDetailId && (
                                              <>
                                                <Details
                                                  detailTitle={
                                                    "Reschedule Charge"
                                                  }
                                                  Details={
                                                    person?.detailElement
                                                      ?.reschedule_Price
                                                  }
                                                />
                                                {/* <Details
                                                  detailTitle={
                                                    "Reschedule Ticket Price"
                                                  }
                                                  Details={
                                                    person?.detailElement
                                                      ?.reschedule_TicketPrice
                                                  }
                                                /> */}
                                              </>
                                            )}
                                            {person?.detailElement?.status ===
                                              "Cancelled" && (
                                              <>
                                                <Details
                                                  detailTitle={
                                                    "Cancellation Charge"
                                                  }
                                                  Details={
                                                    person?.detailElement
                                                      ?.cancelCharge
                                                  }
                                                />
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            {" "}
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={"From Location"}
                                              />
                                              <InputCustom
                                                value={
                                                  selectedTravelEmployees
                                                    ?.travelJourney[index]
                                                    ?.detailForm?.fromLocation
                                                }
                                                onChange={(e) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.travelJourney[
                                                        index
                                                      ].detailForm.fromLocation =
                                                        e.target.value;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                                maxLength={50}
                                                name="PickupLocation"
                                                type={"text"}
                                                placeholder={
                                                  " Enter from Location"
                                                }
                                              />
                                            </div>
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={"Travelling Date"}
                                              />
                                              <DatePickerCustom
                                                selected={
                                                  selectedTravelEmployees
                                                    ?.travelJourney[index]
                                                    ?.detailForm?.travellingDate
                                                }
                                                onChange={(date) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.travelJourney[
                                                        index
                                                      ].detailForm.travellingDate =
                                                        date;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                                name={"FromDate"}
                                                minDate={new Date()}
                                              />
                                            </div>
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={"To Location"}
                                              />
                                              <InputCustom
                                                value={
                                                  selectedTravelEmployees
                                                    ?.travelJourney[index]
                                                    ?.detailForm?.toLocation
                                                }
                                                onChange={(e) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.travelJourney[
                                                        index
                                                      ].detailForm.toLocation =
                                                        e.target.value;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                                maxLength={50}
                                                name="DropLocation"
                                                type={"text"}
                                                placeholder={
                                                  " Enter to Location"
                                                }
                                              />
                                            </div>
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={"Booking Note"}
                                              />
                                              <TextAreaCustom
                                                value={
                                                  selectedTravelEmployees
                                                    ?.travelJourney[index]
                                                    ?.detailForm?.bookingNote
                                                }
                                                onChange={(e) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.travelJourney[
                                                        index
                                                      ].detailForm.bookingNote =
                                                        e.target.value;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                                name="Bookingnote"
                                                placeholder={
                                                  " Enter Booking Note"
                                                }
                                                maxLength={500}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div style={{ padding: "0px 12px" }}>
                                        <div
                                          // className="headingForm"
                                          style={{
                                            width: "100%",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            fontSize: "15px",
                                            padding: "4px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#1296b0",
                                            height: "24px",
                                            margin: "10px 0px 9px 0px",
                                          }}
                                        >
                                          {data?._request?.type === "Self"
                                            ? "Employee's Details"
                                            : data?._request?.type === "Group"
                                            ? "Employee's Details"
                                            : data?._request?.type === "Vendor"
                                            ? "Vendor's Details"
                                            : data?._request?.type === "Guest"
                                            ? "Guest's Details"
                                            : data?._request?.type ===
                                                "Candidate" &&
                                              "Candidate's Details"}
                                        </div>

                                        <div
                                          style={{
                                            overflowY: "auto",
                                          }}
                                        >
                                          <table
                                            className="TravelTableMultiIn"
                                            style={{
                                              overflow: "auto",
                                              width: "100%",
                                              // height: "197px",
                                            }}
                                          >
                                            <thead
                                              style={{
                                                backgroundColor:
                                                  "var(--primary-hover)",
                                              }}
                                            >
                                              <tr>
                                                {selectedTravelEmployees
                                                  ?.travelJourney[index]
                                                  ?.detailIsCancel && (
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "center",
                                                      paddingLeft: "10px",
                                                      width: "60px",
                                                    }}
                                                  >
                                                    <div>
                                                      {selectedTravelEmployees?.travelJourney[
                                                        index
                                                      ]?.detailArr?.every(
                                                        (it) =>
                                                          it?.isSelected ===
                                                          true
                                                      ) ? (
                                                        <svg
                                                          onClick={() =>
                                                            setSelectedTravelEmployees(
                                                              (prev) => {
                                                                const objPrev =
                                                                  {
                                                                    ...prev,
                                                                  };

                                                                objPrev.travelJourney[
                                                                  index
                                                                ].detailArr =
                                                                  objPrev?.travelJourney[
                                                                    index
                                                                  ]?.detailArr?.map(
                                                                    (item) => ({
                                                                      ...item,
                                                                      isSelected: false,
                                                                    })
                                                                  );

                                                                return objPrev;
                                                              }
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          width="17"
                                                          height="17"
                                                          viewBox="0 0 17 17"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <rect
                                                            x="0.983887"
                                                            y="0.5"
                                                            width="16"
                                                            height="16"
                                                            rx="2.66667"
                                                            fill="#1296B0"
                                                          />
                                                          <path
                                                            d="M4.4201 8.42964L7.18764 11.1666L13.5482 5.83325"
                                                            stroke="white"
                                                            stroke-width="1.28"
                                                            stroke-linecap="round"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          onClick={() =>
                                                            setSelectedTravelEmployees(
                                                              (prev) => {
                                                                const objPrev =
                                                                  {
                                                                    ...prev,
                                                                  };

                                                                objPrev.travelJourney[
                                                                  index
                                                                ].detailArr =
                                                                  objPrev?.travelJourney[
                                                                    index
                                                                  ]?.detailArr?.map(
                                                                    (item) => ({
                                                                      ...item,
                                                                      isSelected: true,
                                                                    })
                                                                  );

                                                                return objPrev;
                                                              }
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          width="18"
                                                          height="17"
                                                          viewBox="0 0 18 17"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <rect
                                                            x="0.983887"
                                                            y="0.5"
                                                            width="16"
                                                            height="16"
                                                            rx="2.66667"
                                                            fill="white"
                                                            stroke="#BFBFBF"
                                                            stroke-width="0.888889"
                                                          />
                                                        </svg>
                                                      )}
                                                    </div>
                                                  </td>
                                                )}
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Sl.No.{" "}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  {data?._request?.type ===
                                                  "Self"
                                                    ? "Employee's"
                                                    : data?._request?.type ===
                                                      "Group"
                                                    ? "Employee's"
                                                    : data?._request?.type ===
                                                      "Vendor"
                                                    ? "Vendor's"
                                                    : data?._request?.type ===
                                                      "Guest"
                                                    ? "Guest's"
                                                    : data?._request?.type ===
                                                        "Candidate" &&
                                                      "Candidate's"}{" "}
                                                  Name{" "}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Status
                                                </td>
                                                {data?._request?.type !==
                                                  "Candidate" && (
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Phone No.{" "}
                                                  </td>
                                                )}
                                                {data?._request?.type !==
                                                  "Candidate" && (
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Email{" "}
                                                  </td>
                                                )}
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Age{" "}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Gender{" "}
                                                </td>
                                              </tr>
                                            </thead>
                                            {person?.detailArr?.map(
                                              (item, indexW) => (
                                                <tr>
                                                  {selectedTravelEmployees
                                                    ?.travelJourney[index]
                                                    ?.detailIsCancel && (
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        paddingLeft: "10px",
                                                        color: "#555",
                                                        width: "60px",
                                                      }}
                                                    >
                                                      {selectedTravelEmployees
                                                        ?.travelJourney?.[index]
                                                        ?.detailArr[indexW]
                                                        ?.element?.status !==
                                                      "Cancelled" ? (
                                                        selectedTravelEmployees
                                                          ?.travelJourney?.[
                                                          index
                                                        ]?.detailArr[indexW]
                                                          ?.isSelected ? (
                                                          <svg
                                                            onClick={() =>
                                                              setSelectedTravelEmployees(
                                                                (prev) => {
                                                                  const objPrev =
                                                                    {
                                                                      ...prev,
                                                                    };

                                                                  objPrev.travelJourney[
                                                                    index
                                                                  ].detailArr[
                                                                    indexW
                                                                  ].isSelected =
                                                                    !objPrev
                                                                      ?.travelJourney[
                                                                      index
                                                                    ]
                                                                      ?.detailArr[
                                                                      indexW
                                                                    ]
                                                                      ?.isSelected;
                                                                  return objPrev;
                                                                }
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <rect
                                                              x="0.983887"
                                                              y="0.5"
                                                              width="16"
                                                              height="16"
                                                              rx="2.66667"
                                                              fill="#1296B0"
                                                            />
                                                            <path
                                                              d="M4.4201 8.42964L7.18764 11.1666L13.5482 5.83325"
                                                              stroke="white"
                                                              stroke-width="1.28"
                                                              stroke-linecap="round"
                                                            />
                                                          </svg>
                                                        ) : (
                                                          <svg
                                                            onClick={() =>
                                                              setSelectedTravelEmployees(
                                                                (prev) => {
                                                                  const objPrev =
                                                                    {
                                                                      ...prev,
                                                                    };

                                                                  objPrev.travelJourney[
                                                                    index
                                                                  ].detailArr[
                                                                    indexW
                                                                  ].isSelected =
                                                                    !objPrev
                                                                      ?.travelJourney[
                                                                      index
                                                                    ]
                                                                      ?.detailArr[
                                                                      indexW
                                                                    ]
                                                                      ?.isSelected;
                                                                  return objPrev;
                                                                }
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            width="18"
                                                            height="17"
                                                            viewBox="0 0 18 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <rect
                                                              x="0.983887"
                                                              y="0.5"
                                                              width="16"
                                                              height="16"
                                                              rx="2.66667"
                                                              fill="white"
                                                              stroke="#BFBFBF"
                                                              stroke-width="0.888889"
                                                            />
                                                          </svg>
                                                        )
                                                      ) : (
                                                        <div></div>
                                                      )}
                                                    </td>
                                                  )}
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    {indexW + 1}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    {data?._request?.type !==
                                                    "Candidate"
                                                      ? item?.element?.name
                                                      : data?.candidateName_Travel}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          getStatusColor(
                                                            item?.element
                                                              ?.status === null
                                                              ? person
                                                                  ?.detailElement
                                                                  ?.status ===
                                                                null
                                                                ? data?._request
                                                                    ?.status
                                                                : person
                                                                    ?.detailElement
                                                                    ?.status
                                                              : item?.element
                                                                  ?.status
                                                          )?.split(",")?.[0],
                                                        color: getStatusColor(
                                                          item?.element
                                                            ?.status === null
                                                            ? person
                                                                ?.detailElement
                                                                ?.status ===
                                                              null
                                                              ? data?._request
                                                                  ?.status
                                                              : person
                                                                  ?.detailElement
                                                                  ?.status
                                                            : item?.element
                                                                ?.status
                                                        )?.split(",")?.[1],
                                                        cursor: "default",
                                                        padding: "6px",
                                                        borderRadius: "6px",
                                                        width: "fit-content",
                                                        margin: "auto auto",
                                                      }}
                                                    >
                                                      {item?.element?.status ===
                                                      null
                                                        ? person?.detailElement
                                                            ?.status === null
                                                          ? data?._request
                                                              ?.status
                                                          : person
                                                              ?.detailElement
                                                              ?.status
                                                        : item?.element?.status}
                                                    </div>
                                                  </td>
                                                  {data?._request?.type !==
                                                    "Candidate" && (
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "start",
                                                        paddingLeft: "10px",
                                                        color: "#555",
                                                      }}
                                                    >
                                                      {
                                                        item?.element
                                                          ?.phoneNumber
                                                      }
                                                    </td>
                                                  )}
                                                  {data?._request?.type !==
                                                    "Candidate" && (
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "start",
                                                        paddingLeft: "10px",
                                                        color: "#555",
                                                      }}
                                                    >
                                                      {item?.element?.emailId}
                                                    </td>
                                                  )}
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    {item?.element?.age}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    {item?.element?.gender}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </table>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{ margin: "22px 0px" }}>
                                      {!showAction &&
                                        person?.detailActions?.length > 0 && (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              width: "100%",
                                              gap: "10px",
                                              padding: "16px",
                                              borderTop: "1px solid #BFBFBF",
                                            }}
                                          >
                                            {" "}
                                            {person?.detailActions?.map(
                                              (it) => {
                                                if (
                                                  it?.type === "Cancel Journey"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FFFFFF",
                                                        color: "#F71D1D",
                                                        boxShadow:
                                                          " 0px 4px 10px 0px #0000000A, 0px 1px 10px 0px #0000000D inset",
                                                      }}
                                                      onClick={() => {
                                                        setShowAction(true);
                                                        setIsCancel(true);
                                                        setSelectedTravelEmployees(
                                                          (prev) => {
                                                            const finalObj = {
                                                              ...prev,
                                                            };
                                                            finalObj.travelJourney[
                                                              index
                                                            ].detailIsCancel = true;
                                                            return finalObj;
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      Cancel Journey
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type ===
                                                  "Reschedule Journey"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        setShowAction(true);
                                                        setIsReSchedule(true);
                                                        setIsEditReSchedule(
                                                          false
                                                        );

                                                        setSelectedTravelEmployees(
                                                          (prev) => {
                                                            const finalObj = {
                                                              ...prev,
                                                            };
                                                            finalObj.travelJourney[
                                                              index
                                                            ].detailIsReschedule = true;
                                                            return finalObj;
                                                          }
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      Reschedule
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type ===
                                                  "Edit Reschedule Journey"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        setShowAction(true);
                                                        setIsReSchedule(false);
                                                        setIsEditReSchedule(
                                                          true
                                                        );

                                                        setSelectedTravelEmployees(
                                                          (prev) => {
                                                            const finalObj = {
                                                              ...prev,
                                                            };
                                                            finalObj.travelJourney[
                                                              index
                                                            ].detailIsReschedule = true;
                                                            return finalObj;
                                                          }
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      Update Reschedule
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type ===
                                                    "Approve Journey" ||
                                                  it?.type === "Reject Journey"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() =>
                                                        AppRejCall(
                                                          it?.type,
                                                          "travel",
                                                          it?.journeyActionId
                                                        )
                                                      }
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {it?.type ===
                                                      "Approve Journey"
                                                        ? "Approve"
                                                        : it?.type ===
                                                          "Reject Journey"
                                                        ? "Reject"
                                                        : it?.type}
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type ===
                                                  "Upload Ticket Options"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        toggleActionPopup(
                                                          it?.type,
                                                          person?.detailElement
                                                            ?.id
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {it?.type}
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type === "Submit Booking"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        toggleActionPopup(
                                                          it?.type,
                                                          person?.detailElement
                                                            ?.id
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {it?.type}
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type === "Upload Tickets"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        toggleActionPopup(
                                                          it?.type,
                                                          person?.detailElement
                                                            ?.id
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {it?.type}
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {}}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {it?.type}
                                                    </div>
                                                  );
                                                }
                                              }
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {!showAction &&
                      selectedTravelEmployees?.travelJourney?.length > 0 &&
                      data?._request?.employeeId === LoggedInUser?.id &&
                      data?._request?.isTicketBooked &&
                      (data?._request?.status === "Ticket Booked" ||
                        data?._request?.status === "pending") &&
                      data?.travellingdetails?.every(
                        (it) =>
                          new Date(it?.fromDate) - new Date() >=
                          24 * 60 * 60 * 1000
                      ) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                            gap: "10px",
                          }}
                        >
                          {" "}
                          <div
                            className="button secondaryButton"
                            style={{
                              margin: "0px",
                              fontSize: "13px",
                              background: "#1296B0",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              gap: "6px",
                            }}
                            onClick={() => togglePopup("Add Journey")}
                          >
                            <svg
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 1.40137V12.5442"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M0.428467 6.93848H11.5713"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div>Add Journey</div>
                          </div>
                        </div>
                      )}
                    {selectedTravelEmployees?.hotelBooked?.length > 0 && (
                      <>
                        <div className="headingForm" style={{ width: "100%" }}>
                          Hotel Booking Details{" "}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            padding: "0px 0px 0px 0px",
                          }}
                        >
                          <div className="detailInner">
                            {selectedTravelEmployees?.hotelBooked?.map(
                              (person, index) => (
                                <div style={{ width: "100%" }}>
                                  {/* <div
                                // className="headingForm"
                                style={{
                                  width: "100%",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                  padding: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#1296b0",
                                  height: "24px",
                                  margin: "10px 0px 9px 0px",
                                }}
                              >
                                Hotel Booking Details
                              </div> */}
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="headingForm"
                                      style={{
                                        width: "100%",
                                        borderBottom: "none",
                                      }}
                                    >
                                      Hotel Booked #{index + 1}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: "#EFF9FA",
                                      borderRadius: "12px",
                                    }}
                                  >
                                    <div
                                      style={{ padding: "10px 10px 30px 10px" }}
                                    >
                                      <div
                                        style={{
                                          // borderBottom: "1px solid #E6E6E6",
                                          margin: "10px 0px",
                                        }}
                                      >
                                        {person?.detailElement
                                          ?.originalHotelBookinglId ? (
                                          <>
                                            <div
                                              className="headingForm"
                                              style={{ width: "97%" }}
                                            >
                                              Old Hotel Booking Details{" "}
                                            </div>
                                            <div className="detailInner">
                                              <Details
                                                detailTitle={"Hotel Category "}
                                                Details={
                                                  data?.category_name_hotel
                                                }
                                              />
                                              <Details
                                                detailTitle={"Location"}
                                                Details={
                                                  data?.hoteldetail?.filter(
                                                    (it) =>
                                                      it?.id ===
                                                      person?.detailElement
                                                        ?.originalHotelBookinglId
                                                  )[0]?.location
                                                }
                                              />
                                              <Details
                                                detailTitle={"Check In Time"}
                                                Details={
                                                  data?.hoteldetail?.filter(
                                                    (it) =>
                                                      it?.id ===
                                                      person?.detailElement
                                                        ?.originalHotelBookinglId
                                                  )[0]?.checkInTime
                                                    ? format(
                                                        new Date(
                                                          data?.hoteldetail?.filter(
                                                            (it) =>
                                                              it?.id ===
                                                              person
                                                                ?.detailElement
                                                                ?.originalHotelBookinglId
                                                          )[0]?.checkInTime
                                                        ),
                                                        "dd/MM/yyyy hh:mm a"
                                                      )
                                                    : "-"
                                                }
                                              />
                                              <Details
                                                detailTitle={"Preferred Hotel"}
                                                Details={
                                                  data?.hoteldetail?.filter(
                                                    (it) =>
                                                      it?.id ===
                                                      person?.detailElement
                                                        ?.originalHotelBookinglId
                                                  )[0]?.preferHotel
                                                }
                                              />
                                              <Details
                                                detailTitle={"Check Out Time"}
                                                Details={
                                                  data?.hoteldetail?.filter(
                                                    (it) =>
                                                      it?.id ===
                                                      person?.detailElement
                                                        ?.originalHotelBookinglId
                                                  )[0]?.checkOutTime
                                                    ? format(
                                                        new Date(
                                                          data?.hoteldetail?.filter(
                                                            (it) =>
                                                              it?.id ===
                                                              person
                                                                ?.detailElement
                                                                ?.originalHotelBookinglId
                                                          )[0]?.checkOutTime
                                                        ),
                                                        "dd/MM/yyyy hh:mm a"
                                                      )
                                                    : "-"
                                                }
                                              />

                                              <Details
                                                detailTitle={"Booking Note"}
                                                Details={
                                                  data?.hoteldetail?.filter(
                                                    (it) =>
                                                      it?.id ===
                                                      person?.detailElement
                                                        ?.originalHotelBookinglId
                                                  )[0]?.bookingNote
                                                }
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                      <div>
                                        {person?.detailElement
                                          ?.originalHotelBookinglId && (
                                          <div
                                            className="headingForm"
                                            style={{ width: "97%" }}
                                          >
                                            Rescheduled Hotel Booking Details{" "}
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          // borderBottom: "1px solid #E6E6E6",
                                          margin: "10px 0px",
                                        }}
                                        className="detailInner"
                                      >
                                        {!selectedTravelEmployees?.hotelBooked[
                                          index
                                        ]?.detailIsReschedule ? (
                                          <>
                                            <Details
                                              detailTitle={"Hotel Category "}
                                              Details={
                                                data?.category_name_hotel
                                              }
                                            />
                                            <Details
                                              detailTitle={"Location"}
                                              Details={
                                                person?.detailElement?.location
                                              }
                                            />
                                            <Details
                                              detailTitle={"Check In Time"}
                                              Details={
                                                person?.detailElement
                                                  ?.checkInTime
                                                  ? format(
                                                      new Date(
                                                        person?.detailElement?.checkInTime
                                                      ),
                                                      "dd/MM/yyyy hh:mm a"
                                                    )
                                                  : "-"
                                              }
                                            />
                                            <Details
                                              detailTitle={"Preferred Hotel"}
                                              Details={
                                                person?.detailElement
                                                  ?.preferHotel
                                              }
                                            />
                                            <Details
                                              detailTitle={"Check Out Time"}
                                              Details={
                                                person?.detailElement
                                                  ?.checkOutTime
                                                  ? format(
                                                      new Date(
                                                        person?.detailElement?.checkOutTime
                                                      ),
                                                      "dd/MM/yyyy hh:mm a"
                                                    )
                                                  : "-"
                                              }
                                            />

                                            {/* {data?._request?.isLinkedTravelHotel && (
                                        <Details
                                          detailTitle={"Budget"}
                                          Details={person?.budget}
                                        />
                                      )} */}
                                            {/* {data?._request?.isLinkedTravelHotel && ( */}
                                            <Details
                                              detailTitle={"Status"}
                                              Details={
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      getStatusColor(
                                                        person?.detailElement
                                                          ?.status === null
                                                          ? data?._request
                                                              ?.status
                                                          : person
                                                              ?.detailElement
                                                              ?.status
                                                      )?.split(",")?.[0],
                                                    color: getStatusColor(
                                                      person?.detailElement
                                                        ?.status === null
                                                        ? data?._request?.status
                                                        : person?.detailElement
                                                            ?.status
                                                    )?.split(",")?.[1],
                                                    cursor: "default",
                                                    padding: "6px",
                                                    borderRadius: "6px",
                                                  }}
                                                >
                                                  {person?.detailElement
                                                    ?.status === null
                                                    ? data?._request?.status
                                                    : person?.detailElement
                                                        ?.status}
                                                </div>
                                              }
                                            />
                                            <Details
                                              detailTitle={"Booking Note"}
                                              Details={
                                                person?.detailElement
                                                  ?.bookingNote
                                              }
                                            />
                                            {person?.detailElement
                                              ?.originalHotelBookinglId && (
                                              <>
                                                <Details
                                                  detailTitle={
                                                    "Reschedule Charge"
                                                  }
                                                  Details={
                                                    person?.detailElement
                                                      ?.reschedule_Price
                                                  }
                                                />
                                                {/* <Details
                                                  detailTitle={
                                                    "Reschedule Ticket Price"
                                                  }
                                                  Details={
                                                    person?.detailElement
                                                      ?.reschedule_TicketPrice
                                                  }
                                                /> */}
                                              </>
                                            )}
                                            {person?.detailElement?.status ===
                                              "Cancelled" && (
                                              <>
                                                <Details
                                                  detailTitle={
                                                    "Cancellation Charge"
                                                  }
                                                  Details={
                                                    person?.detailElement
                                                      ?.cancelCharge
                                                  }
                                                />
                                              </>
                                            )}
                                            {/* )} */}
                                          </>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            {" "}
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={"Hotel Category"}
                                              />

                                              <SelectCustom
                                                name="HotelCategory"
                                                options={hotelCategoryOptions}
                                                values={
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm?.hotelCategory
                                                }
                                                onChange={(value) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.hotelBooked[
                                                        index
                                                      ].detailForm.hotelCategory =
                                                        value;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={"Location"}
                                              />
                                              <InputCustom
                                                value={
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm?.location
                                                }
                                                onChange={(e) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.hotelBooked[
                                                        index
                                                      ].detailForm.location =
                                                        e.target.value;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                                maxLength={50}
                                                name="Location"
                                                type={"text"}
                                                placeholder={
                                                  "Please Enter Location"
                                                }
                                              />
                                            </div>
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={
                                                  "Check-In Date and Time"
                                                }
                                              />
                                              <DatePickerCustom
                                                selected={
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm?.checkInDate
                                                }
                                                onChange={(date) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.hotelBooked[
                                                        index
                                                      ].detailForm.checkInDate =
                                                        date;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                                name={"CheckInTime"}
                                                minDate={new Date()}
                                                showTimeSelect
                                                maxDate={
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm
                                                    ?.checkOutDate &&
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm?.checkOutDate
                                                }
                                              />
                                            </div>
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={"Preferred Hotel"}
                                              />
                                              <InputCustom
                                                value={
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm?.preferredHotel
                                                }
                                                onChange={(e) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.hotelBooked[
                                                        index
                                                      ].detailForm.preferredHotel =
                                                        e.target.value;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                                maxLength={150}
                                                name="PreferHotel"
                                                type={"text"}
                                                placeholder={
                                                  "Please Enter Preferred Hotel"
                                                }
                                              />
                                            </div>
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={
                                                  "Check-Out Date and Time"
                                                }
                                              />
                                              <DatePickerCustom
                                                selected={
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm?.checkOutDate
                                                }
                                                onChange={(date) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.hotelBooked[
                                                        index
                                                      ].detailForm.checkOutDate =
                                                        date;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                                name={"CheckOutTime"}
                                                minDate={
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm?.checkInDate &&
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm?.checkInDate
                                                }
                                                showTimeSelect
                                              />
                                            </div>
                                            <div style={{ width: "50%" }}>
                                              <LabelCustom
                                                labelName={"Booking Note"}
                                              />
                                              <TextAreaCustom
                                                value={
                                                  selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailForm?.bookingNote
                                                }
                                                onChange={(e) => {
                                                  setSelectedTravelEmployees(
                                                    (prev) => {
                                                      const obj = { ...prev };
                                                      obj.hotelBooked[
                                                        index
                                                      ].detailForm.bookingNote =
                                                        e.target.value;
                                                      return obj;
                                                    }
                                                  );
                                                }}
                                                name="BookingNote"
                                                placeholder={
                                                  "Please Enter Booking Note"
                                                }
                                                maxLength={500}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div style={{ padding: "0px 12px" }}>
                                        <div
                                          // className="headingForm"
                                          style={{
                                            width: "100%",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            fontSize: "15px",
                                            padding: "4px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#1296b0",
                                            height: "24px",
                                            margin: "10px 0px 9px 0px",
                                          }}
                                        >
                                          {data?._request?.type === "Self"
                                            ? "Employee's"
                                            : data?._request?.type === "Group"
                                            ? "Employee's"
                                            : data?._request?.type === "Vendor"
                                            ? "Vendor's"
                                            : data?._request?.type === "Guest"
                                            ? "Guest's"
                                            : data?._request?.type ===
                                                "Candidate" &&
                                              "Candidate's"}{" "}
                                          Details
                                        </div>
                                        <div
                                          style={{
                                            overflowY: "auto",
                                          }}
                                        >
                                          <table
                                            className="TravelTableMultiIn"
                                            style={{
                                              overflow: "auto",
                                              width: "100%",
                                              // height: "197px",
                                            }}
                                          >
                                            <thead
                                              style={{
                                                backgroundColor:
                                                  "var(--primary-hover)",
                                              }}
                                            >
                                              <tr>
                                                {selectedTravelEmployees
                                                  ?.hotelBooked[index]
                                                  ?.detailIsCancel && (
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "center",
                                                      paddingLeft: "10px",
                                                      width: "60px",
                                                    }}
                                                  >
                                                    <div>
                                                      {selectedTravelEmployees?.hotelBooked[
                                                        index
                                                      ]?.detailArr
                                                        ?.filter(
                                                          (it) =>
                                                            it?.element
                                                              ?.status !==
                                                            "Cancelled"
                                                        )
                                                        ?.every(
                                                          (it) =>
                                                            it?.isSelected ===
                                                            true
                                                        ) ? (
                                                        <svg
                                                          onClick={() =>
                                                            setSelectedTravelEmployees(
                                                              (prev) => {
                                                                const objPrev =
                                                                  {
                                                                    ...prev,
                                                                  };

                                                                objPrev.hotelBooked[
                                                                  index
                                                                ].detailArr =
                                                                  objPrev?.hotelBooked[
                                                                    index
                                                                  ]?.detailArr?.map(
                                                                    (item) => ({
                                                                      ...item,
                                                                      isSelected: false,
                                                                    })
                                                                  );

                                                                return objPrev;
                                                              }
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          width="17"
                                                          height="17"
                                                          viewBox="0 0 17 17"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <rect
                                                            x="0.983887"
                                                            y="0.5"
                                                            width="16"
                                                            height="16"
                                                            rx="2.66667"
                                                            fill="#1296B0"
                                                          />
                                                          <path
                                                            d="M4.4201 8.42964L7.18764 11.1666L13.5482 5.83325"
                                                            stroke="white"
                                                            stroke-width="1.28"
                                                            stroke-linecap="round"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        <svg
                                                          onClick={() =>
                                                            setSelectedTravelEmployees(
                                                              (prev) => {
                                                                const objPrev =
                                                                  {
                                                                    ...prev,
                                                                  };

                                                                objPrev.hotelBooked[
                                                                  index
                                                                ].detailArr =
                                                                  objPrev?.hotelBooked[
                                                                    index
                                                                  ]?.detailArr?.map(
                                                                    (item) => {
                                                                      if (
                                                                        item
                                                                          ?.element
                                                                          ?.status ===
                                                                        "Cancelled"
                                                                      ) {
                                                                        return {
                                                                          ...item,
                                                                          isSelected: false,
                                                                        };
                                                                      } else {
                                                                        return {
                                                                          ...item,
                                                                          isSelected: true,
                                                                        };
                                                                      }
                                                                    }
                                                                  );

                                                                return objPrev;
                                                              }
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          width="18"
                                                          height="17"
                                                          viewBox="0 0 18 17"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <rect
                                                            x="0.983887"
                                                            y="0.5"
                                                            width="16"
                                                            height="16"
                                                            rx="2.66667"
                                                            fill="white"
                                                            stroke="#BFBFBF"
                                                            stroke-width="0.888889"
                                                          />
                                                        </svg>
                                                      )}
                                                    </div>
                                                  </td>
                                                )}
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Sl.No.{" "}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  {data?._request?.type ===
                                                  "Self"
                                                    ? "Employee's"
                                                    : data?._request?.type ===
                                                      "Group"
                                                    ? "Employee's"
                                                    : data?._request?.type ===
                                                      "Vendor"
                                                    ? "Vendor's"
                                                    : data?._request?.type ===
                                                      "Guest"
                                                    ? "Guest's"
                                                    : data?._request?.type ===
                                                        "Candidate" &&
                                                      "Candidate's"}{" "}
                                                  Name{" "}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Status
                                                </td>
                                                {data?._request?.type !==
                                                  "Candidate" && (
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Phone No.{" "}
                                                  </td>
                                                )}
                                                {data?._request?.type !==
                                                  "Candidate" && (
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Email{" "}
                                                  </td>
                                                )}
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Adults{" "}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    textAlign: "start",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  Children{" "}
                                                </td>
                                              </tr>
                                            </thead>
                                            {person?.detailArr?.map(
                                              (item, indexW) => (
                                                <tr>
                                                  {selectedTravelEmployees
                                                    ?.hotelBooked[index]
                                                    ?.detailIsCancel && (
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "center",
                                                        paddingLeft: "10px",
                                                        color: "#555",
                                                        width: "60px",
                                                      }}
                                                    >
                                                      {selectedTravelEmployees
                                                        ?.hotelBooked?.[index]
                                                        ?.detailArr[indexW]
                                                        ?.element?.status !==
                                                      "Cancelled" ? (
                                                        selectedTravelEmployees
                                                          ?.hotelBooked?.[index]
                                                          ?.detailArr[indexW]
                                                          ?.isSelected ? (
                                                          <svg
                                                            onClick={() =>
                                                              setSelectedTravelEmployees(
                                                                (prev) => {
                                                                  const objPrev =
                                                                    {
                                                                      ...prev,
                                                                    };

                                                                  objPrev.hotelBooked[
                                                                    index
                                                                  ].detailArr[
                                                                    indexW
                                                                  ].isSelected =
                                                                    !objPrev
                                                                      ?.hotelBooked[
                                                                      index
                                                                    ]
                                                                      ?.detailArr[
                                                                      indexW
                                                                    ]
                                                                      ?.isSelected;
                                                                  return objPrev;
                                                                }
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <rect
                                                              x="0.983887"
                                                              y="0.5"
                                                              width="16"
                                                              height="16"
                                                              rx="2.66667"
                                                              fill="#1296B0"
                                                            />
                                                            <path
                                                              d="M4.4201 8.42964L7.18764 11.1666L13.5482 5.83325"
                                                              stroke="white"
                                                              stroke-width="1.28"
                                                              stroke-linecap="round"
                                                            />
                                                          </svg>
                                                        ) : (
                                                          <svg
                                                            onClick={() =>
                                                              setSelectedTravelEmployees(
                                                                (prev) => {
                                                                  const objPrev =
                                                                    {
                                                                      ...prev,
                                                                    };

                                                                  objPrev.hotelBooked[
                                                                    index
                                                                  ].detailArr[
                                                                    indexW
                                                                  ].isSelected =
                                                                    !objPrev
                                                                      ?.hotelBooked[
                                                                      index
                                                                    ]
                                                                      ?.detailArr[
                                                                      indexW
                                                                    ]
                                                                      ?.isSelected;
                                                                  return objPrev;
                                                                }
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            width="18"
                                                            height="17"
                                                            viewBox="0 0 18 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <rect
                                                              x="0.983887"
                                                              y="0.5"
                                                              width="16"
                                                              height="16"
                                                              rx="2.66667"
                                                              fill="white"
                                                              stroke="#BFBFBF"
                                                              stroke-width="0.888889"
                                                            />
                                                          </svg>
                                                        )
                                                      ) : (
                                                        <div></div>
                                                      )}
                                                    </td>
                                                  )}
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    {indexW + 1}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    {data?._request?.type !==
                                                    "Candidate"
                                                      ? item?.element?.name
                                                      : data?.candidateName_Hotel}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          getStatusColor(
                                                            item?.element
                                                              ?.status === null
                                                              ? person
                                                                  ?.detailElement
                                                                  ?.status ===
                                                                null
                                                                ? data?._request
                                                                    ?.status
                                                                : person
                                                                    ?.detailElement
                                                                    ?.status
                                                              : item?.element
                                                                  ?.status
                                                          )?.split(",")?.[0],
                                                        color: getStatusColor(
                                                          item?.element
                                                            ?.status === null
                                                            ? person
                                                                ?.detailElement
                                                                ?.status ===
                                                              null
                                                              ? data?._request
                                                                  ?.status
                                                              : person
                                                                  ?.detailElement
                                                                  ?.status
                                                            : item?.element
                                                                ?.status
                                                        )?.split(",")?.[1],
                                                        cursor: "default",
                                                        padding: "6px",
                                                        borderRadius: "6px",
                                                        width: "fit-content",
                                                        margin: "auto auto",
                                                      }}
                                                    >
                                                      {item?.element?.status ===
                                                      null
                                                        ? person?.detailElement
                                                            ?.status === null
                                                          ? data?._request
                                                              ?.status
                                                          : person
                                                              ?.detailElement
                                                              ?.status
                                                        : item?.element?.status}
                                                    </div>
                                                  </td>
                                                  {data?._request?.type !==
                                                    "Candidate" && (
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "start",
                                                        paddingLeft: "10px",
                                                        color: "#555",
                                                      }}
                                                    >
                                                      {
                                                        item?.element
                                                          ?.phoneNumber
                                                      }
                                                    </td>
                                                  )}
                                                  {data?._request?.type !==
                                                    "Candidate" && (
                                                    <td
                                                      style={{
                                                        border:
                                                          "1px solid black",
                                                        textAlign: "start",
                                                        paddingLeft: "10px",
                                                        color: "#555",
                                                      }}
                                                    >
                                                      {item?.element?.emailId}
                                                    </td>
                                                  )}
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    {item?.element?.adults}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid black",
                                                      textAlign: "start",
                                                      paddingLeft: "10px",
                                                      color: "#555",
                                                    }}
                                                  >
                                                    {item?.element?.children}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ margin: "22px 0px" }}>
                                      {!showAction &&
                                        person?.detailActions?.length > 0 && (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              width: "100%",
                                              gap: "10px",
                                              padding: "16px",
                                              borderTop: "1px solid #BFBFBF",
                                            }}
                                          >
                                            {person?.detailActions?.map(
                                              (it) => {
                                                if (
                                                  it?.type === "Cancel Journey"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FFFFFF",
                                                        color: "#F71D1D",
                                                        boxShadow:
                                                          " 0px 4px 10px 0px #0000000A, 0px 1px 10px 0px #0000000D inset",
                                                      }}
                                                      onClick={() => {
                                                        setShowAction(true);
                                                        setIsCancel(true);
                                                        setSelectedTravelEmployees(
                                                          (prev) => {
                                                            const finalObj = {
                                                              ...prev,
                                                            };
                                                            finalObj.hotelBooked[
                                                              index
                                                            ].detailIsCancel = true;
                                                            return finalObj;
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      Cancel Journey
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type ===
                                                  "Reschedule Journey"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        setShowAction(true);
                                                        setIsReSchedule(true);
                                                        setIsEditReSchedule(
                                                          false
                                                        );

                                                        setSelectedTravelEmployees(
                                                          (prev) => {
                                                            const finalObj = {
                                                              ...prev,
                                                            };
                                                            finalObj.hotelBooked[
                                                              index
                                                            ].detailIsReschedule = true;
                                                            return finalObj;
                                                          }
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      Reschedule
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type ===
                                                  "Edit Reschedule Journey"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        setShowAction(true);
                                                        setIsReSchedule(false);
                                                        setIsEditReSchedule(
                                                          true
                                                        );

                                                        setSelectedTravelEmployees(
                                                          (prev) => {
                                                            const finalObj = {
                                                              ...prev,
                                                            };
                                                            finalObj.hotelBooked[
                                                              index
                                                            ].detailIsReschedule = true;
                                                            return finalObj;
                                                          }
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      Update Reschedule
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type ===
                                                    "Approve Journey" ||
                                                  it?.type === "Reject Journey"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        AppRejCall(
                                                          it?.type,
                                                          "hotel",
                                                          it?.journeyActionId
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {it?.type ===
                                                      "Approve Journey"
                                                        ? "Approve"
                                                        : it?.type ===
                                                          "Reject Journey"
                                                        ? "Reject"
                                                        : it?.type}
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type ===
                                                  "Upload Ticket Options"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        toggleActionPopup(
                                                          it?.type,
                                                          person?.detailElement
                                                            ?.id
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {it?.type}
                                                    </div>
                                                  );
                                                } else if (
                                                  it?.type === "Submit Booking"
                                                ) {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {
                                                        toggleActionPopup(
                                                          it?.type,
                                                          person?.detailElement
                                                            ?.id
                                                        );
                                                      }}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {it?.type}
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div
                                                      className="button secondaryButton"
                                                      onClick={() => {}}
                                                      style={{
                                                        margin: "0px",
                                                        fontSize: "13px",
                                                        background: "#FB7158",
                                                        color: "white",
                                                      }}
                                                    >
                                                      {it?.type}
                                                    </div>
                                                  );
                                                }
                                              }
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {!showAction &&
                      selectedTravelEmployees?.hotelBooked?.length > 0 &&
                      data?._request?.employeeId === LoggedInUser?.id &&
                      data?._request?.isTicketBooked &&
                      (data?._request?.status === "Ticket Booked" ||
                        data?._request?.status === "pending") &&
                      data?.hoteldetail?.every(
                        (it) =>
                          new Date(it?.checkInTime) - new Date() >=
                          24 * 60 * 60 * 1000
                      ) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                            gap: "10px",
                          }}
                        >
                          {" "}
                          <div
                            className="button secondaryButton"
                            style={{
                              margin: "0px",
                              fontSize: "13px",
                              background: "#1296B0",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              gap: "6px",
                            }}
                            onClick={() => togglePopup("Add Hotel Booking")}
                          >
                            <svg
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 1.40137V12.5442"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M0.428467 6.93848H11.5713"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div>Add Hotel Booking</div>
                          </div>
                        </div>
                      )}

                    <div className="headingForm"> Requester's Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Requester Name"}
                        Details={`${data?._request?.employee?.name} ${
                          data?._request?.employee?.lastName ?? ""
                        }`}
                      />
                      <Details
                        detailTitle={"Requester's Manager"}
                        Details={data?._request?.employee?.rO_Name}
                      />
                      <Details
                        detailTitle={"Requester Designation"}
                        Details={data?._request?.employee?.designation}
                      />
                      <Details
                        detailTitle={"Requester Department"}
                        Details={data?._request?.employee?.department}
                      />
                      <Details
                        detailTitle={"Requester Email"}
                        Details={data?._request?.employee?.email}
                      />
                      <Details
                        detailTitle={"Contact Number"}
                        Details={data?._request?.employee?.personalPhoneNumber}
                      />
                      <Details
                        detailTitle={"Location"}
                        Details={data?._request?.employee?.locationName}
                      />
                    </div>

                    {/* {data?.bookinglist?.length > 0 && (
                      <table
                        className="tabless tabless-bordered"
                        style={{ width: "100%" }}
                      >
                        <thead
                          style={{ backgroundColor: "var(--primary-hover)" }}
                        >
                          <tr>
                            <th width="3%">S No.</th>
                            <th width="3%">Option</th>
                            <th width="3%">Attachment</th>
                            <th width="3%">amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.bookinglist?.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item?.remarks}</td>
                                <td>
                                  {item?.attachment?.includes("|") ? (
                                    item?.attachment?.split("|").map((item) => {
                                      return (
                                        <div>
                                          {" "}
                                          <a
                                            href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${item}`}
                                            target="blank"
                                          >
                                            {item}
                                          </a>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <a
                                      href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${item?.attachment}`}
                                      target="blank"
                                    >
                                      {item?.attachment}
                                    </a>
                                  )}
                                </td>
                                <td>{item?.amount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )} */}
                    {console.log(
                      "object data?.actionlist?.length > 0",
                      data?.actionlist?.length
                    )}
                    <div style={{ width: "100%", height: "60px" }}></div>
                    <div
                      className={
                        showAction ||
                        data?.actionlist?.filter(
                          (it) =>
                            it.type === "Approve" ||
                            it?.type === "Reject" ||
                            it?.type === "Release"
                        )?.length > 0
                          ? `createFootDetails`
                          : ""
                      }
                      style={
                        showAction ||
                        data?.actionlist?.filter(
                          (it) =>
                            it.type === "Approve" ||
                            it?.type === "Reject" ||
                            it?.type === "Release"
                        )?.length > 0
                          ? {
                              background: "white !important",
                              bottom: "-13px",
                              margin: "-13px",
                              gap: "13px",
                              // padding: "10px",
                            }
                          : {}
                      }
                    >
                      {showAction ? (
                        <div
                          // className="createFootDetails"
                          style={{
                            display: "flex",
                            gap: "20px",
                            margin: "10px 0px",
                          }}
                        >
                          <div>
                            <div
                              className="button secondaryButton"
                              style={{
                                margin: "0px",
                                fontSize: "13px",
                                background: "#FFFFFF",
                                color: "#F71D1D",
                                boxShadow:
                                  " 0px 4px 10px 0px #0000000A, 0px 1px 10px 0px #0000000D inset",
                              }}
                              onClick={() => {
                                setIsHotelCancel(false);
                                setShowAction(false);
                                setIsCancel(false);
                                setIsReSchedule(false);
                                setIsEditReSchedule(false);
                                valuesSet(resultState);
                              }}
                            >
                              Back to Details
                            </div>
                          </div>
                          <div>
                            <div
                              className="button secondaryButton"
                              style={{
                                margin: "0px",
                                fontSize: "13px",
                                background: "#FB7158",
                                color: "white",
                              }}
                              onClick={() => {
                                if (isCancel || isHotelCancel) {
                                  const val = {
                                    TravelId: data?._request?.id,
                                  };

                                  if (
                                    selectedTravelEmployees?.travelJourney
                                      ?.filter(
                                        (it) => it?.detailIsCancel === true
                                      )[0]
                                      ?.detailArr?.filter(
                                        (item) => item?.isSelected === true
                                      )
                                      ?.map((itz) => itz?.element?.id)?.length >
                                    0
                                  ) {
                                    if (
                                      selectedTravelEmployees?.travelJourney?.filter(
                                        (it) => it?.detailIsCancel === true
                                      )[0]?.detailIsNew
                                    ) {
                                      val["TravelEmployeeIdsNew"] =
                                        selectedTravelEmployees?.travelJourney
                                          ?.filter(
                                            (it) => it?.detailIsCancel === true
                                          )[0]
                                          ?.detailArr?.filter(
                                            (item) => item?.isSelected === true
                                          )
                                          ?.map((itz) => itz?.element?.id);
                                      val["JourneyIdsNew"] =
                                        selectedTravelEmployees?.travelJourney
                                          ?.filter(
                                            (it) => it?.detailIsCancel === true
                                          )[0]
                                          ?.detailArr?.filter(
                                            (item) => item?.isSelected === true
                                          )
                                          ?.map(
                                            (itz) =>
                                              itz?.element
                                                ?.travellingDetailId ??
                                              itz?.element
                                                ?.newTravellingDetailId
                                          );
                                    } else {
                                      val["TravelEmployeeIds"] =
                                        selectedTravelEmployees?.travelJourney
                                          ?.filter(
                                            (it) => it?.detailIsCancel === true
                                          )[0]
                                          ?.detailArr?.filter(
                                            (item) => item?.isSelected === true
                                          )
                                          ?.map((itz) => itz?.element?.id);
                                      val["JourneyIds"] =
                                        selectedTravelEmployees?.travelJourney
                                          ?.filter(
                                            (it) => it?.detailIsCancel === true
                                          )[0]
                                          ?.detailArr?.filter(
                                            (item) => item?.isSelected === true
                                          )
                                          ?.map(
                                            (itz) =>
                                              itz?.element
                                                ?.travellingDetailId ??
                                              itz?.element
                                                ?.newTravellingDetailId
                                          );
                                    }
                                  }
                                  if (
                                    selectedTravelEmployees?.hotelBooked
                                      ?.filter(
                                        (it) => it?.detailIsCancel === true
                                      )[0]
                                      ?.detailArr?.filter(
                                        (item) => item?.isSelected === true
                                      )
                                      ?.map((itz) => itz?.element?.id)?.length >
                                    0
                                  ) {
                                    if (
                                      selectedTravelEmployees?.hotelBooked?.filter(
                                        (it) => it?.detailIsCancel === true
                                      )[0]?.detailIsNew
                                    ) {
                                      val["HotelEmployeeIdsNew"] =
                                        selectedTravelEmployees?.hotelBooked
                                          ?.filter(
                                            (it) => it?.detailIsCancel === true
                                          )[0]
                                          ?.detailArr?.filter(
                                            (item) => item?.isSelected === true
                                          )
                                          ?.map((itz) => itz?.element?.id);
                                      val["BookingIdsNew"] =
                                        selectedTravelEmployees?.hotelBooked
                                          ?.filter(
                                            (it) => it?.detailIsCancel === true
                                          )[0]
                                          ?.detailArr?.filter(
                                            (item) => item?.isSelected === true
                                          )
                                          ?.map(
                                            (itz) =>
                                              itz?.element?.hotelDetailId ??
                                              itz?.element?.hotelDetailNewId
                                          );
                                    } else {
                                      val["HotelEmployeeIds"] =
                                        selectedTravelEmployees?.hotelBooked
                                          ?.filter(
                                            (it) => it?.detailIsCancel === true
                                          )[0]
                                          ?.detailArr?.filter(
                                            (item) => item?.isSelected === true
                                          )
                                          ?.map((itz) => itz?.element?.id);
                                      val["BookingIds"] =
                                        selectedTravelEmployees?.hotelBooked
                                          ?.filter(
                                            (it) => it?.detailIsCancel === true
                                          )[0]
                                          ?.detailArr?.filter(
                                            (item) => item?.isSelected === true
                                          )
                                          ?.map(
                                            (itz) =>
                                              itz?.element?.hotelDetailId ??
                                              itz?.element?.hotelDetailNewId
                                          );
                                    }
                                  }
                                  cancelJourneys(val);
                                } else if (isReSchedule) {
                                  const obj = {
                                    id: data?._request?.id,
                                    budget: data?._request?.budget,
                                  };
                                  if (
                                    selectedTravelEmployees?.hotelBooked?.some(
                                      (it) => it?.detailIsReschedule === true
                                    )
                                  ) {
                                    obj["HotelbookingdetailsNew"] =
                                      selectedTravelEmployees?.hotelBooked
                                        ?.filter(
                                          (it) =>
                                            it?.detailIsReschedule === true
                                        )
                                        ?.map((it) => ({
                                          id: it?.detailForm?.hotelId,
                                          CheckOutTime: new Date(
                                            it?.detailForm?.checkOutDate
                                          ),

                                          CheckInTime: new Date(
                                            it?.detailForm?.checkInDate
                                          ),

                                          Location: it?.detailForm?.location,
                                          PreferHotel:
                                            it?.detailForm?.preferredHotel,
                                          hotelCategory:
                                            data?.travellingdetails[0]
                                              ?.categoryID,
                                          Bookingnote:
                                            it?.detailForm?.bookingNote,
                                          OriginalHotelBookinglId:
                                            it?.detailForm?.originalId,
                                          // TravelHospitalityEmployeeNew:
                                          //   it?.detailArr?.map((it) => it),
                                        }));
                                  }
                                  if (
                                    selectedTravelEmployees?.travelJourney?.some(
                                      (it) => it?.detailIsReschedule === true
                                    )
                                  )
                                    obj["TravelingDetailsNew"] =
                                      selectedTravelEmployees?.travelJourney
                                        ?.filter(
                                          (it) =>
                                            it?.detailIsReschedule === true
                                        )
                                        ?.map((it) => {
                                          if (it?.detailForm?.isToDate) {
                                            return {
                                              id: it?.detailForm?.travelId,
                                              ToDate:
                                                format(
                                                  new Date(
                                                    it?.detailForm?.travellingDate
                                                  ),
                                                  "yyyy-MM-dd"
                                                ) + "T00:00:00.000",
                                              PickupLocation:
                                                it?.detailForm?.fromLocation,
                                              DropLocation:
                                                it?.detailForm?.toLocation,
                                              CategoryID:
                                                data?.travellingdetails[0]
                                                  ?.categoryID,
                                              Bookingnote:
                                                it?.detailForm?.bookingNote,
                                              OriginalTravelDetailId:
                                                it?.detailForm?.originalId,
                                            };
                                          } else {
                                            return {
                                              id: it?.detailForm?.travelId,
                                              FromDate:
                                                format(
                                                  new Date(
                                                    it?.detailForm?.travellingDate
                                                  ),
                                                  "yyyy-MM-dd"
                                                ) + "T00:00:00.000",
                                              PickupLocation:
                                                it?.detailForm?.fromLocation,
                                              DropLocation:
                                                it?.detailForm?.toLocation,
                                              CategoryID:
                                                data?.travellingdetails[0]
                                                  ?.categoryID,
                                              Bookingnote:
                                                it?.detailForm?.bookingNote,
                                              OriginalTravelDetailId:
                                                it?.detailForm?.originalId,
                                            };
                                          }

                                          // TravelHospitalityEmployeeNew:
                                          //   it?.detailArr?.map((it) => it),
                                        });
                                  editJourneys(obj);
                                } else if (isEditReSchedule) {
                                  const obj = {
                                    id: data?._request?.id,
                                    budget: data?._request?.budget,
                                  };
                                  if (
                                    selectedTravelEmployees?.hotelBooked?.some(
                                      (it) => it?.detailIsReschedule === true
                                    )
                                  ) {
                                    obj["HotelbookingdetailsNew"] =
                                      selectedTravelEmployees?.hotelBooked
                                        ?.filter(
                                          (it) =>
                                            it?.detailIsReschedule === true
                                        )
                                        ?.map((it) => ({
                                          id: it?.detailForm?.hotelId,
                                          CheckOutTime: new Date(
                                            it?.detailForm?.checkOutDate
                                          ),

                                          CheckInTime: new Date(
                                            it?.detailForm?.checkInDate
                                          ),

                                          Location: it?.detailForm?.location,
                                          PreferHotel:
                                            it?.detailForm?.preferredHotel,
                                          hotelCategory:
                                            data?.travellingdetails[0]
                                              ?.categoryID,
                                          Bookingnote:
                                            it?.detailForm?.bookingNote,
                                          OriginalHotelBookinglId:
                                            it?.detailForm?.originalId, // TravelHospitalityEmployeeNew:
                                          //   it?.detailArr?.map((it) => it),
                                        }));
                                  }
                                  if (
                                    selectedTravelEmployees?.travelJourney?.some(
                                      (it) => it?.detailIsReschedule === true
                                    )
                                  )
                                    obj["TravelingDetailsNew"] =
                                      selectedTravelEmployees?.travelJourney
                                        ?.filter(
                                          (it) =>
                                            it?.detailIsReschedule === true
                                        )
                                        ?.map((it) => {
                                          if (it?.detailForm?.isToDate) {
                                            return {
                                              id: it?.detailForm?.travelId,
                                              ToDate:
                                                format(
                                                  new Date(
                                                    it?.detailForm?.travellingDate
                                                  ),
                                                  "yyyy-MM-dd"
                                                ) + "T00:00:00.000",
                                              PickupLocation:
                                                it?.detailForm?.fromLocation,
                                              DropLocation:
                                                it?.detailForm?.toLocation,
                                              CategoryID:
                                                data?.travellingdetails[0]
                                                  ?.categoryID,
                                              Bookingnote:
                                                it?.detailForm?.bookingNote,
                                              OriginalTravelDetailId:
                                                it?.detailForm?.originalId,
                                            };
                                          } else {
                                            return {
                                              id: it?.detailForm?.travelId,
                                              FromDate:
                                                format(
                                                  new Date(
                                                    it?.detailForm?.travellingDate
                                                  ),
                                                  "yyyy-MM-dd"
                                                ) + "T00:00:00.000",
                                              PickupLocation:
                                                it?.detailForm?.fromLocation,
                                              DropLocation:
                                                it?.detailForm?.toLocation,
                                              CategoryID:
                                                data?.travellingdetails[0]
                                                  ?.categoryID,
                                              Bookingnote:
                                                it?.detailForm?.bookingNote,
                                              OriginalTravelDetailId:
                                                it?.detailForm?.originalId,
                                            };
                                          }

                                          // TravelHospitalityEmployeeNew:
                                          //   it?.detailArr?.map((it) => it),
                                        });
                                  editResceduleJourneys(obj);
                                }
                              }}
                            >
                              {isCancel
                                ? "Cancel Journey"
                                : isHotelCancel
                                ? " Cancel Hotel Reservation"
                                : isReSchedule
                                ? "ReSchedule"
                                : isEditReSchedule
                                ? "Update ReSchedule"
                                : "cancel"}
                            </div>
                          </div>
                        </div>
                      ) : (
                        data?.actionlist?.length > 0 && (
                          <div
                            // className="createFootDetails"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              backgroundColor: "white",
                            }}
                          >
                            {data?.actionlist?.length > 0
                              ? data?.actionlist.map((item) => {
                                  if (
                                    item.type === "Approve" ||
                                    item?.type === "Reject" ||
                                    item?.type === "Release"
                                  )
                                    return (
                                      <div>
                                        <div
                                          className="button secondaryButton"
                                          onClick={() =>
                                            clickHandlerFunction(
                                              item?.id,
                                              item?.type,
                                              item?.color
                                            )
                                          }
                                          style={{
                                            background: item?.color,
                                            color: "white",
                                          }}
                                        >
                                          {item?.type}
                                        </div>
                                      </div>
                                    );
                                  else return <></>;
                                })
                              : null}
                          </div>
                        )
                      )}
                    </div>
                    {isOpen && typeAction === "Add Journey" && (
                      <Popup
                        popupHeading={"Add Journey"}
                        content={
                          <>
                            <div>
                              <TravelBookingAddJourney
                                detailsData={data}
                                togglePopup={togglePopup}
                                getDetails={getDetails}
                              />
                            </div>
                          </>
                        }
                        handleClose={togglePopup}
                      />
                    )}
                    {isOpen && typeAction === "Add Hotel Booking" && (
                      <Popup
                        popupHeading={"Add Hotel Booking"}
                        content={
                          <>
                            <div>
                              <HotelBookingAddJourney
                                detailsData={data}
                                togglePopup={togglePopup}
                                getDetails={getDetails}
                                isBoth={data?._request?.isLinkedTravelHotel}
                              />
                            </div>
                          </>
                        }
                        handleClose={togglePopup}
                      />
                    )}
                    {isActionOpen && (
                      <Popup
                        popupHeading={typeActionAction}
                        content={
                          <>
                            {typeActionAction === "Upload Ticket Options" ? (
                              <UploadTicketOptions
                                id={data?._request?.id}
                                selActionId={selActionId}
                                handleClose={toggleActionPopup}
                                getListData={getDetails}
                              />
                            ) : typeActionAction === "Submit Booking" ? (
                              <SubmitBooking
                                id={data?._request?.id}
                                selActionId={selActionId}
                                handleClose={toggleActionPopup}
                                getListData={getDetails}
                              />
                            ) : typeActionAction === "Upload Tickets" ? (
                              <UploadTicket
                                id={data?._request?.id}
                                selActionId={selActionId}
                                handleClose={toggleActionPopup}
                                getListData={getDetails}
                              />
                            ) : typeActionAction ===
                              "Cancel Tickets & Upload Ticket Options" ? (
                              <CancelUploadTickets
                                id={data?._request?.id}
                                selActionId={selActionId}
                                handleClose={toggleActionPopup}
                                getListData={getDetails}
                              />
                            ) : (
                              <Remark
                                data={PostApRejHol}
                                type={typeActionAction}
                                id={data?._request?.id}
                                color={colorAction}
                                handleClose={toggleActionPopup}
                              />
                            )}
                          </>
                        }
                        handleClose={toggleActionPopup}
                      />
                    )}
                  </div>
                )}
              </>
            )}
            {tabIndex === 1 && (
              <>
                <div className="tabularMasterDetailsPageTabBodyContainer">
                  <div style={{ display: "flex", width: "100%" }}>
                    <TimeLineData
                      id={getIdFromDynamicUrl(currentUrl)}
                      url="TravelHospitalityRequests"
                      requestNumber={data?._request?.requestNumber}
                      module={"Travel and Hospitality Requests"}
                    />
                  </div>
                </div>
              </>
            )}

            {tabIndex === 2 && (
              <>
                <div
                  className="tabularMasterDetailsPageTabBodyContainer"
                  style={{ flexGrow: "initial" }}
                >
                  <Notifications
                    id={getIdFromDynamicUrl(currentUrl)}
                    module={"Travel and Hospitality Requests"}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export { TravelBookingDetails };

import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import { DetailsStep } from "../../../../Components/FormComponent/DetailsStep";
import UploadImageDetail from "../../../../Components/UploadDetail/UploadImageDetail";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { Padding } from "@mui/icons-material";

const DetailsPageNoticePayroll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(true);
  const [bannerData, setBannerData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PayrollNoticeBoard/Details?Id=${selectedId}`);
      setBannerData(result.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => { };
  }, []);

  // //console.log("eventtData",bannerData)
  useEffect(() => {
    document.title = `PeopleSol - Details Notice`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            {/* <div className="moduleMasterDetailsBox"> */}
            <DetailsStep style={{ margin: 0, padding: 0 }} detailTitle={"Name"} Details={bannerData?.title} />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Description"}
              Details={bannerData?.description}
            />
            <DetailsStep style={{ margin: 0, padding: 0 }} detailTitle={"Module"} Details={bannerData?.module} />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Request ID"}
              Details={bannerData?.requestId}
            />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Button Text"}
              Details={bannerData?.buttonText}
            />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Footer Text"}
              Details={bannerData?.footerText}
            />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Redirect Url"}
              Details={
                <a href={bannerData?.redirectUrl} target="blank">
                  {bannerData?.redirectUrl}
                </a>
              }
            />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Uploaded Image"}
              Details={
                <UploadImageDetail filepath={`banners/${bannerData?.image}`} />
              }
            />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Is Active?"}
              Details={bannerData?.isActive === true ? "Yes" : "No"}
            />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Is Display in Mobile?"}
              Details={bannerData?.isDisplayInMobile === true ? "Yes" : "No"}
            />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Created On"}
              Details={format(
                new Date(bannerData?.createdOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />
            <DetailsStep style={{ margin: 0, padding: 0 }}
              detailTitle={"Last Updated On"}
              Details={format(
                new Date(bannerData?.lastUpdatedOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />

            {/* </div> */}
          </div>)}
      </div>
    </div>
  );
};

export { DetailsPageNoticePayroll };

import React, { useEffect } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { InputCustom } from "../../../../../Components/FormComponent/InputCustom";

const Typeoption = [
  // { label: "Employee Loan", value: "Employee Loan" },
  // { label: "Term Advance", value: "Term Advance" },
  { label: "Salary Advance", value: "Salary Advance" },
];
const type = [
  // { label: "Term Advance", value: "Term Advance" },
  { label: "Salary Advance", value: "Salary Advance" },
];
export const CreateLoanAdvanceComp = ({ formValues }) => {
  // //console.log(
  //   "formValues",
  //   formValues?.totalRecoveryMonth !== "" && formValues?.amount !== ""
  // );
  const diff = () => {
    return (
      formValues?.amount / Number(formValues?.totalRecoveryMonth)
    ).toFixed(2);
  };

  useEffect(() => {
    document.title = `PeopleSol -Create Loan Advance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
        alignItems: "start",
      }}
    >
      <div style={{ flex: 5 }}>
        <div>
          <LabelCustom labelName={"Loan Or Advance Type "} mandatory={true} />
          <SelectForm
            name={"type"}
            options={formValues?.time >= 6 ? type : Typeoption}
            placeholder={" Select Loan Or Advance Type"}
          />
        </div>

        <div className="formcustom">
          <div>
            <LabelCustom
              labelName={"Loan Or Advance Amount"}
              mandatory={true}
            />
            <InputCustomFormik
              maxLength={9}
              name={"amount"}
              type="number"
              step="0.01"
              placeholder={"Enter Loan Or Advance Amount "}
            />
          </div>
          <div>
            <LabelCustom labelName={"Eligible Amount"} />
            <InputCustom
              disabled
              // maxLength={9}
              // name={"amount"}
              // type="number"
              // step="0.01"
              // placeholder={"Enter Loan Or Advance Amount "}
              value={""}
            />
          </div>
        </div>
        <div>
          <LabelCustom labelName={"Loan Or Advance Reason "} mandatory={true} />
          <TextAreaFormik
            maxlength="500"
            style={{ height: "100px" }}
            name="reason"
            placeholder={"Enter Loan Or Advance Reason"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Required by Date "} mandatory={true} />
          <DatePickerFormiks
            minDate={new Date()}
            style={{ height: "100px" }}
            name="requiredBydate"
            placeholder={"Select Date "}
          />
        </div>
        <div className="formcustom">
          <div>
            <LabelCustom labelName={"Total Recovery Month "} mandatory={true} />
            <InputCustomFormik
              maxLength={1}
              type="number"
              step="1"
              name={"totalRecoveryMonth"}
              placeholder={"Enter No. of Month "}
            />
          </div>
          <div>
            <LabelCustom labelName={"Eligible Month"} />
            <InputCustom
              // maxLength={1}
              // type="number"
              // step="1"
              // name={"totalRecoveryMonth"}
              // placeholder={"Enter No. of Month "}
              disabled
              value={"6"}
            />
          </div>
        </div>
        <div>
          <LabelCustom labelName={"Per Month Deduction "} />
          <InputCustom
            disabled
            value={
              formValues?.totalRecoveryMonth !== "" && formValues?.amount !== ""
                ? (
                    formValues?.amount / Number(formValues?.totalRecoveryMonth)
                  ).toFixed(2)
                : ""
            }
            name={"perMonthDeduction"}
            type="number"
            step="0.01"
            placeholder={"Enter Per Month Deduction "}
          />
        </div>
      </div>
      <div
        style={{
          flex: 5,
          marginTop: "50px",
          background: "var(--primary-hover)",
          padding: "24px",
          lineHeight: "36px",
        }}
      >
        <h4>Loan Policy</h4>
        <div>
          <ul>
            <li>
              This policy is applicable only for confirmed employees, who have
              worked for at least 1 year in the company
              {/* <strong>Salary Advance</strong> */}
            </li>
            <li>
              There should not be any advance/loan outstanding against the
              individual’s name at the time of the application of advance/loan
            </li>
            <li>
              The advance can be given once in a year, with a maximum amount
              equal to one-month CTC. Entitlement of Advance/Loan can be
              increased with the approval from the Directors.
            </li>
            <li>
              The entitled amount should be paid back in a maximum of 6
              instalments (may change- depends on the special approval), starts
              with the first month of advance disbursement.
            </li>
            <li>
              In case of separation of the individual, the company will reserve
              the right to withhold the individual’s final settlement until all
              the dues were paid.
            </li>
          </ul>
          <ul>
            <li>
              <strong>NOTE:</strong>
              <br /> Advance will be admissible to the employee as per his
              entitlement only in exceptional & genuine cases as Mentioned
              Below:
            </li>
            <li>1. In case of major illness or accident.</li>
            <li>
              2. In case of family member treatment in emergency cases
              (Parents/guardian/spouse etc.)
            </li>
            <li>3. In case of natural disaster (flood, cyclone, earthquake)</li>
            <li>4. Unplanned emergencies if any</li>
            <li>
              5. In case of daughter/son’s marriage. (Applicable to the
              employees getting salary less than 1 lakh per month)
            </li>
            <li>6. Special cases, if any (approved by Corp. HR/Directors)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

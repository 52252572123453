import * as Yup from "yup";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getRequest, postRequest } from "../../../../Services/axios";
import { isLoading, showToast } from "../../../../Features";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { Form, Formik } from "formik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import ImageRepeater from "../../../PMSMaster/Components/ImageRepeater";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { format } from "date-fns";

const ReviewAndApprovedPerformanceSlab = ({
  id,
  PostApRejHol,
  type,
  color,
  handleClose,
  DepartmentId,
  criteriaId,
  getAllListData,
  selListId,
  finalConfirm = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [formValues, setFormValues] = useState(false);
  const [initialValues, setinitialValues] = useState({
    criteria: [
      {
        appraisalSlab: [
          {
            name: "",
            rating: "",
            fromWeightageRange: "",
            toWeightageRange: "",
          },
        ],
      },
    ],
    Comments: "",
  });
  console.log("object formValues", formValues);
  console.log("object initialValues", initialValues);

  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let result;
      if (DepartmentId) {
        result = await getRequest(
          `pmsPerformanceRatingSlab/Details?ID=${criteriaId}&DepartmentId=${DepartmentId}`
        );
      } else {
        result = await getRequest(
          `pmsPerformanceRatingSlab/Details?ID=${criteriaId}`
        );
      }
      setData(result.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data)
      setinitialValues(() => {
        // id: data?.id,
        const newCriteria = data?.pmsAppraisalCriteria?.pmsPerformanceRatingItem
          ?.map((criteriaItem, index) => {
            if (DepartmentId) {
              return criteriaItem?.pmsPerformanceRatingDepartment
                ?.filter((it) => it?.departmentId === DepartmentId)
                ?.map((appcriteria) => {
                  return {
                    departmentId: appcriteria?.departmentId,
                    id: appcriteria?.id,
                    appraisalSlab: criteriaItem?.pmsPerformanceRatingSlab
                      ?.filter(
                        (item) =>
                          item?.reviewby ===
                            appcriteria?.reviewedBy[
                              appcriteria?.reviewedBy?.length - 1
                            ] &&
                          (item?.reviewbyDepartmentId ===
                            appcriteria?.departmentId ||
                            item?.reviewbyDepartmentId === null)
                      )
                      ?.sort((a, b) => b?.rating - a?.rating)
                      ?.map((item) => ({
                        name: item?.name,
                        rating: item?.rating,
                        fromWeightageRange: item?.weightFrom,
                        toWeightageRange: item?.weightTo,
                      })),
                  };
                });
            } else
              return criteriaItem?.pmsPerformanceRatingDepartment?.map(
                (appcriteria) => {
                  return {
                    departmentId: appcriteria?.departmentId,
                    id: appcriteria?.id,
                    appraisalSlab: criteriaItem?.pmsPerformanceRatingSlab
                      ?.filter(
                        (item) =>
                          item?.reviewby ===
                            appcriteria?.reviewedBy[
                              appcriteria?.reviewedBy?.length - 1
                            ] &&
                          (item?.reviewbyDepartmentId ===
                            appcriteria?.departmentId ||
                            item?.reviewbyDepartmentId === null)
                      )
                      ?.sort((a, b) => b?.rating - a?.rating)
                      ?.map((item) => ({
                        name: item?.name,
                        rating: item?.rating,
                        fromWeightageRange: item?.weightFrom,
                        toWeightageRange: item?.weightTo,
                      })),
                  };
                }
              );
          })
          ?.flat();
        return { criteria: newCriteria, Comments: "" };
      });
  }, [data]);

  useEffect(() => {
    document.title = `PeopleSol`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    getDetails();
  }, []);
  const isAllEmpty = () => {
    // const variable1 = formValues?.criteria?.every((it) =>
    //   it?.appraisalClassification?.every((item) => item?.hodReview === "")
    // );
    const variable2 = formValues?.criteria?.every((it) =>
      it?.appraisalSlab?.every(
        (item) =>
          item?.fromWeightageRange === "" && item?.toWeightageRange === ""
      )
    );
    // console.log("object variable1", variable1);
    console.log("object variable2", variable2);

    // formValues?.appraisalSlab?.every(
    //   (item) =>
    //     item?.fromWeightageRange === "" && item?.toWeightageRange === ""
    // );
    // const variable3 = formValues?.Comments === "";

    return variable2;
  };
  const isAllFilled = () => {
    const variable2 = formValues?.criteria?.every((it) =>
      it?.appraisalSlab?.every(
        (item) =>
          item?.fromWeightageRange !== "" && item?.toWeightageRange !== ""
      )
    );
    // const variable3 = formValues?.Comments !== "";

    return variable2;
  };
  const getValidationSchema = () => {
    // if (isAnthingFilled()) {
    return Yup.object({
      appraisalSlab: Yup.array().of(
        Yup.object().shape({
          fromWeightageRange: Yup.string().required("Enter"),
          toWeightageRange: Yup.string().required("Enter"),
        })
      ),

      // Comments: Yup.string().required("Enter Comments"),
    });
    // } else return Yup.object();
  };
  const createReviewApproves = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `pmsPerformanceRatingSlab/ReviewApproved`,
        values
      );
      if (result?.data?.isSuccess) {
        dispatch(
          showToast({
            text: result?.data?.message ?? "Successfully Updated",
            severity: "success",
          })
        );
        handleClose();
        getAllListData();
      } else {
        dispatch(
          showToast({
            text: result?.data?.message ?? "some error occured",
            severity: "error",
          })
        );
        handleClose();
        getAllListData();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const createFinalConfirm = async (values) => {
    try {
      console.log("object values", values);
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `pmsPerformanceRatingSlab/FinalConform`,
        values
      );
      if (result?.data?.isSuccess) {
        dispatch(
          showToast({
            text: result?.data?.message ?? "Successfully Updated",
            severity: "success",
          })
        );
        handleClose();
        getAllListData();
      } else {
        dispatch(
          showToast({
            text: result?.data?.message ?? "some error occured",
            severity: "error",
          })
        );
        handleClose();
        getAllListData();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const createGenralApprove = async (values) => {
    try {
      console.log("object values", values);
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `pmsPerformanceRatingSlab/GeneralsReviewApproved`,
        values
      );
      if (result?.data?.isSuccess) {
        dispatch(
          showToast({
            text: result?.data?.message ?? "Successfully Updated",
            severity: "success",
          })
        );
        handleClose();
        getAllListData();
      } else {
        dispatch(
          showToast({
            text: result?.data?.message ?? "some error occured",
            severity: "error",
          })
        );
        handleClose();
        getAllListData();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const onSubmit = (values) => {
    if (type === "Genral Approve & Review") {
      // if (isAllEmpty()) {
      //   const payload = {
      //     id: id,
      //   };
      //   payload["RequestId"] = id;
      //   payload["Name"] = data?.pmsAppraisalCriteria?.name;
      //   if (values?.Comments !== "") payload["Comments"] = values?.Comments;
      //   createGenralApprove(payload);
      // } else
      if (!isAllFilled()) {
        dispatch(
          showToast({
            text: "Please fill the rest",
            severity: "error",
          })
        );
      } else if (isAllFilled()) {
        const payload = {
          CriteriaId: criteriaId,
        };
        payload["GenralRequestId"] = id;
        // payload["Name"] = data?.pmsAppraisalCriteria?.name;
        if (values?.Comments !== "") payload["HrComments"] = values?.Comments;

        const Items = values?.criteria?.map((criteriaItem, index) => {
          return {
            id: criteriaItem?.id,
            DepartmentId: criteriaItem?.departmentId,
            SlabModel: criteriaItem?.appraisalSlab?.map(
              (departmentItem, ind) => {
                return {
                  Name: departmentItem?.name,
                  Rating: departmentItem?.rating,
                  WeightFrom: departmentItem?.fromWeightageRange,
                  WeightTo: departmentItem?.toWeightageRange,
                };
              }
            ),
          };
        });
        payload["DepartmentReview"] = Items;

        createGenralApprove(payload);
      }
    } else if (finalConfirm) {
      // if (isAllEmpty()) {
      //   const payload = {
      //     id: criteriaId,
      //   };
      //   payload["Name"] = data?.pmsAppraisalCriteria?.name;
      //   if (values?.Comments !== "") payload["Comments"] = values?.Comments;

      //   createFinalConfirm(payload);
      // } else
      if (!isAllFilled()) {
        dispatch(
          showToast({
            text: "Please fill the rest",
            severity: "error",
          })
        );
      } else if (isAllFilled()) {
        const payload = {
          CriteriaId: criteriaId,
        };
        // payload["Name"] = data?.pmsAppraisalCriteria?.name;
        if (values?.Comments !== "") payload["HrComments"] = values?.Comments;

        const Items = values?.criteria?.map((criteriaItem, index) => {
          return {
            id: criteriaItem?.id,
            DepartmentId: criteriaItem?.departmentId,
            SlabModel: criteriaItem?.appraisalSlab?.map(
              (departmentItem, ind) => {
                return {
                  Name: departmentItem?.name,
                  Rating: departmentItem?.rating,
                  WeightFrom: departmentItem?.fromWeightageRange,
                  WeightTo: departmentItem?.toWeightageRange,
                };
              }
            ),
          };
        });
        payload["DepartmentReview"] = Items;

        createFinalConfirm(payload);
      }
    } else {
      // if (isAllEmpty()) {
      //   const payload = {
      //     id: selListId,
      //   };
      //   if (values?.Comments !== "") payload["Comments"] = values?.Comments;

      //   createReviewApproves(payload);
      // } else
      if (!isAllFilled()) {
        dispatch(
          showToast({
            text: "Please fill the rest",
            severity: "error",
          })
        );
      } else if (isAllFilled()) {
        const payload = {
          id: selListId,
        };
        if (values?.Comments !== "") payload["Comments"] = values?.Comments;

        const slabModelObj = values?.criteria[0]?.appraisalSlab
          ?.sort((a, b) => b.rating - a.rating)
          ?.map((item, ind) => ({
            Name: item?.name,
            Rating: item?.rating,
            WeightFrom: item?.fromWeightageRange,
            WeightTo: item?.toWeightageRange,
          }));

        payload["slabModel"] = slabModelObj;

        createReviewApproves(payload);
      }
    }
  };

  useEffect(() => {
    const scrollToRight = () => {
      data?.pmsAppraisalCriteria?.pmsPerformanceRatingItem?.forEach(
        (item, index) => {
          item?.pmsPerformanceRatingDepartment?.forEach((it, ind) => {
            const element = document.getElementById(`div-${it?.id}`);
            if (element && element.scrollWidth) {
              // element.scrollLeft = element.scrollWidth;
              element.scrollTo({
                left: element.scrollWidth,
                behavior: "smooth",
              });
            }
          });
        }
      );
    };

    if (data) {
      setTimeout(scrollToRight, 300);
    }
  }, [data]);
  let itemCommon = 0;
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            // validationSchema={getValidationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(formik) => {
              setFormValues(formik.values);
              itemCommon = 0;
              return (
                <Form>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div>
                      {data?.pmsAppraisalCriteria?.pmsPerformanceRatingItem?.map(
                        (criteriaItem, index) => {
                          if (DepartmentId) {
                            return criteriaItem?.pmsPerformanceRatingDepartment
                              ?.filter(
                                (it) => it?.departmentId === DepartmentId
                              )
                              ?.map((departmentItem, ind) => {
                                itemCommon++;
                                return (
                                  <div className="DepartmentDesign">
                                    <div
                                      className="detailInner"
                                      style={{ padding: "20px" }}
                                    >
                                      <Details
                                        detailTitle={"Sl.No."}
                                        Details={itemCommon}
                                      />
                                      <Details
                                        detailTitle={"Department"}
                                        Details={departmentItem?.departmentName}
                                      />

                                      <Details
                                        detailTitle={"Status"}
                                        Details={
                                          <div
                                            style={{
                                              backgroundColor: getStatusColor(
                                                departmentItem?.departmentStatus
                                              )?.split(",")?.[0],
                                              color: getStatusColor(
                                                departmentItem?.departmentStatus
                                              )?.split(",")?.[1],
                                              cursor: "default",
                                              padding: "6px",
                                              borderRadius: "6px",
                                            }}
                                          >
                                            {departmentItem?.departmentStatus}
                                          </div>
                                        }
                                      />
                                      <Details
                                        detailTitle={"Created On"}
                                        Details={
                                          departmentItem?.createdOn
                                            ? format(
                                                new Date(
                                                  departmentItem?.createdOn?.replace(
                                                    "T",
                                                    " "
                                                  )
                                                ),
                                                "dd/MM/yyyy hh:mm a"
                                              )
                                            : "-"
                                        }
                                      />
                                      <Details
                                        detailTitle={"Last Updated On"}
                                        Details={
                                          departmentItem?.lastUpdatedOn
                                            ? format(
                                                new Date(
                                                  departmentItem?.lastUpdatedOn?.replace(
                                                    "T",
                                                    " "
                                                  )
                                                ),
                                                "dd/MM/yyyy hh:mm a"
                                              )
                                            : "-"
                                        }
                                      />
                                    </div>
                                    <div style={{ padding: "21px" }}>
                                      <div
                                        className="headingForm"
                                        style={{
                                          borderBottom: "none",
                                          margin: "12px 12px 6px 0px",
                                        }}
                                      >
                                        Configure Rating Slabs
                                      </div>
                                      <div
                                        key={departmentItem?.id}
                                        id={`div-${departmentItem?.id}`}
                                        // ref={(el) => {
                                        //   console.log("object el", el);
                                        //   divRefs.current[index] = el;
                                        // }}
                                        style={{
                                          width: "100%",
                                          // width: "588px",
                                          overflowX: "auto",
                                          overflowY: "hidden",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        <div className="table-wrapper PmsDetailsTable">
                                          <table
                                            style={{ width: "max-content" }}
                                          >
                                            <thead>
                                              <tr>
                                                <td
                                                  rowspan="2"
                                                  className="sticky"
                                                  style={{
                                                    width: "90px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Sl.No.
                                                </td>
                                                <td
                                                  colspan="2"
                                                  className="sticky2 "
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Rating
                                                </td>
                                                <td
                                                  rowspan="2"
                                                  className="sticky4"
                                                  style={{
                                                    width: "130px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Name
                                                </td>
                                                <td
                                                  colspan="2"
                                                  className="normalCell "
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Weightage Range
                                                </td>
                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => {
                                                    if (item === "Creator") {
                                                      return null;
                                                    } else {
                                                      return (
                                                        <td
                                                          className="normalCell "
                                                          colSpan="2"
                                                          style={{
                                                            fontSize: "12px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {item === "Creator"
                                                            ? "Weightage (%)"
                                                            : item + " Review"}
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <td
                                                      className="normalCell "
                                                      colSpan="2"
                                                      style={{
                                                        fontSize: "12px",
                                                        fontWeight: "500",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {data?.nextReviewer +
                                                        " Review"}
                                                    </td>
                                                  )}
                                              </tr>
                                              <tr>
                                                <td
                                                  className="sticky2"
                                                  style={{
                                                    width: "133px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Value
                                                </td>
                                                <td
                                                  rowspan="2"
                                                  className="sticky3"
                                                  style={{
                                                    width: "144px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Star
                                                </td>
                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => (
                                                    <>
                                                      <td
                                                        className="normalCell "
                                                        style={{
                                                          width: "133px",
                                                          fontSize: "12px",
                                                          fontWeight: "500",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        From
                                                      </td>
                                                      <td
                                                        rowspan="2"
                                                        className="normalCell "
                                                        style={{
                                                          width: "144px",
                                                          fontSize: "12px",
                                                          fontWeight: "500",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        To
                                                      </td>
                                                    </>
                                                  )
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <>
                                                      <td
                                                        className="normalCell "
                                                        style={{
                                                          width: "133px",
                                                          fontSize: "12px",
                                                          fontWeight: "500",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        From
                                                      </td>
                                                      <td
                                                        rowspan="2"
                                                        className="normalCell "
                                                        style={{
                                                          width: "144px",
                                                          fontSize: "12px",
                                                          fontWeight: "500",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        To
                                                      </td>
                                                    </>
                                                  )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {criteriaItem?.pmsPerformanceRatingSlab
                                                ?.filter(
                                                  (it) =>
                                                    it?.reviewby ===
                                                      "Creator" &&
                                                    it?.reviewbyDepartmentId ===
                                                      null
                                                )
                                                ?.sort(
                                                  (a, b) => b.rating - a.rating
                                                )
                                                ?.map((item, indexW) => {
                                                  console.log(
                                                    "object itemCommon",
                                                    itemCommon
                                                  );
                                                  return (
                                                    <tr>
                                                      <td
                                                        className="sticky"
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {indexW + 1}
                                                      </td>
                                                      <td
                                                        className="sticky2"
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {item?.rating}
                                                      </td>
                                                      <td className="sticky3">
                                                        {item?.rating >= 0 &&
                                                          item?.rating <= 5 &&
                                                          item?.rating % 0.5 ===
                                                            0 && (
                                                            <ImageRepeater
                                                              number={item?.rating?.toFixed(
                                                                1
                                                              )}
                                                              total={5}
                                                              imageUrl={
                                                                <svg
                                                                  width="18"
                                                                  height="17"
                                                                  viewBox="0 0 18 17"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                                                                    fill="#F7941D"
                                                                  />
                                                                </svg>
                                                              }
                                                              imageUrlSecond={
                                                                <svg
                                                                  width="18"
                                                                  height="18"
                                                                  viewBox="0 0 18 18"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                                                                    fill="#E6E6E6"
                                                                  />
                                                                  <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M8.99994 0L6.97931 6.21885H0.44043L5.7305 10.0623L3.70987 16.2812L8.99994 12.4377V0ZM14.1987 16H13.903L14.29 16.2812L14.1987 16Z"
                                                                    fill="#F7941D"
                                                                  />
                                                                </svg>
                                                              }
                                                              imgBlank={
                                                                <svg
                                                                  width="18"
                                                                  height="17"
                                                                  viewBox="0 0 18 17"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                                                                    fill="#E6E6E6"
                                                                  />
                                                                </svg>
                                                              }
                                                            />
                                                          )}
                                                      </td>
                                                      <td
                                                        className="sticky4"
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {item?.name}
                                                      </td>
                                                      <td
                                                        className="normalCell "
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {criteriaItem?.pmsPerformanceRatingSlab
                                                          ?.filter(
                                                            (itz) =>
                                                              itz?.reviewby ===
                                                                "Creator" &&
                                                              itz?.reviewbyDepartmentId ===
                                                                null
                                                          )
                                                          ?.sort(
                                                            (a, b) =>
                                                              b.rating -
                                                              a.rating
                                                          )[indexW]
                                                          ?.weightFrom + "%"}
                                                      </td>
                                                      <td
                                                        className="normalCell "
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {criteriaItem?.pmsPerformanceRatingSlab
                                                          ?.filter(
                                                            (itz) =>
                                                              itz?.reviewby ===
                                                                "Creator" &&
                                                              itz?.reviewbyDepartmentId ===
                                                                null
                                                          )
                                                          ?.sort(
                                                            (a, b) =>
                                                              b.rating -
                                                              a.rating
                                                          )[indexW]?.weightTo +
                                                          "%"}
                                                      </td>
                                                      {departmentItem?.reviewedBy?.map(
                                                        (it) => {
                                                          if (
                                                            it === "Creator"
                                                          ) {
                                                            return null;
                                                          } else {
                                                            return (
                                                              <>
                                                                <td
                                                                  className="normalCell "
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  {criteriaItem?.pmsPerformanceRatingSlab
                                                                    ?.filter(
                                                                      (itz) =>
                                                                        itz?.reviewby ===
                                                                          it &&
                                                                        (itz?.reviewbyDepartmentId ===
                                                                          departmentItem?.departmentId ||
                                                                          itz?.reviewbyDepartmentId ===
                                                                            null)
                                                                    )
                                                                    ?.sort(
                                                                      (a, b) =>
                                                                        b.rating -
                                                                        a.rating
                                                                    )[indexW]
                                                                    ?.weightFrom +
                                                                    "%"}
                                                                </td>
                                                                <td
                                                                  className="normalCell "
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  {criteriaItem?.pmsPerformanceRatingSlab
                                                                    ?.filter(
                                                                      (itz) =>
                                                                        itz?.reviewby ===
                                                                          it &&
                                                                        (itz?.reviewbyDepartmentId ===
                                                                          departmentItem?.departmentId ||
                                                                          itz?.reviewbyDepartmentId ===
                                                                            null)
                                                                    )
                                                                    ?.sort(
                                                                      (a, b) =>
                                                                        b.rating -
                                                                        a.rating
                                                                    )[indexW]
                                                                    ?.weightTo +
                                                                    "%"}
                                                                </td>
                                                              </>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                      {data?.nextReviewer &&
                                                        data?.nextReviewer
                                                          ?.length > 0 && (
                                                          <>
                                                            <td
                                                              className="normalCell "
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              <InputCustomFormik
                                                                style={{
                                                                  border:
                                                                    "0.75px solid #BFBFBF",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                                name={`criteria[${
                                                                  itemCommon - 1
                                                                }].appraisalSlab[${indexW}].fromWeightageRange`}
                                                                type="number"
                                                              />
                                                            </td>
                                                            <td
                                                              className="normalCell "
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              <InputCustomFormik
                                                                style={{
                                                                  border:
                                                                    "0.75px solid #BFBFBF",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                                name={`criteria[${
                                                                  itemCommon - 1
                                                                }].appraisalSlab[${indexW}].toWeightageRange`}
                                                                type="number"
                                                              />
                                                            </td>
                                                          </>
                                                        )}
                                                    </tr>
                                                  );
                                                })}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              });
                          } else
                            return criteriaItem?.pmsPerformanceRatingDepartment?.map(
                              (departmentItem, ind) => {
                                itemCommon++;
                                return (
                                  <div className="DepartmentDesign">
                                    <div
                                      className="detailInner"
                                      style={{ padding: "20px" }}
                                    >
                                      <Details
                                        detailTitle={"Sl.No."}
                                        Details={itemCommon}
                                      />
                                      <Details
                                        detailTitle={"Department"}
                                        Details={departmentItem?.departmentName}
                                      />

                                      <Details
                                        detailTitle={"Status"}
                                        Details={
                                          <div
                                            style={{
                                              backgroundColor: getStatusColor(
                                                departmentItem?.departmentStatus
                                              )?.split(",")?.[0],
                                              color: getStatusColor(
                                                departmentItem?.departmentStatus
                                              )?.split(",")?.[1],
                                              cursor: "default",
                                              padding: "6px",
                                              borderRadius: "6px",
                                            }}
                                          >
                                            {departmentItem?.departmentStatus}
                                          </div>
                                        }
                                      />
                                      <Details
                                        detailTitle={"Created On"}
                                        Details={
                                          departmentItem?.createdOn
                                            ? format(
                                                new Date(
                                                  departmentItem?.createdOn?.replace(
                                                    "T",
                                                    " "
                                                  )
                                                ),
                                                "dd/MM/yyyy hh:mm a"
                                              )
                                            : "-"
                                        }
                                      />
                                      <Details
                                        detailTitle={"Last Updated On"}
                                        Details={
                                          departmentItem?.lastUpdatedOn
                                            ? format(
                                                new Date(
                                                  departmentItem?.lastUpdatedOn?.replace(
                                                    "T",
                                                    " "
                                                  )
                                                ),
                                                "dd/MM/yyyy hh:mm a"
                                              )
                                            : "-"
                                        }
                                      />
                                    </div>
                                    <div style={{ padding: "21px" }}>
                                      <div
                                        className="headingForm"
                                        style={{
                                          borderBottom: "none",
                                          margin: "12px 12px 6px 0px",
                                        }}
                                      >
                                        Configure Rating Slabs
                                      </div>
                                      <div
                                        key={departmentItem?.id}
                                        id={`div-${departmentItem?.id}`}
                                        // ref={(el) => {
                                        //   console.log("object el", el);
                                        //   divRefs.current[index] = el;
                                        // }}
                                        style={{
                                          width: "100%",
                                          // width: "588px",
                                          overflowX: "auto",
                                          overflowY: "hidden",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        <div className="table-wrapper PmsDetailsTable">
                                          <table
                                            style={{ width: "max-content" }}
                                          >
                                            <thead>
                                              <tr>
                                                <td
                                                  rowspan="2"
                                                  className="sticky"
                                                  style={{
                                                    width: "90px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Sl.No.
                                                </td>
                                                <td
                                                  colspan="2"
                                                  className="sticky2 "
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Rating
                                                </td>
                                                <td
                                                  rowspan="2"
                                                  className="sticky4"
                                                  style={{
                                                    width: "130px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Name
                                                </td>
                                                <td
                                                  colspan="2"
                                                  className="normalCell "
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Weightage Range
                                                </td>
                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => {
                                                    if (item === "Creator") {
                                                      return null;
                                                    } else {
                                                      return (
                                                        <td
                                                          className="normalCell "
                                                          colSpan="2"
                                                          style={{
                                                            fontSize: "12px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {item === "Creator"
                                                            ? "Weightage (%)"
                                                            : item + " Review"}
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <td
                                                      className="normalCell "
                                                      colSpan="2"
                                                      style={{
                                                        fontSize: "12px",
                                                        fontWeight: "500",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {data?.nextReviewer +
                                                        " Review"}
                                                    </td>
                                                  )}
                                              </tr>
                                              <tr>
                                                <td
                                                  className="sticky2"
                                                  style={{
                                                    width: "133px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Value
                                                </td>
                                                <td
                                                  rowspan="2"
                                                  className="sticky3"
                                                  style={{
                                                    width: "144px",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Star
                                                </td>
                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => (
                                                    <>
                                                      <td
                                                        className="normalCell "
                                                        style={{
                                                          width: "133px",
                                                          fontSize: "12px",
                                                          fontWeight: "500",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        From
                                                      </td>
                                                      <td
                                                        rowspan="2"
                                                        className="normalCell "
                                                        style={{
                                                          width: "144px",
                                                          fontSize: "12px",
                                                          fontWeight: "500",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        To
                                                      </td>
                                                    </>
                                                  )
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <>
                                                      <td
                                                        className="normalCell "
                                                        style={{
                                                          width: "133px",
                                                          fontSize: "12px",
                                                          fontWeight: "500",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        From
                                                      </td>
                                                      <td
                                                        rowspan="2"
                                                        className="normalCell "
                                                        style={{
                                                          width: "144px",
                                                          fontSize: "12px",
                                                          fontWeight: "500",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        To
                                                      </td>
                                                    </>
                                                  )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {criteriaItem?.pmsPerformanceRatingSlab
                                                ?.filter(
                                                  (it) =>
                                                    it?.reviewby ===
                                                      "Creator" &&
                                                    it?.reviewbyDepartmentId ===
                                                      null
                                                )
                                                ?.sort(
                                                  (a, b) => b.rating - a.rating
                                                )
                                                ?.map((item, indexW) => {
                                                  console.log(
                                                    "object itemCommon",
                                                    itemCommon
                                                  );
                                                  return (
                                                    <tr>
                                                      <td
                                                        className="sticky"
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {indexW + 1}
                                                      </td>
                                                      <td
                                                        className="sticky2"
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {item?.rating}
                                                      </td>
                                                      <td className="sticky3">
                                                        {item?.rating >= 0 &&
                                                          item?.rating <= 5 &&
                                                          item?.rating % 0.5 ===
                                                            0 && (
                                                            <ImageRepeater
                                                              number={item?.rating?.toFixed(
                                                                1
                                                              )}
                                                              total={5}
                                                              imageUrl={
                                                                <svg
                                                                  width="18"
                                                                  height="17"
                                                                  viewBox="0 0 18 17"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                                                                    fill="#F7941D"
                                                                  />
                                                                </svg>
                                                              }
                                                              imageUrlSecond={
                                                                <svg
                                                                  width="18"
                                                                  height="18"
                                                                  viewBox="0 0 18 18"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                                                                    fill="#E6E6E6"
                                                                  />
                                                                  <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M8.99994 0L6.97931 6.21885H0.44043L5.7305 10.0623L3.70987 16.2812L8.99994 12.4377V0ZM14.1987 16H13.903L14.29 16.2812L14.1987 16Z"
                                                                    fill="#F7941D"
                                                                  />
                                                                </svg>
                                                              }
                                                              imgBlank={
                                                                <svg
                                                                  width="18"
                                                                  height="17"
                                                                  viewBox="0 0 18 17"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                                                                    fill="#E6E6E6"
                                                                  />
                                                                </svg>
                                                              }
                                                            />
                                                          )}
                                                      </td>
                                                      <td
                                                        className="sticky4"
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {item?.name}
                                                      </td>
                                                      <td
                                                        className="normalCell "
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {criteriaItem?.pmsPerformanceRatingSlab
                                                          ?.filter(
                                                            (itz) =>
                                                              itz?.reviewby ===
                                                                "Creator" &&
                                                              itz?.reviewbyDepartmentId ===
                                                                null
                                                          )
                                                          ?.sort(
                                                            (a, b) =>
                                                              b.rating -
                                                              a.rating
                                                          )[indexW]
                                                          ?.weightFrom + "%"}
                                                      </td>
                                                      <td
                                                        className="normalCell "
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {criteriaItem?.pmsPerformanceRatingSlab
                                                          ?.filter(
                                                            (itz) =>
                                                              itz?.reviewby ===
                                                                "Creator" &&
                                                              itz?.reviewbyDepartmentId ===
                                                                null
                                                          )
                                                          ?.sort(
                                                            (a, b) =>
                                                              b.rating -
                                                              a.rating
                                                          )[indexW]?.weightTo +
                                                          "%"}
                                                      </td>
                                                      {departmentItem?.reviewedBy?.map(
                                                        (it) => {
                                                          if (
                                                            it === "Creator"
                                                          ) {
                                                            return null;
                                                          } else {
                                                            return (
                                                              <>
                                                                <td
                                                                  className="normalCell "
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  {criteriaItem?.pmsPerformanceRatingSlab
                                                                    ?.filter(
                                                                      (itz) =>
                                                                        itz?.reviewby ===
                                                                          it &&
                                                                        (itz?.reviewbyDepartmentId ===
                                                                          departmentItem?.departmentId ||
                                                                          itz?.reviewbyDepartmentId ===
                                                                            null)
                                                                    )
                                                                    ?.sort(
                                                                      (a, b) =>
                                                                        b.rating -
                                                                        a.rating
                                                                    )[indexW]
                                                                    ?.weightFrom +
                                                                    "%"}
                                                                </td>
                                                                <td
                                                                  className="normalCell "
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  {criteriaItem?.pmsPerformanceRatingSlab
                                                                    ?.filter(
                                                                      (itz) =>
                                                                        itz?.reviewby ===
                                                                          it &&
                                                                        (itz?.reviewbyDepartmentId ===
                                                                          departmentItem?.departmentId ||
                                                                          itz?.reviewbyDepartmentId ===
                                                                            null)
                                                                    )
                                                                    ?.sort(
                                                                      (a, b) =>
                                                                        b.rating -
                                                                        a.rating
                                                                    )[indexW]
                                                                    ?.weightTo +
                                                                    "%"}
                                                                </td>
                                                              </>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                      {data?.nextReviewer &&
                                                        data?.nextReviewer
                                                          ?.length > 0 && (
                                                          <>
                                                            <td
                                                              className="normalCell "
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              <InputCustomFormik
                                                                style={{
                                                                  border:
                                                                    "0.75px solid #BFBFBF",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                                name={`criteria[${
                                                                  itemCommon - 1
                                                                }].appraisalSlab[${indexW}].fromWeightageRange`}
                                                                type="number"
                                                              />
                                                            </td>
                                                            <td
                                                              className="normalCell "
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              <InputCustomFormik
                                                                style={{
                                                                  border:
                                                                    "0.75px solid #BFBFBF",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                                name={`criteria[${
                                                                  itemCommon - 1
                                                                }].appraisalSlab[${indexW}].toWeightageRange`}
                                                                type="number"
                                                              />
                                                            </td>
                                                          </>
                                                        )}
                                                    </tr>
                                                  );
                                                })}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            );
                        }
                      )}
                    </div>
                  </div>
                  {finalConfirm || type === "Genral Approve & Review"
                    ? data?.pmsAppraisalCriteria?.pmsPerformanceRatingComment?.map(
                        (item) => (
                          <div style={{ margin: "20px 0px" }}>
                            <div
                              className="headingForm"
                              style={{
                                borderBottom: "none",
                                // margin: " 20px 12px 12px",
                              }}
                            >
                              {item?.name + " (" + item?.degination + ")"}
                            </div>
                            <LabelCustom labelName={"Comments"} />
                            <TextAreaFormik
                              maxLength={500}
                              placeholder="Type Here"
                              // style={{ height: "80px" }}
                              disabled={true}
                              value={item?.comment}
                              name={`Comments`}
                            />
                          </div>
                        )
                      )
                    : data?.pmsAppraisalCriteria?.pmsPerformanceRatingComment
                        ?.filter(
                          (it) =>
                            it?.ratingItemId ===
                            data?.pmsAppraisalCriteria
                              ?.pmsPerformanceRatingItem[0]?.ratingItemId
                        )
                        ?.map((item) => (
                          <div style={{ margin: "20px 0px" }}>
                            <div
                              className="headingForm"
                              style={{
                                borderBottom: "none",
                                // margin: " 20px 12px 12px",
                              }}
                            >
                              {item?.name + " (" + item?.degination + ")"}
                            </div>
                            <LabelCustom labelName={"Comments"} />
                            <TextAreaFormik
                              maxLength={500}
                              placeholder="Type Here"
                              // style={{ height: "80px" }}
                              disabled={true}
                              value={item?.comment}
                              name={`Comments`}
                            />
                          </div>
                        ))}
                  <div style={{ margin: "20px 0px" }}>
                    <LabelCustom labelName={"Comments"} />
                    <TextAreaFormik
                      maxLength={500}
                      placeholder="Type Here"
                      // style={{ height: "80px" }}
                      name={`Comments`}
                    />
                  </div>
                  <div className="createFootCust" style={{ zIndex: "20" }}>
                    <div>
                      <button
                        onClick={handleClose}
                        className="createFooterButtonForm1"
                        type="cancel"
                        style={{ color: "#1296B0" }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      {finalConfirm ? (
                        <button className="button primaryButton" type="submit">
                          Final Confirm
                        </button>
                      ) : (
                        <button className="button primaryButton" type="submit">
                          Review & Approve
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div></div>
        </>
      )}
    </div>
  );
};

export default ReviewAndApprovedPerformanceSlab;

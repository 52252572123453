import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getRequest, postRequest } from "../../../../Services/axios";
import { isLoading, showToast } from "../../../../Features";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { Form, Formik } from "formik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { format } from "date-fns";

const ReviewAndApproved = ({
  id,
  type,
  handleClose,
  DepartmentId,
  criteriaId,
  getAllListData,
  finalConfirm = false,
  selListId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [formValues, setFormValues] = useState(false);

  const [initialValues, setinitialValues] = useState({
    criteria: [
      {
        appraisalClassification: [{ criteria: "", hodReview: "" }],
        total: "",
        appraisalSlab: [
          {
            weightFrom: "",
            weightTo: "",
            appraisalPercentageHOD: "",
            appraisalValueHOD: "",
          },
        ],
        Comments: "",
      },
    ],
  });
  console.log("object formValues", formValues);
  console.log("object initialValues", initialValues);

  const isDisabled = (arr, key) => {
    if (Array.isArray(arr)) {
      const a = arr?.some((it) => it?.appraisalPercentageHOD !== "");
      const b = arr?.some((it) => it?.appraisalValueHOD !== "");
      if (key === "appraisalPercentageHOD") {
        return b;
      }
      if (key === "appraisalValueHOD") {
        return a;
      } else {
        return false;
      }
      //  setAppraisalPercentDisable(b);
      //  setAppraisalValueDisable(a);
    }
    return false;
  };

  const calculateTotalHodReview = (formValues) => {
    if (formValues && Array.isArray(formValues.criteria)) {
      const arr = formValues.criteria.map((it) => {
        // Return the result of the reduce function
        return it?.appraisalClassification?.reduce(
          (sum, item) => sum + (item?.hodReview || 0),
          0
        );
      });
      console.log("object arr", arr);
      console.log(
        "object arr.every((it) => it === 100",
        arr.every((it) => it === 100)
      );
      if (arr.every((it) => it === 100)) {
        return true;
      }
    }
    return false;
  };
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let result;
      if (DepartmentId) {
        result = await getRequest(
          `pmsAppraisalCriteria/Details?ID=${criteriaId}&DepartmentId=${DepartmentId}`
          // `pmsAppraisalCriteria/Details?ID=7&DepartmentId=1`
        );
      } else {
        result = await getRequest(
          `pmsAppraisalCriteria/Details?ID=${criteriaId}`
          // `pmsAppraisalCriteria/Details?ID=7&DepartmentId=1`
        );
      }
      setData(result.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log("object setinitialValues out");
    if (data) {
      console.log("object setinitialValues in");
      setinitialValues(() => {
        const newCriteria = data?.pmsAppraisalCriteria?.pmsAppraisalCriteriaItem
          ?.map((criteriaItem, index) => {
            if (DepartmentId) {
              return criteriaItem?.pmsAppraisalCriteriaDepartment
                ?.filter((it) => it?.departmentId === DepartmentId)
                ?.map((appcriteria) => ({
                  departmentId: appcriteria?.departmentId,
                  id: appcriteria?.id,
                  appraisalClassification:
                    criteriaItem?.pmsAppraisalCriteriaClassification
                      ?.filter(
                        (item) =>
                          item?.reviewby ===
                            appcriteria?.reviewedBy[
                              appcriteria?.reviewedBy?.length - 1
                            ] &&
                          (item?.reviewbyDepartmentId ===
                            appcriteria?.departmentId ||
                            item?.reviewbyDepartmentId === null)
                      )
                      ?.map((item) => ({
                        criteria: item?.criteria ?? "",
                        hodReview: item?.weightage ?? "",
                      })),
                  appraisalSlab: criteriaItem?.pmsAppraisalCriteriaSlab
                    ?.filter(
                      (item) =>
                        item?.reviewby ===
                          appcriteria?.reviewedBy[
                            appcriteria?.reviewedBy?.length - 1
                          ] &&
                        (item?.reviewbyDepartmentId ===
                          appcriteria?.departmentId ||
                          item?.reviewbyDepartmentId === null)
                    )
                    ?.sort((a, b) => a.weightFrom - b.weightFrom)
                    ?.map((item) => ({
                      weightTo: item?.weightTo ?? "",
                      weightFrom: item?.weightFrom ?? "",
                      appraisalPercentageHOD: item?.appraisalPercentage ?? "",
                      appraisalValueHOD: item?.appraisalValue ?? "",
                    })),
                }));
            } else {
              return criteriaItem?.pmsAppraisalCriteriaDepartment?.map(
                (appcriteria) => ({
                  departmentId: appcriteria?.departmentId,
                  id: appcriteria?.id,
                  appraisalClassification:
                    criteriaItem?.pmsAppraisalCriteriaClassification
                      ?.filter(
                        (item) =>
                          item?.reviewby ===
                            appcriteria?.reviewedBy[
                              appcriteria?.reviewedBy?.length - 1
                            ] &&
                          (item?.reviewbyDepartmentId ===
                            appcriteria?.departmentId ||
                            item?.reviewbyDepartmentId === null)
                      )
                      ?.map((item) => ({
                        criteria: item?.criteria ?? "",
                        hodReview: item?.weightage ?? "",
                      })),
                  appraisalSlab: criteriaItem?.pmsAppraisalCriteriaSlab
                    ?.filter(
                      (item) =>
                        item?.reviewby ===
                          appcriteria?.reviewedBy[
                            appcriteria?.reviewedBy?.length - 1
                          ] &&
                        (item?.reviewbyDepartmentId ===
                          appcriteria?.departmentId ||
                          item?.reviewbyDepartmentId === null)
                    )
                    ?.sort((a, b) => a.weightFrom - b.weightFrom)
                    ?.map((item) => ({
                      weightTo: item?.weightTo ?? "",
                      weightFrom: item?.weightFrom ?? "",
                      appraisalPercentageHOD: item?.appraisalPercentage ?? "",
                      appraisalValueHOD: item?.appraisalValue ?? "",
                    })),
                })
              );
            }
          })
          ?.flat();
        return { criteria: newCriteria, Comments: "" };
      });
    }
  }, [data]);

  useEffect(() => {
    document.title = `PeopleSol - Appraisal Criteria Review`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    getDetails();
  }, []);
  const isAllEmpty = () => {
    const variable1 =
      formValues?.criteria &&
      formValues?.criteria?.every((it) =>
        it?.appraisalClassification?.every((item) => item?.hodReview === "")
      );
    const variable2 =
      formValues?.criteria &&
      formValues?.criteria?.every((it) =>
        it?.appraisalSlab?.every(
          (item) =>
            item?.appraisalPercentageHOD === "" &&
            item?.appraisalValueHOD === ""
        )
      );
    console.log("object variable1", variable1);
    console.log("object variable2", variable2);

    return variable1 && variable2;
  };
  const isAllFilled = () => {
    const variable1 =
      formValues?.criteria &&
      formValues?.criteria?.every((it) =>
        it.appraisalClassification?.every((item) => item?.hodReview !== "")
      );
    const variable2 =
      formValues?.criteria &&
      formValues?.criteria?.every((it) =>
        it?.appraisalSlab?.every(
          (item) =>
            item?.appraisalPercentageHOD !== "" ||
            item?.appraisalValueHOD !== ""
        )
      );

    return variable1 && variable2;
  };

  const createReviewApproves = async (values) => {
    console.log("object values", values);
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `pmsAppraisalCriteria/ReviewApproved`,
        values
      );
      if (result?.data?.isSuccess) {
        dispatch(
          showToast({
            text: result?.data?.message ?? "Successfully Updated",
            severity: "success",
          })
        );
        handleClose();
        getAllListData();
      } else {
        dispatch(
          showToast({
            text: result?.data?.message ?? "some error occured",
            severity: "error",
          })
        );
        handleClose();
        getAllListData();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const createFinalConfirm = async (values) => {
    try {
      console.log("object values", values);
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `pmsAppraisalCriteria/FinalConform`,
        values
      );
      if (result?.data?.isSuccess) {
        dispatch(
          showToast({
            text: result?.data?.message ?? "Successfully Updated",
            severity: "success",
          })
        );
        handleClose();
        getAllListData();
      } else {
        dispatch(
          showToast({
            text: result?.data?.message ?? "some error occured",
            severity: "error",
          })
        );
        handleClose();
        getAllListData();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const createGenralApprove = async (values) => {
    try {
      console.log("object values", values);
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `pmsAppraisalCriteria/GeneralsReviewApproved`,
        values
      );
      if (result?.data?.isSuccess) {
        dispatch(
          showToast({
            text: result?.data?.message ?? "Successfully Updated",
            severity: "success",
          })
        );
        handleClose();
        getAllListData();
      } else {
        dispatch(
          showToast({
            text: result?.data?.message ?? "some error occured",
            severity: "error",
          })
        );
        handleClose();
        getAllListData();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const onSubmit = (values) => {
    if (type === "Genral Approve & Review") {
      if (!isAllFilled()) {
        dispatch(
          showToast({
            text: "Please fill the rest",
            severity: "error",
          })
        );
      } else if (!calculateTotalHodReview(formValues)) {
        dispatch(
          showToast({
            text: "Total is not 100",
            severity: "error",
          })
        );
      } else if (isAllFilled()) {
        const payload = {
          CriteriaId: criteriaId,
        };
        payload["GenralRequestId"] = id;
        if (values?.Comments !== "") payload["HrComments"] = values?.Comments;

        const Items = values?.criteria?.map((criteriaItem, index) => {
          return {
            id: criteriaItem?.id,
            DepartmentId: criteriaItem?.departmentId,
            ClassificationModel: criteriaItem?.appraisalClassification?.map(
              (item, ind) => ({
                Criteria: item?.criteria,
                Weightage: item?.hodReview,
              })
            ),
            SlabModel: criteriaItem?.appraisalSlab?.map(
              (departmentItem, ind) => {
                return {
                  WeightFrom:
                    departmentItem?.weightFrom !== ""
                      ? departmentItem?.weightFrom
                      : null,
                  WeightTo:
                    departmentItem?.weightTo !== ""
                      ? departmentItem?.weightTo
                      : null,
                  AppraisalPercentage:
                    departmentItem?.appraisalPercentageHOD !== ""
                      ? departmentItem?.appraisalPercentageHOD
                      : null,
                  AppraisalValue:
                    departmentItem?.appraisalValueHOD !== ""
                      ? departmentItem?.appraisalValueHOD
                      : null,
                };
              }
            ),
          };
        });
        payload["DepartmentReview"] = Items;

        // const Itemss = values?.criteria?.map((item, index) => ({
        //   Id: data?.pmsAppraisalCriteria?.pmsAppraisalCriteriaItem[index]?.id,
        //   ItemName: "Criteria" + Number(index + 1),
        //   Departments: data?.pmsAppraisalCriteria?.pmsAppraisalCriteriaItem[
        //     index
        //   ]?.pmsAppraisalCriteriaDepartment?.map((it) => it?.departmentId),
        //   Classifications: data?.pmsAppraisalCriteria?.pmsAppraisalCriteriaItem[
        //     index
        //   ]?.pmsAppraisalCriteriaClassification
        //     ?.filter((itz) => itz?.reviewby === "Creator")
        //     ?.map((it, indd) => {
        //       return {
        //         Criteria: item?.appraisalClassification[indd]?.criteria,
        //         Weightage: item?.appraisalClassification[indd]?.hodReview,
        //       };
        //     }),
        //   Slabs: data?.pmsAppraisalCriteria?.pmsAppraisalCriteriaItem[
        //     index
        //   ]?.pmsAppraisalCriteriaSlab
        //     ?.filter((itz) => itz?.reviewby === "Creator")
        //     ?.map((it, indd) => ({
        //       WeightFrom:
        //         item?.appraisalSlab[indd]?.weightFrom !== ""
        //           ? item?.appraisalSlab[indd]?.weightFrom
        //           : null,
        //       WeightTo:
        //         item?.appraisalSlab[indd]?.weightTo !== ""
        //           ? item?.appraisalSlab[indd]?.weightTo
        //           : null,
        //       AppraisalPercentage:
        //         item?.appraisalSlab[indd]?.appraisalPercentageHOD !== ""
        //           ? item?.appraisalSlab[indd]?.appraisalPercentageHOD
        //           : null,
        //       AppraisalValue:
        //         item?.appraisalSlab[indd]?.appraisalValueHOD !== ""
        //           ? item?.appraisalSlab[indd]?.appraisalValueHOD
        //           : null,
        //     })),
        // }));
        // payload["Items"] = Items;

        createGenralApprove(payload);
      }
    } else if (finalConfirm) {
      if (!isAllFilled()) {
        dispatch(
          showToast({
            text: "Please fill the rest",
            severity: "error",
          })
        );
      } else if (!calculateTotalHodReview(formValues)) {
        dispatch(
          showToast({
            text: "Total is not 100",
            severity: "error",
          })
        );
      } else if (isAllFilled()) {
        const payload = {
          CriteriaId: criteriaId,
        };
        // payload["GenralRequestId"] = id;
        if (values?.Comments !== "") payload["HrComments"] = values?.Comments;

        const Items = values?.criteria?.map((criteriaItem, index) => {
          return {
            id: criteriaItem?.id,
            DepartmentId: criteriaItem?.departmentId,
            ClassificationModel: criteriaItem?.appraisalClassification?.map(
              (item, ind) => ({
                Criteria: item?.criteria,
                Weightage: item?.hodReview,
              })
            ),
            SlabModel: criteriaItem?.appraisalSlab?.map(
              (departmentItem, ind) => {
                return {
                  WeightFrom:
                    departmentItem?.weightFrom !== ""
                      ? departmentItem?.weightFrom
                      : null,
                  WeightTo:
                    departmentItem?.weightTo !== ""
                      ? departmentItem?.weightTo
                      : null,
                  AppraisalPercentage:
                    departmentItem?.appraisalPercentageHOD !== ""
                      ? departmentItem?.appraisalPercentageHOD
                      : null,
                  AppraisalValue:
                    departmentItem?.appraisalValueHOD !== ""
                      ? departmentItem?.appraisalValueHOD
                      : null,
                };
              }
            ),
          };
        });
        payload["DepartmentReview"] = Items;

        createFinalConfirm(payload);
      }
    } else {
      if (!isAllFilled()) {
        dispatch(
          showToast({
            text: "Please fill the rest",
            severity: "error",
          })
        );
      } else if (!calculateTotalHodReview(formValues)) {
        dispatch(
          showToast({
            text: "Total is not 100",
            severity: "error",
          })
        );
      } else if (isAllFilled()) {
        const payload = {
          id: selListId,
        };
        if (values?.Comments !== "") payload["Comments"] = values?.Comments;
        const classificationModelObj =
          values?.criteria[0]?.appraisalClassification?.map((item, ind) => ({
            Criteria: item?.criteria,
            Weightage: item?.hodReview,
          }));
        const slabModelObj = values?.criteria[0]?.appraisalSlab?.map(
          (item, ind) => ({
            WeightFrom: item?.weightFrom !== "" ? item?.weightFrom : null,
            WeightTo: item?.weightTo !== "" ? item?.weightTo : null,
            AppraisalPercentage:
              item?.appraisalPercentageHOD !== ""
                ? item?.appraisalPercentageHOD
                : null,
            AppraisalValue:
              item?.appraisalValueHOD !== "" ? item?.appraisalValueHOD : null,
          })
        );
        payload["classificationModel"] = classificationModelObj;
        payload["slabModel"] = slabModelObj;

        createReviewApproves(payload);
      }
    }
  };
  useEffect(() => {
    const scrollToRight = () => {
      const myArray = new Array(itemCommon + 1).fill(0);
      myArray?.forEach((it, ind) => {
        const element = document.getElementById(`div-${ind}`);
        const elementC = document.getElementById(`div-${ind + "c"}`);
        if (
          element &&
          elementC &&
          element.scrollWidth &&
          elementC.scrollWidth
        ) {
          // element.scrollLeft = element.scrollWidth;
          element.scrollTo({
            left: element.scrollWidth,
            behavior: "smooth",
          });
          elementC.scrollTo({
            left: elementC.scrollWidth,
            behavior: "smooth",
          });
        }
      });
    };
    console.log("object scrollToRight");
    if (data) {
      setTimeout(scrollToRight, 300);
    }
  }, [data]);
  let itemCommon = 0;
  console.log("object itemCommon", itemCommon);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            // validationSchema={getValidationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(formik) => {
              setFormValues(formik.values);
              itemCommon = 0;
              return (
                <Form>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div>
                      {data?.pmsAppraisalCriteria?.pmsAppraisalCriteriaItem?.map(
                        (criteriaItem, index) => {
                          if (DepartmentId) {
                            return criteriaItem?.pmsAppraisalCriteriaDepartment
                              ?.filter(
                                (it) => it?.departmentId === DepartmentId
                              )
                              ?.map((departmentItem, ind) => {
                                itemCommon++;
                                return (
                                  <div className="DepartmentDesign">
                                    <div
                                      className="detailInner"
                                      style={{ padding: "20px" }}
                                    >
                                      <Details
                                        detailTitle={"Sl.No."}
                                        Details={itemCommon}
                                      />
                                      <Details
                                        detailTitle={"Department"}
                                        Details={departmentItem?.departmentName}
                                      />

                                      <Details
                                        detailTitle={"Status"}
                                        Details={
                                          <div
                                            style={{
                                              backgroundColor: getStatusColor(
                                                departmentItem?.departmentStatus
                                              )?.split(",")?.[0],
                                              color: getStatusColor(
                                                departmentItem?.departmentStatus
                                              )?.split(",")?.[1],
                                              cursor: "default",
                                              padding: "6px",
                                              borderRadius: "6px",
                                            }}
                                          >
                                            {departmentItem?.departmentStatus}
                                          </div>
                                        }
                                      />
                                      <Details
                                        detailTitle={"Created On"}
                                        Details={
                                          departmentItem?.createdOn
                                            ? format(
                                                new Date(
                                                  departmentItem?.createdOn?.replace(
                                                    "T",
                                                    " "
                                                  )
                                                ),
                                                "dd/MM/yyyy hh:mm a"
                                              )
                                            : "-"
                                        }
                                      />
                                      <Details
                                        detailTitle={"Last Updated On"}
                                        Details={
                                          departmentItem?.lastUpdatedOn
                                            ? format(
                                                new Date(
                                                  departmentItem?.lastUpdatedOn?.replace(
                                                    "T",
                                                    " "
                                                  )
                                                ),
                                                "dd/MM/yyyy hh:mm a"
                                              )
                                            : "-"
                                        }
                                      />
                                    </div>
                                    <div style={{ padding: "21px" }}>
                                      <div
                                        className="headingForm"
                                        style={{
                                          borderBottom: "none",
                                          margin: "12px 12px 6px 0px",
                                        }}
                                      >
                                        Appraisal Classification Inputs
                                      </div>
                                      <div
                                        key={itemCommon + "1"}
                                        id={`div-${itemCommon + "c"}`}
                                        style={{
                                          width: "100%",
                                          // width: "588px",
                                          overflowX: "auto ",
                                          overflowY: "hidden",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        <div className="table-wrapper PmsClassificationTable">
                                          <table
                                            style={{ width: "max-content" }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  className="sticky"
                                                  style={{ width: "92px" }}
                                                >
                                                  Sl.No.
                                                </th>
                                                <th
                                                  style={{ width: "141px" }}
                                                  className="sticky2 "
                                                >
                                                  Criteria
                                                </th>
                                                <th
                                                  className="sticky3"
                                                  style={{ width: "154px" }}
                                                >
                                                  Weightage (%)
                                                </th>

                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => {
                                                    if (item === "Creator") {
                                                      return null;
                                                    } else {
                                                      return (
                                                        <th
                                                          className="normalCell"
                                                          style={{
                                                            width: "130px",
                                                          }}
                                                        >
                                                          {item + " Review"}
                                                        </th>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <th
                                                      className="normalCell "
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {data?.nextReviewer +
                                                        " Review"}
                                                    </th>
                                                  )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {criteriaItem?.pmsAppraisalCriteriaClassification
                                                ?.filter(
                                                  (it) =>
                                                    it?.reviewby ===
                                                      "Creator" &&
                                                    it?.reviewbyDepartmentId ===
                                                      null
                                                )

                                                ?.map((item, indexW) => (
                                                  <tr>
                                                    <td
                                                      className="sticky"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {indexW + 1}
                                                    </td>

                                                    <td
                                                      className=" sticky2"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {
                                                        criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )[indexW]?.criteria
                                                      }
                                                    </td>
                                                    <td
                                                      className=" sticky3"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                        (itz) =>
                                                          itz?.reviewby ===
                                                            "Creator" &&
                                                          itz?.reviewbyDepartmentId ===
                                                            null
                                                      )[indexW]?.weightage +
                                                        "%"}
                                                    </td>
                                                    {departmentItem?.reviewedBy?.map(
                                                      (it) => {
                                                        if (it === "Creator") {
                                                          return null;
                                                        } else {
                                                          return (
                                                            <>
                                                              <td
                                                                className="normalCell "
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                                  (itz) =>
                                                                    itz?.reviewby ===
                                                                      it &&
                                                                    (itz?.reviewbyDepartmentId ===
                                                                      departmentItem?.departmentId ||
                                                                      itz?.reviewbyDepartmentId ===
                                                                        null)
                                                                )[indexW]
                                                                  ?.weightage +
                                                                  "%"}
                                                              </td>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                    {data?.nextReviewer &&
                                                      data?.nextReviewer
                                                        ?.length > 0 && (
                                                        <td
                                                          className="normalCell "
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <InputCustomFormik
                                                            style={{
                                                              border:
                                                                "0.75px solid #BFBFBF",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                            name={`criteria[${
                                                              itemCommon - 1
                                                            }].appraisalClassification[${indexW}].hodReview`}
                                                            type="number"
                                                          />
                                                        </td>
                                                      )}
                                                  </tr>
                                                ))}
                                              <tr>
                                                <td
                                                  className="sticky "
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                ></td>
                                                <td
                                                  className="sticky2 "
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Total
                                                </td>
                                                <td
                                                  className="sticky3 "
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  100%
                                                </td>
                                                {departmentItem?.reviewedBy?.map(
                                                  (it) => {
                                                    if (it === "Creator") {
                                                      return null;
                                                    } else {
                                                      return (
                                                        <td
                                                          className="normalCell "
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          100%
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <td
                                                      className="normalCell "
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {Array?.isArray(
                                                        formValues?.criteria
                                                      ) &&
                                                        formValues?.criteria[
                                                          itemCommon - 1
                                                        ]?.appraisalClassification?.reduce(
                                                          (acc, item) => {
                                                            return (
                                                              acc +
                                                              (item?.hodReview ||
                                                                0)
                                                            );
                                                          },
                                                          0
                                                        )}
                                                    </td>
                                                  )}
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ padding: "21px" }}>
                                      <div
                                        className="headingForm"
                                        style={{
                                          borderBottom: "none",
                                          margin: "12px 12px 6px 0px",
                                        }}
                                      >
                                        Configure Appraisal Slabs
                                      </div>
                                      <div
                                        key={itemCommon}
                                        id={`div-${itemCommon}`}
                                        style={{
                                          width: "100%",
                                          // width: "588px",
                                          overflowX: "auto ",
                                          overflowY: "hidden",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        <div className="table-wrapper PmsAppraisalSlabTable">
                                          <table
                                            style={{ width: "max-content" }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  rowspan="2"
                                                  className="sticky"
                                                  style={{ width: "90px" }}
                                                >
                                                  Sl.No.
                                                </th>
                                                <th
                                                  colspan="2"
                                                  className="sticky2 "
                                                  style={{ width: "280px" }}
                                                >
                                                  Weightage Range
                                                </th>
                                                <th
                                                  rowspan="2"
                                                  className="normalCell"
                                                  style={{ width: "154px" }}
                                                >
                                                  Appraisal %
                                                </th>
                                                <th
                                                  rowspan="2"
                                                  className="normalCell "
                                                >
                                                  Appraisal Value
                                                </th>
                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => {
                                                    if (item === "Creator") {
                                                      return null;
                                                    } else {
                                                      return (
                                                        <th
                                                          className="normalCell "
                                                          colSpan="2"
                                                        >
                                                          {item === "Creator"
                                                            ? "Weightage (%)"
                                                            : item + " Review"}
                                                        </th>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <th
                                                      className="normalCell "
                                                      colSpan="2"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {data?.nextReviewer +
                                                        " Review"}
                                                    </th>
                                                  )}
                                              </tr>
                                              <tr>
                                                <th
                                                  className="sticky2 "
                                                  style={{
                                                    width: "140px",
                                                  }}
                                                >
                                                  From
                                                </th>
                                                <th
                                                  rowspan="2"
                                                  className="sticky3 "
                                                  style={{
                                                    width: "140px",
                                                  }}
                                                >
                                                  To
                                                </th>

                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => {
                                                    if (item === "Creator")
                                                      return null;
                                                    else
                                                      return (
                                                        <>
                                                          <th
                                                            className="normalCell "
                                                            style={{
                                                              width: "133px",
                                                            }}
                                                          >
                                                            Appraisal %
                                                          </th>
                                                          <th
                                                            rowspan="2"
                                                            className="normalCell "
                                                            style={{
                                                              width: "144px",
                                                            }}
                                                          >
                                                            Appraisal Value
                                                          </th>
                                                        </>
                                                      );
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <>
                                                      <th
                                                        className="normalCell "
                                                        style={{
                                                          width: "133px",
                                                        }}
                                                      >
                                                        Appraisal %
                                                      </th>
                                                      <th
                                                        className="normalCell "
                                                        style={{
                                                          width: "144px",
                                                        }}
                                                      >
                                                        Appraisal Value
                                                      </th>
                                                    </>
                                                  )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {criteriaItem?.pmsAppraisalCriteriaSlab
                                                ?.filter(
                                                  (it) =>
                                                    it?.reviewby ===
                                                      "Creator" &&
                                                    it?.reviewbyDepartmentId ===
                                                      null
                                                )
                                                ?.sort(
                                                  (a, b) =>
                                                    a.weightFrom - b.weightFrom
                                                )
                                                ?.map((item, indexW) => (
                                                  <tr>
                                                    <td
                                                      className="sticky"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {indexW + 1}
                                                    </td>
                                                    <td
                                                      className="sticky2"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaSlab
                                                        ?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.weightFrom -
                                                            b.weightFrom
                                                        )[indexW]?.weightFrom +
                                                        "%"}
                                                    </td>
                                                    <td
                                                      className="sticky3"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaSlab
                                                        ?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.weightFrom -
                                                            b.weightFrom
                                                        )[indexW]?.weightTo +
                                                        "%"}
                                                    </td>
                                                    <td
                                                      className="normalCell"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaSlab
                                                        ?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.weightFrom -
                                                            b.weightFrom
                                                        )[indexW]
                                                        ?.appraisalPercentage
                                                        ? criteriaItem?.pmsAppraisalCriteriaSlab
                                                            ?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )
                                                            ?.sort(
                                                              (a, b) =>
                                                                a.weightFrom -
                                                                b.weightFrom
                                                            )[indexW]
                                                            ?.appraisalPercentage +
                                                          "%"
                                                        : "NA"}
                                                    </td>
                                                    <td
                                                      className="normalCell"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaSlab
                                                        ?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.weightFrom -
                                                            b.weightFrom
                                                        )[indexW]
                                                        ?.appraisalValue ??
                                                        "NA"}
                                                    </td>

                                                    {departmentItem?.reviewedBy?.map(
                                                      (it) => {
                                                        if (it === "Creator") {
                                                          return null;
                                                        } else {
                                                          return (
                                                            <>
                                                              <td
                                                                className="normalCell "
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {criteriaItem?.pmsAppraisalCriteriaSlab
                                                                  ?.filter(
                                                                    (itz) =>
                                                                      itz?.reviewby ===
                                                                        it &&
                                                                      (itz?.reviewbyDepartmentId ===
                                                                        departmentItem?.departmentId ||
                                                                        itz?.reviewbyDepartmentId ===
                                                                          null)
                                                                  )
                                                                  ?.sort(
                                                                    (a, b) =>
                                                                      a.weightFrom -
                                                                      b.weightFrom
                                                                  )[indexW]
                                                                  ?.appraisalPercentage
                                                                  ? criteriaItem?.pmsAppraisalCriteriaSlab?.filter(
                                                                      (itz) =>
                                                                        itz?.reviewby ===
                                                                          it &&
                                                                        (itz?.reviewbyDepartmentId ===
                                                                          departmentItem?.departmentId ||
                                                                          itz?.reviewbyDepartmentId ===
                                                                            null)
                                                                    )[indexW]
                                                                      ?.appraisalPercentage +
                                                                    "%"
                                                                  : "NA"}
                                                              </td>
                                                              <td
                                                                className="normalCell "
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {criteriaItem?.pmsAppraisalCriteriaSlab
                                                                  ?.filter(
                                                                    (itz) =>
                                                                      itz?.reviewby ===
                                                                        it &&
                                                                      (itz?.reviewbyDepartmentId ===
                                                                        departmentItem?.departmentId ||
                                                                        itz?.reviewbyDepartmentId ===
                                                                          null)
                                                                  )
                                                                  ?.sort(
                                                                    (a, b) =>
                                                                      a.weightFrom -
                                                                      b.weightFrom
                                                                  )[indexW]
                                                                  ?.appraisalValue ??
                                                                  "NA"}
                                                              </td>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                    {data?.nextReviewer &&
                                                      data?.nextReviewer
                                                        ?.length > 0 && (
                                                        <>
                                                          <td
                                                            className="normalCell "
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {" "}
                                                            <InputCustomFormik
                                                              style={{
                                                                border:
                                                                  "0.75px solid #BFBFBF",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                              name={`criteria[${
                                                                itemCommon - 1
                                                              }].appraisalSlab[${indexW}].appraisalPercentageHOD`}
                                                              type="number"
                                                              disabled={isDisabled(
                                                                formValues?.criteria &&
                                                                  formValues
                                                                    ?.criteria[
                                                                    itemCommon -
                                                                      1
                                                                  ]
                                                                    ?.appraisalSlab,
                                                                "appraisalPercentageHOD"
                                                              )}
                                                            />
                                                          </td>
                                                          <td
                                                            className="normalCell "
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            <InputCustomFormik
                                                              style={{
                                                                border:
                                                                  "0.75px solid #BFBFBF",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                              name={`criteria[${
                                                                itemCommon - 1
                                                              }].appraisalSlab[${indexW}].appraisalValueHOD`}
                                                              type="number"
                                                              disabled={isDisabled(
                                                                formValues?.criteria &&
                                                                  formValues
                                                                    ?.criteria[
                                                                    itemCommon -
                                                                      1
                                                                  ]
                                                                    ?.appraisalSlab,
                                                                "appraisalValueHOD"
                                                              )}
                                                            />
                                                          </td>
                                                        </>
                                                      )}
                                                  </tr>
                                                ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              });
                          } else
                            return criteriaItem?.pmsAppraisalCriteriaDepartment?.map(
                              (departmentItem, ind) => {
                                itemCommon++;
                                return (
                                  <div className="DepartmentDesign">
                                    <div
                                      className="detailInner"
                                      style={{ padding: "20px" }}
                                    >
                                      <Details
                                        detailTitle={"Sl.No."}
                                        Details={itemCommon}
                                      />
                                      <Details
                                        detailTitle={"Department"}
                                        Details={departmentItem?.departmentName}
                                      />

                                      <Details
                                        detailTitle={"Status"}
                                        Details={
                                          <div
                                            style={{
                                              backgroundColor: getStatusColor(
                                                departmentItem?.departmentStatus
                                              )?.split(",")?.[0],
                                              color: getStatusColor(
                                                departmentItem?.departmentStatus
                                              )?.split(",")?.[1],
                                              cursor: "default",
                                              padding: "6px",
                                              borderRadius: "6px",
                                            }}
                                          >
                                            {departmentItem?.departmentStatus}
                                          </div>
                                        }
                                      />
                                      <Details
                                        detailTitle={"Created On"}
                                        Details={
                                          departmentItem?.createdOn
                                            ? format(
                                                new Date(
                                                  departmentItem?.createdOn?.replace(
                                                    "T",
                                                    " "
                                                  )
                                                ),
                                                "dd/MM/yyyy hh:mm a"
                                              )
                                            : "-"
                                        }
                                      />
                                      <Details
                                        detailTitle={"Last Updated On"}
                                        Details={
                                          departmentItem?.lastUpdatedOn
                                            ? format(
                                                new Date(
                                                  departmentItem?.lastUpdatedOn?.replace(
                                                    "T",
                                                    " "
                                                  )
                                                ),
                                                "dd/MM/yyyy hh:mm a"
                                              )
                                            : "-"
                                        }
                                      />
                                    </div>
                                    <div style={{ padding: "21px" }}>
                                      <div
                                        className="headingForm"
                                        style={{
                                          borderBottom: "none",
                                          margin: "12px 12px 6px 0px",
                                        }}
                                      >
                                        Appraisal Classification Inputs
                                      </div>
                                      <div
                                        key={itemCommon + "1"}
                                        id={`div-${itemCommon + "c"}`}
                                        style={{
                                          width: "100%",
                                          // width: "588px",
                                          overflowX: "auto ",
                                          overflowY: "hidden",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        <div className="table-wrapper PmsClassificationTable">
                                          <table
                                            style={{ width: "max-content" }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  className="sticky"
                                                  style={{ width: "92px" }}
                                                >
                                                  Sl.No.
                                                </th>
                                                <th
                                                  style={{ width: "141px" }}
                                                  className="sticky2 "
                                                >
                                                  Criteria
                                                </th>
                                                <th
                                                  className="sticky3"
                                                  style={{ width: "154px" }}
                                                >
                                                  Weightage (%)
                                                </th>

                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => {
                                                    if (item === "Creator") {
                                                      return null;
                                                    } else {
                                                      return (
                                                        <th
                                                          className="normalCell"
                                                          style={{
                                                            width: "130px",
                                                          }}
                                                        >
                                                          {item + " Review"}
                                                        </th>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <th
                                                      className="normalCell "
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {data?.nextReviewer +
                                                        " Review"}
                                                    </th>
                                                  )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {criteriaItem?.pmsAppraisalCriteriaClassification
                                                ?.filter(
                                                  (it) =>
                                                    it?.reviewby ===
                                                      "Creator" &&
                                                    it?.reviewbyDepartmentId ===
                                                      null
                                                )

                                                ?.map((item, indexW) => (
                                                  <tr>
                                                    <td
                                                      className="sticky"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {indexW + 1}
                                                    </td>

                                                    <td
                                                      className=" sticky2"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {
                                                        criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )[indexW]?.criteria
                                                      }
                                                    </td>
                                                    <td
                                                      className=" sticky3"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                        (itz) =>
                                                          itz?.reviewby ===
                                                            "Creator" &&
                                                          itz?.reviewbyDepartmentId ===
                                                            null
                                                      )[indexW]?.weightage +
                                                        "%"}
                                                    </td>
                                                    {departmentItem?.reviewedBy?.map(
                                                      (it) => {
                                                        if (it === "Creator") {
                                                          return null;
                                                        } else {
                                                          return (
                                                            <>
                                                              <td
                                                                className="normalCell "
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                                  (itz) =>
                                                                    itz?.reviewby ===
                                                                      it &&
                                                                    (itz?.reviewbyDepartmentId ===
                                                                      departmentItem?.departmentId ||
                                                                      itz?.reviewbyDepartmentId ===
                                                                        null)
                                                                )[indexW]
                                                                  ?.weightage +
                                                                  "%"}
                                                              </td>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                    {data?.nextReviewer &&
                                                      data?.nextReviewer
                                                        ?.length > 0 && (
                                                        <td
                                                          className="normalCell "
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <InputCustomFormik
                                                            style={{
                                                              border:
                                                                "0.75px solid #BFBFBF",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                            name={`criteria[${
                                                              itemCommon - 1
                                                            }].appraisalClassification[${indexW}].hodReview`}
                                                            type="number"
                                                          />
                                                        </td>
                                                      )}
                                                  </tr>
                                                ))}
                                              <tr>
                                                <td
                                                  className="sticky "
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                ></td>
                                                <td
                                                  className="sticky2 "
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Total
                                                </td>
                                                <td
                                                  className="sticky3 "
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  100%
                                                </td>
                                                {departmentItem?.reviewedBy?.map(
                                                  (it) => {
                                                    if (it === "Creator") {
                                                      return null;
                                                    } else {
                                                      return (
                                                        <td
                                                          className="normalCell "
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          100%
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <td
                                                      className="normalCell "
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {Array?.isArray(
                                                        formValues?.criteria
                                                      ) &&
                                                        formValues?.criteria[
                                                          itemCommon - 1
                                                        ]?.appraisalClassification?.reduce(
                                                          (acc, item) => {
                                                            return (
                                                              acc +
                                                              (item?.hodReview ||
                                                                0)
                                                            );
                                                          },
                                                          0
                                                        )}
                                                    </td>
                                                  )}
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{ padding: "21px" }}>
                                      <div
                                        className="headingForm"
                                        style={{
                                          borderBottom: "none",
                                          margin: "12px 12px 6px 0px",
                                        }}
                                      >
                                        Configure Appraisal Slabs
                                      </div>
                                      <div
                                        key={itemCommon}
                                        id={`div-${itemCommon}`}
                                        style={{
                                          width: "100%",
                                          // width: "588px",
                                          overflowX: "auto ",
                                          overflowY: "hidden",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        <div className="table-wrapper PmsAppraisalSlabTable">
                                          <table
                                            style={{ width: "max-content" }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  rowspan="2"
                                                  className="sticky"
                                                  style={{ width: "90px" }}
                                                >
                                                  Sl.No.
                                                </th>
                                                <th
                                                  colspan="2"
                                                  className="sticky2 "
                                                  style={{ width: "280px" }}
                                                >
                                                  Weightage Range
                                                </th>
                                                <th
                                                  rowspan="2"
                                                  className="normalCell"
                                                  style={{ width: "154px" }}
                                                >
                                                  Appraisal %
                                                </th>
                                                <th
                                                  rowspan="2"
                                                  className="normalCell "
                                                >
                                                  Appraisal Value
                                                </th>
                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => {
                                                    if (item === "Creator") {
                                                      return null;
                                                    } else {
                                                      return (
                                                        <th
                                                          className="normalCell "
                                                          colSpan="2"
                                                        >
                                                          {item === "Creator"
                                                            ? "Weightage (%)"
                                                            : item + " Review"}
                                                        </th>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <th
                                                      className="normalCell "
                                                      colSpan="2"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {data?.nextReviewer +
                                                        " Review"}
                                                    </th>
                                                  )}
                                              </tr>
                                              <tr>
                                                <th
                                                  className="sticky2 "
                                                  style={{
                                                    width: "140px",
                                                  }}
                                                >
                                                  From
                                                </th>
                                                <th
                                                  rowspan="2"
                                                  className="sticky3 "
                                                  style={{
                                                    width: "140px",
                                                  }}
                                                >
                                                  To
                                                </th>

                                                {departmentItem?.reviewedBy?.map(
                                                  (item) => {
                                                    if (item === "Creator")
                                                      return null;
                                                    else
                                                      return (
                                                        <>
                                                          <th
                                                            className="normalCell "
                                                            style={{
                                                              width: "133px",
                                                            }}
                                                          >
                                                            Appraisal %
                                                          </th>
                                                          <th
                                                            rowspan="2"
                                                            className="normalCell "
                                                            style={{
                                                              width: "144px",
                                                            }}
                                                          >
                                                            Appraisal Value
                                                          </th>
                                                        </>
                                                      );
                                                  }
                                                )}
                                                {data?.nextReviewer &&
                                                  data?.nextReviewer?.length >
                                                    0 && (
                                                    <>
                                                      <th
                                                        className="normalCell "
                                                        style={{
                                                          width: "133px",
                                                        }}
                                                      >
                                                        Appraisal %
                                                      </th>
                                                      <th
                                                        className="normalCell "
                                                        style={{
                                                          width: "144px",
                                                        }}
                                                      >
                                                        Appraisal Value
                                                      </th>
                                                    </>
                                                  )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {criteriaItem?.pmsAppraisalCriteriaSlab
                                                ?.filter(
                                                  (it) =>
                                                    it?.reviewby ===
                                                      "Creator" &&
                                                    it?.reviewbyDepartmentId ===
                                                      null
                                                )
                                                ?.sort(
                                                  (a, b) =>
                                                    a.weightFrom - b.weightFrom
                                                )
                                                ?.map((item, indexW) => (
                                                  <tr>
                                                    <td
                                                      className="sticky"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {indexW + 1}
                                                    </td>
                                                    <td
                                                      className="sticky2"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaSlab
                                                        ?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.weightFrom -
                                                            b.weightFrom
                                                        )[indexW]?.weightFrom +
                                                        "%"}
                                                    </td>
                                                    <td
                                                      className="sticky3"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaSlab
                                                        ?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.weightFrom -
                                                            b.weightFrom
                                                        )[indexW]?.weightTo +
                                                        "%"}
                                                    </td>
                                                    <td
                                                      className="normalCell"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaSlab
                                                        ?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.weightFrom -
                                                            b.weightFrom
                                                        )[indexW]
                                                        ?.appraisalPercentage
                                                        ? criteriaItem?.pmsAppraisalCriteriaSlab?.filter(
                                                            (itz) =>
                                                              itz?.reviewby ===
                                                                "Creator" &&
                                                              itz?.reviewbyDepartmentId ===
                                                                null
                                                          )[indexW]
                                                            ?.appraisalPercentage +
                                                          "%"
                                                        : "NA"}
                                                    </td>
                                                    <td
                                                      className="normalCell"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {criteriaItem?.pmsAppraisalCriteriaSlab
                                                        ?.filter(
                                                          (itz) =>
                                                            itz?.reviewby ===
                                                              "Creator" &&
                                                            itz?.reviewbyDepartmentId ===
                                                              null
                                                        )
                                                        ?.sort(
                                                          (a, b) =>
                                                            a.weightFrom -
                                                            b.weightFrom
                                                        )[indexW]
                                                        ?.appraisalValue ??
                                                        "NA"}
                                                    </td>

                                                    {departmentItem?.reviewedBy?.map(
                                                      (it) => {
                                                        if (it === "Creator") {
                                                          return null;
                                                        } else {
                                                          return (
                                                            <>
                                                              <td
                                                                className="normalCell "
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {criteriaItem?.pmsAppraisalCriteriaSlab
                                                                  ?.filter(
                                                                    (itz) =>
                                                                      itz?.reviewby ===
                                                                        it &&
                                                                      (itz?.reviewbyDepartmentId ===
                                                                        departmentItem?.departmentId ||
                                                                        itz?.reviewbyDepartmentId ===
                                                                          null)
                                                                  )
                                                                  ?.sort(
                                                                    (a, b) =>
                                                                      a.weightFrom -
                                                                      b.weightFrom
                                                                  )[indexW]
                                                                  ?.appraisalPercentage
                                                                  ? criteriaItem?.pmsAppraisalCriteriaSlab?.filter(
                                                                      (itz) =>
                                                                        itz?.reviewby ===
                                                                          it &&
                                                                        (itz?.reviewbyDepartmentId ===
                                                                          departmentItem?.departmentId ||
                                                                          itz?.reviewbyDepartmentId ===
                                                                            null)
                                                                    )[indexW]
                                                                      ?.appraisalPercentage +
                                                                    "%"
                                                                  : "NA"}
                                                              </td>
                                                              <td
                                                                className="normalCell "
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {criteriaItem?.pmsAppraisalCriteriaSlab
                                                                  ?.filter(
                                                                    (itz) =>
                                                                      itz?.reviewby ===
                                                                        it &&
                                                                      (itz?.reviewbyDepartmentId ===
                                                                        departmentItem?.departmentId ||
                                                                        itz?.reviewbyDepartmentId ===
                                                                          null)
                                                                  )
                                                                  ?.sort(
                                                                    (a, b) =>
                                                                      a.weightFrom -
                                                                      b.weightFrom
                                                                  )[indexW]
                                                                  ?.appraisalValue ??
                                                                  "NA"}
                                                              </td>
                                                            </>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                    {data?.nextReviewer &&
                                                      data?.nextReviewer
                                                        ?.length > 0 && (
                                                        <>
                                                          <td
                                                            className="normalCell "
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {" "}
                                                            <InputCustomFormik
                                                              style={{
                                                                border:
                                                                  "0.75px solid #BFBFBF",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                              name={`criteria[${
                                                                itemCommon - 1
                                                              }].appraisalSlab[${indexW}].appraisalPercentageHOD`}
                                                              type="number"
                                                              disabled={isDisabled(
                                                                formValues?.criteria &&
                                                                  formValues
                                                                    ?.criteria[
                                                                    itemCommon -
                                                                      1
                                                                  ]
                                                                    ?.appraisalSlab,
                                                                "appraisalPercentageHOD"
                                                              )}
                                                            />
                                                          </td>
                                                          <td
                                                            className="normalCell "
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            <InputCustomFormik
                                                              style={{
                                                                border:
                                                                  "0.75px solid #BFBFBF",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                              name={`criteria[${
                                                                itemCommon - 1
                                                              }].appraisalSlab[${indexW}].appraisalValueHOD`}
                                                              type="number"
                                                              disabled={isDisabled(
                                                                formValues?.criteria &&
                                                                  formValues
                                                                    ?.criteria[
                                                                    itemCommon -
                                                                      1
                                                                  ]
                                                                    ?.appraisalSlab,
                                                                "appraisalValueHOD"
                                                              )}
                                                            />
                                                          </td>
                                                        </>
                                                      )}
                                                  </tr>
                                                ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            );
                        }
                      )}
                    </div>
                  </div>
                  <div style={{ margin: "20px 0px" }}>
                    <LabelCustom labelName={"Comments"} />
                    <TextAreaFormik
                      maxLength={500}
                      placeholder="Type Here"
                      // style={{ height: "80px" }}
                      name={`Comments`}
                    />
                  </div>
                  <div className="createFootCust" style={{ zIndex: "21" }}>
                    <div>
                      <button
                        onClick={handleClose}
                        className="createFooterButtonForm1"
                        type="cancel"
                        style={{ color: "#1296B0" }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button className="button primaryButton" type="submit">
                        Review & Approve
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div></div>
        </>
      )}
    </div>
  );
};

export default ReviewAndApproved;

/* eslint-disable no-unused-vars */
import React from "react";
import { Routes, Route } from "react-router-dom";
import { RequireAuth, NoAuth } from "../Components";
import {
  Home,
  Error404,
  Landing,
  Approvals,
  Notifications,
  Services,
  Chat,
  HRMS,
  ATS,
  Settings,
} from "../Pages";
import { HRMSMaster } from "../Pages/HRMSMaster/HRMSMaster";
import { ATSMaster } from "../Pages/ATSMaster/ATSMaster";
import { PMSMaster } from "../Pages/PMSMaster/PMSMaster";
import { LDMaster } from "../Pages/LandDMaster/LDMaster";
import { OfficeMaster } from "../Pages/OfficeMaster/OfficeMaster";
import { ITSMMaster } from "../Pages/ITSMMaster/ITSMMaster";
import { TravelMaster } from "../Pages/TravelMaster/TravelMaster";
import { PayrollMaster } from "../Pages/PayrollMaster/PayrollMaster";
import { HomeMaster } from "../Pages/HomeMaster/HomeMaster";
import { GlobalSettingsMaster } from "../Pages/GlobalSettingsMaster/GlobalSettingsMaster";
import { CandidateLanding } from "../Pages/Landing/CandidateLanding";
import { ConsultantLanding } from "../Pages/Landing/ConsultantLanding";
import { CandidatePage } from "../Pages/CandidatePage/CandidatePage";
import { ConsultantPage } from "../Pages/ConsultantPage/ConsultantPage";
import { CandidateRequireAuth } from "../Components/RequireAuth/CandidateRequireAuth";
import { ConsultantRequireAuth } from "../Components/RequireAuth/ConsultantRequireAuth";
import { CandidateJobsPage } from "../Pages/CandidatePage/CandidateJobsPage";
import { ConsultantJobsPage } from "../Pages/ConsultantPage/ConsultantJobsPage";
import { ConsultantCandidatePage } from "../Pages/ConsultantPage/ConsultantCandidatePage";
import { TaxMaster } from "../Pages/TaxMaster/TaxMaster";
import {
  CandidateOnboardingLanding,
  OnboardingForm,
} from "../Pages/Landing/CandidateOnboardingLanding";
import FormikDemoForm, {
  FormikMultiStepForm,
} from "../Components/FormikMultiStepForm/FormikMultiStepForm";
import OnboardingCompleted from "../Pages/Landing/OnboardingCompleted";
import { EmployeeOnboardingLanding } from "../Pages/Landing/EmployeeOnboardingLanding";
import { Office } from "../Pages/Office/Office";
import { Travel } from "../Pages/Travel/Travel";
import { ITSM } from "../Pages/ITSM/ITSM";
import { LD } from "../Pages/LD/LD";
import { ForgotPassword } from "../Pages/Landing/ForgotPassword";
import { VerifyOtp } from "../Pages/Landing/VerifyOtp";
import { NewPassword } from "../Pages/Landing/NewPassword";
import { Day30Form } from "../Pages/Home/Components/HomeDashboard/FeedbackComp/Day30Form";
import { Day30Feedback } from "../Pages/Home/Components/HomeDashboard/FeedbackComp/Day30Feedback";
import { KnowledgeCenter } from "../Pages/Home/Components/HomeDashboard/KnowledgeCenter/KnowledgeCenter";
import { WhatsNew } from "../Pages/Home/Components/HomeDashboard/WhatsNew/WhatsNew";
import { PreviewCandidateOnboarding } from "../Pages/Landing/PreviewCandidateOnboarding";
import { UserOnboarding } from "../Pages/Home/Components/HomeDashboard/UserOnboarding/UserOnboarding";
import { Payroll } from "../Pages/Payroll/Payroll";
import { MasterAdminOnboarding } from "../Pages/Home/Components/HomeDashboard/MasterAdminOnboarding/MasterAdminOnboarding";
import { PMS } from "../Pages/PMS/PMS";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import UpdatePassword from "../Pages/ResetPassword/UpdatePassword";
import { Tax } from "../Pages/Tax/Tax";
import { Contactdirectory } from "../Pages/Home/Components/HomeDashboard/Contactdirectory";
import { CreateEmployeeN } from "../Pages/HRMSMaster/OrgStructureComponents/CreateEmployeeN";
import { TeamMemberDetails } from "../Pages/Home/Components/HomeDashboard/Components/TeamCardComp/TeamMemberDetails";
import { ReportsKpi } from "../Pages/Office/Components/ReportsKpi";
import { ReportsKpiMsI } from "../Pages/ReportsKpi/ReportsKpiMsI";
import PremiumContentPageChat from "../Pages/Home/PremiumContentPageChat";
import PremiumContentPageSocial from "../Pages/Home/PremiumContentPageSocial";
import { ChatApp } from "../Pages/Chat/ChatApp";
import { SwitchCompany } from "../Pages/Home/SwitchCompany";
import { CandidateOfferLetter } from "../Pages/HRMS/Components/OnboardingComponents/CandidateOfferLetter";

const Routepath = () => {
  return (
    <Routes>
      <Route element={<NoAuth />}>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Landing />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/verifyotp" element={<VerifyOtp />} />
        <Route path="/newpassword" element={<NewPassword />} />
        <Route path="/candidate/login" element={<CandidateLanding />} />
        <Route path="/candidate" element={<CandidateLanding />} />
        <Route path="/consultant/login" element={<ConsultantLanding />} />
        <Route path="/candidateofferletter/*" element={<CandidateOfferLetter />} />
        <Route path="/consultant" element={<ConsultantLanding />} />
        <Route path="/candidateonboarding/*" element={<OnboardingForm />} />
        <Route path="/onboardingcompleted" element={<OnboardingCompleted />} />
      </Route>
      <Route element={<RequireAuth />}>
        <Route
          path="/employeeonboarding"
          element={<EmployeeOnboardingLanding />}
        />
        <Route path="/premium/chat" element={<PremiumContentPageChat />} />
        <Route path="/premium/payroll" element={<PremiumContentPageChat />} />
        <Route path="/premium/ld" element={<PremiumContentPageChat />} />
        <Route path="/premium/office" element={<PremiumContentPageChat />} />
        <Route
          path="/previewonboardingcompleted"
          element={<OnboardingCompleted />}
        />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/changepassword" element={<UpdatePassword />} />
        <Route path="/home/*" element={<Home />} />
        <Route path="/switchCompany/*" element={<SwitchCompany />} />
        <Route path="/homemaster/*" element={<HomeMaster />} />
        <Route path="/knowledgecenter" element={<KnowledgeCenter />} />
        <Route path="/contactdirectory" element={<Contactdirectory />} />
        <Route path="/whatsnew/*" element={<WhatsNew />} />
        <Route path="/useronboarding/*" element={<UserOnboarding />} />
        <Route path="/adminonboarding/*" element={<MasterAdminOnboarding />} />
        <Route path="/chat" element={<ChatApp />} />
        <Route path="/approvals" element={<Approvals />} />
        <Route path="/approvals/*" element={<Approvals />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/services" element={<Services />} />
        <Route
          path="/services/profile/create"
          element={<CreateEmployeeN title={"Create Employee"} />}
        />
        <Route
          path="/services/profile/details"
          element={<TeamMemberDetails title={"Create Employee"} />}
        />
        <Route path="/services/*" element={<Services />} />
        <Route path="/hrms/*" element={<HRMS />} />
        <Route path="/itsm/*" element={<ITSM />} />
        <Route path="/reportskpi/*" element={<ReportsKpiMsI />} />
        <Route path="/hrmsmaster/*" element={<HRMSMaster />} />
        <Route path="/payroll/*" element={<Payroll />} />
        <Route path="/payrollmaster/*" element={<PayrollMaster />} />
        <Route path="/pms/*" element={<PMS />} />
        <Route path="/tax/*" element={<Tax />} />
        <Route path="/taxmaster/*" element={<TaxMaster />} />
        <Route path="/ats/*" element={<ATS />} />
        <Route path="/atsmaster/*" element={<ATSMaster />} />
        <Route path="/pmsmaster/*" element={<PMSMaster />} />
        <Route path="/feedback/*" element={<Day30Feedback />} />
        <Route path="/ld/*" element={<LD />} />
        <Route path="/ldmaster/*" element={<LDMaster />} />
        <Route path="/office/*" element={<Office />} />
        <Route path="/officemaster/*" element={<OfficeMaster />} />
        <Route path="/itsmmaster/*" element={<ITSMMaster />} />
        <Route path="/travel/*" element={<Travel />} />
        <Route path="/travelmaster/*" element={<TravelMaster />} />
        <Route
          path="/previewcandidateonboarding/*"
          element={<PreviewCandidateOnboarding />}
        />
        <Route
          path="/globalsettingsmaster/*"
          element={<GlobalSettingsMaster />}
        />
        <Route path="/config/*" element={<Settings />} />
        <Route path="/*" element={<Error404 />} />
      </Route>

      <Route element={<CandidateRequireAuth />}>
        <Route path="/candidate/profile" element={<CandidatePage />} />
        <Route path="/candidate/jobs/*" element={<CandidateJobsPage />} />
      </Route>

      <Route element={<ConsultantRequireAuth />}>
        <Route path="/consultant/profile" element={<ConsultantPage />} />
        <Route path="/consultant/jobs/*" element={<ConsultantJobsPage />} />
        <Route
          path="/consultant/candidate/*"
          element={<ConsultantCandidatePage />}
        />
      </Route>
    </Routes>
  );
};

export { Routepath };

import React from "react";
import { useEffect } from "react";
const Details = ({ detailTitle, Details, styles, ...props }) => {
  useEffect(() => {
    document.title = `PeopleSol - Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="detailData" style={styles} {...props}>
      <label>{detailTitle ?? ""}</label>
      <label>:</label>
      <label className="detailsValue">{Details ?? ""}</label>
    </div>
  );
};
export { Details };

import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";

const GetTeamTaskTodayDeadline = () => {
  const [loading, setloading] = useState(false);
  const [teaddeadlinedata, setteamdeadlinedata] = useState([]);

  const getapidata = async () => {
    try {
      setloading(true);
      const result = await getRequest(`Settings/GetTeamTaskTodayDeadline`);
      setteamdeadlinedata(result?.data?.data?.task);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getapidata();
    document.title = "PeopleSol - Team Task Today Deadline";
    return () => (document.title = "PeopleSol");
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : teaddeadlinedata.length > 0 ? (
        <div style={{ height: "100%" }}>
          <div style={{ height: "560px" }}>
            <table className="responsive-table tableHeaderRow">
              {/* Table Header */}
              <thead style={{ position: "sticky" }}>
                <tr>
                  <th>S no.</th>
                  <th>Request Number</th>
                  <th>Description</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Deadline</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                {teaddeadlinedata.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.requestNumber}</td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                      }}
                    >
                      {item?.description ? item?.description : "-"}
                    </td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                      }}
                    >
                      {item?.title ? item?.title : "-"}
                    </td>
                    <td>
                      <div
                        style={{
                          backgroundColor: getStatusColor(item?.status)?.split(
                            ","
                          )?.[0],
                          color: getStatusColor(item?.status)?.split(",")?.[1],
                          cursor: "default",
                          padding: "2px 6px",
                          borderRadius: "4px",
                        }}
                      >
                        {item?.status}
                      </div>
                    </td>
                    <td>
                      {item?.endsOn
                        ? format(new Date(item?.endsOn), "dd/MM/yyyy")
                        : "-"}
                    </td>
                    <td>{item?.priority ? item?.priority : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="noDatastyle" style={{ padding: "75px 105px" }}>
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>No Records</h4>
        </div>
      )}
    </>
  );
};

export default GetTeamTaskTodayDeadline;

import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { showToast, isLoading } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";

const opt = [{ label: "select", value: null }];

export const CreateAreaComp = ({ data, editID }) => {
  const dispatch = useDispatch();
  const [personalSubList, setPersonalSubList] = useState([]);
  useEffect(() => {
    document.title = `PeopleSol - Create Area `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getSubAreaData = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });

      setPersonalSubList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getSubAreaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"ERP ID"} mandatory={true} />
        <InputCustomFormik
          name="sapid"
          type={"text"}
          placeholder={"Enter SAP ID"}
        />
      </div>
      <div>
        <LabelCustom labelName={"HRBP"} mandatory={true} />
        <DropdownInfiniteScroll
          name="hrbp"
          placeholder={"Select HRBP"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.hrbp]}
        />
      </div>
      <div>
        <LabelCustom labelName={"TA"} mandatory={true} />
        <DropdownInfiniteScroll
          name="taEmployeeId"
          placeholder={"Select TA"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.taEmployeeId]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Location HOD"} mandatory={true} />
        <DropdownInfiniteScroll
          name="locationHOD"
          placeholder={"Select Location HOD"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.locationHOD]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Personal Sub Area"} />
        <SelectForm
          name="subAreaId"
          options={personalSubList}
          placeholder={"Select Personal Sub Area"}
          values={
            data
              ? personalSubList.filter(
                  (options) => options.value === data?.subAreaId
                )
              : opt
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"Accounts"} mandatory={true} />
        <DropdownInfiniteScroll
          name="accounts"
          placeholder={"Select Accounts"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.accounts]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Plant Training Executive"} mandatory={true} />
        <DropdownInfiniteScroll
          name="plantTrainingExecutive"
          placeholder={"Select Plant Training Executive"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.plantTrainingExecutive]}
        />
      </div>
      <div>
        <LabelCustom labelName={"IT HOD"} mandatory={true} />
        <DropdownInfiniteScroll
          name="itHOD"
          placeholder={"Select IT HOD"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.ithod]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Goal Sheet"} mandatory={true} />
        <DropdownInfiniteScroll
          name="goalSheet"
          placeholder={"Select Goal Sheet"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.goalSheet]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Payment Team"} mandatory={true} />
        <DropdownInfiniteScroll
          name="paymentTeam"
          placeholder={"Select Payment Team"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.paymentTeam]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Plant Gate Employee"} mandatory={true} />
        <DropdownInfiniteScroll
          name="plantGateEmployee"
          placeholder={"Select Plant Gate Employee"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.plantGateEmployee]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Plant Time Officer"} mandatory={true} />
        <DropdownInfiniteScroll
          name="planttimeOfficer"
          placeholder={"Select Plant Time Officer"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.planttimeOfficer]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Salary Structure Group"} mandatory={true} />
        <DropdownInfiniteScroll
          name="salaryStructureGroup"
          placeholder={"Select Salary Structure Group"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.salaryStructureGroup]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Salary Structure HR"} mandatory={true} />
        <DropdownInfiniteScroll
          name="salaryStructureHR"
          placeholder={"Select Salary Structure HR"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.salaryStructureHR]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Settlement"} mandatory={true} />
        <DropdownInfiniteScroll
          name="settlement"
          placeholder={"Select Settlement"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.settlement]}
        />
      </div>
      <div>
        <LabelCustom labelName={"Account For Loan"} mandatory={true} />
        <DropdownInfiniteScroll
          name="accountforLoan"
          placeholder={"Select Account For Loan"}
          details={data}
          editID={editID}
          detailsForEdit={[data?.area?.accountforLoan]}
        />
      </div>
    </div>
  );
};

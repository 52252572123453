import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { useEffect } from "react";
import { CreateAppraisalCriteriaComp } from "./CreateAppraisalCriteriaComp";

const CreateAppraisalCriteria = () => {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);

  const data = state?.id;
  const [radioOptions, setRadioOptions] = useState([]);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState(false);
  const [initialValues, setinitialValues] = useState({
    nameAppraisalCriteria: "",
    criteria: [
      {
        departmentSelected: [],
        AppraisalWeightage: [
          { label: "Performance", Performance: "", isActive: true },
          { label: "Tasks", Tasks: "", isActive: true },
          { label: "Rewards", Rewards: "", isActive: true },
          { label: "L&D", ld: "", isActive: true },
        ],
        AppraisalWeightageTotal: "",
        appraisalSlab: [
          {
            fromWeightageRange: "",
            toWeightageRange: "",
            appraisalPercentage: "",
            appraisalValue: "",
          },
        ],
      },
    ],
  });
  const isFilledOrNot = (val) => {
    const arr = val?.criteria?.map((item) => {
      return item?.AppraisalWeightage?.filter(
        (itz) => itz.isActive === true
      ).map((it) => {
        if (it?.label === "Performance") {
          return it?.Performance !== "" && it?.Performance !== 0;
        } else if (it?.label === "Tasks") {
          return it?.Tasks !== "" && it?.Tasks !== 0;
        } else if (it?.label === "Rewards") {
          return it?.Rewards !== "" && it?.Rewards !== 0;
        } else if (it?.label === "L&D") {
          return it?.ld !== "" && it?.ld !== 0;
        }
        return false; // Return false for any other labels
      });
    });
    const resultArr = arr?.map((item) => {
      return !item?.some((it) => it === false);
    });
    return !resultArr?.some((it) => it === false);
  };
  useEffect(() => {
    if (data)
      setinitialValues(() => {
        let arr = [
          { label: "Performance", Performance: "", isActive: true },
          { label: "Tasks", Tasks: "", isActive: true },
          { label: "Rewards", Rewards: "", isActive: true },
          { label: "L&D", ld: "", isActive: true },
        ];

        return {
          nameAppraisalCriteria: data ? detailsform?.name : "",
          criteria: detailsform?.pmsAppraisalCriteriaItem
            ? detailsform?.pmsAppraisalCriteriaItem?.map((item) => ({
                departmentSelected: item?.pmsAppraisalCriteriaDepartment?.map(
                  (it) => it?.departmentId?.toString()
                ),
                AppraisalWeightage: arr?.map((itz, ind) => {
                  let a =
                    itz.label === "L&D"
                      ? {
                          label: itz?.label,
                          ld: item?.pmsAppraisalCriteriaClassification?.some(
                            (itm) =>
                              itm?.criteria === itz?.label &&
                              itm?.reviewby === "Creator"
                          )
                            ? item?.pmsAppraisalCriteriaClassification?.filter(
                                (itm) =>
                                  itm?.criteria === itz?.label &&
                                  itm?.reviewby === "Creator"
                              )[0]?.weightage
                            : 0,
                          isActive:
                            item?.pmsAppraisalCriteriaClassification?.some(
                              (itm) =>
                                itm?.criteria === itz?.label &&
                                itm?.reviewby === "Creator" &&
                                itm?.weightage !== 0
                            ),
                        }
                      : {
                          label: itz?.label,
                          [itz.label]:
                            item?.pmsAppraisalCriteriaClassification?.some(
                              (itm) =>
                                itm?.criteria === itz?.label &&
                                itm?.reviewby === "Creator"
                            )
                              ? item?.pmsAppraisalCriteriaClassification?.filter(
                                  (itm) =>
                                    itm?.criteria === itz?.label &&
                                    itm?.reviewby === "Creator"
                                )[0]?.weightage
                              : 0,
                          isActive:
                            item?.pmsAppraisalCriteriaClassification?.some(
                              (itm) =>
                                itm?.criteria === itz?.label &&
                                itm?.reviewby === "Creator" &&
                                itm?.weightage !== 0
                            ),
                        };
                  return a;
                }),
                AppraisalWeightageTotal: "",
                appraisalSlab:
                  item?.pmsAppraisalCriteriaSlab?.length > 0
                    ? item?.pmsAppraisalCriteriaSlab
                        ?.filter((item) => item?.reviewby === "Creator")
                        ?.sort((a, b) => a?.weightFrom - b?.weightFrom)
                        ?.map((it) => ({
                          fromWeightageRange: data ? it?.weightFrom : "",
                          toWeightageRange: data ? it?.weightTo : "",
                          appraisalPercentage: data
                            ? it?.appraisalPercentage ?? ""
                            : "",
                          appraisalValue: data ? it?.appraisalValue ?? "" : "",
                        }))
                    : [
                        {
                          fromWeightageRange: "",
                          toWeightageRange: "",
                          appraisalPercentage: "",
                          appraisalValue: "",
                        },
                      ],
              }))
            : [
                {
                  departmentSelected: [],
                  AppraisalWeightage: [
                    { label: "Performance", Performance: "", isActive: true },
                    { label: "Tasks", Tasks: "", isActive: true },
                    { label: "Rewards", Rewards: "", isActive: true },
                    { label: "L&D", ld: "", isActive: true },
                  ],
                  AppraisalWeightageTotal: "",
                  appraisalSlab: [
                    {
                      fromWeightageRange: "",
                      toWeightageRange: "",
                      appraisalPercentage: "",
                      appraisalValue: "",
                    },
                  ],
                },
              ],
        };
      });
  }, [detailsform, radioOptions]);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `pmsAppraisalCriteria/Details?ID=${data}`
      );
      setDetailsform(result?.data?.summary?.pmsAppraisalCriteria);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("pmsAppraisalCriteria/Create", values);
      if (result?.data?.isSuccess) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      } else {
        dispatch(showToast({ text: result?.data?.message, severity: "error" }));
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Appraisal Criteria`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, [data]);
  const getRadioOptions = async () => {
    try {
      const result = await getRequest(`Employees/GetDepartments`);
      let listdata = [];
      result?.data?.results?.map((item) =>
        listdata?.push({
          id: item?.id,
          answerText: item?.text,
        })
      );
      setRadioOptions(listdata);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getRadioOptions();
  }, []);
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("pmsAppraisalCriteria/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const duplicateDepartment = (values) => {
    if (!Array.isArray(values?.criteria)) {
      return false;
    }

    const numberCounts = {};

    for (const item of values?.criteria) {
      if (Array.isArray(item.departmentSelected)) {
        for (const number of item.departmentSelected) {
          if (numberCounts[number]) {
            return true; // Found a duplicate number
          } else {
            numberCounts[number] = 1;
          }
        }
      }
    }

    return false; // No duplicates found
  };
  const valuePercentAppraisal = (values) => {
    const result = values?.criteria?.every((it) => {
      return it?.appraisalSlab?.every((itz) => {
        return itz?.appraisalPercentage !== "" || itz?.appraisalValue !== "";
      });
    });

    return !result;
  };
  const validationSchema = Yup.object({
    nameAppraisalCriteria: Yup.string()
      .max(149, "Name must be less than 150 characters")
      .required("Please enter the Name "),
    criteria: Yup.array().of(
      Yup.object().shape({
        departmentSelected: Yup.array()
          .min(1, "Please Select atleast one Department")
          .required("Please Select Department"),
        AppraisalWeightageTotal: Yup.number()
          .required("Total must be 100")
          .equals([100], "Total must be 100"),
        appraisalSlab: Yup.array().of(
          Yup.object().shape({
            fromWeightageRange: Yup.number().required("Enter"),
            toWeightageRange: Yup.number().required("Enter"),
            // appraisalPercentage: Yup.number().required("Enter"),
            // appraisalValue: Yup.number().required("Enter"),
            // appraisalPercentage: Yup.number().when("appraisalValue", {
            //   is: (value) => !value || value === "",
            //   then: Yup.number().required(
            //     "Either appraisalPercentage or appraisalValue must be filled"
            //   ),
            //   otherwise: Yup.number(),
            // }),
            // appraisalValue: Yup.number().when("appraisalPercentage", {
            //   is: (value) => !value || value === "",
            //   then: Yup.number().required(
            //     "Either appraisalPercentage or appraisalValue must be filled"
            //   ),
            //   otherwise: Yup.number(),
            // }),
          })
        ),
      })
    ),
  });

  const onSubmit = (values) => {
    if (valuePercentAppraisal(values)) {
      dispatch(
        showToast({
          text: "Either Appraisal Percentage or Appraisal Value must be filled",
          severity: "error",
        })
      );
    } else if (duplicateDepartment(values)) {
      dispatch(
        showToast({
          text: "Selected departments should be unique for each criteria",
          severity: "error",
        })
      );
    } else if (
      !values?.criteria?.every((it) => {
        return it?.appraisalSlab?.every((itz) => {
          return (
            itz?.fromWeightageRange % 1 === 0 && itz?.toWeightageRange % 1 === 0
          );
        });
      })
    ) {
      dispatch(
        showToast({
          text: "Weight To and Weight From cannot have decimals",
          severity: "error",
        })
      );
    } else if (isFilledOrNot(values)) {
      const createValues = {
        Id: data ? detailsform?.id : 0,
        Name: values?.nameAppraisalCriteria,
        Items: values?.criteria?.map((item, index) => ({
          ItemName: "Criteria" + Number(index + 1),
          Departments: item?.departmentSelected?.map((it) => Number(it)),
          Classifications: item?.AppraisalWeightage?.map((it) => {
            if (it?.label === "Performance") {
              return { Criteria: it?.label, Weightage: it?.Performance };
            } else if (it?.label === "Tasks") {
              return { Criteria: it?.label, Weightage: it?.Tasks };
            } else if (it?.label === "Rewards") {
              return { Criteria: it?.label, Weightage: it?.Rewards };
            } else if (it?.label === "L&D") {
              return { Criteria: it?.label, Weightage: it?.ld };
            }
          }),
          Slabs: item?.appraisalSlab?.map((it) => ({
            WeightFrom: it?.fromWeightageRange,
            WeightTo: it?.toWeightageRange,
            AppraisalPercentage:
              it?.appraisalPercentage !== "" ? it?.appraisalPercentage : null,
            AppraisalValue:
              it?.appraisalValue !== "" ? it?.appraisalValue : null,
          })),
        })),
      };
      data ? Edit(createValues) : createPageData(createValues);
    } else
      dispatch(
        showToast({
          text: "Enter All Weightage Percentage",
          severity: "error",
        })
      );
  };

  const navigate = useNavigate();
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  console.log("object formValues", formValues);
  console.log("object initialValues", initialValues);
  const returnPage = () => {
    navigate(-1);
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={
        data ? "Edit Appraisal Criteria" : "Create Appraisal Criteria"
      }
      ButtonLabel={
        data ? "Edit Appraisal Criteria" : "Create Appraisal Criteria"
      }
      onSubmit={onSubmit}
      styles={{ width: "841px", maxWidth: "80%" }}
      validationSchema={validationSchema}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={
        <CreateAppraisalCriteriaComp
          formValues={formValues}
          setinitialValues={setinitialValues}
          setRadioOptions={setRadioOptions}
          radioOptions={radioOptions}
        />
      }
    />
  );
};
export { CreateAppraisalCriteria };

import React, { useEffect, useState } from "react";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { getRequest } from "../../../Services/axios";
const emailTemplateList = [
  { label: "Investment Declaration", value: "Income tax declaration" },
  { label: "Proof Submission", value: "Document Submission" },
  // { label: "Tax Finalisation", value: "Tax Finalisation" },
];
const taxTypeList = [
  { label: "Tax Declaration", value: "Tax Declaration" },
  { label: "Tax Submission", value: "Tax Submission" },
];


export const CreateTaxComp = ({ formValues }) => {
  const [oldRegimeList, setOldList] = useState([]);
  const [newRegimeList, setNewList] = useState([]);

  const [financeYearOpt, setFinanceOpt] = useState([]);
  const getFinanceYear = () => {
    let startYear = new Date().getFullYear() - 10;
    let financialYears = [];
    while (true) {
      if (financialYears.length == 50) break;
      let a = JSON.stringify(startYear) + "-" + JSON.stringify(startYear + 1);
      financialYears.push({ label: a, value: a })
      startYear++;
    }
    setFinanceOpt(financialYears);
  }



  useEffect(() => {
    getFinanceYear()
    getRegimes()
    document.title = `PeopleSol -  Create Tax `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getRegimes = async () => {
    const oldResult = await getRequest("IncomeTaxDeclarationSettings/IncomeTaxRegimeDroupDown?regime=Old Regime");
    const newResult = await getRequest("IncomeTaxDeclarationSettings/IncomeTaxRegimeDroupDown?regime=New Regime");
    if (oldResult.data) {
      let list = oldResult.data.results.map(item => ({ label: item.text, value: item.id }))
      setOldList([...list]);
    }
    if (newResult.data) {
      let list = newResult.data.results.map(item => ({ label: item.text, value: item.id }))
      setNewList([...list]);
    }
  }
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Financial Year"} mandatory={true} />
        <SelectForm
          options={financeYearOpt}
          maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Select Financial Year"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Declaration/Submission"} mandatory={true} />
        <SelectForm
          placeholder={"Select Email Template"}
          name="emailTemplate"
          options={emailTemplateList}
        />
      </div>
      <div>
        <LabelCustom labelName={"Start Date for Initiating"} mandatory={true} />
        <DatePickerFormiks name="startDate" />
      </div>
      {formValues?.startDate ? (
        formValues?.startDate !== "" ? (
          <div>
            <LabelCustom labelName={"Deadline for Filing"} mandatory={true} />
            <DatePickerFormiks
              name="endDate"
              minDate={
                formValues?.startDate
                  ? formValues?.startDate !== ""
                    ? new Date(formValues?.startDate)
                    : null
                  : null
              }
            />
          </div>
        ) : null
      ) : null}
      <div>
        <LabelCustom labelName={"Select New Regime Tax Structure"} />
        <SelectForm
          placeholder={"Select regime"}
          name="newStectureId"
          options={newRegimeList}
        />
      </div>
      <div>
        <LabelCustom labelName={"Select Old Regime Tax Structure"} />
        <SelectForm
          placeholder={"Select regime"}
          name="oldStectureId"
          options={oldRegimeList}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"} />
        <TextAreaFormik

          maxLength={500}
          name="description"
          type={"text"}
          placeholder={"Enter Description"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload Attachment"} />
        <FileUploadFormik name="attachment" />
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>Size: 2 MB</span>
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>Format: JPEG, PNG, PDF</span>
      </div>
      {/* <div>
        <LabelCustom labelName={"Tax Type"} />
        <SelectForm
          placeholder={"Select Tax Type"}
          name="taxType"
          options={taxTypeList}
        />
      </div> */}
    </div>
  );
};

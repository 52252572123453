import React, { useState, useEffect } from "react";
import { getRequest } from "../../../Services/axios";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { showToast, isLoading } from "../../../Features";
import { useDispatch } from "react-redux";
import { FieldArray } from "formik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";

const opt = [{ label: "select", value: null }];

export const CreateTravelCategoryComp = ({ setInitialValue, formValues }) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Travel Eligibility`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const dispatch = useDispatch();
  const [roleList, setRoleList] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const getDesignation = async () => {
    try {
      const result = await getRequest("TravelAdvanceEligibilities/GetBands");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      setRoleList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const modeArray = [
    { label: "Flight", value: "Flight", rateUnit: "Per Ticket" },
    { label: "Train", value: "Train", rateUnit: "Per Ticket" },
    { label: "Bus", value: "Bus", rateUnit: "Cost Per Km" },
    { label: "Taxi", value: "Taxi", rateUnit: "Cost Per Km" },
    { label: "Hotel", value: "Hotel", rateUnit: "Cost Per Night" },
    { label: "Rickshaw", value: "Rickshaw", rateUnit: "Cost Per Km" },
    { label: "Self Vehicle", value: "Self Vehicle", rateUnit: "Cost Per Km" },
    { label: "Food", value: "Food", rateUnit: "Cost Per Unit" },
    { label: "Gift", value: "Gift", rateUnit: "Cost Per Unit" },
    { label: "Gadget", value: "Gadget", rateUnit: "Cost Per Unit" },
    { label: "Metro", value: "Metro", rateUnit: "Cost Per Km" },
    { label: "Local Train", value: "Local Train", rateUnit: "Cost Per Km" },
    { label: "Local Bus", value: "Local Bus", rateUnit: "Cost Per Km" },
  ];

  useEffect(() => {
    const selectedElement = modeArray?.find(
      (item) => item?.value === formValues?.mode
    );
    if (selectedElement) {
      const updatedTravelDetails = formValues?.TravellingCategoriesDetails?.map(
        (item) => ({
          ...item,
          type: selectedElement?.rateUnit,
        })
      );

      setInitialValue((prevState) => ({
        mode: formValues?.mode,
        TravellingCategoriesDetails: updatedTravelDetails,
      }));
    }
  }, [formValues?.mode]);

  useEffect(() => {
    getDesignation();
  }, []);
  return (
    <div>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName={"Mode"} mandatory={true} />

          <SelectForm
            placeholder={"Select Mode"}
            name="mode"
            options={modeArray}
          />
        </div>
      </div>
      <div style={{ marginTop: "20px" }} className="repeatedSectionContainer">
        <label className="repeatedHeading">Expenses</label>
        <FieldArray name="TravellingCategoriesDetails">
          {(fieldArrayProps) => {
            const { insert, push, remove, form } = fieldArrayProps;
            const { values, errors } = form;
            const { TravellingCategoriesDetails } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"S. No."} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Category"} mandatory={true} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Budget"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={"Budget Type"}
                          mandatory={true}
                        />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {TravellingCategoriesDetails?.map((Requestlist, index) => (
                    <TableBody key={index}>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      {/* 2nd column */}

                      <StyledTableCell>
                        <InputCustomFormik
                          maxlength={150}
                          name={`TravellingCategoriesDetails[${index}].category`}
                          type={"text"}
                          placeholder={"Please Enter Category"}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputCustomFormik
                          name={`TravellingCategoriesDetails[${index}].budget`}
                          type={"number"}
                          placeholder={"Please Enter Budget"}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputCustomFormik
                          maxlength={150}
                          disabled={true}
                          name={`TravellingCategoriesDetails[${index}].type`}
                          type={"text"}
                          placeholder={"Please Enter Type"}
                        />
                      </StyledTableCell>

                      {/* 8th column */}
                      <StyledTableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            TravellingCategoriesDetails.length > 1
                              ? remove(index)
                              : dispatch(
                                  showToast({
                                    text: "Atleast One Item is Required ",
                                    severity: "error",
                                  })
                                )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() => {
                            insert(index + 1, {
                              category: "",
                              budget: "",
                              type: TravellingCategoriesDetails[0].type,
                            });
                          }}
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

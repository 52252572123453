/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import {
  deleteWithPayloadRequest,
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../Services/axios";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { DetailsStep } from "../../../../Components/FormComponent/DetailsStep";
import { EditableInput } from "../../../../Components/FormComponent/EditableInput";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
const SpecificDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.item;
  const closeDetails = () => {
    navigate(-1);
  };
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isApprovePopup, setIsApprovePopup] = useState(false);
  const [selId, setSelId] = useState(false);
  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setIsOpen((prev) => !prev);
  };
  let Arrid = [];
  if (eventtData)
    eventtData?._pmssettings?.goalslist?.map((item) => {
      return Arrid.push(item.id);
    });
  const hasApprovedStatus =
    eventtData?._pmssettings?.pmsGoalsettingsEmployees.some(
      (obj) => obj.status === "RM Approved"
    );
  const hasRejectedStatus =
    eventtData?._pmssettings?.pmsGoalsettingsEmployees.some(
      (obj) => obj.status === "RM Rejected"
    );
  const Pending = eventtData?._pmssettings?.pmsGoalsettingsEmployees.some(
    (obj) => obj.status === "RM Approval Pending"
  );
  useEffect(() => {
    document.title = `PeopleSol - Specific Details `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `goals/details/?GoalSettingId=${selectedId?.goalId}&&EmployeeId=${selectedId?.employeeId}`
      );
      setEventtData(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const checkValue = (value) => {
    if (
      (value?.name === "StartDate" || value?.name === "ExpectedEndDate") &&
      value?.value === ""
    ) {
      return false;
    }
    //  else if (value?.name === "Weightage") {
    //   const val = eventtData._pmssettings.goalslist.reduce((acc, item) => {
    //     debugger;
    //     if (item.id !== Number(value?.pk)) {
    //       return acc + (item?.weightage || 0);
    //     } else {
    //       return acc;
    //     }
    //   }, 0);
    //   const currentWeightage = Number(value?.value) || 0;
    //   const totalWeightage = val + currentWeightage;

    //   if (totalWeightage !== 100) {
    //     dispatch(
    //       showToast({
    //         text: "Total weightage is not equal to 100",
    //         severity: "error",
    //       })
    //     );
    //     return false;
    //   } else return true;
    // }
    else return true;
  };

  const handleApiCall = async (value) => {
    console.log("object value", value);
    let result;
    try {
      if (checkValue(value)) {
        setLoading(true);
        if (value.name === "Name") {
          result = await postRequest(
            `goals/SaveObjective?goalid=${selectedId?.goalId}&value=${value.value}&pk=${value.pk}`
          );
        } else if (value.name === "Description") {
          result = await postRequest(
            `goals/SaveKPIName?goalid=${selectedId?.goalId}&value=${value.value}&pk=${value.pk}`
          );
        } else if (value.name === "StartDate") {
          result = await postRequest(
            `goals/SaveDate?goalid=${selectedId?.goalId}&value=${value.value}&pk=${value.pk}`
          );
        } else if (value.name === "ExpectedEndDate") {
          result = await postRequest(
            `goals/SaveEndDate?goalid=${selectedId?.goalId}&value=${value.value}&pk=${value.pk}`
          );
        } else if (value.name === "Kra") {
          result = await postRequest(
            `goals/SaveKra?goalid=${selectedId?.goalId}&value=${value.value}&pk=${value.pk}`
          );
        } else if (value.name === "Weightage") {
          result = await postRequest(
            `goals/SaveWeightage?goalid=${selectedId?.goalId}&value=${value.value}&pk=${value.pk}`
          );
        }
        setApiData(result.data.data);
      } else {
        if (value?.name === "StartDate" || value?.name === "ExpectedEndDate") {
          dispatch(
            showToast({
              text: "Please Enter Date",
              severity: "error",
            })
          );
        }
        if (value?.name === "StartDate" || value?.name === "ExpectedEndDate") {
          dispatch(
            showToast({
              text: "Please Enter Date",
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text:
            error?.response?.data === "One or more validation errors occurred."
              ? "Please Enter Data in Correct Format"
              : error?.response?.data,
          severity: "error",
        })
      );
    } finally {
      getEmployeeData();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  const checkWeightage = () => {
    const val = eventtData._pmssettings.goalslist.reduce((acc, item) => {
      return acc + (item?.weightage || 0);
    }, 0);
    const totalWeightage = val;
    if (totalWeightage !== 100) {
      dispatch(
        showToast({
          text: "Total weightage is not equal to 100",
          severity: "error",
        })
      );
      return false;
    } else return true;
  };
  const handleApprove = async (type, remark) => {
    try {
      if (checkWeightage()) {
        setLoading(true);

        if (type === "Reject")
          await deleteWithPayloadRequest(
            `Goals/RejectAll?Remarks=${remark}`,
            Arrid
          );
        else if (type === "RejectAll")
          await deleteWithPayloadRequest(
            `Goals/${type}?Remarks=${remark}`,
            Arrid
          );
        else await deleteWithPayloadRequest(`Goals/${type}`, Arrid);
        dispatch(showToast({ text: "Success", severity: "success" }));
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getEmployeeData();
      isOpen && togglePopup();
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => { };
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Goal Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          {loading ? (
            <Loader />
          ) : eventtData ? (
            <div
              className="detailsEReq"
              style={{ height: "100%", display: "block" }}
            >
              <div style={{ height: "calc(100% - 67px)" }}>
                <div className="detailInner" syle={{ overflow: "auto" }}>
                  <Details
                    detailTitle={"Employee"}
                    Details={eventtData?.employee}
                  />
                  <Details
                    detailTitle={"Goal Setting"}
                    Details={eventtData?._pmssettings?.name}
                  />

                  <Details
                    detailTitle={"Status"}
                    Details={
                      hasApprovedStatus ? (
                        <div className="approvedGoals">Approved</div>
                      ) : Pending ? (
                        <div className="approvedGoals">Pending</div>
                      ) : (
                        <div className="rejectedGoals">Rejected</div>
                      )
                    }
                  />

                  {hasApprovedStatus && (
                    <Details
                      detailTitle={"Approved Date"}
                      Details={
                        hasApprovedStatus && (
                          <div>
                            {eventtData?._pmssettings
                              ?.pmsGoalsettingsEmployees[0]?.lastUpdatedOn &&
                              format(
                                new Date(
                                  eventtData?._pmssettings?.pmsGoalsettingsEmployees[0]?.lastUpdatedOn
                                ),
                                "dd/MM/yyyy "
                              )}
                          </div>
                        )
                      }
                    />
                  )}
                  {hasRejectedStatus && (
                    <Details
                      detailTitle={"Rejected Date"}
                      Details={
                        hasRejectedStatus && (
                          <div>
                            {eventtData?._pmssettings
                              ?.pmsGoalsettingsEmployees[0]?.lastUpdatedOn &&
                              format(
                                new Date(
                                  eventtData?._pmssettings?.pmsGoalsettingsEmployees[0]?.lastUpdatedOn
                                ),
                                "dd/MM/yyyy "
                              )}
                          </div>
                        )
                      }
                    />
                  )}
                </div>
                {eventtData?._pmssettings?.goalslist?.length > 0 ? (
                  <>
                    <div className="detailInner">
                      <div style={{ width: "100%" }}>
                        <table
                          className="tabless tabless-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead
                            style={{
                              backgroundColor: "var(--primary-hover)",
                              borderTop: "1px solid lightgray",
                            }}
                          >
                            <tr>
                              <td>S.no</td>
                              <td>Objective</td>
                              <td>Goal Name</td>
                              <td>Start Date</td>
                              <td>Expected Date of Achievement</td>
                              <td>Goal Threshold</td>
                              <td>Weightage Percentage</td>
                            </tr>
                          </thead>
                          <tbody>
                            {eventtData?._pmssettings?.goalslist?.map(
                              (items, index) => {
                                return (
                                  <tr className="trStyle">
                                    <td>{index + 1}</td>
                                    <td>
                                      {Pending ? (
                                        <EditableInput
                                          text={items.name}
                                          type="text"
                                          id={items.id}
                                          onApiCall={(e) =>
                                            handleApiCall({
                                              name: "Name",
                                              value: e.value,
                                              pk: e.id,
                                              goalId: selectedId?.goalId,
                                            })
                                          }
                                        />
                                      ) : (
                                        items.name
                                      )}

                                      {apiData && (
                                        <p>
                                          API Data: {JSON.stringify(apiData)}
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {Pending ? (
                                        <EditableInput
                                          text={items.description}
                                          type="text"
                                          id={items.id}
                                          onApiCall={(e) =>
                                            handleApiCall({
                                              name: "Description",
                                              value: e.value,
                                              pk: e.id,
                                              goalId: selectedId?.goalId,
                                            })
                                          }
                                        />
                                      ) : (
                                        items.description
                                      )}
                                      {apiData && (
                                        <p>
                                          API Data: {JSON.stringify(apiData)}
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {Pending ? (
                                        <EditableInput
                                          text={
                                            items.startDate &&
                                            format(
                                              new Date(items.startDate),
                                              "dd/MM/yyyy"
                                            )
                                          }
                                          type="date"
                                          id={items.id}
                                          onApiCall={(e) =>
                                            handleApiCall({
                                              name: "StartDate",
                                              value: e.value,
                                              pk: e.id,
                                              goalId: selectedId?.goalId,
                                            })
                                          }
                                        />
                                      ) : (
                                        items.startDate &&
                                        format(
                                          new Date(items.startDate),
                                          "dd/MM/yyyy"
                                        )
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {Pending ? (
                                        <EditableInput
                                          text={
                                            items.expectedEndDate &&
                                            format(
                                              new Date(items.expectedEndDate),
                                              "dd/MM/yyyy"
                                            )
                                          }
                                          type="date"
                                          id={items.id}
                                          onApiCall={(e) =>
                                            handleApiCall({
                                              name: "ExpectedEndDate",
                                              value: e.value,
                                              pk: e.id,
                                              goalId: selectedId?.goalId,
                                            })
                                          }
                                        />
                                      ) : (
                                        items.expectedEndDate &&
                                        format(
                                          new Date(items.expectedEndDate),
                                          "dd/MM/yyyy"
                                        )
                                      )}
                                    </td>
                                    <td>
                                      {Pending ? (
                                        <EditableInput
                                          text={items.kra}
                                          type="number"
                                          id={items.id}
                                          onApiCall={(e) =>
                                            handleApiCall({
                                              name: "Kra",
                                              value: e.value,
                                              pk: e.id,
                                              goalId: selectedId?.goalId,
                                            })
                                          }
                                        />
                                      ) : (
                                        items.kra
                                      )}
                                    </td>
                                    <td>
                                      {Pending ? (
                                        <EditableInput
                                          text={items.weightage}
                                          type="number"
                                          id={items.id}
                                          onApiCall={(e) =>
                                            handleApiCall({
                                              name: "Weightage",
                                              value: e.value,
                                              pk: e.id,
                                              goalId: selectedId?.goalId,
                                            })
                                          }
                                        />
                                      ) : (
                                        items.weightage
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              {Pending && (
                <div
                  className="createFootCust"
                  style={{
                    background: "#fff",
                    marginTop: "0",
                  }}
                >
                  <div>
                    <button
                      className="button primaryButton"
                      type="button"
                      style={{ background: "red", color: "white" }}
                      onClick={() => togglePopup("Reject", "red", Arrid)}
                    >
                      Reject
                    </button>
                    <button
                      type="button"
                      className="button secondaryButton"
                      onClick={() => handleApprove("approveAll")}
                      style={{
                        background: "var(--primary)",
                        color: "white",
                      }}
                    >
                      Approve
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Records"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={typeAction}
          content={
            <>
              <Remark
                data={handleApprove}
                type={typeAction}
                id={selId}
                color={colorAction}
                loading={loading}
                handleClose={togglePopup}
              />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export { SpecificDetails };


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest, postRequestForm } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";

import { useEffect } from "react";
import { CreateTaxStructureComp } from "./CreateTaxStructureComp";

const CreateTaxStructure = (props) => {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);
  const data = state?.id;
  const [editFormData, seEditFormData] = useState(null);
  const getDetails = async () => {
    try {
      const result = await getRequest(
        `IncomeTaxDeclarationSettings/StructureDetails?StructureId=${data}`
      );
      seEditFormData(result?.data);
      let comp = [];
      for (let i = 0; i < result.data.taxStructureComponent.length; i++) {
        let subComp = [];
        let a = result.data.taxStructureComponent[i];
        for (let k = 0; k < a.subComponents.length; k++) {
          subComp.push({
            "SubComponentName": a.subComponents[k].subComponentName,
            "SubDisplayName": a.subComponents[k].subDisplayName,
            "SubLimitAmount": a.subComponents[k].subLimitAmount,
            "id": a.subComponents[k].id
          })
        }
        comp.push(
          {
            id: result.data.taxStructureComponent[i].id,
            DisplayName: result.data.taxStructureComponent[i].displayName,
            ComponentName: result.data.taxStructureComponent[i].componentName,
            LimitAmount: result.data.taxStructureComponent[i].limitAmount,
            SubComponents: subComp,
          }
        )
      }
      setInitialValues({
        FinancialYear: result.data.financialYear,
        TaxYear: result.data.taxYear,
        TypeOfRegime: result.data.typeOfRegime,
        Description: result.data.description,
        TaxStructureComponent: comp
      })

    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Tax`;
    getDefaultTaxValues();
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "IncomeTaxStructureSettings/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "IncomeTaxStructureSettings/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Edited", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getInitalAnswerValues = () => {
    let returnArray = [];
    (returnArray = [
      {
        ComponentName: "Annual income other than salary",
        DisplayName: "",
        LimitAmount: 0,
        SubComponents: [
          {
            "SubComponentName": "House Rent",
            "SubDisplayName": "House Rent Display",
            "SubLimitAmount": 0
          },
          {
            "SubComponentName": "Conveyance",
            "SubDisplayName": "Conveyance Display",
            "SubLimitAmount": 0
          }
        ],
      }
    ]);

    return returnArray;
  };

  const [initialValues, setInitialValues] = useState({
    FinancialYear: JSON.stringify(new Date().getFullYear()) + "-" + JSON.stringify(new Date().getFullYear() + 1),
    TaxYear: JSON.stringify(new Date().getFullYear()),
    TypeOfRegime: "Old Regime",
    Description: "",
    TaxStructureComponent: getInitalAnswerValues(),
  });



  const validationSchema = Yup.object({
    TaxYear: Yup.string().max(149, " Tax Year must be less than 150 characters").required("Please Tax Year "),
    FinancialYear: Yup.string().max(149, " Financial Year must be less than 150 characters").required("Please Financial Year "),
    TypeOfRegime: Yup.string().required("Please select Type of Regime "),
  });

  const onSubmit = (values) => {
    let dataToSend = {
      ...values
    };
    if (dataToSend.TaxStructureComponent.length == 0) {
      dispatch(showToast({ text: "Tax Structure should have atleast 1 Investment", severity: "error" }));
      return;
    } else {
      for (let i = 0; i < dataToSend.TaxStructureComponent.length; i++) {
        if (!dataToSend.TaxStructureComponent[i].ComponentName || dataToSend.TaxStructureComponent[i].ComponentName == "" || (!dataToSend.TaxStructureComponent[i].LimitAmount && dataToSend.TaxStructureComponent[i].LimitAmount != 0)) {
          dispatch(showToast({ text: "Provide component name and limit amount fields of every Investment correctly", severity: "error" }));
          return;
        }
        if (dataToSend.TaxStructureComponent[i].SubComponents.length == 0) {
          dispatch(showToast({ text: "All investments should have atleast one of sub-component", severity: "error" }));
          return;
        }
        if (dataToSend.TaxStructureComponent[i] && dataToSend.TaxStructureComponent[i].SubComponents && dataToSend.TaxStructureComponent[i].SubComponents.length) {
          let sum = 0;
          for (let j = 0; j < dataToSend.TaxStructureComponent[i].SubComponents.length; j++) {
            sum += dataToSend.TaxStructureComponent[i].SubComponents[j].SubLimitAmount;
            if (!dataToSend.TaxStructureComponent[i].SubComponents[j].SubComponentName || dataToSend.TaxStructureComponent[i].SubComponents[j].SubComponentName == "") {
              dispatch(showToast({ text: "Provide sub-vomponent Name fields of every Investment sub-component correctly", severity: "error" }));
              return;
            }
          }
          if ((!dataToSend.TaxStructureComponent[i].LimitAmount && sum > 0) || (dataToSend.TaxStructureComponent[i].LimitAmount < sum)) {
            dispatch(showToast({ text: `Sum of sub-components' sublimit amounts cannot exceed the investment limit amount, check "${dataToSend.TaxStructureComponent[i].ComponentName}"`, severity: "error" }));
            return;
          }
        }
      }
    }
    if (data) {
      edit(dataToSend);
    } else {
      create(dataToSend);
    }

  };

  const edit = async (payload) => {
    dispatch(isLoading(true));
    setLoading(true);
    const result = await postRequest("IncomeTaxDeclarationSettings/EditStructure", { ...payload, id: data });
    dispatch(isLoading(false));
    setLoading(false);
    returnPage();
  }

  const create = async (payload) => {
    dispatch(isLoading(true));
    setLoading(true);
    const result = await postRequest("IncomeTaxDeclarationSettings/CreateStructure", payload);
    dispatch(isLoading(false));
    setLoading(false);
    returnPage();
  }

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  const getDefaultTaxValues = async () => {
    if (data) return;
    const result = await getRequest("IncomeTaxDeclarationSettings/IncomeTaxComponentDefault");
    if (result.data) {
      let a = [];
      for (let i = 0; i < result.data.length; i++) {
        let sub = [];
        for (let j = 0; j < result.data[i].subComponents.length; j++) {
          sub.push(
            {
              "SubComponentName": result.data[i].subComponents[j].subcomponentName,
              "SubDisplayName": "",
              "SubLimitAmount": result.data[i].subComponents[j].limitAmount,
            }
          )
        }
        a.push({
          ComponentName: result.data[i].componentName,
          DisplayName: "",
          LimitAmount: result.data[i].limitAmount,
          SubComponents: sub,
        })
      }
      setInitialValues({ ...initialValues, TaxStructureComponent: a })
    }

  }

  return (
    <FormCustomFormik
      loading={loading}
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Edit Tax Structure " : "Create Tax Structure "}
      ButtonLabel={data ? "Update Tax Structure " : "Create Tax Structure "}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={<CreateTaxStructureComp setInitialValues={setInitialValues} formValues={formValues} />}
    />
  );
};

export { CreateTaxStructure };

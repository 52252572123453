import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./FormComponent.css";
import { useEffect } from "react";

export const DateRangePicker = (props) => {
  const {
    selectedEnd,
    onChangeEnd,
    onChangeStart,
    selectedStart,
    valuesstart,
    valuesend,
    isMaxDate = true,
  } = props;

  return (
    <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
      {isMaxDate ? (
        <>
          <div style={{ flexGrow: "1" }}>
            <DatePicker
              {...props}
              dateFormat="dd/MM/yyyy"
              className={"datePickerStyle"}
              selected={selectedStart}
              onChange={onChangeStart}
              maxDate={selectedEnd ? selectedEnd : new Date()}
              value={valuesstart}
              showYearDropdown={"true"}
              placeholderText="Select from date"
              dropdownMode={"select"}
            />
          </div>
          :
          <div style={{ flexGrow: "1" }}>
            <DatePicker
              {...props}
              showYearDropdown={"true"}
              placeholderText="Select to date"
              className={"datePickerStyle"}
              dropdownMode={"select"}
              dateFormat="dd/MM/yyyy"
              selected={selectedEnd}
              onChange={onChangeEnd}
              value={valuesend}
              maxDate={new Date()}
              minDate={selectedStart}
            />
          </div>
        </>
      ) : (
        <>
          <div style={{ flexGrow: "1" }}>
            <DatePicker
              {...props}
              dateFormat="dd/MM/yyyy"
              className={"datePickerStyle"}
              selected={selectedStart}
              onChange={onChangeStart}
              value={valuesstart}
              showYearDropdown={"true"}
              placeholderText="Select from date"
              dropdownMode={"select"}
            />
          </div>
          :
          <div style={{ flexGrow: "1" }}>
            <DatePicker
              {...props}
              showYearDropdown={"true"}
              placeholderText="Select to date"
              className={"datePickerStyle"}
              dropdownMode={"select"}
              dateFormat="dd/MM/yyyy"
              selected={selectedEnd}
              onChange={onChangeEnd}
              value={valuesend}
              minDate={selectedStart}
            />
          </div>
        </>
      )}
    </div>
  );
};

import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Details } from "../../DetailsComp/Details";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import StarRatings from "react-star-ratings";
const OnboardingFeedbackDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const getDetails = async () => {

    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `OnBoardingFeedbackForm/OnboardingDetails?Id=${selectedId}`
      );

      setData(result.data);
    } catch (error) {
      // dispatch(
      //   showToast({
      //     text: "Error in fetching Data",
      //     severity: "error",
      //   })
      // );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Onboarding Feedback`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    if (!data)
      getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Onboarding Feedback Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <div className="tabContainerLine"></div>
          </div>
          {loading ? <Loader /> : data ? (
            <div className="detailsEReq" style={{ flexDirection: "column" }}>
              <div className="headingForm"> Feedback Given By</div>
              <div className="detailInner">
                <Details
                  detailTitle={"Employee Name"}
                  Details={data?.employee?.nameWithPersonNumber ?? "--"}
                />
                <Details
                  detailTitle={"Feedback Given"}
                  Details={data?.feedback ?? "--"}
                />
                <Details
                  detailTitle={"Rating"}
                  Details={
                    <StarRatings
                      starEmptyColor={"#BFBFBF"}
                      starRatedColor={"var(--primary)"}
                      rating={parseInt(data?.rating, 10) ?? "--"}
                      starDimension="30px"
                      starSpacing="5px"
                    />
                  }
                />
              </div>
            </div>
          ) : (
            <div className="noDatastyle">
              <Loader />
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Records"}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { OnboardingFeedbackDetails };

import { IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import * as Yup from "yup";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import {
  areaUom,
  currencyUom,
  dataUom,
  electricityUom,
  energyUom,
  forceUom,
  frequencyUom,
  lengthUom,
  lightIntensityUom,
  massUom,
  pressureUom,
  speedUom,
  temperatureUom,
  timeUom,
  typeUom,
  volumeUom,
} from "../../../../Utilities/uomArray";
export const SetGoals = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const data = state?.item;
  //console.log("data", data);
  const [loading, setLoading] = useState(false);
  const [editFormData, seEditFormData] = useState([]);
  const [uomArr, setuomArr] = useState([]);
  const [formValues, setFormValues] = useState();
  const editid = editFormData?._pmsAppraisalCycles?.goalslist;

  const getGoalslist = () => {
    let returnArray = [
      {
        Name: "",
        Description: "",
        StartDate: "",
        ExpectedEndDate: "",
        Kra: "",
        Weightage: "",
        uom: "",
        typeUom: "",
      },
    ];
    if (editid?.length > 0) {
      returnArray = [];
      editid?.map((item) => {
        returnArray.push({
          Name: item.name,
          Description: item.description,
          StartDate: item.startDate ? new Date(item.startDate) : null,
          ExpectedEndDate: item.expectedEndDate
            ? new Date(item.expectedEndDate)
            : null,
          Kra: item.kra,
          Weightage: item.weightage,
        });
        return null;
      });
    }
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({
    totalWeightage: null,
    Name: "",
    Goalslist: getGoalslist(),
  });
  //console.log("data", editid);
  const returnPage = () => {
    navigate(-1);
  };
  console.log("object areaUom", areaUom);
  const handleModeChange = async (index, value) => {
    try {
      if (value === "Length/Distance") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = lengthUom;
          return updatedArray;
        });
      } else if (value === "Mass/Weight") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = massUom;
          return updatedArray;
        });
      } else if (value === "Volume") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = volumeUom;
          return updatedArray;
        });
      } else if (value === "Time") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = timeUom;
          return updatedArray;
        });
      } else if (value === "Temperature") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = temperatureUom;
          return updatedArray;
        });
      } else if (value === "Electricity") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = electricityUom;
          return updatedArray;
        });
      } else if (value === "Energy") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = energyUom;
          return updatedArray;
        });
      } else if (value === "Speed") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = speedUom;
          return updatedArray;
        });
      } else if (value === "Data/Information") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = dataUom;
          return updatedArray;
        });
      } else if (value === "Currency") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = currencyUom;
          return updatedArray;
        });
      } else if (value === "Area") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = areaUom;
          return updatedArray;
        });
      } else if (value === "Pressure") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = pressureUom;
          return updatedArray;
        });
      } else if (value === "Force") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = forceUom;
          return updatedArray;
        });
      } else if (value === "Frequency") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = frequencyUom;
          return updatedArray;
        });
      } else if (value === "Light Intensity") {
        setuomArr((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = lightIntensityUom;
          return updatedArray;
        });
      }
      setInitialValues(() => {
        const arr = { ...formValues };
        arr.Goalslist[index].uom = "";
        return arr;
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occurred in fetching categories",
          severity: "error",
        })
      );
    }
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Goals/ViewGoals?ID=${data.id}`);
      seEditFormData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Set Goals `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);
  const createFunction = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("Goals/ManageGoals", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      //console.log("err",error)
      dispatch(
        showToast({
          text: error?.response?.data ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      returnPage();
    }
  };

  const StyledTableCell = styled(TableCell)({
    padding: "0",
  });
  const StyledTableCont = styled(TableCell)({
    overflow: "revert !important",
  });
  const onSubmit = async (values) => {
    const create = {
      ...values,
      Id: data.id,
      Name: data.name,
      Goalslist: values.Goalslist.map((item) => {
        return {
          ...item,
          StartDate: format(new Date(item?.StartDate), "yyyy-MM-dd"),
          ExpectedEndDate: format(
            new Date(item?.ExpectedEndDate),
            "yyyy-MM-dd"
          ),
        };
      }),
    };
    //console.log("create", create);
    createFunction(create);
  };
  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal" style={{ padding: "24px" }}>
          <div className="headingContainer" style={{ padding: "0px 12px" }}>
            <div
              onClick={() => navigate(-1)}
              style={{ display: "flex" }}
              className="returnToDashboard cursor"
            >
              <div>
                <svg
                  width="20"
                  height="8"
                  viewBox="0 0 20 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                    fill="#1296B0"
                  />
                </svg>
              </div>
              <label className="returnLabelHolder">{data?.name}</label>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div
              style={{
                boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
                overflow: "auto",
                height: "100%",
              }}
            >
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={Yup.object({
                  KpiTemplatelist: Yup.array().of(
                    Yup.object().shape({
                      Name: Yup.string()

                        .max(499, "Name must be less than 500 characters")
                        .required("Please enter the Name"),
                      Description: Yup.string()

                        .max(
                          149,
                          "Description must be less than 150 characters"
                        )
                        .required("Please enter the KPI name"),
                      StartDate: Yup.string().required(
                        "Please Select the Start Date"
                      ),
                      ExpectedEndDate: Yup.string().required(
                        "Please Select the Expected Date"
                      ),
                      Kra: Yup.string()

                        .max(149, "Kra must be less than 150 characters")
                        .required("Please enter the Kra"),
                      Weightage: Yup.string()

                        .max(149, "Weightage must be less than 150 characters")
                        .required("Please enter the Weightage"),
                    })
                  ),
                  totalWeightage: Yup.string()
                    .required("Please enter the Total Weightage")
                    .test(
                      "Is 100 percent",
                      "Total Weightage % should be 100",
                      (value) => Number(value) === 100
                    ),
                })}
              >
                {(formik) => {
                  formik.values.totalWeightage =
                    formik.values.Goalslist?.reduce(
                      (sum, card) => sum + card?.Weightage,
                      0
                    );
                  setFormValues(formik?.values);
                  console.log("object formik?.values", formik?.values);
                  return (
                    <Form
                      style={{
                        height: "100%",
                      }}
                    >
                      <div className="bodyContainer" style={{ height: "100%" }}>
                        <div
                          className="repeatedSectionContainer"
                          style={{ height: "calc( 100% - 65px )" }}
                        >
                          <FieldArray name="Goalslist">
                            {(fieldArrayProps) => {
                              const { push, remove, form } = fieldArrayProps;
                              const { values } = form;
                              const { Goalslist } = values;
                              //console.log("gapo", Goalslist);
                              return (
                                <StyledTableCont>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom labelName={"Sl. No."} />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Objective"}
                                            mandatory={true}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Goal Name"}
                                            mandatory={true}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Start Date"}
                                            mandatory={true}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={
                                              "Expected Date of Achievement"
                                            }
                                            mandatory={true}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Goal Threshold"}
                                            mandatory={true}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Type of UOM"}
                                            mandatory={true}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"UOM"}
                                            mandatory={true}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Weightage Percentage"}
                                            mandatory={true}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          padding="0"
                                          style={{
                                            fontSize: "12px",
                                          }}
                                          align="center"
                                        >
                                          <LabelCustom
                                            labelName={"Add/Remove"}
                                          />
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {Goalslist?.map((Goalslists, index) => (
                                        <TableRow key={index}>
                                          {/* 1st column */}
                                          <StyledTableCell align="center">
                                            {index + 1}
                                          </StyledTableCell>

                                          {/* 2nd column */}
                                          <StyledTableCell>
                                            <TextAreaFormik
                                              name={`Goalslist[${index}].Name`}
                                              placeholder={"Enter Name"}
                                              style={{
                                                height: "56px",
                                                minWidth: "120px",
                                              }}
                                              maxLength={500}
                                            />
                                          </StyledTableCell>

                                          {/* 3rd column */}
                                          <StyledTableCell>
                                            <TextAreaFormik
                                              style={{
                                                height: "56px",
                                                minWidth: "120px",
                                              }}
                                              maxLength={500}
                                              name={`Goalslist[${index}].Description`}
                                              placeholder={"Enter Description"}
                                            />
                                          </StyledTableCell>

                                          {/* 4th column */}
                                          <StyledTableCell>
                                            <DatePickerFormiks
                                              name={`Goalslist[${index}].StartDate`}
                                              minDate={new Date()}
                                              maxDate={
                                                Goalslists.ExpectedEndDate
                                              }
                                            />
                                          </StyledTableCell>

                                          {/* 5th column */}
                                          <StyledTableCell>
                                            <DatePickerFormiks
                                              minDate={Goalslists.StartDate}
                                              name={`Goalslist[${index}].ExpectedEndDate`}
                                            />
                                          </StyledTableCell>

                                          {/* 6th column */}
                                          <StyledTableCell>
                                            <InputCustomFormik
                                              placeholder="Please Enter Threashold"
                                              name={`Goalslist[${index}].Kra`}
                                              type="text"
                                              step="0.01"
                                            />
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <SelectForm
                                              name={`Goalslist[${index}].typeUom`}
                                              options={typeUom}
                                              onChangeProp={(e) => {
                                                form.setFieldValue(
                                                  `Goalslist[${index}].typeUom`,
                                                  e.value
                                                );
                                                handleModeChange(
                                                  index,
                                                  e.value
                                                );
                                              }}
                                            />
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <SelectForm
                                              name={`Goalslist[${index}].uom`}
                                              options={uomArr[index]}
                                            />
                                          </StyledTableCell>
                                          <StyledTableCell>
                                            <InputCustomFormik
                                              placeholder="Please Enter Weightage"
                                              name={`Goalslist[${index}].Weightage`}
                                              type="number"
                                              step="0.01"
                                            />
                                          </StyledTableCell>

                                          {/* 7th column */}
                                          <StyledTableCell
                                            sx={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="remove-btn"
                                              onClick={() =>
                                                Goalslist.length > 1
                                                  ? remove(index)
                                                  : dispatch(
                                                      showToast({
                                                        text: "Atleast One Item is Required ",
                                                        severity: "error",
                                                      })
                                                    )
                                              }
                                            >
                                              -
                                            </button>
                                            <button
                                              type="button"
                                              className="add-btn"
                                              onClick={() =>
                                                push({
                                                  Name: "",
                                                  Description: "",
                                                  StartDate: "",
                                                  ExpectedEndDate: "",
                                                  Kra: "",
                                                  Weightage: "",
                                                })
                                              }
                                            >
                                              +
                                            </button>
                                          </StyledTableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                  <div className="repeatedTotla">
                                    <div>
                                      {" "}
                                      <InputCustomFormik
                                        style={{ display: "none" }}
                                        name={"totalWeightage"}
                                        type="hidden"
                                        step="0.01"
                                        disabled
                                      />
                                    </div>
                                    <LabelCustom
                                      style={{
                                        color:
                                          Goalslist?.reduce(
                                            (sum, card) =>
                                              sum + card?.Weightage,
                                            0
                                          ) <= 100
                                            ? "var(--primary)"
                                            : "red",
                                        fontSize: "13px",
                                      }}
                                      labelName={`Total Weightage (%): ${Goalslist?.reduce(
                                        (sum, card) => sum + card?.Weightage,
                                        0
                                      )}`}
                                    />
                                  </div>
                                </StyledTableCont>
                              );
                            }}
                          </FieldArray>
                        </div>
                        <div
                          className="createFootCust"
                          style={{
                            // backgroundColor: "#f8f8f8f3;",
                            backgroundColor: "#fff",
                            boxShadow: "0px 1px 6px rgb(0 0 0 / 5%);",
                          }}
                        >
                          <div>
                            <div
                              onClick={() => navigate(-1)}
                              className="cancelbtn"
                            >
                              Cancel
                            </div>
                          </div>
                          <div>
                            <button
                              className="button primaryButton"
                              type="submit"
                            >
                              {editid ? "Update Your Goals" : "Set Your Goals"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

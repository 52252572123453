import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import ClockIcon from "../../Assets/ClockIcon.svg";
import CalendarIcon from "../../Assets/CalendarIcon.svg";
import Check from "../../Assets/CheckWhiteVector.svg";
import SearchIcon from "../../Assets/SearchMessageIcon.svg";
import { closeService, loggedOut, switchCompanyAct } from "../../Features";
import { Tabs, Tab } from "@mui/material";
import { HomeDashboard, HomeEngage } from "./Components";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import PremiumContentPageSocial from "./PremiumContentPageSocial";
import { getRequest } from "../../Services/axios";
import { useDispatch } from "react-redux";
import { LoginUser } from "../../Services";
import { Loader } from "../../Components/FormComponent/Loader";

const SwitchCompany = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const navigate = useNavigate(); // Initialize navigate from react-router-dom
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { subMenuList } = useSelector((state) => state.menu);
    const [companies, setComp] = useState([]);
    const [companiesCopy, setCompCopy] = useState([]);
    const { LoggedInUser } = useSelector((state) => state.auth);
    useEffect(() => {
        document.title = `PeopleSol - Select Organisation`;
        return () => {
            document.title = "PeopleSol";
        };
    });
    useEffect(() => {
        getCompanies()
    }, [])
    const getCompanies = async () => {
        const result = await getRequest("GetCompany");
        setComp(result.data)
        setCompCopy(result.data)
    }
    const [currComp, setCurrComp] = useState(null);
    const closeNotifications = () => dispatch(closeService());
    const logout = async () => {
        try {
            // await postRequest("Settings/Logout");
            dispatch(loggedOut());
        } catch (error) {
            dispatch(loggedOut());
        } finally {
            navigate("/login");
        }
    };
    const filterText = (text) => {
        if (!text || text == "") setCompCopy(companies);
        setCompCopy([...companies.filter(item => item.companyName.companyName.toLowerCase().includes(text.toLowerCase()))]);
    }
    const handleLogin = () => {
        if (localStorage.getItem("passKey") && currComp) {
            closeNotifications();
            dispatch(switchCompanyAct());
            setTimeout(() => {
                let loginType = "UserId";
                if (currComp.systemId.includes("@") && currComp.systemId.includes(".")) {
                    loginType = "EmailId";
                }
                if (loginType === "UserId") {
                    LoginUser(
                        { UserId: currComp.systemId, Password: localStorage.getItem("passKey") },
                        null,
                        navigate,
                        dispatch,
                        true,
                        () => { setLoading(false) }
                    );
                } else {
                    LoginUser(
                        { EmailId: currComp.systemId, Password: localStorage.getItem("passKey") },
                        null,
                        navigate,
                        dispatch,
                        true,
                        () => { setLoading(false) }
                    );
                }
            }, 2000)
        }
    }
    return (
        <div className="homeContainer">
            <div style={{ width: 300, height: "90%", width: "94%", marginTop: 20, backgroundColor: "rgba(255, 255, 255, 1)", borderRadius: 8, alignSelf: "center", display: "flex", justifyContent: "space-between", flexDirection: "column", boxShadow: "0px 1px 3px rgba(0,0,0,.4)" }}>
                <div style={{ width: "100%", padding: 10, paddingLeft: 20, paddingRight: 20, borderTopRightRadius: 8, borderTopLeftRadius: 8, backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: "0px 1px 3px rgba(0,0,0,.4)", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ color: "rgba(18, 150, 176, 1)", fontSize: 16 }}>
                        Choose a Company to Continue
                    </div>
                    <div style={{ borderStyle: "solid", borderWidth: 2, borderTop: "none", borderLeft: "none", borderRight: "none", borderColor: "rgba(18, 150, 176, 1)", display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: 5 }}>
                        <input onChange={(e) => { filterText(e.target.value) }} placeholder="Search" style={{ outline: "none", borderStyle: "none", width: 150 }} />
                        <img src={SearchIcon} />
                    </div>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", height: "88%", overflow: "auto", flexWrap: "wrap" }}>
                    {companiesCopy.map((company) => (
                        <div onClick={() => setCurrComp(company)} style={{ boxShadow: "0px 1px 3px rgba(0,0,0,.4)", backgroundColor: "rgba(255, 255, 255, 1)", borderRadius: 8, padding: 10, paddingBottom: 30, margin: 20, display: "flex", flexDirection: "column", height: "fit-content", width: 300, borderStyle: "solid", borderColor: "rgba(18, 150, 176, 1)", borderWidth: currComp == company ? 2 : 0, alignItems: "center" }}>
                            <div style={{ backgroundColor: currComp == company ? "rgba(18, 150, 176, 1)" : "white", borderRadius: 70, width: 20, height: 20, borderWidth: 2, borderStyle: "solid", borderColor: currComp == company ? "rgba(18, 150, 176, 1)" : "rgba(191, 191, 191, 1)", display: "flex", justifyContent: "center", alignItems: "center", alignSelf: "flex-end" }}>
                                {currComp == company ? <img style={{ height: 12, width: 12 }} src={Check} /> : null}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                                <img style={{ height: 50, marginRight: 10, marginBottom: 10 }} src={process.env.REACT_APP_.BASE_URL + "/Core/Uploads/Company/" + company.companyCode + "/Logo/" + company.companyName.logo}></img>
                                <div>{company.companyName.companyName}</div>
                            </div>
                        </div>
                    ))}
                </div>
                {loading ? <div style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
                    <Loader />
                </div> : null}
                <div style={{ width: "100%", paddingLeft: 20, paddingRight: 20, padding: 4, borderBottomRightRadius: 8, borderBottomLeftRadius: 8, backgroundColor: "rgba(255, 255, 255, 1)", borderStyle: "solid", borderWidth: 1, borderColor: "transparent", borderTopColor: "rgba(230, 230, 230, 1)", display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ display: "flex", flexDirection: "row", }}>
                        <div onClick={() => { logout(); closeNotifications() }} className="button secondaryButton" style={{ border: "1px solid rgba(18, 150, 176, 1)" }}>Back to login</div>
                        <div onClick={() => { handleLogin(); setLoading(true) }} className="button primaryButton" style={{ border: "1px solid rgba(18, 150, 176, 1)" }}>Continue</div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export { SwitchCompany };
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { isLoading, showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import { format } from "date-fns";
import "./change.css";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Timeline } from "../../../HRMS/Components/DetailsComp/TimeLine";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { Comments } from "../../../HRMS/Components/DetailsComp/Comments";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import OwnerApproval from "./Form/OwnerApproval";
import "./change.css";
import { HodApproval } from "./Form/HodApproval";
import { ITdirectorApproval } from "./Form/ITdirectorApproval";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import { useSelector } from "react-redux";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { ProblemForm } from "../ItDeskComponent/Form/ProblemForm";
import { PIreview } from "./Form/PIreview";
import { HodReview } from "./Form/HodReview";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SummaryDetails } from "../../../HRMS/Components/DetailsComp/SummaryDetails";
import { ITImplementation } from "./Form/ITImplementation";

const ChangeDetails = () => {
  useEffect(() => {
    document.title = `PeopleSol - Change Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [extra, setExtra] = useState("");
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const { LoggedInUser } = useSelector((state) => state.auth);
  const closeDetails = () => {
    returnPage();
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const togglePopup = (type, color, aid) => {
    setTypeAction(type);
    setColorAction(color);
    setExtra(Number(aid));
    setIsOpen((prev) => !prev);
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  let color1 = "#E7F8EC";
  let letter1 = "#0DB53C";
  let color2 = "#E7F8EC";
  let letter2 = "#0DB53C";
  let color3 = "#E7F8EC";
  let letter3 = "#0DB53C";
  let color4 = "#E7F8EC";
  let letter4 = "#0DB53C";
  let color5 = "#E7F8EC";
  let letter5 = "#0DB53C";
  let color6 = "#E7F8EC";
  let letter6 = "#0DB53C";

  let color20 = "#E7F8EC";
  let letter20 = "#0DB53C";

  let color21 = "#E7F8EC";
  let letter21 = "#0DB53C";

  if (data?.itsmChangeRequest?.isApprovedByCo == null) {
    color1 = "#D8F0F2";
    letter1 = "var(--primary)";
  } else if (data?.itsmChangeRequest?.isApprovedByCo === false) {
    color1 = "#FDD2D2";
    letter1 = "#F71D1D";
  }

  if (
    data?.itsmChangeRequest?.changeType === "Emergency" ||
    data?.itsmChangeRequest?.changeType === "Standard"
  ) {
    color2 = "#E7F8EC";
    letter2 = "#0DB53C";
  } else {
    if (data?.hodid === LoggedInUser.id) {
      if (data?.isapprovedbyHOD === true && data?.approved === "Yes") {
        color2 = "#E7F8EC";
        letter2 = "#0DB53C";
      } else if (data?.isapprovedbyHOD === false && data?.approved === "No") {
        color2 = "#FDD2D2";
        letter2 = "#F71D1D";
      } else if (data?.itsmChangeRequest?.isApprovedByCo === true) {
        color2 = "#D8F0F2";
        letter2 = "var(--primary)";
      } else {
        color2 = "#D8F0F2";
        letter2 = "var(--primary)";
      }
    } else {
      if (data?.iSapprovalNeed === false) {
        color2 = "#D8F0F2";
        letter2 = "var(--primary)";
      } else {
        if (data?.isapprovedbyHOD === true && data?.approved === "Yes") {
          color2 = "#E7F8EC";
          letter2 = "#0DB53C";
        } else if (data?.isapprovedbyHOD === false && data?.approved === "No") {
          color2 = "#FDD2D2";
          letter2 = "#F71D1D";
        } else {
          color2 = "#D8F0F2";
          letter2 = "var(--primary)";
        }
      }
    }
  }

  if (data?.itsmChangeRequest?.changeType === "Standard") {
    color3 = "#E7F8EC";
    letter3 = "#0DB53C";
  } else {
    if (
      data?.itsmChangeRequest?.isApprovedByManager === true &&
      data?.itsmChangeRequest?.directorDecision == null &&
      data?.itsmChangeRequest?.status !== "Cancelled" &&
      data?.itsmChangeRequest?.status !== "Rejected"
    ) {
      if (
        data?.directorID === LoggedInUser?.id &&
        data?.isapprovedbyHOD === true &&
        data?.approved === "Yes" &&
        data?.itsmChangeRequest?.directorDecision == null
      ) {
        color3 = "#D8F0F2";
        letter3 = "var(--primary)";
      } else if (data?.itsmChangeRequest?.isApprovedByManager === true) {
        if (
          data?.itsmChangeRequest?.directorDecision === "Approved" ||
          data?.itsmChangeRequest?.directorDecision ===
            "Approved with Conditions"
        ) {
          color3 = "#E7F8EC";
          letter3 = "#0DB53C";
        } else if (data?.itsmChangeRequest?.directorDecision === "Rejected") {
          color3 = "#FDD2D2";
          letter3 = "#F71D1D";
        } else {
          color3 = "#D8F0F2";
          letter3 = "var(--primary)";
        }
      } else {
        color3 = "#D8F0F2";
        letter3 = "var(--primary)";
      }
    } else {
      if (
        data?.itsmChangeRequest?.directorDecision === "Approved" ||
        data?.itsmChangeRequest?.directorDecision === "Approved with Conditions"
      ) {
        color3 = "#E7F8EC";
        letter3 = "#0DB53C";
      } else if (data?.itsmChangeRequest?.directorDecision === "Rejected") {
        color3 = "#FDD2D2";
        letter3 = "#F71D1D";
      } else {
        color3 = "#D8F0F2";
        letter3 = "var(--primary)";
      }
    }
  }

  if (
    data?.itsmChangeRequest?.implementerStatus === "Change successful" ||
    data?.itsmChangeRequest?.implementerStatus === "Roll back successful"
  ) {
    color4 = "#E7F8EC";
    letter4 = "#0DB53C";
  } else if (data?.itsmChangeRequest?.implementerStatus === "Deferred") {
    color4 = "#FDD2D2";
    letter4 = "#F71D1D";
  } else {
    color4 = "#D8F0F2";
    letter4 = "var(--primary)";
  }

  if (
    (data?.itsmChangeRequest?.implementerStatus === "Change successful" ||
      data?.itsmChangeRequest?.implementerStatus === "Roll back successful") &&
    data?.itsmChangeRequest?.status !== "Cancelled" &&
    data?.itsmChangeRequest?.status !== "Rejected"
  ) {
    if (
      data?.itsmChangeRequest?.ticketCategory.changeOwnerEmployee.Id ===
        LoggedInUser?.id &&
      data?.itsmChangeRequest?.status !== "Closed" &&
      data?.itsmChangeRequest?.status !== "Rolled back" &&
      data?.itsmChangeRequest?.finalRemarks == null
    ) {
      color5 = "#D8F0F2";
      letter5 = "var(--primary)";
    } else {
      if (data?.itsmChangeRequest?.coFinalApproval === true) {
        color5 = "#E7F8EC";
        letter5 = "#0DB53C";
      } else {
        color5 = "#D8F0F2";
        letter5 = "var(--primary)";
      }
    }
  } else if (
    data?.itsmChangeRequest?.coFinalApproval === false &&
    data?.itsmChangeRequest?.status === "Rejected" &&
    data?.itsmChangeRequest?.finalRemarks != null
  ) {
    color5 = "#FDD2D2";
    letter5 = "#F71D1D";
  } else {
    color5 = "#D8F0F2";
    letter5 = "var(--primary)";
  }

  if (data?.itsmChangeRequest?.status !== "Closed") {
    color6 = "#D8F0F2";
    letter6 = "var(--primary)";
  } else {
    if (
      (data?.itsmChangeRequest?.implementerStatus === "Change successful" ||
        data?.itsmChangeRequest?.implementerStatus ===
          "Roll back successful") &&
      data?.itsmChangeRequest?.status !== "Cancelled" &&
      data?.itsmChangeRequest?.status !== "Rejected"
    ) {
      if (
        data?.hodid === LoggedInUser?.id &&
        data?.itsmChangeRequest?.coFinalApproval === true &&
        data?.itsmChangeRequest?.status !== "Closed" &&
        data?.itsmChangeRequest?.status !== "Rolled back"
      ) {
        color6 = "#D8F0F2";
        letter6 = "var(--primary)";
      } else {
        if (data?.itsmChangeRequest?.hodFinalApproval === true) {
          color6 = "#E7F8EC";
          letter6 = "#0DB53C";
        } else {
          color6 = "#D8F0F2";
          letter6 = "var(--primary)";
        }
      }
    } else if (
      data?.itsmChangeRequest?.hodFinalApproval === false &&
      data?.itsmChangeRequest?.status === "Rejected" &&
      data?.itsmChangeRequest?.hodFinalRemarks != null
    ) {
      color6 = "#FDD2D2";
      letter6 = "#F71D1D";
    } else {
      color5 = "#D8F0F2";
      letter5 = "var(--primary)";
    }
  }
  //console.log("data?.implementer", data?.implementer);
  color20 = "#D8F0F2";
  letter20 = "var(--primary)";

  const SAPBusinessApproval = data?.sapBusinessApproval;

  color21 = "#D8F0F2";
  letter21 = "var(--primary)";

  const MDApproval = data?.mdApproval;

  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `ITsmChangeRequest/Details?ID=${selectedId}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Change Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: 10,
                borderRadius: 8,
                boxShadow: "0px 1px 4px rgba(0, 0, 0, .1)",
              }}
            >
              <div
                onClick={() => {
                  setTabIndex(0);
                }}
                style={{
                  backgroundColor: tabIndex === 0 ? "#0db53c" : "#E7F8EC",
                  color: tabIndex === 0 ? "white" : "#0db53c",
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 4,
                  marginRight: 5,
                }}
              >
                Request Details
              </div>
              <div
                onClick={() => {
                  setTabIndex(1);
                }}
                style={{
                  backgroundColor: tabIndex === 1 ? letter1 : color1,
                  color: tabIndex === 1 ? "white" : letter1,
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 4,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                Approvals
              </div>
              <div
                onClick={() => {
                  setTabIndex(2);
                }}
                style={{
                  backgroundColor: tabIndex === 2 ? letter4 : color4,
                  color: tabIndex === 2 ? "white" : letter4,
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 4,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                Implementation
              </div>
              <div
                onClick={() => {
                  setTabIndex(3);
                }}
                style={{
                  backgroundColor: tabIndex === 3 ? letter5 : color5,
                  color: tabIndex === 3 ? "white" : letter5,
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 4,
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                Review
              </div>
              <div
                onClick={() => {
                  setTabIndex(4);
                }}
                style={{
                  backgroundColor: tabIndex === 4 ? letter5 : color5,
                  color: tabIndex === 4 ? "white" : letter5,
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 4,
                  marginLeft: 5,
                }}
              >
                Notification
              </div>
            </div>
            {/* <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{
                  minWidth: 130,
                  backgroundColor: "#E7F8EC",
                  color: "#0DB53C",
                  borderRadius: 8,
                  marginLeft: 10, right: 10,
                  padding: 0,
                }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActiv"}`}
                  >
                    Request Details
                  </span>
                }
              />
              <Tab
                style={{
                  minWidth: 130,
                  backgroundColor: color1,
                  color: letter1,
                  borderRadius: 8,
                  marginLeft: 10, right: 10,

                }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActiv"}`}
                  >
                    Approvals
                  </span>
                }
              />
              <Tab
                style={{
                  minWidth: 130,
                  backgroundColor: color4,
                  color: letter4,
                  borderRadius: 8,
                  marginLeft: 10, right: 10,

                }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActiv"}`}
                  >
                    Implementation
                  </span>
                }
              />
              <Tab
                style={{
                  minWidth: 130,
                  backgroundColor: color5,
                  color: letter5,
                  borderRadius: 8,
                  marginLeft: 10, right: 10,

                }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 3 && "tabActiv"}`}
                  >
                    Review
                  </span>
                }
              />
              <Tab
                style={{
                  minWidth: 130,
                  backgroundColor: "var(--primary-hover)",
                  color: "var(--primary",
                  borderRadius: 8,
                  marginLeft: 10, right: 10,

                }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 4 && "tabActiv"}`}
                  >
                    Notification
                  </span>
                }
              />
            </Tabs> */}
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 && (
                <div
                  className="detailsEReq"
                  style={{ flexDirection: "column" }}
                >
                  <div className="headingForm" style={{ marginBottom: 20 }}>
                    Request Details
                  </div>
                  <div className="detailInner padInner ">
                    <Details
                      detailTitle={"Change Request Number"}
                      Details={data?.itsmChangeRequest?.ticketNumber}
                    />

                    <Details
                      detailTitle={"Status"}
                      Details={
                        <div
                          style={{
                            backgroundColor: getStatusColor(
                              data?.itsmChangeRequest?.status
                            )?.split(",")?.[0],
                            color: getStatusColor(
                              data?.itsmChangeRequest?.status
                            )?.split(",")?.[1],
                            cursor: "default",
                            padding: "6px",
                            borderRadius: "6px",
                          }}
                        >
                          {data?.itsmChangeRequest?.status}
                        </div>
                      }
                    />
                    <Details
                      detailTitle={"Change Required In?"}
                      Details={data?.itsmChangeRequest?.ticketCategory?.name}
                    />
                    <Details
                      detailTitle={"Nature of Change"}
                      Details={data?.itsmChangeRequest?.changeType}
                    />
                    <Details
                      detailTitle={"Change Request Priority"}
                      Details={data?.itsmChangeRequest?.priority}
                    />
                    <Details
                      detailTitle={"Change Request Document"}
                      Details={
                        <UploadDetail
                          filepath={`ChangeRequest/${
                            data?.itsmChangeRequest?.supportingDocument !== null
                              ? data?.itsmChangeRequest?.supportingDocument
                              : "-"
                          }`}
                        />
                      }
                    />
                    <Details
                      detailTitle={"Reason/Justification for Change "}
                      Details={data?.itsmChangeRequest?.reason}
                    />
                    <Details
                      detailTitle={"Description of Change"}
                      Details={data?.itsmChangeRequest?.descriptionOfchange}
                    />
                    {data?.itsmChangeRequest?.referredBackRemarks != null && (
                      <Details
                        detailTitle={"Referreback Remarks "}
                        Details={data?.itsmChangeRequest?.referredBackRemarks}
                      />
                    )}
                    <Details
                      detailTitle={"Created On"}
                      Details={
                        data?.itsmChangeRequest?.createdOn
                          ? format(
                              new Date(data?.itsmChangeRequest?.createdOn),
                              "dd/MM/yyyy hh:mm a"
                            )
                          : "-"
                      }
                    />
                    <Details
                      detailTitle={"Last Updated On"}
                      Details={
                        data?.itsmChangeRequest?.createdOn
                          ? format(
                              new Date(data?.itsmChangeRequest?.lastUpdatedOn),
                              "dd/MM/yyyy hh:mm a"
                            )
                          : "-"
                      }
                    />
                  </div>
                  <div className="headingForm" style={{ marginBottom: 20 }}>
                    {" "}
                    Requester's Details
                  </div>
                  <div className="detailInner padInner">
                    <Details
                      detailTitle={"Requester Name"}
                      Details={`${data?.itsmChangeRequest?.employee?.name} ${
                        data?.itsmChangeRequest?.employee?.lastName ?? ""
                      }`}
                    />
                    <Details
                      detailTitle={"Requester's Manager"}
                      Details={data?.itsmChangeRequest?.employee?.rO_Name}
                    />
                    <Details
                      detailTitle={"Requester's HOD"}
                      Details={
                        data?.hod === "-"
                          ? data?.itsmChangeRequest?.employee?.fhoD_Name
                          : data?.hod
                      }
                    />
                    <Details
                      detailTitle={"Requester Designation"}
                      Details={data?.itsmChangeRequest?.employee?.designation}
                    />
                    <Details
                      detailTitle={"Requester Department"}
                      Details={data?.itsmChangeRequest?.employee?.department}
                    />
                    <Details
                      detailTitle={"Requester Email"}
                      Details={data?.itsmChangeRequest?.employee?.email}
                    />
                    <Details
                      detailTitle={"Contact Number"}
                      Details={
                        data?.itsmChangeRequest?.employee?.personalPhoneNumber
                      }
                    />
                    <Details
                      detailTitle={"Location"}
                      Details={data?.itsmChangeRequest?.employee?.locationName}
                    />
                  </div>
                </div>
              )}
              {tabIndex === 1 && (
                <div
                  className="detailsEReq"
                  style={{
                    boxShadow: "0px 2px 2px rgb(0 0 0 / 2%)",
                    borderRadius: "8px",
                    gap: "8px",
                  }}
                >
                  <div style={{ padding: "8px" }}>
                    <Accordion
                      sx={{
                        boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
                      }}
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          sx={{
                            width: "33%",
                            flexShrink: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                          }}
                        >
                          Change Owner Approval
                        </Typography>
                        {data?.itsmChangeRequest?.changeType === "Emergency" ? (
                          <SummaryDetails
                            detailTitle={" Status"}
                            style={{ width: "23%" }}
                            Details={
                              <div
                                style={{
                                  backgroundColor:
                                    getStatusColor("Pre-Approved")?.split(
                                      ","
                                    )?.[0],
                                  color:
                                    getStatusColor("Pre-Approved")?.split(
                                      ","
                                    )?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  minWidth: "55px",
                                }}
                              >
                                {"Pre-Approved"}
                              </div>
                            }
                          />
                        ) : data?.itsmChangeRequest?.isApprovedByCo ? (
                          <>
                            <SummaryDetails
                              detailTitle={" Status"}
                              style={{ width: "23%" }}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Approved")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Approved")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Approved"}
                                </div>
                              }
                            />
                          </>
                        ) : data?.itsmChangeRequest?.isApprovedByCo ===
                          false ? (
                          <SummaryDetails
                            detailTitle={" Status"}
                            style={{ width: "23%" }}
                            Details={
                              <div
                                style={{
                                  backgroundColor:
                                    getStatusColor("Rejected")?.split(",")?.[0],
                                  color:
                                    getStatusColor("Rejected")?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  minWidth: "55px",
                                }}
                              >
                                {"Rejected"}
                              </div>
                            }
                          />
                        ) : (
                          <SummaryDetails
                            detailTitle={" Status"}
                            style={{ width: "23%" }}
                            Details={
                              <div
                                style={{
                                  backgroundColor:
                                    getStatusColor("Pending")?.split(",")?.[0],
                                  color:
                                    getStatusColor("Pending")?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  minWidth: "55px",
                                }}
                              >
                                {"Pending"}
                              </div>
                            }
                          />
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <div
                          style={{
                            background: "white",
                            borderTop: ".75px solid #b5b5b5",
                          }}
                        >
                          {data.itsmChangeRequest.ticketCategory
                            ?.changeOwnerEmployeeId === LoggedInUser?.id &&
                          data?.itsmChangeRequest?.status !== "Cancelled" &&
                          data?.itsmChangeRequest?.status !==
                            "Referred back" ? (
                            <div style={{ width: "100%" }}>
                              {data?.itsmChangeRequest?.changeType !==
                                "Emergency" && (
                                <OwnerApproval
                                  getDetails={getDetails}
                                  data={data}
                                />
                              )}
                            </div>
                          ) : (
                            <>
                              <div
                                className="headingForm"
                                style={{ marginBottom: 20 }}
                              >
                                Change Owner Details
                              </div>
                              <div className="detailInner padInner">
                                <Details
                                  detailTitle={"Change Owner Name"}
                                  Details={
                                    data?.itsmChangeRequest?.ticketCategory
                                      ?.changeOwnerEmployee
                                      ?.nameWithPersonNumber
                                  }
                                />
                                {data?.itsmChangeRequest?.changeType ===
                                "Emergency" ? (
                                  <Details
                                    detailTitle={"Change Owner Approval Status"}
                                    Details={
                                      <div
                                        style={{
                                          backgroundColor:
                                            getStatusColor(
                                              "Pre-Approved"
                                            )?.split(",")?.[0],
                                          color:
                                            getStatusColor(
                                              "Pre-Approved"
                                            )?.split(",")?.[1],
                                          cursor: "default",
                                          padding: "6px",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        {"Pre-Approved"}
                                      </div>
                                    }
                                  />
                                ) : data?.itsmChangeRequest?.isApprovedByCo ? (
                                  <>
                                    <Details
                                      detailTitle={
                                        "Change Owner Approval Status"
                                      }
                                      Details={
                                        <div
                                          style={{
                                            backgroundColor:
                                              getStatusColor("Approved")?.split(
                                                ","
                                              )?.[0],
                                            color:
                                              getStatusColor("Approved")?.split(
                                                ","
                                              )?.[1],
                                            cursor: "default",
                                            padding: "6px",
                                            borderRadius: "6px",
                                          }}
                                        >
                                          {"Approved"}
                                        </div>
                                      }
                                    />
                                    <Details
                                      detailTitle={
                                        "Change Owner Approving Date"
                                      }
                                      Details={format(
                                        new Date(
                                          data?.itsmChangeRequest?.changeOwnerApprovingDate
                                        ),
                                        "dd/MM/yyyy hh:mm a"
                                      )}
                                    />
                                  </>
                                ) : data?.itsmChangeRequest?.isApprovedByCo ===
                                  false ? (
                                  <Details
                                    detailTitle={"Change Owner Approval Status"}
                                    Details={
                                      <div
                                        style={{
                                          backgroundColor:
                                            getStatusColor("Rejected")?.split(
                                              ","
                                            )?.[0],
                                          color:
                                            getStatusColor("Rejected")?.split(
                                              ","
                                            )?.[1],
                                          cursor: "default",
                                          padding: "6px",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        {"Rejected"}
                                      </div>
                                    }
                                  />
                                ) : (
                                  <Details
                                    detailTitle={"Change Owner Approval Status"}
                                    Details={
                                      <div
                                        style={{
                                          backgroundColor:
                                            getStatusColor("Pending")?.split(
                                              ","
                                            )?.[0],
                                          color:
                                            getStatusColor("Pending")?.split(
                                              ","
                                            )?.[1],
                                          cursor: "default",
                                          padding: "6px",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        {"Pending"}
                                      </div>
                                    }
                                  />
                                )}
                              </div>
                              <div
                                className="headingForm"
                                style={{ marginBottom: 20 }}
                              >
                                BusinessAnalysis
                              </div>
                              <div className="detailInner padInner">
                                <Details
                                  detailTitle={"Business Impact Type"}
                                  Details={
                                    data?.itsmChangeRequest?.businessImpact
                                  }
                                />
                                <Details
                                  detailTitle={"Business Impact Description"}
                                  Details={
                                    data?.itsmChangeRequest?.descriptionOfImpact
                                  }
                                />
                                <Details
                                  detailTitle={"Overall impact Type"}
                                  Details={
                                    data?.itsmChangeRequest?.overallImpact
                                  }
                                />
                                <Details
                                  detailTitle={"Overall Impact Description"}
                                  Details={
                                    data?.itsmChangeRequest
                                      ?.impactAssociatedWithProposeChange
                                  }
                                />
                                <Details
                                  detailTitle={
                                    "Risk associated with propose change"
                                  }
                                  Details={
                                    data?.itsmChangeRequest
                                      ?.riskAssociatedWithProposeChange
                                  }
                                />
                              </div>
                              <div
                                className="headingForm"
                                style={{ marginBottom: 20 }}
                              >
                                Schedule & Cost Analysis
                              </div>
                              <div className="detailInner padInner">
                                <Details
                                  detailTitle={"Cost Impact Type"}
                                  Details={data?.itsmChangeRequest?.costImpact}
                                />
                                <Details
                                  detailTitle={"Cost Impact Description "}
                                  Details={
                                    data?.itsmChangeRequest?.descriptionByOwner
                                  }
                                />
                                <Details
                                  detailTitle={"Estimated cost (In Rupees)"}
                                  Details={
                                    data?.itsmChangeRequest?.estimatedCost
                                  }
                                />
                                {data?.itsmChangeRequest
                                  ?.estimatedTimetoComplete && (
                                  <Details
                                    detailTitle={"Estimated Completion Date "}
                                    Details={format(
                                      new Date(
                                        data?.itsmChangeRequest?.estimatedTimetoComplete
                                      ),
                                      "dd/MM/yyyy hh:mm "
                                    )}
                                  />
                                )}
                              </div>
                              <div
                                className="headingForm"
                                style={{ marginBottom: 20 }}
                              >
                                Implementation Analysis
                              </div>
                              <div className="detailInner padInner">
                                <Details
                                  detailTitle={"Technology Impact Type"}
                                  Details={
                                    data?.itsmChangeRequest?.technologyImpact
                                  }
                                />
                                <Details
                                  detailTitle={"Technology Impact Description"}
                                  Details={data?.itsmChangeRequest?.remarksByCo}
                                />

                                <Details
                                  detailTitle={"Test Plan Document"}
                                  Details={
                                    <UploadDetail
                                      filepath={`ChangeRequest/${data?.itsmChangeRequest?.testplanDocument}`}
                                    />
                                  }
                                />
                                <Details
                                  detailTitle={"Test Plan Description"}
                                  Details={
                                    data?.itsmChangeRequest?.testplandescription
                                  }
                                />
                                <Details
                                  detailTitle={"Roll-back Plan Description"}
                                  Details={
                                    data?.itsmChangeRequest
                                      ?.rollBackPlanDescription
                                  }
                                />

                                <Details
                                  detailTitle={"Roll-back Plan Document"}
                                  Details={
                                    <UploadDetail
                                      filepath={`ChangeRequest/${data?.itsmChangeRequest?.rollBackplanDocument}`}
                                    />
                                  }
                                />
                                <Details
                                  detailTitle={
                                    "Resource Required For Implementation"
                                  }
                                  Details={
                                    data?.itsmChangeRequest
                                      ?.resourcerForChangeImplementation
                                  }
                                />
                                <Details
                                  detailTitle={
                                    "Downtime Required For Implementation"
                                  }
                                  Details={
                                    data?.itsmChangeRequest?.downtimeHours
                                  }
                                />
                                {data?.itsmChangeRequest?.implementerStatus ==
                                  null &&
                                (data?.itsmChangeRequest?.directorDecision ===
                                  "Approved" ||
                                  data?.itsmChangeRequest?.directorDecision ===
                                    "Approved with Conditions") &&
                                data?.itsmChangeRequest?.ticketCategory
                                  .changeOwnerEmployeeId == LoggedInUser.id &&
                                data?.itsmChangeRequest?.status !==
                                  "Cancelled" &&
                                data?.itsmChangeRequest?.status !==
                                  "Rejected" ? (
                                  <>
                                    <Details
                                      detailTitle={"Implementation assign to"}
                                      Details={data?.teamName}
                                    />
                                    {data?.itsmChangeRequest
                                      ?.implementerStatus != null && (
                                      <Details
                                        detailTitle={"Change Schedule"}
                                        Details={
                                          data?.itsmChangeRequest
                                            ?.implementationTimeline
                                        }
                                      />
                                    )}
                                  </>
                                ) : (
                                  (data?.itsmChangeRequest?.directorDecision ==
                                    "Approved" ||
                                    data?.itsmChangeRequest
                                      ?.directorDecision ===
                                      "Approved with Conditions") && (
                                    <>
                                      <Details
                                        detailTitle={"Implementation assign to"}
                                        Details={data?.teamName}
                                      />
                                      <Details
                                        detailTitle={"Implementation Timeline"}
                                        Details={
                                          data?.itsmChangeRequest
                                            ?.implementationTimeline
                                        }
                                      />
                                    </>
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <Accordion
                      sx={{
                        boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
                      }}
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography
                          sx={{
                            width: "33%",
                            flexShrink: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                          }}
                        >
                          HOD Approval
                        </Typography>
                        {data?.itsmChangeRequest?.changeType === "Standard" ||
                        data?.itsmChangeRequest?.changeType === "Emergency" ? (
                          <SummaryDetails
                            detailTitle={" Status"}
                            style={{ width: "23%" }}
                            Details={
                              <div
                                style={{
                                  backgroundColor:
                                    getStatusColor("Pre-Approved")?.split(
                                      ","
                                    )?.[0],
                                  color:
                                    getStatusColor("Pre-Approved")?.split(
                                      ","
                                    )?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  minWidth: "55px",
                                }}
                              >
                                {"Pre-Approved"}
                              </div>
                            }
                          />
                        ) : data?.hodid === LoggedInUser?.id ? (
                          data?.isapprovedbyHOD === true &&
                          data?.approved === "Yes" ? (
                            <SummaryDetails
                              detailTitle={"Status"}
                              style={{ width: "23%" }}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Approved")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Approved")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Approved"}
                                </div>
                              }
                            />
                          ) : data?.isapprovedbyHOD === false &&
                            data?.approved === "No" ? (
                            <SummaryDetails
                              detailTitle={"Status"}
                              style={{ width: "23%" }}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Rejected")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Rejected")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Rejected"}
                                </div>
                              }
                            />
                          ) : data?.itsmChangeRequest?.isApprovedByCo ===
                            true ? (
                            <SummaryDetails
                              detailTitle={"Status"}
                              style={{ width: "23%" }}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Pending"}
                                </div>
                              }
                            />
                          ) : (
                            <SummaryDetails
                              detailTitle={"Status"}
                              style={{ width: "23%" }}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Pending"}
                                </div>
                              }
                            />
                          )
                        ) : data?.iSapprovalNeed === false ? (
                          <SummaryDetails
                            detailTitle={"Status"}
                            style={{ width: "23%" }}
                            Details={
                              <div
                                style={{
                                  backgroundColor:
                                    getStatusColor("Pending")?.split(",")?.[0],
                                  color:
                                    getStatusColor("Pending")?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  minWidth: "55px",
                                }}
                              >
                                {"Pending"}
                              </div>
                            }
                          />
                        ) : data?.isapprovedbyHOD === true &&
                          data?.approved === "Yes" ? (
                          <SummaryDetails
                            detailTitle={"Status"}
                            style={{ width: "23%" }}
                            Details={
                              <div
                                style={{
                                  backgroundColor:
                                    getStatusColor("Approved")?.split(",")?.[0],
                                  color:
                                    getStatusColor("Approved")?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  minWidth: "55px",
                                }}
                              >
                                {"Approved"}
                              </div>
                            }
                          />
                        ) : data?.isapprovedbyHOD === false &&
                          data?.approved === "No" ? (
                          <SummaryDetails
                            detailTitle={"Status"}
                            style={{ width: "23%" }}
                            Details={
                              <div
                                style={{
                                  backgroundColor:
                                    getStatusColor("Rejected")?.split(",")?.[0],
                                  color:
                                    getStatusColor("Rejected")?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  minWidth: "55px",
                                }}
                              >
                                {"Rejected"}
                              </div>
                            }
                          />
                        ) : (
                          <SummaryDetails
                            detailTitle={"Status"}
                            style={{ width: "23%" }}
                            Details={
                              <div
                                style={{
                                  backgroundColor:
                                    getStatusColor("Pending")?.split(",")?.[0],
                                  color:
                                    getStatusColor("Pending")?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  minWidth: "55px",
                                }}
                              >
                                {"Pending"}
                              </div>
                            }
                          />
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <div
                          style={{
                            background: "white",
                            borderTop: ".75px solid #b5b5b5",
                          }}
                        >
                          {data?.itsmChangeRequest?.changeType === "Standard" ||
                          data?.itsmChangeRequest?.changeType ===
                            "Emergency" ? (
                            <></>
                          ) : data?.hodid === LoggedInUser?.id ? (
                            data?.isapprovedbyHOD === true &&
                            data?.approved === "Yes" ? (
                              <div className="detailInner">
                                <Details
                                  detailTitle={"Approved By"}
                                  Details={data?.hod}
                                />
                                <Details
                                  detailTitle={"Approved On"}
                                  Details={format(
                                    new Date(data?.hoDapprovedTime),
                                    "dd/MM/yyyy hh:mm a"
                                  )}
                                />
                                <Details
                                  detailTitle={"Notes"}
                                  Details={data?.hodRemarks}
                                />
                                <Details
                                  detailTitle={"Estimated Time to Complete"}
                                  Details={format(
                                    new Date(
                                      data?.itsmChangeRequest?.estimatedTimetoComplete
                                    ),
                                    "dd/MM/yyyy hh:mm "
                                  )}
                                />
                                <Details
                                  detailTitle={"Downtime Hours"}
                                  Details={
                                    data?.itsmChangeRequest?.downtimeHours
                                  }
                                />
                              </div>
                            ) : data?.isapprovedbyHOD === false &&
                              data?.approved === "No" ? (
                              <div
                                className="detailInner"
                                style={{
                                  padding: "12px",
                                  margin: "12px",
                                  boxShadow: "none",
                                }}
                              >
                                <Details
                                  detailTitle={"Rejected By"}
                                  Details={data?.hod}
                                />
                                <Details
                                  detailTitle={"Rejected On"}
                                  Details={format(
                                    new Date(data?.hoDapprovedTime),
                                    "dd/MM/yyyy hh:mm a"
                                  )}
                                />
                                <Details
                                  detailTitle={"Notes"}
                                  Details={data?.hodRemarks}
                                />
                              </div>
                            ) : data?.itsmChangeRequest?.isApprovedByCo ===
                              true ? (
                              <HodApproval
                                getDetails={getDetails}
                                data={data}
                              />
                            ) : (
                              <div
                                className="detailInner"
                                style={{
                                  padding: "12px",
                                  margin: "12px",
                                  boxShadow: "none",
                                }}
                              >
                                <Details
                                  detailTitle={"Change Type"}
                                  Details={"-"}
                                />
                                <Details
                                  detailTitle={"Priority"}
                                  Details={"-"}
                                />
                                <Details
                                  detailTitle={"Estimated Time to Complete"}
                                  Details={"-"}
                                />
                                <Details
                                  detailTitle={"Downtime Hours"}
                                  Details={"-"}
                                />
                              </div>
                            )
                          ) : data?.iSapprovalNeed === false ? (
                            <></>
                          ) : data?.isapprovedbyHOD === true &&
                            data?.approved === "Yes" ? (
                            <div className="detailInner">
                              <Details
                                detailTitle={"Approved By"}
                                Details={data?.hod}
                              />
                              <Details
                                detailTitle={"Approved On"}
                                Details={format(
                                  new Date(data?.hoDapprovedTime),
                                  "dd/MM/yyyy hh:mm a"
                                )}
                              />
                              <Details
                                detailTitle={"Notes"}
                                Details={data?.hodRemarks}
                              />
                              <Details
                                detailTitle={"Estimated Time to Complete"}
                                Details={format(
                                  new Date(
                                    data?.itsmChangeRequest?.estimatedTimetoComplete
                                  ),
                                  "dd/MM/yyyy hh:mm a"
                                )}
                              />
                              <Details
                                detailTitle={"Downtime Hours"}
                                Details={data?.itsmChangeRequest?.downtimeHours}
                              />
                            </div>
                          ) : data?.isapprovedbyHOD === false &&
                            data?.approved === "No" ? (
                            <div
                              className="detailInner"
                              style={{
                                padding: "12px",
                                margin: "12px",
                                boxShadow: "none",
                              }}
                            >
                              <Details
                                detailTitle={"Rejected By"}
                                Details={data?.hod}
                              />
                              <Details
                                detailTitle={"Rejected On"}
                                Details={format(
                                  new Date(data?.hoDapprovedTime),
                                  "dd/MM/yyyy hh:mm a"
                                )}
                              />
                              <Details
                                detailTitle={"Notes"}
                                Details={data?.hodRemarks}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <Accordion
                      sx={{
                        boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
                      }}
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                      >
                        <Typography
                          sx={{
                            width: "33%",
                            flexShrink: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                          }}
                        >
                          IT Director Approval
                        </Typography>
                        {data?.itsmChangeRequest?.changeType === "Standard" ? (
                          <>
                            <SummaryDetails
                              detailTitle={"Status"}
                              style={{ width: "23%" }}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Pre-Approved")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Pre-Approved")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Pre-Approved"}
                                </div>
                              }
                            />
                          </>
                        ) : data?.itsmChangeRequest?.changeType ===
                          "Emergency" ? (
                          <>
                            {LoggedInUser?.id === data?.directorID &&
                            data.itsmChangeRequest?.directorDecision == null &&
                            data?.itsmChangeRequest?.status !== "Cancelled" &&
                            data?.itsmChangeRequest?.status !== "Rejected" ? (
                              data?.itsmChangeRequest?.status === "On-Hold" ? (
                                <SummaryDetails
                                  detailTitle={"Status"}
                                  style={{ width: "23%" }}
                                  Details={
                                    <div
                                      style={{
                                        backgroundColor: getStatusColor(
                                          data?.itsmChangeRequest?.status
                                        )?.split(",")?.[0],
                                        color: getStatusColor(
                                          data?.itsmChangeRequest?.status
                                        )?.split(",")?.[1],
                                        cursor: "default",
                                        padding: "6px",
                                        borderRadius: "6px",
                                      }}
                                    >
                                      {data?.itsmChangeRequest?.status}
                                    </div>
                                  }
                                />
                              ) : (
                                <SummaryDetails
                                  detailTitle={"Status"}
                                  style={{ width: "23%" }}
                                  Details={
                                    <div
                                      style={{
                                        backgroundColor:
                                          getStatusColor("Pending")?.split(
                                            ","
                                          )?.[0],
                                        color:
                                          getStatusColor("Pending")?.split(
                                            ","
                                          )?.[1],
                                        cursor: "default",
                                        padding: "6px",
                                        borderRadius: "6px",
                                      }}
                                    >
                                      {"Pending"}
                                    </div>
                                  }
                                />
                              )
                            ) : (
                              <>
                                {data?.itsmChangeRequest?.directorDecision ===
                                  "Approved" ||
                                data?.itsmChangeRequest?.directorDecision ===
                                  "Approved with Conditions" ? (
                                  <>
                                    <SummaryDetails
                                      detailTitle={"Status"}
                                      style={{ width: "23%" }}
                                      Details={
                                        <div
                                          style={{
                                            backgroundColor:
                                              getStatusColor("Approved")?.split(
                                                ","
                                              )?.[0],
                                            color:
                                              getStatusColor("Approved")?.split(
                                                ","
                                              )?.[1],
                                            cursor: "default",
                                            padding: "6px",
                                            borderRadius: "6px",
                                          }}
                                        >
                                          {"Approved"}
                                        </div>
                                      }
                                    />
                                  </>
                                ) : data?.itsmChangeRequest?.directorDecision ==
                                  "Rejected" ? (
                                  <>
                                    <SummaryDetails
                                      detailTitle={"Status"}
                                      style={{ width: "23%" }}
                                      Details={
                                        <div
                                          style={{
                                            backgroundColor:
                                              getStatusColor("Rejected")?.split(
                                                ","
                                              )?.[0],
                                            color:
                                              getStatusColor("Rejected")?.split(
                                                ","
                                              )?.[1],
                                            cursor: "default",
                                            padding: "6px",
                                            borderRadius: "6px",
                                          }}
                                        >
                                          {"Rejected"}
                                        </div>
                                      }
                                    />
                                  </>
                                ) : (
                                  data?.itsmChangeRequest?.directorDecision ===
                                    null &&
                                  data?.itsmChangeRequest?.status ===
                                    "On-Hold" && (
                                    <SummaryDetails
                                      detailTitle={"Status"}
                                      style={{ width: "23%" }}
                                      Details={
                                        <div
                                          style={{
                                            backgroundColor: getStatusColor(
                                              data?.itsmChangeRequest?.status
                                            )?.split(",")?.[0],
                                            color: getStatusColor(
                                              data?.itsmChangeRequest?.status
                                            )?.split(",")?.[1],
                                            cursor: "default",
                                            padding: "6px",
                                            borderRadius: "6px",
                                          }}
                                        >
                                          {data?.itsmChangeRequest?.status}
                                        </div>
                                      }
                                    />
                                  )
                                )}
                              </>
                            )}
                          </>
                        ) : data?.itsmChangeRequest?.isApprovedByManager ===
                            true &&
                          data?.itsmChangeRequest?.directorDecision == null &&
                          data?.itsmChangeRequest?.status !== "Cancelled" &&
                          data?.itsmChangeRequest?.status !== "Rejected" &&
                          data?.approved === "Yes" ? (
                          data.directorID === LoggedInUser.id &&
                          data?.itsmChangeRequest?.directorDecision == null &&
                          data?.itsmChangeRequest?.status !== "Cancelled" &&
                          data?.itsmChangeRequest?.status !== "Rejected" ? (
                            <SummaryDetails
                              detailTitle={"Status"}
                              style={{ width: "23%" }}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Pending"}
                                </div>
                              }
                            />
                          ) : data?.isApprovedByManager ? (
                            <>
                              {data?.itsmChangeRequest?.directorDecision ===
                                "Approved" ||
                              data?.itsmChangeRequest?.directorDecision ===
                                "Approved with Conditions" ? (
                                <>
                                  <SummaryDetails
                                    detailTitle={"Status"}
                                    style={{ width: "23%" }}
                                    Details={
                                      <div
                                        style={{
                                          backgroundColor:
                                            getStatusColor("Approved")?.split(
                                              ","
                                            )?.[0],
                                          color:
                                            getStatusColor("Approved")?.split(
                                              ","
                                            )?.[1],
                                          cursor: "default",
                                          padding: "6px",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        {"Approved"}
                                      </div>
                                    }
                                  />
                                </>
                              ) : data?.itsmChangeRequest?.directorDecision ===
                                "Rejected" ? (
                                <>
                                  <SummaryDetails
                                    detailTitle={"Status"}
                                    style={{ width: "23%" }}
                                    Details={
                                      <div
                                        style={{
                                          backgroundColor:
                                            getStatusColor("Rejected")?.split(
                                              ","
                                            )?.[0],
                                          color:
                                            getStatusColor("Rejected")?.split(
                                              ","
                                            )?.[1],
                                          cursor: "default",
                                          padding: "6px",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        {"Rejected"}
                                      </div>
                                    }
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            data?.itsmChangeRequest?.directorDecision ===
                              null &&
                            data?.itsmChangeRequest?.status === "On-Hold" && (
                              <SummaryDetails
                                detailTitle={"Status"}
                                style={{ width: "23%" }}
                                Details={
                                  <div
                                    style={{
                                      backgroundColor: getStatusColor(
                                        data?.itsmChangeRequest?.status
                                      )?.split(",")?.[0],
                                      color: getStatusColor(
                                        data?.itsmChangeRequest?.status
                                      )?.split(",")?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {data?.itsmChangeRequest?.status}
                                  </div>
                                }
                              />
                            )
                          )
                        ) : (
                          <>
                            {data?.itsmChangeRequest?.directorDecision ===
                              "Approved" ||
                            data?.itsmChangeRequest?.directorDecision ===
                              "Approved with Conditions" ? (
                              <>
                                <SummaryDetails
                                  detailTitle={"Status"}
                                  style={{ width: "23%" }}
                                  Details={
                                    <div
                                      style={{
                                        backgroundColor:
                                          getStatusColor("Approved")?.split(
                                            ","
                                          )?.[0],
                                        color:
                                          getStatusColor("Approved")?.split(
                                            ","
                                          )?.[1],
                                        cursor: "default",
                                        padding: "6px",
                                        borderRadius: "6px",
                                      }}
                                    >
                                      {"Approved"}
                                    </div>
                                  }
                                />
                              </>
                            ) : data?.itsmChangeRequest?.directorDecision ==
                              "Rejected" ? (
                              <>
                                <SummaryDetails
                                  detailTitle={"Status"}
                                  style={{ width: "23%" }}
                                  Details={
                                    <div
                                      style={{
                                        backgroundColor:
                                          getStatusColor("Rejected")?.split(
                                            ","
                                          )?.[0],
                                        color:
                                          getStatusColor("Rejected")?.split(
                                            ","
                                          )?.[1],
                                        cursor: "default",
                                        padding: "6px",
                                        borderRadius: "6px",
                                      }}
                                    >
                                      {"Rejected"}
                                    </div>
                                  }
                                />
                              </>
                            ) : (
                              <SummaryDetails
                                detailTitle={"Status"}
                                style={{ width: "23%" }}
                                Details={
                                  <div
                                    style={{
                                      backgroundColor:
                                        getStatusColor("Pending")?.split(
                                          ","
                                        )?.[0],
                                      color:
                                        getStatusColor("Pending")?.split(
                                          ","
                                        )?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {"Pending"}
                                  </div>
                                }
                              />
                            )}
                          </>
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <div
                          style={{
                            background: "white",
                            borderTop: ".75px solid #b5b5b5",
                          }}
                        >
                          {data?.itsmChangeRequest?.changeType == "Standard" ? (
                            <></>
                          ) : data?.itsmChangeRequest?.changeType ===
                            "Emergency" ? (
                            <>
                              {LoggedInUser?.id === data?.directorID &&
                              data.itsmChangeRequest?.directorDecision ==
                                null &&
                              data?.itsmChangeRequest?.status !== "Cancelled" &&
                              data?.itsmChangeRequest?.status !== "Rejected" ? (
                                <ITdirectorApproval
                                  getDetails={getDetails}
                                  data={data}
                                />
                              ) : (
                                <div className="detailInner">
                                  {/* <Details
                                    detailTitle={"Decision"}
                                    Details={
                                      data?.itsmChangeRequest?.directorDecision
                                    }
                                  /> */}
                                  <Details
                                    detailTitle={"Notes"}
                                    Details={
                                      data?.itsmChangeRequest
                                        ?.decisionExplanation
                                    }
                                  />
                                  {data?.itsmChangeRequest?.directorDecision ===
                                    "Approved with Conditions" && (
                                    <Details
                                      detailTitle={"Conditions"}
                                      Details={
                                        data?.itsmChangeRequest?.conditions
                                      }
                                    />
                                  )}
                                  {data?.itsmChangeRequest?.directorDecision ===
                                    "Approved" ||
                                  data?.itsmChangeRequest?.directorDecision ===
                                    "Approved with Conditions" ? (
                                    <>
                                      <Details
                                        detailTitle={"Approved By"}
                                        Details={data?.director}
                                      />
                                      <Details
                                        detailTitle={"Approved On"}
                                        Details={format(
                                          new Date(
                                            data?.itsmChangeRequest?.directorApprovingDate
                                          ),
                                          "dd/MM/yyyy hh:mm a"
                                        )}
                                      />
                                    </>
                                  ) : data?.itsmChangeRequest
                                      ?.directorDecision == "Rejected" ? (
                                    <>
                                      <Details
                                        detailTitle={"Rejected By"}
                                        Details={data?.director}
                                      />
                                      <Details
                                        detailTitle={"Rejected On"}
                                        Details={format(
                                          new Date(
                                            data?.itsmChangeRequest?.directorApprovingDate
                                          ),
                                          "dd/MM/yyyy hh:mm a"
                                        )}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )}
                            </>
                          ) : data?.itsmChangeRequest?.isApprovedByManager ===
                              true &&
                            data?.itsmChangeRequest?.directorDecision == null &&
                            data?.itsmChangeRequest?.status !== "Cancelled" &&
                            data?.itsmChangeRequest?.status !== "Rejected" &&
                            data?.approved === "Yes" ? (
                            data.directorID === LoggedInUser.id &&
                            data?.itsmChangeRequest?.directorDecision == null &&
                            data?.itsmChangeRequest?.status !== "Cancelled" &&
                            data?.itsmChangeRequest?.status !== "Rejected" ? (
                              <ITdirectorApproval
                                getDetails={getDetails}
                                data={data}
                              />
                            ) : data?.isApprovedByManager ? (
                              <div
                                className="detailInner"
                                style={{ paddingTop: 50 }}
                              >
                                {/* <Details
                                  detailTitle={"Decision"}
                                  Details={
                                    data?.itsmChangeRequest?.directorDecision ==
                                    null
                                      ? "-"
                                      : data?.itsmChangeRequest
                                          ?.directorDecision
                                  }
                                /> */}
                                <Details
                                  detailTitle={"Notes"}
                                  Details={
                                    data?.itsmChangeRequest?.decisionExplanation
                                      ? "-"
                                      : data?.itsmChangeRequest
                                          ?.decisionExplanation
                                  }
                                />

                                {data?.itsmChangeRequest?.directorDecision ===
                                  "Approved with Conditions" && (
                                  <Details
                                    detailTitle={"Conditions"}
                                    Details={
                                      data?.itsmChangeRequest?.conditions
                                    }
                                  />
                                )}
                                <Details
                                  detailTitle={"Decision Date"}
                                  Details={
                                    data?.itsmChangeRequest?.decisionDate ==
                                    null
                                      ? "-"
                                      : format(
                                          new Date(
                                            data?.itsmChangeRequest?.decisionDate
                                          ),
                                          "dd/MM/yyyy hh:mm a"
                                        )
                                  }
                                />
                                {data?.itsmChangeRequest?.directorDecision ===
                                  "Approved" ||
                                data?.itsmChangeRequest?.directorDecision ===
                                  "Approved with Conditions" ? (
                                  <>
                                    <Details
                                      detailTitle={"Approved By"}
                                      Details={data?.director}
                                    />
                                    <Details
                                      detailTitle={"Approved On"}
                                      Details={format(
                                        new Date(
                                          data?.itsmChangeRequest?.directorApprovingDate
                                        ),
                                        "dd/MM/yyyy hh:mm a"
                                      )}
                                    />
                                  </>
                                ) : data?.itsmChangeRequest
                                    ?.directorDecision === "Rejected" ? (
                                  <>
                                    <Details
                                      detailTitle={"Rejected By"}
                                      Details={data?.director}
                                    />
                                    <Details
                                      detailTitle={"Rejected On"}
                                      Details={format(
                                        new Date(
                                          data?.itsmChangeRequest?.directorApprovingDate
                                        ),
                                        "dd/MM/yyyy hh:mm a"
                                      )}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )
                          ) : (
                            <div
                              className="detailInner"
                              style={{ paddingTop: 50 }}
                            >
                              {/* <Details
                                detailTitle={"Decision"}
                                Details={
                                  data?.itsmChangeRequest?.directorDecision ==
                                  null
                                    ? "-"
                                    : data?.itsmChangeRequest?.directorDecision
                                }
                              /> */}
                              <Details
                                detailTitle={"Notes"}
                                Details={
                                  data?.itsmChangeRequest
                                    ?.decisionExplanation == null
                                    ? "-"
                                    : data?.itsmChangeRequest
                                        ?.decisionExplanation
                                }
                              />
                              {data?.itsmChangeRequest?.directorDecision ===
                                "Approved with Conditions" && (
                                <Details
                                  detailTitle={"Conditions"}
                                  Details={data?.itsmChangeRequest?.conditions}
                                />
                              )}
                              <Details
                                detailTitle={"Decision Date"}
                                Details={
                                  data?.itsmChangeRequest?.decisionDate == null
                                    ? "-"
                                    : format(
                                        new Date(
                                          data?.itsmChangeRequest?.decisionDate
                                        ),
                                        "dd/MM/yyyy hh:mm a"
                                      )
                                }
                              />
                              {data?.itsmChangeRequest?.directorDecision ===
                                "Approved" ||
                              data?.itsmChangeRequest?.directorDecision ===
                                "Approved with Conditions" ? (
                                <>
                                  <Details
                                    detailTitle={"Approved By"}
                                    Details={data?.director}
                                  />
                                  <Details
                                    detailTitle={"Approved On"}
                                    Details={format(
                                      new Date(
                                        data?.itsmChangeRequest?.directorApprovingDate
                                      ),
                                      "dd/MM/yyyy hh:mm a"
                                    )}
                                  />
                                </>
                              ) : data?.itsmChangeRequest?.directorDecision ==
                                "Rejected" ? (
                                <>
                                  <Details
                                    detailTitle={"Rejected By"}
                                    Details={data?.director}
                                  />
                                  <Details
                                    detailTitle={"Rejected On"}
                                    Details={format(
                                      new Date(
                                        data?.itsmChangeRequest?.directorApprovingDate
                                      ),
                                      "dd/MM/yyyy hh:mm a"
                                    )}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              )}

              {tabIndex === 2 && (
                <div
                  className="detailsEReq"
                  style={{ padding: "0px", paddingTop: 40 }}
                >
                  {data?.itsmChangeRequest?.status === "Closed" ||
                  data?.itsmChangeRequest?.status === "Deferred" ||
                  data?.itsmChangeRequest?.status ===
                    "Roll back not successful" ? (
                    <div
                      className="detailInner"
                      style={{ flexGrow: "initial" }}
                    >
                      <Details
                        detailTitle={"Implementation Timeline"}
                        Details={data?.itsmChangeRequest?.timeline}
                      />
                      <Details
                        detailTitle={"Implementation Notes"}
                        Details={data?.itsmChangeRequest?.additionalComments}
                      />
                      <Details
                        detailTitle={"Implementation Support Document"}
                        Details={
                          <UploadDetail
                            filepath={`Change/${data?.itsmChangeRequest?.implemeterTimelineUpload}`}
                          />
                        }
                      />
                      <Details
                        detailTitle={"Status"}
                        Details={
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                data?.itsmChangeRequest?.implementerStatus !==
                                  null
                                  ? data?.itsmChangeRequest?.implementerStatus
                                  : "-"
                              )?.split(",")?.[0],
                              color: getStatusColor(
                                data?.itsmChangeRequest?.implementerStatus !==
                                  null
                                  ? data?.itsmChangeRequest?.implementerStatus
                                  : "-"
                              )?.split(",")?.[1],
                              cursor: "default",
                              padding: "6px",
                              borderRadius: "6px",
                              textAlign: "center",
                              minWidth: "55px",
                            }}
                          >
                            {data?.itsmChangeRequest?.implementerStatus !== null
                              ? data?.itsmChangeRequest?.implementerStatus
                              : "-"}
                          </div>
                        }
                      />

                      {data?.itsmChangeRequest?.ticket != null && (
                        <Details
                          detailTitle={"Request Created"}
                          Details={
                            data?.itsmChangeRequest?.ticket?.ticketNumber
                          }
                        />
                      )}
                      {data?.itsmChangeRequest?.problemRequest != null && (
                        <Details
                          detailTitle={"Request Created"}
                          Details={
                            data?.itsmChangeRequest?.problemRequest
                              ?.requestNumber
                          }
                        />
                      )}
                      {data?.isCreated === false && (
                        <>
                          {data?.itsmChangeRequest?.implementerStatus ===
                            "Roll back not successful" && (
                            <div className="createFootCust">
                              {LoggedInUser?.id === data?.problemManagerID ? (
                                <button
                                  onClick={() =>
                                    togglePopup("Problem", "Change", selectedId)
                                  }
                                  className="button primary"
                                  style={{
                                    background: "var(--primary-hover)",
                                  }}
                                >
                                  Raise a Problem Request
                                </button>
                              ) : (
                                <button
                                  onClick={() =>
                                    dispatch(
                                      showToast({
                                        text: "Please Login as a Problem Manager to raise the request",
                                        severity: "error",
                                      })
                                    )
                                  }
                                  className="button primary"
                                  style={{
                                    background: "var(--primary-hover)",
                                  }}
                                >
                                  Raise a Problem Request
                                </button>
                              )}
                              <button
                                className="primary button"
                                style={{ background: "var(--primary-hover)" }}
                                onClick={() => {
                                  navigate(-1);
                                  setTimeout(
                                    () =>
                                      navigate(`/itsm/itdeskticket/create`, {
                                        state: { selectedId },
                                      }),
                                    30
                                  );
                                }}
                              >
                                Raise a Incident Request
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ) : data?.itsmChangeRequest?.status === "Rolled back" ? (
                    <>
                      <div className="detailInner" style={{ paddingTop: 40 }}>
                        <Details
                          detailTitle={"Implementation Timeline"}
                          Details={data?.itsmChangeRequest?.timeline}
                        />
                        <Details
                          detailTitle={"Implementation Notes"}
                          Details={data?.itsmChangeRequest?.additionalComments}
                        />
                        <Details
                          detailTitle={"Implementation Support Document"}
                          Details={
                            <UploadDetail
                              filepath={`Change/${data?.itsmChangeRequest?.implemeterTimelineUpload}`}
                            />
                          }
                        />
                      </div>
                      {data?.implementer == true && (
                        <ITImplementation data={data} getDetails={getDetails} />
                      )}
                    </>
                  ) : data?.implementer === true &&
                    data?.itsmChangeRequest?.status !== "Cancelled" &&
                    data?.itsmChangeRequest?.status !== "Rejected" ? (
                    data?.itsmChangeRequest?.isApprovedByCo != null ? (
                      data?.itsmChangeRequest?.changeType === "Standard" ? (
                        <ITImplementation data={data} getDetails={getDetails} />
                      ) : data?.itsmChangeRequest?.changeType === "Emergency" &&
                        data?.itsmChangeRequest?.directorDecision != null &&
                        data?.itsmChangeRequest?.status !== "Cancelled" &&
                        data?.itsmChangeRequest?.status !== "Rejected" ? (
                        <ITImplementation data={data} getDetails={getDetails} />
                      ) : data?.itsmChangeRequest?.changeType === "Normal" &&
                        data?.itsmChangeRequest?.directorDecision != null &&
                        data?.itsmChangeRequest?.status !== "Cancelled" &&
                        data?.itsmChangeRequest?.status !== "Rejected" ? (
                        <ITImplementation data={data} getDetails={getDetails} />
                      ) : (
                        <div className="detailInner">
                          <Details
                            detailTitle={"Implementation Notes"}
                            Details={
                              data?.itsmChangeRequest?.additionalComments
                            }
                          />
                          <Details
                            detailTitle={"Implementation Support Document"}
                            Details={
                              data?.itsmChangeRequest
                                ?.implemeterTimelineUpload != null ? (
                                <UploadDetail
                                  filepath={`Change/${data?.itsmChangeRequest?.implemeterTimelineUpload}`}
                                />
                              ) : (
                                "-"
                              )
                            }
                          />
                          <Details
                            detailTitle={"Status"}
                            style={{ width: "23%" }}
                            Details={
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    data?.itsmChangeRequest?.implementerStatus
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    data?.itsmChangeRequest?.implementerStatus
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  minWidth: "55px",
                                }}
                              >
                                {data?.itsmChangeRequest?.implementerStatus !==
                                null
                                  ? data?.itsmChangeRequest?.implementerStatus
                                  : "-"}
                              </div>
                            }
                          />
                        </div>
                      )
                    ) : (
                      <div className="detailInner" style={{ paddingTop: 40 }}>
                        <Details
                          detailTitle={"Implementation Timeline"}
                          Details={data?.itsmChangeRequest?.timeline}
                        />
                        <Details
                          detailTitle={"Implementation Notes"}
                          Details={data?.itsmChangeRequest?.additionalComments}
                        />
                        <Details
                          detailTitle={"Implementation Support Document"}
                          Details={
                            data?.itsmChangeRequest?.implemeterTimelineUpload !=
                            null ? (
                              <UploadDetail
                                filepath={`Change/${data?.itsmChangeRequest?.implemeterTimelineUpload}`}
                              />
                            ) : (
                              "-"
                            )
                          }
                        />

                        <Details
                          detailTitle={"Status"}
                          style={{ width: "23%" }}
                          Details={
                            <div
                              style={{
                                backgroundColor: getStatusColor(
                                  data?.itsmChangeRequest?.implementerStatus
                                )?.split(",")?.[0],
                                color: getStatusColor(
                                  data?.itsmChangeRequest?.implementerStatus
                                )?.split(",")?.[1],
                                cursor: "default",
                                padding: "6px",
                                borderRadius: "6px",
                              }}
                            >
                              {data?.itsmChangeRequest?.implementerStatus !==
                              null
                                ? data?.itsmChangeRequest?.implementerStatus
                                : "-"}
                            </div>
                          }
                        />
                      </div>
                    )
                  ) : (
                    <div className="detailInner" style={{ paddingTop: 40 }}>
                      <Details
                        detailTitle={"Implementation Timeline"}
                        Details={data?.itsmChangeRequest?.timeline}
                      />
                      <Details
                        detailTitle={"Implementation Notes"}
                        Details={data?.itsmChangeRequest?.additionalComments}
                      />
                      <Details
                        detailTitle={"Implementation Support Document"}
                        Details={
                          data?.itsmChangeRequest?.implemeterTimelineUpload !=
                          null ? (
                            <UploadDetail
                              filepath={`Change/${data?.itsmChangeRequest?.implemeterTimelineUpload}`}
                            />
                          ) : (
                            "-"
                          )
                        }
                      />
                      <Details
                        detailTitle={"Status"}
                        style={{ width: "23%" }}
                        Details={
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                data?.itsmChangeRequest?.implementerStatus
                              )?.split(",")?.[0],
                              color: getStatusColor(
                                data?.itsmChangeRequest?.implementerStatus
                              )?.split(",")?.[1],
                              cursor: "default",
                              padding: "6px",
                              borderRadius: "6px",
                              textAlign: "center",
                              minWidth: "55px",
                            }}
                          >
                            {data?.itsmChangeRequest?.implementerStatus !== null
                              ? data?.itsmChangeRequest?.implementerStatus
                              : "-"}
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              {tabIndex === 3 && (
                <div
                  className="detailsEReq"
                  style={{
                    boxShadow: "0px 2px 2px rgb(0 0 0 / 2%)",
                    borderRadius: "8px",
                    gap: "8px",
                  }}
                >
                  <div style={{ padding: "8px" }}>
                    <Accordion
                      sx={{
                        boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
                      }}
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography
                          sx={{
                            width: "33%",
                            flexShrink: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                          }}
                        >
                          Post Implementation Review
                        </Typography>
                        {(data?.itsmChangeRequest?.implementerStatus ===
                          "Change successful" ||
                          data?.itsmChangeRequest?.implementerStatus ===
                            "Roll back successful") &&
                        data?.itsmChangeRequest?.status !== "Cancelled" &&
                        data?.itsmChangeRequest?.status !== "Rejected" ? (
                          data?.itsmChangeRequest?.ticketCategory
                            .changeOwnerEmployee.id === LoggedInUser.id &&
                          data?.itsmChangeRequest?.status !== "Closed" &&
                          data?.itsmChangeRequest?.status !== "Rolled back" &&
                          data?.itsmChangeRequest?.finalRemarks == null ? (
                            <SummaryDetails
                              detailTitle={"Status"}
                              style={{ width: "23%" }}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Pending"}
                                </div>
                              }
                            />
                          ) : data?.itsmChangeRequest?.coFinalApproval ? (
                            <>
                              <SummaryDetails
                                style={{ width: "23%" }}
                                detailTitle={"Status"}
                                Details={
                                  <div
                                    style={{
                                      backgroundColor: getStatusColor(
                                        "Request Approved by Change Owner"
                                      )?.split(",")?.[0],
                                      color: getStatusColor(
                                        "Request Approved by Change Owner"
                                      )?.split(",")?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {"Request Approved by Change Owner"}
                                  </div>
                                }
                              />
                            </>
                          ) : data?.itsmChangeRequest?.coFinalApproval ===
                              false &&
                            data?.itsmChangeRequest?.finalRemarks != null ? (
                            <>
                              <SummaryDetails
                                style={{ width: "23%" }}
                                detailTitle={"Status"}
                                Details={
                                  <div
                                    style={{
                                      backgroundColor:
                                        getStatusColor("Roll backing")?.split(
                                          ","
                                        )?.[0],
                                      color:
                                        getStatusColor("Roll backing")?.split(
                                          ","
                                        )?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {"Roll backing"}
                                  </div>
                                }
                              />
                            </>
                          ) : (
                            <SummaryDetails
                              style={{ width: "23%" }}
                              detailTitle={"Status"}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Pending"}
                                </div>
                              }
                            />
                          )
                        ) : data?.itsmChangeRequest?.status !== "Rejected" ? (
                          data?.itsmChangeRequest?.coFinalApproval === false &&
                          data?.itsmChangeRequest?.finalRemarks != null ? (
                            <SummaryDetails
                              style={{ width: "23%" }}
                              detailTitle={"Status"}
                              Details={
                                <div
                                  style={{
                                    backgroundColor:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[0],
                                    color:
                                      getStatusColor("Pending")?.split(
                                        ","
                                      )?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {"Pending"}
                                </div>
                              }
                            />
                          ) : (
                            <></>
                          )
                        ) : data?.itsmChangeRequest?.status !== "Cancelled" ? (
                          <></>
                        ) : (
                          <SummaryDetails
                            style={{ width: "23%" }}
                            detailTitle={"Status"}
                            Details={
                              <div
                                style={{
                                  backgroundColor:
                                    getStatusColor("Pending")?.split(",")?.[0],
                                  color:
                                    getStatusColor("Pending")?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                              >
                                {"Pending"}
                              </div>
                            }
                          />
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <div style={{ borderTop: ".75px solid #b5b5b5" }}>
                          {" "}
                          {(data?.itsmChangeRequest?.implementerStatus ===
                            "Change successful" ||
                            data?.itsmChangeRequest?.implementerStatus ===
                              "Roll back successful") &&
                          data?.itsmChangeRequest?.status !== "Cancelled" &&
                          data?.itsmChangeRequest?.status !== "Rejected" ? (
                            data?.itsmChangeRequest?.ticketCategory
                              .changeOwnerEmployee.id === LoggedInUser.id &&
                            data?.itsmChangeRequest?.status !== "Closed" &&
                            data?.itsmChangeRequest?.status !== "Rolled back" &&
                            data?.itsmChangeRequest?.finalRemarks == null ? (
                              <PIreview getDetails={getDetails} data={data} />
                            ) : data?.itsmChangeRequest?.coFinalApproval ? (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial", paddingTop: 40 }}
                              >
                                <Details
                                  detailTitle={
                                    "Post Implementation Review Notes"
                                  }
                                  Details={
                                    data?.itsmChangeRequest?.finalRemarks
                                  }
                                />
                              </div>
                            ) : data?.itsmChangeRequest?.coFinalApproval ==
                                false &&
                              data?.itsmChangeRequest?.finalRemarks != null ? (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial", paddingTop: 40 }}
                              >
                                <Details
                                  detailTitle={
                                    "Post Implementation Review Notes"
                                  }
                                  Details={
                                    data?.itsmChangeRequest?.finalRemarks
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial", paddingTop: 40 }}
                              >
                                <Details
                                  detailTitle={
                                    "Post Implementation Review Notes"
                                  }
                                  Details={
                                    data?.itsmChangeRequest?.finalRemarks
                                  }
                                />
                              </div>
                            )
                          ) : data?.itsmChangeRequest?.status !== "Rejected" ? (
                            data?.itsmChangeRequest?.coFinalApproval ===
                              false &&
                            data?.itsmChangeRequest?.finalRemarks != null ? (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial", paddingTop: 40 }}
                              >
                                <Details
                                  detailTitle={
                                    "Post Implementation Review Notes"
                                  }
                                  Details={
                                    data?.itsmChangeRequest?.finalRemarks
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial", paddingTop: 40 }}
                              >
                                {" "}
                                <Details
                                  detailTitle={
                                    "Post Implementation Review Notes"
                                  }
                                  Details={
                                    data?.itsmChangeRequest?.finalRemarks
                                  }
                                />
                              </div>
                            )
                          ) : data?.itsmChangeRequest?.status !==
                            "Cancelled" ? (
                            <div
                              className="detailInner"
                              style={{ flexGrow: "initial", paddingTop: 40 }}
                            >
                              {" "}
                              <Details
                                detailTitle={"Post Implementation Review Notes"}
                                Details={data?.itsmChangeRequest?.finalRemarks}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <Accordion
                      sx={{
                        boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
                      }}
                      expanded={expanded === "panel5"}
                      onChange={handleChange("panel5")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                      >
                        <Typography
                          sx={{
                            width: "33%",
                            flexShrink: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                          }}
                        >
                          HOD Review
                        </Typography>
                        <>
                          {(data?.itsmChangeRequest?.implementerStatus ===
                            "Change successful" ||
                            data?.itsmChangeRequest?.implementerStatus ===
                              "Roll back successful") &&
                          data?.itsmChangeRequest?.status !== "Cancelled" &&
                          data?.itsmChangeRequest?.status !== "Rejected" ? (
                            data.hodid === LoggedInUser.id &&
                            data?.itsmChangeRequest?.coFinalApproval === true &&
                            data?.itsmChangeRequest?.status !== "Closed" &&
                            data?.itsmChangeRequest?.status !==
                              "Rolled back" ? (
                              <SummaryDetails
                                detailTitle={"Status"}
                                style={{ width: "23%" }}
                                Details={
                                  <div
                                    style={{
                                      backgroundColor:
                                        getStatusColor("Pending")?.split(
                                          ","
                                        )?.[0],
                                      color:
                                        getStatusColor("Pending")?.split(
                                          ","
                                        )?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {"Pending"}
                                  </div>
                                }
                              />
                            ) : data?.itsmChangeRequest?.hodFinalApproval ==
                              true ? (
                              <SummaryDetails
                                detailTitle={"Status"}
                                style={{ width: "23%" }}
                                Details={
                                  <div
                                    style={{
                                      backgroundColor:
                                        getStatusColor("Request Closed")?.split(
                                          ","
                                        )?.[0],
                                      color:
                                        getStatusColor("Request Closed")?.split(
                                          ","
                                        )?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {"Request Closed"}
                                  </div>
                                }
                              />
                            ) : (
                              <SummaryDetails
                                detailTitle={"Status"}
                                style={{ width: "23%" }}
                                Details={
                                  <div
                                    style={{
                                      backgroundColor:
                                        getStatusColor("Pending")?.split(
                                          ","
                                        )?.[0],
                                      color:
                                        getStatusColor("Pending")?.split(
                                          ","
                                        )?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {"Pending"}
                                  </div>
                                }
                              />
                            )
                          ) : data?.itsmChangeRequest?.status === "Rejected" ? (
                            data?.itsmChangeRequest?.hodFinalApproval ===
                              false &&
                            data?.itsmChangeRequest?.hodFinalRemarks != null ? (
                              <SummaryDetails
                                detailTitle={"Status"}
                                style={{ width: "23%" }}
                                Details={
                                  <div
                                    style={{
                                      backgroundColor:
                                        getStatusColor("Rejected")?.split(
                                          ","
                                        )?.[0],
                                      color:
                                        getStatusColor("Rejected")?.split(
                                          ","
                                        )?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {"Rejected"}
                                  </div>
                                }
                              />
                            ) : (
                              <></>
                            )
                          ) : (
                            data?.itsmChangeRequest?.status === "Cancelled" && (
                              <></>
                            )
                          )}
                        </>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <div style={{ borderTop: ".75px solid #b5b5b5" }}>
                          {(data?.itsmChangeRequest?.implementerStatus ===
                            "Change successful" ||
                            data?.itsmChangeRequest?.implementerStatus ===
                              "Roll back successful") &&
                          data?.itsmChangeRequest?.status !== "Cancelled" &&
                          data?.itsmChangeRequest?.status !== "Rejected" ? (
                            data.hodid === LoggedInUser.id &&
                            data?.itsmChangeRequest?.coFinalApproval === true &&
                            data?.itsmChangeRequest?.status !== "Closed" &&
                            data?.itsmChangeRequest?.status !==
                              "Rolled back" ? (
                              <HodReview getDetails={getDetails} data={data} />
                            ) : data?.itsmChangeRequest?.hodFinalApproval ==
                              true ? (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial" }}
                              >
                                <Details
                                  detailTitle={"Final Remark"}
                                  Details={
                                    data?.itsmChangeRequest?.finalRemarks
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial" }}
                              >
                                <Details
                                  detailTitle={"Final Remark"}
                                  Details={
                                    data?.itsmChangeRequest?.hodFinalRemarks !=
                                    null
                                      ? data?.itsmChangeRequest?.hodFinalRemarks
                                      : "-"
                                  }
                                />
                              </div>
                            )
                          ) : data?.itsmChangeRequest?.status === "Rejected" ? (
                            data?.itsmChangeRequest?.hodFinalApproval ===
                              false &&
                            data?.itsmChangeRequest?.hodFinalRemarks != null ? (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial" }}
                              >
                                <Details
                                  detailTitle={"Final Remark"}
                                  Details={
                                    data?.itsmChangeRequest?.hodFinalRemarks !=
                                    null
                                      ? data?.itsmChangeRequest?.hodFinalRemarks
                                      : "-"
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial" }}
                              >
                                {" "}
                                <Details
                                  detailTitle={"Final Remark"}
                                  Details={
                                    data?.itsmChangeRequest?.hodFinalRemarks !=
                                    null
                                      ? data?.itsmChangeRequest?.hodFinalRemarks
                                      : "-"
                                  }
                                />
                              </div>
                            )
                          ) : (
                            data?.itsmChangeRequest?.status === "Cancelled" && (
                              <div
                                className="detailInner"
                                style={{ flexGrow: "initial" }}
                              >
                                <Details
                                  detailTitle={"Final Remark"}
                                  Details={
                                    data?.itsmChangeRequest?.hodFinalRemarks !=
                                    null
                                      ? data?.itsmChangeRequest?.hodFinalRemarks
                                      : "-"
                                  }
                                />
                              </div>
                            )
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              )}
              {tabIndex === 4 && (
                <>
                  <div className="detailsEReq" style={{ flexGrow: "initial" }}>
                    <Notifications
                      id={selectedId}
                      module={"ITSM Change Requests"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          handleClose={togglePopup}
          popupHeading={"Raise Problem Request"}
          content={
            <ProblemForm
              loading={loading}
              setLoading={setLoading}
              data={getDetails}
              type={typeAction}
              id={extra}
              color={colorAction}
              handleClose={togglePopup}
            />
          }
        />
      )}
    </div>
  );
};

export { ChangeDetails };

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { CreateCompetencySetComp } from "./FormComp/CreateCompetencySetComp";

const CreateCompetencySet = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `CompetencySetController/Details?ID=${data}`
      );
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "CompetencySetController/Create",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("CompetencySetController/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {}
  };

  const initialvalue = {
    // "Name": "team work",

    // "SubDepartmentId": 1
    Name: data ? detailsform?.name : "",
  };
  const validationSchema = Yup.object({
    Name: Yup.string()
      .max(149, "Name must be less than 150 characters")
      .required("Please Enter Name"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: detailsform?.id,
    };

    const create = {
      ...values,
      Id: 0,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  return (
    <FormCustomFormik
      styles={{ width: "500px" }}
      loading={loading}
      FormHeading={data ? "Edit Competency Set" : "Create Competency Set"}
      ButtonLabel={data ? "Edit Competency Set" : "Create Competency Set"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateCompetencySetComp data={detailsform} />}
    />
  );
};

export { CreateCompetencySet };

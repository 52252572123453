import React, { useEffect, useState } from 'react'
import { InstructionSelf } from './InstructionSelf';
import { Popup } from '../../../../Components/FormComponent/PopupComponent/Popup';
import Nodata from "../../../../Assets/Nodata.svg";
import { InputCustomFormik } from '../../../../Components/FormComponent/InputCustomFormik';
import { formatNumber } from '../../../../Utilities/formatNumber';
import { TextAreaFormik } from '../../../../Components/FormComponent/TextAreaFormik';
import { selfRatingObtain } from '../../../../Utilities';
import StarRatings from 'react-star-ratings';
import { Form, Formik } from 'formik';
import { Loader } from '../../../../Components/FormComponent/Loader';
import * as Yup from "yup";
import { isLoading, showToast } from '../../../../Features';
import { getRequest, postRequest } from '../../../../Services/axios';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MyRewardsReviewWinnersCriteria from '../../MyRewardsReviewWinnersCriteria';



const SubmitMyRewardsSelfReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = useParams();
  let xy = Object.keys(id);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const n = id[xy].lastIndexOf("/");
  // const selectedId = id[xy].substring(n + 1);
  // const isEdit = location?.state?.type === "Update Review";
  const rewardsId = location?.state?.item?.id;
  const studentId = location?.state?.item?.employeeid;
  const isEdit = location?.state?.item?.isEdit;
  const title = location?.state?.item?.title;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(true);
  const [details, setDetails] = useState(false);
  const [formValues, setFormValues] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selfTargetDetail, setSelfTargetDetail] = useState(false)
  // const [starData, setStarData] = useState(false);

  console.log("this is submit rewards item employeidhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh",studentId)

  // const { LoggedInUser } = useSelector((state) => state.auth);
  console.log("this is logged usergggggggggfd", LoggedInUser)

 const loggeduserId = LoggedInUser.id;

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  // const getStarDetails = async () => {
  //   try {
  //     const result = await getRequest(
  //       `PMSAppraisalReview/AppraisalRatingSlab?ID=${data?.pmsReviews?.appraisalCycleId}&EmployeeId=${data?.pmsReviews?.employeeId}`
  //     );
  //     setStarData(result?.data?.data);
  //   } catch (error) {
  //     dispatch(
  //       showToast({
  //         text: error?.response?.data ?? "Error in fetching Data",
  //         severity: "error",
  //       })
  //     );
  //   } finally {
  //   }
  // };
  // useEffect(() => {
  //   if (data?.pmsReviews?.appraisalCycleId) getStarDetails();
  // }, [data]);

  // const getDetails = async () => {
  //     try {
  //         setLoading(true);

  //         const result = await getRequest(
  //           `PMSAppraisalReview/AppraisalRatingSlab?ID=${selectedId}&EmployeeId=${LoggedInUser?.id}`
  //         );
  //         const resultDetails = await getRequest(
  //           `PMSAppraisalReview/FinalReviewEmployee?ID=${selectedId}&EmployeeId=${LoggedInUser?.id}`
  //         );

  //         setData(result?.data?.data);
  //         setDetails(resultDetails?.data?.data);
  //     } catch (error) {
  //         dispatch(
  //             showToast({
  //                 text: error?.response?.data ?? "Error in fetching Data",
  //                 severity: "error",
  //             })
  //         );
  //     } finally {
  //         setLoading(false);
  //     }
  // };
  const Edit = async (values) => {
    try {
        dispatch(isLoading(true));
        setLoading(true);
        const result = await postRequest("RewardReview/EditSelfReview", values);
        if (result) {
            dispatch(
                showToast({ text: "Successfully Updated", severity: "success" })
            );
            // returnPage();
        }
    } catch (error) {
        dispatch(
            showToast({
                text: error?.response?.data ?? "Error in fetching Data",
                severity: "error",
            })
        );
    } finally {
        dispatch(isLoading(false));
        setLoading(false);
        navigate(-1)
    }
};


  const getDetails = async () => {

    try {
      setLoading(true);
      // const result = await getRequest(`Awards/Details/${selectedId}`);
      const result = await getRequest(`RewardsTransController/TargetSetDetails?RewardId=${rewardsId}&EmployeeId=${loggeduserId}`);
      setDetails(result?.data?.summary);
      
        const result1 = await postRequest(`RewardReview/ViewReview?RewardId=${rewardsId}&EmployeeId=${loggeduserId}`);
        setSelfTargetDetail(result1?.data?.data[0]);
      
      console.log("sandy Quantative details:", result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  console.log("submit detail my target set detail tttttttttttttttttttttttttttttttt", details);
  console.log("submit detail self review detail", selfTargetDetail);



  useEffect(() => {
    document.title = `PeopleSol -  Submit Self Review `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    // debugger
    if(isEdit){
      if (details && rewardsId && selfTargetDetail) {
        setInitialValues({
          total: "",
          reviewId: selfTargetDetail?.id,
          selfNote: selfTargetDetail.comment_Employee,
          appraisalCriteriaClassification: getGoalslist(),
        });
      }
    }else{
      if (details && rewardsId ) {
        setInitialValues({
          total: "",
          appraisalCriteriaClassification: getGoalslist(),
        });
      }
    }
    
  }, [details, rewardsId,selfTargetDetail]);

  const getGoalslist = () => {
    // debugger
    let returnArray = [];
    if (isEdit) {
      isEdit
        ? selfTargetDetail?.rewardsUnits?.map((item, index) => {
          returnArray.push({
            itemId: item?.id ?? "",
            itemDescription: item?.targetName ?? "",
            achievedUnits: item?.unit ?? "",
            achievedValues: item?.value ?? "",
            achievedUnitsPercentage: item?.avg_employee_unit ?? "",
            achievedValuesPercentage: item?.avg_employee_value ?? "",
          });
          return returnArray;
        }) 
        : (returnArray = [
          {
            itemDescription: "",
            achievedUnits: "",
            achievedValues: "",
            achievedUnitsPercentage: "",
            achievedValuesPercentage: "",
          },
        ]);
      return returnArray;
    }
    else {

      details?.targetsSet
        ? details?.targetsSet[0]?.rewardsTargets?.map((item, index) => {
          returnArray.push({
            itemDescription: item?.title ?? "",
            achievedUnits: "",
            achievedValues: "",
            achievedUnitsPercentage: "",
            achievedValuesPercentage: "",
          });
          return returnArray;
        })
        : (returnArray = [
          {
            itemDescription: "",
            achievedUnits: "",
            achievedValues: "",
            achievedUnitsPercentage: "",
            achievedValuesPercentage: "",
          },
        ]);
      return returnArray;
    }
  };

  // const getRewardsReview = () => {
  //   const returnArray = [];
  //   return returnArray.push({
  //     rewardsUnitAverage: "",
  //     rewardsValueAverage: "",
  //     rewardsUnitAveragePercent: "",
  //     rewardsValueAveragePercent: "",
  //   })

  // }

  const [initialValues, setInitialValues] = useState({
    total: "",
    selfNote: "",
    rewardsUnitAverage: "",
    rewardsValueAverage: "",
    rewardsUnitAveragePercent: "",
    rewardsValueAveragePercent: "",
    appraisalCriteriaClassification: getGoalslist(),
    // rewardsReviewAvrage: getRewardsReview(),
  });

  console.log("object initialValues", initialValues);
  console.log("object formValues", formValues);

  useEffect(() => {
    getDetails();
  }, []);

  const createFunction = async (values) => {
    console.log("this is payload of reviewsubmit", values);
    try {
      setLoading(true);
      const result = await postRequest("RewardReview/SubmitSelfReview", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      navigate(-1);
      getDetails();
    }
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  const onSubmit = async (values) => {
      // debugger
    const SubmitRewardsData = {
      Rewards_Id: rewardsId,
      id: values.reviewId,
      Comment_Employee: values?.selfNote,
      Avg_units: Number(values?.rewardsUnitAverage),
      Avg_value: Number(values?.rewardsValueAverage),
      RewardsUnits: values?.appraisalCriteriaClassification?.map((item, index) => ({
        Target_Id: details?.targetsSet[0]?.rewardsTargets[index].id, 
        id: item.itemId,
        Unit: item?.achievedUnits,
        Value: item?.achievedValues,
        Avg_employee_unit: Number(item?.achievedUnitsPercentage),
        Avg_employee_value: Number(item?.achievedValuesPercentage),
      }))
    }
  

    isEdit ? Edit(SubmitRewardsData) : createFunction(SubmitRewardsData);

  };


  useEffect(() => {
    // debugger
    setInitialValues((prev) => {
      const newRewardsItems = { ...formValues };
      newRewardsItems.rewardsUnitAverage = newRewardsItems?.appraisalCriteriaClassification?.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          Number(currentValue?.achievedUnits ?? 0) / details?.targetsSet[0]?.rewardsTargets.length
        );
      }, 0).toFixed(2)
      newRewardsItems.rewardsValueAverage = newRewardsItems?.appraisalCriteriaClassification?.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          Number(currentValue?.achievedValues ?? 0) / details?.targetsSet[0]?.rewardsTargets.length
        );
      }, 0).toFixed(2)
      newRewardsItems.rewardsUnitAveragePercent = details?.targetsSet?.length > 0 && calculateTotalUnitPercent();
      // newRewardsItems?.appraisalCriteriaClassification?.reduce((accumulator, currentValue) => {
      //   return (
      //     accumulator +
      //     Number(currentValue?.achievedUnitsPercentage ?? 0) / details?.targetsSet[0]?.rewardsTargets.length
      //   );
      // }, 0).toFixed(2)
      newRewardsItems.rewardsValueAveragePercent = details?.targetsSet?.length > 0 && calculateTotalValuePercent();
      // newRewardsItems?.appraisalCriteriaClassification?.reduce((accumulator, currentValue) => {
      //   return (
      //     accumulator +
      //     Number(currentValue?.achievedValuesPercentage ?? 0) / details?.targetsSet[0]?.rewardsTargets.length
      //   );
      // }, 0).toFixed(2)
      newRewardsItems.appraisalCriteriaClassification = newRewardsItems?.appraisalCriteriaClassification?.map((item, index) => {
        return {
          ...item,

          achievedUnitsPercentage: ((item.achievedUnits * 100) / details?.targetsSet[0]?.rewardsTargets[index]?.unit).toFixed(2),
          achievedValuesPercentage: ((item.achievedValues * 100) / details?.targetsSet[0]?.rewardsTargets[index]?.value).toFixed(2),
        }
      })

      return newRewardsItems
    })
  }, [formValues?.appraisalCriteriaClassification ])



  const calculateTotalUnitPercent = () => {
    const totaluserunit = formValues?.appraisalCriteriaClassification?.reduce(
      (accumulator, currentValue) => {
        return accumulator + Number(currentValue?.achievedUnits || 0);
      },
      0
    );
    const totalunit = details?.targetsSet[0]?.rewardsTargets.reduce(
      (accumulator, currentValue) => {
        return accumulator + Number(currentValue?.unit || 0);
      },
      0
    );

    const totalUserPercentage = (totaluserunit * 100) / totalunit;
    return parseFloat(totalUserPercentage.toFixed(2));
  };

  const calculateTotalValuePercent = () => {
    const totaluserunit = formValues?.appraisalCriteriaClassification?.reduce(
      (accumulator, currentValue) => {
        return accumulator + Number(currentValue?.achievedValues || 0);
      },
      0
    );
    const totalunit = details?.targetsSet[0]?.rewardsTargets.reduce(
      (accumulator, currentValue) => {
        return accumulator + Number(currentValue?.value || 0);
      },
      0
    );

    const totalUserPercentage = (totaluserunit * 100) / totalunit;
    return parseFloat(totalUserPercentage.toFixed(2));
  };



  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal">
          {loading ? (
            <Loader />
          ) : data ? (
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={onSubmit}
            // validationSchema={Yup.object({
            //   appraisalCriteriaClassification: Yup.array().of(
            //     Yup.object().shape({
            //       selfReview: Yup.string().required("Enter"),
            //     })
            //   ),
            // })}
            >
              {(formik) => {
                chooseFormValues && chooseFormValues(formik.values);
                return (
                  <Form style={{ height: "100%" }}>
                    <div className="selfParent" style={{ gap: "0px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={() => navigate(-1)}
                          style={{ display: "flex" }}
                          className="returnToDashboard cursor"
                        >
                          <div>
                            <svg
                              width="20"
                              height="8"
                              viewBox="0 0 20 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                                fill="#1296B0"
                              />
                            </svg>
                          </div>
                          <label className="returnLabelHolder">
                            {title}
                          </label>
                        </div>

                        <div>
                          <button
                            className="btnSelf"
                            onClick={togglePopup}
                            type="button"
                            style={{ backgroundColor: "#FB7158", color: "#fff" }}
                          >
                            Winners Criteria
                          </button>
                        </div>
                      </div>
                      <div style={{ overflowY: "auto" }}>
                        
                        <div style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
                          maxWidth: "600px",
                          gap: "20px",
                          padding: "20px 56px 20px 24px",
                          backgroundColor: "#d4f2f1",
                          borderRadius: "10px",
                          marginTop: "20px",
                        }}
                        >
                          <div style={{ gridColumn: "span 6 / span 6" }}>
                            <div className="jobDetailContainer" style={{ width: "100%" }} >
                              <div className="verticalLine" style={{
                                width: "2.1px",
                                position: "absolute",
                                left: "12px",
                                top: "20%",
                                height: "70%",
                                backgroundColor: "#5bc4bf"
                              }}></div>
                              <label className="jobDesignation">
                                Self Review Achievement Unit
                              </label>
                              <label style={{ fontSize: "30px", fontWeight: "500", color: "#5bc4bf" }}>
                                {formik.values?.appraisalCriteriaClassification?.reduce((accumulator, currentValue) => {
                                  return (
                                    accumulator +
                                    Number(currentValue?.achievedUnits ?? 0)
                                  );
                                }, 0)}
                              </label>
                            </div>
                          </div>
                          <div style={{ gridColumn: "span 6 / span 6" }}>
                            <div className="jobDetailContainer" style={{ width: "100%" }} >
                              <div className="verticalLine" style={{
                                width: "2.1px",
                                position: "absolute",
                                left: "12px",
                                top: "20%",
                                height: "70%",
                                backgroundColor: "#5bc4bf"
                              }}></div>
                              <label className="jobDesignation">
                                Self Review Achievement Value
                              </label>
                              <label style={{ fontSize: "30px", fontWeight: "500", color: "#5bc4bf" }}>
                                {formik.values?.appraisalCriteriaClassification?.reduce((accumulator, currentValue) => {
                                  return (
                                    accumulator +
                                    Number(currentValue?.achievedValues ?? 0)
                                  );
                                }, 0)}
                              </label>
                            </div>
                          </div>
                          <div style={{ gridColumn: "span 6 / span 6" }}>
                            <div className="jobDetailContainer" style={{ width: "100%" }} >
                              <div className="verticalLine" style={{
                                width: "2.1px",
                                position: "absolute",
                                left: "12px",
                                top: "20%",
                                height: "70%",
                                backgroundColor: "#5bc4bf"
                              }}></div>
                              <label className="jobDesignation">
                                Self Review Achievement Unit %
                              </label>
                              <label style={{ fontSize: "30px", fontWeight: "500", color: "#5bc4bf" }}>
                                {calculateTotalUnitPercent()}
                              </label>
                            </div>
                          </div>
                          <div style={{ gridColumn: "span 6 / span 6" }}>
                            <div className="jobDetailContainer" style={{ width: "100%" }} >
                              <div className="verticalLine" style={{
                                width: "2.1px",
                                position: "absolute",
                                left: "12px",
                                top: "20%",
                                height: "70%",
                                backgroundColor: "#5bc4bf"
                              }}></div>
                              <label className="jobDesignation">
                                Self Review Achievement Unit %
                              </label>
                              <label style={{ fontSize: "30px", fontWeight: "500", color: "#5bc4bf" }}>
                                {calculateTotalValuePercent()}
                              </label>
                            </div>
                          </div>

                        </div>

                        {/* {data?.appraisalCriteriaClassification?.length > 0 ? ( */}
                        <div style={{ width: "100%", overflow: "auto" }}>
                          {/* <div className="borderDot"></div> */}
                          <div
                            style={{
                              marginTop: "12px",
                              background: "#d4f2f1",
                              borderRadius: "6px",
                              display: "flex",
                            }}
                          >
                            <div className="centertdtwo">
                              <div>Self</div>
                              <div>Review</div>
                            </div>
                            <div
                              style={{
                                width: "calc(100% - 120px)",
                                padding: "1px 10px",
                              }}
                            >
                              <table
                                className="appriaisalReviewTable"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr
                                    style={{
                                      borderBottom: "1px #5BC4BF solid",
                                    }}
                                  >
                                    <td
                                      style={{
                                        width: "308px",
                                        paddingLeft: "23px",
                                      }}
                                    >
                                      Item Description
                                    </td>
                                    <td
                                      style={{
                                        width: "40px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Achieved Units
                                    </td>
                                    <td
                                      style={{
                                        width: "40px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Achieved Values
                                    </td>
                                    <td style={{ width: "140px" }}>
                                      Achieved Units %
                                    </td>
                                    <td
                                      style={{
                                        width: "40px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Achieved Values %
                                    </td>


                                    <td></td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formValues?.appraisalCriteriaClassification?.map(
                                    (item, index) => (
                                      <tr>
                                        <td style={{ verticalAlign: "top", textAlign: "center", padding: " 10px 0px" }}>
                                          {" "}
                                          <TextAreaFormik
                                            style={{ height: "80px" }}
                                            name={`appraisalCriteriaClassification[${index}].itemDescription`}
                                            placeholder="Type Here"
                                            maxLength={500}
                                          />
                                        </td>
                                        <td style={{ verticalAlign: "top", textAlign: "center", padding: " 10px 0px" }}>
                                          {" "}
                                          <InputCustomFormik
                                            type={"number"}
                                            className={
                                              "appriaisalReviewTableinput"
                                            }
                                            name={`appraisalCriteriaClassification[${index}].achievedUnits`}
                                          />
                                        </td>
                                        <td style={{ verticalAlign: "top", textAlign: "center", padding: " 10px 0px" }}>
                                          {" "}
                                          <InputCustomFormik
                                            type={"number"}
                                            className={
                                              "appriaisalReviewTableinput"
                                            }
                                            name={`appraisalCriteriaClassification[${index}].achievedValues`}
                                          />
                                        </td>
                                        <td style={{ verticalAlign: "top", textAlign: "center", padding: " 10px 0px" }}>
                                          {" "}
                                          <InputCustomFormik
                                            type={"number"}
                                            disabled={true}
                                            className={
                                              "appriaisalReviewTableinput"
                                            }
                                            name={`appraisalCriteriaClassification[${index}].achievedUnitsPercentage`}

                                          />
                                        </td>
                                        <td style={{ verticalAlign: "top", textAlign: "center", padding: " 10px 0px" }}>
                                          {" "}
                                          <InputCustomFormik
                                            type={"number"}
                                            disabled={true}
                                            className={
                                              "appriaisalReviewTableinput"
                                            }
                                            name={`appraisalCriteriaClassification[${index}].achievedValuesPercentage`}
                                          />
                                        </td>
                                        <td></td>
                                      </tr>
                                    )
                                  )}
                                  <tr
                                    style={{
                                      borderTop:
                                        "1px solid rgb(91, 196, 191)",
                                      borderBottom:
                                        "1px solid rgb(91, 196, 191)",
                                    }}
                                  >
                                    <td style={{ paddingLeft: "23px" }}>Average</td>
                                    <td style={{ verticalAlign: "top", textAlign: "center" }}>
                                      <InputCustomFormik
                                        type={"number"}
                                        disabled={true}

                                        // value={data?.appraisalCriteriaClassification?.reduce(
                                        //   (accumulator, currentValue) => {
                                        //     return (
                                        //       accumulator +
                                        //       Number(currentValue?.achievedUnits)
                                        //     );
                                        //   },
                                        //   0
                                        // )}
                                        className={
                                          "appriaisalReviewTableinput"
                                        }
                                        name={`rewardsUnitAverage`}
                                      />
                                    </td>
                                    <td style={{ verticalAlign: "top", textAlign: "center" }}>
                                      <InputCustomFormik
                                        type={"number"}
                                        disabled={true}
                                        // value={formatNumber(
                                        //   data?.systemInput?.Performance +
                                        //   data?.systemInput?.Tasks +
                                        //   data?.systemInput?.Rewards +
                                        //   data?.systemInput?.[`L&D`]
                                        // )}
                                        className={
                                          "appriaisalReviewTableinput"
                                        }
                                        name={`rewardsValueAverage`}
                                      />
                                    </td>
                                    <td style={{ verticalAlign: "top", textAlign: "center" }}>
                                      <InputCustomFormik
                                        type={"number"}
                                        disabled={true}
                                        // value={formValues?.appraisalCriteriaClassification?.reduce(
                                        //   (accumulator, currentValue) => {
                                        //     return (
                                        //       accumulator +
                                        //       Number(currentValue?.achievedUnitsPercentage)
                                        //     );
                                        //   },
                                        //   0
                                        // )}
                                        className={
                                          "appriaisalReviewTableinput"
                                        }
                                        name={`rewardsUnitAveragePercent`}
                                      />
                                    </td>
                                    <td style={{ verticalAlign: "top", textAlign: "center" }}>
                                      <InputCustomFormik
                                        type={"number"}
                                        disabled={true}
                                        // value={formValues?.appraisalCriteriaClassification?.reduce(
                                        //   (accumulator, currentValue) => {
                                        //     return (
                                        //       accumulator +
                                        //       Number(currentValue?.rewardsValueAveragePercent)
                                        //     );
                                        //   },
                                        //   0
                                        // )}
                                        className={
                                          "appriaisalReviewTableinput"
                                        }
                                        name={`rewardsValueAveragePercent`}
                                      />
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr

                                  >
                                    <td colSpan="5">
                                      <div style={{ padding: "15px 0px", paddingLeft: "23px" }}>
                                        <div style={{ margin: "5px 0px" }}>Self Note</div>
                                        <div> <TextAreaFormik
                                          style={{ height: "80px", marginLeft: "0px" }}
                                          name={`selfNote`}
                                          placeholder="Type Here"
                                          maxLength={500}
                                        /></div>

                                      </div>
                                    </td>


                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* ) : (
                                                    <div></div>
                                                ) */}

                      </div>
                      <div className="createFootCust">
                        <div>
                          <div
                            //   onClick={returnPage}
                            className={`button secondaryButton`}
                            style={{ border: "1px #1190A9 solid" }}
                          >
                            Cancel
                          </div>
                        </div>
                        <div>
                          <button
                            className="button primaryButton"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={"Winners Criteria"}
          content={
            <>
              <MyRewardsReviewWinnersCriteria details={details} />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export default SubmitMyRewardsSelfReview

import styled from "styled-components";
import RadioButton from "../../../Components/FormComponent/RadioGroupInput/RadioButton";
import "./Rewards.css";
import { useState } from "react";

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 21px 7px;
`;

const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-grow: 1;
  gap: 10%;
  flex-direction: row;
  justify-content: center;
`;

const RadioCustomRewards = (props) => {
  const handleChange = (optionId) => {
    props.setactive(optionId);
  };
  // console.log("object active", props.active);
  const { value, options, onChange, errorsParam, touchedParam, inputName } =
    props;
  function renderOptions() {
    return options.map(
      ({ id, label, sublable, images, name, disabled }, index) => {
        const optionId = id;
        console.log("object optionId", optionId);

        return (
          <div
            className={
              props.active === optionId
                ? " radiobuttonCSS PopradioButton"
                : `radiobuttonCSS`
            }
          >
            <RadioButton
              {...props}
              value={value}
              key={optionId}
              id={optionId}
              name={name}
              disabled={disabled}
              // defaultChecked={index === 0}
              onChange={() => handleChange(optionId)}
            />

            <label htmlFor={optionId} className="label-custom">
              <img src={images} alt="images" />
              <br />
              {label} <p>{sublable}</p>
              <br />
              <span
                className={
                  props.active === optionId ? "proplable" : `proplable-circel`
                }
              >
                &#10003;
              </span>
            </label>
          </div>
        );
      }
    );
  }
  return (
    <>
      <div className="radioCust rewardscustom" style={{width: "600px"}}>
        <Fieldset>
          <Wrapper>{renderOptions()}</Wrapper>
        </Fieldset>
        {errorsParam && touchedParam ? (
          <p className="form-error">{errorsParam}</p>
        ) : null}
      </div>
      <div></div>
    </>
  );
};
export { RadioCustomRewards };

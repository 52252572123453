import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateRewardComp } from "./FormComp/CreateRewardComp";
import { useEffect } from "react";
import {
  FormikMultiStepForm,
  FormikMultiStepFormStep,
} from "../../../Components/FormikMultiStepForm/FormikMultiStepForm";
import CreateRewardSetReward from "./CreateRewardSetReward";
import CreateRewardWinnerPosition from "./CreateRewardWinnerPosition";
import CreateRewardEligibleEmp from "./CreateRewardEligibleEmp";
import CreateRewardSelectDate from "./CreateRewardSelectDate";
import CreateRewardQualitative from "./CreateRewardQualitative";
import CreateSetAward from "./CreateSetAward";
import Rewardemployeenomination from "./Rewardemployeenomination";
import { Description } from "@mui/icons-material";
import { format } from "date-fns";

const CreateReward = () => {
  // const [detailsform, setDetailsform] = useState({});
  const location = useLocation();
  console.log("location", location);
  const data = location?.state?.id;
  const type = location?.state?.type;
  const dataFromNext = location?.state?.nextItem;
  console.log("data From next show -:", dataFromNext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [detail, setDetail] = useState([]);

  console.log("hello edit data", data);
  console.log("this is create rewards location cccccccccccccccccccccccccccccc", location)
  console.log("this is create rewards location ddddddddddddddddddddddddddddddd", data)
  console.log("this is create rewards location tttttttttttttttttttttttttttttttt", type)
  console.log("object dataFromNext");
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  const getOrdinalSuffix = (number) => {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return (
        <>
          {number}
          <sup>th</sup>
        </>
      ); // 11th, 12th, 13th for "th"
    }

    switch (lastDigit) {
      case 1:
        return (
          <>
            {number}
            <sup>st</sup>
          </>
        ); // 1st, 21st, 31st
      case 2:
        return (
          <>
            {number}
            <sup>nd</sup>
          </>
        ); // 2nd, 22nd, 32nd
      case 3:
        return (
          <>
            {number}
            <sup>rd</sup>
          </>
        ); // 3rd, 23rd, 33rd
      default:
        return (
          <>
            {number}
            <sup>th</sup>
          </>
        ); // remaining "th"
    }
  };

  function getValidDate(dateString) {

    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return null;
    }

    return date;
  }

  useEffect(() => {
    // debugger
    if (data)
      setInitialValues((prev) => ({
       
        Title: data ? detail?.summary?._RewardsConfig?.title : "",
        Description: data ? detail?.summary?._RewardsConfig?.description : "",
        RewardParticipation: data ? (detail?.summary?._RewardsConfig?.isAlldepartment === true ? "All Department" : "Department wise") : "",
        WinnersCount: data ? detail?.summary?._RewardsConfig?.winnersCount : "",
        ConsolationCount: data ? detail?.summary?._RewardsConfig?.consolationCount : "",
        Template: data ? detail?.summary?._RewardsConfig?.template : "",
        News: data ? detail?.summary?._RewardsConfig?.share_social : "",
        LinkIncentive: data ? (detail?.summary?._RewardsConfig?.link_incentive === true ? "yes" : "No") : "",
        LinkWithPMS: data ? (detail?.summary?._RewardsConfig?.link_pms === true ? "yes" : "No") : "",
        CanConsolationRecieveCetificates: data
        ? (
            detail?.summary?._RewardsConfig?.rewardsConfigWinnerposition
              ?.find(it => it?.isConsolation === true)?.isConsolation_certificate 
              ? "yes" 
              : "No"
          )
        : "",
        RewardsConfigWinnerposition: data ?
          (detail?.summary?._RewardsConfig?.rewardsConfigWinnerposition?.filter(it => it?.isConsolation === false)?.map((item, ind) => ({
            [`Position${ind + 1}`]: data ? item?.category : "",
            [`Position${ind + 1}textArea`]: data ? item?.description : "",
          })))
          : [],
          RewardsConfigConsolation: data ?
          (detail?.summary?._RewardsConfig?.rewardsConfigWinnerposition?.filter(it => it?.isConsolation === true)?.map((item, ind) => ({
            [`Position${ind + 1}`]: data ? item?.category : "",
            [`Position${ind + 1}textArea`]: data ? item?.description : "",
          })))
          : [],
        locationSection: data ?
          ({
            locationArray: [],
            selectedLocationArray: data ? detail?.summary?._RewardsConfig?.locations_IDs?.split(",")?.map(it => Number(it)) : [],
            reasonLocation: ""
          }
          )
          : "",
        Reward_Startdate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Startdate) : "",
        Reward_Enddate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.reward_Enddate) : "",
        Award_announcement_date: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.award_announcement_date) : "",
        Target_Startdate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.target_Startdate) : "",
        Target_Enddate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.target_Enddate) : "",
        SelfReview_Startdate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.selfReview_Startdate) : "",
        SelfReview_Enddate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.selfReview_Enddate) : "",
        ManagerReview_Startdate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.managerReview_Startdate) : "",
        ManagerReview_Enddate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.managerReview_Enddate) : "",
        HodReview_Startdate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.hodReview_Startdate) : "",
        HodReview_Enddate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.hodReview_Enddate) : "",
        HRReview_Startdate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.hrReview_Startdate) : "",
        HRReview_Enddate: data ? getValidDate(detail?.summary?._RewardsConfig?.rewardsDates[0]?.hrReview_Enddate) : "",

      }))
  }, [detail])

  console.log("object infoooooooooooooo", detail?.summary?._RewardsConfig?.locations_IDs?.split(",")?.map(it => Number(it)))

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Reward_ConfigController/Details?ID=${data}`);
      setDetail(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  console.log("form detail here ffffffffffffffffffffffffffff", detail)

  const [initialValues, setInitialValues] = useState({
    Title: "",
    Description: "",
    RewardParticipation: "",
    WinnersCount: "",
    ConsolationCount: "",
    Template: "",
    News: "",
    LinkIncentive: "",
    LinkWithPMS: "",
    RewardsConfigWinnerposition: [],              
    RewardsConfigConsolation: [],
    CanConsolationRecieveCetificates: "",
    locationSection: {
      locationArray: [],
      selectedLocationArray: [],
      reasonLocation: ""
    },
    departmentSection: {
      departmentArray: [],
      selectedDepartmentArray: [],
      reasonDepartment: ""
    },
    deptWiseEmployeesSection: {
      employeesArray: [],
      selectedemployeesArray: [],
      deptWiseReasonEmployees: ""
    },
    employeesSection: {
      employeesArray: [],
      selectedemployeesArray: [],
      reasonEmployees: ""
    },
    Reward_Startdate: "",
    Reward_Enddate: "",
    Award_announcement_date: "",
    Target_Startdate: "",
    Target_Enddate: "",
    SelfReview_Startdate: "",
    SelfReview_Enddate: "",
    ManagerReview_Startdate: "",
    ManagerReview_Enddate: "",
    HodReview_Startdate: "",
    HodReview_Enddate: "",
    HRReview_Startdate: "",
    HRReview_Enddate: ""
  })

  console.log("object initialValues", initialValues)
  useEffect(() => {
    if (data) {
      if (formValues?.WinnersCount) {
        const winnersArray = new Array(Number(formValues?.WinnersCount) || 0).fill(0);
        const consolationArray = new Array(1).fill(0);
        setInitialValues((prev) => {
          const objFinal = {
            ...formValues,
            WinnersCount: formValues?.WinnersCount,
            RewardsConfigWinnerposition: winnersArray?.map((it, ind) => {
              const obj = {}
              obj[`Position${ind + 1}`] = formValues?.RewardsConfigWinnerposition[ind]?.[`Position${ind + 1}`]
              obj[`Position${ind + 1}textArea`] = formValues?.RewardsConfigWinnerposition[ind]?.[`Position${ind + 1}textArea`]
              return obj;
            }),
            RewardsConfigConsolation: consolationArray?.map((it, ind) => {
              const obj = {}
              obj[`Position${ind + 1}`] = formValues?.RewardsConfigConsolation[ind]?.[`Position${ind + 1}`]
              obj[`Position${ind + 1}textArea`] = formValues?.RewardsConfigConsolation[ind]?.[`Position${ind + 1}textArea`]
              return obj;
            }),
          };
          return objFinal;

        })
      }
    }
    else {
      if (formValues?.WinnersCount) {
        const winnersArray = new Array(Number(formValues?.WinnersCount) || 0).fill(0);
        const consolationArray = new Array(1).fill(0);

        setInitialValues((prev) => {
          const objFinal = {
            ...formValues,
            WinnersCount: formValues?.WinnersCount,
            RewardsConfigWinnerposition: winnersArray?.map((it, ind) => {
              const obj = {}
              obj[`Position${ind + 1}`] = formValues?.RewardsConfigWinnerposition[ind]?.[`Position${ind + 1}`]
              obj[`Position${ind + 1}textArea`] = formValues?.RewardsConfigWinnerposition[ind]?.[`Position${ind + 1}textArea`]
              return obj;
            }),
            RewardsConfigConsolation: consolationArray?.map((it, ind) => {
              const obj = {}
              obj[`Position${ind + 1}`] = formValues?.RewardsConfigConsolation[ind]?.[`Position${ind + 1}`]
              obj[`Position${ind + 1}textArea`] = formValues?.RewardsConfigConsolation[ind]?.[`Position${ind + 1}textArea`]
              return obj;
            }),
          };
          return objFinal;

        })
      }
    }

  }, [formValues?.WinnersCount])


  console.log("this is form value77777777777777777777777777777", formValues)

  useEffect(() => {
    // debugger
    if (data) {
      getFormDetails();
    }
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Reward_ConfigController/Create", values);
      console.log("rewards create data", result)
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Reward_ConfigController/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = `PeopleSol -  Create Reward `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);



  // const validationSchema = Yup.object({
  //   title: Yup.string()
  //     .max(149, "Title must be less than 150 characters")
  //     .required("Please Enter The Question"),
  //   cycleId: Yup.string().required("Please Select The Cycle"),
  //   awardFor: Yup.string()
  //     .max(149, "Award For must be less than 150 characters")
  //     .required("Please Enter Award For"),
  //   noOfWinners: Yup.string().required("Please Enter No. Of Winners"),
  //   nominationsCanBeSubmittedBy: Yup.string().required(
  //     "Please Select Nomination Selectors"
  //   ),
  //   eligibilityCriteria: Yup.string()
  //     .max(149, "Eligibility Criteria must be less than 150 characters")
  //     .required("Please Enter Eligibility Criteria"),
  //   requestlist: Yup.array().of(
  //     Yup.object().shape({
  //       question: Yup.string()
  //         .max(299, "Name must be less than 300 characters")
  //         .required("Required"),
  //       isMandatory: Yup.string().required("Required"),
  //     })
  //   ),
  // });

  function RewardsConfigWinnerposition(values) {
    // const ConsolationCount = values?.ConsolationCount;
    let count = values.ConsolationCount;
    let newArray = Array.from({ length: count }, (_, i) => i + 1);
    let ConsolationCount = newArray?.map((item, ind) => ({
      IsConsolation: true,
      Position: 0,
      Category: values?.RewardsConfigConsolation[0]?.Position1,
      IsConsolation_certificate: values?.CanConsolationRecieveCetificates === "Yes" ? true : false,
      description: values?.RewardsConfigConsolation[0]?.Position1textArea,
    }))

    const rewardsPositionValue = values.RewardsConfigWinnerposition.map((item, index) => ({
      IsConsolation: false,
      Category: item["Position" + `${index + 1}`],
      description: item["Position" + `${index + 1}textArea`],
      Position: index + 1,
      IsConsolation_certificate: values?.CanConsolationRecieveCetificates === "Yes" ? true : false,

    }))
    const finalArray = [...rewardsPositionValue, ...ConsolationCount]
    return finalArray;
  }

  function socialArray(values) {
    let newArray = [];

    if (values?.News === true) {
      newArray.push("News");
    }
    if (values?.Noticeboard === true) {
      newArray.push("Noticeboard");
    }
    if (values?.social === true) {
      newArray.push("social");
    }

    return newArray.map(item => item.toLowerCase()).join(", ");;
  }

  const onSubmit = (values) => {
    // debugger
    const rewardsData = {
      id: data ? data : 0,
      Title: values?.Title,
      Description: values?.Description,
      IsAlldepartment: values?.RewardParticipation === "All Department" ? true : false,
      IsAlllocations: values?.RewardParticipation === "All Department" ? true : false,
      WinnersCount: Number(values?.WinnersCount),
      ConsolationCount: Number(values?.ConsolationCount),
      Template: values?.Template,
      Share_social: socialArray(values),
      Link_incentive: values?.LinkIncentive === "yes" ? true : false,
      Link_pms: values?.LinkWithPMS === "yes" ? true : false,
      Reason_removeEmployee: values?.employeesSection?.reasonEmployees,
      RewardsConfigWinnerposition: RewardsConfigWinnerposition(values),
      RewardsElligible: [{
        IsAllDepartment: values?.RewardParticipation ? true : false,
        RemovedEmployees: values?.employeesSection?.selectedemployeesArray,
      }],
      RewardsDates: [
        {
          Reward_Startdate: format(new Date(values?.Reward_Startdate), "yyyy-MM-dd") +
            "T00:00:00.000",
          Reward_Enddate: format(new Date(values?.Reward_Enddate), "yyyy-MM-dd") +
            "T00:00:00.000",
          Award_announcement_date: format(new Date(values?.Award_announcement_date), "yyyy-MM-dd") +
            "T00:00:00.000",
          Target_Startdate: format(new Date(values?.Target_Startdate), "yyyy-MM-dd") +
            "T00:00:00.000",
          Target_Enddate: format(new Date(values?.Target_Enddate), "yyyy-MM-dd") +
            "T00:00:00.000",
          SelfReview_Startdate: format(new Date(values?.SelfReview_Startdate), "yyyy-MM-dd") +
            "T00:00:00.000",
          SelfReview_Enddate: format(new Date(values?.SelfReview_Enddate), "yyyy-MM-dd") +
            "T00:00:00.000",
          ManagerReview_Startdate: format(new Date(values?.ManagerReview_Startdate), "yyyy-MM-dd") +
            "T00:00:00.000",
          ManagerReview_Enddate: format(new Date(values?.ManagerReview_Enddate), "yyyy-MM-dd") +
            "T00:00:00.000",
          HodReview_Startdate: format(new Date(values?.HodReview_Startdate), "yyyy-MM-dd") +
            "T00:00:00.000",
          HodReview_Enddate: format(new Date(values?.HodReview_Enddate), "yyyy-MM-dd") +
            "T00:00:00.000",
          HRReview_Startdate: format(new Date(values?.HRReview_Startdate), "yyyy-MM-dd") +
            "T00:00:00.000",
          HRReview_Enddate: format(new Date(values?.HRReview_Enddate), "yyyy-MM-dd") +
            "T00:00:00.000",
        }
      ],

    };

    const rewardsDeptWiseData = {
      ...rewardsData,
      Departments_IDs: values?.departmentSection?.selectedDepartmentArray.join(','),
      Locations_IDs: values?.locationSection?.selectedLocationArray.join(','),
      Reason_selectDepartment: values?.departmentSection?.reasonDepartment,
      IsdepartmentWise: values?.RewardParticipation === "Department wise" ? true : false,
      RewardsElligible: [{
        IsAllDepartment: values?.RewardParticipation === "Department wise" ? false : true,
        RemovedEmployees: values?.deptWiseEmployeesSection?.selectedemployeesArray,
      }],

    }
    rewardsData["Title"] = values?.Title
    console.log("this is create form values", values)

    data ? (values?.RewardParticipation === "All Department"
      ? Edit(rewardsData)
      : Edit(rewardsDeptWiseData))
      : (values?.RewardParticipation === "All Department"
        ? createPageData(rewardsData)
        : createPageData(rewardsDeptWiseData));
  };

  const navigate = useNavigate();
  console.log("formValues", formValues)

  const returnPage = () => {
    navigate(-1)
    // ("/pmsmaster/rewards");
  };
  return (
    <>
      {data ?
        (type === "quantitative" ? (
          <>
            <FormikMultiStepForm
              initialValues={initialValues}
              onSubmit={async (values) => {
                onSubmit(values);
              }}
              returnPage={returnPage}
              heading={data ? "Edit Rewards" : "Create Rewards"}
              submitText={data ? "Update Rewards" : "Create Rewards"}
              loading={loading}
              chooseFormValues={chooseFormValues}
            >
              <FormikMultiStepFormStep
                label="Set Rewards"
                validationSchema={Yup.object({
                  Title: Yup.string()
                    .max(200, "title must be less then 201 characters")
                    .required("Please Enter Title"),
                  Description: Yup.string()
                    .max(499, "Description must be less then 500 characters")
                    .required("Please Enter Description"),
                  WinnersCount: Yup.number().required(
                    "Please Enter Number of Winners"
                  ),
                  ConsolationCount: Yup.number().required(
                    "pleas Enter Number of consolation"
                  ),
                  RewardParticipation: Yup.string().required(
                    "Please Select Reward Participation"
                  ),
                  Template: Yup.string().required(
                    "Please Select Certificates"
                  ),

                })}
              >
                <CreateRewardSetReward values={formValues} data={detail} />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep
                label="Winner Position"
                validationSchema={Yup.object({
                  CanConsolationRecieveCetificates: Yup.string().required(
                    "Please Select Consolation Certificates"),
                })}
              >
                <CreateRewardWinnerPosition
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  values={formValues}
                  setFormValues={setFormValues}
                  editID={data ?? 0}
                  data={detail}
                />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep label="Eligible Employees">
                <CreateRewardEligibleEmp
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  values={formValues}
                  editID={data ?? 0}
                  detail={detail}
                />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep
                label="Select Dates"
                validationSchema={
                  Yup.object({
                    Reward_Startdate: Yup.date().required(
                      "Please select rewards start date"
                    ),

                    Target_Startdate: Yup.date().required(
                      "Please select target start date"
                    ).test(
                      "is-greater",
                      "target Start date must be greater than rewards start date",
                      function (value) {
                        const targetStartDate = formValues?.Reward_Startdate;
                        const startDate = value;

                        if (targetStartDate && startDate) {
                          return new Date(startDate) >= new Date(targetStartDate);
                        }
                        return true;
                      }
                    ),

                    Target_Enddate: Yup.date().required(
                      "Please select target end date"
                    ).test(
                      "is-greater",
                      "target end date must be greater than rewards start date",
                      function (value) {
                        const targetStartDate = formValues?.Target_Startdate;
                        const endDate = value;

                        if (targetStartDate && endDate) {
                          return new Date(endDate) >= new Date(targetStartDate);
                        }
                        return true;
                      }
                    )
                    ,
                    SelfReview_Startdate: Yup.date().required(
                      "Please select self review start date"
                    ).test(
                      "is-greater",
                      "self review start date must be greater than target end date",
                      function (value) {
                        const targetEndDate = formValues?.Target_Enddate;
                        const startDate = value;

                        if (targetEndDate && startDate) {
                          return new Date(startDate) >= new Date(targetEndDate);
                        }
                        return true;
                      }
                    ),

                    SelfReview_Enddate: Yup.date().required(
                      "Please select self review end date"
                    ).test(
                      "is-greater",
                      "selfreview end date must be greater than self review start date",
                      function (value) {
                        const selfReviewstartDate = formValues?.SelfReview_Startdate;
                        const endDate = value;

                        if (selfReviewstartDate && endDate) {
                          return new Date(endDate) >= new Date(selfReviewstartDate);
                        }
                        return true;
                      }
                    ),

                    ManagerReview_Startdate: Yup.date().required(
                      "Please select manager review start date"
                    ).test(
                      "is-greater",
                      "manager review start date must be greater than selfreview end date",
                      function (value) {
                        const SelfReview_Enddate = formValues?.SelfReview_Enddate;
                        const startDate = value;

                        if (SelfReview_Enddate && startDate) {
                          return new Date(startDate) >= new Date(SelfReview_Enddate);
                        }
                        return true;
                      }
                    ),

                    ManagerReview_Enddate: Yup.date().required(
                      "Please select manager review end date"
                    ).test(
                      "is-greater",
                      "manager review end date must be greater than manager review start date",
                      function (value) {
                        const managerReviewStartDate = formValues?.ManagerReview_Startdate;
                        const endDate = value;

                        if (managerReviewStartDate && endDate) {
                          return new Date(endDate) >= new Date(managerReviewStartDate);
                        }
                        return true;
                      }
                    ),

                    HodReview_Startdate: Yup.date().required(
                      "Please select hod review start date"
                    ).test(
                      "is-greater",
                      "hod review start date must be greater than manager review end date",
                      function (value) {
                        const ManagerReview_Enddate = formValues?.ManagerReview_Enddate;
                        const startDate = value;

                        if (ManagerReview_Enddate && startDate) {
                          return new Date(startDate) >= new Date(ManagerReview_Enddate);
                        }
                        return true;
                      }
                    ),

                    HodReview_Enddate: Yup.date().required(
                      "Please select hod review end date"
                    ).test(
                      "is-greater",
                      "hod review end date must be greater than hod review start date",
                      function (value) {
                        const HodReview_Startdate = formValues?.HodReview_Startdate;
                        const endDate = value;

                        if (HodReview_Startdate && endDate) {
                          return new Date(endDate) >= new Date(HodReview_Startdate);
                        }
                        return true;
                      }
                    ),

                    HRReview_Startdate: Yup.date().required(
                      "Please select hr review start date"
                    ).test(
                      "is-greater",
                      "hr review start date must be greater than hod review end date",
                      function (value) {
                        const HodReview_Enddate = formValues?.HodReview_Enddate;
                        const startDate = value;

                        if (HodReview_Enddate && startDate) {
                          return new Date(startDate) >= new Date(HodReview_Enddate);
                        }
                        return true;
                      }
                    ),

                    HRReview_Enddate: Yup.date().required(
                      "Please select hr review end date"
                    ).test(
                      "is-greater",
                      "hr review end date must be greater than hr review start date",
                      function (value) {
                        const HRReview_Startdate = formValues?.HRReview_Startdate;
                        const endDate = value;

                        if (HRReview_Startdate && endDate) {
                          return new Date(endDate) >= new Date(HRReview_Startdate);
                        }
                        return true;
                      }
                    ),

                    Award_announcement_date: Yup.date().required(
                      "Please select award announcement date"
                    ).test(
                      "is-greater",
                      "areward announcement date must be greater than hr review end date",
                      function (value) {
                        const HRReview_Enddate = formValues?.HRReview_Enddate;
                        const endDate = value;

                        if (HRReview_Enddate && endDate) {
                          return new Date(endDate) >= new Date(HRReview_Enddate);
                        }
                        return true;
                      }
                    ),
                    Reward_Enddate: Yup.date().required(
                      "Please select rewards end date"
                    ).test(
                      "is-greater",
                      "reward end date must be greater than reward announcement date",
                      function (value) {
                        const Award_announcement_date = formValues?.Award_announcement_date;
                        const endDate = value;
                        console.log("rewards end date", endDate)

                        if (Award_announcement_date && endDate) {
                          return new Date(endDate) >= new Date(Award_announcement_date);
                        }
                        return true;
                      }
                    ),
                  })
                }

              >
                <CreateRewardSelectDate
                  values={formValues}
                  editID={data ?? 0}
                  data={detail}
                />
              </FormikMultiStepFormStep>

            </FormikMultiStepForm>
          </>
        ) : dataFromNext?.id === 2 ? (
          <>
            <FormikMultiStepForm
              styles={{ width: "50%" }}
              initialValues={initialValues}
              onSubmit={async (values) => {
                onSubmit(values);
              }}
              returnPage={returnPage}
              heading={data ? "Edit Rewards" : "Create Rewards"}
              submitText={data ? "Update Rewards" : "Create Rewards"}
              loading={loading}
              chooseFormValues={chooseFormValues}
            >
              <FormikMultiStepFormStep label="Set Rewards">
                <CreateRewardQualitative values={formValues} data={detail} />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep label="Set Award">
                <CreateSetAward
                  values={formValues}
                  editID={data ?? 0}
                  data={detail}
                />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep label="Employee Nomination">
                <Rewardemployeenomination
                  values={formValues}
                  editID={data ?? 0}
                  data={detail}
                />
              </FormikMultiStepFormStep>
            </FormikMultiStepForm>
          </>
        ) : (""))
        :
        (dataFromNext?.id === 1 ? (
          <>
            <FormikMultiStepForm
              initialValues={initialValues}
              onSubmit={async (values) => {
                onSubmit(values);
              }}
              returnPage={returnPage}
              heading={data ? "Edit Rewards" : "Create Rewards"}
              submitText={data ? "Update Rewards" : "Create Rewards"}
              loading={loading}
              chooseFormValues={chooseFormValues}
            >
              <FormikMultiStepFormStep
                label="Set Rewards"
                validationSchema={Yup.object({
                  Title: Yup.string()
                    .max(200, "title must be less then 201 characters")
                    .required("Please Enter Title"),
                  Description: Yup.string()
                    .max(499, "Description must be less then 500 characters")
                    .required("Please Enter Description"),
                  WinnersCount: Yup.number().required(
                    "Please Enter Number of Winners"
                  ),
                  ConsolationCount: Yup.number().required(
                    "pleas Enter Number of consolation"
                  ),
                  RewardParticipation: Yup.string().required(
                    "Please Select Reward Participation"
                  ),
                  Template: Yup.string().required(
                    "Please Select Certificates"
                  ),
                  // LinkIncentive: Yup.string().required(
                  //   "Please select Link Incentive"
                  // ),
                  // LinkWithPMS: Yup.string().required(
                  //   "Please Select Link With PMS"
                  // ),
                  // News: Yup.string().required("Pleas Select News"),
                  // Noticeboard: Yup.string().required("Pleas Select Noticeboard"),
                  // social: Yup.string().required("Please Select Social"),
                })}
              >
                <CreateRewardSetReward values={formValues} data={detail} />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep
                label="Winner Position"
                validationSchema={Yup.object({
                  CanConsolationRecieveCetificates: Yup.string().required(
                    "Please Select Consolation Certificates"),
                })}
              >
                <CreateRewardWinnerPosition
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  values={formValues}
                  setFormValues={setFormValues}
                  editID={data ?? 0}
                  data={detail}
                />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep label="Eligible Employees">
                <CreateRewardEligibleEmp
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  values={formValues}
                  editID={data ?? 0}
                  data={detail}
                />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep
                label="Select Dates"
                validationSchema={
                  Yup.object({
                    Reward_Startdate: Yup.date().required(
                      "Please select rewards start date"
                    ),

                    Target_Startdate: Yup.date().required(
                      "Please select target start date"
                    ).test(
                      "is-greater",
                      "target Start date must be greater than rewards start date",
                      function (value) {
                        const targetStartDate = formValues?.Reward_Startdate;
                        const startDate = value;

                        if (targetStartDate && startDate) {
                          return new Date(startDate) >= new Date(targetStartDate);
                        }
                        return true;
                      }
                    ),

                    Target_Enddate: Yup.date().required(
                      "Please select target end date"
                    ).test(
                      "is-greater",
                      "target end date must be greater than rewards start date",
                      function (value) {
                        const targetStartDate = formValues?.Target_Startdate;
                        const endDate = value;

                        if (targetStartDate && endDate) {
                          return new Date(endDate) >= new Date(targetStartDate);
                        }
                        return true;
                      }
                    )
                    ,
                    SelfReview_Startdate: Yup.date().required(
                      "Please select self review start date"
                    ).test(
                      "is-greater",
                      "self review start date must be greater than target end date",
                      function (value) {
                        const targetEndDate = formValues?.Target_Enddate;
                        const startDate = value;

                        if (targetEndDate && startDate) {
                          return new Date(startDate) >= new Date(targetEndDate);
                        }
                        return true;
                      }
                    ),

                    SelfReview_Enddate: Yup.date().required(
                      "Please select self review end date"
                    ).test(
                      "is-greater",
                      "selfreview end date must be greater than self review start date",
                      function (value) {
                        const selfReviewstartDate = formValues?.SelfReview_Startdate;
                        const endDate = value;

                        if (selfReviewstartDate && endDate) {
                          return new Date(endDate) >= new Date(selfReviewstartDate);
                        }
                        return true;
                      }
                    ),

                    ManagerReview_Startdate: Yup.date().required(
                      "Please select manager review start date"
                    ).test(
                      "is-greater",
                      "manager review start date must be greater than selfreview end date",
                      function (value) {
                        const SelfReview_Enddate = formValues?.SelfReview_Enddate;
                        const startDate = value;

                        if (SelfReview_Enddate && startDate) {
                          return new Date(startDate) >= new Date(SelfReview_Enddate);
                        }
                        return true;
                      }
                    ),

                    ManagerReview_Enddate: Yup.date().required(
                      "Please select manager review end date"
                    ).test(
                      "is-greater",
                      "manager review end date must be greater than manager review start date",
                      function (value) {
                        const managerReviewStartDate = formValues?.ManagerReview_Startdate;
                        const endDate = value;

                        if (managerReviewStartDate && endDate) {
                          return new Date(endDate) >= new Date(managerReviewStartDate);
                        }
                        return true;
                      }
                    ),

                    HodReview_Startdate: Yup.date().required(
                      "Please select hod review start date"
                    ).test(
                      "is-greater",
                      "hod review start date must be greater than manager review end date",
                      function (value) {
                        const ManagerReview_Enddate = formValues?.ManagerReview_Enddate;
                        const startDate = value;

                        if (ManagerReview_Enddate && startDate) {
                          return new Date(startDate) >= new Date(ManagerReview_Enddate);
                        }
                        return true;
                      }
                    ),

                    HodReview_Enddate: Yup.date().required(
                      "Please select hod review end date"
                    ).test(
                      "is-greater",
                      "hod review end date must be greater than hod review start date",
                      function (value) {
                        const HodReview_Startdate = formValues?.HodReview_Startdate;
                        const endDate = value;

                        if (HodReview_Startdate && endDate) {
                          return new Date(endDate) >= new Date(HodReview_Startdate);
                        }
                        return true;
                      }
                    ),

                    HRReview_Startdate: Yup.date().required(
                      "Please select hr review start date"
                    ).test(
                      "is-greater",
                      "hr review start date must be greater than hod review end date",
                      function (value) {
                        const HodReview_Enddate = formValues?.HodReview_Enddate;
                        const startDate = value;

                        if (HodReview_Enddate && startDate) {
                          return new Date(startDate) >= new Date(HodReview_Enddate);
                        }
                        return true;
                      }
                    ),

                    HRReview_Enddate: Yup.date().required(
                      "Please select hr review end date"
                    ).test(
                      "is-greater",
                      "hr review end date must be greater than hr review start date",
                      function (value) {
                        const HRReview_Startdate = formValues?.HRReview_Startdate;
                        const endDate = value;

                        if (HRReview_Startdate && endDate) {
                          return new Date(endDate) >= new Date(HRReview_Startdate);
                        }
                        return true;
                      }
                    ),

                    Award_announcement_date: Yup.date().required(
                      "Please select award announcement date"
                    ).test(
                      "is-greater",
                      "areward announcement date must be greater than hr review end date",
                      function (value) {
                        const HRReview_Enddate = formValues?.HRReview_Enddate;
                        const endDate = value;

                        if (HRReview_Enddate && endDate) {
                          return new Date(endDate) >= new Date(HRReview_Enddate);
                        }
                        return true;
                      }
                    ),
                    Reward_Enddate: Yup.date().required(
                      "Please select rewards end date"
                    ).test(
                      "is-greater",
                      "reward end date must be greater than reward announcement date",
                      function (value) {
                        const Award_announcement_date = formValues?.Award_announcement_date;
                        const endDate = value;
                        console.log("rewards end date", endDate)

                        if (Award_announcement_date && endDate) {
                          return new Date(endDate) >= new Date(Award_announcement_date);
                        }
                        return true;
                      }
                    ),
                  })
                }
              >
                <CreateRewardSelectDate
                  values={formValues}
                  editID={data ?? 0}
                  data={detail}
                />
              </FormikMultiStepFormStep>

            </FormikMultiStepForm>
          </>
        ) : dataFromNext?.id === 2 ? (
          <>
            <FormikMultiStepForm
              styles={{ width: "50%" }}
              initialValues={initialValues}
              onSubmit={async (values) => {
                onSubmit(values);
              }}
              returnPage={returnPage}
              heading={data ? "Edit Rewards" : "Create Rewards"}
              submitText={data ? "Update Rewards" : "Create Rewards"}
              loading={loading}
              chooseFormValues={chooseFormValues}
            >
              <FormikMultiStepFormStep label="Set Rewards">
                <CreateRewardQualitative values={formValues} data={detail} />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep label="Set Award">
                <CreateSetAward
                  values={formValues}
                  editID={data ?? 0}
                  data={detail}
                />
              </FormikMultiStepFormStep>

              <FormikMultiStepFormStep label="Employee Nomination">
                <Rewardemployeenomination
                  values={formValues}
                  editID={data ?? 0}
                  data={detail}
                />
              </FormikMultiStepFormStep>
            </FormikMultiStepForm>
          </>
        ) : (""))
      }
    </>
  );
};

export { CreateReward };

import React, { useEffect } from "react";
import DropdownArrowChatMesssage from "../../../Assets/DropdownArrowChatMesssage.svg";
import starWhite from "../../../Assets/starWhite.svg";
import starYellow from "../../../Assets/starYellow.svg";
import DropdownArrowChatMesssageWhite from "../../../Assets/DropdownArrowChatMesssageWhite.svg";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import PhotosAndVideos from "./PhotosAndVideos";
import DocumentsChat from "./DocumentsChat";
import { useState, useRef } from "react";
const MediaTypeMessageComponent = ({
  setSelectedMessage,
  message,
  messageClass,
  setPopupOpen,
  popupOpen,
  setReplyData,
  setForwardData,
  toggleBookMark,
  deleteMessage,
  LoggedInUser,
  messageType,
  ViewFullscreen,
  handleReplyPrivately,
  bookMarkMessage,
  activeChat,
  newSocket,
  viewGroup,
  handleStoryFromChat
}) => {


  if (message.type == 10 || message.type == 9 || message.type == 14 || message.type === 12 || message.type == 8 || message.type === 6 || message.type === 3 || message.type === 2 || message.type == 1 || message?.replyMsgObj?.type == 14 || message?.replyMsgObj?.type == 12 || message?.replyMsgObj?.type == 10 || message?.replyMsgObj?.type == 8 || message?.replyMsgObj?.type == 3 || message?.replyMsgObj?.type == 2 || message?.replyMsgObj?.type == 1) {
    //Media upload for audio: 1 / video: 2 / image: 3 / document: 7 / link: 8 / contact: 10 / zip: 11 / gif: 13
    return (
      <PhotosAndVideos
        handleStoryFromChat={handleStoryFromChat}
        setSelectedMessage={setSelectedMessage}
        messageType={messageType}
        message={message}
        viewGroup={viewGroup}
        messageClass={messageClass}
        setPopupOpen={setPopupOpen}
        popupOpen={popupOpen}
        setReplyData={setReplyData}
        setForwardData={setForwardData}
        toggleBookMark={toggleBookMark}
        deleteMessage={deleteMessage}
        LoggedInUser={LoggedInUser}
        ViewFullscreen={ViewFullscreen}
        bookMarkMessage={bookMarkMessage}
        handleReplyPrivately={handleReplyPrivately}
        activeChat={activeChat}
        newSocket={newSocket}

      />
    );
  } else if (messageType === 7 || message?.replyMsgObj?.type == 7 || message.type == 11 || message?.replyMsgObj?.type == 11) {
    return (
      <DocumentsChat
        messageType={messageType}
        handleReplyPrivately={handleReplyPrivately}
        message={message}
        messageClass={messageClass}
        setPopupOpen={setPopupOpen}
        popupOpen={popupOpen}
        setReplyData={setReplyData}
        setForwardData={setForwardData}
        toggleBookMark={toggleBookMark}
        deleteMessage={deleteMessage}
        LoggedInUser={LoggedInUser}
        activeChat={activeChat}
        setSelectedMessage={setSelectedMessage}
        bookMarkMessage={bookMarkMessage}
      />
    );
  }
};

export default MediaTypeMessageComponent;

import React, { useEffect, useRef, useState } from "react";
import "./Table.css";
import { IconButton, Tooltip } from "@mui/material";
import { CheckBoxCustom } from "../FormComponent/Index.js";
import { useDispatch } from "react-redux";
import { showToast } from "../../Features";
import { format } from "date-fns";
import OutsideClickHandler from "../OutsideClickHandler/OutsideClickHandler";
import Nodata from "../../Assets/Nodata.svg";
import Select from "../../Assets/ChatSelectedCheck.svg"
const Table = ({
  tbodyData,
  actionData,
  headerAction,
  sortType,
  sortParam,
  tableFilterName,
  selectedRows,
  addToSelectedRows = "not given",
  selectAll = "not given",
  onlyTable,
  originalData,
  containStatus,
  moreActions,
  moreActionList,
}) => {
  tbodyData = tbodyData ? (tbodyData.length > 0 ? tbodyData : false) : false;
  const dispatch = useDispatch();
  const initialFilter =
    localStorage.getItem(tableFilterName) === null ||
      localStorage.getItem(tableFilterName).length === 0
      ? []
      : localStorage
        .getItem(tableFilterName)
        .split(",")
        .map((item) => (item = parseInt(item)));
  const [sliceIndex, setSliceIndex] = useState(initialFilter);
  const [filterToogle, setFilterToogle] = useState(false);
  const [statusIndex, setStatusIndex] = useState(false);
  const [moreActionPopup, setMoreActionPopup] = useState({
    isActive: false,
    Id: 0,
  });
  const theadData = tbodyData ? Object.keys(tbodyData[0]).slice(1) : null;
  useEffect(() => {
    if (containStatus) {
      if (theadData) {
        setStatusIndex(theadData.findIndex((x) => x === "status"));
      }
    }
  }, [containStatus, theadData]);
  const filteredHeading = tbodyData
    ? Object.keys(tbodyData[0])
      .slice(1)
      .filter((d, i) => sliceIndex.indexOf(i) == -1)
    : null;
  const focusPoint = useRef(null);
  const [isTableScrolled, setIsTableScrolled] = useState(false);
  const onScroll = () => {
    let scrollX = focusPoint.current.scrollLeft;
    if (scrollX > 0) {
      setIsTableScrolled(true);
    } else {
      setIsTableScrolled(false);
    }
  };
  console.log("addToSelectedRows", addToSelectedRows);
  const camelToCapital = (key) => {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  const headerFilter = (e, index) => {
    if (e.target.checked) {
      setSliceIndex((prev) =>
        prev.filter(function (eachElem) {
          return eachElem !== index;
        })
      );
      localStorage.setItem(
        tableFilterName,
        sliceIndex.filter(function (eachElem) {
          return eachElem !== index;
        })
      );
    } else {
      if (sliceIndex.length !== theadData.length - 1) {
        setSliceIndex((prev) => [...prev, index]);
        localStorage.setItem(tableFilterName, [...sliceIndex, index]);
      } else {
        e.target.checked = true;
        dispatch(
          showToast({
            text: "You cannot unselect all the column headers.",
            severity: "error",
          })
        );
      }
    }
  };
  const initialFilterCheck = (index) => {
    if (initialFilter.includes(index)) {
      return false;
    } else {
      return true;
    }
  };
  const getTableDataValue = (data) => {
    let parsedDate = Date.parse(data);
    if (data === true) {
      return "Yes";
    } else if (data === false) {
      return "No";
    } else if (
      typeof data == "number" &&
      filteredHeading?.includes("NoticePeriod")
    ) {
      return `${data} days`;
    } else if (!Number.isNaN(parsedDate)) {
      try {
        if (data.includes("T") && data.includes(":")) {
          let date = new Date(data);
          return `${format(date, "dd/MM/yyyy hh:mm a")}`;
        } else {
          if (data?.length > 25) return `${data?.slice(0, 25)}...`;
          else return data;
        }
      } catch {
        if (data?.length > 25) return `${data?.slice(0, 25)}...`;
        else return data;
      }
    } else if (data?.length > 25) return `${data?.slice(0, 25)}...`;
    else return data;
  };
  if (tbodyData) {
    return (
      <div className={`tableContainer ${onlyTable && "onlyTable"}`}>
        <OutsideClickHandler
          onOutsideClick={() => {
            setFilterToogle((prev) => {
              if (prev) return !prev;
              else return prev;
            });
          }}
        >
          <div
            className={`${filterToogle
              ? "headingFilterContainerActive headingFilterContainer"
              : "headingFilterContainer"
              }`}
          >
            <div className="headingFilterHeader">
              <h5>Column Headers</h5>
              <IconButton onClick={() => setFilterToogle((prev) => !prev)}>
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7579 0.56897L0.757935 12.569"
                    stroke="#F71D1D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.757935 0.56897L12.7579 12.569"
                    stroke="#F71D1D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </div>
            <div className="headingFilterBodyContainer">
              {theadData.map((heading, index) => {
                return (
                  <CheckBoxCustom
                    key={index}
                    id={camelToCapital(heading)}
                    defaultChecked={initialFilterCheck(index)}
                    label={camelToCapital(heading)}
                    onChange={(e) => {
                      headerFilter(e, index);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </OutsideClickHandler>
        <table className="table" ref={focusPoint}>
          <colgroup>
            <col />
            <col />
            <col />
            <col style={{ width: "100%" }} />
            {theadData.slice(2).map((h, index) => {
              return <col key={index} />;
            })}
          </colgroup>
          <thead className="thead">
            <tr>
              <td title="Options" className="optionsCell">
                <IconButton onClick={() => setFilterToogle((prev) => !prev)}>
                  {!filterToogle ? (
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="10.9794"
                        cy="1.73941"
                        r="1.2"
                        transform="rotate(90 10.9794 1.73941)"
                        fill="#BFBFBF"
                      />
                      <circle
                        cx="6.17946"
                        cy="1.73941"
                        r="1.2"
                        transform="rotate(90 6.17946 1.73941)"
                        fill="#BFBFBF"
                      />
                      <circle
                        cx="1.37944"
                        cy="1.73941"
                        r="1.2"
                        transform="rotate(90 1.37944 1.73941)"
                        fill="#BFBFBF"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.7579 0.56897L0.757935 12.569"
                        stroke="#F71D1D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M0.757935 0.56897L12.7579 12.569"
                        stroke="#F71D1D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </IconButton>
              </td>
              {selectAll !== "not given" && (
                <td title="Select" className="checkCell">
                  <IconButton onClick={() => selectAll()}>
                    {selectedRows?.length === tbodyData.length ? (
                      <img src={Select} alt={"select"} />
                    ) : (
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="1.48941"
                          width="16.8417"
                          height="16.5"
                          rx="4.25"
                          stroke="#BFBFBF"
                          strokeWidth="1.5"
                        />
                      </svg>
                    )}
                  </IconButton>
                </td>
              )}
              <td
                style={selectAll === "not given" ? { left: "30px" } : {}}
                className="actionsCell"
              >
                Actions
              </td>
              {filteredHeading.map((heading, index) => {
                return (
                  <td key={index}>
                    <div className="tableHeaderCell">
                      {camelToCapital(heading)}
                      {!onlyTable && (
                        <IconButton onClick={() => headerAction(heading)}>
                          <svg
                            className={`${sortParam === heading
                              ? "headingSort headingSortActive"
                              : "headingSort"
                              }`}
                            width="6"
                            height="11"
                            viewBox="0 0 6 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className={`${sortType == "ascending" && "sortTypeActive"
                                }`}
                              d="M2.99759 0.734329L5.28394 4.39843L2.99759 4.39843L0.711235 4.39843L2.99759 0.734329Z"
                              fill="#BFBFBF"
                            />
                            <path
                              className={`${sortType == "descending" && "sortTypeActive"
                                }`}
                              d="M2.99759 10.7343L5.28394 7.07023L2.99759 7.07023L0.711235 7.07023L2.99759 10.7343Z"
                              fill="#BFBFBF"
                            />
                          </svg>
                        </IconButton>
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tbodyData.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={`${selectedRows?.some((r) => r === (item?.Id ?? item?.id))
                    ? "selectedRow"
                    : null
                    }`}
                >
                  <td className="optionsRow">{index + 1}</td>
                  {addToSelectedRows !== "not given" && (
                    <td title="Select" className="checkRow">
                      <IconButton
                        onClick={() => {
                          addToSelectedRows(item?.Id ?? item?.id);
                        }}
                      >
                        {selectedRows?.some(
                          (r) => r === (item?.Id ?? item?.id)
                        ) ? (
                          <img src={Select} alt={"select"} />
                        ) : (
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.75"
                              y="1.48941"
                              width="16.8417"
                              height="16.5"
                              rx="4.25"
                              stroke="#BFBFBF"
                              strokeWidth="1.5"
                            />
                          </svg>
                        )}
                      </IconButton>
                    </td>
                  )}
                  <td
                    style={
                      addToSelectedRows === "not given" ? { left: "30px" } : {}
                    }
                    className={`actionsRow ${moreActionPopup.isActive &&
                      (item.Id ?? item.id) === moreActionPopup.Id &&
                      "moreActionActive"
                      }`}
                  >
                    {actionData?.map((itemData, index) => {
                      return (
                        <Tooltip arrow title={itemData.action} placement="top">
                          <IconButton
                            key={index}
                            color="primary"
                            onClick={
                              itemData.clickAction
                                ? () =>
                                  itemData.clickAction(
                                    item.employeeId ?? item.id ?? item.Id
                                  )
                                : () => { }
                            }
                          >
                            {itemData.img}
                          </IconButton>
                        </Tooltip>
                      );
                    })}
                    {moreActions && (
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <IconButton
                          color="primary"
                          title={"More Actions"}
                          onClick={() =>
                            setMoreActionPopup((prev) => {
                              if (prev.isActive) {
                                if (prev.Id === (item.Id ?? item.id)) {
                                  return { isActive: false, Id: 0 };
                                } else {
                                  return {
                                    isActive: true,
                                    Id: item.Id ?? item.id,
                                  };
                                }
                              } else {
                                return {
                                  isActive: true,
                                  Id: item.Id ?? item.id,
                                };
                              }
                            })
                          }
                        >
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.264709"
                              y="0.744141"
                              width="20"
                              height="20"
                              rx="4"
                              fill="#E4E4FD"
                            />
                            <circle
                              cx="15.0649"
                              cy="10.7439"
                              r="1.2"
                              transform="rotate(90 15.0649 10.7439)"
                              fill="#8A88ED"
                            />
                            <circle
                              cx="10.2649"
                              cy="10.7439"
                              r="1.2"
                              transform="rotate(90 10.2649 10.7439)"
                              fill="#8A88ED"
                            />
                            <circle
                              cx="5.4649"
                              cy="10.7439"
                              r="1.2"
                              transform="rotate(90 5.4649 10.7439)"
                              fill="#8A88ED"
                            />
                          </svg>
                        </IconButton>
                        {moreActionPopup.isActive &&
                          moreActionPopup.Id === (item.Id ?? item.id) && (
                            <div className="moreActionContainer">
                              <div className="headingFilterHeader">
                                <h5 style={{ width: "120px" }}>Actions</h5>
                                <IconButton
                                  onClick={() =>
                                    setMoreActionPopup({
                                      isActive: false,
                                      Id: 0,
                                    })
                                  }
                                >
                                  <svg
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.7579 0.56897L0.757935 12.569"
                                      stroke="#F71D1D"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M0.757935 0.56897L12.7579 12.569"
                                      stroke="#F71D1D"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </IconButton>
                              </div>
                              {moreActionList?.map((itemData, index) => {
                                return (
                                  <div
                                    className="moreActionTile cursor"
                                    onClick={
                                      itemData.clickAction
                                        ? () =>
                                          itemData.clickAction(
                                            item.Id ?? item.id
                                          )
                                        : () => { }
                                    }
                                  >
                                    <label style={{ whiteSpace: "nowrap" }}>
                                      {itemData.actionTitle}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                      </div>
                    )}
                  </td>
                  {containStatus ? (
                    <>
                      {Object.values(item)
                        .map((cellItem, prevIndex) => {
                          return {
                            data: cellItem,
                            index: prevIndex - 1,
                            Id: item.Id ?? item.id,
                          };
                        })
                        .slice(1)
                        .filter((eachElem, index) => {
                          return sliceIndex.indexOf(index) === -1;
                        })
                        .map((data, mappingIndex) => {
                          let statusColor = originalData
                            .find((x) => x.id === data.Id)
                            .statusColor.toString();
                          return (
                            <td key={mappingIndex}>
                              {data.index === statusIndex ? (
                                <div
                                  className="statusCell"
                                  style={{ backgroundColor: statusColor }}
                                >
                                  {data.data}
                                </div>
                              ) : (
                                getTableDataValue(data.data)
                              )}
                            </td>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {Object.values(item)
                        .slice(1)
                        .filter(function (eachElem, index) {
                          return sliceIndex.indexOf(index) === -1;
                        })
                        .map((data, index) => {
                          return (
                            <td key={index}>
                              {getTableDataValue(data) ?? "-"}
                            </td>
                          );
                        })}
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="noDatastyle">
        <img
          src={Nodata}
          alt="No data"
          style={{ height: "150px", width: "250px" }}
        />
        <h4>{"No Records"}</h4>
      </div>
    );
  }
};
export { Table };

import { IconButton, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { StepsHierarchy } from '../../../Components/FormComponent/StepsHierarchy';
import { WorkflowStepsComp } from '../../../Components/FormComponent/WorkflowStepsComp';
import { isLoading, showToast } from '../../../Features';
import { getRequest } from '../../../Services/axios';
import { DetailsStep } from '../../../Components/FormComponent/DetailsStep'
import UploadDetail from '../../../Components/UploadDetail/UploadDetail';
import { Loader } from '../../../Components/FormComponent/Loader';
import { format } from 'date-fns';
import { Padding } from '@mui/icons-material';

const DetailsGallery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id",selectedId)
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")))
  }
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Albums/Details/${selectedId}`);
      setEventtData(result.data)
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  }
  useEffect(() => {
    getEmployeeData();
    return () => {
    }
  }, [])
  useEffect(() => {
    document.title = `PeopleSol - Details Gallery`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  // //console.log("eventtData",eventtData)
  if (loading) return <Loader />;
  else
    return (
      <div className="moduleMasterDetailsPage">
        <div className="moduleMasterDetailsBackground" onClick={() => closeDetails()}></div>
        <div className="moduleMasterDetailsPageContainer">
          <div className="headingContainer">
            <h3>Details</h3>
            <IconButton onClick={() => closeDetails()} title="close">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.7579 0.56897L0.757935 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M0.757935 0.56897L12.7579 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </IconButton>
          </div>
          <div className="moduleMasterDetailsPageBody">
            {/* <div className="moduleMasterDetailsBox"> */}
            <DetailsStep style={{ padding: 0, margin: 0 }} detailTitle={"Name"} Details={eventtData?.name} />
            <DetailsStep style={{ padding: 0, margin: 0 }} detailTitle={"Cover Image"} Details={<UploadDetail
              filepath={`Albums/${eventtData?.coverImage}`}
            />} />
            <DetailsStep style={{ padding: 0, margin: 0 }} detailTitle={"Description"} Details={eventtData?.description} />
            <DetailsStep style={{ padding: 0, margin: 0 }}
              detailTitle={"Created On"}
              Details={format(
                new Date(eventtData?.createdOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />
            <DetailsStep style={{ padding: 0, margin: 0 }}
              detailTitle={"Last Updated On"}
              Details={format(
                new Date(eventtData?.lastUpdatedOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />
            {/* </div> */}
            <div style={{ borderBottom: "2px solid #e6e6e6" }}>
              <h5 style={{ margin: "10px 0px", color: "rgba(18, 150, 176, 1)" }}>Gallery Photos</h5>
            </div>
            <div style={{ width: "100%" }}>
              <table className="tabless tabless-bordered">
                <thead

                  style={{
                    backgroundColor: "var(--primary-hover)",
                  }}
                >
                  <tr>
                    <td>S.no</td>
                    <td>Name</td>
                    <td>Image</td>

                  </tr>
                </thead>
                <tbody>
                  {eventtData?.albumsPhotoslist?.length > 0
                    ? eventtData?.albumsPhotoslist?.map((item, index) => {
                      return (
                        <tr className="trStyle">
                          <td>{index + 1}</td>
                          <td>{item?.description}</td>
                          <td>{<UploadDetail
                            filepath={`Albums/${item?.image}`}
                          />}</td>
                        </tr>
                      );
                    })
                    : "No Records"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )

}

export { DetailsGallery }
import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";
import { getModuleDetails } from "../../../../Utilities/getModuleDetails";
import DetailsIcon from "../../../../Assets/DetailsIcon.svg";
import { format } from "date-fns";

function GetMyHoldRequests({ navigateToRoute }) {
  const [apiholddata, setapiholddata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copyData, setCopyData] = useState([]);
  const getapidata = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Settings/GetMyHoldRequests`);
      let list = result?.data.sort((a, b) => new Date().valueOf(b.date) - new Date().valueOf(a.date))
      setapiholddata(list);
      setCopyData(list);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const setSearchParam = (val) => {
    if (val == "" || !val) {
      setCopyData(apiholddata);
    } else {
      let a = apiholddata.filter(item => ((item.requestNumber && item?.requestNumber.toLowerCase().includes(val.toLowerCase())) || (item.requester && item?.requester.toLowerCase().includes(val.toLowerCase())) || (item.module && item?.module.toLowerCase().includes(val.toLowerCase()))))

      setCopyData(a);
    }
  }

  useEffect(() => {
    getapidata();
    document.title = `PeopleSol -  Hold Requests`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : apiholddata.length > 0 ? (
        <div style={{ height: "600px" }}>
          <div style={{ height: "560px" }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "flex-end", padding: 10, paddingTop: 0 }}>
              <div className="search-box">
                <input
                  className="search-text"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchParam(e.target.value)}
                />
                <a className="search-btn" style={{ backgroundColor: "transparent" }}>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                      fill="#1296B0"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <table className="responsive-table tableHeaderRow">
              {/* Table Header */}
              <thead style={{ position: "sticky" }}>
                <tr>
                  <th>S. No</th>
                  <th></th>
                  <th>Request Number</th>
                  <th>Requester</th>
                  <th>Date</th>
                  <th>Module</th>
                </tr>
              </thead>
              <tbody>
                {copyData.map((item, index) => (
                  <tr key={item?.index || index}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        className="cursor"
                        // onClick={() => {
                        //   const moduleDetails = getModuleDetails(
                        //     item?.module,
                        //     item?.id
                        //   );
                        //   if (moduleDetails?.returnUrl) {
                        //     navigateToRoute(moduleDetails.returnUrl);
                        //   } else {
                        //   }
                        // }}
                        onClick={() => {
                          navigateToRoute(item?.url);
                        }}
                        src={DetailsIcon}
                        alt=""
                      />
                    </td>
                    <td>{item?.requestNumber}</td>
                    <td>{item?.requester}</td>
                    <td>{item?.date ? format(new Date(item?.date), "dd/MM/yyyy hh:mm a") : ""}</td>
                    <td
                      style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                      }}
                    >
                      {item?.module}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="noDatastyle" style={{ padding: "75px 105px" }}>
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>No Records</h4>
        </div>
      )}
    </>
  );
}

export default GetMyHoldRequests;

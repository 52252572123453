import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../../Components/FormComponent/PopupComponent/Remark";
import { Details } from "../../DetailsComp/Details";
import { Timeline } from "../../DetailsComp/TimeLine";
import { Notifications } from "../../DetailsComp/Notifications";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../../Features/badgeUtils";
const DetailsPageLateComing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.prevPath) {
      navigate(state.prevPath);
    } else {
      navigate(-1);
    }
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [selId, setSelId] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);
  useEffect(() => {
    document.title = `PeopleSol - Details Page Late Coming`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (id, type, color) => {
    setSelId(id);
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `LateComingRequests/Details/${getIdFromDynamicUrl(currentUrl)}`
      );

      setData(result.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              result.data?.summary?.actionlist[0]?.id,
              approve,
              result.data?.summary?.actionlist[0]?.color
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "approve"
      ) {
        await postRequest(`LateComingRequests/${type}/${id}`);
      } else if (type === "Mark as Closed") {
        await postRequest(
          `LateComingRequests/Close?Id=${id}&Remarks=${Remark}`
        );
      } else {
        await postRequest(
          `LateComingRequests/${type}?Id=${id}&Remarks=${Remark}`
        );
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Late Coming Request Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            data && (
              <>
                {tabIndex === 0 &&
                  (data?.data != null ? (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="headingForm"> Request Details</div>
                      <div className="detailInner">
                        <>
                          <Details
                            detailTitle={"Request Number"}
                            Details={data?.data?.requestNumber ?? "--"}
                          />

                          <Details
                            detailTitle={"Status"}
                            Details={
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    data?.data?.status
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    data?.data?.status
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                              >
                                {data?.summary?.status ?? "--"}
                              </div>
                            }
                          />

                          <Details
                            detailTitle={"Late Coming Date"}
                            Details={
                              data?.data?.date
                                ? format(
                                  new Date(data?.data?.date),
                                  "dd/MM/yyyy"
                                )
                                : "--"
                            }
                          />
                          <Details
                            detailTitle={"Late Coming Time"}
                            Details={data?.data?.time ?? "--"}
                          />
                          <Details
                            detailTitle={"Late Coming Reason"}
                            Details={data?.data?.reason ?? "--"}
                          />
                          <Details
                            detailTitle={"Created On"}
                            Details={
                              data?.data?.createdOn
                                ? format(
                                  new Date(data?.data?.createdOn),
                                  "dd/MM/yyyy hh:mm a"
                                )
                                : "--"
                            }
                          />
                          <Details
                            detailTitle={"Last Updated On"}
                            Details={
                              data?.data?.lastUpdatedOn
                                ? format(
                                  new Date(data?.data?.createdOn),
                                  "dd/MM/yyyy hh:mm a"
                                )
                                : "--"
                            }
                          />
                        </>
                      </div>

                      <div className="headingForm"> Requester's Details</div>

                      <div className="detailInner">
                        <Details
                          detailTitle={"Requester Name"}
                          Details={
                            data?.data?.employee?.nameWithPersonNumber ??
                            "--" ??
                            "--"
                          }
                        />
                        <Details
                          detailTitle={"Requester's Manager"}
                          Details={data?.data?.employee?.rO_Name ?? "--"}
                        />
                        <Details
                          detailTitle={"Requester Designation"}
                          Details={data?.data?.employee?.designation ?? "--"}
                        />
                        <Details
                          detailTitle={"Requester Department"}
                          Details={data?.data?.employee?.department ?? "--"}
                        />
                        <Details
                          detailTitle={"Requester Email"}
                          Details={data?.data?.employee?.email ?? "--"}
                        />
                        <Details
                          detailTitle={"Contact Number"}
                          Details={
                            data?.data?.employee?.personalPhoneNumber
                              ? data?.data?.employee?.personalPhoneNumber
                              : "--"
                          }
                        />

                        <Details
                          detailTitle={"Location"}
                          Details={data?.data?.employee?.locationName ?? "--"}
                        />
                      </div>
                      {data?.summary?.actionlist?.length ? (
                        <div
                          className="createFootDetails"
                          style={{ background: "white !important" }}
                        >
                          {data?.summary?.actionlist?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                    item.type !== "Release" &&
                                    item.type !== "Cancel"
                                    ? togglePopup(
                                      item?.id,
                                      item?.type,
                                      item?.color
                                    )
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {isOpen && (
                        <Popup
                          popupHeading={typeAction}
                          content={
                            <>
                              <Remark
                                data={PostApRejHol}
                                type={typeAction}
                                id={selId}
                                color={colorAction}
                                handleClose={togglePopup}
                              />
                            </>
                          }
                          handleClose={togglePopup}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="noDatastyle">
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Records"}</h4>
                    </div>
                  ))}
                {tabIndex === 1 && (
                  <>
                    <div className="tabularMasterDetailsPageTabBodyContainer">
                      <div style={{ display: "flex", width: "100%" }}>
                        <Timeline
                          id={getIdFromDynamicUrl(currentUrl)}
                          url={"LateComingRequests"}
                          module={data?.summary?.module}
                          requestNumber={data?.data?.requestNumber}
                        />
                      </div>
                    </div>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <div
                      className="tabularMasterDetailsPageTabBodyContainer"
                      style={{ flexGrow: "initial" }}
                    >
                      <Notifications
                        id={getIdFromDynamicUrl(currentUrl)}
                        module={data?.summary?.module}
                      />
                    </div>
                  </>
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageLateComing };

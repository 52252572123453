import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
import { format } from "date-fns";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { getBadgeCount } from "../../../../Features/badgeUtils";
import { useSelector } from "react-redux";
const TravelAdvanceDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [selId, setSelId] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);
  const togglePopup = (id, type, color) => {
    setTypeAction(type);
    setSelId(id);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelAdvanceRequests/Details/${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              approve,
              result.data?.summary?.actionlist[0]?.color,
              result.data?.summary?.actionlist[0]?.id
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Advance Travel`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const clickHandlerFunction = (id, type, color) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Ready for Release" &&
      type !== "HRReject"
    ) {
      togglePopup(id, type, color);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "HRReject"
      ) {
        await getRequest(`TravelAdvanceRequests/${type}/${id}`);
      } else if (type === "Ready for Release") {
        await getRequest(`TravelAdvanceRequests/HRApprove/${id}`);
      } else if (type === "Confirm") {
        await postRequest(`TravelAdvanceRequests/Confirm?Id=${id}`);
      } else {
        await postRequest(`TravelAdvanceRequests/${type}/${id}/${Remark}`);
      }

      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Travel Advance Request Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          <>
            {tabIndex === 0 &&
              data?.data != null &&
              (loading ? (
                <Loader />
              ) : (
                <div
                  className="detailsEReq"
                  style={{ flexDirection: "column" }}
                >
                  <div className="headingForm"> Request Details</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Reference Number"}
                      Details={data?.data?.referenceNo}
                    />
                    <Details
                      detailTitle={"Status"}
                      Details={
                        <div
                          style={{
                            backgroundColor: getStatusColor(
                              data?.data?.status
                            )?.split(",")?.[0],
                            color: getStatusColor(data?.data?.status)?.split(
                              ","
                            )?.[1],
                            cursor: "default",
                            padding: "6px",
                            borderRadius: "6px",
                          }}
                        >
                          {data?.data?.status === "Settled"
                            ? data?.data?.status
                            : data?.summary?.status}
                        </div>
                      }
                    />
                    {/* <Details
                      detailTitle={"Travel Planned Start Date"}
                      Details={
                        data?.data?.startDate
                          ? format(
                              new Date(data?.data?.startDate),
                              "dd/MM/yyyy"
                            )
                          : "-"
                      }
                    /> */}
                    {/* <Details
                      detailTitle={"Travel Planned End Date"}
                      Details={
                        data?.data?.endDate
                          ? format(new Date(data?.data?.endDate), "dd/MM/yyyy")
                          : "-"
                      }
                    /> */}
                    <Details
                      detailTitle={"Per Day Eligibility Amount"}
                      Details={
                        data?.data?.perDayEligibilty == null
                          ? "-"
                          : data?.data?.perDayEligibilty
                      }
                    />
                    <Details
                      detailTitle={"Travel Advance Amount (Rs)"}
                      Details={data?.data?.advanceSalary}
                    />
                    <Details
                      detailTitle={"Purpose of Advance"}
                      Details={data?.data?.purposeOfAdvance}
                    />

                    <Details
                      detailTitle={"Created On"}
                      Details={
                        data?.data?.createdOn
                          ? format(
                              new Date(data?.data?.createdOn),
                              "dd/MM/yyyy"
                            )
                          : "-"
                      }
                    />
                    <Details
                      detailTitle={"Last Updated On"}
                      Details={
                        data?.data?.lastUpdatedOn
                          ? format(
                              new Date(data?.data?.lastUpdatedOn),
                              "dd/MM/yyyy"
                            )
                          : "-"
                      }
                    />
                  </div>
                  <div className="headingForm"> Requester's Details</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Requester Name"}
                      Details={`${data?.data?.employee?.name} ${
                        data?.data?.employee?.lastName ?? ""
                      }`}
                    />
                    <Details
                      detailTitle={"Requester's Manager"}
                      Details={data?.data?.employee?.rO_Name}
                    />
                    <Details
                      detailTitle={"Requester Designation"}
                      Details={data?.data?.employee?.designation}
                    />
                    <Details
                      detailTitle={"Requester Department"}
                      Details={data?.data?.employee?.department}
                    />
                    <Details
                      detailTitle={"Requester Email"}
                      Details={data?.data?.employee?.email}
                    />
                    <Details
                      detailTitle={"Contact Number"}
                      Details={data?.data?.employee?.personalPhoneNumber}
                    />
                    <Details
                      detailTitle={"Location"}
                      Details={data?.data?.employee?.locationName}
                    />
                  </div>
                  {data?.summary?.actionlist?.length > 0 ? (
                    <div
                      className="createFootDetails"
                      style={{ background: "white !important" }}
                    >
                      {data?.summary?.actionlist.map((item) => (
                        <div>
                          <div
                            className="button secondaryButton"
                            onClick={() =>
                              clickHandlerFunction(
                                item?.id,
                                item?.type,
                                item?.color
                              )
                            }
                            style={{
                              background: item?.color,
                              color: "white",
                            }}
                          >
                            {item?.type}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {isOpen && (
                    <Popup
                      popupHeading={typeAction}
                      content={
                        <>
                          <Remark
                            data={PostApRejHol}
                            type={typeAction}
                            id={selId}
                            color={colorAction}
                            handleClose={togglePopup}
                          />
                        </>
                      }
                      handleClose={togglePopup}
                    />
                  )}
                </div>
              ))}
            {tabIndex === 1 && (
              <>
                <div className="tabularMasterDetailsPageTabBodyContainer">
                  <div style={{ display: "flex", width: "100%" }}>
                    <TimeLineData
                      id={getIdFromDynamicUrl(currentUrl)}
                      url={"TravelAdvanceRequests"}
                      requestNumber={data?.data?.referenceNo}
                      module={data?.summary?.module}
                    />
                  </div>
                </div>
              </>
            )}

            {tabIndex === 2 && (
              <>
                <div
                  className="tabularMasterDetailsPageTabBodyContainer"
                  style={{ flexGrow: "initial" }}
                >
                  <Notifications
                    id={getIdFromDynamicUrl(currentUrl)}
                    module={"Travel Advance Request"}
                    //module={data?.summary?.module}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export { TravelAdvanceDetails };

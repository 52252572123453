/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";

import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { format } from "date-fns";

const LoanDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const closeDetails = () => {
    returnPage();
  };

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  useEffect(() => {
    document.title = `PeopleSol - Details Page Deputaion`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PreparePayroll/LoanDetails/${selectedId}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  // Convert createdOn string to a Date object
  const createdDate = new Date(data[0]?.createdOn);

  // Adding totalRecoveryMonth months to the createdOn date
  const newDate = new Date(
    createdDate.setMonth(createdDate.getMonth() + data[0]?.totalRecoveryMonth)
  );

  console.log("data", data);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Loan Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data?.length && (
            <>
              {tabIndex === 0 &&
                (data.length ? (
                  loading ? (
                    <div className="noDataFound">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          padding: "12px 25px",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >{`${data[0]?.type} : ${data[0]?.amount}`}</div>
                      <div className="tabDeHead" style={{ gap: "4px" }}>
                        <p
                          style={{ fontSize: "15px", fontWeight: 500 }}
                        >{`Loan : ${data[0]?.requestNumber}`}</p>{" "}
                        <div
                          style={{
                            backgroundColor: getStatusColor(
                              data[0]?.status
                            )?.split(",")?.[0],
                            color: getStatusColor(data[0]?.status)?.split(
                              ","
                            )?.[1],
                            cursor: "default",
                            padding: "6px",
                            borderRadius: "6px",
                          }}
                        >
                          {data[0]?.status}
                        </div>
                      </div>
                      <div
                        className="detailsEReq"
                        style={{ flexDirection: "column", marginTop: "1px" }}
                      >
                        <div className="detailInner">
                          <Details
                            detailTitle={"Loan Amount"}
                            Details={data[0]?.amount}
                          />
                          <Details
                            detailTitle={"Next Instalment Date"}
                            Details={"Sep-24"}
                          />
                          {/* <Details
                            detailTitle={"Disbursment"}
                            Details={
                              data[0]?.requiredByDate &&
                              format(
                                new Date(data[0]?.requiredByDate),
                                "dd/MM/yyyy"
                              )
                            }
                          /> */}
                          <Details
                            detailTitle={"Loan closing Date"}
                            Details={
                              newDate &&
                              format(new Date(newDate), "dd/MM/yyyy")
                            }
                          />
                          <Details
                            detailTitle={"Total Recovery Month"}
                            Details={data[0]?.totalRecoveryMonth}
                          />
                          <Details
                            detailTitle={"Reason"}
                            Details={data[0]?.reason}
                          />
                          <Details
                            detailTitle={"Installment Amount"}
                            Details={data[0]?.perMonthDeduction}
                          />
                        </div>
                        {/* <div
                          style={{
                            padding: "12px 25px",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          Loan Repayment Summary
                        </div> */}
                      </div>
                    </>
                  )
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Records"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimeLineData
                        id={selectedId}
                        module={data?.generalWorkflowsApprovals?.module}
                        requestNumber={data?.requestNumber}
                        url={"DeputationRequests"}
                      />
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications
                      id={selectedId}
                      module={"Deputation Request"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { LoanDetails };

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ColorFormik } from "../../../../Components/FormComponent/ColorFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";

const opt = [{ label: "select", value: null }];
const ServiceCategoryFormIt = ({ data }) => {
  const [optionWork, setOptionWork] = React.useState([]);
  const [employee, setEmployee] = useState([]);
  const dispatch = useDispatch();
  const getEmpl = async () => {
    try {
      const result = await getRequest("Facilities/GetEmployeeName");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.employeeID });
      });
      setEmployee(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Service Category`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getOptionWorkflow = async () => {
    try {
      const result = await getRequest("ItsmCRWorkflows/GetWorkflows");
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setOptionWork(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getEmpl();
    getOptionWorkflow();
  }, []);
  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Change Owner"} />
        <SelectForm
          values={
            data
              ? employee.filter((options) => options?.label === data?.CROwner)
              : opt
          }
          options={employee}
          name={"changeOwnerEmployeeId"}
          placeholder={" Select  Change Owner "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Change Request Workflow"} />
        <SelectForm
          values={
            data
              ? optionWork.filter(
                  (options) => options?.label === data?.CRWorkflow
                )
              : opt
          }
          options={optionWork}
          name={"itsmchangeWorkFlowId"}
          placeholder={" Select Change Request Workflow"}
        />
      </div>
    </div>
  );
};
export { ServiceCategoryFormIt };

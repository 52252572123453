import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { StepsHierarchy } from "../../../Components/FormComponent/StepsHierarchy";
import { WorkflowStepsComp } from "../../../Components/FormComponent/WorkflowStepsComp";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import Nodata from "../../../Assets/Nodata.svg";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailsFacilityCategorys = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [Loading, setLoading] = useState(false);
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  // let currentUrl = location?.pathname;
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const closeDetails = () => {
    navigate(-1);
  };
  const [eventtData, setEventtData] = useState([]);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `FacilityCategories/Details/${selectedId}`
      );
      // //console.log("id", result.data);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => { };
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Details Facility Category`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  if (Loading) return <Loader />;
  else
    return (
      <div className="moduleMasterDetailsPage">
        <div
          className="moduleMasterDetailsBackground"
          onClick={() => closeDetails()}
        ></div>
        <div className="moduleMasterDetailsPageContainer">
          <div className="headingContainer">
            <h3>Details</h3>
            <IconButton onClick={() => closeDetails()} title="close">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7579 0.56897L0.757935 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 0.56897L12.7579 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <div className="moduleMasterDetailsPageBody">
            {eventtData.length > 0 ? (
              eventtData?.map((item) => {
                return (
                  <>
                    <DetailsStep
                      detailTitle={"Facility Category"}
                      Details={item?.name}
                    />
                    <DetailsStep detailTitle={" code"} Details={item?.code} />

                    <DetailsStep
                      detailTitle={"Facility Category Specifications"}
                      Details={item?.specificationsItemName.map((item) => (
                        <p>{item}</p>
                      ))}
                    />
                    {/* <DetailsStep
                      detailTitle={"Facility Types "}
                      Details={item?.facilityMaterialsName?.map((item) => (
                        <p>{item}</p>
                      ))}
                    /> */}

                    <DetailsStep
                      detailTitle={"Created On"}
                      Details={format(
                        new Date(item?.createdOn),
                        "dd/MM/yyyy hh:mm a"
                      )}
                    />
                    <DetailsStep
                      detailTitle={"Last Updated On"}
                      Details={format(
                        new Date(item?.lastUpdatedOn),
                        "dd/MM/yyyy hh:mm a"
                      )}
                    />
                  </>
                );
              })
            ) : (
              <div className="noDatastyle">
                <img
                  src={Nodata}
                  alt="No data"
                  style={{ height: "150px", width: "250px" }}
                />
                <h4>{"No Records"}</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export { DetailsFacilityCategorys };

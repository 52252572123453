import React, { useEffect, useState } from "react";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";

const ReimbursementformTypesMulti = ({
  disabled = false,
  typeUpdtReceipt = false,
  ExpenseTypesSelected,
  groupItems,
  indexW,
  setEmployeeList,
  employeeList,
  index,
  formValues,
  setInitialValues,
}) => {
  const yesNoOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const dispatch = useDispatch();
  const [categoryOption, setCategoryOption] = useState();

  const getFormDetails = async (nam) => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/Getcategory?mode=${nam}`
      );

      let findCategory = result.data?.results?.map((d, i) => {
        return { label: d?.text, value: d?.id };
      });
      setCategoryOption(findCategory);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getTypeData = async (nam, catid, index, indexW) => {
    try {
      const result = await getRequest(
        `TravelAdvanceEligibilities/Getcategorytype?mode=${nam}&categoryid=${catid}`
      );
      let listData = [];
      result?.data?.map((item) => {
        return listData.push({
          label: item.type,
          budget: item.budget,
          value: item.id ? Number(item.id) : null,
        });
      });
      console.log("object listData", listData);
      setEmployeeList(listData);
      setInitialValues((prev) => {
        if (formValues[groupItems] && Array?.isArray(formValues[groupItems])) {
          const arr = [...formValues[groupItems]];
          arr[index].Expenselist[indexW].cost = listData[0]?.budget;
          arr[index].Expenselist[indexW].type = listData[0]?.label;
          // arr[index].cost = listData[0]?.budget;
          if (groupItems === "groupItems")
            return { ...formValues, groupItems: arr };
          else return { ...formValues, groupItemsHotel: arr };
        } else {
          return { ...formValues };
        }
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    setInitialValues((prev) => {
      if (formValues[groupItems] && Array?.isArray(formValues[groupItems])) {
        const arr = [...formValues[groupItems]];
        arr?.forEach((item, ind) => {
          item?.Expenselist?.forEach((it, index) => {
            if (
              arr[ind]?.Expenselist[index]?.ExpenseTypes === "Taxi" ||
              arr[ind]?.Expenselist[index]?.ExpenseTypes === "Rickshaw" ||
              arr[ind]?.Expenselist[index]?.ExpenseTypes === "Self Vehicle"
            ) {
              if (arr[ind]?.Expenselist[index]?.totalkm)
                arr[ind].Expenselist[index].Amount =
                  arr[ind]?.Expenselist[index]?.cost *
                  arr[ind]?.Expenselist[index]?.totalkm;
              else {
                arr[ind].Expenselist[index].Amount = 0;
              }
            } else if (
              arr[ind]?.Expenselist[index]?.ExpenseTypes === "Food" ||
              arr[ind]?.Expenselist[index]?.ExpenseTypes === "Gift" ||
              arr[ind]?.Expenselist[index]?.ExpenseTypes === "Gadget"
            ) {
              if (arr[ind]?.Expenselist[index]?.ExpenseTypes === "Food") {
                if (arr[ind]?.Expenselist[index]?.totaldays)
                  arr[ind].Expenselist[index].Amount =
                    arr[ind]?.Expenselist[index]?.cost *
                    arr[ind]?.Expenselist[index]?.totaldays;
                else {
                  arr[ind].Expenselist[index].Amount = 0;
                }
              } else {
                if (arr[ind]?.Expenselist[index]?.totalitems)
                  arr[ind].Expenselist[index].Amount =
                    arr[ind]?.Expenselist[index]?.cost *
                    arr[ind]?.Expenselist[index]?.totalitems;
                else {
                  arr[ind].Expenselist[index].Amount = 0;
                }
              }
            } else if (
              arr[ind]?.Expenselist[index]?.ExpenseTypes === "Metro" ||
              arr[ind]?.Expenselist[index]?.ExpenseTypes === "Local Train" ||
              arr[ind]?.Expenselist[index]?.ExpenseTypes === "Local Bus"
            ) {
              if (arr[ind]?.Expenselist[index]?.totaltickets)
                arr[ind].Expenselist[index].Amount =
                  arr[ind]?.Expenselist[index]?.cost *
                  arr[ind]?.Expenselist[index]?.totaltickets;
              else {
                arr[ind].Expenselist[index].Amount = 0;
              }
            }
          });
        });
        if (groupItems === "groupItems")
          return { ...formValues, groupItems: arr };
        else return { ...formValues, groupItemsHotel: arr };
      } else {
        return { ...formValues };
      }
    });
  }, [formValues?.groupItems, formValues?.groupItemsHotel]);
  useEffect(() => {
    if (!disabled) {
      if (ExpenseTypesSelected) {
        formValues[groupItems][index].Expenselist[indexW].categoryid = "";
        formValues[groupItems][index].Expenselist[indexW].type = "";
        setEmployeeList([]);
        getFormDetails(ExpenseTypesSelected);
      }
    }
  }, [ExpenseTypesSelected]);
  useEffect(() => {
    if (!disabled) {
      if (
        formValues?.[groupItems][index]?.Expenselist[indexW]?.categoryid &&
        ExpenseTypesSelected
      ) {
        formValues[groupItems][index].Expenselist[indexW].type = "";
        setEmployeeList([]);
        getTypeData(
          ExpenseTypesSelected,
          formValues?.[groupItems][index]?.Expenselist[indexW]?.categoryid,
          index,
          indexW
        );
      }
    }
  }, [
    formValues?.[groupItems][index]?.Expenselist[indexW]?.categoryid,
    ExpenseTypesSelected,
  ]);
  return (
    <>
      {(formValues?.[groupItems][index]?.Expenselist[indexW]?.ExpenseTypes ===
        "Taxi" ||
        formValues?.[groupItems][index]?.Expenselist[indexW]?.ExpenseTypes ===
          "Rickshaw" ||
        formValues?.[groupItems][index]?.Expenselist[indexW]?.ExpenseTypes ===
          "Self Vehicle") && (
        <>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Date"} mandatory={true} />
            <DatePickerFormiks
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].Date`}
              minDate={new Date()}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"From Location"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].fromlocation`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"To Location"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].tolocation`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Category"} mandatory={true} />
            {disabled && (
              <InputCustomFormik
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].categoryid`}
                placeholder={"Enter"}
              />
            )}
            {!disabled && (
              <SelectForm
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].categoryid`}
                options={categoryOption}
              />
            )}
            {/* <SelectForm
            disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].categoryid`}
              options={categoryOption}
            /> */}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom
              labelName={
                formValues?.[groupItems][index]?.Expenselist[indexW]
                  ?.ExpenseTypes === "Self Vehicle"
                  ? "Vehicle Type"
                  : "Type"
              }
              mandatory={true}
            />
            {disabled && (
              <InputCustomFormik
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].type`}
                placeholder={"Enter"}
              />
            )}
            {!disabled && (
              // <SelectForm
              //   disabled={disabled}
              //   name={`${groupItems}[${index}].Expenselist[${indexW}].type`}
              //   options={employeeList}
              // />
              <InputCustomFormik
                disabled={true}
                name={`${groupItems}[${index}].Expenselist[${indexW}].type`}
                placeholder={"Enter"}
              />
            )}
            {/* <SelectForm
            disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].type`}
              options={employeeList}
            /> */}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Total KM"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`${groupItems}[${index}].Expenselist[${indexW}].totalkm`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Cost per KM"} mandatory={true} />

            <InputCustomFormik
              // disabled={disabled}
              disabled={true}
              type="number"
              name={`${groupItems}[${index}].Expenselist[${indexW}].cost`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Claim Amount"} mandatory={true} />

            <InputCustomFormik
              // disabled={disabled}
              disabled={true}
              type="number"
              name={`${groupItems}[${index}].Expenselist[${indexW}].Amount`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Is Receipt Availabe ?"} mandatory={true} />
            {!typeUpdtReceipt && (
              <SelectForm
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
            {typeUpdtReceipt && (
              <SelectForm
                name={`${groupItems}[${index}].Expenselist[${indexW}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
          </div>
          {formValues?.[groupItems][index]?.Expenselist[indexW]
            ?.IsReceiptAvailable === "Yes" && (
            <div className="reimbursementTablediv">
              <LabelCustom labelName={"Receipt"} mandatory={true} />
              {!typeUpdtReceipt && (
                <FileUploadFormik
                  addPath={
                    formValues?.TravalRequestId
                      ? "TravelBookings/"
                      : "Expenses/"
                  }
                  disabled={disabled}
                  name={`${groupItems}[${index}].Expenselist[${indexW}].Upload`}
                />
              )}
              {typeUpdtReceipt && (
                <FileUploadFormik
                  addPath={
                    formValues?.TravalRequestId
                      ? "TravelBookings/"
                      : "Expenses/"
                  }
                  name={`${groupItems}[${index}].Expenselist[${indexW}].Upload`}
                />
              )}
              <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                Size: 2 MB
              </span>
              <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                Format: JPG, PNG, PDF , Doc ,Docx
              </span>
            </div>
          )}

          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Reason"} mandatory={true} />
            {!typeUpdtReceipt && (
              <TextAreaFormik
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
            {typeUpdtReceipt && (
              <TextAreaFormik
                name={`${groupItems}[${index}].Expenselist[${indexW}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Remarks"} mandatory={true} />
            <TextAreaFormik
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].Remarks`}
              placeholder={"Enter Remarks"}
            />
          </div>
        </>
      )}
      {(formValues?.[groupItems][index]?.Expenselist[indexW]?.ExpenseTypes ===
        "Food" ||
        formValues?.[groupItems][index]?.Expenselist[indexW]?.ExpenseTypes ===
          "Gift" ||
        formValues?.[groupItems][index]?.Expenselist[indexW]?.ExpenseTypes ===
          "Gadget") && (
        <>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Date"} mandatory={true} />
            <DatePickerFormiks
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].Date`}
              minDate={new Date()}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Category"} mandatory={true} />
            {disabled && (
              <InputCustomFormik
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].categoryid`}
                placeholder={"Enter"}
              />
            )}
            {!disabled && (
              <SelectForm
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].categoryid`}
                options={categoryOption}
              />
            )}
            {/* <SelectForm
            disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].categoryid`}
              options={categoryOption}
            /> */}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom
              labelName={
                formValues?.[groupItems][index]?.Expenselist[indexW]
                  ?.ExpenseTypes === "Food"
                  ? "Total Days"
                  : "Total Items"
              }
              mandatory={true}
            />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={
                formValues?.[groupItems][index]?.Expenselist[indexW]
                  ?.ExpenseTypes === "Food"
                  ? `${groupItems}[${index}].Expenselist[${indexW}].totaldays`
                  : `${groupItems}[${index}].Expenselist[${indexW}].totalitems`
              }
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom
              labelName={
                formValues?.[groupItems][index]?.Expenselist[indexW]
                  ?.ExpenseTypes === "Food"
                  ? "Cost per day"
                  : "Cost per item"
              }
              mandatory={true}
            />

            <InputCustomFormik
              // disabled={disabled}
              disabled={true}
              type="number"
              name={`${groupItems}[${index}].Expenselist[${indexW}].cost`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Claim Amount"} mandatory={true} />

            <InputCustomFormik
              // disabled={disabled}
              disabled={true}
              type="number"
              name={`${groupItems}[${index}].Expenselist[${indexW}].Amount`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Is Receipt Availabe ?"} mandatory={true} />
            {!typeUpdtReceipt && (
              <SelectForm
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
            {typeUpdtReceipt && (
              <SelectForm
                name={`${groupItems}[${index}].Expenselist[${indexW}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
          </div>
          {formValues?.[groupItems][index]?.Expenselist[indexW]
            ?.IsReceiptAvailable === "Yes" && (
            <div className="reimbursementTablediv">
              <LabelCustom labelName={"Receipt"} mandatory={true} />
              {!typeUpdtReceipt && (
                <FileUploadFormik
                  addPath={
                    formValues?.TravalRequestId
                      ? "TravelBookings/"
                      : "Expenses/"
                  }
                  disabled={disabled}
                  name={`${groupItems}[${index}].Expenselist[${indexW}].Upload`}
                />
              )}
              {typeUpdtReceipt && (
                <FileUploadFormik
                  addPath={
                    formValues?.TravalRequestId
                      ? "TravelBookings/"
                      : "Expenses/"
                  }
                  name={`${groupItems}[${index}].Expenselist[${indexW}].Upload`}
                />
              )}
              <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                Size: 2 MB
              </span>
              <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                Format: JPG, PNG, PDF , Doc ,Docx
              </span>
            </div>
          )}
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Reason"} mandatory={true} />
            {!typeUpdtReceipt && (
              <TextAreaFormik
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
            {typeUpdtReceipt && (
              <TextAreaFormik
                name={`${groupItems}[${index}].Expenselist[${indexW}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Remarks"} mandatory={true} />
            <TextAreaFormik
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].Remarks`}
              placeholder={"Enter Remarks"}
            />
          </div>
        </>
      )}
      {(formValues?.[groupItems][index]?.Expenselist[indexW]?.ExpenseTypes ===
        "Metro" ||
        formValues?.[groupItems][index]?.Expenselist[indexW]?.ExpenseTypes ===
          "Local Train" ||
        formValues?.[groupItems][index]?.Expenselist[indexW]?.ExpenseTypes ===
          "Local Bus") && (
        <>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Date"} mandatory={true} />
            <DatePickerFormiks
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].Date`}
              minDate={new Date()}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"From Location"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].fromlocation`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"To Location"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].tolocation`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Category"} mandatory={true} />
            {disabled && (
              <InputCustomFormik
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].categoryid`}
                placeholder={"Enter"}
              />
            )}
            {!disabled && (
              <SelectForm
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].categoryid`}
                options={categoryOption}
              />
            )}
            {/* <SelectForm
            disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].categoryid`}
              options={categoryOption}
            /> */}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Total tickets"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`${groupItems}[${index}].Expenselist[${indexW}].totaltickets`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Cost per ticket"} mandatory={true} />

            <InputCustomFormik
              // disabled={disabled}
              disabled={true}
              type="number"
              name={`${groupItems}[${index}].Expenselist[${indexW}].cost`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Claim Amount"} mandatory={true} />

            <InputCustomFormik
              // disabled={disabled}
              disabled={true}
              type="number"
              name={`${groupItems}[${index}].Expenselist[${indexW}].Amount`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Is Receipt Availabe ?"} mandatory={true} />
            {!typeUpdtReceipt && (
              <SelectForm
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
            {typeUpdtReceipt && (
              <SelectForm
                name={`${groupItems}[${index}].Expenselist[${indexW}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
          </div>
          {formValues?.[groupItems][index]?.Expenselist[indexW]
            ?.IsReceiptAvailable === "Yes" && (
            <div className="reimbursementTablediv">
              <LabelCustom labelName={"Receipt"} mandatory={true} />
              {!typeUpdtReceipt && (
                <FileUploadFormik
                  addPath={
                    formValues?.TravalRequestId
                      ? "TravelBookings/"
                      : "Expenses/"
                  }
                  disabled={disabled}
                  name={`${groupItems}[${index}].Expenselist[${indexW}].Upload`}
                />
              )}
              {typeUpdtReceipt && (
                <FileUploadFormik
                  addPath={
                    formValues?.TravalRequestId
                      ? "TravelBookings/"
                      : "Expenses/"
                  }
                  name={`${groupItems}[${index}].Expenselist[${indexW}].Upload`}
                />
              )}
              <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                Size: 2 MB
              </span>
              <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
                Format: JPG, PNG, PDF , Doc ,Docx
              </span>
            </div>
          )}
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Reason"} mandatory={true} />
            {!typeUpdtReceipt && (
              <TextAreaFormik
                disabled={disabled}
                name={`${groupItems}[${index}].Expenselist[${indexW}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
            {typeUpdtReceipt && (
              <TextAreaFormik
                name={`${groupItems}[${index}].Expenselist[${indexW}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Remarks"} mandatory={true} />
            <TextAreaFormik
              disabled={disabled}
              name={`${groupItems}[${index}].Expenselist[${indexW}].Remarks`}
              placeholder={"Enter Remarks"}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ReimbursementformTypesMulti;

import React from "react";
import "./Completed.css";
import TableContent from "./TableContent";
import { useDispatch } from "react-redux";
import { postRequest } from "../../../Services/axios";
import { isLoading, showToast } from "../../../Features";
import { useState } from "react";
import { useEffect } from "react";
import { format } from "date-fns";
import Nodata from "../../../Assets/Nodata.svg";

const Completed = () => {
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState([]);
  const completedStyle = {
    padding: "7px 0px 7px 0px",
    borderRadius: "6px",
    backgroundColor: "#EFF9FA",
  };
  const dispatch = useDispatch();
  const payload = { Status: "Completed" };
  const getInProgressDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `LMSController/MyTeamLearningProgress`,
        payload
      );
      // console.log("MyTeamLearningProgress", result);
      setCompleted(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getInProgressDetails();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Completed`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      {completed?.length !== 0 ? (
        <div
          style={{
            // minWidth: "1172px",
            overflowY: "hidden",
            overflowX: "scroll",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="completedHeadingldteam"
            style={{
              borderRadius: "6px",
              backgroundColor: "#FBFBFB",
              padding: " 6px 0px 7px 0px",
            }}
          >
            <div className="columnwidth " style={{ marginLeft: "16px" }}>
              Name
            </div>
            <div className="columnwidth ">Course</div>
            <div className="columnwidth ">Chapter</div>
            <div className="columnwidth ">Lesson</div>
            <div className="columnwidth ">Status</div>
            <div className="columnwidth ">Date</div>
          </div>
          <div className="tablecontentldteam">
            {completed
              ? completed?.map((item, index) => (
                <TableContent
                  Name={item?.employeeName}
                  Category={item?.name}
                  Course={item?.title}
                  Chapter={item?.chapterName}
                  Status={item?.status}
                  Date={format(new Date(item?.date), "dd/MM/yyyy")}
                  completedStyle={completedStyle}
                  key={index}
                />
              ))
              : ""}
          </div>
        </div>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Records"}</h4>
        </div>
      )}
    </>
  );
};

export { Completed };

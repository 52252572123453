import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import CriteriaAppraisalComp from "./CriteriaAppraisalComp";

import { useDispatch } from "react-redux";

export const CreateAppraisalCriteriaComp = ({
  formValues,
  setinitialValues,
  setRadioOptions,
  radioOptions,
}) => {
  //console.log("formValues",formValues?.cycleStartDate)
  useEffect(() => {
    document.title = `PeopleSol -  Create Appraisal Criteria`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const removeCriteria = (ind) => {
    setinitialValues(() => {
      const finalObj = { ...formValues };
      const resultArr = finalObj?.criteria?.filter(
        (it, index) => index !== ind
      );
      const finalResultObj = { ...finalObj, criteria: resultArr };
      return finalResultObj;
    });
  };
  const dispatch = useDispatch();
  return (
    <div style={{ minHeight: "626px" }}>
      <div
        style={{ display: "flex", alignItems: "center", paddingLeft: "12px" }}
      >
        <div
          style={{
            fontWeight: "400",
            fontSize: "15px",
            padding: "10px 13px",
            color: "#555555",
            display: "block",
            fontFamily: "Poppins",
          }}
        >
          Name/Year of Appraisal Criteria{" "}
          <span style={{ color: "red" }}>{"*"}</span> :{" "}
        </div>
        <InputCustomFormik
          maxLength={150}
          name="nameAppraisalCriteria"
          type={"text"}
          placeholder={"Enter"}
        />
      </div>
      <div>
        {formValues?.criteria?.map((item, index) => {
          return (
            <CriteriaAppraisalComp
              formValues={formValues}
              ind={index}
              setinitialValues={setinitialValues}
              setRadioOptions={setRadioOptions}
              radioOptions={radioOptions}
              removeCriteria={removeCriteria}
            />
          );
        })}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            onClick={() =>
              setinitialValues(() => {
                const finalObj = { ...formValues };
                finalObj.criteria?.push({
                  departmentSelected: [],
                  AppraisalWeightage: [
                    { label: "Performance", Performance: "", isActive: true },
                    { label: "Tasks", Tasks: "", isActive: true },
                    { label: "Rewards", Rewards: "", isActive: true },
                    { label: "L&D", ld: "", isActive: true },
                  ],
                  AppraisalWeightageTotal: "",
                  appraisalSlab: [
                    {
                      fromWeightageRange: "",
                      toWeightageRange: "",
                      appraisalPercentage: "",
                      appraisalValue: "",
                    },
                  ],
                });
                return finalObj;
              })
            }
            className="button primaryButton"
            style={{ margin: "14px 0px", width: "128px" }}
          >
            + Add Criteria
          </div>
        </div>
      </div>
    </div>
  );
};

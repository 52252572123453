import React, { useEffect, useState } from "react";
import pmsAppraisalDetails from "../../Assets/pmsAppraisalDetails.svg";
import pmsAppraisalDownload from "../../Assets/pmsAppraisalDownload.svg";
import { useDispatch } from "react-redux";
import { showToast } from "../../Features";
import { getRequest } from "../../Services/axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PoppinsRegular from "./fonts/Poppins-Regular.ttf";
import PoppinsBold from "./fonts/Poppins-Bold.ttf";
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";
import { Loader } from "../../Components/FormComponent/Loader";
import { SpinningCircleLoader } from "react-loaders-kit";
Font.register({
  family: "Poppins",
  fonts: [
    { src: PoppinsRegular, fontWeight: "normal" },
    { src: PoppinsBold, fontWeight: "bold" },
  ],
});
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 100,
    paddingHorizontal: 35,
    backgroundColor: "white",
    hyphens: "none",
    wordBreak: "keep-all",
  },
  marginBottomContent: {
    marginBottom: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    // paddingBottom: 30,
  },
  logo: {
    height: 50,
  },
  signature: {
    height: 20,
    width: "auto",
  },
  signatureText: {
    fontSize: 10,
  },
  cinText: {
    fontSize: 10,
  },
  footerContainer: {
    marginTop: 20,
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
  },
  footerNote: {
    textAlign: "center",
    color: "#194F78",
    fontSize: 10,
    margin: 10,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    borderTop: "1.5px solid #EEEEEE",
  },
  footerLeft: {
    width: "50%",
    paddingLeft: 40,
    fontSize: 11,
    color: "#414042",
  },
  footerRight: {
    width: "50%",
    paddingRight: 40,
    fontSize: 11,
    color: "#414042",
  },

  container: {
    padding: 0,
  },
  heading1: {
    fontSize: 24,
    fontWidth: 500,
    marginBottom: 10,
    textAlign: "center",
  },
  heading3: {
    color: "#414042",
    fontSize: 14,
    fontWidth: 400,
    marginBottom: 10,
    textAlign: "center",
  },
  heading5: {
    color: "#414042",
    fontSize: 12,
    marginBottom: 10,
  },
  paragraph: {
    color: "#414042",
    fontSize: 12,
    fontWidth: 400,
    marginBottom: 10,
    textAlign: "justify",
    hyphens: "none",
    wordBreak: "keep-all",
    lineHeight: 1.5,
  },
  paragraphAddress: {
    color: "#414042",
    fontSize: 12,
    fontWidth: 400,
    marginBottom: 5,
    textAlign: "justify",
  },
  boldText: {
    fontWeight: "bold",
  },
  boldTextBlack: {
    color: "black",
  },
  contactInfo: {
    marginBottom: 10,
    fontSize: 12,
    color: "#414042",
    fontWidth: 400,
  },
  tableContainer: {
    marginVertical: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#bfbfbf",
  },
  rowBlue: {
    backgroundColor: "#E0F7FA",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0, // Light blue color
  },
  rowWhite: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0, // White color
  },
  rowTopWhite: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0, // White color
  },
  rowBottomWhite: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10, // White color
  },
  rowBottomBlue: {
    backgroundColor: "#E0F7FA",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10, // White color
  },
  tableRow: {
    flexDirection: "row",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#f0f0f0",
  },
  tableColHeader: {
    width: "33%",
    borderStyle: "solid",
    // borderWidth: 1,

    borderColor: "#bfbfbf",
  },
  tableCellFirst: {
    color: "#414042",
  },
  tableCol: {
    width: "33%",
    borderStyle: "solid",
    // borderWidth: 1,
    borderColor: "#bfbfbf",
  },
  tableColFirst: {
    paddingLeft: 30,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    color: "#194F78",
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
  heading4: {
    fontSize: 13,
    color: "#194f76",
    marginVertical: 10,
  },
  heading6: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 15,
  },

  footerText: {
    fontSize: 11,
    textAlign: "center",
  },
  affirmation: {
    marginTop: 10,
    marginBottom: 20,
  },
  tableContainerTwo: {
    marginVertical: 10,
    border: "1px solid #194f78",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  tableRowTwo: {
    flexDirection: "row",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#ffffff",
  },

  tableCellTwo: {
    margin: 5,
    fontSize: 10,
    color: "#414042",
  },
});

// Create Document Component
const MyDocument = ({
  imageData,
  data,
  companyData,
  payrollData,
  LoggedInUser,
  itemElement,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header} fixed>
        <Image src={`data:image/*;base64,${imageData}`} style={styles.logo} />
        {/* <Text style={styles.cinText}>CIN: U72900MP2022PTC060562</Text> */}
      </View>
      {/* Appraisal1 */}
      <View style={styles.container}>
        <Text style={styles.heading1}>Appraisal Letter</Text>
        <Text style={styles.heading3}>
          Performance Appraisal for {itemElement?.appraisalCycleName?.name}
        </Text>
        <Text style={styles.heading5}>
          {format(new Date(itemElement?.lastUpdatedOn), "dd MMMM yyyy")}
        </Text>

        {/* <View style={styles.contactInfo}> */}
        <View>
          <Text style={styles.paragraphAddress}>
            {companyData?.contactPersonName}
          </Text>
        </View>
        <View>
          <Text style={styles.paragraphAddress}>
            {companyData?.contactPersonPhoneNumber}
          </Text>
        </View>
        <View>
          <Text style={styles.paragraphAddress}>
            {companyData?.contactPersonEmailId}
          </Text>
        </View>
        <View style={styles?.marginBottomContent}>
          <Text style={styles.paragraphAddress}>{itemElement?.area}</Text>
        </View>
        {/* </View> */}

        <Text style={styles.paragraph}>
          We would like to present this appraisal letter for your dedication and
          contribution to the {companyData?.companyName}. You took extreme
          efforts for the success of company.
        </Text>
        <Text style={styles.paragraph}>
          Your Performances this year has exceeded our expectations. We would
          like to Thank you for your efforts provided.
        </Text>

        {/* Table */}
        <View style={styles.tableContainer}>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={[styles.tableRow, styles.rowTopWhite]}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}></Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Previous</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Revised</Text>
              </View>
            </View>
            {/* Table Rows */}
            {data.map((itemz, index) => (
              <View
                style={[
                  styles.tableRow,

                  index === data?.length - 1
                    ? index % 2 === 0
                      ? styles.rowBottomBlue
                      : styles.rowBottomWhite
                    : index % 2 === 0
                    ? styles.rowBlue
                    : styles.rowWhite,
                ]}
                key={itemz?.id}
              >
                <View style={[styles.tableCol, styles.tableColFirst]}>
                  <Text style={[styles.tableCell, styles.tableCellFirst]}>
                    {itemz?.mscl}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {itemz?.mscl === "Role"
                      ? itemElement?.currentRole
                      : itemz?.mscl === "Band"
                      ? itemElement?.currentBand
                      : itemz?.mscl === "Designation"
                      ? itemElement?.currentDesignation
                      : ""}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {" "}
                    {itemz?.mscl === "Role"
                      ? itemElement?.newRole
                      : itemz?.mscl === "Band"
                      ? itemElement?.newBand
                      : itemz?.mscl === "Designation"
                      ? itemElement?.newDesignation
                      : ""}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <Text style={styles.paragraph}>
          We hope that you will continue your work with the same dedication and
          meet all the challenges in the upcoming year. We congratulate you on
          your contribution and look forward to your participation in the growth
          of the {companyData?.companyName}. For any Doubts related to your
          increment. Please feel free to contact.
        </Text>
        <Text style={styles.paragraph}>For & on behalf of</Text>
        <Text style={styles.paragraph}>{companyData?.companyName}</Text>
        {/* <Image
          src={`data:image/*;base64,${imageData}`}
          style={styles.signature}
        /> */}
        <Text style={styles.signatureText}>Authorized Signatory</Text>

        {/* <Text style={styles.paragraph}>
          Your appointment will commence with effect from your date of joining
          the Company, any case be later than the date of joining mentioned
          above. In the event of you failing to join by the aforementioned date,
          this employment agreement (‘Agreement’) shall be corrected as per the
          revised date of joining.
        </Text>

        <Text style={styles.paragraph}>
          You can be transferred to any other office of the Company in any other
          location in India or outside India, as decided by the Company from
          time to time.
        </Text> */}
      </View>
      {/* <View style={styles.container}>
        <Text style={styles.paragraph}>
          If the terms and conditions of this offer letter are acceptable to
          you, please confirm your acceptance by signing and returning a copy of
          this letter to the Company within the timelines specified by the HR at
          the time of joining, failing which, this offer stands automatically
          withdrawn by the Company without any further notice to you.
        </Text>
        <Text style={styles.paragraph}>
          Your employment is subject to the following terms and conditions:
        </Text>
        <Text style={styles.heading4}>Terms &amp; Conditions</Text>
        <Text style={[styles.heading5, styles.boldTextBlack]}>
          A. Compensation
        </Text>
        <Text style={styles.paragraph}>
          Your annual fixed compensation will be INR 2,20,000 (Two Lakhs Twenty
          Thousand Only). The detailed break-up of the salary components,
          allowance, and reimbursements which are offered as part of your
          compensation is mentioned in Annexure 1. The Company follows a system
          of annual appraisal of performance in the job to revise your
          compensation. The revision in your compensation post-performance
          evaluation is subject to the discretion of the Company. The changes in
          your compensation (if any) will be communicated to you separately.
        </Text>
        <Text style={[styles.heading5, styles.boldTextBlack]}>
          B. Background Verification
        </Text>
        <Text style={styles.paragraph}>
          The Company may, at any time, (or as part of the joining formalities)
          conduct reference/background checks (including but not limited to
          previous employers, qualifications, etc.). In the event the
          statements/particulars furnished by you at the time of joining are
          found to be false or misleading or any information was suppressed, or
          if the Company, during the course of the check, receives any adverse
          report against you that may be detrimental to the interests of the
          Company, the Company shall have the right to terminate your services
          forthwith without giving any notice, notwithstanding any other terms
          and conditions stipulated herein. In the event there are any
          pending/closed legal cases against you in your professional and
          personal capacity in the courts of law, you shall disclose the same to
          the Company immediately.
        </Text>
        <Text style={[styles.heading5, styles.boldTextBlack]}>
          C. Probation
        </Text>
        <Text style={styles.paragraph}>
          You will be on probation for a period of 6 months, which may be
          extended by a maximum of another six months, at the sole discretion of
          the Company. You shall be deemed to be on probation until a letter of
          confirmation in writing is issued to you. If during the probation
          period, your performance is not up to the mark, you may not be
          considered for confirmation and your service shall be terminated as
          per the decision of the management.
        </Text>
        <Text style={[styles.heading5, styles.boldTextBlack]}>
          D. Miscellaneous
        </Text>
        <Text style={[styles.heading5]}>1. Working Hours</Text>
        <Text style={styles.paragraph}>
          You will be required to work as per the Company’s working hours policy
          applicable to you. The work timings, schedules, and shifts may vary
          from time to time based on the business requirement. You may be
          required to work additional hours as appropriate to fulfill the
          responsibilities of your role.
        </Text>
        <Text style={[styles.heading5]}>2. Leaves</Text>
        <Text style={styles.paragraph}>
          You shall be entitled to Earned Leave in accordance with the Company
          policy subject to the applicable law.
        </Text>
        <Text style={[styles.heading5]}>3. Gratuity</Text>
        <Text style={styles.paragraph}>
          You shall be entitled to payment of gratuity as per the Company policy
          and in line with the applicable law.
        </Text>
        <Text style={[styles.heading5]}>4. Taxation</Text>
        <Text style={styles.paragraph}>
          Any amount payable by the Company to you towards Compensation, other
          entitlements and payments shall be subject to deduction of withholding
          taxes and, or any other taxes.
        </Text>
        <Text style={[styles.heading5]}>5. Confidentiality of Information</Text>
        <Text style={styles.paragraph}>
          For the purposes of this Agreement, 'Confidential Information' in
          relation to the Company means:-
        </Text>
        <View style={styles.list}>
          <View>
            <Text style={styles.paragraph}>• Trade secrets</Text>
          </View>
          <View>
            <Text style={styles.paragraph}>
              • Lists or details of its suppliers, their services, or customers
              and the services and their terms of business
            </Text>
          </View>
          <View>
            <Text style={styles.paragraph}>
              • Prices charged to and terms of business with clients,
            </Text>
          </View>
          <View>
            <Text style={styles.paragraph}>
              • Marketing plans and revenue forecasts,
            </Text>
          </View>
          <View>
            <Text style={styles.paragraph}>
              • Any proposals relating to the future of Company or any of its
              business or any part thereof,
            </Text>
          </View>
          <View>
            <Text style={styles.paragraph}>
              • Details of its employees and officers and of the remuneration
              and other benefits paid
            </Text>
          </View>
          <View>
            <Text style={styles.paragraph}>
              • Information relating to business matters, corporate plans,
              management systems, investments, finances, accounts, marketing or
              sales of any past, present or future products or service,
              processes, inventions, designs, know-how, discoveries,
              technical/financial specifications and other technical or
              financial information relating to the creation, production or
              supply of any past, present or future products or service of the
              Company, any information given to the Company in confidence by
              clients/customers, suppliers or other persons and any other
              information (whether or not recorded in documentary form, or on
              computer disk or tape) which is confidential or commercially
              sensitive and is not in the public domain, and
            </Text>
          </View>
          <View>
            <Text style={styles.paragraph}>
              • Any other information which is notified to you as confidential.
            </Text>
          </View>
          <View>
            <Text style={styles.paragraph}>• IP Protection</Text>
          </View>
          <View>
            <Text style={styles.paragraph}>
              • Any items developed by you or anyone in the company shouldn’t be
              transmitted to another party without consent of the company
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.paragraph}>
            You shall not, either during your employment or at any time
            thereafter, except as required by law, use, divulge or disclose to
            any person any Confidential Information, which may have come to your
            knowledge at any time during the course of your employment with the
            Company. This clause will cease to apply to information which enters
            the public domain other than (directly or indirectly) through your
            act, omission, negligence or fault.
          </Text>
        </View>
      </View>
      <Text style={[styles.heading5]}>6. Employment Conditions</Text>
      <Text style={styles.paragraph}>
        During the course of your employment, you will not be permitted to
        undertake any other employment (including self-employment), consulting
        assignments, or engage in any external activities of a commercial nature
        without prior written approval of the Company. You will be required to
        effectively carry out all duties, responsibilities and obligations
        assigned to you by your manager and/or others authorized by the Company
        to assign such duties and responsibilities.
      </Text>
      <Text style={[styles.heading5]}>7. Travel</Text>
      <Text style={styles.paragraph}>
        You shall travel to such places, within or outside India, as the Company
        may from time to time require in relation to the Company's business.
      </Text>
      <Text style={[styles.heading5]}>8. Intellectual Property Rights</Text>
      <Text style={styles.paragraph}>
        You acknowledge that the company is the absolute, unrestricted, and
        exclusive owner of the confidential information or other proprietary
        technical, financial, marketing, manufacturing, distribution, or other
        business-related information or trade secrets of the company, including,
        without limitation, concepts, techniques, processes, designs, methods,
        systems, designs, clients, cost data, computer programs, formulae, and
        other information used by you in the course of your employment with the
        company. You shall not, in any manner whatsoever, represent and/or claim
        that you have any interest by way of ownership, assignment, or otherwise
        in the same. You acknowledge that the company shall own all rights,
        titles, and interests, including copyright, in any work created by you
        in the course of your employment with the company. To the extent such
        rights do not vest immediately in the Company, you agree to and
        irrevocably and unconditionally assign to the Company all your rights,
        title, and interest, including copyright in such works, for adequate
        consideration, receipt whereof you hereby acknowledge. You agree to
        execute such other documents, as may be required by the company, for
        recording the company as the owner of such works at the company's cost
        and expense.
      </Text>
      <Text style={[styles.heading5]}>9. End of Service</Text>
      <Text style={styles.paragraph}>
        During the Probation Period, the Company may terminate this Agreement
        without assigning any reasons upon thirty (30) days prior written notice
        or payment of salary in lieu thereof, at the discretion of the Company.
        Similarly, during the Probation Period you may also terminate this
        Agreement without assigning any reasons upon thirty (30) days prior
        notice in writing or payment by you to the Company of the salary in lieu
        thereof. In such an event and in addition to the thirty (30) days
        written notice or salary in lieu thereof, you shall also be liable to
        reimburse to the Company any joining bonus/ sign-on bonus paid to you by
        the Company at the time of your joining the employment of the Company.
        Upon your confirmation, either Party shall be free to terminate this
        Agreement at will and, at any time, with or without cause, upon ninety
        (90) days prior written notice by the Party desirous of terminating this
        Agreement or payment of equivalent salary in lieu thereof or a
        combination thereof, at the discretion of the Company.
      </Text>
      <Text style={styles.paragraph}>
        In case you have received or otherwise entitled to any joining bonus/
        sign-on bonus and, you exercise the option of terminating this Agreement
        within the period agreed as per your joining bonus/ sign-on bonus
        letter, you shall be liable to refund to the Company such joining bonus/
        sign-on bonus as paid to you by the Company which shall be in addition
        to the 60 (Sixty) days written notice or salary in lieu thereof or a
        combination thereof
      </Text>
      <Text style={[styles.heading5]}>10. Breach or Misconduct</Text>
      <Text style={styles.paragraph}>
        Notwithstanding anything herein, the Company shall be entitled to
        terminate this Agreement, without notice and with immediate effect, in
        the event you are:
      </Text>
      <Text style={styles.paragraph}>
        • Found to have engaged in any act of misconduct or negligence in the
        discharge of your duties or in the conduct of the Company's business
      </Text>
      <Text style={styles.paragraph}>
        • Found to have engaged in any other act or omission, inconsistent with
        your duties
      </Text>
      <Text style={styles.paragraph}>
        • Found to have engaged in any breach of this Agreement, or the Company
        Policy or lawful orders given to you by the Company
      </Text>
      <Text style={styles.paragraph}>• Convicted of any criminal offence</Text>
      <Text style={styles.paragraph}>
        • Found to have engaged in unauthorized absence or absconding from work
        beyond a period of seven (7) days
      </Text>
      <Text style={styles.paragraph}>
        • Found to have engaged in theft of any asset, information or
        proprietary documents and/ or dissemination of any
        confidential/proprietary information to any third party or individual
        not authorized by the Company for receipt of such information
      </Text>
      <Text style={styles.paragraph}>
        • Any infringement to the company’s intellectual property is considered
        to be the breech in the contract.
      </Text>
      <Text style={[styles.heading5]}>11. Return of Property</Text>
      <Text style={styles.paragraph}>
        On the termination of this agreement by either party, you will be liable
        to return any Company property /assets assigned to you. Failure to
        return the same to the Company will be considered as an offence and
        value of the property will be recovered from your Full and Final
        settlement. In case the value of the property is greater than the Full
        and Final settlement, the Company may choose to withhold (wholly /
        partly) the payment of the entire Full and Final settlement amount.
      </Text>
      <Text style={[styles.heading5]}>12. Governing Law</Text>
      <Text style={styles.paragraph}>
        This Agreement shall be governed and construed in accordance with the
        laws of India. The invalidity or unenforceability of any part of this
        Agreement shall not affect the binding effect of the rest of the
        Agreement. This Agreement shall be concluded and will be deemed
        effective on the submission of a signed copy to us, provided that your
        Compensation and Other Entitlements shall not begin to accrue until you
        commence work for the Company.
      </Text>

      <Text style={[styles.heading5]}>13. Dispute Resolution</Text>
      <Text style={styles.paragraph}>
        Your employment with the Company is subject to Indian laws. All disputes
        shall be subject to the jurisdiction of Bhopal, Madhya Pradesh only.
      </Text>
      <Text
        style={[styles.paragraph, styles.boldTextBlack, styles.affirmation]}
      >
        I accept the above terms and conditions and shall abide by the service
        rules of the company.
      </Text>
      <View>
        <Text style={styles.paragraphAddress}>With Best Regards</Text>
      </View>
      <View>
        <Text style={[styles.paragraphAddress, styles.boldTextBlack]}>
          From AESS Solutions Pvt. Ltd.
        </Text>
      </View>
      <View>
        <Text style={[styles.paragraphAddress, styles.boldTextBlack]}>
          Mr. Gulrez Alam
        </Text>
      </View>
      <View>
        <Text style={styles.paragraphAddress}>Founder & MD</Text>
      </View> */}
      <View style={styles.container} break>
        <View>
          <Text style={[styles.paragraphAddress, styles.boldTextBlack]}>
            Annexure 1
          </Text>
        </View>
        <View>
          <Text style={[styles.paragraphAddress, styles.boldTextBlack]}>
            Revised Compensation and Benefits
          </Text>
        </View>
        <View>
          <Text style={[styles.paragraphAddress, styles.boldTextBlack]}>
            {companyData?.contactPersonName} ({itemElement?.newDesignation})
          </Text>
        </View>
        <View style={styles.tableContainerTwo}>
          {/* First Header Row */}
          <View
            style={[
              styles.tableRowTwo,
              {
                backgroundColor: "#194f78",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
            ]}
          >
            <View style={[styles.tableCol, { width: "100%", border: "none" }]}>
              <Text
                style={[
                  styles.tableCell,
                  {
                    textAlign: "center",
                    fontSize: 12,
                    padding: 5,
                    color: "#fff",
                  },
                ]}
              >
                Salary Structure Details
              </Text>
            </View>
          </View>

          {/* Second Header Row */}
          <View style={[styles.tableRowTwo]}>
            <View style={[styles.tableCol, { width: "40%" }]}>
              <Text
                style={{
                  textAlign: "start",
                  fontSize: 10,
                  paddingLeft: 10,
                  padding: 5,
                  color: "#194F78",
                }}
              >
                Salary Component
              </Text>
            </View>
            <View
              style={[styles.tableCol, { width: "20%", alignItems: "center" }]}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  padding: 5,
                  color: "#194F78",
                }}
              >
                Old Structure
              </Text>
            </View>
            <View
              style={[styles.tableCol, { width: "40%", alignItems: "center" }]}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  padding: 5,
                  color: "#194F78",
                }}
              >
                New Structure
              </Text>
            </View>
          </View>

          {/* Third Header Row */}
          <View style={[styles.tableRowTwo, { backgroundColor: "#d6e4ea" }]}>
            <View style={[styles.tableCol, { width: "32%", padding: 5 }]}>
              <Text style={[styles.tableCellTwo]}>Fixed Component</Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { width: "17%", padding: 5, textAlign: "center" },
              ]}
            >
              <Text style={[styles.tableCellTwo]}>INR Per Month</Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { width: "17%", padding: 5, textAlign: "center" },
              ]}
            >
              <Text style={[styles.tableCellTwo]}>INR Per Year</Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { width: "17%", padding: 5, textAlign: "center" },
              ]}
            >
              <Text style={[styles.tableCellTwo]}>INR Per Month</Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { width: "17%", padding: 5, textAlign: "center" },
              ]}
            >
              <Text style={[styles.tableCellTwo]}>INR Per Year</Text>
            </View>
          </View>

          {/* Data Rows */}
          {payrollData.map((item, ind) => (
            <View
              key={item.id}
              style={[
                styles.tableRowTwo,
                payrollData?.length === ind + 1
                  ? {
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      backgroundColor: "#fff",
                    }
                  : // item.id === 14 || item.id === 11
                    // ? {
                    //     backgroundColor: "#d6e4ea",
                    //   }
                    // :
                    { backgroundColor: "#fff" },
              ]}
            >
              <View style={[styles.tableCol, { width: "32%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingRight: 5,
                      paddingLeft: 5,
                      fontSize: payrollData?.length === ind + 1 ? 11 : 10,
                      color:
                        payrollData?.length === ind + 1 ? "black" : "#414042",
                    },
                    {},
                  ]}
                >
                  {item.FixedComponent}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "17%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      textAlign: "center",
                      fontSize: payrollData?.length === ind + 1 ? 11 : 10,
                      color:
                        payrollData?.length === ind + 1 ? "black" : "#414042",
                    },
                  ]}
                >
                  {item.INRPerMonth}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "17%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: payrollData?.length === ind + 1 ? 11 : 10,
                      textAlign: "center",
                      color:
                        payrollData?.length === ind + 1 ? "black" : "#414042",
                    },
                  ]}
                >
                  {item.INRPerYear}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "17%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: payrollData?.length === ind + 1 ? 11 : 10,
                      textAlign: "center",
                      color:
                        payrollData?.length === ind + 1 ? "black" : "#414042",
                    },
                  ]}
                >
                  {item.INRPerMonth2}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "17%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: payrollData?.length === ind + 1 ? 11 : 10,
                      textAlign: "center",
                      color:
                        payrollData?.length === ind + 1 ? "black" : "#414042",
                    },
                  ]}
                >
                  {item.INRPerYear2}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View>
          <Text style={[styles.paragraph, styles.boldTextBlack]}>
            *Important
          </Text>
          <Text style={styles.paragraph}>
            {" "}
            • The above compensation may vary based on the company pay
            restructuring.
          </Text>
          <Text style={styles.paragraph}>
            {" "}
            • Income Tax and other taxes attracted should be deducted based on
            the monthly compensation.
          </Text>
          <Text style={[styles.paragraph, { marginBottom: 0 }]}>
            {" "}
            • Annual bonus disbursement will be based on the company annual
            performance policy.
          </Text>
        </View>
      </View>

      <View style={styles.footerContainer} fixed>
        <Text style={styles.footerNote}>
          *This is system generated document doesn’t require any signature.
        </Text>
        <View style={styles.footer}>
          <View style={styles.footerLeft}>
            <Text>{companyData?.companyWebsite}</Text>
            <Text>
              {companyData?.companyEmailId} | {companyData?.companyPhoneNumber}
            </Text>
          </View>
          <View style={styles.footerRight}>
            <Text>{companyData?.companyProfile?.companyAddress}</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);
const MyAppraisal = () => {
  const [loading, setLoading] = useState();
  const [detailsData, setDetailsData] = useState(false);
  const [companyData, setCompanyData] = useState();
  const [downloadLoading, setDownloadLoading] = useState([]);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [base64Img, setBase64Img] = useState("");
  const [payrollData, setpayrollData] = useState("");
  console.log("object downloadLoading", downloadLoading);
  const imageProvider = async () => {
    const result = await getRequest(
      `LMSController/imageToBase64?imageUrl=${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Company/${LoggedInUser?.companyCode}/Logo/${LoggedInUser?.companyLogo}`
    );
    setBase64Img(result?.data);
  };
  // const pmsDataApi = async () => {
  //   const result = await getRequest(
  //     `PMSAppraisalNew/EmployeeSaleryDetails/${LoggedInUser?.id}`
  //   );
  // };
  console.log("object payrollData", payrollData);
  // const payrollDataApi = async () => {
  //   const result = await getRequest(
  //     `EmployeeSalaryDetails/Details/${LoggedInUser?.payrollEmployeeId}`
  //   );
  //   const listData = [];
  //   result?.data?.data?.map((it, ind) =>
  //     listData?.push({
  //       id: ind,
  //       FixedComponent: it?.type,
  //       INRPerMonth: it?.monthlyAmount,
  //       INRPerYear: it?.annualAmount,
  //       INRPerMonth2: it?.monthlyAmount,
  //       INRPerYear2: it?.annualAmount,
  //       isEmployerContributionInCTC: it?.isEmployerContributionInCTC,
  //     })
  //   );
  //   listData?.push({
  //     id: listData?.length,
  //     FixedComponent: "Total Net Payable",
  //     INRPerMonth: listData?.reduce((acc, item) => {
  //       if (item?.isEmployerContributionInCTC === false) return acc;
  //       else return acc + Number(item?.INRPerMonth);
  //     }, 0),
  //     INRPerYear: listData?.reduce((acc, item) => {
  //       if (item?.isEmployerContributionInCTC === false) return acc;
  //       else return acc + Number(item?.INRPerYear);
  //     }, 0),
  //     INRPerMonth2: listData?.reduce((acc, item) => {
  //       if (item?.isEmployerContributionInCTC === false) return acc;
  //       else return acc + Number(item?.INRPerMonth);
  //     }, 0),
  //     INRPerYear2: listData?.reduce((acc, item) => {
  //       if (item?.isEmployerContributionInCTC === false) return acc;
  //       else return acc + Number(item?.INRPerYear);
  //     }, 0),
  //   });

  // };
  useEffect(() => {
    imageProvider();
    // payrollDataApi();
    // pmsDataApi();
  }, [LoggedInUser]);
  const data = [
    {
      id: 1,
      Previous: "",
      Revised: "",
      mscl: "Role",
    },
    {
      id: 2,
      Previous: "",
      Revised: "",
      mscl: "Band",
    },
    {
      id: 3,
      Previous: "",
      Revised: "",
      mscl: "Designation",
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatNumber = (num) => {
    const number = Number(Number(num)?.toFixed(1));
    if (number % 1 === 0) {
      return number?.toFixed(0);
    }
    return number?.toFixed(1);
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`PMSAppraisalNew/MyAppraisal`);
      const listData = [];
      setDetailsData(result.data.data);
      setCompanyData(result.data.data?.company);
      result?.data?.data?.empSalaryDetails?.map((it, ind) =>
        listData?.push({
          id: ind,
          FixedComponent: it?.type,
          INRPerMonth: it?.monthlyAmount,
          INRPerYear: it?.annualAmount,
          INRPerMonth2: it?.monthlyAmount,
          INRPerYear2: it?.annualAmount,
          isEmployerContributionInCTC: it?.isEmployerContributionInCTC,
        })
      );
      listData?.push({
        id: listData?.length,
        FixedComponent: "Total Net Payable",
        INRPerMonth: listData?.reduce((acc, item) => {
          if (item?.isEmployerContributionInCTC === false) return acc;
          else return acc + Number(item?.INRPerMonth);
        }, 0),
        INRPerYear: listData?.reduce((acc, item) => {
          if (item?.isEmployerContributionInCTC === false) return acc;
          else return acc + Number(item?.INRPerYear);
        }, 0),
        INRPerMonth2: listData?.reduce((acc, item) => {
          if (item?.isEmployerContributionInCTC === false) return acc;
          else return acc + Number(item?.INRPerMonth);
        }, 0),
        INRPerYear2: listData?.reduce((acc, item) => {
          if (item?.isEmployerContributionInCTC === false) return acc;
          else return acc + Number(item?.INRPerYear);
        }, 0),
      });

      setpayrollData(listData);
      setDownloadLoading(result?.data?.data?.appraisal?.map((it) => false));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const formatDuration = (durationString) => {
    const regex = /(\d+)\s*Year\(s\)\s*(\d+)\s*Month\(s\)/;
    const matches = durationString?.match(regex);

    if (matches) {
      const years = matches[1];
      const months = matches[2];
      return `${years}Yrs ${months}Mos`;
    }

    return "";
  };
  console.log("object LoggedInUser", LoggedInUser);
  const generatePdfDocument = async (item, ind) => {
    setDownloadLoading((prev) => {
      const arr = [...prev];
      return arr.map((item, i) => {
        if (i === ind) {
          return true;
        } else return false;
      });
    });
    const blob = await pdf(
      <MyDocument
        imageData={base64Img}
        data={data}
        payrollData={payrollData}
        LoggedInUser={LoggedInUser}
        companyData={companyData}
        itemElement={item}
      />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Appraiser Letter.pdf";
    a.click();
    URL.revokeObjectURL(url);
    setDownloadLoading((prev) => {
      const arr = [...prev];
      return arr.map((item, i) => {
        if (i === ind) {
          return false;
        } else return false;
      });
    });
  };
  const loaderProps = {
    loading: true,
    size: 20,
    duration: 5,
    colors: ["#1296b0", "#ffffff"],
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {detailsData && (
              <div className="topDiv">
                <div className="topDivPad">
                  <div className="topDivJoiningCTC">
                    <div className="topDivJoiningCTCdiv">
                      <div className="topDivJoiningCTCdivHeading">
                        Joining
                        <br />
                        CTC
                      </div>
                      <div className="topDivJoiningCTCdivAmount">
                        {formatNumber(detailsData?.joiningCTC)}
                      </div>
                    </div>
                    <div className="topDivJoiningCTCdiv">
                      <div className="topDivJoiningCTCdivHeading">
                        Growth
                        <br />
                        Amount{" "}
                      </div>
                      <div className="topDivJoiningCTCdivAmount">
                        {formatNumber(detailsData?.grothAmmount)}
                      </div>
                    </div>
                    <div className="topDivJoiningCTCdiv">
                      <div className="topDivJoiningCTCdivHeading">
                        Current
                        <br />
                        CTC
                      </div>
                      <div className="topDivJoiningCTCdivAmount">
                        {formatNumber(detailsData?.currentCTC)}
                      </div>
                    </div>
                  </div>
                  <div
                    className="topDivJoiningDate"
                    // style={{ width: "237px", justifyContent: "start" }}
                  >
                    <div className="topDivJoiningDatediv">
                      <div className="topDivJoiningCTCdivHeading">
                        Joining
                        <br />
                        Date
                      </div>
                      <div className="topDivJoiningCTCdivAmount">
                        {detailsData?.doj
                          ? format(new Date(detailsData?.doj), "dd.MM.yy")
                          : ""}
                      </div>
                    </div>
                    <div className="topDivJoiningDatediv">
                      <div className="topDivJoiningCTCdivHeading">
                        Total
                        <br />
                        Experience
                      </div>
                      <div
                        className="topDivJoiningCTCdivAmount"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {formatDuration(detailsData?.totalWorkExperience)}
                      </div>
                    </div>
                  </div>
                  <div className="topDivTotalGrowth">
                    <div className="topDivTotalGrowthdiv">
                      <div className="topDivJoiningCTCdivHeading">
                        Total
                        <br />
                        Growth %
                      </div>
                      <div className="topDivJoiningCTCdivAmount"></div>
                    </div>
                    <div className="topDivTotalGrowthdiv">
                      <div className="topDivJoiningCTCdivHeading">
                        Yearly
                        <br />
                        Growth %
                      </div>
                      <div className="topDivJoiningCTCdivAmount"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="bottomDiv">
              <div className="bottomDivPad">
                {detailsData?.appraisal?.map((item, ind) => (
                  <>
                    <div className="bottomDivPerformance">
                      <div style={{ width: "150px" }}>
                        <div
                          style={{
                            width: "calc( 100% - 30px )",
                            height: "100%",
                            position: "relative",
                            // border: "1px solid black",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "calc(50% - 10px)",
                              right: "26px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                textAlign: "right",
                              }}
                            >
                              {item?.appraisalCycleName?.name}
                            </div>
                            <div
                              style={{
                                fontSize: "12px",
                                textAlign: "right",
                              }}
                            >
                              {format(
                                new Date(item?.appraisalCycleName?.createdOn),
                                "dd.MM.yy"
                              )}
                            </div>
                          </div>
                          {ind !== 0 && (
                            <div
                              className="borderAppraisal"
                              style={{ top: "0%" }}
                            ></div>
                          )}
                          <div className="circleAppraisal">{ind + 1}</div>
                          {detailsData?.appraisal?.length - 1 === 0 ? (
                            <div className="borderAppraisal"></div>
                          ) : (
                            ind !== detailsData?.appraisal?.length - 1 && (
                              <div className="borderAppraisal"></div>
                            )
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "787px",
                          padding: "15px 20px 15px 20px",
                          boxShadow: "0px 3.57px 17.84px 0px #0000000F",
                          marginRight: "9px",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "749px",
                            padding: "17px 0px",
                            borderBottom: "0.75px solid #D8F0F2",
                          }}
                        >
                          <div
                            className="bottomDivPerformanceSize"
                            style={{ backgroundColor: "#F0F0FF" }}
                          >
                            <div style={{ fontSize: "11px" }}>
                              Revised Monthly CTC
                            </div>
                            <div style={{ color: "#8A88ED" }}>
                              {formatNumber(item?.purposedFinalSalary)}
                            </div>
                          </div>
                          <div
                            className="bottomDivPerformanceSize"
                            style={{ backgroundColor: "#FFEEFA" }}
                          >
                            <div style={{ fontSize: "11px" }}>
                              Last Monthly CTC
                            </div>
                            <div style={{ color: "#DA5DB6" }}>
                              {formatNumber(item?.lastCTC)}
                            </div>
                          </div>
                          <div
                            className="bottomDivPerformanceSize"
                            style={{ backgroundColor: "#E7F3F5" }}
                          >
                            <div style={{ fontSize: "11px" }}>Increment %</div>
                            <div style={{ color: "#6AB1BF" }}>
                              {formatNumber(item?.incrementPercentage)}%
                            </div>
                          </div>
                          <div
                            className="bottomDivPerformanceSize"
                            style={{ backgroundColor: "#FFF2F0" }}
                          >
                            <div style={{ fontSize: "11px" }}>
                              Increment Amount
                            </div>
                            <div style={{ color: "#FB7158" }}>
                              {formatNumber(item?.incrementAmmount)}
                            </div>
                          </div>
                          <div
                            className="bottomDivPerformanceSize"
                            style={{ backgroundColor: "#ECECF5" }}
                          >
                            <div style={{ fontSize: "11px" }}>
                              Performance Rating{" "}
                            </div>
                            <div style={{ color: "#A0A0CB" }}>
                              {formatNumber(item?.fhodRating)}/5
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 0px 0px 0px",
                          }}
                        >
                          <img
                            onClick={() => {
                              navigate(
                                `/pms/performancereview/myreview/details/${item?.appraisalCycleName?.performanceCycleId}`,
                                {
                                  state: {
                                    performanceCycleId:
                                      item?.appraisalCycleName
                                        ?.performanceCycleId,
                                    employeeId: LoggedInUser?.id,
                                  },
                                }
                              );
                            }}
                            style={{ height: "28px", marginRight: "12px" }}
                            className="cursor"
                            src={pmsAppraisalDetails}
                            alt=""
                          />

                          <span
                            style={{ fontSize: "11px", marginRight: "41px" }}
                          >
                            {" "}
                            View <br /> Performance
                          </span>
                          {downloadLoading[ind] ? (
                            <div style={{ margin: "0px 10px 0px 0px " }}>
                              <SpinningCircleLoader {...loaderProps} />
                            </div>
                          ) : (
                            <img
                              style={{ height: "28px", marginRight: "12px" }}
                              src={pmsAppraisalDownload}
                              alt=""
                              className="cursor"
                              onClick={() => generatePdfDocument(item, ind)}
                            />
                          )}

                          <span
                            style={{ fontSize: "11px", marginRight: "41px" }}
                          >
                            Download <br /> Appraisal Letter
                          </span>
                          {/* <span
                    style={{ fontSize: "11px", marginRight: "41px" }}
                    onClick={() =>
                      navigate("/pms/appraisal/myappraisal/letter")
                    }
                  >
                    View <br /> Appraisal Letter
                  </span> */}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyAppraisal;

import React from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";
// import { useDispatch } from "react-redux";
// import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
// import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
// import { YesNoFormik } from "../../../../../Components/FormComponent/YesNoFormik";
// import { showToast } from "../../../../../Features";
// import { getRequest } from "../../../../../Services/axios";
// import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import CrossIcon from "../../../../../Assets/CrossChat.svg"

// import DocIconWhite from "../../../../../Assets/DocIconWhite.svg"
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
function Header({ }) {

    const { LoggedInUser } = useSelector((state) => state.auth);
    const [logoSrc, setLogoSrc] = useState('');

    useEffect(() => {
        const fetchLogo = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_.BASE_URL + "/Core/Uploads/Company/" + LoggedInUser?.companyCode + "/Logo/" + LoggedInUser?.companyLogo);
                if (response.ok) {
                    const blob = await response.blob();
                    const logoURL = URL.createObjectURL(blob);
                    setLogoSrc(logoURL);
                } else {
                    console.error('Failed to load logo');
                }
            } catch (error) {
                console.error('Error fetching logo:', error);
            }
        };

        fetchLogo();
    }, []);

    return (
        <div style={{ padding: 20, height: "15%" }}>
            {logoSrc ? (
                <img style={{ height: 30, marginRight: 10, marginBottom: 10 }} src={logoSrc} alt="Company Logo" />
            ) : (
                <p>Loading logo...</p>
            )}
            <p style={{ textAlign: "center", fontWeight: "700", fontSize: 12 }}>{LoggedInUser.companyName}</p>
        </div>
    );
}

export { Header };

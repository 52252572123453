/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import UploadDetail from "../../../Components/UploadDetail/UploadDetail";
import { format } from "date-fns";
import { Loader } from "../../../Components/FormComponent/Loader";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import "./OfficeLocation.css";

const DetailOfficeLocation = () => {
  let defaultPath = [
    { lat: 23.274180690884798, lng: 77.37241318320314 },
    { lat: 23.222963370610092, lng: 77.40717601191407 },
    { lat: 23.236750098842574, lng: 77.43640208583984 },
    { lat: 23.245488256762197, lng: 77.45704509091796 },
    { lat: 23.28419909821067, lng: 77.42691994423826 },
  ];
  const [path, setPath] = useState(defaultPath);
  const [isMapScriptLoading, setIsMapScriptLoading] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    // let currentUrl = location?.pathname;
    // navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
    navigate(-1);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);

  function getShortFormatPath(allpath) {
    let lat = 0;
    let lng = 0;
    let newPath = allpath.map((d) => {
      return { lat: d?.latitude, lng: d?.longitude };
    });
    setPath(newPath);
  }
  function getName(name) {
    if (name) {
      let index = name?.indexOf("(");
      let newName = index > -1 ? name.substring(0, index) : name;
      return newName;
    }
    return null;
  }
  function getEmployeeName(empId) {
    if (empId) {
      let found = employeeList?.find((d) => {
        return d?.id === empId;
      });
      return found?.name ? getName(found?.name) : null;
    }
    return null;
  }
  let listData = [];
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      result.data.map((item) => {
        return listData.push(item);
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Office Location`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getDetailsData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`OfficeLocation/Details/${selectedId}`);
      setEventtData(result?.data);
      setPath(result?.data?.points);
      getShortFormatPath(result?.data?.points)
      // console.log(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    getDetailsData();
    return () => { };
  }, []);

  useEffect(() => {
    if (!document.getElementById("google-maps-api-script")) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_.GOOGLE_MAP_API_KEY}&libraries=places`;
      script.async = true;
      script.id = "google-maps-api-script";
      script.onload = () => {
        setIsMapScriptLoading(false);
      };
      document.body.appendChild(script);
    } else {
      setIsMapScriptLoading(false);
    }
  }, []);

  function getPolygonCentroid(polygonCoordinates) {
    let cx = 0;
    let cy = 0;

    const numVertices = polygonCoordinates.length;

    for (const vertex of polygonCoordinates) {
      cx += vertex.lat;
      cy += vertex.lng;
    }

    const centroidX = cx / numVertices;
    const centroidY = cy / numVertices;

    return { lat: centroidX, lng: centroidY };
  }

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Office Location Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            <DetailsStep detailTitle={"Name"} Details={eventtData?.name} />
            {/* <DetailsStep detailTitle={"Created By"} Details={eventtData?.createdBy} />
            <DetailsStep detailTitle={"Updated By"} Details={eventtData?.updatedBy} /> */}

            <DetailsStep
              detailTitle={"Created On"}
              Details={format(
                new Date(eventtData?.createdOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />

            <DetailsStep
              detailTitle={"LastUpdated On"}
              Details={format(
                new Date(eventtData?.lastUpdatedOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />

            <DetailsStep
              detailTitle={"Total Employees"}
              Details={eventtData?.locationEmployees?.length}
            />

            <DetailsStep
              detailTitle={"Office Location"}
              Details={
                <>
                  {!isMapScriptLoading && eventtData?.polygon && (
                    <div style={{ height: "250px", width: "100%" }}>
                      <GoogleMap
                        mapContainerClassName="App-map"
                        center={getPolygonCentroid(path)}
                        zoom={18}
                        version="weekly"
                        keyboardShortcuts={true}
                      // options={{disableDefaultUI: true, keyboardShortcuts: false}}
                      >
                        <Polygon
                          editable={true}
                          draggable={true}
                          // onResize={false}
                          // onBoundsChanged={false}
                          // onZoomChanged={false}
                          // gestureHandling= "none"
                          // scrollwheel= {false}
                          // zoomControl= {false}
                          // mapTypeControl= {false}
                          // streetViewControl= {false}
                          // disableDefaultUI= {true}
                          // scaleControl= {false}
                          // navigationControl= {false}
                          // rotateControl={false}
                          // fullScreen={false}
                          // disableDefaultUI= {true}
                          path={path}

                        />
                      </GoogleMap>
                    </div>
                  )}
                </>
              }
            />

            <div
              className="headingForm"
              style={{ width: "100%", margin: "0" }}
            >
              Mapped Employees Data
            </div>
            <div style={{ width: "100%" }}>
              <table className="tabless tabless-bordered">
                <thead
                  style={{
                    backgroundColor: "var(--primary-hover)",
                  }}
                >
                  <tr>
                    <td>S.no</td>
                    <td>Employee Id</td>
                    <td>Employee Name</td>
                  </tr>
                </thead>
                <tbody>
                  {eventtData?.locationEmployees?.length > 0
                    ? eventtData?.locationEmployees?.map((item, index) => {
                      return (
                        <tr className="trStyle">
                          <td>{index + 1}</td>
                          <td>{item?.employeeId}</td>
                          <td>{getEmployeeName(item?.employeeId)}</td>
                        </tr>
                      );
                    })
                    : "No Records"}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailOfficeLocation };

import { Formik, Form, FieldArray } from "formik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { InputCustom } from "../../../../../Components/FormComponent/InputCustom";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../../Services/axios";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { showToast } from "../../../../../Features";
import { useDispatch } from "react-redux";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { FileUploadFormikMulti } from "../../../../../Components/FormComponent/FileUploadFormikMulti";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { useSelector } from "react-redux";

const Proioroption = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
const status = [
  { label: "Resolved", value: "Resolved" },
  { label: "New Implementation", value: "New Implementation" },
  { label: "Assigned", value: "Assigned" },
  { label: "In Progress", value: "In Progress" },
  // { label: "Information Requested", value: "Information Requested" },
  // { label: "Request for Procurement", value: "Request for Procurement" },
  { label: "Hold", value: "Hold" },
  { label: "ReOpen", value: "ReOpen" },
];
const UpdateStatus = ({
  data,
  id,
  assId,
  assIgniID,
  prestatus,
  priority,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const { LoggedInUser } = useSelector((state) => state.auth);

  const [initialValues, setInitialValues] = useState({
    status: "",
    Priority: "",
    stages: [{ steps: "", uploadStep: null }],
  });
  const [loading, setLoading] = useState(false);
  const [detailsData, setdetailsData] = useState(false);
  const submitActionRef = useRef(null);
  const detailsCall = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`ItHelpdesks/DetailsV?ID=${id}`);
      setdetailsData(result?.data?.data);
    } catch {
    } finally {
      setLoading(false);
    }
  };
  console.log("object initialValues", initialValues);
  useEffect(() => {
    setInitialValues({
      status:
        detailsData?.employeeTicketsUpdates?.filter(
          (it) => it?.isSubmitted === false
        )?.length > 0
          ? detailsData?.employeeTicketsUpdates
              ?.slice()
              ?.reverse()
              ?.find((it) => it?.isSubmitted === false)?.status ?? prestatus
          : prestatus,
      Priority:
        detailsData?.employeeTicketsUpdates?.filter(
          (it) => it?.isSubmitted === false
        )?.length > 0
          ? detailsData?.employeeTicketsUpdates
              ?.slice()
              ?.reverse()
              ?.find((it) => it?.isSubmitted === false)?.priority ?? priority
            ? priority
            : assId?.priority
          : priority
          ? priority
          : assId?.priority,
      stages:
        detailsData?.employeeTicketsUpdates?.filter(
          (it) => it?.isSubmitted === false
        )?.length > 0
          ? detailsData?.employeeTicketsUpdates
              ?.slice()
              ?.reverse()
              ?.find((it) => it?.isSubmitted === false)?.employeeId ===
              LoggedInUser?.id &&
            detailsData?.employeeTicketsUpdates
              ?.slice()
              ?.reverse()
              ?.find((it) => it?.isSubmitted === false)?.implementedSteps
              ?.length > 0
            ? detailsData?.employeeTicketsUpdates
                ?.slice()
                ?.reverse()
                ?.find((it) => it?.isSubmitted === false)
                ?.implementedSteps?.sort((a, b) => a.id - b.id)
                ?.map((it) => ({
                  steps: it?.step,
                  employeeId: it?.employeeId,
                  // uploadStep: it?.attachment,
                }))
            : [{ steps: "" }]
          : [{ steps: "" }],
    });
  }, [prestatus, assId, priority, detailsData]);

  //console.log("priority",priority)
  const submitSteps = async (value) => {
    try {
      setLoading(true);
      // const result = await postRequest("ItHelpdesks/Update", value);
      const result = await postRequestForm("ItHelpdesks/Update", value);
    } catch (error) {
    } finally {
      setLoading(false);
      submitActionRef.current = null;
      handleClose();
    }
  };
  const onSubmit = async (values) => {
    const action = submitActionRef.current;
    const formData = new FormData();
    formData.append("status", values?.status);
    formData.append("Priority", values?.Priority);
    formData.append("IsSubmitted", action === "submit" ? true : false);
    formData.append("TicketId", id);
    values?.stages?.forEach((it, ind) => {
      formData.append(`Steps[${ind}].Id`, 0);
      formData.append(`Steps[${ind}].TicketUpdateId`, 0);
      formData.append(`Steps[${ind}].Step`, it?.steps);
      if (it?.employeeId) {
        formData.append(`Steps[${ind}].EmployeeId`, it?.employeeId);
      }
      formData.append(`Steps[${ind}].uploadStep`, it?.uploadStep);
    });
    // const value = {
    //   ...values,
    //   IsSubmitted: action === "submit" ? true : false,
    //   TicketId: id,
    //   AssgineID: assIgniID ? assIgniID : assId?.assigineeId,
    //   Steps: values?.stages?.map((it) => ({
    //     Id: 0,
    //     TicketUpdateId: 0,
    //     Step: it?.steps,
    //     EmployeeId: it?.employeeId ? it?.employeeId : null,
    //   })),
    // };
    submitSteps(formData);
  };
  useEffect(() => {
    document.title = `PeopleSol - Update Status`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    detailsCall();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object({
            Priority: Yup.string().required(" Please Enter"),
            status: Yup.string().required(" Please Enter"),
            stages: Yup.array().of(
              Yup.object().shape({
                steps: Yup.string().required("Please Enter"),
              })
            ),
          })}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <div style={{ width: "865px" }}>
                <div className="formcustom formcustomthree">
                  <div>
                    <LabelCustom labelName={"Current Status"} />
                    <InputCustom disabled value={prestatus} />
                  </div>
                  <div>
                    <LabelCustom labelName={"Update Status"} mandatory={true} />
                    <SelectForm
                      name="status"
                      options={status}
                      placeholder="Select Status"
                    />
                  </div>
                  <div>
                    <LabelCustom
                      labelName={"Update Priority"}
                      mandatory={true}
                    />
                    <SelectForm
                      name="Priority"
                      options={Proioroption}
                      placeholder="Select Priority"
                    />
                  </div>
                </div>
                <div style={{ padding: "10px" }}>
                  <LabelCustom
                    style={{ paddingLeft: "3px" }}
                    labelName={"Implementation steps for Resolution"}
                    mandatory={true}
                  />
                  <FieldArray name="stages">
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { stages } = values;

                      return (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell
                                padding="0"
                                style={{
                                  fontSize: "12px",
                                  width: "51px",
                                }}
                                align="center"
                              >
                                <LabelCustom labelName={"Sl No."} />
                              </StyledTableCell>
                              <StyledTableCell
                                padding="0"
                                style={{
                                  fontSize: "12px",
                                  width: "444px",
                                }}
                                align="center"
                              >
                                <LabelCustom
                                  labelName={"Steps"}
                                  mandatory={true}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                padding="0"
                                style={{
                                  fontSize: "12px",
                                }}
                                align="center"
                              >
                                <LabelCustom labelName={"Upload"} />
                              </StyledTableCell>

                              <StyledTableCell
                                style={{
                                  fontSize: "12px",
                                  width: "96px",
                                }}
                                align="center"
                              >
                                <LabelCustom labelName={"Add/ Remove"} />
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {stages?.map((item, index) => (
                            <TableBody key={index}>
                              <StyledTableCell align="center">
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell>
                                <TextAreaFormik
                                  name={`stages[${index}].steps`}
                                />
                              </StyledTableCell>
                              <StyledTableCell>
                                <FileUploadFormik
                                  name={`stages[${index}].uploadStep`}
                                  placeholder={"Choose file"}
                                  addPath="EmplementedStep/"
                                />

                                <div
                                  style={{
                                    paddingLeft: "12px",
                                    color: "var(--secondary)",
                                    fontSize: "12px",
                                  }}
                                >
                                  Size: 2 MB
                                </div>
                                <div
                                  style={{
                                    paddingLeft: "12px",
                                    color: "var(--secondary)",
                                    fontSize: "12px",
                                  }}
                                >
                                  Format: JPG, PNG, PDF , Doc ,Docx
                                </div>
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <button
                                  type="button"
                                  className="remove-btn"
                                  onClick={() =>
                                    stages?.length > 0
                                      ? remove(index)
                                      : dispatch(
                                          showToast({
                                            text: "Atleast One Item is Required ",
                                            severity: "error",
                                          })
                                        )
                                  }
                                >
                                  -
                                </button>

                                <button
                                  type="button"
                                  className="add-btn"
                                  onClick={() =>
                                    push({ steps: "", uploadStep: null })
                                  }
                                >
                                  +
                                </button>
                              </StyledTableCell>
                            </TableBody>
                          ))}
                        </Table>
                      );
                    }}
                  </FieldArray>
                </div>
              </div>
              <div className="createFootCust">
                {/* <div>
                  <div
                    className="button secondaryButton"
                    onClick={() =>
                      formik.resetForm({
                        values: {
                          status: "",
                          Priority: "",
                          stages: [{ steps: "" }],
                        },
                      })
                    }
                  >
                    Reset
                  </div>
                </div> */}
                <div>
                  <button
                    className="button primaryButton"
                    type="submit"
                    onClick={() => {
                      submitActionRef.current = "save";
                    }}
                  >
                    Save & Update
                  </button>
                </div>
                <div>
                  <button
                    className="button primaryButton"
                    type="submit"
                    onClick={() => {
                      submitActionRef.current = "submit";
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export { UpdateStatus };

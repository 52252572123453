import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function UploadImageDetail({ filepath, styl }) {
  const [fileName, setFileName] = useState(filepath);
  useEffect(() => {
    if (filepath)
      if (filepath.includes("/")) {
        let sliceIndex = filepath.indexOf("/");
        setFileName(filepath.slice(sliceIndex + 1));
      }
  }, [filepath]);

  return (
    <img
      style={{ maxWidth: "250px", maxHeight: "200px", ...styl }}
      src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/${filepath}`}
      alt={fileName}
    />
  );
}

export default UploadImageDetail;

import React from "react";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { useEffect } from "react";

export const GenralForm = () => {
  useEffect(() => {
    document.title = `PeopleSol - General Form`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div>
        <LabelCustom labelName={"Relieving Letter Format"} />
        <TextAreaFormik
          name="GeneralRelievingLetterFormat"
          placeholder="Type Here"
        />
      </div>
      <div>
        <LabelCustom labelName={"Ob Certificate Content"} />
        <TextAreaFormik name="ObCertificateContent" placeholder="Type Here" />
      </div>

      <div>
        <LabelCustom labelName={"Cafeteria Email Id"} />
        <InputCustomFormik
          type={"email"}
          name="CafeteriaEmailId"
          placeholder={"Enter Email Id "}
        />
      </div>
    </>
  );
};

import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../../Components/FormComponent/PopupComponent/Remark";
import { Details } from "../../DetailsComp/Details";
import { Timeline } from "../../DetailsComp/TimeLine";
import { Notifications } from "../../DetailsComp/Notifications";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import UploadDetail from "../../../../../Components/UploadDetail/UploadDetail";
import { format } from "date-fns";
import { getBadgeCount } from "../../../../../Features/badgeUtils";
const DetailsPageInduction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  // //console.log("id", colorAction);
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (type, color) => {
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`InductionPlans/Details/${selectedId}`);
      setData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page Induction`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const PostApRejHol = async (type, id, Remark) => {
    // //console.log("type", type);
    // //console.log("id", id);
    // //console.log("type", Remark);
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Approve" || type === "Cancel" || type === "Release") {
        await postRequest(`InductionPlans/${type}?Id=${id}`);
      } else if (type === "Mark as Closed") {
        await postRequest(`InductionPlans/Close?Id=${id}&Remarks=${Remark}`);
      } else {
        await postRequest(`InductionPlans/${type}?Id=${id}&Remarks=${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  // //console.log("data", data);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Induction Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data?.data != null ? (
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="headingForm"> Induction Plan Details</div>
                    <div className="detailInner">
                      <>
                        <Details
                          detailTitle={"Request Number"}
                          Details={data?.summary?.requestNumber}
                        />
                        <Details
                          detailTitle={"Employee Name"}
                          Details={
                            data?.summary?.employee?.nameWithPersonNumber
                          }
                        />
                        <Details
                          detailTitle={"Created By"}
                          Details={
                            data?.summary?.createdBy?.nameWithPersonNumber
                          }
                        />

                        <Details
                          detailTitle={"Induction Date"}
                          Details={format(
                            new Date(data?.summary?.inductionDate),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                        <Details
                          detailTitle={"Second Induction Date"}
                          Details={format(
                            new Date(data?.summary?.secondInductionDate),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                        <Details
                          detailTitle={"Final Induction Date"}
                          Details={data?.summary?.finalInductionDate ? format(
                            new Date(data?.summary?.finalInductionDate),
                            "dd/MM/yyyy hh:mm a"
                          ) : "-"}
                        />

                        <Details
                          detailTitle={"Status"}
                          Details={
                            <div
                              style={{
                                backgroundColor: getStatusColor(
                                  data?.summary?.status
                                )?.split(",")?.[0],
                                color: getStatusColor(
                                  data?.summary?.status
                                )?.split(",")?.[1],
                                cursor: "default",
                                padding: "6px",
                                borderRadius: "6px",
                              }}
                            >
                              {data?.summary?.status}
                            </div>
                          }
                        />
                        <Details
                          detailTitle={"Induction Plan Attachment"}
                          Details={
                            <UploadDetail
                              filepath={`InductionPlans/${data?.summary?.attachment}`}
                            />
                          }
                        />

                        {/* <Details
                            detailTitle={"Created On"}
                            Details={data?.data?.createdOn.replace(
                              "T",
                              " "
                            )}
                          />
                          <Details
                            detailTitle={"Last Updated On"}
                            Details={data?.data?.lastUpdatedOn.replace(
                              "T",
                              " "
                            )}
                          /> */}
                      </>
                    </div>

                    {/* {data?.data?.actionlist ? (
                      <div
                        className="createFootDetails"
                        style={{ background: "white !important" }}
                      >
                        {data?.data?.actionlist.map((item) => (
                          <div>
                            <div
                              className="button secondaryButton"
                              onClick={() => {
                                item.type !== "Approve" &&
                                item.type !== "Release" &&
                                item.type !== "Cancel"
                                  ? togglePopup(item?.type, item?.color)
                                  : PostApRejHol(item?.type, item?.id);
                              }}
                              style={{
                                background: item?.color,
                                color: "white",
                              }}
                            >
                              {item?.type}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                    {data?.data?.actionlist.map(
                      (item) =>
                        isOpen && (
                          <Popup
                            popupHeading={item?.type}
                            content={
                              <>
                                <Remark
                                  data={PostApRejHol}
                                  type={typeAction}
                                  id={item?.id}
                                  color={colorAction}
                                  handleClose={togglePopup}
                                />
                              </>
                            }
                            handleClose={togglePopup}
                          />
                        )
                    )} */}
                  </div>
                ) : (
                  <div className="noDatastyle">
                    <Loader />
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Records"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <Timeline
                        id={selectedId}
                        module={"Induction Plan"}
                        requestNumber={data?.summary?.requestNumber}
                      />
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications id={selectedId} module={"Induction Plan"} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageInduction };

import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Notifications } from "../../DetailsComp/Notifications";
import { Details } from "../../DetailsComp/Details";
import { Remark } from "../../../../../Components/FormComponent/PopupComponent/Remark";

import Nodata from "../../../../../Assets/Nodata.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { TimeLineData } from "../../TransferComponents/DetailsComp/Timeline/TimeLineData";

const DetailsExitFeedback = ({ data, details, loading }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Exit Feedback`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="tabularMasterDetailsPageBody" style={{ maxHeight: "100%" }}>
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            style={{ minWidth: 130 }}
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Details
              </span>
            }
          />
          <Tab
            style={{ minWidth: 130 }}
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Timeline
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {data && (
        <>
          {tabIndex === 0 &&
            (data != null ? (
              loading ? (
                <div className="noDataFound">
                  <Loader />
                </div>
              ) : (
                <div className="detailsSep" style={{ flexDirection: "column" }}>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Employee Name"}
                      Details={data?.employee?.name}
                    />
                    <Details
                      detailTitle={"Employee No"}
                      Details={data?.employee?.personNumber}
                    />
                    <Details
                      detailTitle={"How would you rate your organisation "}
                      Details={data?.exitInterviews?.rating}
                    />
                    <Details
                      detailTitle={
                        "Kindly specify your Reason for leaving  the organisation ?"
                      }
                      Details={data?.exitInterviews?.reasonForLeaving}
                    />
                    <Details
                      detailTitle={
                        "Please mention 3 things that  you  most liked about the organisation."
                      }
                      Details={
                        <ol style={{ marginLeft: "20px" }}>
                          <li>{data?.exitInterviews?.bestThings1}</li>{" "}
                          <li>{data?.exitInterviews?.bestThings2}</li>{" "}
                          <li>{data?.exitInterviews?.bestThings3}</li>
                        </ol>
                      }
                    />
                    <Details
                      detailTitle={
                        "Please mention 3 things you didn't liked about the organisation."
                      }
                      Details={
                        <ol style={{ marginLeft: "20px" }}>
                          <li>{data?.exitInterviews?.thingsDoNotLike1}</li>{" "}
                          <li>{data?.exitInterviews?.thingsDoNotLike2}</li>{" "}
                          <li>{data?.exitInterviews?.thingsDoNotLike3}</li>
                        </ol>
                      }
                    />
                  </div>
                  <div className="headingForm">
                    Please complete the below questions and provide feedback
                  </div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Throughout the company"}
                      Details={data?.exitInterviews?.throughoutTheCompany}
                    />
                    <Details
                      detailTitle={"Between managers and staff "}
                      Details={data?.exitInterviews?.betweenManagersAndStaff}
                    />
                    <Details
                      detailTitle={"Between Department"}
                      Details={data?.exitInterviews?.betweenDepartments}
                    />
                    <Details
                      detailTitle={"Within your department"}
                      Details={data?.exitInterviews?.withinYourDepartment}
                    />
                  </div>
                  <div className="detailInner">
                    <Details
                      detailTitle={
                        "Would you rejoin the organisation, if given an offer in future?"
                      }
                      Details={data?.exitInterviews?.reJoin ? "Yes" : "No"}
                    />
                    <Details
                      detailTitle={"On What Terms?"}
                      Details={data?.exitInterviews?.onWhatTerms}
                    />
                    <Details
                      detailTitle={
                        "Is there anything the company could have done to prevent you from leaving?"
                      }
                      Details={
                        data?.exitInterviews
                          ?.isanythingCompanyDoneAnythingtoPreventYouFromLeaving
                      }
                    />
                  </div>
                  <div className="headingForm">
                    How would you describe communication in the organisation?
                  </div>
                  <div className="detailInner">
                    <Details
                      detailTitle={
                        "Your orientation to the job was effective ?"
                      }
                      Details={data?.exitInterviews?.myOrientationToTheJob}
                    />
                    <Details
                      detailTitle={"Your skills have been used effectively ?"}
                      Details={data?.exitInterviews?.mySkillsUsedEffectively}
                    />
                    <Details
                      detailTitle={
                        "You have been provided all useful information, on time ?"
                      }
                      Details={
                        data?.exitInterviews?.providedAllUsefulInformationOnTime
                      }
                    />
                    <Details
                      detailTitle={
                        "Your work schedule met your professional needs ?"
                      }
                      Details={
                        data?.exitInterviews?.workScheduleMetmyProfessionalNeeds
                      }
                    />
                    <Details
                      detailTitle={
                        "You have been given the opportunity to grow ?"
                      }
                      Details={data?.exitInterviews?.careerAdvancement}
                    />
                    <Details
                      detailTitle={
                        "You had a clear understanding of the tasks and expectations ?"
                      }
                      Details={
                        data?.exitInterviews
                          ?.understandingOfTheTasksandExpectations
                      }
                    />
                    <Details
                      detailTitle={
                        "I had the opportunity to work on different and/ or latest technologies"
                      }
                      Details={
                        data?.exitInterviews
                          ?.opportunityToWorkonLatestTechnologies
                      }
                    />
                    <Details
                      detailTitle={"Your Job training was adequate ?"}
                      Details={data?.exitInterviews?.jobTrainingWasAdequate}
                    />
                    <Details
                      detailTitle={"Any suggestion for the organisation"}
                      Details={data?.exitInterviews?.suggestionsToDevelopAESS}
                    />
                  </div>

                </div>
              )
            ) : (
              <div className="noDatastyle">
                <img
                  src={Nodata}
                  alt="No data"
                  style={{ height: "150px", width: "250px" }}
                />
                <h4>{"No Records"}</h4>
              </div>
            ))}
          {tabIndex === 1 && (
            <>
              <div className="tabularMasterDetailsPageTabBodyContainer">
                <div style={{ display: "flex", width: "100%" }}>
                  <TimeLineData
                    id={details?.request?.id}
                    module={details?.module}
                    requestNumber={details?.request?.requestNumber}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export { DetailsExitFeedback };

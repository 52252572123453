import React, { useEffect, useState } from 'react'
import { FormRadioCustomReward } from '../../../Components/FormComponent/FormRadioCustomReward'
import { TextAreaCustom } from '../../../Components/FormComponent/TextAreaCustom'

const FormRewardsRadioComp = ({ 
    firstposition, 
    ind, 
    detail, 
    editID, 
    values, 
    initialValues, 
    setInitialValues, 
    valueString=false 
}) => {
    const [textValue, setTextValue] = useState("")
    console.log("object textValue", textValue)

    useEffect(() => {
        if(valueString && valueString?.length>0){
        setInitialValues((prev) => {
            const objFinal = { ...values }
            objFinal[valueString][ind][`Position${ind + 1}textArea`] = textValue;
            console.log("objFinal",objFinal)
            return objFinal
        })}
    }, [textValue,valueString])

    useEffect(() => {
        if (editID) {
            setTextValue(initialValues?.[valueString]?.[ind]?.[`Position${ind + 1}textArea`])
        }
    }, [detail])

    return (
        <div className="radioFormikcustomflex">
            <FormRadioCustomReward
                options={firstposition}
                ind={ind}
                name={`${valueString}[${ind}].Position${ind + 1}`} // Dynamic name
                setTextValue={setTextValue}
                textValue={textValue}
                editstyle={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "baseline",
                }}
            />
            <div>
            </div>
        </div>
    )
}

export default FormRewardsRadioComp

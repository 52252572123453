import { IconButton } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TableEmployeeSkillSetcontent } from "./TableEmployeeSkillSetcontent";
import Nodata from "../../../Assets/Nodata.svg";

import { format } from "date-fns";

const TableEmployeeSkillSet = ({
  heading,
  togglePopupOrganisation,
  tbodyDataitem,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentpath = location.pathname.toLowerCase();
  let splitArray = currentpath.split("/");
  // console.log("let splitArray", tbodyDataitem);
  const skillsetdesign = {};

  return (
    <>
      {tbodyDataitem?.length > 0 ? (
        <div
          className="TableEmployeeSkillSetDesign"
          style={{ position: "relative" }}
        >
          <table className="SkillsLDtable" style={{ marginTop: "-10px" }}>
            <thead
              className="SkillsLDtableHead"
              style={{ position: "sticky", top: "0px", zIndex: "3" }}
            >
              <tr>
                <th className="TableEmployeeSkillheadingdiv">Employee Name</th>
                <th className="TableEmployeeSkillheadingdiv">
                  {heading === "skills"
                    ? "Skill Set Name"
                    : "Competency Set Name"}
                </th>
                <th className="TableEmployeeSkillheadingdiv">
                  {heading === "skills" ? "Skill Name" : "Competency Name"}
                </th>
                <th className="TableEmployeeSkillheadingdiv">
                  {heading === "skills" ? "Skill Level" : "Competency Level"}
                </th>
                <th className="TableEmployeeSkillheadingdiv">Document</th>
                <th className="TableEmployeeSkillheadingdiv">Created on</th>
                <th className="TableEmployeeSkillheadingdiv">
                  Last Updated on
                </th>
                <th className="TableEmployeeSkillheadingdiv"></th>
              </tr>
            </thead>
            <tbody>
              {tbodyDataitem &&
                tbodyDataitem?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      <div className="employeeNameStyle"> {item?.name}</div>
                    </td>
                    <td>
                      {" "}
                      <div className="competencyNameStyle ">
                        {" "}
                        {heading === "skills"
                          ? item?.skillset
                          : item?.competencySet}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="competencyNameStyle ">
                        {" "}
                        {heading === "skills" ? item?.skill : item?.competency}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="skillsLevelStyle ">{`${item?.proficiencyLevel}/5`}</div>
                    </td>
                    <td>
                      {" "}
                      <div className="documentNameStyle ">
                        <a
                          href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/SkillsEmployee/${item?.attachment}`}
                          target="_blank"
                        >
                          {item?.attachment}
                        </a>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="lastUpdatedOnStyle ">
                        {format(new Date(item?.createdOn), "dd.MM.yyyy")}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="lastUpdatedOnStyle ">
                        {format(new Date(item?.lastUpdatedOn), "dd.MM.yyyy")}
                      </div>
                    </td>
                    <td className=" ">
                      <div
                        onClick={() =>
                          togglePopupOrganisation(item?.employeeId)
                        }
                        className="skillbuttondesign"
                      >
                        {" "}
                        {heading === "skills"
                          ? "All Skills"
                          : "All Competencies"}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Records"}</h4>
        </div>
      )}
    </>
  );
};

export { TableEmployeeSkillSet };

import React from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";
// import { useDispatch } from "react-redux";
// import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
// import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
// import { YesNoFormik } from "../../../../../Components/FormComponent/YesNoFormik";
// import { showToast } from "../../../../../Features";
// import { getRequest } from "../../../../../Services/axios";
// import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import CrossIcon from "../../../../../Assets/CrossChat.svg"

// import DocIconWhite from "../../../../../Assets/DocIconWhite.svg"
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Header } from "./Header";
import { Footer } from "./Footer";
function LetterView({ formValues, employeeList, templateList, templateData, deleteTempValue, isRevise, showLet }) {

    const { LoggedInUser } = useSelector((state) => state.auth);
    const [pages, setPages] = useState(1);
    const divRef = useRef();
    const divRef1 = useRef();
    const divRef2 = useRef();
    const divRef3 = useRef();
    const divRef4 = useRef();

    const generatePDF = async () => {
        const pdf = new jsPDF("p", "mm", "a4"); // PDF size: A4, portrait mode
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const headerHeight = pageHeight * 0.1; // 15% of the page height
        const footerHeight = pageHeight * 0.1; // 15% of the page height
        const bodyHeight = pageHeight * 0.75; // 70% of the page height

        const elements = [divRef.current, divRef1.current, divRef2.current, divRef3.current];

        for (let element of elements) {
            // Options for html2canvas
            const options = { scale: 2 };

            // Render header
            const headerCanvas = await html2canvas(element.querySelector("div:first-child"), options);
            const headerData = headerCanvas.toDataURL("image/png");
            const headerWidth = pageWidth - 20; // 10mm margin on each side
            const headerRenderedHeight = (headerCanvas.height / headerCanvas.width) * headerWidth; // Preserve aspect ratio

            pdf.addImage(
                headerData,
                "PNG",
                10, // X margin
                10, // Y margin
                headerWidth,
                headerRenderedHeight
            );

            // Render body
            const bodyCanvas = await html2canvas(element.querySelector("div:nth-child(2)"), options);
            const bodyData = bodyCanvas.toDataURL("image/png");
            const bodyWidth = pageWidth - 20; // 10mm margin on each side
            const bodyAspectRatio = bodyCanvas.height / bodyCanvas.width;
            const bodyRenderedHeight = Math.min(bodyHeight, bodyWidth * bodyAspectRatio); // Scale to fit within the body area without stretching

            const bodyYPosition = 10 + headerHeight; // Start position below the header
            pdf.addImage(
                bodyData,
                "PNG",
                10, // X margin
                bodyYPosition, // Below header
                bodyWidth,
                bodyRenderedHeight
            );

            // Render footer
            const footerCanvas = await html2canvas(element.querySelector("div:last-child"), options);
            const footerData = footerCanvas.toDataURL("image/png");
            const footerWidth = pageWidth - 20; // 10mm margin on each side
            const footerRenderedHeight = (footerCanvas.height / footerCanvas.width) * footerWidth; // Preserve aspect ratio

            const footerYPosition = pageHeight - footerHeight; // Start position above bottom margin
            pdf.addImage(
                footerData,
                "PNG",
                10, // X margin
                footerYPosition, // Above bottom
                footerWidth,
                footerRenderedHeight
            );

            // Add a new page if more elements are to be processed
            if (element !== elements[elements.length - 1]) {
                pdf.addPage();
            }
        }

        // Save the generated PDF
        // pdf.save("document.pdf");
        const pdfBlob = pdf.output("blob");
        // Create FormData and append the PDF Blob
        const formData = new FormData();
        formData.append("file", pdfBlob, "document.pdf");
    };





    const Body = () => {
        return (
            <div style={{ padding: 20, paddingLeft: 40, paddingRight: 40, height: "69%" }}>
                {pages === 1 && <div>
                    <p style={{ fontSize: 8, }}>
                        {new Date().toLocaleDateString()}
                    </p>
                    <p style={{ fontSize: 8, marginBottom: 10 }}>
                        Employee Name - «FIRST_NAME» «MIDDLE_NAME» «LAST_NAME»
                    </p>
                    <p style={{ textDecoration: "underline", textAlign: "center", fontSize: 11, fontWeight: "bold" }}>
                        Letter of Appointment
                    </p>
                    <p style={{ fontSize: 10, marginBottom: 20 }}>
                        Dear «FIRST_NAME»,
                    </p>
                    <p style={{ fontSize: 8, marginBottom: 20 }}>
                        With reference to your application and the subsequent interview you had with us, we are pleased to appoint you as a «DESIGNATION» in «DEPARTMENT» «SUB_DEPARTMENT» at our Plant “«BUSINESS_UNIT»” w.e.f «DATE_OF_JOINING», the following terms and conditions are as below:-
                    </p>
                    <p style={{ fontSize: 8, marginBottom: 20 }}>
                        1. Your period of training will be initially for a period of 12 Months with effected from your date of appointment. The period of training may be varied depending upon your progress during the period of Training. On completion of the training period, your services are liable to be terminated, and the management is under no obligation to regularize your services.
                    </p>
                    <p style={{ fontSize: 8, marginBottom: 20 }}>
                        2. On successful completion of 12-month training , the Trainee shall be assessed on performance, learnings and potential. Based on performance assessment, he/she shall be confirmed to the position of Jr. Engineer/Jr. Executive/Engineer /Executive.
                    </p>
                    <p style={{ fontSize: 8, marginBottom: 20 }}>
                        3. The Trainee not meeting the performance standards shall be put under a Performance Improvement Plan (PIP) for a period of 3 months. If the performance of the Trainee is found to be satisfactory post-evaluation, he/she shall be confirmed to the position of Engineer / Executive.
                    </p>
                    <p style={{ fontSize: 8, marginBottom: 20 }}>
                        4. After the successful completion of your training, subject to your satisfactory performance, attendance, punctuality, observance of discipline etc., and if there are any vacancies, the management may consider your employment and offer you for suitable employment by appointing you as a probationer for the period of 6 Month. Until and unless you are confirmed in writing, you shall continue to be as Graduate Engineer Trainee.
                    </p>
                </div>}
                {pages === 2 &&
                    <div>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"5. During the probation period or extended probation period your services can be terminated without assigning any reason. Unless confirmed in writing, you will be deemed as probationer after the expiry of the initial or the extended period of probation. During probation period either party will be at liberty to terminate service by giving 10 days’ notice or payment of basic pay lieu thereof."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"6. During the period of 12 Month training, you will be paid a consolidated remuneration of «CTC_AMOUNT» as CTC per year.  During the period of Training, either party will be at liberty to terminate service by giving ten days’ notice or payment of basic plus dearness allowance in lieu of notice."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"7. During the tenure of your training with the company, your services will be liable   to be transferred   from one place to another and/or from one department/unit to another or any other subsidiary/Associate Establishment either existing   or to be set up in future anywhere in India purely at the discretion of the Organization. On such transfer, you will be governed by the Rules and Regulations and other working/service conditions as applicable at the place of posting."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"8. You will undergo training in the field of «DEPARTMENT» besides the company may entrust you to undergo training in any other functions as deemed fit.  You shall be required to attend training courses at Institute/Academy as specified by the company during the period of your above training."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"9. You will be required to attend training as and when required at timings in any shift at present in existence or which may be started subsequently which may be nominated by us consistent with the requirement of the organization.  It should not be presumed that the scope of your training is circumscribed or limited by your designation and it should be clearly understood that we reserve to ourselves the absolute right to allot you any type of duties during training whatsoever consistent with the work requirements and the nature of your employment."}
                        </p>


                    </div>
                }
                {pages === 3 &&
                    <div>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"10. You will be governed by the Company Code of Conduct / Standing Orders / Service Rules and Regulations of the Organization as may be in force from time to time."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"11. You are required to be punctual, diligent and comply with the instructions of your supervisor, and others senior to you. The management reserves the right to terminate your service at any time, on ground of irregular attendance, unsatisfied work or acts of indiscipline. The decision of the management in these matters shall be final."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"12. Your engagement as «DESIGNATION» and continuation in service with the Organization will be subject to your remaining medically, physically and mentally fit and alert. You are   required to subject yourself for medical examination or re-examination at any time as and when required by the Management, before the Medical Officer/Medical Practitioner/Specialist specified by the Organization."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"13. Your training is on a whole-time basis and you shall not engage yourself in any other work, anywhere in any capacity either honorary or other-wise."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"14. You will not at any time, without the consent of competent authorities disclose or divulge or make public any information about the company’s business, affairs or secrets whether the same may be confided to you or become known to you in the course of your training with the company or other-wise, to any other individual or institution or any customer while in the services of the company and/or even after you leave the services of this organization."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"15. You must use PPE’s (Personal Protective Equipment’s) such as safety glasses, safety shoes, and ear muffs etc., based on the nature of training which you perform or as specified in the specific areas. In case if you violate company’s safety policies and procedures, the same will be viewed as serious misconduct and suitable disciplinary action will be initiated."}
                        </p>
                    </div>}
                {pages == 4 &&
                    <div>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"16. Absence from training/ duty for a continuous period of 7 days including absence when on leave, though applied for but not granted, and when over stayed, for a period of 10 days would make you lose your lien on the service and the same shall automatically come to an end without any notice or even intimation."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"17. If it is found that you have misrepresented any information in your application for employment or have furnished any false information or have concealed any relevant material facts, your engagement is liable to be terminated any time, without notice or compensation in lieu thereof."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 25 }}>

                            {"In case the above terms and conditions are acceptable to you, please return the duplicate copy of this fixed term engagement letter after affixing your signature in token of your having accepted the above terms and conditions."}
                        </p>
                        <p style={{ fontSize: 10, marginBottom: 15, textDecoration: "underline", fontWeight: "500", textAlign: "center" }}>
                            {"Acknowledgment by the Employee"}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>

                            {"I acknowledge receiving a copy of Appointment Letter and Employment Terms and conditions relating to all incidents of my employment with the company and after having read and understood and comprehended the contents and implications therein, I am satisfied and agree to abide by them."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"Employee Signature: ______________"}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 0 }}>
                            {"For & on behalf of  "}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"ASK Automotive Limited  "}
                        </p>

                    </div>}
                {pages == 5 &&
                    <div>
                        <p style={{ fontSize: 10, marginBottom: 15, textDecoration: "underline", fontWeight: "500", textAlign: "center" }}>
                            {"Annexure I"}
                        </p>
                    </div>}

            </div>
        )
    }

    const PDFView = () => {
        return (
            <div style={{ color: "black" }}>
                <div
                    ref={divRef}
                    style={{ width: "40%", height: "90%", backgroundColor: "white", borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 10, }}>
                    <Header />
                    <div>
                        <p style={{ fontSize: 8, }}>
                            {new Date().toLocaleDateString()}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 10 }}>
                            Employee Name - «FIRST_NAME» «MIDDLE_NAME» «LAST_NAME»
                        </p>
                        <p style={{ textDecoration: "underline", textAlign: "center", fontSize: 11, fontWeight: "bold" }}>
                            Letter of Appointment
                        </p>
                        <p style={{ fontSize: 10, marginBottom: 20 }}>
                            Dear «FIRST_NAME»,
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            With reference to your application and the subsequent interview you had with us, we are pleased to appoint you as a «DESIGNATION» in «DEPARTMENT» «SUB_DEPARTMENT» at our Plant “«BUSINESS_UNIT»” w.e.f «DATE_OF_JOINING», the following terms and conditions are as below:-
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            1. Your period of training will be initially for a period of 12 Months with effected from your date of appointment. The period of training may be varied depending upon your progress during the period of Training. On completion of the training period, your services are liable to be terminated, and the management is under no obligation to regularize your services.
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            2. On successful completion of 12-month training , the Trainee shall be assessed on performance, learnings and potential. Based on performance assessment, he/she shall be confirmed to the position of Jr. Engineer/Jr. Executive/Engineer /Executive.
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            3. The Trainee not meeting the performance standards shall be put under a Performance Improvement Plan (PIP) for a period of 3 months. If the performance of the Trainee is found to be satisfactory post-evaluation, he/she shall be confirmed to the position of Engineer / Executive.
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            4. After the successful completion of your training, subject to your satisfactory performance, attendance, punctuality, observance of discipline etc., and if there are any vacancies, the management may consider your employment and offer you for suitable employment by appointing you as a probationer for the period of 6 Month. Until and unless you are confirmed in writing, you shall continue to be as Graduate Engineer Trainee.
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"5. During the probation period or extended probation period your services can be terminated without assigning any reason. Unless confirmed in writing, you will be deemed as probationer after the expiry of the initial or the extended period of probation. During probation period either party will be at liberty to terminate service by giving 10 days’ notice or payment of basic pay lieu thereof."}
                        </p>

                    </div>

                    <Footer />
                </div>
                <div
                    ref={divRef1}
                    style={{ width: "40%", height: "90%", backgroundColor: "white", borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 10, }}>
                    <Header />
                    <div>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"6. During the period of 12 Month training, you will be paid a consolidated remuneration of «CTC_AMOUNT» as CTC per year.  During the period of Training, either party will be at liberty to terminate service by giving ten days’ notice or payment of basic plus dearness allowance in lieu of notice."}
                        </p>

                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"7. During the tenure of your training with the company, your services will be liable   to be transferred   from one place to another and/or from one department/unit to another or any other subsidiary/Associate Establishment either existing   or to be set up in future anywhere in India purely at the discretion of the Organization. On such transfer, you will be governed by the Rules and Regulations and other working/service conditions as applicable at the place of posting."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"8. You will undergo training in the field of «DEPARTMENT» besides the company may entrust you to undergo training in any other functions as deemed fit.  You shall be required to attend training courses at Institute/Academy as specified by the company during the period of your above training."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"9. You will be required to attend training as and when required at timings in any shift at present in existence or which may be started subsequently which may be nominated by us consistent with the requirement of the organization.  It should not be presumed that the scope of your training is circumscribed or limited by your designation and it should be clearly understood that we reserve to ourselves the absolute right to allot you any type of duties during training whatsoever consistent with the work requirements and the nature of your employment."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"10. You will be governed by the Company Code of Conduct / Standing Orders / Service Rules and Regulations of the Organization as may be in force from time to time."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"11. You are required to be punctual, diligent and comply with the instructions of your supervisor, and others senior to you. The management reserves the right to terminate your service at any time, on ground of irregular attendance, unsatisfied work or acts of indiscipline. The decision of the management in these matters shall be final."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"12. Your engagement as «DESIGNATION» and continuation in service with the Organization will be subject to your remaining medically, physically and mentally fit and alert. You are   required to subject yourself for medical examination or re-examination at any time as and when required by the Management, before the Medical Officer/Medical Practitioner/Specialist specified by the Organization."}
                        </p>

                    </div>

                    <Footer />
                </div>
                <div
                    ref={divRef2}
                    style={{ width: "40%", height: "90%", backgroundColor: "white", borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 10, }}>
                    <Header />

                    <div>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"13. Your training is on a whole-time basis and you shall not engage yourself in any other work, anywhere in any capacity either honorary or other-wise."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"14. You will not at any time, without the consent of competent authorities disclose or divulge or make public any information about the company’s business, affairs or secrets whether the same may be confided to you or become known to you in the course of your training with the company or other-wise, to any other individual or institution or any customer while in the services of the company and/or even after you leave the services of this organization."}
                        </p>

                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"15. You must use PPE’s (Personal Protective Equipment’s) such as safety glasses, safety shoes, and ear muffs etc., based on the nature of training which you perform or as specified in the specific areas. In case if you violate company’s safety policies and procedures, the same will be viewed as serious misconduct and suitable disciplinary action will be initiated."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"16. Absence from training/ duty for a continuous period of 7 days including absence when on leave, though applied for but not granted, and when over stayed, for a period of 10 days would make you lose your lien on the service and the same shall automatically come to an end without any notice or even intimation."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"17. If it is found that you have misrepresented any information in your application for employment or have furnished any false information or have concealed any relevant material facts, your engagement is liable to be terminated any time, without notice or compensation in lieu thereof."}
                        </p>

                    </div>

                    <Footer />
                </div>
                <div
                    ref={divRef3}
                    style={{ width: "40%", height: "90%", backgroundColor: "white", borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 10, }}>
                    <Header />

                    <div>
                        <p style={{ fontSize: 8, marginBottom: 25 }}>
                            {"In case the above terms and conditions are acceptable to you, please return the duplicate copy of this fixed term engagement letter after affixing your signature in token of your having accepted the above terms and conditions."}
                        </p>
                        <p style={{ fontSize: 10, marginBottom: 15, textDecoration: "underline", fontWeight: "500", textAlign: "center" }}>
                            {"Acknowledgment by the Employee"}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"I acknowledge receiving a copy of Appointment Letter and Employment Terms and conditions relating to all incidents of my employment with the company and after having read and understood and comprehended the contents and implications therein, I am satisfied and agree to abide by them."}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"Employee Signature: ______________"}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 0 }}>
                            {"For & on behalf of  "}
                        </p>
                        <p style={{ fontSize: 8, marginBottom: 20 }}>
                            {"ASK Automotive Limited  "}
                        </p>
                        <p style={{ fontSize: 10, marginBottom: 15, textDecoration: "underline", fontWeight: "500", textAlign: "center" }}>
                            {"Annexure I"}
                        </p>
                    </div>

                    <Footer />
                </div>
            </div>

        )
    }
    return (
        <div style={{ backgroundColor: "rgba(0, 0, 0, .5)", width: "100%", height: "100%", position: "absolute", zIndex: 100 }}>
            <div
                onClick={() => { showLet(); }}
                style={{ position: "absolute", right: 20, top: 20, zIndex: 111 }}
            >
                <img src={CrossIcon} alt="close" />
            </div>
            <div style={{ width: "100%", height: "100%", position: "relative", }}>
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", }}>
                    <div style={{ width: "35%", height: "90%", backgroundColor: "white", borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 10 }}>
                        <Header />
                        <Body />
                        <Footer />
                    </div>

                </div>

                <div style={{
                    position: "absolute", bottom: 0,
                    left: 0,
                    right: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "400px"
                }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
                        <div
                            onClick={() => { if (pages > 1) setPages(pages - 1) }}
                            className="button primaryButton"
                        >
                            Previous
                        </div>
                        <div onClick={() => { generatePDF() }}>
                            <span style={{
                                color: "white"
                            }}>
                                {"Pages " + pages}
                            </span>
                        </div>
                        <div
                            onClick={() => { setPages(pages + 1) }}
                            className="button primaryButton"
                        >
                            Next
                        </div>
                    </div>
                </div>
            </div>

            <PDFView />
        </div>
    );
}

export { LetterView };

import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { StepsHierarchy } from "../../../Components/FormComponent/StepsHierarchy";
import { WorkflowStepsComp } from "../../../Components/FormComponent/WorkflowStepsComp";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailsServiceIt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`ServiceTypes/Details/${selectedId}`);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  }, []);

  useEffect(() => {
    document.title = `PeopleSol - IT Service Detail`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // //console.log("eventtData", eventtData);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody" style={{ gap: "5px" }}>
            {/* <div className="moduleMasterDetailsBox"> */}
            <DetailsStep detailTitle={"Name"} Details={eventtData?.name} />
            <DetailsStep
              detailTitle={"Service Category"}
              Details={eventtData?.category?.name}
            />

            <DetailsStep
              detailTitle={"Request Category"}
              Details={eventtData?.requestCategory?.name}
            />
            <DetailsStep
              detailTitle={"Asset Category"}
              Details={eventtData?.assetCategory?.name}
            />
            <DetailsStep
              detailTitle={"Notification Flow"}
              Details={eventtData?.notificationFlow?.title}
            />
            <DetailsStep
              detailTitle={"Team Name"}
              Details={eventtData?.teamName}
            />
            <DetailsStep
              detailTitle={"Require more Information for Asset Approval?"}
              Details={
                eventtData?.requiremoreinformationforAssetApproval == true
                  ? "Yes"
                  : "No"
              }
            />
            <DetailsStep
              detailTitle={"Asset Request Type"}
              Details={eventtData?.assetRequestType}
            />
            <DetailsStep
              detailTitle={"Is Active"}
              Details={eventtData?.isActive == true ? "Yes" : "No"}
            />
            <div
              className="headingForm"
              style={{ margin: "12px 12px 12px 7px" }}
            >
              TAT (In Hrs)
            </div>
            <DetailsStep detailTitle={"High"} Details={eventtData?.tatHigh} />
            <DetailsStep
              detailTitle={"Medium"}
              Details={eventtData?.tatMedium}
            />
            <DetailsStep detailTitle={"Low"} Details={eventtData?.tatLow} />
            <DetailsStep
              detailTitle={"Created On"}
              Details={format(
                new Date(eventtData?.createdOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />
            <DetailsStep
              detailTitle={"Last Updated On"}
              Details={format(
                new Date(eventtData?.lastUpdatedOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsServiceIt };

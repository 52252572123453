import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { newemployeefacilities } from "./NewEmployeeFacilitiesSchemas";
import { BuisnessUnitComp } from "./BuisnessUnitComp";
import * as Yup from "yup";

const CreateBuisnessUnit = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const [detail, setDetail] = useState(false);
  const dispatch = useDispatch();

  const getDetails = async () => {
    try {
      const result = await getRequest(`HRHelpdeskTeams/Details/${data}`);
      setDetail(result.data);
      console.log("datas data", result);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getInitalAnswerValues = () => {
    let returnArray = [];
    data
      ? detail?.teamsMembers?.map((item) => {
          return returnArray.push({
            employeeId: item.employeeId,
            signature: item.signature,
          });
        })
      : (returnArray = [{ employeeId: "", signature: null }]);

    return returnArray;
  };
  const initialvalue = {
    name: data ? detail?.name : "",
    teamsMembers: getInitalAnswerValues(),
  };
  const postFormData = async (values) => {
    try {
      const result = await postRequestForm("HRHelpdeskTeams/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {}
  };
  const Edit = async (values) => {
    try {
      const result = await postRequestForm("HRHelpdeskTeams/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required(" Please Enter Name"),
    teamsMembers: Yup.array().of(
      Yup.object().shape({
        employeeId: Yup.string().required("Please Select Employee"),
        signature: Yup.string().required("Please Attach Signature"),
      })
    ),
  });
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("id", data ? detail?.id : 0);
    formData.append("name", values?.name);
    values?.teamMembers?.forEach((item, ind) => {
      formData.append(`teamMembers[${ind}].employeeId`, values?.employeeId);
      if (typeof values?.signature === "object")
        formData.append(`teamMembers[${ind}].signature`, values?.signature);
    });

    data ? Edit(formData) : postFormData(formData);
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Buisness Unit`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <FormCustomFormik
      ButtonLabel={data ? "Update  Buisness Unit" : "Create  Buisness Unit"}
      FormHeading={data ? "Edit Buisness Unit" : "Create Buisness Unit"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<BuisnessUnitComp data={detail} editID={data ? true : false} />}
    />
  );
};

export { CreateBuisnessUnit };

import React, { useState } from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { RadioFormik } from "../../../Components/FormComponent/RadioFormik";
import { FormRadioCustomReward } from "../../../Components/FormComponent/FormRadioCustomReward";
import { TextAreaCustom } from "../../../Components/FormComponent/TextAreaCustom";
import FormRewardsRadioComp from "./FormRewardsRadioComp";

function CreateRewardWinnerPosition({ values, data, editID, initialValues, setInitialValues }) {
  // const winnersArray = new Array(Number(values.WinnersCount) || 0).fill(0);
  console.log("this is form values ", Number(values.WinnersCount) || 0);
  // console.log("this is form values ", winnersArray);
  console.log("this is data", data);
  const firstposition = [
    { label: "Prize money with Award", value: "Prize money with Award" },
    { label: "Gift with Memento", value: "Gift with Memento" },
    { label: "Memento only", value: "Memento only" },
    { label: "Others", value: "Others" },
  ];

  const getOrdinalSuffix = (number) => {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return (
        <>
          {number}
          <sup>th</sup>
        </>
      ); // 11th, 12th, 13th for "th"
    }

    switch (lastDigit) {
      case 1:
        return (
          <>
            {number}
            <sup>st</sup>
          </>
        ); // 1st, 21st, 31st
      case 2:
        return (
          <>
            {number}
            <sup>nd</sup>
          </>
        ); // 2nd, 22nd, 32nd
      case 3:
        return (
          <>
            {number}
            <sup>rd</sup>
          </>
        ); // 3rd, 23rd, 33rd
      default:
        return (
          <>
            {number}
            <sup>th</sup>
          </>
        ); // remaining "th"
    }
  };

  const cansolations = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <div className="P-20 winner">
      <div className="formcustom">

        {values?.RewardsConfigWinnerposition?.map((it, ind) =>

          <div key={ind}>
            <h5>
              {getOrdinalSuffix(ind + 1)} Position
            </h5>{" "}
            <hr />
            <div>
              <FormRewardsRadioComp
              valueString={"RewardsConfigWinnerposition"}
                firstposition={firstposition}
                detail={data} 
                editID={editID}
                ind={ind} 
                values={values}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
              />
            </div>
          </div>

        )}
        {/* <div>
          <h5>
            2<sup>nd</sup> Position
          </h5>{" "}
          <hr />
          <div>
            <div className="radioFormikcustomflex ">
              <FormRadioCustomReward
                options={firstposition}
                name="SecondPosistion"
                editstyle={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  alignItems: "baseline",
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <h5>
            3<sup>st</sup> Position
          </h5>
          <hr />
          <div>
            <div className="radioFormikcustomflex ">
              <FormRadioCustomReward
                options={firstposition}
                name="ThirdPosistion"
                editstyle={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  alignItems: "baseline",
                }}
              />
            </div>
          </div>
        </div> */}

        <div>
          <h5>Consolation</h5>
          <hr />
          <div>
            <div className="radioFormikcustomflex ">
            <FormRewardsRadioComp
              valueString={"RewardsConfigConsolation"}
                firstposition={firstposition}
                detail={data} 
                editID={editID}
                ind={0} 
                values={values}
                setInitialValues={setInitialValues}
                initialValues={initialValues}
              />
            </div>
          </div>
        </div>
        <div className="radioFormikcustomflex ">
          <LabelCustom
            labelName={"Can cansolation candidates receive certificate"}
            mandatory="true"
          />

          <RadioFormik
            editstyle={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "baseline",
            }}
            options={cansolations}
            name="CanConsolationRecieveCetificates"
          />
        </div>
      </div>
    </div>
  );
}

export default CreateRewardWinnerPosition;

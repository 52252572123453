import React, { useState, useEffect } from "react";

import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { TimeCustomFormik } from "../../../../../Components/FormComponent/TimeCustomFormik";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { postRequest } from "../../../../../Services/axios";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";

export const EditRegularisationComp = ({ detailsform, regularizeData }) => {
  // const [categoryList, setCategoryList] = useState([]);
  // const [typeList, setTypeList] = useState([]);
  // const dispatch = useDispatch();

  const [configData, setConfigData] = useState();
  const downloadReqData = {
    dataTableRequest: {
      isExport: true,
    },
  };
  const getConfigDetails = async () => {
    try {
      var result = await postRequest(
        "AttendanceRegularizations/List",
        downloadReqData
      );
      setConfigData(result);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Edit Regularisation`;
    // getConfigDetails();
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Date"} />
        <InputCustomFormik name={"date"} type="text" disabled={true} />
      </div>
      <div>
        <LabelCustom labelName={"Reason"} mandatory={true} />
        {/* <TextAreaFormik name="remarks" placeholder={"Enter the Remarks"} /> */}
        <SelectForm
          name={`remarks`}
          options={regularizeData}
          placeholder={"Select... "}
        />
      </div>

      <div>
        <LabelCustom labelName={"Old Start Time"} />
        <InputCustomFormik name={"oldStartTime"} type="text" disabled={true} />
      </div>
      <div>
        <LabelCustom labelName={"New Start Time"} />
        <TimeCustomFormik
          name="newStartTime"
          type="text"
          style={{ margin: "12px 0" }}
        />
      </div>
      <div>
        <LabelCustom labelName={"Old End Time"} />
        <InputCustomFormik name={"oldEndTime"} type="text" disabled={true} />
      </div>
      <div>
        <LabelCustom labelName={"New End Time"} />
        <TimeCustomFormik
          name="newEndTime"
          type="text"
          style={{ margin: "12px 0" }}
        />
      </div>
      <div>
        <LabelCustom labelName={"Remarks"} />
        <TextAreaFormik name="remarksNew" placeholder={"Enter the Remarks"} />
      </div>
    </div>
  );
};

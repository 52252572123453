import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import UploadImageDetail from "../../../Components/UploadDetail/UploadImageDetail";
import { Loader } from "../../../Components/FormComponent/Loader";
import UploadDetail from "../../../Components/UploadDetail/UploadDetail";
import { format } from "date-fns";

const DetailsTax = ({ activeFeature }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let url = `IncomeTaxDeclarationSettings/Details/${selectedId}`;
      if (activeFeature === "taxstructure") url = `IncomeTaxDeclarationSettings/StructureDetails?StructureId=${selectedId}`
      const result = await getRequest(url);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.title = `PeopleSol -  Tax Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>{activeFeature === "taxstructure" ? "Tax Structure Details" : "Tax Config Details"}</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {activeFeature === "taxstructure" ?
              <div className="moduleMasterDetailsPageBody" style={{ overflow: "auto", height: "70%", paddingBottom: 100 }}>
                <DetailsStep detailTitle={"Financial Year"} Details={eventtData?.financialYear} />
                <DetailsStep
                  detailTitle={"Tax Year"}
                  Details={eventtData?.taxYear}
                />
                <DetailsStep
                  detailTitle={"Type Of Regime"}
                  Details={
                    eventtData?.typeOfRegime
                  }
                />
                <DetailsStep
                  detailTitle={"Description"}
                  Details={eventtData?.description}
                />
                <DetailsStep
                  detailTitle={"Created On"}
                  Details={
                    eventtData?.createdOn
                      ? format(
                        new Date(eventtData?.createdOn),
                        "dd/MM/yyyy hh:mm a"
                      )
                      : null
                  }
                />
                <DetailsStep
                  detailTitle={"Last Updated On"}
                  Details={
                    eventtData?.lastUpdatedOn
                      ? format(
                        new Date(eventtData?.lastUpdatedOn),
                        "dd/MM/yyyy hh:mm a"
                      )
                      : null
                  }
                />

                <table
                  className="tabless tabless-bordered"
                  style={{
                    margin: "0px 18px 0 18px",
                    overflow: "auto",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "var(--primary-hover)",
                    }}
                  >
                    <tr>
                      <td>S.no</td>
                      <td>Component Name</td>
                      <td>Display Name</td>
                      <td>limitAmount</td>
                    </tr>
                  </thead>
                  <tbody>
                    {eventtData?.taxStructureComponent.map(
                      (item, index) => {
                        return (
                          <tr className="trStyle">
                            <td>{index + 1}</td>
                            <td>{item?.componentName}</td>
                            <td>{item?.displayName}</td>
                            <td>{item?.limitAmount}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              :
              <div className="moduleMasterDetailsPageBody">
                <DetailsStep detailTitle={"Title"} Details={eventtData?.title} />
                <DetailsStep
                  detailTitle={"Document Or Submission"}
                  Details={eventtData?.emailTemplate}
                />
                <DetailsStep
                  detailTitle={"Start Date for Initiating"}
                  Details={
                    eventtData?.startDate
                      ? format(new Date(eventtData?.startDate), "dd/MM/yyyy")
                      : null
                  }
                />
                <DetailsStep
                  detailTitle={"Deadline for Filing"}
                  Details={
                    eventtData?.endDate
                      ? format(new Date(eventtData?.endDate), "dd/MM/yyyy")
                      : null
                  }
                />
                <DetailsStep
                  detailTitle={"Description"}
                  Details={eventtData?.description}
                />
                <DetailsStep
                  detailTitle={"Attachment"}
                  Details={
                    <UploadDetail filepath={`tax/${eventtData?.attachment}`} />
                  }
                />

                <DetailsStep
                  detailTitle={"Created On"}
                  Details={
                    eventtData?.createdOn
                      ? format(
                        new Date(eventtData?.createdOn),
                        "dd/MM/yyyy hh:mm a"
                      )
                      : null
                  }
                />
                <DetailsStep
                  detailTitle={"Last Updated On"}
                  Details={
                    eventtData?.lastUpdatedOn
                      ? format(
                        new Date(eventtData?.lastUpdatedOn),
                        "dd/MM/yyyy hh:mm a"
                      )
                      : null
                  }
                />
              </div>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsTax };

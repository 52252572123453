import React, { useState } from "react";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";

import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";

export const NotiFLowForm = ({ detailsform }) => {
  useEffect(() => {
    document.title = `PeopleSol - Notification Flow`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    setInitialValues({
      title: detailsform?.notificationFlowsIntervals[0]?.title,
      areminder1: detailsform?.notificationFlowsIntervals[0]?.reminder1,
      areminder2: detailsform?.notificationFlowsIntervals[0]?.reminder2,
      areminder3: detailsform?.notificationFlowsIntervals[0]?.reminder3,
      aescalation1: detailsform?.notificationFlowsIntervals[0]?.escalation1,
      aescalation2: detailsform?.notificationFlowsIntervals[0]?.escalation2,
      aescalation3: detailsform?.notificationFlowsIntervals[0]?.escalation3,
      aautoApproveAfter:
        detailsform?.notificationFlowsIntervals[0]?.autoApproveAfter,

      breminder1: detailsform?.notificationFlowsIntervals[1]?.reminder1,
      breminder2: detailsform?.notificationFlowsIntervals[1]?.reminder2,
      breminder3: detailsform?.notificationFlowsIntervals[1]?.reminder3,
      bescalation1: detailsform?.notificationFlowsIntervals[1]?.escalation1,
      bescalation2: detailsform?.notificationFlowsIntervals[1]?.escalation2,
      bescalation3: detailsform?.notificationFlowsIntervals[1]?.escalation3,
      bautoApproveAfter:
        detailsform?.notificationFlowsIntervals[1]?.autoApproveAfter,

      creminder1: detailsform?.notificationFlowsIntervals[2]?.reminder1,
      creminder2: detailsform?.notificationFlowsIntervals[2]?.reminder2,
      creminder3: detailsform?.notificationFlowsIntervals[2]?.reminder3,
      cescalation1: detailsform?.notificationFlowsIntervals[2]?.escalation1,
      cescalation2: detailsform?.notificationFlowsIntervals[2]?.escalation2,
      cescalation3: detailsform?.notificationFlowsIntervals[2]?.escalation3,
      cautoApproveAfter:
        detailsform?.notificationFlowsIntervals[2]?.autoApproveAfter,
    });
  }, [detailsform]);

  return (
    <>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          <Form>
            <StyledTableCont>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                        fontFamily: "Poppins",
                      }}
                      align="center"
                    >
                      Priority
                    </StyledTableCell>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                        fontFamily: "Poppins",
                      }}
                      align="center"
                    >
                      Reminder 1 (In Hrs)
                    </StyledTableCell>

                    <StyledTableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                        fontFamily: "Poppins",
                      }}
                      align="center"
                    >
                      Reminder 2 (In Hrs)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                        fontFamily: "Poppins",
                      }}
                      align="center"
                    >
                      Reminder 3 (In Hrs)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                        fontFamily: "Poppins",
                      }}
                      align="center"
                    >
                      Escalation 1 (In Hrs)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                        fontFamily: "Poppins",
                      }}
                      align="center"
                    >
                      Escalation 2 (In Hrs)
                    </StyledTableCell>

                    <StyledTableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                        fontFamily: "Poppins",
                      }}
                      align="center"
                    >
                      Escalation 3 (In Hrs)
                    </StyledTableCell>

                    {/* <StyledTableCell
                        style={{
                          fontSize: "14px",
                          fontWeight:"bolder"
                        }}
                        align="center"
                      >
                        Auto Approve After
                      </StyledTableCell> */}
                  </TableRow>
                </TableHead>
                {/* {items?.map((items, index) => ( */}
                <TableBody
                // key={index}
                >
                  <TableRow>
                    {/* 1st column */}
                    <StyledTableCell
                      align="center"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                      }}
                    >
                      {/* {index == 0} */}
                      High
                    </StyledTableCell>

                    {/* 2nd column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.areminder1}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 3rd column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.areminder2}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 4th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.areminder3}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 5th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.aescalation1}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 6th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.aescalation2}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 7th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.aescalation3}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>
                    {/* 8th Column */}
                    {/* <StyledTableCell>
                        <InputCustomFormik 
                        disabled
                        type='number'
                        name={`aautoApproveAfter`}  
                        
                        placeholder={" Enter Value  "}
                        />
                      </StyledTableCell> */}
                  </TableRow>

                  {/* Medium Row */}

                  <TableRow>
                    {/* 1st column */}
                    <StyledTableCell
                      align="center"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                      }}
                    >
                      {/* {index == 1} */}
                      Medium
                    </StyledTableCell>

                    {/* 2nd column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.breminder1}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 3rd column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.breminder2}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 4th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.breminder3}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 5th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.bescalation1}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 6th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.bescalation2}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 7th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.bescalation3}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>
                    {/* 8th Column */}
                    {/* <StyledTableCell>
                      <InputCustomFormik
                        disabled
                        type="number"
                        name={`bautoApproveAfter`}
                        placeholder={" Enter Value  "}
                      />
                    </StyledTableCell> */}
                  </TableRow>

                  {/* Low Row */}

                  <TableRow>
                    {/* 1st column */}
                    <StyledTableCell
                      align="center"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "10px",
                      }}
                    >
                      {/* {index == 2} */}
                      Low
                    </StyledTableCell>

                    {/* 2nd column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.creminder1}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 3rd column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.creminder2}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 4th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.creminder3}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 5th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.cescalation1}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 6th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.cescalation2}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>

                    {/* 7th column */}
                    <StyledTableCell>
                      <LabelCustom
                        labelName={initialValues?.cescalation3}
                        style={{ textAlign: "center" }}
                      />
                    </StyledTableCell>
                    {/* 8th Column */}
                    {/* <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                      type='number'
                      name={`cautoApproveAfter`}  

                      placeholder={" Enter Value  "}
                      />
                      </StyledTableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableCont>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export const typeUom = [
  { label: "Length/Distance", value: "Length/Distance" },
  { label: "Mass/Weight", value: "Mass/Weight" },
  { label: "Volume", value: "Volume" },
  { label: "Time", value: "Time" },
  { label: "Temperature", value: "Temperature" },
  { label: "Electricity", value: "Electricity" },
  { label: "Energy", value: "Energy" },
  { label: "Speed", value: "Speed" },
  { label: "Data/Information", value: "Data/Information" },
  { label: "Currency", value: "Currency" },
  { label: "Area", value: "Area" },
  { label: "Pressure", value: "Pressure" },
  { label: "Force", value: "Force" },
  { label: "Frequency", value: "Frequency" },
  { label: "Light Intensity", value: "Light Intensity" },
];
export const lengthUom = [
  { label: "Meter", value: "Meter" },
  { label: "Kilometer", value: "Kilometer" },
  { label: "Centimeter", value: "Centimeter" },
  { label: "Millimeter", value: "Millimeter" },
  { label: "Inch", value: "Inch" },
  { label: "Foot", value: "Foot" },
  { label: "Yard", value: "Yard" },
  { label: "Mile", value: "Mile" },
];
export const massUom = [
  { label: "Gram", value: "Gram" },
  { label: "Kilogram", value: "Kilogram" },
  { label: "Metric Ton", value: "Metric Ton" },
  { label: "Pound", value: "Pound" },
  { label: "Ounce", value: "Ounce" },
  { label: "Stone", value: "Stone" },
];
export const volumeUom = [
  { label: "Liter", value: "Liter" },
  { label: "Milliliter", value: "Milliliter" },
  { label: "Cubic Meter", value: "Cubic Meter" },
  { label: "Gallon", value: "Gallon" },
  { label: "Quart", value: "Quart" },
  { label: "Pint", value: "Pint" },
  { label: "Fluid Ounce", value: "Fluid Ounce" },
];
export const timeUom = [
  { label: "Second", value: "Second" },
  { label: "Minute", value: "Minute" },
  { label: "Hour", value: "Hour" },
  { label: "Day", value: "Day" },
  { label: "Year", value: "Year" },
];
export const temperatureUom = [
  { label: "Celsius (°C)", value: "Celsius" },
  { label: "Fahrenheit (°F)", value: "Fahrenheit" },
  { label: "Kelvin (K)", value: "Kelvin" },
];
export const electricityUom = [
  { label: "Ampere (A)", value: "Ampere" },
  { label: "Volt (V)", value: "Volt" },
  { label: "Ohm (Ω)", value: "Ohm" },
  { label: "Watt (W)", value: "Watt" },
];
export const energyUom = [
  { label: "Joule (J)", value: "Joule" },
  { label: "Kilowatt-hour (kWh)", value: "Kilowatt-hour" },
  { label: "Calorie (cal)", value: "Calorie" },
  { label: "Electron Volt (eV)", value: "Electron Volt" },
];

export const speedUom = [
  { label: "Meters per Second (m/s)", value: "Meters per Second" },
  { label: "Kilometers per Hour (km/h)", value: "Kilometers per Hour" },
  { label: "Miles per Hour (mph)", value: "Miles per Hour" },
];
export const dataUom = [
  { label: "Byte (B)", value: "Byte" },
  { label: "Kilobyte (KB)", value: "Kilobyte" },
  { label: "Megabyte (MB)", value: "Megabyte" },
  { label: "Gigabyte (GB)", value: "Gigabyte" },
  { label: "Terabyte (TB)", value: "Terabyte" },
];
export const currencyUom = [
  { label: "Rupee (INR)", value: "Rupee" },
  { label: "Dollar (USD)", value: "Dollar" },
  { label: "Euro (EUR)", value: "Euro" },
  // Add more currencies as needed
];
export const areaUom = [
  { label: "Square Meter (m²)", value: "Square Meter" },
  { label: "Square Kilometer (km²)", value: "Square Kilometer" },
  { label: "Acre", value: "Acre" },
  { label: "Hectare", value: "Hectare" },
];
export const pressureUom = [
  { label: "Pascal (Pa)", value: "Pascal" },
  { label: "Bar", value: "Bar" },
  { label: "Atmosphere (atm)", value: "Atmosphere" },
  { label: "Pound per Square Inch (psi)", value: "Pound per Square Inch" },
];

export const forceUom = [
  { label: "Newton (N)", value: "Newton" },
  { label: "Dyne", value: "Dyne" },
  { label: "Pound-force (lbf)", value: "Pound-force" },
];

export const frequencyUom = [{ label: "Hertz (Hz)", value: "Hertz" }];

export const lightIntensityUom = [
  { label: "Candela (cd)", value: "Candela" },
  { label: "Lumen (lm)", value: "Lumen" },
  { label: "Lux (lx)", value: "Lux" },
];

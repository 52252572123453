import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import Nodata from "../../../Assets/Nodata.svg";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../HRMS/Components/DetailsComp/Notifications";
import { Timeline } from "../../HRMS/Components/DetailsComp/TimeLine";
import { Details } from "../../HRMS/Components/DetailsComp/Details";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../Features/badgeUtils";
import { format } from "date-fns";
import ImageRepeater from "./ImageRepeater";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";

const AppraisalCriteriaDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  console.log(state);
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const selectedId = location?.state?.id;
  const criteriaId = location?.state?.criteriaId;
  const departmentId = location?.state?.departmentId;
  const { LoggedInUser } = useSelector((state) => state.auth);

  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState("");

  const togglePopup = (id, type, color) => {
    setSelId(id);
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let result;
      if (selectedId) {
        result = await getRequest(
          `pmsAppraisalCriteria/Details?ID=${selectedId}`
        );
        console.log("appraisalcriteria Detail", result);
      } else {
        result = await getRequest(
          `pmsAppraisalCriteria/Details?ID=${criteriaId}&DepartmentId=${departmentId}`
        );
      }
      setData(result.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Appraisal Criteria Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    const scrollToRight = () => {
      const myArray = new Array(itemCommon + 1).fill(0);
      myArray?.forEach((it, ind) => {
        const element = document.getElementById(`div-${ind}`);
        const elementC = document.getElementById(`div-${ind + "c"}`);
        if (
          element &&
          elementC &&
          element.scrollWidth &&
          elementC.scrollWidth
        ) {
          // element.scrollLeft = element.scrollWidth;
          element.scrollTo({
            left: element.scrollWidth,
            behavior: "smooth",
          });
          elementC.scrollTo({
            left: elementC.scrollWidth,
            behavior: "smooth",
          });
        }
      });
    };

    if (data) {
      setTimeout(scrollToRight, 300);
    }
  }, [data]);

  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Cancel" || type === "Release") {
        await getRequest(`GatePassRequestsController/${type}?id=${id}`);
      } else if (type === "Mark as Closed") {
        await getRequest(
          `GatePassRequestsController/Close?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Approve") {
        await getRequest(`GatePassRequestsController/Approve?Id=${id}`);
      } else if (type === "approve") {
        await getRequest(`GatePassRequestsController/Approve?Id=${id}`);
      } else if (type === "Reject") {
        await postRequest(
          `GatePassRequestsController/Reject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "reject") {
        await postRequest(
          `GatePassRequestsController/Reject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Add out Time ") {
        await getRequest(`GatePassRequestsController/AddOutTime?ID=${id}`);
      } else if (type === "Add in Time ") {
        await getRequest(`GatePassRequestsController/AddInTime?ID=${id}`);
      } else {
        await getRequest(`GatePassRequestsController/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    if (location?.pathname?.toLowerCase()?.includes("appraisalcriteria"))
      getDetails();
  }, [LoggedInUser.id]);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  console.log("selectedId", selectedId);
  let itemCommon = 0;
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Request Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody">
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Details
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Timeline
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                    >
                      Notifications
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {data && (
              <>
                {tabIndex === 0 &&
                  (data != null ? (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column", display: "block" }}
                    >
                      <div className="headingForm"> Request Details</div>
                      <div className="detailInner">
                        <>
                          <Details
                            detailTitle={"Request Number"}
                            Details={data?.pmsAppraisalCriteria?.requestNumber}
                          />
                          <Details
                            detailTitle={"Name"}
                            Details={data?.pmsAppraisalCriteria?.name}
                          />
                          {/* <Details
                            detailTitle={"comments"}
                            Details={data?.pmsAppraisalCriteria?.comments}
                          /> */}
                          {/* <Details
                            detailTitle={"items"}
                            Details={data?.items}
                          /> */}

                          <Details
                            detailTitle={"Created On"}
                            Details={
                              data?.pmsAppraisalCriteria?.createdOn
                                ? format(
                                    new Date(
                                      data?.pmsAppraisalCriteria?.createdOn?.replace(
                                        "T",
                                        " "
                                      )
                                    ),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />

                          <Details
                            detailTitle={"Status"}
                            Details={
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    data?.pmsAppraisalCriteria?.status
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    data?.pmsAppraisalCriteria?.status
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                              >
                                {data?.pmsAppraisalCriteria?.status}
                              </div>
                            }
                          />
                          <Details
                            detailTitle={"Last Updated On"}
                            Details={
                              data?.pmsAppraisalCriteria?.lastUpdatedOn
                                ? format(
                                    new Date(
                                      data?.pmsAppraisalCriteria?.lastUpdatedOn?.replace(
                                        "T",
                                        " "
                                      )
                                    ),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />
                        </>
                      </div>
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div>
                          {data?.pmsAppraisalCriteria?.pmsAppraisalCriteriaItem?.map(
                            (criteriaItem, index) => {
                              if (departmentId) {
                                return criteriaItem?.pmsAppraisalCriteriaDepartment
                                  ?.filter(
                                    (it) => it?.departmentId === departmentId
                                  )
                                  ?.map((departmentItem, ind) => {
                                    itemCommon++;
                                    return (
                                      <div className="DepartmentDesign">
                                        <div
                                          className="detailInner"
                                          style={{ padding: "20px" }}
                                        >
                                          <Details
                                            detailTitle={"Sl.No."}
                                            Details={itemCommon}
                                          />
                                          <Details
                                            detailTitle={"Department"}
                                            Details={
                                              departmentItem?.departmentName
                                            }
                                          />

                                          <Details
                                            detailTitle={"Status"}
                                            Details={
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    getStatusColor(
                                                      departmentItem?.departmentStatus
                                                    )?.split(",")?.[0],
                                                  color: getStatusColor(
                                                    departmentItem?.departmentStatus
                                                  )?.split(",")?.[1],
                                                  cursor: "default",
                                                  padding: "6px",
                                                  borderRadius: "6px",
                                                }}
                                              >
                                                {
                                                  departmentItem?.departmentStatus
                                                }
                                              </div>
                                            }
                                          />
                                          {/* <Details
                                            detailTitle={"Created On"}
                                            Details={
                                              departmentItem?.createdOn
                                                ? format(
                                                    new Date(
                                                      departmentItem?.createdOn?.replace(
                                                        "T",
                                                        " "
                                                      )
                                                    ),
                                                    "dd/MM/yyyy hh:mm a"
                                                  )
                                                : "-"
                                            }
                                          />
                                          <Details
                                            detailTitle={"Last Updated On"}
                                            Details={
                                              departmentItem?.lastUpdatedOn
                                                ? format(
                                                    new Date(
                                                      departmentItem?.lastUpdatedOn?.replace(
                                                        "T",
                                                        " "
                                                      )
                                                    ),
                                                    "dd/MM/yyyy hh:mm a"
                                                  )
                                                : "-"
                                            }
                                          /> */}
                                        </div>
                                        <div style={{ padding: "21px" }}>
                                          <div
                                            className="headingForm"
                                            style={{
                                              borderBottom: "none",
                                              margin: "12px 12px 6px 0px",
                                            }}
                                          >
                                            Appraisal Classification Inputs
                                          </div>
                                          <div
                                            key={itemCommon + "c"}
                                            id={`div-${itemCommon + "c"}`}
                                            // ref={(el) => {
                                            //   console.log("object el", el);
                                            //   divRefs.current[index] = el;
                                            // }}
                                            style={{
                                              width: "100%",
                                              // width: "588px",
                                              overflowX: "auto ",
                                              overflowY: "hidden",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            <div className="table-wrapper PmsClassificationTable">
                                              <table
                                                style={{ width: "max-content" }}
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      className="sticky"
                                                      style={{ width: "92px" }}
                                                    >
                                                      Sl.No.
                                                    </th>
                                                    <th
                                                      style={{ width: "141px" }}
                                                      className="sticky2 "
                                                    >
                                                      Criteria
                                                    </th>
                                                    <th
                                                      className="sticky3"
                                                      style={{ width: "154px" }}
                                                    >
                                                      Weightage (%)
                                                    </th>

                                                    {departmentItem?.reviewedBy?.map(
                                                      (item) => {
                                                        if (
                                                          item === "Creator"
                                                        ) {
                                                          return null;
                                                        } else {
                                                          return (
                                                            <th
                                                              className="normalCell"
                                                              style={{
                                                                width: "130px",
                                                              }}
                                                            >
                                                              {item + " Review"}
                                                            </th>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {criteriaItem?.pmsAppraisalCriteriaClassification
                                                    ?.filter(
                                                      (it) =>
                                                        it?.reviewby ===
                                                          "Creator" &&
                                                        it?.reviewbyDepartmentId ===
                                                          null
                                                    )

                                                    ?.map((item, indexW) => (
                                                      <tr>
                                                        <td
                                                          className="sticky"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {indexW + 1}
                                                        </td>

                                                        <td
                                                          className=" sticky2"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {
                                                            criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )[indexW]?.criteria
                                                          }
                                                        </td>
                                                        <td
                                                          className=" sticky3"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                            (itz) =>
                                                              itz?.reviewby ===
                                                                "Creator" &&
                                                              itz?.reviewbyDepartmentId ===
                                                                null
                                                          )[indexW]?.weightage +
                                                            "%"}
                                                        </td>
                                                        {departmentItem?.reviewedBy?.map(
                                                          (it) => {
                                                            if (
                                                              it === "Creator"
                                                            ) {
                                                              return null;
                                                            } else {
                                                              return (
                                                                <>
                                                                  <td
                                                                    className="normalCell "
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    {criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                                      (itz) =>
                                                                        itz?.reviewby ===
                                                                          it &&
                                                                        (itz?.reviewbyDepartmentId ===
                                                                          departmentItem?.departmentId ||
                                                                          itz?.reviewbyDepartmentId ===
                                                                            null)
                                                                    )[indexW]
                                                                      ?.weightage +
                                                                      "%"}
                                                                  </td>
                                                                </>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                      </tr>
                                                    ))}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{ padding: "21px" }}>
                                          <div
                                            className="headingForm"
                                            style={{
                                              borderBottom: "none",
                                              margin: "12px 12px 6px 0px",
                                            }}
                                          >
                                            Appraisal Slab
                                          </div>
                                          <div
                                            key={itemCommon}
                                            id={`div-${itemCommon}`}
                                            // ref={(el) => {
                                            //   console.log("object el", el);
                                            //   divRefs.current[index] = el;
                                            // }}
                                            style={{
                                              width: "100%",
                                              // width: "588px",
                                              overflowX: "auto ",
                                              overflowY: "hidden",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            <div className="table-wrapper PmsAppraisalSlabTable">
                                              <table
                                                style={{ width: "max-content" }}
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      rowspan="2"
                                                      className="sticky"
                                                      style={{ width: "90px" }}
                                                    >
                                                      Sl.No.
                                                    </th>
                                                    <th
                                                      colspan="2"
                                                      className="sticky2 "
                                                      style={{ width: "280px" }}
                                                    >
                                                      Weightage Range
                                                    </th>
                                                    <th
                                                      rowspan="2"
                                                      className="normalCell"
                                                      style={{ width: "154px" }}
                                                    >
                                                      Appraisal %
                                                    </th>
                                                    <th
                                                      rowspan="2"
                                                      className="normalCell "
                                                    >
                                                      Appraisal Value
                                                    </th>
                                                    {departmentItem?.reviewedBy?.map(
                                                      (item) => {
                                                        if (
                                                          item === "Creator"
                                                        ) {
                                                          return null;
                                                        } else {
                                                          return (
                                                            <th
                                                              className="normalCell "
                                                              colSpan="2"
                                                            >
                                                              {item ===
                                                              "Creator"
                                                                ? "Weightage (%)"
                                                                : item +
                                                                  " Review"}
                                                            </th>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </tr>
                                                  <tr>
                                                    <th
                                                      className="sticky2 "
                                                      style={{
                                                        width: "140px",
                                                      }}
                                                    >
                                                      From
                                                    </th>
                                                    <th
                                                      rowspan="2"
                                                      className="sticky3 "
                                                      style={{
                                                        width: "140px",
                                                      }}
                                                    >
                                                      To
                                                    </th>

                                                    {departmentItem?.reviewedBy?.map(
                                                      (item) => {
                                                        if (item === "Creator")
                                                          return null;
                                                        else
                                                          return (
                                                            <>
                                                              <th
                                                                className="normalCell "
                                                                style={{
                                                                  width:
                                                                    "133px",
                                                                }}
                                                              >
                                                                Appraisal %
                                                              </th>
                                                              <th
                                                                rowspan="2"
                                                                className="normalCell "
                                                                style={{
                                                                  width:
                                                                    "144px",
                                                                }}
                                                              >
                                                                Appraisal Value
                                                              </th>
                                                            </>
                                                          );
                                                      }
                                                    )}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {criteriaItem?.pmsAppraisalCriteriaSlab
                                                    ?.filter(
                                                      (it) =>
                                                        it?.reviewby ===
                                                          "Creator" &&
                                                        it?.reviewbyDepartmentId ===
                                                          null
                                                    )

                                                    ?.map((item, indexW) => (
                                                      <tr>
                                                        <td
                                                          className="sticky"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {indexW + 1}
                                                        </td>
                                                        <td
                                                          className="sticky2"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaSlab
                                                            ?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )
                                                            ?.sort(
                                                              (a, b) =>
                                                                a.weightFrom -
                                                                b.weightFrom
                                                            )[indexW]
                                                            ?.weightFrom + "%"}
                                                        </td>
                                                        <td
                                                          className="sticky3"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaSlab
                                                            ?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )
                                                            ?.sort(
                                                              (a, b) =>
                                                                a.weightFrom -
                                                                b.weightFrom
                                                            )[indexW]
                                                            ?.weightTo + "%"}
                                                        </td>
                                                        <td
                                                          className="normalCell"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaSlab
                                                            ?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )
                                                            ?.sort(
                                                              (a, b) =>
                                                                a.weightFrom -
                                                                b.weightFrom
                                                            )[indexW]
                                                            ?.appraisalPercentage
                                                            ? criteriaItem?.pmsAppraisalCriteriaSlab
                                                                ?.filter(
                                                                  (itz) =>
                                                                    itz?.reviewby ===
                                                                      "Creator" &&
                                                                    itz?.reviewbyDepartmentId ===
                                                                      null
                                                                )
                                                                ?.sort(
                                                                  (a, b) =>
                                                                    a.weightFrom -
                                                                    b.weightFrom
                                                                )[indexW]
                                                                ?.appraisalPercentage +
                                                              "%"
                                                            : "NA"}
                                                        </td>
                                                        <td
                                                          className="normalCell"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaSlab
                                                            ?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )
                                                            ?.sort(
                                                              (a, b) =>
                                                                a.weightFrom -
                                                                b.weightFrom
                                                            )[indexW]
                                                            ?.appraisalValue ??
                                                            "NA"}
                                                        </td>

                                                        {departmentItem?.reviewedBy?.map(
                                                          (it) => {
                                                            if (
                                                              it === "Creator"
                                                            ) {
                                                              return null;
                                                            } else {
                                                              return (
                                                                <>
                                                                  <td
                                                                    className="normalCell "
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    {criteriaItem?.pmsAppraisalCriteriaSlab
                                                                      ?.filter(
                                                                        (itz) =>
                                                                          itz?.reviewby ===
                                                                            it &&
                                                                          (itz?.reviewbyDepartmentId ===
                                                                            departmentItem?.departmentId ||
                                                                            itz?.reviewbyDepartmentId ===
                                                                              null)
                                                                      )
                                                                      ?.sort(
                                                                        (
                                                                          a,
                                                                          b
                                                                        ) =>
                                                                          a.weightFrom -
                                                                          b.weightFrom
                                                                      )[indexW]
                                                                      ?.appraisalPercentage
                                                                      ? criteriaItem?.pmsAppraisalCriteriaSlab?.filter(
                                                                          (
                                                                            itz
                                                                          ) =>
                                                                            itz?.reviewby ===
                                                                              it &&
                                                                            (itz?.reviewbyDepartmentId ===
                                                                              departmentItem?.departmentId ||
                                                                              itz?.reviewbyDepartmentId ===
                                                                                null)
                                                                        )[
                                                                          indexW
                                                                        ]
                                                                          ?.appraisalPercentage +
                                                                        "%"
                                                                      : "NA"}
                                                                  </td>
                                                                  <td
                                                                    className="normalCell "
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    {criteriaItem?.pmsAppraisalCriteriaSlab
                                                                      ?.filter(
                                                                        (itz) =>
                                                                          itz?.reviewby ===
                                                                            it &&
                                                                          (itz?.reviewbyDepartmentId ===
                                                                            departmentItem?.departmentId ||
                                                                            itz?.reviewbyDepartmentId ===
                                                                              null)
                                                                      )
                                                                      ?.sort(
                                                                        (
                                                                          a,
                                                                          b
                                                                        ) =>
                                                                          a.weightFrom -
                                                                          b.weightFrom
                                                                      )[indexW]
                                                                      ?.appraisalValue ??
                                                                      "NA"}
                                                                  </td>
                                                                </>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                      </tr>
                                                    ))}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{ padding: "21px" }}>
                                          {data?.pmsAppraisalCriteria?.pmsAppraisalCriteriaComment
                                            ?.filter(
                                              (it) =>
                                                it?.departmentId ===
                                                  departmentId ||
                                                it?.departmentId === null
                                            )
                                            ?.sort(
                                              (a, b) =>
                                                a.departmentId - b.departmentId
                                            )
                                            ?.map((item) => (
                                              <>
                                                <div
                                                  className="headingForm"
                                                  style={{
                                                    borderBottom: "none",
                                                    // margin: " 20px 12px 12px",
                                                  }}
                                                >
                                                  {item?.degination}
                                                </div>
                                                <div
                                                  style={{
                                                    borderBottom: "none",
                                                    display: "flex",

                                                    // margin: " 20px 12px 12px",
                                                  }}
                                                >
                                                  <LabelCustom
                                                    fontWeight="500"
                                                    labelName={"Comment"}
                                                  />
                                                  <LabelCustom
                                                    labelName={" : "}
                                                  />
                                                  <LabelCustom
                                                    style={{ flex: "1" }}
                                                    labelName={`${item?.comment}`}
                                                  />
                                                </div>
                                              </>
                                            ))}
                                        </div>
                                      </div>
                                    );
                                  });
                              } else {
                                return criteriaItem?.pmsAppraisalCriteriaDepartment?.map(
                                  (departmentItem, ind) => {
                                    itemCommon++;
                                    return (
                                      <div className="DepartmentDesign">
                                        <div
                                          className="detailInner"
                                          style={{ padding: "20px" }}
                                        >
                                          <Details
                                            detailTitle={"Sl.No."}
                                            Details={itemCommon}
                                          />
                                          <Details
                                            detailTitle={"Department"}
                                            Details={
                                              departmentItem?.departmentName
                                            }
                                          />

                                          <Details
                                            detailTitle={"Status"}
                                            Details={
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    getStatusColor(
                                                      departmentItem?.departmentStatus
                                                    )?.split(",")?.[0],
                                                  color: getStatusColor(
                                                    departmentItem?.departmentStatus
                                                  )?.split(",")?.[1],
                                                  cursor: "default",
                                                  padding: "6px",
                                                  borderRadius: "6px",
                                                }}
                                              >
                                                {
                                                  departmentItem?.departmentStatus
                                                }
                                              </div>
                                            }
                                          />
                                          {/* <Details
                                            detailTitle={"Created On"}
                                            Details={
                                              departmentItem?.createdOn
                                                ? format(
                                                    new Date(
                                                      departmentItem?.createdOn?.replace(
                                                        "T",
                                                        " "
                                                      )
                                                    ),
                                                    "dd/MM/yyyy hh:mm a"
                                                  )
                                                : "-"
                                            }
                                          />
                                          <Details
                                            detailTitle={"Last Updated On"}
                                            Details={
                                              departmentItem?.lastUpdatedOn
                                                ? format(
                                                    new Date(
                                                      departmentItem?.lastUpdatedOn?.replace(
                                                        "T",
                                                        " "
                                                      )
                                                    ),
                                                    "dd/MM/yyyy hh:mm a"
                                                  )
                                                : "-"
                                            }
                                          /> */}
                                        </div>
                                        <div style={{ padding: "21px" }}>
                                          <div
                                            className="headingForm"
                                            style={{
                                              borderBottom: "none",
                                              margin: "12px 12px 6px 0px",
                                            }}
                                          >
                                            Appraisal Classification Inputs
                                          </div>
                                          <div
                                            key={itemCommon + "c"}
                                            id={`div-${itemCommon + "c"}`}
                                            // ref={(el) => {
                                            //   console.log("object el", el);
                                            //   divRefs.current[index] = el;
                                            // }}
                                            style={{
                                              width: "100%",
                                              // width: "588px",
                                              overflowX: "auto ",
                                              overflowY: "hidden",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            <div className="table-wrapper PmsClassificationTable">
                                              <table
                                                style={{ width: "max-content" }}
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      className="sticky"
                                                      style={{ width: "92px" }}
                                                    >
                                                      Sl.No.
                                                    </th>
                                                    <th
                                                      style={{ width: "141px" }}
                                                      className="sticky2 "
                                                    >
                                                      Criteria
                                                    </th>
                                                    <th
                                                      className="sticky3"
                                                      style={{ width: "154px" }}
                                                    >
                                                      Weightage (%)
                                                    </th>

                                                    {departmentItem?.reviewedBy?.map(
                                                      (item) => {
                                                        if (
                                                          item === "Creator"
                                                        ) {
                                                          return null;
                                                        } else {
                                                          return (
                                                            <th
                                                              className="normalCell"
                                                              style={{
                                                                width: "130px",
                                                              }}
                                                            >
                                                              {item + " Review"}
                                                            </th>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {criteriaItem?.pmsAppraisalCriteriaClassification
                                                    ?.filter(
                                                      (it) =>
                                                        it?.reviewby ===
                                                          "Creator" &&
                                                        it?.reviewbyDepartmentId ===
                                                          null
                                                    )

                                                    ?.map((item, indexW) => (
                                                      <tr>
                                                        <td
                                                          className="sticky"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {indexW + 1}
                                                        </td>

                                                        <td
                                                          className=" sticky2"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {
                                                            criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )[indexW]?.criteria
                                                          }
                                                        </td>
                                                        <td
                                                          className=" sticky3"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                            (itz) =>
                                                              itz?.reviewby ===
                                                                "Creator" &&
                                                              itz?.reviewbyDepartmentId ===
                                                                null
                                                          )[indexW]?.weightage +
                                                            "%"}
                                                        </td>
                                                        {departmentItem?.reviewedBy?.map(
                                                          (it) => {
                                                            if (
                                                              it === "Creator"
                                                            ) {
                                                              return null;
                                                            } else {
                                                              return (
                                                                <>
                                                                  <td
                                                                    className="normalCell "
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    {criteriaItem?.pmsAppraisalCriteriaClassification?.filter(
                                                                      (itz) =>
                                                                        itz?.reviewby ===
                                                                          it &&
                                                                        (itz?.reviewbyDepartmentId ===
                                                                          departmentItem?.departmentId ||
                                                                          itz?.reviewbyDepartmentId ===
                                                                            null)
                                                                    )[indexW]
                                                                      ?.weightage +
                                                                      "%"}
                                                                  </td>
                                                                </>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                      </tr>
                                                    ))}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{ padding: "21px" }}>
                                          <div
                                            className="headingForm"
                                            style={{
                                              borderBottom: "none",
                                              margin: "12px 12px 6px 0px",
                                            }}
                                          >
                                            Appraisal Slab
                                          </div>
                                          <div
                                            key={itemCommon}
                                            id={`div-${itemCommon}`}
                                            // ref={(el) => {
                                            //   console.log("object el", el);
                                            //   divRefs.current[index] = el;
                                            // }}
                                            style={{
                                              width: "100%",
                                              // width: "588px",
                                              overflowX: "auto ",
                                              overflowY: "hidden",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            <div className="table-wrapper PmsAppraisalSlabTable">
                                              <table
                                                style={{ width: "max-content" }}
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      rowspan="2"
                                                      className="sticky"
                                                      style={{ width: "90px" }}
                                                    >
                                                      Sl.No.
                                                    </th>
                                                    <th
                                                      colspan="2"
                                                      className="sticky2 "
                                                      style={{ width: "280px" }}
                                                    >
                                                      Weightage Range
                                                    </th>
                                                    <th
                                                      rowspan="2"
                                                      className="normalCell"
                                                      style={{ width: "154px" }}
                                                    >
                                                      Appraisal %
                                                    </th>
                                                    <th
                                                      rowspan="2"
                                                      className="normalCell "
                                                    >
                                                      Appraisal Value
                                                    </th>
                                                    {departmentItem?.reviewedBy?.map(
                                                      (item) => {
                                                        if (
                                                          item === "Creator"
                                                        ) {
                                                          return null;
                                                        } else {
                                                          return (
                                                            <th
                                                              className="normalCell "
                                                              colSpan="2"
                                                            >
                                                              {item ===
                                                              "Creator"
                                                                ? "Weightage (%)"
                                                                : item +
                                                                  " Review"}
                                                            </th>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </tr>
                                                  <tr>
                                                    <th
                                                      className="sticky2 "
                                                      style={{
                                                        width: "140px",
                                                      }}
                                                    >
                                                      From
                                                    </th>
                                                    <th
                                                      rowspan="2"
                                                      className="sticky3 "
                                                      style={{
                                                        width: "140px",
                                                      }}
                                                    >
                                                      To
                                                    </th>

                                                    {departmentItem?.reviewedBy?.map(
                                                      (item) => {
                                                        if (item === "Creator")
                                                          return null;
                                                        else
                                                          return (
                                                            <>
                                                              <th
                                                                className="normalCell "
                                                                style={{
                                                                  width:
                                                                    "133px",
                                                                }}
                                                              >
                                                                Appraisal %
                                                              </th>
                                                              <th
                                                                rowspan="2"
                                                                className="normalCell "
                                                                style={{
                                                                  width:
                                                                    "144px",
                                                                }}
                                                              >
                                                                Appraisal Value
                                                              </th>
                                                            </>
                                                          );
                                                      }
                                                    )}
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {criteriaItem?.pmsAppraisalCriteriaSlab
                                                    ?.filter(
                                                      (it) =>
                                                        it?.reviewby ===
                                                          "Creator" &&
                                                        it?.reviewbyDepartmentId ===
                                                          null
                                                    )

                                                    ?.map((item, indexW) => (
                                                      <tr>
                                                        <td
                                                          className="sticky"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {indexW + 1}
                                                        </td>
                                                        <td
                                                          className="sticky2"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaSlab
                                                            ?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )
                                                            ?.sort(
                                                              (a, b) =>
                                                                a.weightFrom -
                                                                b.weightFrom
                                                            )[indexW]
                                                            ?.weightFrom + "%"}
                                                        </td>
                                                        <td
                                                          className="sticky3"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaSlab
                                                            ?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )
                                                            ?.sort(
                                                              (a, b) =>
                                                                a.weightFrom -
                                                                b.weightFrom
                                                            )[indexW]
                                                            ?.weightTo + "%"}
                                                        </td>
                                                        <td
                                                          className="normalCell"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaSlab
                                                            ?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )
                                                            ?.sort(
                                                              (a, b) =>
                                                                a.weightFrom -
                                                                b.weightFrom
                                                            )[indexW]
                                                            ?.appraisalPercentage
                                                            ? criteriaItem?.pmsAppraisalCriteriaSlab
                                                                ?.filter(
                                                                  (itz) =>
                                                                    itz?.reviewby ===
                                                                      "Creator" &&
                                                                    itz?.reviewbyDepartmentId ===
                                                                      null
                                                                )
                                                                ?.sort(
                                                                  (a, b) =>
                                                                    a.weightFrom -
                                                                    b.weightFrom
                                                                )[indexW]
                                                                ?.appraisalPercentage +
                                                              "%"
                                                            : "NA"}
                                                        </td>
                                                        <td
                                                          className="normalCell"
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {criteriaItem?.pmsAppraisalCriteriaSlab
                                                            ?.filter(
                                                              (itz) =>
                                                                itz?.reviewby ===
                                                                  "Creator" &&
                                                                itz?.reviewbyDepartmentId ===
                                                                  null
                                                            )
                                                            ?.sort(
                                                              (a, b) =>
                                                                a.weightFrom -
                                                                b.weightFrom
                                                            )[indexW]
                                                            ?.appraisalValue ??
                                                            "NA"}
                                                        </td>

                                                        {departmentItem?.reviewedBy?.map(
                                                          (it) => {
                                                            if (
                                                              it === "Creator"
                                                            ) {
                                                              return null;
                                                            } else {
                                                              return (
                                                                <>
                                                                  <td
                                                                    className="normalCell "
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    {criteriaItem?.pmsAppraisalCriteriaSlab
                                                                      ?.filter(
                                                                        (itz) =>
                                                                          itz?.reviewby ===
                                                                            it &&
                                                                          (itz?.reviewbyDepartmentId ===
                                                                            departmentItem?.departmentId ||
                                                                            itz?.reviewbyDepartmentId ===
                                                                              null)
                                                                      )
                                                                      ?.sort(
                                                                        (
                                                                          a,
                                                                          b
                                                                        ) =>
                                                                          a.weightFrom -
                                                                          b.weightFrom
                                                                      )[indexW]
                                                                      ?.appraisalPercentage
                                                                      ? criteriaItem?.pmsAppraisalCriteriaSlab?.filter(
                                                                          (
                                                                            itz
                                                                          ) =>
                                                                            itz?.reviewby ===
                                                                              it &&
                                                                            (itz?.reviewbyDepartmentId ===
                                                                              departmentItem?.departmentId ||
                                                                              itz?.reviewbyDepartmentId ===
                                                                                null)
                                                                        )[
                                                                          indexW
                                                                        ]
                                                                          ?.appraisalPercentage +
                                                                        "%"
                                                                      : "NA"}
                                                                  </td>
                                                                  <td
                                                                    className="normalCell "
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    {criteriaItem?.pmsAppraisalCriteriaSlab
                                                                      ?.filter(
                                                                        (itz) =>
                                                                          itz?.reviewby ===
                                                                            it &&
                                                                          (itz?.reviewbyDepartmentId ===
                                                                            departmentItem?.departmentId ||
                                                                            itz?.reviewbyDepartmentId ===
                                                                              null)
                                                                      )
                                                                      ?.sort(
                                                                        (
                                                                          a,
                                                                          b
                                                                        ) =>
                                                                          a.weightFrom -
                                                                          b.weightFrom
                                                                      )[indexW]
                                                                      ?.appraisalValue ??
                                                                      "NA"}
                                                                  </td>
                                                                </>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                      </tr>
                                                    ))}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{ padding: "21px" }}>
                                          {data?.pmsAppraisalCriteria?.pmsAppraisalCriteriaComment
                                            ?.filter(
                                              (it) =>
                                                it?.departmentId ===
                                                  departmentItem?.departmentId ||
                                                it?.departmentId === null
                                            )
                                            ?.sort(
                                              (a, b) =>
                                                a.departmentId - b.departmentId
                                            )
                                            ?.map((item) => (
                                              <>
                                                <div
                                                  className="headingForm"
                                                  style={{
                                                    borderBottom: "none",
                                                    // margin: " 20px 12px 12px",
                                                  }}
                                                >
                                                  {item?.degination}
                                                </div>
                                                <div
                                                  style={{
                                                    borderBottom: "none",
                                                    display: "flex",

                                                    // margin: " 20px 12px 12px",
                                                  }}
                                                >
                                                  <LabelCustom
                                                    fontWeight="500"
                                                    labelName={"Comment"}
                                                  />
                                                  <LabelCustom
                                                    labelName={" : "}
                                                  />
                                                  <LabelCustom
                                                    style={{ flex: "1" }}
                                                    labelName={`${item?.comment}`}
                                                  />
                                                </div>
                                              </>
                                            ))}
                                        </div>
                                      </div>
                                    );
                                  }
                                );
                              }
                            }
                          )}
                        </div>
                      </div>

                      {data?.summary?.actionList?.length ? (
                        <div
                          className="createFootDetails"
                          style={{ background: "white !important" }}
                        >
                          {data?.summary?.actionList?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                  item.type !== "Release" &&
                                  item.type !== "Cancel"
                                    ? togglePopup(
                                        item?.id,
                                        item?.type,
                                        item?.color
                                      )
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {isOpen && (
                        <Popup
                          popupHeading={typeAction}
                          content={
                            <>
                              <Remark
                                data={PostApRejHol}
                                type={typeAction}
                                id={selId}
                                color={colorAction}
                                handleClose={togglePopup}
                              />
                            </>
                          }
                          handleClose={togglePopup}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="noDatastyle">
                      <Loader />
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Records"}</h4>
                    </div>
                  ))}
                {tabIndex === 1 && (
                  <>
                    <div className="tabularMasterDetailsPageTabBodyContainer">
                      <div style={{ display: "flex", width: "100%" }}>
                        <Timeline
                          id={selectedId ?? criteriaId}
                          module={"Appraisal Criteria"}
                          requestNumber={
                            data?.pmsAppraisalCriteria?.requestNumber
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <div
                      className="tabularMasterDetailsPageTabBodyContainer"
                      style={{ flexGrow: "initial" }}
                    >
                      <Notifications
                        id={selectedId ?? criteriaId}
                        module={"Appraisal Criteria"}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { AppraisalCriteriaDetailsPage };

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Popover } from "@mui/material";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { useState } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { useDeferredValue } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
const noOfRecords = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
function PayrollApprovalDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const returnPage = () => {
    navigate(-1);
  };
  const [Deduction, setDeduction] = useState(null);
  const [typeAction, setTypeAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  // const [selId, setSelId] = useState(false);
  const togglePopup = (type, id) => {
    sendPayrollRun();
    setTypeAction(type);
    setIsOpen((prev) => !prev);
  };
  const [employeeData, setEmployeeData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState(null);
  const [employeeList, setEmployeeList] = useState(null);

  const [count, setCount] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [searchParam, setSearchParam] = useState(null);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      isExport: false,
    },
  });
  useEffect(() => {
    document.title = `PeopleSol - Prpare Payroll  `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };

  const { state, pathname } = useLocation();

  const editId = state?.id;

  useEffect(() => {
    if (editId) {
      getFormDetails();
    }
  }, []);
  useEffect(() => {
    if (detailsform?.monthPayout) {
      getEmployeeList(detailsform);
    }
  }, [reqData]);
  const getEmployeeList = async (detail) => {
    const employeeResult = await postRequest(
      `PreparePayroll/EmployeeSummaryList?Month=${detail?.monthPayout}&PayAreaId=${detail?.payAreaId}`,
      reqData
    );
    if (employeeResult) {
      setEmployeeList(employeeResult?.data?.data);
      setCount(employeeResult.data.summary.allCount);
      calculateTotalPages(employeeResult.data.summary.allCount);
    }
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PreparePayroll/Details/${editId}`);
      if (result) {
        setDeduction(result?.data?.data?.summary);
        setDetailsform(result?.data?.data);

        getEmployeeList(result?.data?.data);
      } else {
        dispatch(
          showToast({
            text: "Some error occured. Please try again later.",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const sendPayrollRun = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PreparePayroll/RunPayroll/${editId}`);
      if (result) {
        setEmployeeData(result?.data);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const DeductionComponent = ({ deduction, iconColor }) => {
    let className = "";
    let content = null;

    if (deduction.type?.slice(0, 4) === "EPF Employer Contribution") {
      className = "insuranceSummary";
      content = (
        <>
          <div
            className="insuranceSummary"
            style={{ background: "transparent" }}
          >
            <p style={{
              fontWeight: "500", lineHeight: "14px",
              color: iconColor
            }}>
              {deduction.type?.slice(0, 4)}
            </p>
            <h5 style={{ color: "var(--primary-text)" }}>
              ₹{deduction.amount}
            </h5>
          </div>
        </>
      );
    } else if (deduction.type?.slice(0, 4) === "ESI Employee Contribution") {
      className = "esicSummary";
      content = (
        <>
          <div className="esicSummary" style={{ background: "transparent" }}>
            <p style={{
              fontWeight: "500", lineHeight: "14px",
              color: iconColor
            }}>
              {deduction.type?.slice(0, 4)}
            </p>
            <h5 style={{ color: "var(--primary-text)" }}>
              ₹{deduction.amount}
            </h5>
          </div>
        </>
      );
    } else if (deduction.type?.slice(0, 4) === "ESI Employer Contribution") {
      content = (
        <>
          <div className="esicSummary" style={{ background: "transparent" }}>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
                lineHeight: "14px",
                color: iconColor

              }}
            >
              {deduction.type?.slice(0, 4)}
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 500,
                color: "var(--primary-text)",
              }}
            >
              ₹{deduction.amount}
            </p>
          </div>
        </>
      );
    } else if (deduction.type?.slice(0, 4) === "TDS") {
      content = (
        <>
          <div className="tdsSummary" style={{ background: "transparent" }}>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
                lineHeight: "14px",
                color: iconColor

              }}
            >
              {deduction.type?.slice(0, 4)}
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 500,
                color: "var(--primary-text)",
              }}
            >
              ₹{deduction.amount}
            </p>
          </div>
        </>
      );
    } else if (deduction.type?.slice(0, 4) === "Gratuity") {
      content = (
        <>
          <div
            className="gratuitySummary"
            style={{ background: "transparent" }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
                lineHeight: "14px",
                color: iconColor

              }}
            >
              {deduction.type?.slice(0, 4)}
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 500,
                color: "var(--primary-text)",
              }}
            >
              ₹{deduction.amount}
            </p>
          </div>
        </>
      );
    } else if (deduction.type?.slice(0, 4) === "Insurance") {
      content = (
        <>
          <div
            className="insuranceSummary"
            style={{ background: "transparent" }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
                lineHeight: "14px",
                color: iconColor

              }}
            >
              {deduction.type?.slice(0, 4)}
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 500,
                color: "var(--primary-text)",
              }}
            >
              ₹{deduction.amount}
            </p>
          </div>
        </>
      );
    } else if (deduction.type?.slice(0, 4) === "Loan and Advance") {
      className = "loanSummary";
      content = (
        <>
          <div
            className="tdsSummary"
            style={{ backgroundColor: "transparent" }}
          >
            <div>
              <p style={{
                fontWeight: "500", lineHeight: "14px",
                color: iconColor
              }}>
                {deduction.type?.slice(0, 4)}
              </p>
              <h5 style={{ color: "var(--primary-text)" }}>
                ₹{deduction.amount}
              </h5>
            </div>
          </div>
        </>
      );
    } else {
      className = "tdsSummary";
      content = (
        <>
          <div className="tdsSummary" style={{ background: "transparent" }}>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
                lineHeight: "14px",
                color: iconColor
              }}
            >
              {deduction.type?.slice(0, 4)}
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 500,
                color: "var(--primary-text)",
              }}
            >
              ₹{deduction.amount}
            </p>
          </div>
        </>
      );
    }

    return (
      <div>
        <svg
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
            stroke={iconColor}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
            stroke={iconColor}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {content}
      </div>
    );
  };
  const DeductionsList = ({ deductions }) => {
    const iconColor = ["#8A88ED", "#5bc4bf", "#da5db6", "#f7941d", "#77a2c7", "#a0a0cb", "#8A88ED", "#5bc4bf", "#da5db6", "#f7941d", "#77a2c7", "#a0a0cb"];
    return (
      <>
        {deductions?.map((deduction, index) => (
          <DeductionComponent key={index} iconColor={index < iconColor.length ? iconColor[index] : "#8A88ED"} deduction={deduction} />
        ))}
      </>
    );
  };
  if (loading) return <Loader />;
  else
    return (
      <div className="moduleMainContainer">
        <div className="tableHeaderContainer" style={{ padding: "0 12px" }}>
          <IconButton onClick={returnPage}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.368615 3.35738C0.173352 3.55265 0.173352 3.86923 0.368615 4.06449L3.5506 7.24647C3.74586 7.44173 4.06244 7.44173 4.2577 7.24647C4.45296 7.05121 4.45296 6.73463 4.2577 6.53936L1.42927 3.71094L4.2577 0.88251C4.45296 0.687248 4.45296 0.370666 4.2577 0.175404C4.06244 -0.0198586 3.74586 -0.0198586 3.5506 0.175404L0.368615 3.35738ZM18.749 4.21094C19.0252 4.21094 19.249 3.98708 19.249 3.71094C19.249 3.4348 19.0252 3.21094 18.749 3.21094V4.21094ZM0.722168 4.21094H18.749V3.21094H0.722168V4.21094Z"
                fill="#1296B0"
              />
            </svg>
          </IconButton>
          <h6>Ongoing  Payroll , {detailsform?.monthPayout}</h6>
          {/* {detailsform?.status === "Pending" ? (
            <button
              className="button primaryButton"
              style={{ marginLeft: "auto" }}
              onClick={() => togglePopup("Run Payroll", editId)}
            >
              Approve
            </button>
          ) : detailsform?.status === "Completed" ? (
            <button
              className="button primaryButton"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                navigate("/payroll/salaryslip/details", {
                  state: { id: editId },
                });
              }}
            >
              Salary Slip
            </button>
          ) : (
            ""
          )} */}
        </div>

        <div className="preparePayrollMainContainer">
          <div
            className="homeDashboardContainer"
            style={{
              padding: "15px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "30%",
                gap: "12px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div
                  className="horizontalLine"
                  style={{
                    backgroundColor: "#B69FB3",
                    width: "20%",
                    marginBottom: "6px",
                  }}
                />
                <h4>{detailsform?.payArea}</h4>
              </div>
              <div>
                <p
                  style={{ fontSize: "13px" }}
                >{`Payout ${detailsform?.monthPayout}`}</p>
                <h2 style={{ color: "#B69FB3" }}>{detailsform?.amount}</h2>
              </div>
              <div>
                <p style={{ fontSize: "13px" }}>No. of Employee</p>
                <h2 style={{ color: "#5BC4BF" }}>
                  {detailsform?.noOfEmployees}
                </h2>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                overflow: "auto",
                width: "70%",
                justifyContent: "space-around",
                alignItems: "flex-start",
                paddingLeft: "24px",
              }}
            >
              <DeductionsList deductions={Deduction} />

              {/* <div>
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="tdsSummary" style={{backgroundColor:'transparent'}}>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>TDS</p>
                  <h5 style={{ color: "var(--primary-text)" }}>2,00,0000</h5>
                </div>
              </div> */}
              {/* <div>
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="esicSummary" style={{backgroundColor:'transparent'}}>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>ESIC</p>
                  <h5 style={{ color: "var(--primary-text)" }}>1500000</h5>
                </div>
              </div>
              <div>
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="insuranceSummary" style={{backgroundColor:'transparent'}}>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>
                    Insurance
                  </p>
                  <h5 style={{ color: "var(--primary-text)" }}>1,00,00000</h5>
                </div>
              </div>
              <div>
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>EPF</p>
                  <h5 style={{ color: "var(--primary-text)" }}>300000</h5>
                </div>
              </div>
              <div className="epfSummary" style={{backgroundColor:'transparent'}}>
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>
                    Gratuity
                  </p>
                  <h5 style={{ color: "var(--primary-text)" }}>1500</h5>
                </div>
              </div>
              <div className="gratuitySummary" style={{backgroundColor:'transparent'}}>
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="loanSummary" style={{backgroundColor:'transparent'}}>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>Loan</p>
                  <h5 style={{ color: "var(--primary-text)" }}>50,00,0000</h5>
                </div>
              </div> */}
            </div>
          </div>

          <div className="preparePayrollTableContainer">
            <table className="attendance-table" style={{ marginTop: '-6px' }}>
              <colgroup>
                <col />
                <col style={{ width: "100%" }} />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr className="tableHeaderRow" style={{ zIndex: "99" }}>
                  {/* <th>Actions</th> */}
                  <th style={{ textAlign: "start", fontWeight: "500" }}>Employee Name</th>
                  <th style={{ fontWeight: "500" }}>Account Number</th>
                  <th style={{ fontWeight: "500" }}>Bank Name</th>
                  <th style={{ fontWeight: "500" }}>Pay Days</th>
                  <th style={{ fontWeight: "500" }}>Total Earning</th>
                  <th style={{ fontWeight: "500" }}>Total Deduction</th>
                  <th style={{ fontWeight: "500" }}>Total Reimbursement</th>
                  <th style={{ fontWeight: "500" }}>Payout Month</th>
                  <th style={{ fontWeight: "500" }}>Total Net Payable</th>
                </tr>
              </thead>
              <tbody>
                {employeeList?.map((item) => (
                  <tr key={item.item.id}>
                    {/* <td style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={() =>
                          salaryStructureClickHandler(
                            detailsform?.payAreaId,
                            item.item.id
                          )
                        }
                      >
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.15"
                            d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
                            fill="#0DB53C"
                          />
                          <line
                            x1="14.7295"
                            y1="7.43701"
                            x2="7.72949"
                            y2="7.43701"
                            stroke="#0DB53C"
                            strokeLinecap="round"
                          />
                          <line
                            x1="5.72949"
                            y1="7.43701"
                            x2="5.61462"
                            y2="7.43701"
                            stroke="#0DB53C"
                            strokeLinecap="round"
                          />
                          <line
                            x1="14.7295"
                            y1="11.3437"
                            x2="7.72949"
                            y2="11.3437"
                            stroke="#0DB53C"
                            strokeLinecap="round"
                          />
                          <line
                            x1="5.72949"
                            y1="11.3437"
                            x2="5.61462"
                            y2="11.3438"
                            stroke="#0DB53C"
                            strokeLinecap="round"
                          />
                          <line
                            x1="14.7295"
                            y1="15.2505"
                            x2="7.72949"
                            y2="15.2505"
                            stroke="#0DB53C"
                            strokeLinecap="round"
                          />
                          <line
                            x1="5.72949"
                            y1="15.2505"
                            x2="5.61462"
                            y2="15.2505"
                            stroke="#0DB53C"
                            strokeLinecap="round"
                          />
                        </svg>
                      </IconButton>
                      <IconButton
                        onClick={(event) =>
                          handleMoreClick(event, item.item.id)
                        }
                        title="more"
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 3 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="1.52099"
                            cy="2.0957"
                            r="1.22363"
                            fill="#D9D9D9"
                          />
                          <circle
                            cx="1.52099"
                            cy="7.87207"
                            r="1.22363"
                            fill="#D9D9D9"
                          />
                          <circle
                            cx="1.52099"
                            cy="13.6484"
                            r="1.22363"
                            fill="#D9D9D9"
                          />
                        </svg>
                      </IconButton>
                    </td> */}
                    <td style={{ textAlign: "start", cursor: "pointer" }}>
                      {item.item.employee}
                    </td>
                    <td>{item.item.accountNumber ?? "-"}</td>
                    <td>{item.item.bankName ?? "-"}</td>
                    <td>{item.item.payDays ?? "-"}</td>
                    <td>{item.item.totalEarning ?? "-"}</td>
                    <td>{item.item.totalDeduction ?? "-"}</td>
                    <td>{item.item.totalReimbursement ?? "-"}</td>
                    <td>{item.item.month ?? "-"}</td>
                    <td>{item.item.totalNetPayable ?? "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="horizontalLine"></div>
            <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords}
                dropdownPosition="top"
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
          </div>
        </div>
        {/* Popover to display additional fields */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        ></Popover>

      </div>
    );
}

export default PayrollApprovalDetails;

import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { ErrorMessage, FieldArray } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import "../Categories.css";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import axios from "axios";
import { FileUploadFormikLD } from "../../../../Components/FormComponent/FileUploadFormikLD";
import { useRef } from "react";
import { InputCustomFormikLD } from "../../../../Components/FormComponent/InputCustomFormikLD";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { SelectFormLD } from "../../../../Components/FormComponent/SelectFormLD";
import ReactPlayer from "react-player";
import { confirmAlert } from "react-confirm-alert";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { SpinningCircleLoader } from "react-loaders-kit";
import { format } from "date-fns";
import { useLocation } from "react-router";
import { TextError } from "../../../../Components/FormComponent/TextError";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";

export const CreateChapterComp = ({
  edit,
  formValues,
  changeButton,
  setchangeButton,
  setVideoFileData,
  videoFileData,
  detailsform,
  messaged,
}) => {
  const dispatch = useDispatch();
  const [questionSetList, setQuestionSetList] = useState([]);
  const [bandList, setBandList] = useState([]);
  const [durationfullvideo, setDurationfullvideo] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(null);
  const [afterDelete, setAfterDelete] = useState(false);
  const [wrongFormat, setWrongFormat] = useState(false);

  const myRef = useRef(null);
  const location = useLocation();

  const videoOptions = [
    { label: "Video", value: "video" },
    { label: "URL", value: "url" },
  ];

  const [infos, setInfos] = useState("");

  const getBandListFilter = async () => {
    try {
      const result = await postRequest("TrainingQuestionsSets/GetQuestionSet");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setQuestionSetList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleUpload = () => {
    if (changeButton) {
      handleVideoDeletion();
    } else {
      handleVideoUpoad();
    }
  };
  const handleVideoUpoad = async () => {
    try {
      setLoadingUpload(true);
      const formData = new FormData();
      formData.append("files", formValues?.files);
      const response = await fetch(
        `${process.env.REACT_APP_.MEDIA_BASE_URL}/api/v1/Storage/Save?token=4681fc01-54cd-4506-bfc6-eddc2c04916c`,
        {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: formData,
        }
      );
      const responseData = await response.json();
      setVideoFileData(responseData?.data[0]);
      if (response.ok) {
        setchangeButton(true);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoadingUpload(false);
    }
  };
  const handleVideoDeletion = async () => {
    try {
      const url = videoFileData?.url;
      const stringWithoutLD = url.split("LD/")[1];
      const stringWithBackslashes = stringWithoutLD.replace(/\//g, "\\");
      setLoadingUpload(true);
      const response = await fetch(
        `${process.env.REACT_APP_.MEDIA_BASE_URL}/api/v1/Storage/Delete?token=4681fc01-54cd-4506-bfc6-eddc2c04916c&fileName=${stringWithBackslashes}`,
        {
          method: "DELETE",
          // headers: {
          //   "Content-Type": "application/json",
          // },
        }
      );
      if (response.ok) {
        setchangeButton(false);
        setAfterDelete(true);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "File Already Deleted",
          severity: "error",
        })
      );
    } finally {
      setLoadingUpload(false);
    }
  };
  const getCoursesListFilter = async () => {
    try {
      const result = await getRequest("TrainingCourses/GetTrainingCourses");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.title, value: item.id });
      });
      setBandList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleVideoError = (e) => {
    if (e) {
      confirmAlert({
        customUI: () => {
          return (
            <div className="videowarningPopup">
              <div className="videowarning">Warning!!!</div>
              <div className="videowarningcontent">
                Invalid URL. Please verify it or check for Ad Blockers or Faulty
                extensions.
              </div>
            </div>
          );
        },
      });
    }
  };
  useEffect(() => {
    if (!location.pathname?.includes("/ldmaster/lessons")) {
      if (changeButton) {
        handleVideoDeletion();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    document.title = `PeopleSol - Create Chapter`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    getBandListFilter();
    getCoursesListFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (formValues) {
      formValues.files = "";
      formValues.videoUrl = "";
      formValues.duration = "";
    }
  }, [formValues?.SelectVideo]);
  useEffect(() => {
    if (formValues?.videoUrl)
      formValues.duration = durationfullvideo
        ? Math.floor(durationfullvideo)
        : "";
  }, [formValues?.videoUrl]);
  useEffect(() => {
    if (formValues?.videoUrl)
      formValues.duration = durationfullvideo
        ? Math.floor(durationfullvideo)
        : "";
  }, [durationfullvideo]);
  useEffect(() => {
    if (formValues?.files) {
      formValues.duration = infos ? Math.floor(infos) : "";
    }
  }, [formValues?.files]);
  useEffect(() => {
    if (formValues?.files) {
      formValues.duration = infos ? Math.floor(infos) : "";
    }
  }, [infos]);
  useEffect(() => {
    setchangeButton(false);
  }, [formValues?.files]);
  const videoFormats = [
    ".mp4",
    ".avi",
    ".mov",
    ".wmv",
    ".flv",
    ".mkv",
    ".webm",
    ".mpeg",
    ".mpg",
    ".m4v",
    ".3gp",
    ".ogg",
    ".ogv",
    ".vob",
    ".gifv",
    ".asf",
  ];
  useEffect(() => {
    if (formValues?.files) {
      if (
        !videoFormats.some((format) =>
          formValues?.files?.name?.endsWith(format)
        )
      ) {
        setWrongFormat(true);
      } else setWrongFormat(false);
    }
  }, [formValues?.files]);

  function convertFileSize(bytes) {
    const kilobytes = bytes / 1024;
    const megabytes = kilobytes / 1024;

    if (megabytes >= 1) {
      return `${megabytes.toFixed(2)} MB`;
    } else {
      return `${kilobytes.toFixed(2)} KB`;
    }
  }
  const loaderProps = {
    loading: true,
    size: 20,
    duration: 5,
    colors: ["#1296b0", "#ffffff"],
  };
  // if (formValues && edit) {
  //   formValues.SelectVideo = "url";
  // }
  return (
    <div>
      <div style={{ display: "none" }}>
        {formValues?.videoUrl &&
          !formValues?.videoUrl?.includes(
            `${process.env.REACT_APP_.MEDIA_BASE_URL}/wwwroot`
          ) && (
            <ReactPlayer
              style={{ backgroundColor: "black" }}
              playing={false}
              url={formValues?.videoUrl}
              controls={false}
              width="10%"
              height="50px"
              playbackRate={1}
              volume={0}
              muted={false}
              loop={false}
              // onError={(error) => handleVideoError(error)}
              onDuration={(duration) => {
                setDurationfullvideo(duration);
              }}
            />
          )}
      </div>
      <div className="formcustom">
        <div>
          <LabelCustom labelName={"Chapter"} mandatory={true} />
          <SelectForm
            placeholder={"Select Chapter"}
            name={"courseId"}
            options={bandList}
          />
        </div>
        <div>
          <LabelCustom labelName={"Title"} mandatory={true} />
          <InputCustomFormik
            maxLength={50}
            name="title"
            type={"text"}
            placeholder={"Enter title"}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <div>
            <LabelCustom
              labelName={
                formValues?.SelectVideo === ""
                  ? "Select Video from"
                  : formValues?.SelectVideo === "url"
                  ? "Video URL"
                  : "Video File"
              }
              mandatory={true}
            />
            <div
              className="SelectVideoLDMaster"
              style={{ position: "relative" }}
            >
              <SelectFormLD
                placeholder={"Select Video Source"}
                name={"SelectVideo"}
                options={videoOptions}
              />
              {formValues?.SelectVideo === "video" && (
                <div
                  style={{
                    position: "absolute",
                    left: "2px",
                    top: "-9.5px",
                    width: "85%",
                  }}
                >
                  <FileUploadFormikLD
                    style={{ height: "39px", boxShadow: "none" }}
                    id="fileUp"
                    name={`files`}
                    setInfos={setInfos}
                    changeButton={changeButton}
                    afterDelete={afterDelete}
                    resetAfterDelete={() => setAfterDelete(false)}
                  />
                  <div className="FileuploadLDbutton">
                    <div className="FileuploadLDbuttonDiv1">
                      {loadingUpload && (
                        <div>
                          <SpinningCircleLoader
                            {...loaderProps}
                            // style={{
                            //   position: "absolute",
                            //   right: " 41px",
                            //   top: "9px",
                            // }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          width: "100%",
                          paddingLeft: "12px",
                          color: "var(--secondary)",
                          fontSize: "12px",
                        }}
                      >
                        Size :
                        {`${
                          formValues?.files &&
                          convertFileSize(formValues?.files?.size)
                            ? convertFileSize(formValues?.files?.size)
                            : ""
                        }`}{" "}
                      </div>
                    </div>
                    <div>
                      {formValues?.files &&
                      formValues?.files?.size / 1048576 < 100 &&
                      !wrongFormat ? (
                        <div
                          onClick={
                            formValues?.files &&
                            formValues?.files?.size / 1048576 < 100 &&
                            handleUpload
                          }
                          className={"buttonLD primaryButton"}
                          style={changeButton ? { backgroundColor: "red" } : {}}
                        >
                          {changeButton ? "Delete" : "Upload"}
                        </div>
                      ) : (
                        <div
                          className={"buttonLD primaryLDButtonDisabled"}
                          style={changeButton ? { backgroundColor: "red" } : {}}
                        >
                          {changeButton ? "Delete" : "Upload"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {formValues?.SelectVideo === "url" && (
                <div
                  style={{
                    position: "absolute",
                    left: "2px",
                    top: "-9.5px",
                    width: "85%",
                  }}
                >
                  <InputCustomFormikLD
                    style={{ height: "35px", boxShadow: "none" }}
                    maxLength={150}
                    name="videoUrl"
                    type={"text"}
                    placeholder={"Enter video URL"}
                  />
                  <div
                    style={{
                      width: "100%",
                      paddingLeft: "12px",
                      color: "var(--secondary)",
                      fontSize: "11px",
                    }}
                  >
                    Note: Only YouTube video URLs are allowed.{" "}
                  </div>
                </div>
              )}
            </div>
          </div>
          {messaged &&
            (formValues?.SelectVideo === "url"
              ? !formValues?.videoUrl
              : !formValues?.files) && (
              <div
                className="form-error"
                style={
                  formValues?.SelectVideo
                    ? { marginTop: " 32px" }
                    : { marginTop: "0px" }
                }
              >
                <GppMaybeOutlinedIcon
                  sx={{ height: "14px", width: "15px" }}
                  fontSize="small"
                  color="red"
                />
                Enter URL or Select File
              </div>
            )}
          {wrongFormat && (
            <div
              className="form-error"
              style={
                formValues?.SelectVideo
                  ? { marginTop: " 32px" }
                  : { marginTop: "0px" }
              }
            >
              <GppMaybeOutlinedIcon
                sx={{ height: "14px", width: "15px" }}
                fontSize="small"
                color="red"
              />
              Invalid file format. Please use a supported video format.
            </div>
          )}

          {formValues?.files && formValues?.files?.size / 1048576 >= 100 && (
            <div
              className="form-error"
              style={
                formValues?.SelectVideo
                  ? { marginTop: " 32px" }
                  : { marginTop: "0px" }
              }
            >
              <GppMaybeOutlinedIcon
                sx={{ height: "14px", width: "15px" }}
                fontSize="small"
                color="red"
              />
              File size exceeds 100 MB. Please upload a file below 100 MB.
            </div>
          )}
        </div>

        {/* <input
          type="file"
          class="upload"
          id="fileUp"
          name="fileUpload"
          onChange={setFileInfo}
        /> */}
        {/* <div id="infos">Output: {infos}</div> */}
        <div>
          <LabelCustom labelName={"Duration (in seconds)"} mandatory={true} />
          <InputCustomFormik
            name="duration"
            step=".001"
            type="number"
            // disable={edit ? true : formValues?.files && infos ? true : false}
            disable={true}
            value={
              edit
                ? detailsform?.duration
                : formValues?.files
                ? infos
                  ? Math.floor(infos)
                  : ""
                : formValues?.videoUrl &&
                  durationfullvideo &&
                  Math.floor(durationfullvideo)
            }
            placeholder={"Enter duration"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Pass Percentage"} mandatory={true} />
          <InputCustomFormik
            name="passPercentage"
            step=".001"
            min={0}
            max={100}
            placeholder={"Enter pass percentage"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Cover Image"} mandatory={true} />
          <FileUploadFormik name="upload" />
          <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
            Size: 2 MB
          </span>
          <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
            Format: JPEG, PNG
          </span>
        </div>
        <div>
          <LabelCustom labelName={"Description"} mandatory={true} />
          <TextAreaFormik
            maxLength={500}
            name="description"
            type={"text"}
            placeholder={"Enter description"}
          />
        </div>
        {/* {edit ? (
          <div>
            <LabelCustom labelName={"Is Active"} mandatory={true} />
            <YesNoFormik name={"isActive"} />
          </div>
        ) : null} */}
      </div>

      <div className="headingForm">Details</div>
      <div className="repeatedSectionContainer">
        <FieldArray name="questions">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { questions } = values;

            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Sl. No"} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={"Question Set"}
                          mandatory={true}
                        />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={"Trigger Position Second (in seconds)"}
                          mandatory={true}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {questions?.map((question, index) => (
                    <TableBody key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <SelectForm
                          menuPlacement="top"
                          name={`questions[${index}].questionSetId`}
                          options={questionSetList}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                          step="0.01"
                          min={0}
                          name={`questions[${index}].triggerPositionSecond`}
                          placeholder={"Enter trigger position in seconds"}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            questions.length > 1
                              ? remove(index)
                              : dispatch(
                                  showToast({
                                    text: "Atleast One Item is Required ",
                                    severity: "error",
                                  })
                                )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({
                              questionSetId: "",
                              triggerPositionSecond: "",
                            })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

/* eslint-disable no-unused-vars */
import React from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { postRequestForm } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";

import { Form, Formik } from "formik";
import { OnboardingSettingForm } from "./Form/OnboardingSettingForm";
import { useState } from "react";
import { Loader } from "../../../Components/FormComponent/Loader";
import { useEffect } from "react";

const OnboardingSetting = (props) => {
  const [value, setValue] = useState("");
  // //console.log("value",value)
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [option, setOption] = React.useState({});

  const getOption = async () => {
    try {
      setLoading(true);
      const result = await postRequestForm("Settings/GetCompanySettings");
      setOption(result.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    getOption();
  }, []);
  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm("Settings/CompanySettings", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getOption();
    }
  };
  const schema = Yup.object({
    Upload: Yup.string().required("Please upload a doc"),
  });

  useEffect(() => {
    document.title = `PeopleSol - Onboaring Settings`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const initialvalue = {
    GeneralRelievingLetterFormat: option?.generalRelievingLetterFormat,
    ObCertificateContent: option?.obCertificateContent,
    CafeteriaEmailId: option?.cafeteriaEmailId,
    ObInductionContent: "",
    ObInductionMinTime: option?.obInductionMinTime,
    ObPoliciesMinTime: option?.obPoliciesMinTime,
    ObAssetFormMinTime: option?.obAssetFormMinTime,
    ObFeedbackMinTime: option?.obFeedbackMinTime,
    SupportMail: option?.supportMail,
    Upload: option?.attachment ? `Settings/${option?.attachment}` : null,
  };
  const onSubmit = (values) => {
    const formData = new FormData();
    if (values?.GeneralRelievingLetterFormat)
      formData.append(
        "GeneralRelievingLetterFormat",
        values?.GeneralRelievingLetterFormat
      );
    if (values?.ObCertificateContent)
      formData.append("ObCertificateContent", values?.ObCertificateContent);
    if (values?.CafeteriaEmailId)
      formData.append("CafeteriaEmailId", values?.CafeteriaEmailId);
    if (value) formData.append("ObInductionContent", value);
    if (values?.ObInductionMinTime)
      formData.append("ObInductionMinTime", values?.ObInductionMinTime);
    if (values?.ObPoliciesMinTime)
      formData.append("ObPoliciesMinTime", values?.ObPoliciesMinTime);
    if (values?.ObAssetFormMinTime)
      formData.append("ObAssetFormMinTime", values?.ObAssetFormMinTime);
    if (values?.ObFeedbackMinTime)
      formData.append("ObFeedbackMinTime", values?.ObFeedbackMinTime);
    if (values?.SupportMail)
      formData.append("SupportMail", values?.SupportMail);
    if (values?.Upload) formData.append("Upload", values?.Upload);
    // exportUserInfo(value)
    Edit(formData);
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate("settings/onboardingfeedback");
  };

  // function exportUserInfo(userInfo) {
  //   const fileData = JSON.stringify(userInfo);
  //   const blob = new Blob([fileData], { type: "Doc" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.download = "user-info.pdf";
  //   link.href = url;
  //   link.click();
  // }
  if (loading) return <Loader />;
  return (
    <div
      style={{
        background: "#FAF9F8",
        marginTop: "20px",
        height: "calc(100% - 69px)",
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialvalue}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {(formik, values) => {
          return (
            <Form
              style={{
                // padding: " 15px 15px 0 15px ",
                position: "relative",
                height: "100%",
                flexGrow: 1,
              }}
            >
              <div
                style={{
                  padding: " 15px 15px 0 15px ",
                  height: "calc(100% - 79px)",
                  overflowY: "auto",
                }}
              >
                <OnboardingSettingForm data={option} setValue={setValue} />
              </div>

              <div className="createFootCust" style={{ paddingRight: "14px" }}>
                <div>
                  <button
                    // style={{ padding: " 5px 21px" }}
                    className="cancelbtn"
                    onClick={getOption}
                    type="reset"
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="button primaryButton" type="submit">
                    Update
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { OnboardingSetting };

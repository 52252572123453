import React, { useEffect, useState } from 'react'
import "../PMSMaster/Components/Rewards.css";
import { IconButton } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SelectCustom } from '../../Components/FormComponent/SelectCustom';
import SelectCustomRewardsWinner from './SelectCustomRewardsWinner';

import winnerFirstPosition from '../../../src/Assets/rewardsfirstwinner.png';
import winnerSecondPosition from '../../../src/Assets/rewardssecondwinner.png';
import winnerThirdPosition from '../../../src/Assets/rewardsthirdwinner.png';
import person1 from '../../../src/Assets/winnerimage_1.png';
import person2 from '../../../src/Assets/winnerimage_2.png';
import person3 from '../../../src/Assets/winnerimage_3.png';
import consolation1 from '../../../src/Assets/winnerconsolationimag.jsx.png';
import { useDispatch } from 'react-redux';
import { getRequest, postRequest } from '../../Services/axios';
import { isLoading, showToast } from '../../Features';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

const TeamRewardsWinnwers = () => {
    const [searchParam, setSearchParam] = useState(null);
    const [activeTab, setActiveTab] = useState("Organisation");
    const [loading, setLoading] = useState(true);
    const [orgData, setOrgData] = useState(false);
    const [deptData, setDeptData] = useState(false);
    const [locationData, setLocationData] = useState(false);
    const [selectedLocation, setSelectedLocaton] = useState([]);
    const [optionLocation, setOptionLocation] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [optionDepartment, setOptionDepartment] = useState([]);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const rewadsId = location.state.rewardId
    const rewadsTitle = location.state.title

    // console.log("this is rewardid", rewadsId)
    console.log("this is rewardid of winnelist", params)
    // console.log("this is rewardid", location)


    const [reqData, setReqData] = useState({
        RewardID: rewadsId,
        lastUpdatedOn: null,
        dataTableRequest: {
            sSearch: searchParam,
        },
    });

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const getListData = async (departmentId, locationId) => {

        try {
            dispatch(isLoading(true));
            setLoading(true);
            const departmentParam = departmentId ?? 0;
            const locationParam = locationId ?? 0;
            const result = await postRequest(`RewardReview/ViewResult?RewardID=${rewadsId}&DepartmentId=${departmentParam}&LocationId=${locationParam}`
            );
            if (result.data) {
                let tempDataOrg = [];
                let tempDataDept = [];
                let tempDataLoc = [];

                result?.data?.data?.organisationwinners?.map((item) => {
                    return tempDataOrg.push({
                        ...item,
                        empname: item.employees.fullName,
                        empnamewithid: item.employees.nameWithPersonNumber,
                        empid: item.employees.personNumber,
                        personimage: item.employees.image,

                    });

                });
                result?.data?.data?.departmentwinners?.map((item) => {
                    return tempDataDept.push({
                        ...item,
                        empname: item.employees.fullName,
                        empnamewithid: item.employees.nameWithPersonNumber,
                        empid: item.employees.personNumber,
                        personimage: item.employees.image,

                    });

                });
                result?.data?.data?.locationwinners?.map((item) => {
                    return tempDataLoc.push({
                        ...item,
                        empname: item.employees.fullName,
                        empnamewithid: item.employees.nameWithPersonNumber,
                        empid: item.employees.personNumber,
                        personimage: item.employees.image,

                    });

                });
                setOrgData(tempDataOrg);
                setDeptData(tempDataDept);
                setLocationData(tempDataLoc);


            } else {
                setOrgData(false);
                setDeptData(false);
                setLocationData(false);
            }
        } catch (error) {
            dispatch(
                showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
            );
        } finally {
            dispatch(isLoading(false));
            setLoading(false);
        }
    };

    console.log("this is original data", orgData)


    const getDepartment = async () => {
        try {
            const result = await getRequest(`RewardReview/GetDepartments?RewardID=${rewadsId}`);
            let listData = [];
            result.data.results.map((item) => {
                listData.push({ label: item.text, value: item.id });
                return null;
            });
            setOptionDepartment(listData);
        } catch (error) {
        } finally {
        }
    };

    console.log("selected Department", selectedDepartment)

    const getLocation = async () => {
        try {
            const result = await getRequest(`RewardReview/GetLocations?RewardID=${rewadsId}&DepartmentID=${selectedDepartment[0].value}`);
            let listData = [];
            result.data.results.map((item) => {
                listData.push({ label: item.text, value: item.id });
                return null;
            });
            setOptionLocation(listData);
        } catch (error) {
        } finally {
        }
    };
    console.log("selected Location", selectedLocation)


    useEffect(() => {

        if (activeTab === "Organisation") {

            getListData();
        } else if (activeTab === "Department" && selectedDepartment.length > 0) {

            const departmentId = selectedDepartment[0]?.value
            if (departmentId)
                getListData(departmentId);
        } else if (activeTab === "Location" && selectedDepartment.length > 0 && selectedLocation.length > 0) {

            const departmentId = selectedDepartment[0]?.value
            const locationId = selectedLocation[0]?.value
            if (departmentId && locationId) {

                getListData(departmentId, locationId);
            }
        }
    }, [location.pathname, activeTab, selectedDepartment, selectedLocation]);

    console.log("this is myrewards data  winners list", orgData)


    return (
        <div>
            <div className='pmsrewardswinnercontainer pmsrewardswinnercontainerActive'>
                <div className="result-card">
                    {/* Header Section */}
                    <div className="header">
                        <div style={{ display: "flex" }}>
                            <IconButton onClick={() => navigate("/pms/rewards/teamrewards")}>
                                <svg
                                    width="20"
                                    height="8"
                                    viewBox="0 0 20 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                                        fill="#1296B0"
                                    />
                                </svg>
                            </IconButton>
                            <h4 className='rewarstitle'>

                                {rewadsTitle}
                            </h4>
                        </div>
                        <div style={{ maxWidth: "280px"}} className="search-box" >
                            <input
                                className="search-text"
                                type="text"
                                placeholder="Search"
                                onChange={(e) => setSearchParam(e.target.value)}
                            />
                            <a className="search-btn" >
                                <svg
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                                        fill="#1296B0"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>

                    {/* Tabs Section */}
                    <div className='loverFoter'>
                        <div className="tabswinnerlist">
                            <button
                                className={`tabwinnerlinst ${activeTab === "Organisation" ? "active" : ""}`}
                                onClick={() => handleTabClick("Organisation")}
                            >
                                Organisation
                            </button>
                            <button
                                className={`tabwinnerlinst ${activeTab === "Department" ? "active" : ""}`}
                                onClick={() => handleTabClick("Department")}
                            >
                                Department
                            </button>
                            <button
                                className={`tabwinnerlinst ${activeTab === "Location" ? "active" : ""}`}
                                onClick={() => handleTabClick("Location")}
                            >
                                Location
                            </button>
                        </div>
                        <div className='select-section'>
                            {(activeTab === "Department" || activeTab === "Location") &&
                                <>

                                    <div>
                                        <SelectCustomRewardsWinner

                                            sty1={{
                                                background: "#EDF8F9",
                                                minWidth: "172px",
                                                border: "1px solid #E6E6E6",
                                                textOverflow: "ellipsis"
                                            }}

                                            onDropdownOpen={() => {
                                                getDepartment();
                                            }}
                                            options={optionDepartment}
                                            values={selectedDepartment}

                                            onChange={(value) => {
                                                setSelectedDepartment(value);
                                            }}
                                        />
                                    </div>

                                    {activeTab === "Location" &&
                                        (<div>
                                            <SelectCustomRewardsWinner

                                                sty1={{
                                                    background: "#EDF8F9",
                                                    minWidth: "172px",
                                                    border: "1px solid #E6E6E6",
                                                    textOverflow: "ellipsis"
                                                }}

                                                onDropdownOpen={() => {
                                                    getLocation();
                                                }}
                                                options={optionLocation}
                                                values={selectedLocation}

                                                onChange={(value) => {
                                                    setSelectedLocaton(value);
                                                }}
                                            />
                                        </div>)
                                    }

                                </>
                            }

                        </div>
                    </div>
                    {/* Leaderboard Section */}
                    <div className="leaderboard" style={{
                        margin: activeTab === "Organisation" ? "14px 38px 24px 38px" : "0px 38px 24px 38px",
                    }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>

                            {activeTab === "Organisation" && orgData?.length > 0 &&
                                orgData
                                    .filter(item => item.position <= 3 && item.position > 0) // Filter items with position 1, 2, or 3
                                    .sort((a, b) => a.position - b.position) // Ensure they are sorted by position
                                    .map((item, index) => (
                                        <div className="leader" key={index}>
                                            <div className='leaderItem'>
                                                <div className="winner">
                                                    {/* Display actual employee data from the item */}
                                                    <div className={
                                                        item.position === 1
                                                            ? "winnersouterdiv1"
                                                            : item.position === 2
                                                                ? "winnersouterdiv2"
                                                                : "winnersouterdiv3"
                                                    }>
                                                        <img
                                                            className={
                                                                item.position === 1
                                                                    ? "winnersouterdiv1_img"
                                                                    : item.position === 2
                                                                        ? "winnersouterdiv2_img"
                                                                        : "winnersouterdiv3_img"
                                                            }
                                                            src={item.personimage ? `https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/${item.personimage}` : person2}
                                                            alt="Winner's image"
                                                        />

                                                        {/* <img src={person1} alt="Winners image" /> */}
                                                    </div>

                                                    <h6>{item.empname}</h6>
                                                    <p>({item.empid})</p>
                                                </div>
                                                <div className={`trophy trophy-${item.position === 1 ? "gold" : item.position === 2 ? "silver" : "bronze"}`}>
                                                    {/* Conditional trophy image based on position */}
                                                    <img
                                                        src={
                                                            item.position === 1
                                                                ? winnerFirstPosition
                                                                : item.position === 2
                                                                    ? winnerSecondPosition
                                                                    : winnerThirdPosition
                                                        }
                                                        alt={`${item.position === 1 ? "First" : item.position === 2 ? "Second" : "Third"} position`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }


                            {activeTab === "Department" && deptData.length > 0 &&
                                deptData
                                    .filter(item => item.position <= 3 && item.position > 0) // Filter items with position 1, 2, or 3
                                    .sort((a, b) => a.position - b.position) // Ensure they are sorted by position
                                    .map((item, index) => (
                                        <div className="leader" key={index}>
                                            <div className='leaderItem'>
                                                <div className="winner">
                                                    {/* Display actual employee data from the item */}
                                                    <div className={
                                                        item.position === 1
                                                            ? "winnersouterdiv1"
                                                            : item.position === 2
                                                                ? "winnersouterdiv2"
                                                                : "winnersouterdiv3"
                                                    }>
                                                        <img
                                                            className={
                                                                item.position === 1
                                                                    ? "winnersouterdiv1_img"
                                                                    : item.position === 2
                                                                        ? "winnersouterdiv2_img"
                                                                        : "winnersouterdiv3_img"
                                                            }
                                                            src={item.personimage ? `https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/${item.personimage}` : person2}
                                                            alt="Winner's image"
                                                        />

                                                        {/* <img src={person1} alt="Winners image" /> */}
                                                    </div>

                                                    <h6>{item.empname}</h6>
                                                    <p>({item.empid})</p>
                                                </div>
                                                <div className={`trophy trophy-${item.position === 1 ? "gold" : item.position === 2 ? "silver" : "bronze"}`}>
                                                    {/* Conditional trophy image based on position */}
                                                    <img
                                                        src={
                                                            item.position === 1
                                                                ? winnerFirstPosition
                                                                : item.position === 2
                                                                    ? winnerSecondPosition
                                                                    : winnerThirdPosition
                                                        }
                                                        alt={`${item.position === 1 ? "First" : item.position === 2 ? "Second" : "Third"} position`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }

                            {activeTab === "Location" && locationData.length > 0 &&
                                locationData
                                    .filter(item => item.position <= 3 && item.position > 0) // Filter items with position 1, 2, or 3
                                    .sort((a, b) => a.position - b.position) // Ensure they are sorted by position
                                    .map((item, index) => (
                                        <div className="leader" key={index}>
                                            <div className='leaderItem'>
                                                <div className="winner">
                                                    {/* Display actual employee data from the item */}
                                                    <div className={
                                                        item.position === 1
                                                            ? "winnersouterdiv1"
                                                            : item.position === 2
                                                                ? "winnersouterdiv2"
                                                                : "winnersouterdiv3"
                                                    }>
                                                        <img
                                                            className={
                                                                item.position === 1
                                                                    ? "winnersouterdiv1_img"
                                                                    : item.position === 2
                                                                        ? "winnersouterdiv2_img"
                                                                        : "winnersouterdiv3_img"
                                                            }
                                                            src={item.personimage ? `https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/${item.personimage}` : person2}
                                                            alt="Winner's image"
                                                        />

                                                        {/* <img src={person1} alt="Winners image" /> */}
                                                    </div>

                                                    <h6>{item.empname}</h6>
                                                    <p>({item.empid})</p>
                                                </div>
                                                <div className={`trophy trophy-${item.position === 1 ? "gold" : item.position === 2 ? "silver" : "bronze"}`}>
                                                    {/* Conditional trophy image based on position */}
                                                    <img
                                                        src={
                                                            item.position === 1
                                                                ? winnerFirstPosition
                                                                : item.position === 2
                                                                    ? winnerSecondPosition
                                                                    : winnerThirdPosition
                                                        }
                                                        alt={`${item.position === 1 ? "First" : item.position === 2 ? "Second" : "Third"} position`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>

                        <div className='leftposition'>
                            <ul>
                                {activeTab === "Organisation" && orgData.length > 0 &&
                                    orgData
                                        .filter(item => item.position > 3) // Filter items with position 1, 2, or 3
                                        .sort((a, b) => a.position - b.position) // Ensure they are sorted by position
                                        .map((item, index) => (
                                            <li>
                                                <div className='liItems'>
                                                    <span className=''>{`# ${item?.position}`}</span>
                                                    <img

                                                        src={item.personimage ? `https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/${item.personimage}` : person2}
                                                        alt="consolation img" />
                                                    <span > {item.empnamewithid}</span>
                                                </div>
                                                <span className="winnertag">Winner</span>
                                            </li>
                                        ))
                                }
                                {activeTab === "Department" && deptData.length > 0 &&
                                    deptData
                                        .filter(item => item.position > 3) // Filter items with position 1, 2, or 3
                                        .sort((a, b) => a.position - b.position) // Ensure they are sorted by position
                                        .map((item, index) => (
                                            <li>
                                                <div className='liItems'>
                                                    <span className=''>{`# ${item?.position}`}</span>
                                                    <img

                                                        src={item.personimage ? `https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/${item.personimage}` : person2}
                                                        alt="consolation img" />
                                                    <span > {item.empnamewithid}</span>
                                                </div>
                                                <span className="winnertag">Winner</span>
                                            </li>
                                        ))
                                }
                                {activeTab === "Location" && locationData.length > 0 &&
                                    locationData
                                        .filter(item => item.position > 3) // Filter items with position 1, 2, or 3
                                        .sort((a, b) => a.position - b.position) // Ensure they are sorted by position
                                        .map((item, index) => (
                                            <li>
                                                <div className='liItems'>
                                                    <span className=''>{`# ${item?.position}`}</span>
                                                    <img

                                                        src={item.personimage ? `https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/${item.personimage}` : person2}
                                                        alt="consolation img" />
                                                    <span > {item.empnamewithid}</span>
                                                </div>
                                                <span className="winnertag">winner</span>
                                            </li>
                                        ))
                                }


                            </ul>
                        </div>
                    </div>


                    {/* Consolation Section */}

                    <h6 className='winnerconsolation_h6'>Consolation Candidates</h6>

                    <div className="winnerconsolation">
                        <ul>
                            {activeTab === "Organisation" && orgData.length > 0 &&
                                orgData
                                    .filter(item => item.isConsolation === true) // Filter items with position 1, 2, or 3
                                    .sort((a, b) => a.position - b.position) // Ensure they are sorted by position
                                    .map((item, index) => (
                                        <li>
                                            <div className='liItems'>
                                                <span className=''>{`# ${index + 1}`}</span>
                                                <img

                                                    src={item.personimage ? `https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/${item.personimage}` : person2}
                                                    alt="consolation img" />
                                                <span > {item.empnamewithid}</span>
                                            </div>
                                            <span className="tag">Consolation</span>
                                        </li>
                                    ))
                            }
                            {activeTab === "Department" && deptData.length > 0 &&
                                deptData
                                    .filter(item => item.isConsolation === true) // Filter items with position 1, 2, or 3
                                    .sort((a, b) => a.position - b.position) // Ensure they are sorted by position
                                    .map((item, index) => (
                                        <li>
                                            <div className='liItems'>
                                                <span className=''>{`# ${index + 1}`}</span>
                                                <img

                                                    src={item.personimage ? `https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/${item.personimage}` : person2}
                                                    alt="consolation img" />
                                                <span > {item.empnamewithid}</span>
                                            </div>
                                            <span className="tag">Consolation</span>
                                        </li>
                                    ))
                            }
                            {activeTab === "Location" && locationData.length > 0 &&
                                locationData
                                    .filter(item => item.isConsolation === true) // Filter items with position 1, 2, or 3
                                    .sort((a, b) => a.position - b.position) // Ensure they are sorted by position
                                    .map((item, index) => (
                                        <li>
                                            <div className='liItems'>
                                                <span className=''>{`# ${index + 1}`}</span>
                                                <img

                                                    src={item.personimage ? `https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/${item.personimage}` : person2}
                                                    alt="consolation img" />
                                                <span > {item.empnamewithid}</span>
                                            </div>
                                            <span className="tag">Consolation</span>
                                        </li>
                                    ))
                            }
                            {/* <li>
                                <div className='liItems'>
                                    <span className=''>#1</span>
                                    <img src={consolation1} alt="consolation img" />
                                    <span > Sarah Thompson (1000004)</span>
                                </div>
                                <span className="tag">Consolation</span>
                            </li>
                            <li>
                                <div className='liItems'>
                                    <span className=''>#2</span>
                                    <img src={consolation1} alt="consolation img" />
                                    <span > Sarah Thompson (1000004)</span>
                                </div>
                                <span className="tag">Consolation</span>
                            </li>
                            <li>
                                <div className='liItems'>
                                    <span className=''>#3</span>
                                    <img src={consolation1} alt="consolation img" />
                                    <span > Sarah Thompson (1000004)</span>
                                </div>
                                <span className="tag">Consolation</span>
                            </li>
                            <li>
                                <div className='liItems'>
                                    <span className=''>#3</span>
                                    <img src={consolation1} alt="consolation img" />
                                    <span > Sarah Thompson (1000004)</span>
                                </div>
                                <span className="tag">Consolation</span>
                            </li>
                            <li>
                                <div className='liItems'>
                                    <span className=''>#3</span>
                                    <img src={consolation1} alt="consolation img" />
                                    <span > Sarah Thompson (1000004)</span>
                                </div>
                                <span className="tag">Consolation</span>
                            </li>
                            <li>
                                <div className='liItems'>
                                    <span className=''>#3</span>
                                    <img src={consolation1} alt="consolation img" />
                                    <span > Sarah Thompson (1000004)</span>
                                </div>
                                <span className="tag">Consolation</span>
                            </li>
                            <li>
                                <div className='liItems'>
                                    <span className=''>#3</span>
                                    <img src={consolation1} alt="consolation img" />
                                    <span > Sarah Thompson (1000004)</span>
                                </div>
                                <span className="tag">Consolation</span>
                            </li>
                            <li>
                                <div className='liItems'>
                                    <span className=''>#3</span>
                                    <img src={consolation1} alt="consolation img" />
                                    <span > Sarah Thompson (1000004)</span>
                                </div>
                                <span className="tag">Consolation</span>
                            </li>
                            <li>
                                <div className='liItems'>
                                    <span className=''>#3</span>
                                    <img src={consolation1} alt="consolation img" />
                                    <span > Sarah Thompson (1000004)</span>
                                </div>
                                <span className="tag">Consolation</span>
                            </li> */}

                        </ul>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default TeamRewardsWinnwers

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import EditPencilIcon from "../../../Assets/EditPencilIcon.svg";
import EPFCover from "../../../Assets/EPFCover.png";
import { IconButton, Tooltip } from "@mui/material";
import "./EPFTab.css";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { Form, Formik } from "formik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";

import { CheckboxFormiks } from "../../../Components/FormComponent/CheckboxFormiks";
import { RadioFormik } from "../../../Components/FormComponent/RadioFormik";
import { Loader } from "../../../Components/FormComponent/Loader";
const EPFTab = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [ePFData, setEPFData] = useState({});
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest("StatutoryCompliance/DetailsEPF");
      if (result.data) {
        if (result.data.isEnable) setEnabled(true);
        setEPFData(result.data);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - EPF `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const disableEPF = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `StatutoryCompliance/DisableEPF/${ePFData?.id}`
      );
      if (result.data) {
        setEnabled(false);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("StatutoryCompliance/EditEPF", values);
      getEmployeeData();
      setEditActive();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const submitEditCompany = (values) => {
    const getEmployeeContributionRate = () => {
      if (values.employerContributionRate === "12%") return "12%";
      else return values.employeeContributionRate;
    };
    const getEdliContribution = () => {
      if (values.includeEmployerContribution) return values.edliContribution;
      else return false;
    };
    const getEpfAdminCharges = () => {
      if (values.includeEmployerContribution) return values.epfAdminCharges;
      else return false;
    };
    const getAbryEligibility = () => {
      if (values.abryEligibilityBool) return values.abryEligibility;
      else return "";
    };
    const editData = {
      ...values,
      id: values.id,
      deductionCycle: "Monthly",
      epfNumber: values.epfNumber,
      // deductionCycle: values.deductionCycle,
      employerContributionRate: values.employerContributionRate,
      employeeContributionRate: getEmployeeContributionRate(),
      includeEmployerContribution: values.includeEmployerContribution,
      edliContribution: getEdliContribution(),
      epfAdminCharges: getEpfAdminCharges(),
      overridePFContributionRate: values.overridePFContributionRate,
      proRateRestrictedPFWage: values.proRateRestrictedPFWage,
      pfWageOnLOP: values.pfWageOnLOP,
      abryEligibility: getAbryEligibility(),
    };
    createPageData(editData);
  };
  const getABRYBool = () => {
    if (!ePFData?.abryEligibility) return false;
    if (ePFData?.abryEligibility === "") return false;
    return true;
  };
  const contributionOptions = [
    { label: "12% of Actual PF Wage", value: "12%" },
    { label: "Restrict Contribution to ₹15,000 of PF Wage", value: "15000" },
  ];
  const getEmployerTotal = (values) => {
    if (values.employerContributionRate === "15000") {
      if (
        values.includeEmployerContribution &&
        values.epfAdminCharges &&
        values.edliContribution
      )
        return "₹ 1950";
      else if (values.includeEmployerContribution && values.epfAdminCharges)
        return "₹ 1875";
      else if (values.includeEmployerContribution && values.edliContribution)
        return "₹ 1875";
      else return "₹ 1800";
    } else {
      if (
        values.includeEmployerContribution &&
        values.epfAdminCharges &&
        values.edliContribution
      )
        return "₹ 2575";
      else if (values.includeEmployerContribution && values.epfAdminCharges)
        return "₹ 2500";
      else if (values.includeEmployerContribution && values.edliContribution)
        return "₹ 2475";
      else return "₹ 2400";
    }
  };
  return (
    <div className="fullTableContainer centerContent" style={{ overflow: 'auto' }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {editActive ? (
            <div
              className={`companyCard ${editActive && "editActiveBackground"}`}
            >
              <div style={{ height: "100%" }}>
                <div className="headingContainer">
                  <h3>Employees' Provident Fund</h3>
                  {!editActive && <Tooltip arrow title="Cancel Edit" placement="left">
                    <div
                      onClick={() => setEditActive((prev) => !prev)}
                      className={`${editActive && "pencilBackground"}`}
                    >
                      <img
                        src={EditPencilIcon}
                        alt="Edit Pencil"
                        className="editPencilIcon"
                      />
                    </div>
                  </Tooltip>}
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    ...ePFData,
                    abryEligibilityBool: getABRYBool(),
                  }}
                  onSubmit={submitEditCompany}
                >
                  {(formik) => {
                    return (
                      <Form
                        style={{
                          overflow: "auto",
                          height: "calc(100% - 135px)",
                        }}
                      >
                        <div style={{ display: "flex", }}>
                          <div style={{ width: "60%" }}>
                            <div className="formcustom twoColumn">
                              <div>
                                <LabelCustom labelName={"EPF Number"} />
                                <InputCustomFormik
                                  borderBlack={true}
                                  name="epfNumber"
                                  placeholder="AA/AAA/0000000/000"
                                />
                              </div>
                              <div>
                                <LabelCustom labelName={"Deduction Cycle"} />
                                <InputCustomFormik
                                  borderBlack={true}
                                  name="deductionCycle"
                                  disabled={true}
                                />
                              </div>
                              <div>
                                <LabelCustom
                                  labelName={"Employee Male Contribution (%)"}
                                />

                                <InputCustomFormik
                                  borderBlack={true}
                                  name="employeeMaleContribution"
                                  type="number"
                                />
                              </div>
                              <div>
                                <LabelCustom
                                  labelName={"Employee Female Contribution (%)"}
                                />

                                <InputCustomFormik
                                  borderBlack={true}
                                  name="employeeFemaleContribution"
                                  type="number"
                                />
                              </div>
                            </div>
                            <div className="headingForm">Employer Contribution Rate</div>
                            <div className="formcustom twoColumn">
                              <div>
                                <LabelCustom
                                  labelName={"Employer EPF Contribution Rate (%)"}
                                />

                                <InputCustomFormik
                                  borderBlack={true}
                                  name="employerEPFContribution"
                                  type="number"
                                />
                              </div>
                              <div>
                                <LabelCustom
                                  labelName={"Employer EPS Contribution Rate (%)"}
                                />

                                <InputCustomFormik
                                  borderBlack={true}
                                  name="employerEPSContribution"
                                  type="number"
                                />
                              </div>
                              <div>
                                <LabelCustom labelName={"EDLIS Contribution (%)"} />

                                <InputCustomFormik
                                  borderBlack={true}
                                  name="edlisContribution"
                                  type="number"
                                />
                              </div>
                              <div>
                                <LabelCustom labelName={"EPF Admin Charges (%)"} />

                                <InputCustomFormik
                                  borderBlack={true}
                                  name="epfAdminCharge"
                                  type="number"
                                />
                              </div>
                              <div>
                                <LabelCustom
                                  labelName={"EDLIS Admin Charges (%)"}
                                />

                                <InputCustomFormik
                                  borderBlack={true}
                                  name="edlisAdminCharge"
                                  type="number"
                                />
                              </div>
                            </div>
                            <CheckboxFormiks
                              name="includeEmployerContribution"
                              labelName="Include employer's contribution in the CTC"
                            />
                          </div>
                          <div
                            style={{
                              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.02)",
                              backgroundColor: "white",
                              borderRadius: "4px",
                              width: "40%",
                              padding: "12px",
                              margin: "24px 12px 12px 12px",
                              fontSize: "12px",
                              lineHeight: "22px",
                              height: "fit-content",
                            }}
                          >
                            <h5 style={{ color: "var(--primary)" }}>
                              Sample EPF Calculation
                            </h5>
                            <p style={{ margin: "6px 0" }}>
                              Let's assume the PF wage is ₹ 20,000. The breakup
                              of contribution will be:
                            </p>
                            <div
                              style={{
                                border: "1px solid #D8F0F2",
                                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.02)",
                                borderRadius: "4px",
                                backgroundColor: "white",
                                padding: "12px",
                              }}
                            >
                              <h6>Employer's Contribution</h6>
                              <div
                                style={{
                                  padding: "6px 0",
                                  borderBottom: "0.75px dashed #D9D9D9",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "3px 0",
                                  }}
                                >
                                  <p>{`EPS (8.33% of ${formik.values.employerContributionRate ===
                                    "15000"
                                    ? "15000"
                                    : "20000"
                                    } (Max of ₹ 15,000))`}</p>
                                  <p>₹ 1250</p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "3px 0",
                                  }}
                                >
                                  <p>{`EPF (12% of ${formik.values.employerContributionRate ===
                                    "15000"
                                    ? "15000"
                                    : "20000"
                                    } - EPS)`}</p>
                                  <p>{`₹ ${formik.values.employerContributionRate ===
                                    "15000"
                                    ? "550"
                                    : "1150"
                                    }`}</p>
                                </div>
                                {formik.values.includeEmployerContribution &&
                                  formik.values.edliContribution && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "3px 0",
                                      }}
                                    >
                                      <p>{`EDLI Contribution (0.50% of ${formik.values
                                        .employerContributionRate === "15000"
                                        ? "15000"
                                        : "20000"
                                        } (Max ₹ 75))`}</p>
                                      <p>₹ 75</p>
                                    </div>
                                  )}
                                {formik.values.includeEmployerContribution &&
                                  formik.values.epfAdminCharges && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "3px 0",
                                      }}
                                    >
                                      <p>{`EPF Admin Charges (0.50% of ${formik.values
                                        .employerContributionRate === "15000"
                                        ? "15000"
                                        : "20000"
                                        })`}</p>
                                      <p>{`₹ ${formik.values
                                        .employerContributionRate === "15000"
                                        ? "75"
                                        : "100"
                                        }`}</p>
                                    </div>
                                  )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  borderBottom: "0.75px solid #D9D9D9",
                                  padding: "6px 0",
                                }}
                              >
                                <h6>Total</h6>
                                <h6>{getEmployerTotal(formik.values)}</h6>
                              </div>
                              <h6 style={{ marginTop: "6px" }}>
                                Employee's Contribution
                              </h6>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "6px 0",
                                }}
                              >
                                <p>{`EPF (12% of ${formik.values.employeeContributionRate ===
                                  "15000"
                                  ? "15000"
                                  : "20000"
                                  })`}</p>
                                <p>{`₹ ${formik.values.employeeContributionRate ===
                                  "15000"
                                  ? "1800"
                                  : "2400"
                                  }`}</p>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              position: "absolute",
                              width: "100%",
                              bottom: "12px",
                              borderTop: "1px solid var(--secondary-text)",
                              // margin: "0 12px",
                            }}
                          >
                            <button
                              className="button secondaryButton"
                              type="button"
                              style={{ border: "1px solid rgba(18, 150, 176, 1)" }}
                              onClick={() => setEditActive((prev) => !prev)}
                            >
                              Cancel
                            </button>
                            <button
                              className="button primaryButton"
                              type="submit"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          ) : enabled ? (
            <div className="companyCard">
              <div className="companyCardContainer">
                <div className="headingContainer">
                  <h3>Employees' Provident Fund</h3>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <button
                      className="button"
                      style={{ curson: "pointer", backgroundColor: "red", color: "white", textAlign: "center" }}

                      onClick={disableEPF}
                    >
                      {/* <svg
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.500244"
                          y="1.12598"
                          width="18"
                          height="18"
                          rx="2"
                          stroke="#1296B0"
                        />
                        <circle
                          cx="9.50021"
                          cy="10.1259"
                          r="5.65573"
                          fill="#F71D1D"
                        />
                        <line
                          x1="12.4458"
                          y1="9.9978"
                          x2="6.55432"
                          y2="9.9978"
                          stroke="white"
                          stroke-linecap="round"
                        />
                      </svg> */}
                      Disable EPF
                    </button>
                    <Tooltip arrow title="Edit EPF" placement="left">
                      <div
                        style={{ cursor: "pointer", marginTop: 5 }}
                        onClick={() => setEditActive(true)}
                        className={`${editActive && "pencilBackground"}`}
                      >
                        <img
                          src={EditPencilIcon}
                          alt="Edit Pencil"
                          style={{ height: 35, width: 35 }}
                          className="editPencilIcon"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="dataTableRowContainer">
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">EPF Number</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.epfNumber}
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">Deduction Cycle</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.deductionCycle}
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">
                      Employer Contribution Rate
                    </label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.employerContributionRate === "12%"
                        ? "12% of Actual PF Wage"
                        : "Restrict Contribution to ₹15,000 of PF Wage"}
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">Employee Male Contribution</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.employeeMaleContribution} %
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">Employee Female Contribution</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.employeeFemaleContribution} %
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">Employer EPF Contribution Rate</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.employerEPFContribution} %
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">Employer EPS Contribution Rate</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.employerEPSContribution} %
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">EDLIS Contribution</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.edlisContribution} %
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">Employer EPS Contribution Rate</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.edlisContribution} %
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">EPF Admin Charges</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.epfAdminCharge} %
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">EDLIS Admin Charges</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.edlisAdminCharge}%
                    </label>
                  </div>
                  {/* <div className="dataTableRow">
                    <label className="dataTableRowCell">CTC Inclusions</label>
                    <label className="dataTableRowCell">:</label>
                    <div className="dataTableRowCell">
                      <div className="dataTableRow">
                        <label
                          style={
                            ePFData?.includeEmployerContribution
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {ePFData?.includeEmployerContribution ? "✔" : "✖"}
                        </label>
                        <label>
                          Employer's contribution is included in the CTC.
                        </label>
                      </div>
                      <div className="dataTableRow">
                        <label
                          style={
                            ePFData?.edliContribution
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {ePFData?.edliContribution ? "✔" : "✖"}
                        </label>
                        <label>
                          Employer's EDLI contribution is included in the CTC.
                        </label>
                      </div>
                      <div className="dataTableRow">
                        <label
                          style={
                            ePFData?.epfAdminCharges
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {ePFData?.epfAdminCharges ? "✔" : "✖"}
                        </label>
                        <label>Admin charges is included in the CTC.</label>
                      </div>
                    </div>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">
                      Allow Employee level Override
                    </label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.overridePFContributionRate ? "Yes" : "No"}
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">
                      Pro-rate Restricted PF Wage
                    </label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.proRateRestrictedPFWage ? "Yes" : "No"}
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">
                      Consider applicable salary components based on LOP
                    </label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.pfWageOnLOP
                        ? "Yes (when PF wage is less than ₹15,000)"
                        : "No"}
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">
                      Eligible for ABRY Scheme
                    </label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData?.abryEligibility
                        ? "Yes (Both Employer and Employee contribution is included)"
                        : "No"}
                    </label>
                  </div> */}
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                maxWidth: "50%",
                gap: "12px",
              }}
            >
              <img src={EPFCover} alt="EPF Cover" />
              <h5>Are you registered for EPF?</h5>
              <p>
                Any organisation with 20 or more employees must register for the
                Employee Provident Fund (EPF) scheme, a retirement benefit plan
                for all salaried employees.
              </p>
              <button
                className="primaryButton button"
                onClick={() => setEditActive(true)}
              >
                Enable EPF
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export { EPFTab };

import React from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { useEffect } from "react";
import { FieldArray } from "formik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { showToast } from "../../../../../Features";
import { useDispatch } from "react-redux";

export const CreateOutdoorComp = ({ formValues }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `PeopleSol - Create OutDoor`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div className="formcustom twoColumn">
        {/* <div>
          <LabelCustom labelName={"Location"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name={"location"}
            type={"text"}
            placeholder={"Enter the Location"}
          />
        </div> */}
        <div>
          <LabelCustom labelName={"Outdoor From Date"} mandatory={true} />
          <DatePickerFormiks name={"fromDate"} maxDate={formValues?.toDate} />
        </div>
        <div>
          <LabelCustom labelName={"Outdoor To Date"} mandatory={true} />
          <DatePickerFormiks name={"toDate"} minDate={formValues?.fromDate} />
        </div>
        {/* <div>
          <LabelCustom labelName={"Outdoor Reason"} mandatory={true} />
          <TextAreaFormik
            maxLength={500}
            name="description"
            placeholder={"Enter the reason"}
          />
        </div> */}
      </div>
      <div style={{ display: "grid" }}>
        <FieldArray name="List">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { List } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell padding="0" align="center">
                        <LabelCustom labelName={"S.No."} />
                      </StyledTableCell>
                      <StyledTableCell padding="0" align="center">
                        <LabelCustom labelName={"Location"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <LabelCustom
                          labelName={"Outdoor Reason"}
                          mandatory={true}
                        />
                      </StyledTableCell>

                      <StyledTableCell
                        sx={{
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <LabelCustom labelName={"Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {List.map((ListItem, index) => {
                    return (
                      <>
                        <TableBody key={index}>
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            <InputCustomFormik
                              maxLength={150}
                              name={"location"}
                              type={"text"}
                              placeholder={"Enter the Location"}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <TextAreaFormik
                              maxLength={500}
                              name="description"
                              placeholder={"Enter the reason"}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                List.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  location: "",
                                  description: "",
                                })
                              }
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </TableBody>
                      </>
                    );
                  })}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </>
  );
};

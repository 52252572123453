import React from "react";
import Nodata from "../../../../../../Assets/Nodata.svg";
import ProfileAvatar from "../../../../../../Assets/ProfileAvatar.svg";
import CloseIcon from "../../../../../../Assets/CloseIcon.svg";
import HomeDashboardBackground from "../../../../../../Assets/HomeDashboardBackground.svg";
import { useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../../../Services/axios";
import { showToast } from "../../../../../../Features";
import { Loader } from "../../../../../../Components/FormComponent/Loader";
import { useEffect } from "react";
import { format } from "date-fns";
export const TeamMemberDetails = ({
  selectedId,
  selfProfileCompletionPercentage,
  handleClose,
}) => {
  const [detailsform, setDetailsform] = useState(false);
  const location = useLocation();
  let currentUrl = location?.pathname;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `Employees/EmployeeDetails/${selectedId}`
      );
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getFormDetails();
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        <div style={{ width: "100%" }}>
          <div className="closeIcon" style={{ zIndex: 9 }}>
            <IconButton onClick={handleClose} title="Close">
              <img className="close-icon" src={CloseIcon} alt="close" />
            </IconButton>
          </div>
          {detailsform && (
            <div
              className={`homeDashboardContainer `}
              style={{ height: "185px" }}
            >
              <div className="profileImgHolders">
                <img
                  src={HomeDashboardBackground}
                  alt="Profile"
                  className="profileImgHolderbackground"
                />
                <div className="avatarBadge">
                  {selfProfileCompletionPercentage}%
                  {/* <img src={InfoIcon} alt="Info" className="infoIcon" /> */}
                </div>
                <div className="avatarContainer">
                  {detailsform?._employees?.image ? (
                    <img
                      src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${detailsform?._employees?.image}`}
                      alt={"Profile"}
                      className="imageInsideProgressBar"
                    />
                  ) : (
                    <img
                      src={ProfileAvatar}
                      alt={"Profile"}
                      className="imageInsideProgressBar"
                    />
                  )}
                </div>
              </div>

              <div style={{ margin: "auto", marginLeft: "55px" }}>
                <div className="horizontalLinePrimary"></div>
                <h3>{detailsform?._employees?.name}</h3>
                <h3>{detailsform?._employees?.lastName ?? ""}</h3>
              </div>

              <div className="dashboardRights">
                <div className="dashboardServicess">
                  <div className="dashboardServicesContainerss">
                    <div className="dashboardServicesType">
                      <p className="dashboardServicesHeading">
                        {" "}
                        <div className="horizontalLinePrimaryss"></div>
                      </p>
                      <p className="dashdetailsNames">Title</p>
                      <p className="dashdetailsNamess">
                        {detailsform?._employees?.initialName}
                      </p>
                    </div>
                  </div>
                  <div className="dashboardServicesContainerss">
                    <div className="dashboardServicesType">
                      <p className="dashboardServicesHeading">
                        {" "}
                        <div className="horizontalLinePrimaryss"></div>
                      </p>{" "}
                      <p className="dashdetailsNames">First Name</p>
                      <p className="dashdetailsNamess">
                        {detailsform?._employees?.name}
                      </p>
                    </div>
                  </div>
                  <div className="dashboardServicesContainerss">
                    <div className="dashboardServicesType">
                      <p className="dashboardServicesHeading">
                        {" "}
                        <div className="horizontalLinePrimaryss"></div>
                      </p>{" "}
                      <p className="dashdetailsNames">Last Name</p>
                      <p className="dashdetailsNamess">
                        {detailsform?._employees?.lastName ?? ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="dashboardServicess">
                  <div className="dashboardServicesContainerss">
                    <div className="dashboardServicesType">
                      <p className="dashboardServicesHeading">
                        {" "}
                        <div className="horizontalLinePrimaryss"></div>
                      </p>{" "}
                      <p className="dashdetailsNames">Official Mobile Number</p>
                      <p className="dashdetailsNamess">
                        {detailsform?._employees?.officePhoneNumber}
                      </p>
                    </div>
                  </div>
                  <div className="dashboardServicesContainerss">
                    <div className="dashboardServicesType">
                      <p className="dashboardServicesHeading">
                        {" "}
                        <div className="horizontalLinePrimaryss"></div>
                      </p>{" "}
                      <p className="dashdetailsNames">Official Email Id</p>
                      <p className="dashdetailsNamess">
                        {detailsform?._employees?.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            overflow: "auto",
            height: "calc(100% - 185px)",
            marginBottom: "30px",
          }}
        >
          <div className="teamddiv">
            <div
              style={{
                width: "100%",
                fontSize: "11px",
                fontWeight: "600",
                padding: "12px 19px",
              }}
            >
              Organisation Details
            </div>
            <div className="dashboardRigh">
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>
                    <p className="dashdetailsNames">Date of Joining</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.doj
                        ? format(
                            new Date(detailsform?._employees?.doj),
                            "dd/MM/yyyy"
                          )
                        : "-" ?? <span style={{ color: "black" }}>-</span>}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Reporting Manager</p>
                    <p className="dashdetailsNamess">
                      {detailsform?.reportingOfficer ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Employee Type</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.employeeTypeCode ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Location</p>
                    <p className="dashdetailsNamess">
                      {detailsform?.location?.name ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Designation</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.designation ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Department</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.department ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Role</p>
                    <p className="dashdetailsNamess">
                      {detailsform?.systemroles ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Shift</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.shift?.title ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="teamddiv">
            <div
              style={{
                width: "100%",
                fontSize: "11px",
                fontWeight: "600",
                padding: "12px 19px",
              }}
            >
              Personal Details
            </div>
            <div className="dashboardRigh">
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>
                    <p className="dashdetailsNames">Personal Email Id</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.personalEmail ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Personal Mobile Number</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.personalPhoneNumber ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Date of Birth</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.dob
                        ? format(
                            new Date(detailsform?._employees?.dob),
                            "dd/MM/yyyy"
                          )
                        : "-" ?? <span style={{ color: "black" }}>-</span>}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Gender</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.gender ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Nationality</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.nationality ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Language</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.language ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Religion</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.religion ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Marital Status</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.martialStatus ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Overall Experience</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.currentExperience ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Group Health Insurance</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.groupHealthInsurance ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="teamddiv">
            <div
              style={{
                width: "100%",
                fontSize: "11px",
                fontWeight: "600",
                padding: "12px 19px",
              }}
            >
              Emergency Contact Details
            </div>
            <div className="dashboardRigh">
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>
                    <p className="dashdetailsNames">Person Name</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.emergencyContactName ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Relationship</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.relationShip ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Emergency Mobile Number</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.emergencyContactNumber ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="teamddiv">
            <div
              style={{
                width: "100%",
                fontSize: "11px",
                fontWeight: "600",
                padding: "12px 19px",
              }}
            >
              Education Details
            </div>
            <div style={{ width: "100%", padding: "12px" }}>
              {detailsform?._employees?.educationlist?.length > 0 ? (
                <table className="tabless tabless-bordered">
                  <thead
                    style={{
                      backgroundColor: "var(--primary-hover)",
                    }}
                  >
                    <tr>
                      <td>S. No.</td>
                      <td>Education Level</td>
                      <td>Specialization</td>
                      <td>College/School</td>
                      <td>Year of Passing</td>
                      <td>Grade/%</td>
                    </tr>
                  </thead>
                  <tbody>
                    {detailsform?._employees?.educationlist?.map(
                      (item, index) => {
                        return (
                          <tr className="trStyle">
                            <td>{index + 1}</td>
                            <td>{item.educationLevel}</td>
                            <td>{item.degree}</td>
                            <td>{item.college}</td>
                            <td>{item.yearofPassingToYear}</td>
                            <td>{item.grade}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              ) : (
                <div
                  className="noDatastyle"
                  style={{ background: "#FBFBFB", gap: "12px" }}
                >
                  <img
                    src={Nodata}
                    alt="No data"
                    style={{ height: "80px", width: "160px", margin: "auto" }}
                  />
                  <p style={{ fontSize: "11px" }}>
                    {"Education Details  Not Found"}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="teamddiv">
            <div
              style={{
                width: "100%",
                fontSize: "11px",
                fontWeight: "600",
                padding: "12px 19px",
              }}
            >
              Experience Details
            </div>
            <div style={{ width: "100%", padding: "12px" }}>
              {detailsform?._employees?.experiencelist?.length > 0 ? (
                <table className="tabless tabless-bordered">
                  <thead
                    style={{
                      backgroundColor: "var(--primary-hover)",
                    }}
                  >
                    <tr>
                      <td>S. No.</td>
                      <td>Job Role</td>
                      <td>Job Position</td>
                      <td>Company</td>
                      <td>Duration (in Years)</td>
                    </tr>
                  </thead>
                  <tbody>
                    {detailsform?._employees?.experiencelist?.map(
                      (item, index) => {
                        return (
                          <tr className="trStyle">
                            <td>{index + 1}</td>
                            <td>{item.occupation}</td>
                            <td>{item.summary}</td>
                            <td>{item.company}</td>
                            <td>{item.duration}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              ) : (
                <div
                  className="noDatastyle"
                  style={{ background: "#FBFBFB", gap: "12px" }}
                >
                  <img
                    src={Nodata}
                    alt="No data"
                    style={{ height: "80px", width: "160px", margin: "auto" }}
                  />
                  <p style={{ fontSize: "11px" }}>
                    {"Experience Details  Not Found"}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="teamddiv">
            <div
              style={{
                width: "100%",
                fontSize: "11px",
                fontWeight: "600",
                padding: "12px 19px",
              }}
            >
              Permanent Address Details
            </div>
            <div className="dashboardRigh">
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>
                    <p className="dashdetailsNames">Address</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.permanentAddress ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Address Line 2</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.permanentAddressLine2 ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">City</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.permanentAddressCity ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">State</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.permanentAddressState ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Pincode/Zip Code</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.permanentAddressPincode ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Country</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.permanentAddressCountry ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="teamddiv">
            <div
              style={{
                width: "100%",
                fontSize: "11px",
                fontWeight: "600",
                padding: "12px 19px",
              }}
            >
              Current Address Details
            </div>
            <div className="dashboardRigh">
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>
                    <p className="dashdetailsNames">Address</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.currentAddress ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Address Line 2</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.currentAddressLine2 ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">City</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.currentAddressCity ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">State</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.currentAddressState ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="dashboardService">
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Pincode/Zip Code</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.currentAddressPincode ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="dashboardServicesContainers">
                  <div className="dashboardServicesType">
                    <p className="dashboardServicesHeading">
                      {" "}
                      <div className="horizontalLinePrimaryss"></div>
                    </p>{" "}
                    <p className="dashdetailsNames">Country</p>
                    <p className="dashdetailsNamess">
                      {detailsform?._employees?.currentAddressCountry ?? (
                        <span style={{ color: "black" }}>-</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};
